export default {
  "6d008515-8165-4990-a179-942eeec92822": {
    "team": "1610612737",
    "year": "1949",
    "players": [
      "76155",
      "76636",
      "76808",
      "76967",
      "77249",
      "77834",
      "77918",
      "78402",
      "78521"
    ]
  },
  "e8e283cb-e932-4006-a2e4-decb30f70f3b": {
    "team": "1610612737",
    "year": "1950",
    "players": [
      "76129",
      "76224",
      "76250",
      "76322",
      "76346",
      "76394",
      "76452",
      "76562",
      "76636",
      "76797",
      "76949",
      "77403",
      "77713",
      "77834",
      "77841",
      "78082",
      "78346",
      "78402"
    ]
  },
  "d6ffff35-bcc0-428a-b5ad-36aa591e1699": {
    "team": "1610612737",
    "year": "1951",
    "players": [
      "76141",
      "76173",
      "76214",
      "76311",
      "76394",
      "76473",
      "76551",
      "76745",
      "77089",
      "77278",
      "77513",
      "77572",
      "77778",
      "77783",
      "77933",
      "77941",
      "78402",
      "78586"
    ]
  },
  "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd": {
    "team": "1610612737",
    "year": "1952",
    "players": [
      "76331",
      "76473",
      "76506",
      "76702",
      "77089",
      "77375",
      "77482",
      "77514",
      "77587",
      "77610",
      "77713",
      "77742",
      "77767",
      "77778",
      "77821",
      "77913"
    ]
  },
  "2ad5d0c9-b624-431d-b5e2-fe34429aff25": {
    "team": "1610612737",
    "year": "1953",
    "players": [
      "76145",
      "76331",
      "76626",
      "76962",
      "77032",
      "77050",
      "77402",
      "77843",
      "77913",
      "78125",
      "78278",
      "78280",
      "78359",
      "78448"
    ]
  },
  "d734ea86-9401-4fa7-846a-e69cc31d9800": {
    "team": "1610612737",
    "year": "1954",
    "players": [
      "76331",
      "76442",
      "76578",
      "76937",
      "76962",
      "77430",
      "77477",
      "77499",
      "77563",
      "77847",
      "77913",
      "78063",
      "78113",
      "78125"
    ]
  },
  "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b": {
    "team": "1610612737",
    "year": "1955",
    "players": [
      "76414",
      "76708",
      "76937",
      "76962",
      "77551",
      "77799",
      "77847",
      "78072",
      "78113",
      "78125",
      "78249"
    ]
  },
  "83150f4c-0043-4b65-834a-7af2f1b9b071": {
    "team": "1610612737",
    "year": "1956",
    "players": [
      "76096",
      "76145",
      "76414",
      "76912",
      "76937",
      "77429",
      "77480",
      "77551",
      "77799",
      "77847",
      "78125",
      "78225",
      "78256"
    ]
  },
  "ea187132-fedd-4cc9-a408-f8efdbf7552c": {
    "team": "1610612737",
    "year": "1957",
    "players": [
      "76414",
      "76912",
      "77429",
      "77480",
      "77551",
      "77648",
      "77799",
      "77814",
      "77847",
      "78113",
      "78125",
      "78529"
    ]
  },
  "48b4a377-9bad-455c-bad0-01dfe0485f4a": {
    "team": "1610612737",
    "year": "1958",
    "players": [
      "76708",
      "76774",
      "76877",
      "76912",
      "77414",
      "77429",
      "77480",
      "77551",
      "77847",
      "77927",
      "78125",
      "78529"
    ]
  },
  "6604b030-ca23-43fb-b63e-9cbe726436f1": {
    "team": "1610612737",
    "year": "1959",
    "players": [
      "76708",
      "76712",
      "76774",
      "76877",
      "76912",
      "77414",
      "77480",
      "77506",
      "77551",
      "77847",
      "78125"
    ]
  },
  "b3e2ebec-a85e-4e83-a60c-e58564046a18": {
    "team": "1610612737",
    "year": "1960",
    "players": [
      "76136",
      "76708",
      "76744",
      "76877",
      "76912",
      "77327",
      "77414",
      "77506",
      "77847",
      "77862",
      "78064",
      "78530"
    ]
  },
  "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0": {
    "team": "1610612737",
    "year": "1961",
    "players": [
      "76053",
      "76324",
      "76511",
      "76651",
      "76708",
      "76744",
      "76912",
      "76969",
      "77025",
      "77058",
      "77327",
      "77414",
      "77506",
      "77555",
      "77847",
      "78160",
      "78530"
    ]
  },
  "f0939af6-305c-41a3-a3ae-9ff408049d2c": {
    "team": "1610612737",
    "year": "1962",
    "players": [
      "76108",
      "76133",
      "76253",
      "76324",
      "76610",
      "76694",
      "76912",
      "77210",
      "77727",
      "77847",
      "78358",
      "78409",
      "78530"
    ]
  },
  "851cfbc4-923d-43ea-b7ce-6801f5a3d479": {
    "team": "1610612737",
    "year": "1963",
    "players": [
      "76108",
      "76133",
      "76253",
      "76694",
      "76902",
      "76912",
      "77727",
      "77847",
      "78011",
      "78358",
      "78409",
      "78455",
      "78530"
    ]
  },
  "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e": {
    "team": "1610612737",
    "year": "1964",
    "players": [
      "76108",
      "76133",
      "76253",
      "76314",
      "76694",
      "76902",
      "76912",
      "77531",
      "77662",
      "77847",
      "78151",
      "78409",
      "78530",
      "78651"
    ]
  },
  "fd982b48-258b-4fc9-b6d2-1be39fa32925": {
    "team": "1610612737",
    "year": "1965",
    "players": [
      "76133",
      "76253",
      "76694",
      "76902",
      "76912",
      "77662",
      "78151",
      "78358",
      "78466",
      "78530"
    ]
  },
  "90d69000-90d6-401d-bb07-c69727c20c2d": {
    "team": "1610612737",
    "year": "1966",
    "players": [
      "76133",
      "76253",
      "76329",
      "76902",
      "77051",
      "77074",
      "77313",
      "78151",
      "78213",
      "78335",
      "78358",
      "78530"
    ]
  },
  "021571b6-cd31-4915-befc-76b28e61b3ae": {
    "team": "1610612737",
    "year": "1967",
    "players": [
      "76133",
      "76253",
      "76329",
      "76525",
      "77074",
      "77369",
      "77601",
      "78151",
      "78213",
      "78358",
      "78530"
    ]
  },
  "385be451-82b9-4142-849d-362d686d325b": {
    "team": "1610612737",
    "year": "1968",
    "players": [
      "76133",
      "76253",
      "76329",
      "76525",
      "76902",
      "76926",
      "76951",
      "76983",
      "77074",
      "77369",
      "77751",
      "78151",
      "78444"
    ]
  },
  "992d1ecc-4c9e-41e2-9a95-6165deedc409": {
    "team": "1610612737",
    "year": "1969",
    "players": [
      "76130",
      "76253",
      "76329",
      "76525",
      "76883",
      "76902",
      "76983",
      "77074",
      "77712",
      "77751",
      "77766",
      "78358"
    ]
  },
  "fa657246-a721-4d64-b321-9a6be5364290": {
    "team": "1610612737",
    "year": "1970",
    "players": [
      "76144",
      "76253",
      "76376",
      "76382",
      "76395",
      "76525",
      "76983",
      "77074",
      "77459",
      "77963",
      "78397",
      "78508"
    ]
  },
  "ade59950-b4fe-47d2-96c5-c7cb7b85caa6": {
    "team": "1610612737",
    "year": "1971",
    "players": [
      "76144",
      "76395",
      "76815",
      "76922",
      "76923",
      "77074",
      "77459",
      "77489",
      "77823",
      "78147",
      "78366",
      "78560"
    ]
  },
  "690bc2a9-162d-412a-8944-2faf83fc5604": {
    "team": "1610612737",
    "year": "1972",
    "players": [
      "76144",
      "76226",
      "76395",
      "76815",
      "77074",
      "77459",
      "77483",
      "78366",
      "78370",
      "78466",
      "78501"
    ]
  },
  "662a66d9-119b-4346-9391-e2e73fb7a872": {
    "team": "1610612737",
    "year": "1973",
    "players": [
      "76144",
      "76226",
      "76276",
      "76815",
      "77074",
      "77094",
      "77182",
      "77459",
      "78084",
      "78370",
      "78466",
      "78501"
    ]
  },
  "52ac4966-7c29-485c-b025-f837eb878c5b": {
    "team": "1610612737",
    "year": "1974",
    "players": [
      "76276",
      "76598",
      "76815",
      "76996",
      "77074",
      "77182",
      "77227",
      "77578",
      "78217",
      "78399",
      "78466",
      "78501"
    ]
  },
  "9bcb404e-1244-4a1a-b70f-c130c5a2178c": {
    "team": "1610612737",
    "year": "1975",
    "players": [
      "76276",
      "76468",
      "76598",
      "76623",
      "76972",
      "76996",
      "77042",
      "77074",
      "77182",
      "77578",
      "78217",
      "78399",
      "78577"
    ]
  },
  "0408e522-71e2-49ae-bd83-8ebbebbf212f": {
    "team": "1610612737",
    "year": "1976",
    "players": [
      "76099",
      "76276",
      "76383",
      "76534",
      "76558",
      "76563",
      "76598",
      "76971",
      "76996",
      "77024",
      "77074",
      "77582",
      "77993",
      "78217",
      "78308",
      "78577"
    ]
  },
  "63f3df89-660b-46e0-ab32-a6754eb78888": {
    "team": "1610612737",
    "year": "1977",
    "players": [
      "76276",
      "76383",
      "76471",
      "76598",
      "76971",
      "77024",
      "77144",
      "77162",
      "77553",
      "77983",
      "78014",
      "78308"
    ]
  },
  "9e2678b7-ef4f-487f-8c0b-3559c45262e1": {
    "team": "1610612737",
    "year": "1978",
    "players": [
      "76471",
      "76598",
      "76819",
      "76971",
      "77010",
      "77024",
      "77144",
      "77553",
      "78014",
      "78025",
      "78583"
    ]
  },
  "0931529d-0881-4f63-b629-4c68ef624474": {
    "team": "1610612737",
    "year": "1979",
    "players": [
      "76471",
      "76598",
      "76819",
      "76971",
      "77024",
      "77144",
      "77553",
      "77829",
      "78014",
      "78025",
      "78583"
    ]
  },
  "3c8804a4-d28c-4245-8592-95f990e5e797": {
    "team": "1610612737",
    "year": "1980",
    "players": [
      "76305",
      "76416",
      "76471",
      "76598",
      "76971",
      "77144",
      "77484",
      "77528",
      "77553",
      "77829",
      "78014",
      "78025",
      "78132"
    ]
  },
  "217eb2d7-0d13-4b6e-bb3c-787afa52edd5": {
    "team": "1610612737",
    "year": "1981",
    "players": [
      "76598",
      "76822",
      "76971",
      "77144",
      "77435",
      "77484",
      "77528",
      "77553",
      "77829",
      "78014",
      "78025",
      "78132",
      "600009"
    ]
  },
  "27d777a7-4281-47bc-8d7f-03cca62e745b": {
    "team": "1610612737",
    "year": "1982",
    "players": [
      "1122",
      "76283",
      "76526",
      "76638",
      "76822",
      "76968",
      "77144",
      "77149",
      "77435",
      "77484",
      "77553",
      "78014",
      "78025",
      "78200"
    ]
  },
  "234ba200-c395-49b7-8b55-38dbd3fb9d66": {
    "team": "1610612737",
    "year": "1983",
    "players": [
      "470",
      "1122",
      "1456",
      "76283",
      "76471",
      "76526",
      "76822",
      "76968",
      "77024",
      "77144",
      "77334",
      "77817",
      "77861",
      "78014",
      "78025",
      "78570"
    ]
  },
  "a4a21a99-b31a-4a65-abb5-925ebd311511": {
    "team": "1610612737",
    "year": "1984",
    "players": [
      "156",
      "470",
      "788",
      "1122",
      "1456",
      "76283",
      "76471",
      "76632",
      "76822",
      "76847",
      "76968",
      "77144",
      "77377",
      "77415",
      "77916",
      "78014",
      "78048",
      "78570"
    ]
  },
  "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6": {
    "team": "1610612737",
    "year": "1985",
    "players": [
      "156",
      "174",
      "402",
      "470",
      "788",
      "892",
      "1122",
      "1456",
      "76384",
      "76526",
      "76968",
      "77144",
      "77377",
      "78014",
      "78571"
    ]
  },
  "96e3a73f-0bd6-4012-bcdd-037ccd843b34": {
    "team": "1610612737",
    "year": "1986",
    "players": [
      "156",
      "174",
      "402",
      "470",
      "788",
      "892",
      "1122",
      "1456",
      "76968",
      "77377",
      "77530",
      "78014",
      "78549",
      "78581"
    ]
  },
  "7ffa0fc5-7225-4221-8806-a19568db71d9": {
    "team": "1610612737",
    "year": "1987",
    "players": [
      "156",
      "174",
      "402",
      "470",
      "788",
      "892",
      "941",
      "1122",
      "1456",
      "76968",
      "77377",
      "78014",
      "78463",
      "78611"
    ]
  },
  "00888d29-1563-459d-b34d-1bd9d30be418": {
    "team": "1610612737",
    "year": "1988",
    "players": [
      "156",
      "174",
      "273",
      "402",
      "470",
      "892",
      "1122",
      "76231",
      "77377",
      "77449",
      "77456",
      "78310",
      "78348"
    ]
  },
  "cb5e9f38-1a01-49f9-b378-bb5e337469e9": {
    "team": "1610612737",
    "year": "1989",
    "players": [
      "174",
      "181",
      "273",
      "402",
      "470",
      "788",
      "892",
      "906",
      "1122",
      "1381",
      "77377",
      "77449",
      "77460",
      "77484",
      "78421",
      "78558"
    ]
  },
  "1da0f652-a611-4cc2-9f88-ea410f5a6c08": {
    "team": "1610612737",
    "year": "1990",
    "players": [
      "174",
      "273",
      "402",
      "470",
      "716",
      "788",
      "892",
      "1027",
      "1122",
      "77370",
      "77449",
      "77516",
      "77626"
    ]
  },
  "6fd292ed-c920-4655-a87f-7bf233c75094": {
    "team": "1610612737",
    "year": "1991",
    "players": [
      "273",
      "278",
      "402",
      "716",
      "788",
      "1122",
      "1874",
      "76385",
      "76842",
      "77370",
      "77496",
      "77628",
      "78421",
      "78528",
      "78656"
    ]
  },
  "9fd8f340-dead-4135-9b33-86ae2c1fd70d": {
    "team": "1610612737",
    "year": "1992",
    "players": [
      "228",
      "273",
      "278",
      "302",
      "323",
      "402",
      "788",
      "1122",
      "1667",
      "1874",
      "76247",
      "76842",
      "77496",
      "78656"
    ]
  },
  "06369f70-ef9a-4da3-8cef-0a062e244b3c": {
    "team": "1610612737",
    "year": "1993",
    "players": [
      "228",
      "273",
      "278",
      "302",
      "330",
      "346",
      "378",
      "402",
      "457",
      "788",
      "941",
      "76082",
      "76839",
      "76842"
    ]
  },
  "a859ae74-acea-4f80-abaa-92f971001064": {
    "team": "1610612737",
    "year": "1994",
    "players": [
      "80",
      "246",
      "278",
      "302",
      "346",
      "378",
      "402",
      "457",
      "895",
      "941",
      "1844",
      "76128",
      "77064",
      "77372",
      "78528"
    ]
  },
  "dbb6f07b-f7c6-48bb-ba15-d3224335d02b": {
    "team": "1610612737",
    "year": "1995",
    "players": [
      "3",
      "80",
      "120",
      "278",
      "302",
      "363",
      "378",
      "399",
      "671",
      "672",
      "673",
      "674",
      "1118",
      "77690"
    ]
  },
  "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3": {
    "team": "1610612737",
    "year": "1996",
    "players": [
      "87",
      "120",
      "241",
      "302",
      "363",
      "468",
      "673",
      "695",
      "895",
      "968",
      "1080",
      "1096"
    ]
  },
  "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9": {
    "team": "1610612737",
    "year": "1997",
    "players": [
      "87",
      "120",
      "246",
      "292",
      "302",
      "359",
      "363",
      "673",
      "695",
      "895",
      "1003",
      "1544"
    ]
  },
  "5e997f1e-341e-4eb5-a34c-c83b46ab3db0": {
    "team": "1610612737",
    "year": "1998",
    "players": [
      "3",
      "87",
      "120",
      "302",
      "673",
      "770",
      "895",
      "1516",
      "1533",
      "1544",
      "1728"
    ]
  },
  "5a840cd1-bbf4-43f1-b005-40d97eead426": {
    "team": "1610612737",
    "year": "1999",
    "players": [
      "87",
      "111",
      "673",
      "754",
      "902",
      "953",
      "1003",
      "1544",
      "1728",
      "1891",
      "1898",
      "1901"
    ]
  },
  "634cdb54-39c9-4502-91b5-1c424e492da2": {
    "team": "1610612737",
    "year": "2000",
    "players": [
      "389",
      "673",
      "953",
      "1074",
      "1510",
      "1544",
      "1737",
      "1891",
      "1898",
      "1901",
      "2035",
      "2069"
    ]
  },
  "cfeb1dff-151c-4957-9363-84d2f20ef58d": {
    "team": "1610612737",
    "year": "2001",
    "players": [
      "389",
      "673",
      "949",
      "1110",
      "1521",
      "1737",
      "1891",
      "1898",
      "1901",
      "1910",
      "1956",
      "2035",
      "2069"
    ]
  },
  "e7ef5a2a-d156-4c29-b503-217499918405": {
    "team": "1610612737",
    "year": "2002",
    "players": [
      "176",
      "299",
      "673",
      "689",
      "712",
      "949",
      "1023",
      "1032",
      "1544",
      "1737",
      "1891",
      "1901",
      "1956",
      "2321",
      "2424",
      "2466"
    ]
  },
  "034cf132-3085-4071-860d-8f8cdea90094": {
    "team": "1610612737",
    "year": "2003",
    "players": [
      "445",
      "673",
      "682",
      "1442",
      "1521",
      "1536",
      "1544",
      "1891",
      "1918",
      "2038",
      "2044",
      "2055",
      "2214",
      "2564",
      "2580"
    ]
  },
  "79883e1e-2f57-488f-80fe-7e9c9148ff6b": {
    "team": "1610612737",
    "year": "2004",
    "players": [
      "339",
      "788",
      "960",
      "1542",
      "1565",
      "1731",
      "1733",
      "2044",
      "2564",
      "2735",
      "2746",
      "2764",
      "2767"
    ]
  },
  "1133cf87-e88e-4041-9357-78207256459c": {
    "team": "1610612737",
    "year": "2005",
    "players": [
      "1731",
      "1733",
      "2207",
      "2585",
      "2735",
      "2746",
      "2764",
      "2767",
      "2823",
      "101107",
      "101136",
      "101212",
      "101219"
    ]
  },
  "3458a560-4411-4f2b-808a-125fa86c6925": {
    "team": "1610612737",
    "year": "2006",
    "players": [
      "953",
      "1533",
      "1731",
      "2049",
      "2098",
      "2207",
      "2585",
      "2735",
      "2746",
      "2767",
      "101107",
      "101136",
      "101212",
      "200749",
      "200780"
    ]
  },
  "77b6338f-7de2-4f7d-ba3b-5c3bad321805": {
    "team": "1610612737",
    "year": "2007",
    "players": [
      "1710",
      "2049",
      "2207",
      "2585",
      "2735",
      "2746",
      "101107",
      "101136",
      "200780",
      "200978",
      "201143",
      "201151",
      "201238"
    ]
  },
  "90104477-18df-4be0-bd36-d89f5a93be6e": {
    "team": "1610612737",
    "year": "2008",
    "players": [
      "1710",
      "2049",
      "2207",
      "2230",
      "2436",
      "2585",
      "2746",
      "101107",
      "200780",
      "201043",
      "201143",
      "201151",
      "201238",
      "201242",
      "201629"
    ]
  },
  "45da336c-49c6-4cdc-bf17-b1407bce4edc": {
    "team": "1610612737",
    "year": "2009",
    "players": [
      "693",
      "1710",
      "2037",
      "2207",
      "2215",
      "2230",
      "2585",
      "2746",
      "101107",
      "201043",
      "201143",
      "201238",
      "201952"
    ]
  },
  "6cf40ab4-4d59-4726-b858-a2680c16e9c3": {
    "team": "1610612737",
    "year": "2010",
    "players": [
      "2037",
      "2041",
      "2207",
      "2215",
      "2550",
      "2585",
      "2694",
      "2746",
      "2863",
      "101107",
      "200756",
      "201143",
      "201952",
      "202375",
      "202377"
    ]
  },
  "55e966cc-28f6-412c-ba16-a6f3cb71b074": {
    "team": "1610612737",
    "year": "2011",
    "players": [
      "711",
      "956",
      "1503",
      "2207",
      "2209",
      "2215",
      "2457",
      "2550",
      "2584",
      "2585",
      "2746",
      "101107",
      "201143",
      "201274",
      "201952"
    ]
  },
  "09be1129-7bd3-4a7d-83ae-2eff8261643e": {
    "team": "1610612737",
    "year": "2012",
    "players": [
      "2052",
      "2563",
      "2585",
      "2594",
      "2734",
      "2746",
      "101130",
      "101150",
      "201143",
      "201229",
      "201274",
      "201952",
      "202714",
      "203098",
      "203118"
    ]
  },
  "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626": {
    "team": "1610612737",
    "year": "2013",
    "players": [
      "1882",
      "2594",
      "101150",
      "200794",
      "201143",
      "201858",
      "201952",
      "201960",
      "202714",
      "202970",
      "203098",
      "203118",
      "203471",
      "203488",
      "203544"
    ]
  },
  "85fbd70c-d195-4ad9-8b22-5623295c653a": {
    "team": "1610612737",
    "year": "2014",
    "players": [
      "1882",
      "2594",
      "200757",
      "200794",
      "201143",
      "201948",
      "201952",
      "201960",
      "202714",
      "203098",
      "203118",
      "203145",
      "203471",
      "203488",
      "203544"
    ]
  },
  "e024d958-351f-40c8-be44-4c56111346b2": {
    "team": "1610612737",
    "year": "2015",
    "players": [
      "2550",
      "2594",
      "2743",
      "200757",
      "200794",
      "201143",
      "201168",
      "201952",
      "203118",
      "203145",
      "203471",
      "203488",
      "203501",
      "203934",
      "204002"
    ]
  },
  "26c5cccc-f9b4-44f4-b37d-591287106fe7": {
    "team": "1610612737",
    "year": "2016",
    "players": [
      "2399",
      "2730",
      "2743",
      "101141",
      "101181",
      "200757",
      "200794",
      "203145",
      "203471",
      "203488",
      "203501",
      "203527",
      "1627098",
      "1627752",
      "1627761"
    ]
  },
  "14c9a360-f6fd-47ee-bd45-11d21cb11ddf": {
    "team": "1610612737",
    "year": "2017",
    "players": [
      "202379",
      "203101",
      "203145",
      "203471",
      "203473",
      "203488",
      "203705",
      "1627098",
      "1627752",
      "1627761",
      "1627814",
      "1627819",
      "1628381",
      "1628416",
      "1628463",
      "1628499",
      "1628510",
      "1628537"
    ]
  },
  "f3a386ba-a54e-40c2-8b4f-93b47b5f4694": {
    "team": "1610612737",
    "year": "2018",
    "players": [
      "1713",
      "203101",
      "203145",
      "203458",
      "203473",
      "1626147",
      "1627752",
      "1627761",
      "1627816",
      "1628381",
      "1628989",
      "1629016",
      "1629027",
      "1629121",
      "1629353"
    ]
  },
  "e8169801-0d82-448f-b1b7-6fb64e81aac6": {
    "team": "1610612737",
    "year": "2019",
    "players": [
      "1713",
      "201952",
      "203473",
      "203991",
      "1626203",
      "1627745",
      "1627746",
      "1627761",
      "1628381",
      "1628981",
      "1628989",
      "1629027",
      "1629164",
      "1629629",
      "1629631",
      "1629718"
    ]
  },
  "6968c993-52b5-4f36-8d4d-8062bfe11654": {
    "team": "1610612738",
    "year": "1946",
    "players": [
      "76254",
      "76432",
      "76470",
      "76609",
      "76653",
      "76706",
      "76780",
      "76863",
      "77031",
      "77035",
      "77237",
      "77304",
      "77672",
      "78153",
      "78154",
      "78226",
      "78411"
    ]
  },
  "e032e6bf-840a-444b-8044-7f6795db68e6": {
    "team": "1610612738",
    "year": "1947",
    "players": [
      "76432",
      "76649",
      "76780",
      "76935",
      "77014",
      "77035",
      "77462",
      "77665",
      "77735",
      "77958",
      "78050",
      "78153",
      "78226",
      "78275"
    ]
  },
  "fe9b3a4c-28b4-472c-bf2f-4421d945cba6": {
    "team": "1610612738",
    "year": "1948",
    "players": [
      "76080",
      "76139",
      "76582",
      "76649",
      "76689",
      "76692",
      "76780",
      "76982",
      "77218",
      "77238",
      "77277",
      "77417",
      "77734",
      "77735",
      "77745",
      "78114",
      "78226",
      "78275"
    ]
  },
  "0fed28f3-2e7c-4840-a01b-655dd8b56ec1": {
    "team": "1610612738",
    "year": "1949",
    "players": [
      "76582",
      "76677",
      "77011",
      "77218",
      "77277",
      "77346",
      "77366",
      "77443",
      "77659",
      "78114",
      "78124",
      "78226",
      "78434"
    ]
  },
  "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7": {
    "team": "1610612738",
    "year": "1950",
    "players": [
      "76442",
      "76584",
      "76615",
      "76959",
      "77007",
      "77011",
      "77319",
      "77366",
      "77429",
      "77546",
      "78240",
      "600003"
    ]
  },
  "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc": {
    "team": "1610612738",
    "year": "1951",
    "players": [
      "76238",
      "76442",
      "76565",
      "76584",
      "76959",
      "77429",
      "77443",
      "77546",
      "78126",
      "600003"
    ]
  },
  "388102eb-8ad3-4dde-8091-c1dd42d4df9b": {
    "team": "1610612738",
    "year": "1952",
    "players": [
      "76238",
      "76428",
      "76442",
      "76584",
      "76959",
      "77429",
      "77443",
      "77444",
      "78012",
      "78126",
      "600003"
    ]
  },
  "9f27c185-ffd4-46f2-8bdc-af4c63a87f71": {
    "team": "1610612738",
    "year": "1953",
    "players": [
      "76102",
      "76114",
      "76238",
      "76442",
      "76584",
      "76959",
      "77429",
      "77590",
      "77713",
      "78126",
      "600003"
    ]
  },
  "0f305d34-33d7-4e16-a669-f7ab442c151d": {
    "team": "1610612738",
    "year": "1954",
    "players": [
      "76102",
      "76238",
      "77429",
      "77648",
      "77713",
      "77791",
      "77907",
      "78094",
      "78126",
      "78506",
      "600003"
    ]
  },
  "75b95e75-2397-48c9-b255-676792b7226d": {
    "team": "1610612738",
    "year": "1955",
    "players": [
      "76114",
      "76989",
      "77409",
      "77429",
      "77648",
      "77713",
      "77791",
      "77967",
      "78126",
      "600003"
    ]
  },
  "bae9643c-0a73-40b8-aa1d-bb17197c309f": {
    "team": "1610612738",
    "year": "1956",
    "players": [
      "76988",
      "76989",
      "77409",
      "77713",
      "77853",
      "77907",
      "77967",
      "78049",
      "78126",
      "78371",
      "600003"
    ]
  },
  "fe5e959f-5c88-4953-a2b0-23b90347849d": {
    "team": "1610612738",
    "year": "1957",
    "players": [
      "76988",
      "77196",
      "77409",
      "77713",
      "77853",
      "77907",
      "77967",
      "78049",
      "78126",
      "78371",
      "600003"
    ]
  },
  "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7": {
    "team": "1610612738",
    "year": "1958",
    "players": [
      "76428",
      "76988",
      "77188",
      "77196",
      "77409",
      "77907",
      "78049",
      "78126",
      "78283",
      "78371",
      "600003"
    ]
  },
  "dbde9336-2a91-43f4-b1e4-d556d19c14a6": {
    "team": "1610612738",
    "year": "1959",
    "players": [
      "76428",
      "76900",
      "76988",
      "77188",
      "77196",
      "77272",
      "77409",
      "77907",
      "77954",
      "78049",
      "78126",
      "600003"
    ]
  },
  "aa143b42-fd39-4478-a383-635e77cdb263": {
    "team": "1610612738",
    "year": "1960",
    "players": [
      "76428",
      "76900",
      "76988",
      "77188",
      "77196",
      "77409",
      "77907",
      "78049",
      "78060",
      "78126",
      "600003"
    ]
  },
  "6f8988e1-d339-4b6e-a083-75700b9406ef": {
    "team": "1610612738",
    "year": "1961",
    "players": [
      "76243",
      "76900",
      "76988",
      "77188",
      "77196",
      "77409",
      "77855",
      "77907",
      "78049",
      "78060",
      "600003"
    ]
  },
  "540c48fa-176d-4700-a617-fc71b5ac523b": {
    "team": "1610612738",
    "year": "1962",
    "players": [
      "76900",
      "76970",
      "76988",
      "77188",
      "77196",
      "77409",
      "77414",
      "77907",
      "78049",
      "78060",
      "78285",
      "600003"
    ]
  },
  "3e94e421-e072-4018-95e3-f183762c70eb": {
    "team": "1610612738",
    "year": "1963",
    "players": [
      "76970",
      "76988",
      "77188",
      "77196",
      "77409",
      "77414",
      "77506",
      "77693",
      "77907",
      "78049",
      "78060",
      "78147"
    ]
  },
  "40748532-955b-4d14-ab45-0409adb5a075": {
    "team": "1610612738",
    "year": "1964",
    "players": [
      "76201",
      "76458",
      "76970",
      "76988",
      "77188",
      "77196",
      "77693",
      "77727",
      "78049",
      "78060",
      "78147",
      "78328",
      "78455"
    ]
  },
  "5f4f7d08-7158-465f-83ab-59e46d24adfd": {
    "team": "1610612738",
    "year": "1965",
    "players": [
      "76201",
      "76458",
      "76877",
      "76970",
      "77188",
      "77196",
      "77693",
      "77700",
      "78049",
      "78060",
      "78064",
      "78147",
      "78328",
      "78474"
    ]
  },
  "de3c97df-aa08-40c1-bb58-7d65bf023583": {
    "team": "1610612738",
    "year": "1966",
    "players": [
      "76106",
      "76668",
      "76970",
      "77070",
      "77188",
      "77196",
      "77259",
      "77700",
      "78049",
      "78060",
      "78147",
      "78474"
    ]
  },
  "c2d3d982-20dd-4625-ae6e-e60fe81ca32a": {
    "team": "1610612738",
    "year": "1967",
    "players": [
      "76668",
      "76843",
      "76970",
      "77070",
      "77187",
      "77196",
      "77700",
      "78049",
      "78060",
      "78147",
      "78309",
      "78486"
    ]
  },
  "b576b451-62e4-4a16-98e2-535cecc8e551": {
    "team": "1610612738",
    "year": "1968",
    "players": [
      "76105",
      "76289",
      "76379",
      "76843",
      "76970",
      "77070",
      "77164",
      "77196",
      "77700",
      "78049",
      "78060",
      "78147"
    ]
  },
  "c068cf79-fb5f-4f83-8302-92b10a5dcfff": {
    "team": "1610612738",
    "year": "1969",
    "players": [
      "76105",
      "76289",
      "76379",
      "76716",
      "76970",
      "77070",
      "77164",
      "77316",
      "77700",
      "77716",
      "78060",
      "78147",
      "78510"
    ]
  },
  "0cd82dfb-5312-4478-8847-027549aeea25": {
    "team": "1610612738",
    "year": "1970",
    "players": [
      "76379",
      "76462",
      "76576",
      "76716",
      "76970",
      "77164",
      "77316",
      "77641",
      "77700",
      "78060",
      "78189",
      "78510",
      "78539"
    ]
  },
  "c16db7e2-2323-44aa-8409-48fb4fa4ced9": {
    "team": "1610612738",
    "year": "1971",
    "players": [
      "76379",
      "76462",
      "76716",
      "76825",
      "76970",
      "77316",
      "77641",
      "77700",
      "78060",
      "78189",
      "78510",
      "78539"
    ]
  },
  "685d7197-3cc2-4e98-8f77-699d694916ab": {
    "team": "1610612738",
    "year": "1972",
    "players": [
      "76379",
      "76462",
      "76716",
      "76970",
      "77316",
      "77612",
      "77700",
      "78060",
      "78151",
      "78500",
      "78510",
      "78539"
    ]
  },
  "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404": {
    "team": "1610612738",
    "year": "1973",
    "players": [
      "76379",
      "76462",
      "76594",
      "76716",
      "76936",
      "76970",
      "77316",
      "77700",
      "78151",
      "78500",
      "78510",
      "78539"
    ]
  },
  "6d722c8b-189c-46c8-b21a-1089287af8e0": {
    "team": "1610612738",
    "year": "1974",
    "players": [
      "76055",
      "76379",
      "76409",
      "76462",
      "76594",
      "76716",
      "76936",
      "76970",
      "77525",
      "77700",
      "78151",
      "78237",
      "78500",
      "78510"
    ]
  },
  "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac": {
    "team": "1610612738",
    "year": "1975",
    "players": [
      "76040",
      "76055",
      "76213",
      "76462",
      "76970",
      "77525",
      "77700",
      "78097",
      "78105",
      "78151",
      "78237",
      "78510"
    ]
  },
  "3d276145-9d90-441c-bb53-8fe44705d3e2": {
    "team": "1610612738",
    "year": "1976",
    "players": [
      "76055",
      "76213",
      "76437",
      "76462",
      "76970",
      "77316",
      "78028",
      "78065",
      "78097",
      "78237",
      "78510",
      "78520",
      "78585"
    ]
  },
  "9661eddb-8e02-42a3-859a-311e00d67a47": {
    "team": "1610612738",
    "year": "1977",
    "players": [
      "76002",
      "76165",
      "76166",
      "76213",
      "76379",
      "76462",
      "76568",
      "76970",
      "77316",
      "77487",
      "78028",
      "78097",
      "78237",
      "78467",
      "78510",
      "78520"
    ]
  },
  "355d317f-a6b3-4f3f-96a6-93a3ea1206f8": {
    "team": "1610612738",
    "year": "1978",
    "players": [
      "76054",
      "76104",
      "76379",
      "76462",
      "76733",
      "77215",
      "77487",
      "77498",
      "77984",
      "78028",
      "78057",
      "78237",
      "78546"
    ]
  },
  "8c38cbed-2fae-4bea-85ca-d5701da62ce7": {
    "team": "1610612738",
    "year": "1979",
    "players": [
      "1449",
      "76054",
      "76351",
      "76379",
      "76462",
      "76707",
      "76733",
      "76993",
      "77215",
      "77487",
      "77984"
    ]
  },
  "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd": {
    "team": "1610612738",
    "year": "1980",
    "players": [
      "305",
      "1449",
      "1450",
      "76054",
      "76351",
      "76707",
      "76733",
      "76993",
      "77312",
      "77487",
      "77984"
    ]
  },
  "928904ed-6459-463d-af51-942ab0d40da2": {
    "team": "1610612738",
    "year": "1981",
    "players": [
      "305",
      "1449",
      "1450",
      "76017",
      "76054",
      "76230",
      "76351",
      "76608",
      "76707",
      "76733",
      "76993",
      "77487",
      "77984"
    ]
  },
  "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a": {
    "team": "1610612738",
    "year": "1982",
    "players": [
      "305",
      "1449",
      "1450",
      "76017",
      "76054",
      "76230",
      "76296",
      "76351",
      "76993",
      "77487",
      "77984",
      "78482"
    ]
  },
  "34f78999-59e2-4fc8-9a4e-a0bbce491c6e": {
    "team": "1610612738",
    "year": "1983",
    "players": [
      "305",
      "1123",
      "1449",
      "1450",
      "76017",
      "76296",
      "76351",
      "76398",
      "76993",
      "77141",
      "77487",
      "78482"
    ]
  },
  "a10ccb63-e385-40ea-8b37-0fa80e7f9675": {
    "team": "1610612738",
    "year": "1984",
    "players": [
      "305",
      "1123",
      "1449",
      "1450",
      "76017",
      "76296",
      "76342",
      "76351",
      "76398",
      "77141",
      "77487",
      "78482",
      "78571"
    ]
  },
  "cbd0f088-e3b1-43f4-af7c-eedca988f4ff": {
    "team": "1610612738",
    "year": "1985",
    "players": [
      "305",
      "1123",
      "1449",
      "1450",
      "76017",
      "76342",
      "77141",
      "78146",
      "78314",
      "78417",
      "78450",
      "78482",
      "78570"
    ]
  },
  "4280400f-b4ac-4e64-b5fb-a764ff206ced": {
    "team": "1610612738",
    "year": "1986",
    "players": [
      "122",
      "305",
      "1123",
      "1449",
      "1450",
      "76017",
      "76342",
      "76543",
      "77002",
      "77141",
      "78146",
      "78314",
      "78377",
      "78417",
      "78450",
      "78482"
    ]
  },
  "70d3bb18-8c77-4118-8c4e-a198d7f4ed83": {
    "team": "1610612738",
    "year": "1987",
    "players": [
      "38",
      "122",
      "305",
      "1449",
      "1450",
      "76009",
      "76017",
      "76543",
      "77141",
      "77384",
      "77609",
      "77819",
      "600014"
    ]
  },
  "a00084cd-698b-4fed-9bea-3d9275c16bfa": {
    "team": "1610612738",
    "year": "1988",
    "players": [
      "97",
      "170",
      "216",
      "291",
      "305",
      "333",
      "1449",
      "1450",
      "76009",
      "76170",
      "77141",
      "77384",
      "77819",
      "77969",
      "78394"
    ]
  },
  "6bc9eb85-3647-4a8c-86c8-896fb5397a53": {
    "team": "1610612738",
    "year": "1989",
    "players": [
      "170",
      "291",
      "305",
      "333",
      "1449",
      "1450",
      "76082",
      "77141",
      "77384",
      "77819",
      "78179",
      "78197"
    ]
  },
  "e0e2c95e-209e-4d00-9e53-3035df29fe95": {
    "team": "1610612738",
    "year": "1990",
    "players": [
      "170",
      "216",
      "244",
      "291",
      "305",
      "333",
      "1037",
      "1449",
      "1450",
      "77384",
      "77872",
      "78179",
      "78183",
      "78197",
      "78627"
    ]
  },
  "d830473c-10ae-439b-a8f4-bb837688cbf8": {
    "team": "1610612738",
    "year": "1991",
    "players": [
      "170",
      "244",
      "291",
      "296",
      "305",
      "333",
      "340",
      "428",
      "1037",
      "1449",
      "1450",
      "1683",
      "76082",
      "76874",
      "77384"
    ]
  },
  "5f014df6-a40d-42f4-9def-c68b338e0ee2": {
    "team": "1610612738",
    "year": "1992",
    "players": [
      "31",
      "170",
      "244",
      "291",
      "296",
      "305",
      "333",
      "428",
      "1365",
      "1450",
      "76001",
      "76082",
      "76123",
      "77294",
      "77384",
      "78478"
    ]
  },
  "aedf3815-98a8-4388-ba46-c3ae826b2947": {
    "team": "1610612738",
    "year": "1993",
    "players": [
      "129",
      "244",
      "291",
      "296",
      "305",
      "333",
      "428",
      "768",
      "1365",
      "1429",
      "76001",
      "76450",
      "76961",
      "77387",
      "78492"
    ]
  },
  "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a": {
    "team": "1610612738",
    "year": "1994",
    "players": [
      "65",
      "129",
      "133",
      "244",
      "296",
      "376",
      "400",
      "428",
      "442",
      "768",
      "1122",
      "1365",
      "1806",
      "76542",
      "76961",
      "77081"
    ]
  },
  "20f34d15-ed9b-4828-98e7-1ef7044902e6": {
    "team": "1610612738",
    "year": "1995",
    "players": [
      "65",
      "103",
      "129",
      "133",
      "175",
      "244",
      "296",
      "344",
      "376",
      "442",
      "475",
      "675",
      "676",
      "677",
      "942",
      "1127",
      "1132"
    ]
  },
  "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b": {
    "team": "1610612738",
    "year": "1996",
    "players": [
      "55",
      "103",
      "133",
      "175",
      "244",
      "296",
      "677",
      "952",
      "984",
      "1041",
      "1136",
      "1138"
    ]
  },
  "ab598beb-863a-4658-a955-99fa97cb8d08": {
    "team": "1610612738",
    "year": "1997",
    "players": [
      "65",
      "72",
      "344",
      "440",
      "692",
      "721",
      "952",
      "962",
      "963",
      "969",
      "1500",
      "1682"
    ]
  },
  "1417f231-1a9a-40cc-829d-5a1c4c5422f9": {
    "team": "1610612738",
    "year": "1998",
    "players": [
      "35",
      "72",
      "344",
      "368",
      "952",
      "958",
      "962",
      "1477",
      "1499",
      "1500",
      "1718",
      "1800"
    ]
  },
  "f2f0afff-3d0a-467c-81db-0d74f5985472": {
    "team": "1610612738",
    "year": "1999",
    "players": [
      "72",
      "77",
      "344",
      "384",
      "677",
      "952",
      "958",
      "962",
      "1499",
      "1504",
      "1559",
      "1718"
    ]
  },
  "5fbe659e-3a17-41b5-8578-5853b8e1b00c": {
    "team": "1610612738",
    "year": "2000",
    "players": [
      "179",
      "677",
      "952",
      "958",
      "962",
      "1499",
      "1548",
      "1559",
      "1718",
      "1914",
      "1960",
      "2040"
    ]
  },
  "a9f752e8-7260-43b1-ac76-bca349ff930a": {
    "team": "1610612738",
    "year": "2001",
    "players": [
      "72",
      "677",
      "915",
      "952",
      "958",
      "960",
      "962",
      "1065",
      "1499",
      "1548",
      "1718",
      "2208",
      "2241"
    ]
  },
  "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6": {
    "team": "1610612738",
    "year": "2002",
    "players": [
      "452",
      "677",
      "952",
      "960",
      "962",
      "1499",
      "1718",
      "1742",
      "1743",
      "2106",
      "2208",
      "2452"
    ]
  },
  "512c61a7-8733-4bfa-8c89-ff1e9a4b0732": {
    "team": "1610612738",
    "year": "2003",
    "players": [
      "344",
      "962",
      "1088",
      "1548",
      "1565",
      "1711",
      "1718",
      "1729",
      "1908",
      "2036",
      "2081",
      "2412",
      "2556",
      "2570",
      "2599"
    ]
  },
  "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a": {
    "team": "1610612738",
    "year": "2004",
    "players": [
      "56",
      "952",
      "1548",
      "1711",
      "1718",
      "1729",
      "2556",
      "2570",
      "2744",
      "2753",
      "2754",
      "2770"
    ]
  },
  "d2b8a516-34ac-4a97-bec5-a34b8326eee9": {
    "team": "1610612738",
    "year": "2005",
    "players": [
      "1709",
      "1711",
      "1718",
      "1887",
      "2243",
      "2424",
      "2570",
      "2744",
      "2753",
      "2754",
      "101123",
      "101155",
      "101158",
      "101204"
    ]
  },
  "86b93b83-92a1-4756-8676-68efd7025399": {
    "team": "1610612738",
    "year": "2006",
    "players": [
      "689",
      "1709",
      "1718",
      "1887",
      "2243",
      "2570",
      "2742",
      "2744",
      "2753",
      "2754",
      "101123",
      "101155",
      "200765",
      "200796",
      "200810"
    ]
  },
  "84fd7836-34e8-47f3-9e7e-e0f1be13db4d": {
    "team": "1610612738",
    "year": "2007",
    "players": [
      "136",
      "208",
      "708",
      "951",
      "1513",
      "1718",
      "1899",
      "2067",
      "2243",
      "2570",
      "2754",
      "200765",
      "200796",
      "201172",
      "201175"
    ]
  },
  "459b35a0-59ee-410e-a56c-bfe0340b5482": {
    "team": "1610612738",
    "year": "2008",
    "players": [
      "708",
      "950",
      "951",
      "1630",
      "1718",
      "2067",
      "2243",
      "2570",
      "2754",
      "200765",
      "200796",
      "201172",
      "201175",
      "201592",
      "201611"
    ]
  },
  "18a81748-917e-4293-8ca8-c13073995d9b": {
    "team": "1610612738",
    "year": "2009",
    "players": [
      "708",
      "714",
      "739",
      "951",
      "1718",
      "2243",
      "2570",
      "2605",
      "2754",
      "101126",
      "200749",
      "200765",
      "201175",
      "201802",
      "202070"
    ]
  },
  "88463408-f197-42d6-b955-433fadaa716d": {
    "team": "1610612738",
    "year": "2010",
    "players": [
      "406",
      "708",
      "951",
      "979",
      "1718",
      "2211",
      "2306",
      "2420",
      "2562",
      "2753",
      "101144",
      "200765",
      "201145",
      "201175",
      "202340"
    ]
  },
  "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331": {
    "team": "1610612738",
    "year": "2011",
    "players": [
      "708",
      "951",
      "1718",
      "2039",
      "2554",
      "2562",
      "2605",
      "101138",
      "200765",
      "200797",
      "201157",
      "201880",
      "202340",
      "202707",
      "202734"
    ]
  },
  "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1": {
    "team": "1610612738",
    "year": "2012",
    "players": [
      "708",
      "1718",
      "1891",
      "2404",
      "101138",
      "101183",
      "200765",
      "201145",
      "201584",
      "201591",
      "201944",
      "202340",
      "202348",
      "203096",
      "203097"
    ]
  },
  "69981c85-16cf-45f4-a1bd-a6b4ce882fa1": {
    "team": "1610612738",
    "year": "2013",
    "players": [
      "2222",
      "2586",
      "2743",
      "101138",
      "200765",
      "201145",
      "201202",
      "201573",
      "202340",
      "203096",
      "203187",
      "203482",
      "203515",
      "203543",
      "203569"
    ]
  },
  "783a47da-3162-4403-8a4a-5cd40c5b642b": {
    "team": "1610612738",
    "year": "2014",
    "players": [
      "2222",
      "101138",
      "201973",
      "202323",
      "202340",
      "202738",
      "203092",
      "203096",
      "203109",
      "203482",
      "203515",
      "203540",
      "203569",
      "203923",
      "203935"
    ]
  },
  "6df6d771-ac46-4dcf-8c3a-4428995d5b27": {
    "team": "1610612738",
    "year": "2015",
    "players": [
      "101161",
      "201973",
      "202323",
      "202340",
      "202738",
      "203092",
      "203096",
      "203109",
      "203482",
      "203923",
      "203935",
      "204066",
      "1626154",
      "1626175",
      "1626179"
    ]
  },
  "aa07aa32-4898-40ca-bca4-a83009a87524": {
    "team": "1610612738",
    "year": "2016",
    "players": [
      "101123",
      "101161",
      "201143",
      "201973",
      "202340",
      "202738",
      "203092",
      "203109",
      "203482",
      "203923",
      "203935",
      "1626175",
      "1626179",
      "1627743",
      "1627759"
    ]
  },
  "f703048f-cc3f-439a-bbe4-479bfdca8abf": {
    "team": "1610612738",
    "year": "2017",
    "players": [
      "201143",
      "202328",
      "202330",
      "202681",
      "202694",
      "202918",
      "203382",
      "203499",
      "203935",
      "1626179",
      "1626780",
      "1627759",
      "1627824",
      "1627846",
      "1628369",
      "1628400",
      "1628443",
      "1628444",
      "1628464"
    ]
  },
  "3fbdb326-c438-4197-a3c0-def1b0c8d39a": {
    "team": "1610612738",
    "year": "2018",
    "players": [
      "201143",
      "202330",
      "202681",
      "202694",
      "202954",
      "203382",
      "203935",
      "1626154",
      "1626179",
      "1626780",
      "1627759",
      "1627824",
      "1628369",
      "1628400",
      "1628408",
      "1628464",
      "1629057"
    ]
  },
  "f58fe66a-52f3-49b3-8f25-65157ccddeed": {
    "team": "1610612738",
    "year": "2019",
    "players": [
      "202330",
      "202683",
      "202689",
      "202954",
      "203935",
      "1627759",
      "1628369",
      "1628400",
      "1628464",
      "1629035",
      "1629057",
      "1629605",
      "1629641",
      "1629682",
      "1629684",
      "1629738",
      "1629750"
    ]
  },
  "03595ca5-71b0-4951-8a4c-75061591b9a1": {
    "team": "1610612739",
    "year": "1970",
    "players": [
      "76440",
      "76755",
      "77152",
      "77385",
      "77548",
      "77591",
      "77894",
      "78202",
      "78220",
      "78277",
      "78461",
      "78469",
      "78495"
    ]
  },
  "cbab4ae6-1b09-4518-830c-64f0bf1b90ca": {
    "team": "1610612739",
    "year": "1971",
    "players": [
      "76130",
      "76348",
      "76519",
      "77066",
      "77152",
      "77812",
      "77894",
      "77957",
      "77973",
      "78202",
      "78220",
      "78461",
      "78469",
      "78495"
    ]
  },
  "9d927b38-7d0c-449c-b6e3-af82ff03ffec": {
    "team": "1610612739",
    "year": "1972",
    "players": [
      "76348",
      "76402",
      "76403",
      "76521",
      "77152",
      "77812",
      "77973",
      "78042",
      "78202",
      "78460",
      "78461",
      "78530"
    ]
  },
  "735f4429-ca95-43f1-9eb0-dcb84c1de682": {
    "team": "1610612739",
    "year": "1973",
    "players": [
      "76248",
      "76348",
      "76402",
      "76403",
      "76521",
      "76740",
      "77812",
      "78042",
      "78202",
      "78460",
      "78461",
      "78530",
      "78603"
    ]
  },
  "6c265257-0918-47cb-acd7-85fd0f02f9a1": {
    "team": "1610612739",
    "year": "1974",
    "players": [
      "76248",
      "76348",
      "76392",
      "76402",
      "76521",
      "76740",
      "77812",
      "78047",
      "78202",
      "78213",
      "78436",
      "78603"
    ]
  },
  "ffc810fd-c63c-4a82-a09d-2e2b086d6f86": {
    "team": "1610612739",
    "year": "1975",
    "players": [
      "76248",
      "76348",
      "76392",
      "76402",
      "77331",
      "78047",
      "78202",
      "78213",
      "78436",
      "78603"
    ]
  },
  "1a7d40c1-d265-4c85-ad2f-d5386aca9558": {
    "team": "1610612739",
    "year": "1976",
    "players": [
      "76248",
      "76348",
      "76392",
      "76402",
      "77331",
      "78047",
      "78202",
      "78213",
      "78436",
      "78541",
      "600001"
    ]
  },
  "4a220bca-138c-4d87-babd-217c513c6b5c": {
    "team": "1610612739",
    "year": "1977",
    "players": [
      "76248",
      "76348",
      "76392",
      "76750",
      "76767",
      "77331",
      "78047",
      "78188",
      "78202",
      "78213",
      "78436"
    ]
  },
  "edd9bfc6-82e2-4349-b160-322801b25a0a": {
    "team": "1610612739",
    "year": "1978",
    "players": [
      "76348",
      "76392",
      "76523",
      "76750",
      "76767",
      "77021",
      "77331",
      "77356",
      "77615",
      "78047",
      "78188",
      "78202",
      "78436"
    ]
  },
  "561af7fe-6914-4e3e-8250-12448f9d452c": {
    "team": "1610612739",
    "year": "1979",
    "players": [
      "76348",
      "76350",
      "76734",
      "76750",
      "77331",
      "77356",
      "77615",
      "77998",
      "78047",
      "78200",
      "78208",
      "78294",
      "78436",
      "78577"
    ]
  },
  "e7fbc45b-f144-4840-ad1c-b5f0ec90b697": {
    "team": "1610612739",
    "year": "1980",
    "players": [
      "76242",
      "76336",
      "76350",
      "76734",
      "77078",
      "77087",
      "77209",
      "77331",
      "77615",
      "77848",
      "78200",
      "78204",
      "78468",
      "100263"
    ]
  },
  "68c8e4a0-94d6-4144-a571-88637a22964a": {
    "team": "1610612739",
    "year": "1981",
    "players": [
      "229",
      "76151",
      "76239",
      "76249",
      "76569",
      "76686",
      "76734",
      "77010",
      "77071",
      "77087",
      "77615",
      "77623",
      "77635",
      "77848",
      "77944",
      "77986",
      "78150",
      "78468",
      "78482",
      "78531"
    ]
  },
  "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d": {
    "team": "1610612739",
    "year": "1982",
    "players": [
      "76082",
      "76436",
      "76725",
      "76753",
      "76980",
      "77071",
      "77087",
      "77246",
      "77326",
      "77442",
      "77715",
      "77986",
      "78343",
      "78531"
    ]
  },
  "1eb2a245-2321-463c-b8b3-437375f0d653": {
    "team": "1610612739",
    "year": "1983",
    "players": [
      "76082",
      "76436",
      "76474",
      "76753",
      "76791",
      "76847",
      "77030",
      "77071",
      "77087",
      "77873",
      "77986",
      "78133",
      "78332"
    ]
  },
  "54e1af29-ac7b-45dd-88b9-17ae2aefe389": {
    "team": "1610612739",
    "year": "1984",
    "players": [
      "770",
      "76046",
      "76082",
      "76526",
      "76753",
      "76855",
      "77030",
      "77071",
      "77087",
      "77873",
      "78047",
      "78133",
      "78204",
      "78332",
      "78386",
      "78556"
    ]
  },
  "279912f2-e80a-4555-b8e8-e15af6f8f2fd": {
    "team": "1610612739",
    "year": "1985",
    "players": [
      "770",
      "76082",
      "76249",
      "76753",
      "77030",
      "77071",
      "77184",
      "77361",
      "77524",
      "77609",
      "77873",
      "78133",
      "78386"
    ]
  },
  "c37d295a-100f-471b-8c29-666593f37dc3": {
    "team": "1610612739",
    "year": "1986",
    "players": [
      "73",
      "166",
      "271",
      "378",
      "770",
      "895",
      "899",
      "921",
      "76082",
      "77071",
      "77361",
      "77518",
      "78386"
    ]
  },
  "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64": {
    "team": "1610612739",
    "year": "1987",
    "players": [
      "73",
      "166",
      "201",
      "209",
      "378",
      "899",
      "921",
      "76154",
      "76994",
      "77071",
      "77153",
      "77685",
      "78007",
      "78059"
    ]
  },
  "31b331bf-d3c1-4d54-9975-3c5e8d268691": {
    "team": "1610612739",
    "year": "1988",
    "players": [
      "73",
      "166",
      "201",
      "378",
      "705",
      "899",
      "921",
      "77071",
      "77685",
      "78014",
      "78059",
      "78395"
    ]
  },
  "4f39cfda-e726-4c4d-9553-4328412edaf3": {
    "team": "1610612739",
    "year": "1989",
    "players": [
      "70",
      "73",
      "359",
      "378",
      "899",
      "921",
      "1972",
      "76152",
      "76387",
      "77623",
      "77685",
      "78014",
      "78419"
    ]
  },
  "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f": {
    "team": "1610612739",
    "year": "1990",
    "players": [
      "70",
      "73",
      "198",
      "359",
      "378",
      "899",
      "921",
      "1080",
      "1972",
      "76079",
      "76152",
      "76387",
      "77685",
      "77789",
      "78395",
      "78614"
    ]
  },
  "56805152-7f69-4038-9e73-74d65c84c8ba": {
    "team": "1610612739",
    "year": "1991",
    "players": [
      "70",
      "73",
      "174",
      "184",
      "198",
      "210",
      "378",
      "899",
      "921",
      "1080",
      "1429",
      "77685",
      "78059"
    ]
  },
  "a5c8704a-f574-41ab-a9f9-60f16c4148ac": {
    "team": "1610612739",
    "year": "1992",
    "players": [
      "73",
      "174",
      "184",
      "198",
      "210",
      "378",
      "786",
      "899",
      "921",
      "76904",
      "77335",
      "77685",
      "78059"
    ]
  },
  "2d01fe82-37d3-4641-8980-b545cafd6e1d": {
    "team": "1610612739",
    "year": "1993",
    "players": [
      "73",
      "168",
      "174",
      "184",
      "198",
      "210",
      "238",
      "786",
      "899",
      "921",
      "1118",
      "1666",
      "76022",
      "76904",
      "77019",
      "77685",
      "78352"
    ]
  },
  "6622ad02-9b8f-485d-ac5d-0ca5b498df8e": {
    "team": "1610612739",
    "year": "1994",
    "players": [
      "73",
      "122",
      "168",
      "174",
      "184",
      "198",
      "210",
      "238",
      "262",
      "306",
      "899",
      "1068",
      "1120",
      "1666",
      "76338"
    ]
  },
  "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb": {
    "team": "1610612739",
    "year": "1995",
    "players": [
      "105",
      "114",
      "168",
      "180",
      "184",
      "198",
      "210",
      "226",
      "238",
      "262",
      "680",
      "681",
      "682",
      "683",
      "1432"
    ]
  },
  "a6a532fb-3cac-4383-8923-fdd53f964596": {
    "team": "1610612739",
    "year": "1996",
    "players": [
      "36",
      "168",
      "184",
      "198",
      "210",
      "226",
      "238",
      "681",
      "682",
      "770",
      "958",
      "1002",
      "1035"
    ]
  },
  "3c1cd7eb-779f-4052-bd0b-316146ce785e": {
    "team": "1610612739",
    "year": "1997",
    "players": [
      "198",
      "418",
      "431",
      "445",
      "682",
      "958",
      "980",
      "1035",
      "1063",
      "1507",
      "1510",
      "1538"
    ]
  },
  "ea288811-4d0e-4235-aa38-7f3580a89f0c": {
    "team": "1610612739",
    "year": "1998",
    "players": [
      "198",
      "271",
      "348",
      "431",
      "445",
      "682",
      "692",
      "1507",
      "1538",
      "1756",
      "1863"
    ]
  },
  "25e7a76c-35b5-4898-88be-f97f130ca905": {
    "team": "1610612739",
    "year": "1999",
    "players": [
      "95",
      "198",
      "431",
      "441",
      "445",
      "682",
      "692",
      "1510",
      "1538",
      "1863",
      "1889",
      "1930"
    ]
  },
  "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2": {
    "team": "1610612739",
    "year": "2000",
    "players": [
      "221",
      "359",
      "423",
      "441",
      "445",
      "754",
      "1533",
      "1714",
      "1723",
      "1889",
      "1892",
      "2036"
    ]
  },
  "f51b9d9f-c8f4-475d-943d-8750c0525b57": {
    "team": "1610612739",
    "year": "2001",
    "players": [
      "179",
      "238",
      "441",
      "445",
      "980",
      "1720",
      "1729",
      "1730",
      "1889",
      "1892",
      "1908",
      "2036"
    ]
  },
  "062199f5-2cc4-4d5a-a186-5a1b84e31af1": {
    "team": "1610612739",
    "year": "2002",
    "players": [
      "98",
      "238",
      "902",
      "980",
      "1533",
      "1565",
      "1729",
      "1907",
      "1908",
      "1942",
      "1960",
      "2032",
      "2036",
      "2205",
      "2402",
      "2430",
      "2440",
      "2470"
    ]
  },
  "44ae4057-4588-42ee-bc25-8d3d96f2a8d2": {
    "team": "1610612739",
    "year": "2003",
    "players": [
      "677",
      "976",
      "980",
      "1499",
      "1563",
      "1924",
      "1956",
      "2205",
      "2208",
      "2257",
      "2402",
      "2430",
      "2544",
      "2574"
    ]
  },
  "515e4e87-1f51-4a53-a360-a419dc17ac74": {
    "team": "1610612739",
    "year": "2004",
    "players": [
      "727",
      "976",
      "980",
      "1714",
      "1956",
      "2205",
      "2400",
      "2412",
      "2544",
      "2562",
      "2760"
    ]
  },
  "5319635c-297b-4e15-8b2b-a76473122ca9": {
    "team": "1610612739",
    "year": "2005",
    "players": [
      "673",
      "727",
      "923",
      "980",
      "1716",
      "1800",
      "1956",
      "2400",
      "2436",
      "2544",
      "2562",
      "2739",
      "2760",
      "101149",
      "101211"
    ]
  },
  "54af9cd6-79f3-4008-b5ee-94591bb37088": {
    "team": "1610612739",
    "year": "2006",
    "players": [
      "133",
      "727",
      "923",
      "980",
      "1513",
      "1716",
      "1800",
      "1956",
      "2400",
      "2544",
      "2562",
      "2760",
      "101204",
      "200769",
      "200789"
    ]
  },
  "5406747a-e323-4cbf-be5b-cf9ff36577a9": {
    "team": "1610612739",
    "year": "2007",
    "players": [
      "693",
      "727",
      "980",
      "1112",
      "1800",
      "1887",
      "2484",
      "2544",
      "2562",
      "2753",
      "2760",
      "2873",
      "101204",
      "200789",
      "200984"
    ]
  },
  "0d546caf-814a-4eb2-8303-fd97b90bc898": {
    "team": "1610612739",
    "year": "2008",
    "players": [
      "693",
      "953",
      "980",
      "1112",
      "1887",
      "2544",
      "2562",
      "2590",
      "2753",
      "2760",
      "101214",
      "200789",
      "200814",
      "201581",
      "201616"
    ]
  },
  "d880c1ca-1546-4361-b8de-d39e6da49f39": {
    "team": "1610612739",
    "year": "2009",
    "players": [
      "406",
      "980",
      "1515",
      "1712",
      "2544",
      "2590",
      "2742",
      "2753",
      "2760",
      "101214",
      "200081",
      "200789",
      "200796",
      "201581",
      "201980"
    ]
  },
  "305ed289-0686-4e89-93f0-6bc32c4b5857": {
    "team": "1610612739",
    "year": "2010",
    "players": [
      "1515",
      "1712",
      "1884",
      "2760",
      "101121",
      "200789",
      "200797",
      "201196",
      "201581",
      "201623",
      "201963",
      "202087",
      "202376",
      "202396",
      "202412"
    ]
  },
  "394863c9-b8a6-474b-b7ac-a73cf972c685": {
    "team": "1610612739",
    "year": "2011",
    "players": [
      "1515",
      "1712",
      "2575",
      "2760",
      "200789",
      "201623",
      "201956",
      "202087",
      "202376",
      "202388",
      "202396",
      "202412",
      "202681",
      "202684",
      "202810"
    ]
  },
  "08c952ee-8f32-4c75-99e0-0faba51dcd4c": {
    "team": "1610612739",
    "year": "2012",
    "players": [
      "2575",
      "2733",
      "2760",
      "101139",
      "200789",
      "200809",
      "201578",
      "201956",
      "201961",
      "202087",
      "202681",
      "202684",
      "203079",
      "203092",
      "203158"
    ]
  },
  "51581511-27eb-417b-be7a-c3792b97c046": {
    "team": "1610612739",
    "year": "2013",
    "players": [
      "2736",
      "2760",
      "101127",
      "101139",
      "201150",
      "202087",
      "202681",
      "202684",
      "203079",
      "203092",
      "203461",
      "203467",
      "203508",
      "203521",
      "203816"
    ]
  },
  "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592": {
    "team": "1610612739",
    "year": "2014",
    "players": [
      "1890",
      "2034",
      "2217",
      "2544",
      "2570",
      "2592",
      "2747",
      "2760",
      "201567",
      "202389",
      "202681",
      "202684",
      "202697",
      "203521",
      "203925"
    ]
  },
  "6ac28050-d5f5-4251-8faa-6c3ccee02978": {
    "team": "1610612739",
    "year": "2015",
    "players": [
      "2210",
      "2544",
      "2563",
      "2590",
      "2592",
      "2747",
      "101112",
      "201567",
      "201619",
      "202389",
      "202681",
      "202684",
      "202697",
      "203521",
      "203895"
    ]
  },
  "bd07daee-a787-4c9c-bd26-aa7f2f3eb071": {
    "team": "1610612739",
    "year": "2016",
    "players": [
      "2210",
      "2544",
      "2563",
      "2592",
      "2594",
      "2747",
      "101112",
      "101114",
      "201567",
      "202681",
      "202682",
      "202684",
      "202697",
      "204002",
      "1627770"
    ]
  },
  "d3fa34ae-65ce-4373-b8b9-b9679b504e5a": {
    "team": "1610612739",
    "year": "2017",
    "players": [
      "2544",
      "2570",
      "2594",
      "2747",
      "101181",
      "201145",
      "201567",
      "201588",
      "202684",
      "203903",
      "203918",
      "1626204",
      "1626224",
      "1627790",
      "1627855"
    ]
  },
  "53265a6a-bb78-4439-a46d-37e7fb24c78a": {
    "team": "1610612739",
    "year": "2018",
    "players": [
      "2747",
      "101112",
      "201567",
      "202684",
      "202688",
      "203089",
      "203521",
      "203903",
      "203917",
      "1626204",
      "1626224",
      "1627737",
      "1627790",
      "1628021",
      "1628417",
      "1629012",
      "1629061"
    ]
  },
  "10cfa83d-aa34-4f82-a0eb-ece8c29014ce": {
    "team": "1610612739",
    "year": "2019",
    "players": [
      "201567",
      "202684",
      "203083",
      "203521",
      "203957",
      "1626204",
      "1626218",
      "1626224",
      "1627790",
      "1627815",
      "1628035",
      "1629012",
      "1629636",
      "1629645",
      "1629685",
      "1629731",
      "1629760"
    ]
  },
  "53d22344-d154-40b3-a170-e360f5ec1f92": {
    "team": "1610612740",
    "year": "2002",
    "players": [
      "133",
      "136",
      "248",
      "278",
      "469",
      "672",
      "676",
      "922",
      "1714",
      "1724",
      "1884",
      "1924",
      "2040",
      "2042",
      "2048",
      "2213"
    ]
  },
  "1fb8921b-b6d4-4a72-921b-6b47f00f3981": {
    "team": "1610612740",
    "year": "2003",
    "players": [
      "120",
      "133",
      "136",
      "248",
      "278",
      "353",
      "469",
      "1714",
      "1742",
      "1884",
      "2042",
      "2048",
      "2466",
      "2561"
    ]
  },
  "8749b250-741f-47ab-a2f0-4f32ee5b3eba": {
    "team": "1610612740",
    "year": "2004",
    "players": [
      "136",
      "248",
      "1924",
      "2048",
      "2365",
      "2411",
      "2418",
      "2424",
      "2561",
      "2573",
      "2747",
      "2761"
    ]
  },
  "01490269-b22f-47cf-87fb-1d45cb96a3f3": {
    "team": "1610612740",
    "year": "2005",
    "players": [
      "136",
      "983",
      "1425",
      "1531",
      "2033",
      "2046",
      "2049",
      "2446",
      "2561",
      "2669",
      "2745",
      "2747",
      "101108",
      "101138",
      "101178"
    ]
  },
  "69b0bce7-6eb3-4a3a-8573-640e2d373bc5": {
    "team": "1610612740",
    "year": "2006",
    "players": [
      "978",
      "1517",
      "1531",
      "2046",
      "2199",
      "2446",
      "2457",
      "2484",
      "2561",
      "2669",
      "101108",
      "101138",
      "200756",
      "200759",
      "200790"
    ]
  },
  "14a0291f-910b-489c-ad62-b6ef70677114": {
    "team": "1610612740",
    "year": "2007",
    "players": [
      "978",
      "1719",
      "1763",
      "2050",
      "2199",
      "2229",
      "2365",
      "2408",
      "2446",
      "2457",
      "2561",
      "101108",
      "200756",
      "201153"
    ]
  },
  "6c0c60a8-7363-41d6-bea6-a19e1b5adcea": {
    "team": "1610612740",
    "year": "2008",
    "players": [
      "978",
      "1498",
      "1752",
      "1763",
      "1899",
      "2050",
      "2199",
      "2408",
      "2446",
      "2484",
      "2561",
      "101108",
      "200756",
      "201153"
    ]
  },
  "3496e345-3758-4ff7-ac86-111d4710f34d": {
    "team": "1610612740",
    "year": "2009",
    "players": [
      "978",
      "1752",
      "1899",
      "2050",
      "2443",
      "2561",
      "2731",
      "101108",
      "101113",
      "201153",
      "201189",
      "201954",
      "201977"
    ]
  },
  "02f605c4-2f53-49b5-9add-ed6fc2e857b6": {
    "team": "1610612740",
    "year": "2010",
    "players": [
      "2431",
      "2556",
      "2561",
      "2584",
      "2731",
      "2772",
      "2788",
      "101108",
      "101127",
      "201158",
      "201160",
      "201171",
      "201189",
      "201607",
      "202347"
    ]
  },
  "506d3176-b688-4317-9dc7-cfecc25eb790": {
    "team": "1610612740",
    "year": "2011",
    "players": [
      "2549",
      "2731",
      "2772",
      "101127",
      "201158",
      "201160",
      "201171",
      "201208",
      "201569",
      "202329",
      "202333",
      "202349",
      "202406",
      "202498",
      "202970"
    ]
  },
  "f491a823-32f7-4eac-9f38-2e571e28a597": {
    "team": "1610612740",
    "year": "2012",
    "players": [
      "2427",
      "200811",
      "201160",
      "201569",
      "201577",
      "201583",
      "202227",
      "202329",
      "202333",
      "202349",
      "202498",
      "203076",
      "203085",
      "203121",
      "203148"
    ]
  },
  "05a55327-a089-468c-962f-fef3dd7afc4b": {
    "team": "1610612740",
    "year": "2013",
    "players": [
      "2408",
      "201160",
      "201569",
      "201582",
      "201583",
      "201627",
      "201936",
      "201950",
      "202329",
      "202337",
      "203076",
      "203085",
      "203121",
      "203148",
      "203480",
      "203481"
    ]
  },
  "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8": {
    "team": "1610612740",
    "year": "2014",
    "players": [
      "201569",
      "201582",
      "201583",
      "201600",
      "201936",
      "201950",
      "201962",
      "201967",
      "202337",
      "202347",
      "202690",
      "202708",
      "203076",
      "203481"
    ]
  },
  "a05d79f4-ff84-446b-ba4e-bdcf17064a00": {
    "team": "1610612740",
    "year": "2015",
    "players": [
      "2570",
      "201569",
      "201582",
      "201583",
      "201600",
      "201936",
      "201950",
      "201962",
      "201967",
      "202087",
      "202337",
      "202347",
      "202706",
      "202708",
      "203076",
      "203516",
      "204025"
    ]
  },
  "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb": {
    "team": "1610612740",
    "year": "2016",
    "players": [
      "201582",
      "201600",
      "201950",
      "201967",
      "202326",
      "202347",
      "202348",
      "202700",
      "202734",
      "203076",
      "203524",
      "204025",
      "1626188",
      "1626253",
      "1627767"
    ]
  },
  "3fa2e383-80a4-4177-aad1-60ea0379e220": {
    "team": "1610612740",
    "year": "2017",
    "players": [
      "2731",
      "200765",
      "201582",
      "201950",
      "202326",
      "202348",
      "202703",
      "202732",
      "202734",
      "203076",
      "203121",
      "203524",
      "203546",
      "203580",
      "1627767",
      "1628402",
      "1628429"
    ]
  },
  "039f5cee-9d10-4eaf-b226-d5668fe65648": {
    "team": "1610612740",
    "year": "2018",
    "players": [
      "201950",
      "202734",
      "203076",
      "203121",
      "203524",
      "203546",
      "203901",
      "203944",
      "1626143",
      "1626169",
      "1626174",
      "1627767",
      "1628402",
      "1629026",
      "1629129",
      "1629541"
    ]
  },
  "1b10411f-c8db-4154-9db0-1900f8577a91": {
    "team": "1610612740",
    "year": "2019",
    "players": [
      "200755",
      "201950",
      "202324",
      "202734",
      "203121",
      "1626143",
      "1627742",
      "1627982",
      "1628366",
      "1628402",
      "1628404",
      "1629026",
      "1629597",
      "1629627",
      "1629637",
      "1629638",
      "1629740"
    ]
  },
  "cd81f169-3cb2-46b5-bdc3-08821680d249": {
    "team": "1610612741",
    "year": "1966",
    "players": [
      "76206",
      "76219",
      "76382",
      "76403",
      "76680",
      "77295",
      "77548",
      "77658",
      "78003",
      "78081",
      "78173",
      "78455",
      "78466"
    ]
  },
  "080d0104-5755-4078-9dd5-e5b36018755c": {
    "team": "1610612741",
    "year": "1967",
    "players": [
      "76206",
      "76309",
      "76403",
      "76680",
      "76945",
      "76965",
      "77548",
      "77658",
      "78081",
      "78173",
      "78231",
      "78466",
      "78524"
    ]
  },
  "5ea792bd-890a-47b6-babe-2afabfd78f09": {
    "team": "1610612741",
    "year": "1968",
    "players": [
      "76192",
      "76206",
      "76403",
      "76965",
      "77412",
      "77712",
      "77840",
      "78173",
      "78466",
      "78485",
      "78524"
    ]
  },
  "9f64bae2-055b-4727-8d9e-dee954e0bd10": {
    "team": "1610612741",
    "year": "1969",
    "players": [
      "76125",
      "76192",
      "76922",
      "76965",
      "77227",
      "77412",
      "77840",
      "78173",
      "78372",
      "78435",
      "78485",
      "78495"
    ]
  },
  "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2": {
    "team": "1610612741",
    "year": "1970",
    "players": [
      "76125",
      "76192",
      "76419",
      "76747",
      "77046",
      "77270",
      "77412",
      "78039",
      "78173",
      "78435",
      "78485"
    ]
  },
  "940cea5e-14c6-4fd7-a44a-e5415c80a822": {
    "team": "1610612741",
    "year": "1971",
    "players": [
      "76192",
      "76419",
      "76575",
      "77270",
      "77412",
      "77540",
      "77815",
      "77875",
      "77917",
      "78173",
      "78435",
      "78485"
    ]
  },
  "23be4e16-3397-4e06-9037-ee9c4d7cdc1a": {
    "team": "1610612741",
    "year": "1972",
    "players": [
      "76078",
      "76192",
      "76789",
      "77270",
      "77412",
      "77875",
      "77917",
      "78046",
      "78173",
      "78406",
      "78435",
      "78485"
    ]
  },
  "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5": {
    "team": "1610612741",
    "year": "1973",
    "players": [
      "76015",
      "76078",
      "76192",
      "76789",
      "77412",
      "77875",
      "77917",
      "78173",
      "78406",
      "78435",
      "78485"
    ]
  },
  "66953847-11ae-490d-ba1c-76473d320167": {
    "team": "1610612741",
    "year": "1974",
    "players": [
      "76146",
      "76192",
      "76789",
      "76908",
      "77013",
      "77168",
      "77412",
      "78173",
      "78406",
      "78435",
      "78585",
      "600001"
    ]
  },
  "18b07887-8de0-4728-924b-f6f6bca06f1d": {
    "team": "1610612741",
    "year": "1975",
    "players": [
      "76146",
      "76187",
      "76192",
      "76707",
      "76789",
      "77168",
      "77341",
      "77412",
      "77463",
      "77812",
      "77869",
      "78173",
      "78406",
      "78585",
      "600001"
    ]
  },
  "c3238288-bce4-48df-bf77-11f4a2fd274e": {
    "team": "1610612741",
    "year": "1976",
    "players": [
      "76192",
      "76707",
      "77042",
      "77168",
      "77314",
      "77341",
      "77463",
      "77490",
      "77517",
      "77580",
      "77869",
      "78208",
      "78243",
      "78406",
      "600014"
    ]
  },
  "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7": {
    "team": "1610612741",
    "year": "1977",
    "players": [
      "76055",
      "76059",
      "76192",
      "76564",
      "77042",
      "77168",
      "77334",
      "77490",
      "77580",
      "77869",
      "78045",
      "78135",
      "78406",
      "78475",
      "600014"
    ]
  },
  "87958611-d920-4ef5-b85e-78dafe9cbf1d": {
    "team": "1610612741",
    "year": "1978",
    "players": [
      "76059",
      "76276",
      "76607",
      "77042",
      "77162",
      "77168",
      "77334",
      "77396",
      "77490",
      "77580",
      "78310",
      "600014"
    ]
  },
  "7065fada-dca9-420f-9f3e-14c3d4112b09": {
    "team": "1610612741",
    "year": "1979",
    "players": [
      "76078",
      "76159",
      "76286",
      "76567",
      "76881",
      "77162",
      "77334",
      "77432",
      "77490",
      "77580",
      "78205",
      "78215",
      "78310",
      "600014"
    ]
  },
  "e0fd7afa-adb6-438b-b16f-331254cbf1f6": {
    "team": "1610612741",
    "year": "1980",
    "players": [
      "76567",
      "76881",
      "77182",
      "77246",
      "77373",
      "77490",
      "78215",
      "78310",
      "78531",
      "78533",
      "78618",
      "600014"
    ]
  },
  "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe": {
    "team": "1610612741",
    "year": "1981",
    "players": [
      "76189",
      "76304",
      "76567",
      "76881",
      "77110",
      "77182",
      "77246",
      "77373",
      "77989",
      "78215",
      "78310",
      "78533",
      "78615",
      "600014"
    ]
  },
  "e71842ec-52d3-4f13-93be-8bad598624c2": {
    "team": "1610612741",
    "year": "1982",
    "players": [
      "76231",
      "76242",
      "76453",
      "76497",
      "76881",
      "77019",
      "77110",
      "77373",
      "77756",
      "77757",
      "78233",
      "78310",
      "78615"
    ]
  },
  "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc": {
    "team": "1610612741",
    "year": "1983",
    "players": [
      "941",
      "76291",
      "76453",
      "76497",
      "76876",
      "76881",
      "77019",
      "77134",
      "77373",
      "77757",
      "78523",
      "78615"
    ]
  },
  "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f": {
    "team": "1610612741",
    "year": "1984",
    "players": [
      "893",
      "941",
      "76453",
      "76497",
      "76876",
      "76881",
      "77019",
      "77134",
      "77175",
      "77484",
      "77757",
      "78615"
    ]
  },
  "061c0a9f-f0a9-430b-a0cc-94b6411c069f": {
    "team": "1610612741",
    "year": "1985",
    "players": [
      "891",
      "893",
      "1091",
      "76093",
      "76268",
      "76453",
      "76497",
      "76804",
      "76876",
      "77019",
      "77047",
      "77438",
      "77547",
      "77757",
      "77820",
      "78615"
    ]
  },
  "eb5f39fc-ff99-46c0-9481-df583ece8a3f": {
    "team": "1610612741",
    "year": "1986",
    "players": [
      "9",
      "891",
      "893",
      "939",
      "1479",
      "76093",
      "76411",
      "76453",
      "77820",
      "77873",
      "78110",
      "78378",
      "78430"
    ]
  },
  "bd1683be-ac22-4089-812f-a0cad11f532c": {
    "team": "1610612741",
    "year": "1987",
    "players": [
      "270",
      "891",
      "893",
      "937",
      "1479",
      "76453",
      "77820",
      "78110",
      "78378",
      "78417",
      "78430",
      "600009"
    ]
  },
  "703270c9-07d0-4181-8c5d-d52bb7fd0e9c": {
    "team": "1610612741",
    "year": "1988",
    "players": [
      "116",
      "270",
      "781",
      "893",
      "917",
      "937",
      "76362",
      "76453",
      "76518",
      "77034",
      "77820",
      "77880",
      "78110",
      "78417"
    ]
  },
  "4478b73e-05fd-4148-a9c1-d3bf08421298": {
    "team": "1610612741",
    "year": "1989",
    "players": [
      "270",
      "351",
      "769",
      "781",
      "893",
      "937",
      "1874",
      "76362",
      "76518",
      "77034",
      "77374",
      "77697",
      "77820"
    ]
  },
  "d861b69c-544d-4879-b875-00604c148259": {
    "team": "1610612741",
    "year": "1990",
    "players": [
      "270",
      "281",
      "351",
      "769",
      "781",
      "893",
      "937",
      "76362",
      "77034",
      "77054",
      "77377",
      "77820"
    ]
  },
  "c769ee1b-d32a-40c5-b659-0bc1f7f699b4": {
    "team": "1610612741",
    "year": "1991",
    "players": [
      "270",
      "281",
      "351",
      "769",
      "781",
      "893",
      "937",
      "76362",
      "76942",
      "77034",
      "77377",
      "77707",
      "77820"
    ]
  },
  "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7": {
    "team": "1610612741",
    "year": "1992",
    "players": [
      "270",
      "281",
      "351",
      "769",
      "781",
      "893",
      "937",
      "1622",
      "76184",
      "76362",
      "76676",
      "77697",
      "77820",
      "78375",
      "78437",
      "101258"
    ]
  },
  "8affb09c-9c6b-47cb-b77c-d44e08d962bc": {
    "team": "1610612741",
    "year": "1993",
    "players": [
      "26",
      "70",
      "82",
      "270",
      "281",
      "389",
      "769",
      "781",
      "916",
      "937",
      "939",
      "76362",
      "76676",
      "77138",
      "77820"
    ]
  },
  "fbcf2148-8ad7-45e7-9bd1-30d23b258343": {
    "team": "1610612741",
    "year": "1994",
    "players": [
      "26",
      "54",
      "70",
      "82",
      "146",
      "166",
      "389",
      "769",
      "781",
      "893",
      "916",
      "937",
      "939",
      "1474",
      "76676"
    ]
  },
  "6bc52ba5-a306-4e12-aac5-fd81eab720a2": {
    "team": "1610612741",
    "year": "1995",
    "players": [
      "23",
      "26",
      "54",
      "70",
      "82",
      "146",
      "166",
      "229",
      "389",
      "679",
      "753",
      "893",
      "917",
      "927",
      "937"
    ]
  },
  "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7": {
    "team": "1610612741",
    "year": "1996",
    "players": [
      "23",
      "26",
      "70",
      "146",
      "166",
      "183",
      "305",
      "389",
      "679",
      "753",
      "893",
      "937"
    ]
  },
  "964e7d61-03f9-4a09-bc44-b746e8966b09": {
    "team": "1610612741",
    "year": "1997",
    "players": [
      "23",
      "26",
      "54",
      "70",
      "82",
      "146",
      "166",
      "197",
      "389",
      "753",
      "893",
      "937"
    ]
  },
  "5d5ecf5f-9104-462a-bf8c-b3073e27584c": {
    "team": "1610612741",
    "year": "1998",
    "players": [
      "54",
      "82",
      "95",
      "166",
      "389",
      "699",
      "1522",
      "1600",
      "1601",
      "1736",
      "1757",
      "1869"
    ]
  },
  "a6ed7aab-a56e-46b4-b20f-e3a783ab6299": {
    "team": "1610612741",
    "year": "1999",
    "players": [
      "54",
      "697",
      "713",
      "765",
      "769",
      "1074",
      "1512",
      "1736",
      "1882",
      "1913"
    ]
  },
  "67e5e245-e136-4b67-ba4d-f41d5ab61011": {
    "team": "1610612741",
    "year": "2000",
    "players": [
      "697",
      "1434",
      "1500",
      "1736",
      "1802",
      "1864",
      "1882",
      "1897",
      "2033",
      "2053",
      "2062",
      "2063"
    ]
  },
  "b6ec3c75-4153-44d9-88f4-59c364671885": {
    "team": "1610612741",
    "year": "2001",
    "players": [
      "147",
      "696",
      "697",
      "1944",
      "2033",
      "2037",
      "2053",
      "2062",
      "2199",
      "2201",
      "2239",
      "2369"
    ]
  },
  "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565": {
    "team": "1610612741",
    "year": "2002",
    "players": [
      "77",
      "147",
      "697",
      "916",
      "923",
      "1594",
      "1944",
      "2033",
      "2053",
      "2199",
      "2201",
      "2239",
      "2398",
      "2427",
      "2437"
    ]
  },
  "7a2f643b-b036-4b7b-8c60-33358df26aba": {
    "team": "1610612741",
    "year": "2003",
    "players": [
      "213",
      "383",
      "937",
      "966",
      "1944",
      "2033",
      "2037",
      "2199",
      "2201",
      "2321",
      "2423",
      "2457",
      "2550",
      "2648",
      "2669"
    ]
  },
  "c2deab32-c38d-40ff-aff3-0be69da10978": {
    "team": "1610612741",
    "year": "2004",
    "players": [
      "15",
      "213",
      "970",
      "1444",
      "1559",
      "2199",
      "2421",
      "2457",
      "2550",
      "2732",
      "2768",
      "2804",
      "2876"
    ]
  },
  "75edf884-23c5-48d9-b441-efa9c4b2aa76": {
    "team": "1610612741",
    "year": "2005",
    "players": [
      "15",
      "970",
      "988",
      "2124",
      "2199",
      "2443",
      "2457",
      "2550",
      "2552",
      "2732",
      "2736",
      "2768",
      "2804",
      "101188",
      "101195"
    ]
  },
  "b8ede009-44e0-4e12-977b-3391a1900e94": {
    "team": "1610612741",
    "year": "2006",
    "players": [
      "136",
      "1112",
      "1559",
      "2124",
      "2550",
      "2552",
      "2732",
      "2736",
      "2751",
      "2768",
      "2804",
      "2857",
      "101149",
      "200748",
      "200757"
    ]
  },
  "5d87b501-07e9-4b0d-bf0f-041d38dddfb0": {
    "team": "1610612741",
    "year": "2007",
    "players": [
      "1716",
      "2400",
      "2550",
      "2732",
      "2736",
      "2768",
      "2804",
      "200748",
      "200757",
      "200759",
      "200769",
      "201149",
      "201189",
      "201191",
      "201193"
    ]
  },
  "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a": {
    "team": "1610612741",
    "year": "2008",
    "players": [
      "283",
      "1501",
      "1744",
      "1802",
      "2422",
      "2550",
      "2669",
      "2732",
      "2736",
      "101194",
      "200748",
      "201149",
      "201189",
      "201565",
      "201634"
    ]
  },
  "9108c5c3-88a9-4172-8bf2-7dd36a671a0a": {
    "team": "1610612741",
    "year": "2009",
    "players": [
      "1802",
      "2436",
      "2457",
      "2484",
      "2550",
      "2736",
      "101124",
      "201149",
      "201151",
      "201181",
      "201565",
      "201570",
      "201633",
      "201949",
      "201959"
    ]
  },
  "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801": {
    "team": "1610612741",
    "year": "2010",
    "players": [
      "703",
      "2243",
      "2430",
      "2446",
      "2457",
      "2586",
      "2594",
      "2736",
      "101249",
      "200758",
      "201149",
      "201228",
      "201565",
      "201600",
      "201959"
    ]
  },
  "cffc2631-ee7d-4642-b929-718bf23c51fc": {
    "team": "1610612741",
    "year": "2011",
    "players": [
      "1888",
      "2229",
      "2243",
      "2430",
      "2594",
      "2736",
      "101249",
      "200758",
      "201149",
      "201228",
      "201565",
      "201600",
      "201959",
      "202710"
    ]
  },
  "24f3581c-303b-4110-b220-004a40cdaf1c": {
    "team": "1610612741",
    "year": "2012",
    "players": [
      "1737",
      "1888",
      "2209",
      "2430",
      "2550",
      "2736",
      "101126",
      "201149",
      "201158",
      "201161",
      "201565",
      "201959",
      "202710",
      "202952",
      "203104"
    ]
  },
  "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1": {
    "team": "1610612741",
    "year": "2013",
    "players": [
      "1737",
      "2229",
      "2399",
      "2430",
      "2550",
      "200758",
      "200811",
      "201149",
      "201565",
      "201571",
      "201959",
      "202690",
      "202710",
      "202962",
      "203503"
    ]
  },
  "9a0ff6b8-2d93-4e02-8176-e3dbd9343406": {
    "team": "1610612741",
    "year": "2014",
    "players": [
      "1737",
      "2200",
      "2399",
      "2550",
      "201149",
      "201166",
      "201565",
      "201959",
      "202703",
      "202710",
      "202734",
      "203503",
      "203926",
      "203946"
    ]
  },
  "94ccb42e-7317-4c67-a681-708f0dd0f117": {
    "team": "1610612741",
    "year": "2015",
    "players": [
      "2200",
      "2399",
      "201149",
      "201166",
      "201565",
      "201959",
      "202703",
      "202710",
      "202734",
      "203200",
      "203503",
      "203915",
      "203926",
      "203946",
      "1626171",
      "1626245"
    ]
  },
  "5ce05276-e599-4e4e-8ec6-40ef7f5129f2": {
    "team": "1610612741",
    "year": "2016",
    "players": [
      "2548",
      "200765",
      "201577",
      "201627",
      "202703",
      "202710",
      "203477",
      "203487",
      "203530",
      "1626166",
      "1626170",
      "1626171",
      "1626245",
      "1627756",
      "1627835"
    ]
  },
  "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5": {
    "team": "1610612741",
    "year": "2017",
    "players": [
      "201577",
      "201600",
      "203200",
      "203897",
      "203930",
      "203943",
      "204067",
      "1626166",
      "1626170",
      "1626171",
      "1626245",
      "1627739",
      "1627756",
      "1627835",
      "1627853",
      "1628021",
      "1628374",
      "1628469"
    ]
  },
  "7542de7d-8692-4f6a-a302-a389bb3d20ab": {
    "team": "1610612741",
    "year": "2018",
    "players": [
      "201577",
      "203490",
      "203897",
      "1626245",
      "1627215",
      "1627739",
      "1627756",
      "1627782",
      "1627789",
      "1627853",
      "1627885",
      "1628374",
      "1628469",
      "1628959",
      "1628976",
      "1628990",
      "1629102"
    ]
  },
  "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc": {
    "team": "1610612741",
    "year": "2019",
    "players": [
      "201152",
      "203107",
      "203490",
      "203897",
      "1626245",
      "1627739",
      "1627756",
      "1627853",
      "1627885",
      "1628374",
      "1628436",
      "1628976",
      "1628990",
      "1629622",
      "1629632",
      "1629655",
      "1629690"
    ]
  },
  "6e8363c5-182a-46ea-b472-92fa07dd14b9": {
    "team": "1610612742",
    "year": "1980",
    "players": [
      "76030",
      "76225",
      "76321",
      "76516",
      "76520",
      "76604",
      "76608",
      "77126",
      "77229",
      "77262",
      "77328",
      "77396",
      "77432",
      "77858",
      "77997",
      "78222"
    ]
  },
  "416a441e-349c-43bf-8a25-d9b8fa253a5c": {
    "team": "1610612742",
    "year": "1981",
    "players": [
      "76016",
      "76176",
      "76257",
      "76441",
      "76516",
      "77229",
      "77328",
      "77396",
      "77432",
      "77719",
      "78222",
      "78378",
      "78418"
    ]
  },
  "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9": {
    "team": "1610612742",
    "year": "1982",
    "players": [
      "76016",
      "76176",
      "76257",
      "76483",
      "76516",
      "76785",
      "77396",
      "77719",
      "77911",
      "78222",
      "78325",
      "78378",
      "78418"
    ]
  },
  "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0": {
    "team": "1610612742",
    "year": "1983",
    "players": [
      "107",
      "157",
      "770",
      "76016",
      "76176",
      "76483",
      "76516",
      "76785",
      "77719",
      "77848",
      "78222",
      "78378",
      "78418"
    ]
  },
  "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd": {
    "team": "1610612742",
    "year": "1984",
    "players": [
      "64",
      "107",
      "157",
      "76016",
      "76176",
      "76291",
      "76358",
      "76516",
      "77719",
      "78164",
      "78174",
      "78418"
    ]
  },
  "562818d9-6fdb-4cb2-b851-4748b1c638f2": {
    "team": "1610612742",
    "year": "1985",
    "players": [
      "64",
      "82",
      "96",
      "107",
      "157",
      "76016",
      "76172",
      "76176",
      "76516",
      "76583",
      "77233",
      "77719",
      "78418"
    ]
  },
  "4c8ca104-147e-44d8-9de3-0c6d6a888309": {
    "team": "1610612742",
    "year": "1986",
    "players": [
      "64",
      "82",
      "96",
      "157",
      "76016",
      "76172",
      "76176",
      "76516",
      "76583",
      "77105",
      "77738",
      "78293",
      "78610"
    ]
  },
  "d3cf8ad4-8983-4469-9c02-454ae703ad53": {
    "team": "1610612742",
    "year": "1987",
    "players": [
      "64",
      "82",
      "96",
      "157",
      "76016",
      "76024",
      "76172",
      "76176",
      "76516",
      "76583",
      "76695",
      "78293"
    ]
  },
  "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f": {
    "team": "1610612742",
    "year": "1988",
    "players": [
      "64",
      "82",
      "157",
      "1006",
      "76172",
      "76176",
      "76504",
      "76516",
      "76583",
      "77173",
      "78293",
      "78390",
      "78528"
    ]
  },
  "a4b0ca7d-300a-4686-bcc4-415123e8580a": {
    "team": "1610612742",
    "year": "1989",
    "players": [
      "64",
      "82",
      "157",
      "737",
      "1006",
      "1839",
      "76024",
      "76176",
      "76504",
      "76516",
      "76583",
      "77173",
      "78293",
      "78426"
    ]
  },
  "24a0d010-462a-4f0b-89b1-6cd920460093": {
    "team": "1610612742",
    "year": "1990",
    "players": [
      "157",
      "1006",
      "1117",
      "1622",
      "1839",
      "76024",
      "76176",
      "76516",
      "76583",
      "76673",
      "76844",
      "77376",
      "78293",
      "78394",
      "78621"
    ]
  },
  "0ac01206-d8e9-4609-aff9-f970a8acfada": {
    "team": "1610612742",
    "year": "1991",
    "players": [
      "157",
      "426",
      "475",
      "929",
      "1006",
      "1106",
      "1622",
      "1839",
      "76176",
      "76516",
      "76790",
      "77065",
      "77098",
      "77376",
      "77893",
      "78416"
    ]
  },
  "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c": {
    "team": "1610612742",
    "year": "1992",
    "players": [
      "100",
      "157",
      "399",
      "426",
      "475",
      "754",
      "929",
      "1106",
      "1603",
      "1839",
      "76097",
      "76337",
      "76488",
      "77065",
      "77098",
      "77794",
      "78273",
      "78528"
    ]
  },
  "ca88f8b4-9328-4c6c-a36c-8652a814c7a0": {
    "team": "1610612742",
    "year": "1993",
    "players": [
      "31",
      "100",
      "359",
      "399",
      "426",
      "446",
      "461",
      "469",
      "475",
      "754",
      "1068",
      "1106",
      "1839",
      "76338",
      "77376",
      "78528"
    ]
  },
  "373322f2-e185-4dfb-a02c-b13ec97d67ca": {
    "team": "1610612742",
    "year": "1994",
    "players": [
      "31",
      "45",
      "418",
      "426",
      "434",
      "446",
      "461",
      "467",
      "469",
      "475",
      "754",
      "1106",
      "78293"
    ]
  },
  "39e32802-3fe0-492c-aed8-9d212f63dd00": {
    "team": "1610612742",
    "year": "1995",
    "players": [
      "31",
      "45",
      "116",
      "418",
      "426",
      "434",
      "446",
      "461",
      "467",
      "469",
      "684",
      "685",
      "754"
    ]
  },
  "fa4a30fe-1491-4395-b8f0-25eaeaa946f4": {
    "team": "1610612742",
    "year": "1996",
    "players": [
      "123",
      "157",
      "234",
      "351",
      "390",
      "714",
      "762",
      "920",
      "982",
      "1065",
      "1068",
      "1478"
    ]
  },
  "473a3f11-84e1-44d9-ac95-2e57181d18a2": {
    "team": "1610612742",
    "year": "1997",
    "players": [
      "35",
      "76",
      "93",
      "234",
      "704",
      "714",
      "762",
      "920",
      "955",
      "982",
      "1065",
      "1512",
      "1528"
    ]
  },
  "01f231b7-fdf5-4e89-8d19-3f25d72a9528": {
    "team": "1610612742",
    "year": "1998",
    "players": [
      "73",
      "93",
      "714",
      "718",
      "762",
      "920",
      "955",
      "959",
      "1065",
      "1512",
      "1717",
      "1743"
    ]
  },
  "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b": {
    "team": "1610612742",
    "year": "1999",
    "players": [
      "76",
      "93",
      "123",
      "399",
      "714",
      "718",
      "762",
      "959",
      "1065",
      "1717",
      "1761",
      "1800"
    ]
  },
  "f8978601-057c-4dc7-a94c-b0be55678c99": {
    "team": "1610612742",
    "year": "2000",
    "players": [
      "95",
      "137",
      "436",
      "458",
      "714",
      "762",
      "959",
      "1717",
      "1761",
      "1916",
      "1917",
      "2051",
      "2059"
    ]
  },
  "080fa274-4c53-4190-ab9b-d64d6489f51f": {
    "team": "1610612742",
    "year": "2001",
    "players": [
      "89",
      "271",
      "714",
      "762",
      "959",
      "1559",
      "1711",
      "1717",
      "1761",
      "1915",
      "1917",
      "2059"
    ]
  },
  "04e3eeb2-6367-4dda-8490-df041615bb0b": {
    "team": "1610612742",
    "year": "2002",
    "players": [
      "89",
      "422",
      "461",
      "714",
      "762",
      "959",
      "1505",
      "1559",
      "1711",
      "1717",
      "1915",
      "1952",
      "1967",
      "2059",
      "2492"
    ]
  },
  "2b637960-e6ab-4aca-a17d-2aef872b9e36": {
    "team": "1610612742",
    "year": "2003",
    "players": [
      "281",
      "696",
      "714",
      "762",
      "952",
      "959",
      "960",
      "1504",
      "1505",
      "1712",
      "1717",
      "2059",
      "2572",
      "2605"
    ]
  },
  "4593ad14-6159-4388-bbcc-ff4643a59315": {
    "team": "1610612742",
    "year": "2004",
    "players": [
      "353",
      "673",
      "711",
      "714",
      "762",
      "956",
      "1496",
      "1717",
      "1891",
      "2572",
      "2605",
      "2734"
    ]
  },
  "a735f539-4e33-4e2c-a87f-c0d19a0a64eb": {
    "team": "1610612742",
    "year": "2005",
    "players": [
      "353",
      "711",
      "956",
      "1496",
      "1559",
      "1717",
      "1891",
      "2205",
      "2572",
      "2605",
      "2694",
      "2734",
      "2788"
    ]
  },
  "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2": {
    "team": "1610612742",
    "year": "2006",
    "players": [
      "711",
      "788",
      "956",
      "1506",
      "1717",
      "1761",
      "1891",
      "1904",
      "2205",
      "2572",
      "2734",
      "2788",
      "200772",
      "200822",
      "200826"
    ]
  },
  "b854aa2c-06f1-4ed4-aafa-f10552d16a5d": {
    "team": "1610612742",
    "year": "2007",
    "players": [
      "224",
      "436",
      "467",
      "711",
      "956",
      "1717",
      "1731",
      "1891",
      "1904",
      "2048",
      "2124",
      "2572",
      "101120",
      "101138",
      "200826"
    ]
  },
  "9fded93a-716c-4692-88f2-bcae0e1464b2": {
    "team": "1610612742",
    "year": "2008",
    "players": [
      "467",
      "711",
      "956",
      "1717",
      "1891",
      "1904",
      "2572",
      "2679",
      "101120",
      "101123",
      "101138",
      "101189",
      "200761",
      "200797",
      "200826"
    ]
  },
  "b700ceb6-53ed-4084-89f3-5a24e516486e": {
    "team": "1610612742",
    "year": "2009",
    "players": [
      "467",
      "956",
      "1501",
      "1717",
      "1890",
      "1891",
      "2052",
      "2059",
      "2217",
      "2406",
      "2679",
      "200826",
      "201958"
    ]
  },
  "d12bf9aa-8464-4fb7-9918-3c36a769c1b0": {
    "team": "1610612742",
    "year": "2010",
    "players": [
      "467",
      "978",
      "1717",
      "1890",
      "1891",
      "2052",
      "2073",
      "2199",
      "2217",
      "2406",
      "101133",
      "200826",
      "201147",
      "201958",
      "202346"
    ]
  },
  "3bb057eb-42b5-49f7-ba1d-267e8181cf1a": {
    "team": "1610612742",
    "year": "2011",
    "players": [
      "467",
      "1713",
      "1717",
      "1885",
      "1890",
      "1891",
      "2073",
      "2217",
      "2753",
      "101133",
      "101235",
      "201146",
      "201148",
      "201958",
      "202346"
    ]
  },
  "25084fac-f6f9-4c54-b21c-e94d6295d687": {
    "team": "1610612742",
    "year": "2012",
    "players": [
      "1713",
      "1717",
      "1882",
      "1890",
      "2229",
      "2549",
      "201148",
      "201564",
      "201627",
      "201954",
      "201958",
      "203006",
      "203099",
      "203108",
      "203109"
    ]
  },
  "fa0bce98-f205-4a4b-ad13-66dfb26beb39": {
    "team": "1610612742",
    "year": "2013",
    "players": [
      "1713",
      "1717",
      "1890",
      "2199",
      "2734",
      "101109",
      "101145",
      "201148",
      "201971",
      "203108",
      "203109",
      "203495",
      "203539"
    ]
  },
  "d78fca6a-85ea-4d8f-ad24-43938ce73ff8": {
    "team": "1610612742",
    "year": "2014",
    "players": [
      "1717",
      "2199",
      "2210",
      "2405",
      "2734",
      "101109",
      "101111",
      "101145",
      "200765",
      "200826",
      "202329",
      "202718",
      "202962",
      "203108",
      "203939"
    ]
  },
  "2e619fa8-fe19-4df4-a834-a6ba21ebf87a": {
    "team": "1610612742",
    "year": "2015",
    "players": [
      "1717",
      "2585",
      "2734",
      "101109",
      "101111",
      "101114",
      "101135",
      "200826",
      "201580",
      "202083",
      "202379",
      "202718",
      "203939",
      "1626147",
      "1626257"
    ]
  },
  "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668": {
    "team": "1610612742",
    "year": "2016",
    "players": [
      "1717",
      "2734",
      "200826",
      "202083",
      "202412",
      "202732",
      "203084",
      "203457",
      "203552",
      "203939",
      "1626257",
      "1627773",
      "1627784",
      "1627812",
      "1627827",
      "1627852"
    ]
  },
  "ee481f46-7270-4ab6-8086-5a707f5f85d4": {
    "team": "1610612742",
    "year": "2017",
    "players": [
      "1717",
      "200826",
      "202083",
      "203084",
      "203457",
      "203552",
      "203926",
      "203939",
      "1626151",
      "1626257",
      "1627812",
      "1627827",
      "1627858",
      "1627883",
      "1628372",
      "1628405",
      "1628467"
    ]
  },
  "52b4e695-cf53-49a6-8a86-ff4295a423ce": {
    "team": "1610612742",
    "year": "2018",
    "players": [
      "1717",
      "2734",
      "200826",
      "201584",
      "203501",
      "203504",
      "203939",
      "204001",
      "1626257",
      "1627827",
      "1628382",
      "1628467",
      "1628961",
      "1628973",
      "1629029",
      "1629133",
      "1629151"
    ]
  },
  "55f52854-053e-40c1-a39d-85ba78a61d32": {
    "team": "1610612742",
    "year": "2019",
    "players": [
      "200826",
      "201584",
      "203077",
      "203501",
      "203552",
      "203939",
      "204001",
      "1626153",
      "1626161",
      "1626246",
      "1627827",
      "1628382",
      "1628467",
      "1628499",
      "1628973",
      "1629029",
      "1629729"
    ]
  },
  "7d5d2b23-3811-4485-8ebe-5300180b8967": {
    "team": "1610612743",
    "year": "1976",
    "players": [
      "76134",
      "76336",
      "77097",
      "77193",
      "77510",
      "77883",
      "78151",
      "78302",
      "78326",
      "78361",
      "78481",
      "78602"
    ]
  },
  "66743650-e493-4c91-a3ab-5a488a063464": {
    "team": "1610612743",
    "year": "1977",
    "players": [
      "76336",
      "76437",
      "76664",
      "77097",
      "77193",
      "77328",
      "77975",
      "78204",
      "78296",
      "78326",
      "78531"
    ]
  },
  "2db8a2e7-5351-4109-ac43-ae331e73b4b4": {
    "team": "1610612743",
    "year": "1978",
    "players": [
      "76213",
      "76474",
      "76664",
      "77017",
      "77078",
      "77097",
      "77320",
      "77532",
      "77975",
      "78097",
      "78204",
      "78326",
      "78531"
    ]
  },
  "a6bd7a55-926b-4045-a20f-fdda4c48cc0f": {
    "team": "1610612743",
    "year": "1979",
    "players": [
      "76664",
      "76673",
      "76781",
      "76830",
      "77078",
      "77097",
      "77148",
      "77306",
      "77975",
      "78000",
      "78097",
      "78326",
      "78531"
    ]
  },
  "d941e1a4-3192-4944-be68-b1cbdacc880a": {
    "team": "1610612743",
    "year": "1980",
    "players": [
      "76618",
      "76673",
      "76830",
      "77021",
      "77056",
      "77097",
      "77547",
      "77757",
      "77920",
      "77998",
      "78000",
      "78326",
      "78396",
      "78404"
    ]
  },
  "051f86ce-ff57-4132-8a18-d40f37418bfc": {
    "team": "1610612743",
    "year": "1981",
    "players": [
      "76307",
      "76618",
      "76673",
      "76830",
      "77021",
      "77056",
      "77097",
      "77547",
      "77920",
      "77998",
      "78000",
      "78326",
      "78404"
    ]
  },
  "13d70b6b-6bf3-44f1-810b-e54da749cc7e": {
    "team": "1610612743",
    "year": "1982",
    "players": [
      "7",
      "76037",
      "76618",
      "76673",
      "76686",
      "76830",
      "76944",
      "77097",
      "77547",
      "77920",
      "77998",
      "78404",
      "78565"
    ]
  },
  "606a6691-6b18-478d-b60f-85baee1be70c": {
    "team": "1610612743",
    "year": "1983",
    "players": [
      "7",
      "76045",
      "76358",
      "76556",
      "76618",
      "76638",
      "76673",
      "76686",
      "76944",
      "77097",
      "77975",
      "78404",
      "78565"
    ]
  },
  "92aca97c-c546-4b12-80d7-3f47b2556f23": {
    "team": "1610612743",
    "year": "1984",
    "players": [
      "7",
      "76441",
      "76618",
      "76673",
      "76686",
      "76944",
      "77097",
      "77300",
      "77376",
      "77691",
      "78378",
      "78515"
    ]
  },
  "c5a1816a-9035-49ea-8a46-28d38f029f5a": {
    "team": "1610612743",
    "year": "1985",
    "players": [
      "7",
      "76441",
      "76618",
      "76673",
      "76686",
      "76944",
      "77376",
      "77691",
      "78378",
      "78515",
      "78566",
      "78656"
    ]
  },
  "3b2774a2-1ea5-4cf1-a5ac-e638f710782f": {
    "team": "1610612743",
    "year": "1986",
    "players": [
      "7",
      "76019",
      "76441",
      "76618",
      "76673",
      "76686",
      "76944",
      "77376",
      "77476",
      "77691",
      "78198",
      "78437",
      "78566",
      "78656"
    ]
  },
  "294fc5dc-cadb-457c-849a-7a994aeedbda": {
    "team": "1610612743",
    "year": "1987",
    "players": [
      "7",
      "149",
      "76266",
      "76441",
      "76618",
      "76673",
      "76686",
      "76944",
      "77376",
      "77476",
      "77691",
      "78418",
      "78620",
      "78656"
    ]
  },
  "e5a6a74e-32e5-4965-829e-f866fe21f504": {
    "team": "1610612743",
    "year": "1988",
    "players": [
      "7",
      "149",
      "1453",
      "76435",
      "76441",
      "76671",
      "76673",
      "76881",
      "76944",
      "77077",
      "77335",
      "77376",
      "78378",
      "78656"
    ]
  },
  "242ef09c-2ea9-49d1-8d5b-8638d520230e": {
    "team": "1610612743",
    "year": "1989",
    "players": [
      "7",
      "149",
      "1118",
      "1453",
      "1848",
      "76353",
      "76618",
      "76673",
      "76944",
      "77077",
      "77335",
      "77376",
      "77387",
      "78656"
    ]
  },
  "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5": {
    "team": "1610612743",
    "year": "1990",
    "players": [
      "51",
      "100",
      "149",
      "193",
      "199",
      "246",
      "341",
      "76123",
      "76433",
      "76618",
      "76695",
      "76770",
      "77335",
      "77386",
      "77387",
      "77694",
      "78615",
      "78656"
    ]
  },
  "37167a67-466f-4920-b036-1481c8665cf9": {
    "team": "1610612743",
    "year": "1991",
    "players": [
      "51",
      "87",
      "88",
      "199",
      "246",
      "341",
      "1453",
      "1855",
      "76265",
      "76433",
      "76782",
      "76968",
      "77386",
      "77387"
    ]
  },
  "5f767836-141f-43d2-8d66-70390ca5907c": {
    "team": "1610612743",
    "year": "1992",
    "players": [
      "51",
      "67",
      "87",
      "111",
      "123",
      "179",
      "199",
      "438",
      "1855",
      "76265",
      "76968",
      "77386",
      "77387",
      "77865"
    ]
  },
  "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8": {
    "team": "1610612743",
    "year": "1993",
    "players": [
      "51",
      "67",
      "87",
      "111",
      "123",
      "179",
      "183",
      "199",
      "915",
      "1577",
      "1817",
      "1821",
      "76265",
      "76695",
      "77460"
    ]
  },
  "6595b285-a439-44cb-85e6-c9a6a5292df4": {
    "team": "1610612743",
    "year": "1994",
    "players": [
      "51",
      "67",
      "87",
      "107",
      "111",
      "123",
      "147",
      "179",
      "183",
      "199",
      "285",
      "695",
      "915",
      "1073",
      "1577",
      "1821",
      "77377"
    ]
  },
  "04ade0a1-b5eb-49bd-872d-e46e23abcb67": {
    "team": "1610612743",
    "year": "1995",
    "players": [
      "51",
      "67",
      "77",
      "87",
      "107",
      "111",
      "147",
      "179",
      "199",
      "285",
      "686",
      "687",
      "900",
      "931",
      "1010"
    ]
  },
  "f6c05285-c034-4b22-912e-eb9157727aad": {
    "team": "1610612743",
    "year": "1996",
    "players": [
      "67",
      "107",
      "179",
      "181",
      "240",
      "355",
      "678",
      "686",
      "706",
      "728",
      "911",
      "924"
    ]
  },
  "444e98c3-fcb5-48e2-8abd-64b23dedcf83": {
    "team": "1610612743",
    "year": "1997",
    "players": [
      "111",
      "179",
      "271",
      "724",
      "924",
      "968",
      "1051",
      "1499",
      "1504",
      "1517",
      "1540",
      "1677"
    ]
  },
  "77b80143-e8fe-4061-a904-4d6d527f123c": {
    "team": "1610612743",
    "year": "1998",
    "players": [
      "61",
      "89",
      "179",
      "677",
      "684",
      "686",
      "724",
      "1497",
      "1504",
      "1511",
      "1540",
      "1820"
    ]
  },
  "75827e5b-1c4c-4a63-8f07-fc61069a6e50": {
    "team": "1610612743",
    "year": "1999",
    "players": [
      "45",
      "89",
      "461",
      "686",
      "724",
      "1711",
      "1721",
      "1763",
      "1899",
      "1914",
      "78009"
    ]
  },
  "cd5e6171-eb18-4ed7-b180-d060bc1fd918": {
    "team": "1610612743",
    "year": "2000",
    "players": [
      "45",
      "89",
      "123",
      "426",
      "686",
      "702",
      "788",
      "924",
      "1711",
      "1763",
      "1899",
      "2082"
    ]
  },
  "42000ca1-2c10-44de-ab6f-9b981adc311a": {
    "team": "1610612743",
    "year": "2001",
    "players": [
      "45",
      "384",
      "436",
      "702",
      "1763",
      "1899",
      "1985",
      "2051",
      "2261",
      "2306",
      "2365",
      "2370"
    ]
  },
  "2d910a29-a4be-43ee-bfe0-3c9cd2201494": {
    "team": "1610612743",
    "year": "2002",
    "players": [
      "45",
      "239",
      "436",
      "948",
      "1548",
      "1763",
      "1899",
      "1985",
      "2051",
      "2206",
      "2261",
      "2365",
      "2401",
      "2403",
      "2428",
      "2453",
      "2454"
    ]
  },
  "e98d32ee-a54c-44c0-a2d4-de81113efba0": {
    "team": "1610612743",
    "year": "2003",
    "players": [
      "468",
      "702",
      "948",
      "998",
      "1720",
      "1763",
      "1863",
      "1889",
      "1922",
      "2206",
      "2244",
      "2365",
      "2401",
      "2403",
      "2546"
    ]
  },
  "b8701d1f-d5cd-4826-b63a-3977c7671cd9": {
    "team": "1610612743",
    "year": "2004",
    "players": [
      "445",
      "702",
      "935",
      "948",
      "998",
      "1761",
      "1863",
      "1889",
      "1922",
      "2030",
      "2035",
      "2059",
      "2403",
      "2546",
      "2784"
    ]
  },
  "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f": {
    "team": "1610612743",
    "year": "2005",
    "players": [
      "458",
      "948",
      "1739",
      "1761",
      "1863",
      "1889",
      "1922",
      "2030",
      "2035",
      "2059",
      "2403",
      "2501",
      "2546",
      "101125",
      "101132"
    ]
  },
  "8da357d5-a704-4099-97e4-f09887e5cf25": {
    "team": "1610612743",
    "year": "2006",
    "players": [
      "947",
      "948",
      "1853",
      "2030",
      "2035",
      "2059",
      "2403",
      "2441",
      "2501",
      "2546",
      "2581",
      "2747",
      "101132",
      "101144",
      "200821"
    ]
  },
  "49864ef2-1c25-4439-86f9-86f5b097ecf2": {
    "team": "1610612743",
    "year": "2007",
    "players": [
      "947",
      "948",
      "1088",
      "1853",
      "2030",
      "2059",
      "2212",
      "2403",
      "2546",
      "2747",
      "101132",
      "200784",
      "200821",
      "201192"
    ]
  },
  "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae": {
    "team": "1610612743",
    "year": "2008",
    "players": [
      "1497",
      "1853",
      "2030",
      "2078",
      "2212",
      "2365",
      "2403",
      "2546",
      "2563",
      "2747",
      "101130",
      "101132",
      "200764",
      "201603"
    ]
  },
  "a8d64aaf-36e8-451d-9ece-f80d97529ff5": {
    "team": "1610612743",
    "year": "2009",
    "players": [
      "1497",
      "1853",
      "2030",
      "2124",
      "2365",
      "2403",
      "2546",
      "2747",
      "101121",
      "101130",
      "200764",
      "201167",
      "201207",
      "201951",
      "202221"
    ]
  },
  "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b": {
    "team": "1610612743",
    "year": "2010",
    "players": [
      "1733",
      "2030",
      "2365",
      "2403",
      "2408",
      "2747",
      "101109",
      "201163",
      "201167",
      "201568",
      "201585",
      "201814",
      "201951",
      "202389"
    ]
  },
  "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d": {
    "team": "1610612743",
    "year": "2011",
    "players": [
      "1733",
      "1889",
      "2365",
      "201147",
      "201163",
      "201164",
      "201167",
      "201568",
      "201580",
      "201585",
      "201951",
      "202389",
      "202702",
      "202706",
      "202933"
    ]
  },
  "4bddbc82-c340-415e-a90b-60892b06c970": {
    "team": "1610612743",
    "year": "2012",
    "players": [
      "1889",
      "2738",
      "201147",
      "201163",
      "201568",
      "201576",
      "201580",
      "201585",
      "201951",
      "202389",
      "202702",
      "202706",
      "202933",
      "203095",
      "203113"
    ]
  },
  "927197ae-18fb-4e93-9274-396b0b0eceeb": {
    "team": "1610612743",
    "year": "2013",
    "players": [
      "101126",
      "200751",
      "201163",
      "201166",
      "201568",
      "201576",
      "201580",
      "201581",
      "201589",
      "201951",
      "202389",
      "202686",
      "202702",
      "203095",
      "203113"
    ]
  },
  "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f": {
    "team": "1610612743",
    "year": "2014",
    "players": [
      "2749",
      "200751",
      "201163",
      "201568",
      "201581",
      "201589",
      "201951",
      "202702",
      "203115",
      "203475",
      "203479",
      "203530",
      "203546",
      "203914",
      "203994"
    ]
  },
  "8b352059-4d32-4f24-b88d-cfa95665790a": {
    "team": "1610612743",
    "year": "2015",
    "players": [
      "2034",
      "2749",
      "201163",
      "201568",
      "201571",
      "201589",
      "202702",
      "203115",
      "203530",
      "203914",
      "203960",
      "203994",
      "203999",
      "1626144",
      "1626253"
    ]
  },
  "2cb80819-95d7-430d-89e8-20ee10cc90af": {
    "team": "1610612743",
    "year": "2016",
    "players": [
      "2034",
      "2749",
      "201163",
      "201568",
      "201579",
      "201589",
      "202702",
      "203115",
      "203486",
      "203914",
      "203999",
      "1626144",
      "1627736",
      "1627750",
      "1627823"
    ]
  },
  "4d75880a-40ba-475a-9d0d-58175849b671": {
    "team": "1610612743",
    "year": "2017",
    "players": [
      "2210",
      "2734",
      "200794",
      "201163",
      "201589",
      "202702",
      "203115",
      "203486",
      "203914",
      "203999",
      "1626168",
      "1627736",
      "1627750",
      "1627823",
      "1628399",
      "1628420",
      "1628470"
    ]
  },
  "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9": {
    "team": "1610612743",
    "year": "2018",
    "players": [
      "200794",
      "202738",
      "203115",
      "203486",
      "203914",
      "203999",
      "1626168",
      "1627736",
      "1627750",
      "1627823",
      "1628399",
      "1628420",
      "1628470",
      "1629008",
      "1629020",
      "1629118",
      "1629164"
    ]
  },
  "dbd3455e-60ba-4d50-9b96-353f912b371a": {
    "team": "1610612743",
    "year": "2019",
    "players": [
      "200794",
      "203115",
      "203486",
      "203584",
      "203914",
      "203924",
      "203943",
      "203999",
      "1627750",
      "1628408",
      "1628420",
      "1628427",
      "1628470",
      "1628966",
      "1629008",
      "1629626"
    ]
  },
  "fee19330-42a6-4e11-9702-950d58c8a50c": {
    "team": "1610612744",
    "year": "1946",
    "players": [
      "76498",
      "76721",
      "76764",
      "76909",
      "77027",
      "77677",
      "78017",
      "78043",
      "78115",
      "78131"
    ]
  },
  "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a": {
    "team": "1610612744",
    "year": "1947",
    "players": [
      "76285",
      "76479",
      "76498",
      "76721",
      "76764",
      "77027",
      "77221",
      "77677",
      "77744",
      "78002",
      "78115"
    ]
  },
  "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb": {
    "team": "1610612744",
    "year": "1948",
    "players": [
      "76171",
      "76207",
      "76479",
      "76498",
      "76721",
      "76764",
      "77642",
      "77677",
      "78043",
      "78050",
      "78115"
    ]
  },
  "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388": {
    "team": "1610612744",
    "year": "1949",
    "players": [
      "76190",
      "76207",
      "76479",
      "76721",
      "76764",
      "76779",
      "77379",
      "77622",
      "77724",
      "77736",
      "77804",
      "78043",
      "78115"
    ]
  },
  "78cf5ce6-637f-459d-812a-47c6b4615dc4": {
    "team": "1610612744",
    "year": "1950",
    "players": [
      "76056",
      "76190",
      "76209",
      "76406",
      "76764",
      "76779",
      "77392",
      "77590",
      "77622",
      "77797",
      "77853",
      "78115"
    ]
  },
  "1c190e61-1a8b-4cd1-aba2-fae468830890": {
    "team": "1610612744",
    "year": "1951",
    "players": [
      "76056",
      "76190",
      "76285",
      "76298",
      "76496",
      "76764",
      "76779",
      "77169",
      "77590",
      "77824",
      "77853",
      "78115"
    ]
  },
  "9398ab8b-2109-490e-8b37-d2bbaadb361f": {
    "team": "1610612744",
    "year": "1952",
    "players": [
      "76190",
      "76717",
      "76721",
      "76764",
      "77169",
      "77319",
      "77402",
      "77512",
      "77619",
      "77631",
      "77780",
      "77868",
      "77900",
      "78115",
      "78616"
    ]
  },
  "2a1981fa-3157-4dfe-84dc-dc914f881f5f": {
    "team": "1610612744",
    "year": "1953",
    "players": [
      "76136",
      "76536",
      "76717",
      "76764",
      "76800",
      "76838",
      "76886",
      "77169",
      "77849",
      "78115",
      "78447",
      "78639"
    ]
  },
  "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86": {
    "team": "1610612744",
    "year": "1954",
    "players": [
      "76056",
      "76246",
      "76454",
      "76536",
      "76555",
      "76717",
      "76800",
      "76838",
      "77036",
      "77169",
      "77230",
      "77634",
      "77675",
      "78639"
    ]
  },
  "05b98b66-c377-4196-b63f-7c9749f98f47": {
    "team": "1610612744",
    "year": "1955",
    "players": [
      "76056",
      "76136",
      "76536",
      "76555",
      "76800",
      "76828",
      "76838",
      "76998",
      "77169",
      "77634"
    ]
  },
  "59113a13-7bcb-4a63-908c-a566386976eb": {
    "team": "1610612744",
    "year": "1956",
    "players": [
      "76056",
      "76061",
      "76136",
      "76454",
      "76536",
      "76555",
      "76800",
      "76838",
      "77032",
      "77169",
      "77352",
      "77634"
    ]
  },
  "eca4ab58-7709-4f1a-a4b0-4ac2cf730447": {
    "team": "1610612744",
    "year": "1957",
    "players": [
      "76056",
      "76136",
      "76536",
      "76555",
      "76617",
      "76800",
      "76828",
      "76838",
      "77169",
      "77901",
      "78018",
      "78064",
      "78446"
    ]
  },
  "dfaf5774-af9e-4334-82a0-3587b2230530": {
    "team": "1610612744",
    "year": "1958",
    "players": [
      "76056",
      "76136",
      "76800",
      "76828",
      "76838",
      "76969",
      "77131",
      "77169",
      "78003",
      "78013",
      "78018",
      "78064"
    ]
  },
  "16181ef3-9a5e-4560-bbac-f30072f1a572": {
    "team": "1610612744",
    "year": "1959",
    "players": [
      "76056",
      "76136",
      "76375",
      "76828",
      "76838",
      "76969",
      "77131",
      "78003",
      "78040",
      "78064",
      "78223"
    ]
  },
  "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae": {
    "team": "1610612744",
    "year": "1960",
    "players": [
      "76056",
      "76070",
      "76375",
      "76429",
      "76828",
      "76838",
      "76969",
      "77131",
      "77245",
      "78003",
      "78040"
    ]
  },
  "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5": {
    "team": "1610612744",
    "year": "1961",
    "players": [
      "76056",
      "76070",
      "76375",
      "76429",
      "76828",
      "77339",
      "77421",
      "77584",
      "77899",
      "78003",
      "78040"
    ]
  },
  "60508db9-69f9-4133-ac4d-b9848b2add2d": {
    "team": "1610612744",
    "year": "1962",
    "players": [
      "76070",
      "76375",
      "76697",
      "76906",
      "77023",
      "77327",
      "77359",
      "77421",
      "77584",
      "77630",
      "77693",
      "77855",
      "78003",
      "78106",
      "78509"
    ]
  },
  "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad": {
    "team": "1610612744",
    "year": "1963",
    "players": [
      "76070",
      "76375",
      "77023",
      "77026",
      "77359",
      "77584",
      "77855",
      "78003",
      "78106",
      "78591",
      "600001"
    ]
  },
  "6530f527-58ae-467d-8504-53e63dd6c84e": {
    "team": "1610612744",
    "year": "1964",
    "players": [
      "76070",
      "76375",
      "76566",
      "77023",
      "77299",
      "77359",
      "77548",
      "77584",
      "77687",
      "77706",
      "77855",
      "78003",
      "78037",
      "600001"
    ]
  },
  "754f9d27-7573-442d-8f17-5aeb6cb61e54": {
    "team": "1610612744",
    "year": "1965",
    "players": [
      "76070",
      "76680",
      "76751",
      "77012",
      "77548",
      "77584",
      "77706",
      "77765",
      "77855",
      "78003",
      "600001",
      "600013"
    ]
  },
  "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b": {
    "team": "1610612744",
    "year": "1966",
    "players": [
      "76070",
      "76661",
      "77012",
      "77270",
      "77357",
      "77359",
      "77584",
      "77662",
      "77706",
      "77765",
      "78459",
      "600001",
      "600013"
    ]
  },
  "46106409-18d2-4f10-9158-50537c42c5f5": {
    "team": "1610612744",
    "year": "1967",
    "players": [
      "76070",
      "76661",
      "77012",
      "77270",
      "77340",
      "77342",
      "77357",
      "77359",
      "77385",
      "77662",
      "78385",
      "78459",
      "600001"
    ]
  },
  "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e": {
    "team": "1610612744",
    "year": "1968",
    "players": [
      "76028",
      "76070",
      "76661",
      "77270",
      "77340",
      "77357",
      "77385",
      "77662",
      "78084",
      "78385",
      "78567",
      "600001"
    ]
  },
  "19c148e8-3989-4f5a-8bdd-b388c4a1defe": {
    "team": "1610612744",
    "year": "1969",
    "players": [
      "76070",
      "76661",
      "76774",
      "77357",
      "77385",
      "77418",
      "77662",
      "77878",
      "78084",
      "78178",
      "78567",
      "600001"
    ]
  },
  "852d8746-a465-41f3-b644-085c99bbad27": {
    "team": "1610612744",
    "year": "1970",
    "players": [
      "76070",
      "76661",
      "76731",
      "77195",
      "77357",
      "77418",
      "77662",
      "77748",
      "77878",
      "78178",
      "78385",
      "78567",
      "600001"
    ]
  },
  "0f779b88-44c7-47eb-84fd-fd9043bbd2cd": {
    "team": "1610612744",
    "year": "1971",
    "players": [
      "76029",
      "76106",
      "76118",
      "76661",
      "77195",
      "77357",
      "77662",
      "77878",
      "78045",
      "78385",
      "78567",
      "600001"
    ]
  },
  "ecb02e00-4b70-45b1-b216-cabefbc881e4": {
    "team": "1610612744",
    "year": "1972",
    "players": [
      "76106",
      "76661",
      "77133",
      "77149",
      "77357",
      "77662",
      "77878",
      "78045",
      "78567",
      "600001",
      "600013"
    ]
  },
  "6f1b3ca5-9585-4d10-afb2-d34d80dca864": {
    "team": "1610612744",
    "year": "1973",
    "players": [
      "76106",
      "76130",
      "76564",
      "76661",
      "77133",
      "77149",
      "77357",
      "77662",
      "78045",
      "600001",
      "600013"
    ]
  },
  "c1880d6b-19f4-45d3-9d7e-564fe009bff1": {
    "team": "1610612744",
    "year": "1974",
    "players": [
      "76130",
      "76226",
      "76564",
      "76607",
      "77133",
      "77149",
      "77242",
      "77662",
      "77917",
      "78199",
      "78532",
      "600013"
    ]
  },
  "edf26e64-d881-43d8-a1c3-c5951c5cf796": {
    "team": "1610612744",
    "year": "1975",
    "players": [
      "76521",
      "76564",
      "76607",
      "76975",
      "77133",
      "77149",
      "77662",
      "77917",
      "78199",
      "78532",
      "78549",
      "600013"
    ]
  },
  "a41a6363-1cad-4f4f-9d67-2ab15fc75444": {
    "team": "1610612744",
    "year": "1976",
    "players": [
      "305",
      "76521",
      "76564",
      "76607",
      "77133",
      "77800",
      "77917",
      "78008",
      "78199",
      "78532",
      "78549",
      "600013"
    ]
  },
  "79486fdf-1032-41a7-b0fd-7bc9c309f79e": {
    "team": "1610612744",
    "year": "1977",
    "players": [
      "305",
      "76413",
      "76465",
      "76607",
      "76874",
      "77133",
      "77465",
      "77800",
      "77917",
      "78199",
      "78561",
      "600013"
    ]
  },
  "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10": {
    "team": "1610612744",
    "year": "1978",
    "players": [
      "305",
      "76005",
      "76441",
      "76465",
      "76678",
      "77419",
      "77800",
      "77917",
      "77983",
      "78139",
      "78199",
      "78365",
      "78510",
      "78561"
    ]
  },
  "1f05489e-b5ab-49f4-acea-e8f96d192bfc": {
    "team": "1610612744",
    "year": "1979",
    "players": [
      "305",
      "76005",
      "76441",
      "76457",
      "77028",
      "77159",
      "77419",
      "77800",
      "77917",
      "78139",
      "78199",
      "78365",
      "78510",
      "78587"
    ]
  },
  "6af54433-9189-4d97-aafc-8c3d7350a043": {
    "team": "1610612744",
    "year": "1980",
    "players": [
      "76283",
      "76353",
      "76386",
      "76753",
      "76966",
      "77264",
      "77419",
      "77495",
      "77580",
      "77800",
      "77917",
      "77937",
      "78015",
      "78139",
      "78195"
    ]
  },
  "b865787d-a59a-4abd-b1ce-ce5c96a411d9": {
    "team": "1610612744",
    "year": "1981",
    "players": [
      "76283",
      "76353",
      "76753",
      "76771",
      "76966",
      "77264",
      "77395",
      "77527",
      "77800",
      "78015",
      "78139",
      "78195",
      "78569"
    ]
  },
  "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0": {
    "team": "1610612744",
    "year": "1982",
    "players": [
      "76249",
      "76353",
      "76431",
      "76608",
      "76670",
      "76726",
      "76966",
      "77168",
      "77395",
      "78015",
      "78139",
      "78183",
      "78195",
      "78569"
    ]
  },
  "aea08569-655f-4acc-8b71-27160b0a4dad": {
    "team": "1610612744",
    "year": "1983",
    "players": [
      "76242",
      "76353",
      "76418",
      "76431",
      "76478",
      "76670",
      "76726",
      "77168",
      "77456",
      "78139",
      "78195",
      "78343"
    ]
  },
  "d4f7a688-d761-4a62-88cf-2893af24c272": {
    "team": "1610612744",
    "year": "1984",
    "players": [
      "76021",
      "76242",
      "76316",
      "76431",
      "76726",
      "77168",
      "77865",
      "78139",
      "78195",
      "78304",
      "78311",
      "78516",
      "78578"
    ]
  },
  "e8f07ede-458f-46ff-8d3c-a054940f687f": {
    "team": "1610612744",
    "year": "1985",
    "players": [
      "904",
      "76091",
      "76353",
      "76431",
      "76726",
      "77087",
      "78139",
      "78195",
      "78304",
      "78311",
      "78414",
      "78516",
      "78550"
    ]
  },
  "105e54a0-ada0-4f0c-a038-906cd453091e": {
    "team": "1610612744",
    "year": "1986",
    "players": [
      "904",
      "76091",
      "76353",
      "76726",
      "76994",
      "77019",
      "77524",
      "77654",
      "78139",
      "78181",
      "78195",
      "78304",
      "78463",
      "78516"
    ]
  },
  "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d": {
    "team": "1610612744",
    "year": "1987",
    "players": [
      "904",
      "76703",
      "76748",
      "76782",
      "76960",
      "77019",
      "77053",
      "77524",
      "78055",
      "78195",
      "78198",
      "78304",
      "78426",
      "78467",
      "78514",
      "78516"
    ]
  },
  "14984cdc-30be-46d0-8591-dfe9b9b7c482": {
    "team": "1610612744",
    "year": "1988",
    "players": [
      "317",
      "782",
      "904",
      "76024",
      "76195",
      "76748",
      "76782",
      "76841",
      "77019",
      "77524",
      "78055",
      "78195",
      "78198",
      "78271",
      "78304"
    ]
  },
  "607283b5-4d8a-4cbf-8512-75892fe92cdf": {
    "team": "1610612744",
    "year": "1989",
    "players": [
      "36",
      "175",
      "782",
      "896",
      "904",
      "1091",
      "1117",
      "1846",
      "76195",
      "77019",
      "77160",
      "77839",
      "78301",
      "78304",
      "78347",
      "78394"
    ]
  },
  "f45e82f9-7cbe-41dc-85ce-85176585f132": {
    "team": "1610612744",
    "year": "1990",
    "players": [
      "36",
      "53",
      "175",
      "238",
      "340",
      "355",
      "782",
      "896",
      "904",
      "77019",
      "77129",
      "77134",
      "77294",
      "77623",
      "77839",
      "78347"
    ]
  },
  "3b5de1dd-634c-4faf-b5fe-5a55420cd942": {
    "team": "1610612744",
    "year": "1991",
    "players": [
      "36",
      "53",
      "146",
      "175",
      "182",
      "238",
      "355",
      "423",
      "763",
      "896",
      "904",
      "1091",
      "1114",
      "1631",
      "2349",
      "76123",
      "77019",
      "77839",
      "78347"
    ]
  },
  "4d8dc0a0-9b77-4771-ad3e-4861ded80060": {
    "team": "1610612744",
    "year": "1992",
    "players": [
      "24",
      "36",
      "81",
      "84",
      "146",
      "175",
      "182",
      "238",
      "423",
      "780",
      "896",
      "904",
      "925",
      "1090",
      "1432",
      "2349",
      "77879",
      "78227",
      "78251"
    ]
  },
  "59cf984a-acd5-4aea-83fa-fa9f1638b97c": {
    "team": "1610612744",
    "year": "1993",
    "players": [
      "24",
      "81",
      "84",
      "146",
      "182",
      "185",
      "422",
      "423",
      "725",
      "780",
      "904",
      "2349",
      "76852",
      "77674"
    ]
  },
  "92f101b9-7456-437b-8d91-9d5618b0b994": {
    "team": "1610612744",
    "year": "1994",
    "players": [
      "24",
      "32",
      "84",
      "100",
      "116",
      "132",
      "423",
      "435",
      "894",
      "896",
      "904",
      "938",
      "943",
      "2349",
      "76195",
      "77019"
    ]
  },
  "28e4506b-c071-48bb-9f41-64bf7ff44c90": {
    "team": "1610612744",
    "year": "1995",
    "players": [
      "32",
      "84",
      "468",
      "692",
      "693",
      "760",
      "769",
      "788",
      "902",
      "903",
      "904",
      "923",
      "928",
      "938"
    ]
  },
  "3c0f756a-f6f9-4ef2-80d8-1cac30649502": {
    "team": "1610612744",
    "year": "1996",
    "players": [
      "84",
      "197",
      "280",
      "511",
      "691",
      "692",
      "693",
      "899",
      "904",
      "923",
      "957",
      "986",
      "1077"
    ]
  },
  "0aa87910-76b7-4782-a081-a241102c4730": {
    "team": "1610612744",
    "year": "1997",
    "players": [
      "81",
      "84",
      "177",
      "221",
      "273",
      "280",
      "679",
      "754",
      "956",
      "957",
      "960",
      "986",
      "1502",
      "1666"
    ]
  },
  "5cd72c30-3fd8-4449-bb2e-f890d4bb6095": {
    "team": "1610612744",
    "year": "1998",
    "players": [
      "168",
      "177",
      "187",
      "273",
      "280",
      "317",
      "902",
      "923",
      "956",
      "960",
      "1502",
      "1712"
    ]
  },
  "2f7ada02-daaa-431d-b4e1-61d409829b71": {
    "team": "1610612744",
    "year": "1999",
    "players": [
      "182",
      "223",
      "302",
      "679",
      "707",
      "923",
      "1108",
      "1502",
      "1716",
      "1734",
      "1907",
      "1937"
    ]
  },
  "f21a72e6-4c46-422e-9b29-b3a098dc7b62": {
    "team": "1610612744",
    "year": "2000",
    "players": [
      "228",
      "302",
      "682",
      "916",
      "956",
      "1502",
      "1531",
      "1612",
      "1712",
      "1716",
      "1907",
      "2084",
      "2101"
    ]
  },
  "89160d0f-db62-4529-acb6-4226a6796989": {
    "team": "1610612744",
    "year": "2001",
    "players": [
      "168",
      "682",
      "956",
      "1051",
      "1502",
      "1504",
      "1712",
      "1716",
      "2202",
      "2211",
      "2240",
      "2352"
    ]
  },
  "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0": {
    "team": "1610612744",
    "year": "2002",
    "players": [
      "168",
      "292",
      "682",
      "956",
      "1502",
      "1504",
      "1712",
      "1747",
      "2202",
      "2211",
      "2240",
      "2352",
      "2399",
      "2412"
    ]
  },
  "919b0c55-1add-47b4-99b3-0c5f42a02455": {
    "team": "1610612744",
    "year": "2003",
    "players": [
      "89",
      "361",
      "384",
      "422",
      "461",
      "956",
      "1502",
      "1915",
      "2049",
      "2073",
      "2202",
      "2211",
      "2399",
      "2452",
      "2554"
    ]
  },
  "ef222756-c1ac-4a73-95d8-003390f0ea1c": {
    "team": "1610612744",
    "year": "2004",
    "players": [
      "384",
      "965",
      "1502",
      "1884",
      "2202",
      "2206",
      "2211",
      "2399",
      "2401",
      "2554",
      "2560",
      "2740"
    ]
  },
  "4d430473-658d-4cd6-9454-184fc4fa8b60": {
    "team": "1610612744",
    "year": "2005",
    "players": [
      "384",
      "965",
      "1502",
      "1884",
      "2202",
      "2211",
      "2399",
      "2554",
      "2560",
      "2740",
      "101113",
      "101145",
      "101147",
      "101198"
    ]
  },
  "3d8f99eb-b0ef-4035-9fac-39a301a315c8": {
    "team": "1610612744",
    "year": "2006",
    "players": [
      "1502",
      "1536",
      "1733",
      "1884",
      "2202",
      "2440",
      "2554",
      "2560",
      "2694",
      "2740",
      "101145",
      "101180",
      "101235",
      "200753"
    ]
  },
  "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860": {
    "team": "1610612744",
    "year": "2007",
    "players": [
      "1506",
      "1536",
      "1733",
      "1884",
      "2440",
      "2554",
      "2740",
      "101145",
      "101235",
      "200753",
      "200785",
      "201148",
      "201158",
      "201228"
    ]
  },
  "6d70f6ee-362f-4ae7-b814-24b533c75c04": {
    "team": "1610612744",
    "year": "2008",
    "players": [
      "1536",
      "1894",
      "2037",
      "2740",
      "101142",
      "101145",
      "101235",
      "201148",
      "201158",
      "201176",
      "201228",
      "201576",
      "201627",
      "201633"
    ]
  },
  "a51334eb-1216-409a-a077-121ff993d69c": {
    "team": "1610612744",
    "year": "2009",
    "players": [
      "1894",
      "1904",
      "2209",
      "2740",
      "101142",
      "101145",
      "101235",
      "201148",
      "201228",
      "201229",
      "201576",
      "201627",
      "201805",
      "201939",
      "202130"
    ]
  },
  "728d43da-a0f1-4599-96c2-a09036340618": {
    "team": "1610612744",
    "year": "2010",
    "players": [
      "2209",
      "2294",
      "2740",
      "2748",
      "101135",
      "101145",
      "200811",
      "201151",
      "201154",
      "201939",
      "202130",
      "202327",
      "202391",
      "202399"
    ]
  },
  "044b28cb-9356-44d5-8389-fa87b0ad75da": {
    "team": "1610612744",
    "year": "2011",
    "players": [
      "1630",
      "2210",
      "2740",
      "2748",
      "101106",
      "101126",
      "101135",
      "201187",
      "201575",
      "201939",
      "202148",
      "202691",
      "202719",
      "202724",
      "202874"
    ]
  },
  "674c9b5e-4ae4-466f-ae8c-c27783858243": {
    "team": "1610612744",
    "year": "2012",
    "players": [
      "2210",
      "2740",
      "101106",
      "101127",
      "101135",
      "101204",
      "201171",
      "201575",
      "201939",
      "202691",
      "203084",
      "203105",
      "203110",
      "203145",
      "203159"
    ]
  },
  "1b0e3844-8de5-43dd-98a8-06dfafc12e5a": {
    "team": "1610612744",
    "year": "2013",
    "players": [
      "979",
      "2581",
      "2738",
      "101106",
      "101135",
      "200756",
      "201578",
      "201939",
      "202348",
      "202691",
      "203084",
      "203105",
      "203110",
      "203136",
      "203517"
    ]
  },
  "3be5e5e4-3153-4f95-920a-943fe574edb9": {
    "team": "1610612744",
    "year": "2014",
    "players": [
      "2571",
      "2733",
      "2738",
      "101106",
      "101135",
      "201575",
      "201578",
      "201939",
      "202691",
      "203084",
      "203105",
      "203110",
      "203136",
      "203200",
      "203949"
    ]
  },
  "db39856a-8bb1-4ea4-9ef1-1e99ff028d42": {
    "team": "1610612744",
    "year": "2015",
    "players": [
      "2571",
      "2733",
      "2738",
      "2760",
      "101106",
      "201575",
      "201578",
      "201939",
      "202691",
      "203084",
      "203105",
      "203110",
      "203546",
      "203949",
      "1626172"
    ]
  },
  "815c2c83-c122-4c09-ab9b-0807af4014bd": {
    "team": "1610612744",
    "year": "2016",
    "players": [
      "2440",
      "2561",
      "2585",
      "2733",
      "2738",
      "201142",
      "201580",
      "201939",
      "202691",
      "203110",
      "203546",
      "203949",
      "1626172",
      "1627745",
      "1627775"
    ]
  },
  "39e73052-29ff-45ac-b1b9-e4a6a187b0ca": {
    "team": "1610612744",
    "year": "2017",
    "players": [
      "2561",
      "2585",
      "2733",
      "2738",
      "201142",
      "201156",
      "201580",
      "201939",
      "202691",
      "203110",
      "1626172",
      "1626188",
      "1627745",
      "1627775",
      "1628395"
    ]
  },
  "e8c56c8c-df2c-4465-923b-c47a35c6de01": {
    "team": "1610612744",
    "year": "2018",
    "players": [
      "2733",
      "2738",
      "101106",
      "201142",
      "201939",
      "201973",
      "202326",
      "202691",
      "203110",
      "1626172",
      "1626188",
      "1627745",
      "1627814",
      "1628035",
      "1628395",
      "1628980",
      "1629094"
    ]
  },
  "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e": {
    "team": "1610612744",
    "year": "2019",
    "players": [
      "201939",
      "202691",
      "203110",
      "203952",
      "1626172",
      "1626184",
      "1627733",
      "1627737",
      "1627814",
      "1628539",
      "1629065",
      "1629308",
      "1629346",
      "1629672",
      "1629673"
    ]
  },
  "e511f2f0-158b-44a1-90e9-a101165211e2": {
    "team": "1610612745",
    "year": "1967",
    "players": [
      "76010",
      "76106",
      "76108",
      "76187",
      "76258",
      "76716",
      "76774",
      "76867",
      "77195",
      "77259",
      "77295",
      "77533",
      "77962",
      "78457",
      "78539"
    ]
  },
  "fd689085-06a6-45c4-ac3c-bdfe79bd037d": {
    "team": "1610612745",
    "year": "1968",
    "players": [
      "76015",
      "76103",
      "76106",
      "76187",
      "76716",
      "76979",
      "77259",
      "77295",
      "77338",
      "77962",
      "78367",
      "78539"
    ]
  },
  "9bd3bcb9-0ee5-437b-bd21-a473bad44eea": {
    "team": "1610612745",
    "year": "1969",
    "players": [
      "76015",
      "76106",
      "76187",
      "76979",
      "77259",
      "77295",
      "77338",
      "77962",
      "78202",
      "78367",
      "78539",
      "78540"
    ]
  },
  "84e31c1f-82ce-462e-bbce-b0b659005594": {
    "team": "1610612745",
    "year": "1970",
    "players": [
      "76012",
      "76187",
      "76647",
      "76979",
      "77259",
      "77338",
      "77669",
      "77835",
      "78147",
      "78350",
      "78367",
      "78540"
    ]
  },
  "92ed15bb-e4b9-4237-b321-f82f5e329c1a": {
    "team": "1610612745",
    "year": "1971",
    "players": [
      "76012",
      "76485",
      "76647",
      "76807",
      "76979",
      "77338",
      "77548",
      "77570",
      "77669",
      "77710",
      "77835",
      "78190",
      "78350",
      "78397"
    ]
  },
  "69ac3cf7-6042-40b1-b780-0bea2ee84e0a": {
    "team": "1610612745",
    "year": "1972",
    "players": [
      "76002",
      "77147",
      "77463",
      "77517",
      "77543",
      "77567",
      "77570",
      "77636",
      "77669",
      "77710",
      "78350",
      "78439"
    ]
  },
  "298aa605-b35d-4f1f-978f-4f4883ddee16": {
    "team": "1610612745",
    "year": "1973",
    "players": [
      "76002",
      "76413",
      "77147",
      "77323",
      "77463",
      "77517",
      "77543",
      "77570",
      "77669",
      "77710",
      "77914",
      "77964",
      "78350",
      "78606"
    ]
  },
  "3f4d6adc-f325-4204-a5ca-f7eccb585194": {
    "team": "1610612745",
    "year": "1974",
    "players": [
      "76002",
      "76083",
      "76971",
      "77323",
      "77570",
      "77669",
      "77710",
      "77914",
      "77964",
      "78350",
      "78488",
      "78606"
    ]
  },
  "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe": {
    "team": "1610612745",
    "year": "1975",
    "players": [
      "76083",
      "77152",
      "77323",
      "77582",
      "77669",
      "77710",
      "77914",
      "77964",
      "78350",
      "78513",
      "78606"
    ]
  },
  "1f08be28-cf75-4838-8319-73b4c61b0c71": {
    "team": "1610612745",
    "year": "1976",
    "players": [
      "77017",
      "77152",
      "77182",
      "77243",
      "77323",
      "77419",
      "77449",
      "77669",
      "77710",
      "77786",
      "77914",
      "78350",
      "78513"
    ]
  },
  "bcf8abc7-2e95-4058-8cd4-c9c3751281ff": {
    "team": "1610612745",
    "year": "1977",
    "players": [
      "76198",
      "76229",
      "76616",
      "77152",
      "77182",
      "77194",
      "77323",
      "77325",
      "77419",
      "77449",
      "77621",
      "77669",
      "77710",
      "77914",
      "77936",
      "78350",
      "78513"
    ]
  },
  "766b4adc-1011-4526-90b5-c29ba8adc757": {
    "team": "1610612745",
    "year": "1978",
    "players": [
      "76229",
      "76413",
      "76587",
      "76616",
      "77182",
      "77449",
      "77669",
      "77710",
      "77936",
      "78350",
      "78473",
      "600013"
    ]
  },
  "08b96232-bec8-4c70-a55c-7f13e94a9137": {
    "team": "1610612745",
    "year": "1979",
    "players": [
      "76229",
      "76616",
      "76996",
      "77182",
      "77189",
      "77353",
      "77449",
      "77623",
      "77669",
      "77936",
      "78350",
      "78513",
      "600013"
    ]
  },
  "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8": {
    "team": "1610612745",
    "year": "1980",
    "players": [
      "76616",
      "76786",
      "76996",
      "77189",
      "77353",
      "77449",
      "77669",
      "77817",
      "77936",
      "78274",
      "78350",
      "78577"
    ]
  },
  "c949c9e9-fb17-443f-b682-96f8c34ca982": {
    "team": "1610612745",
    "year": "1981",
    "players": [
      "76616",
      "76786",
      "76979",
      "76996",
      "77189",
      "77353",
      "77449",
      "77669",
      "77757",
      "77817",
      "77936",
      "78233",
      "78577"
    ]
  },
  "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a": {
    "team": "1610612745",
    "year": "1982",
    "players": [
      "76085",
      "76290",
      "76786",
      "76979",
      "76996",
      "77175",
      "77189",
      "77353",
      "77669",
      "77707",
      "78299",
      "78304",
      "78442"
    ]
  },
  "03cc21be-0761-49ef-83d1-c28b52282177": {
    "team": "1610612745",
    "year": "1983",
    "players": [
      "378",
      "1622",
      "76085",
      "76736",
      "76979",
      "77175",
      "77189",
      "77353",
      "77395",
      "77936",
      "78055",
      "78304",
      "78442"
    ]
  },
  "4b873a4c-c9af-4bf4-b246-ebccbf734299": {
    "team": "1610612745",
    "year": "1984",
    "players": [
      "165",
      "378",
      "1622",
      "76736",
      "77043",
      "77353",
      "77395",
      "77419",
      "77527",
      "77839",
      "77936",
      "78055",
      "78523"
    ]
  },
  "742ebe4a-3448-4659-b32c-a6aa0ea7cb64": {
    "team": "1610612745",
    "year": "1985",
    "players": [
      "165",
      "378",
      "1622",
      "76960",
      "77353",
      "77395",
      "77419",
      "77527",
      "77839",
      "77936",
      "78055",
      "78430",
      "78523"
    ]
  },
  "6cadde58-cc80-4e96-af67-6d093657445c": {
    "team": "1610612745",
    "year": "1986",
    "players": [
      "165",
      "1622",
      "76045",
      "76703",
      "76960",
      "77130",
      "77353",
      "77395",
      "77487",
      "77609",
      "77839",
      "77936",
      "78055",
      "78523"
    ]
  },
  "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8": {
    "team": "1610612745",
    "year": "1987",
    "players": [
      "165",
      "1622",
      "76353",
      "76431",
      "76726",
      "76753",
      "77130",
      "77353",
      "77487",
      "77839",
      "77936",
      "78139",
      "78377"
    ]
  },
  "79d8e657-ce27-4052-9492-c9833808173f": {
    "team": "1610612745",
    "year": "1988",
    "players": [
      "165",
      "901",
      "76158",
      "76387",
      "76726",
      "77130",
      "77146",
      "77353",
      "77516",
      "77707",
      "78139",
      "78323",
      "78614"
    ]
  },
  "955db219-1fe5-405f-a607-095000d7ba49": {
    "team": "1610612745",
    "year": "1989",
    "players": [
      "137",
      "154",
      "165",
      "194",
      "901",
      "76574",
      "76726",
      "77130",
      "77395",
      "77419",
      "77516",
      "77707",
      "78195",
      "78523",
      "78614"
    ]
  },
  "08d8a2af-aeb5-46c8-b3e3-f630b3519727": {
    "team": "1610612745",
    "year": "1990",
    "players": [
      "116",
      "137",
      "154",
      "165",
      "181",
      "672",
      "901",
      "76703",
      "76726",
      "77114",
      "77130",
      "78195",
      "78589"
    ]
  },
  "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9": {
    "team": "1610612745",
    "year": "1991",
    "players": [
      "61",
      "137",
      "165",
      "181",
      "422",
      "672",
      "901",
      "1819",
      "76726",
      "76827",
      "77114",
      "77130",
      "78014",
      "78195"
    ]
  },
  "026c485f-5e1e-4793-b4e8-1970ad2c7536": {
    "team": "1610612745",
    "year": "1992",
    "players": [
      "61",
      "109",
      "137",
      "165",
      "181",
      "418",
      "672",
      "901",
      "76726",
      "76782",
      "78014",
      "78304",
      "78589"
    ]
  },
  "a98c4621-6e20-4474-b8b9-b92e12d377b0": {
    "team": "1610612745",
    "year": "1993",
    "players": [
      "35",
      "53",
      "61",
      "109",
      "137",
      "165",
      "181",
      "208",
      "418",
      "672",
      "901",
      "940",
      "1137",
      "1683",
      "77846"
    ]
  },
  "8cc5342c-8019-4957-bedc-7eced9f39f49": {
    "team": "1610612745",
    "year": "1994",
    "players": [
      "17",
      "53",
      "61",
      "109",
      "137",
      "145",
      "154",
      "160",
      "165",
      "181",
      "208",
      "279",
      "359",
      "432",
      "440"
    ]
  },
  "6c851fc6-72f6-46a0-9760-a9d43dd79674": {
    "team": "1610612745",
    "year": "1995",
    "players": [
      "17",
      "53",
      "95",
      "109",
      "160",
      "165",
      "181",
      "208",
      "279",
      "359",
      "432",
      "511",
      "694",
      "695",
      "929",
      "1080",
      "1114",
      "76987"
    ]
  },
  "f4fda864-3f52-4244-b98b-9f40cdbfafc6": {
    "team": "1610612745",
    "year": "1996",
    "players": [
      "9",
      "17",
      "53",
      "165",
      "279",
      "672",
      "698",
      "787",
      "788",
      "970",
      "988",
      "1074"
    ]
  },
  "4bfccb84-ee2c-48d6-9279-43235ccb5f4f": {
    "team": "1610612745",
    "year": "1997",
    "players": [
      "17",
      "53",
      "85",
      "165",
      "279",
      "672",
      "698",
      "787",
      "788",
      "970",
      "1074",
      "1518"
    ]
  },
  "f9d171e9-8890-47d7-bbfa-313297290f40": {
    "team": "1610612745",
    "year": "1998",
    "players": [
      "85",
      "156",
      "165",
      "672",
      "694",
      "787",
      "914",
      "937",
      "970",
      "1722",
      "1724",
      "1749"
    ]
  },
  "a97884c1-58e9-4a89-9737-96f55a224730": {
    "team": "1610612745",
    "year": "1999",
    "players": [
      "292",
      "435",
      "672",
      "787",
      "983",
      "1000",
      "1005",
      "1509",
      "1749",
      "1883",
      "1903"
    ]
  },
  "e502556e-6647-4877-a1dc-fa3278e8fa26": {
    "team": "1610612745",
    "year": "2000",
    "players": [
      "165",
      "435",
      "672",
      "983",
      "1000",
      "1005",
      "1509",
      "1749",
      "1883",
      "1903",
      "2061",
      "2173"
    ]
  },
  "75f68605-ac26-42aa-a606-d75bdf9ed219": {
    "team": "1610612745",
    "year": "2001",
    "players": [
      "788",
      "983",
      "1509",
      "1749",
      "1883",
      "1903",
      "2044",
      "2061",
      "2204",
      "2242",
      "2351",
      "2357"
    ]
  },
  "1263d642-0f8f-47dd-b80b-8930a1814b1e": {
    "team": "1610612745",
    "year": "2002",
    "players": [
      "779",
      "983",
      "1038",
      "1508",
      "1509",
      "1749",
      "1864",
      "1883",
      "1903",
      "2044",
      "2204",
      "2242",
      "2351",
      "2357",
      "2397",
      "2411",
      "2432"
    ]
  },
  "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51": {
    "team": "1610612745",
    "year": "2003",
    "players": [
      "15",
      "221",
      "349",
      "754",
      "891",
      "1508",
      "1509",
      "1559",
      "1749",
      "1883",
      "1909",
      "2366",
      "2397",
      "2411"
    ]
  },
  "34e936dc-3545-4fdb-ae48-4f0caf8342cc": {
    "team": "1610612745",
    "year": "2004",
    "players": [
      "87",
      "133",
      "221",
      "452",
      "468",
      "682",
      "983",
      "1503",
      "1763",
      "1765",
      "1909",
      "2229",
      "2397"
    ]
  },
  "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef": {
    "team": "1610612745",
    "year": "2005",
    "players": [
      "87",
      "133",
      "436",
      "682",
      "1503",
      "1594",
      "1747",
      "1763",
      "2031",
      "2397",
      "2499",
      "2573",
      "2586",
      "101129",
      "101236"
    ]
  },
  "4852e234-5b8e-4b03-b656-686bfe7506c0": {
    "team": "1610612745",
    "year": "2006",
    "players": [
      "87",
      "682",
      "1503",
      "1719",
      "1747",
      "2054",
      "2203",
      "2397",
      "2745",
      "2779",
      "101129",
      "101236",
      "101249",
      "200779"
    ]
  },
  "1eea79e8-edd3-4788-a937-608905bafdf4": {
    "team": "1610612745",
    "year": "2007",
    "players": [
      "87",
      "1503",
      "1517",
      "1747",
      "1883",
      "2203",
      "2254",
      "2397",
      "2449",
      "101129",
      "101236",
      "200779",
      "200839",
      "201166",
      "201171"
    ]
  },
  "c5f83588-1194-41d6-980b-c021b4b2ba98": {
    "team": "1610612745",
    "year": "2008",
    "players": [
      "87",
      "699",
      "1503",
      "1897",
      "2203",
      "2397",
      "2449",
      "2567",
      "101144",
      "101236",
      "200768",
      "200778",
      "201166",
      "201171",
      "201595"
    ]
  },
  "8632a477-9924-4d11-859a-78889ddfee93": {
    "team": "1610612745",
    "year": "2009",
    "players": [
      "2203",
      "2397",
      "2407",
      "2431",
      "2449",
      "2755",
      "2772",
      "101236",
      "200768",
      "200792",
      "200839",
      "201166",
      "201941",
      "201966",
      "201978"
    ]
  },
  "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b": {
    "team": "1610612745",
    "year": "2010",
    "players": [
      "1802",
      "2397",
      "2449",
      "2755",
      "101236",
      "200768",
      "201584",
      "201609",
      "201934",
      "201941",
      "201944",
      "201978",
      "202335",
      "202392",
      "202408"
    ]
  },
  "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a": {
    "team": "1610612745",
    "year": "2011",
    "players": [
      "948",
      "1863",
      "2223",
      "2449",
      "2755",
      "200768",
      "201584",
      "201609",
      "201978",
      "202067",
      "202335",
      "202622",
      "202694",
      "202718",
      "202962"
    ]
  },
  "3d4a6009-0dcb-4aaf-a836-e4c80e32d963": {
    "team": "1610612745",
    "year": "2012",
    "players": [
      "2568",
      "101128",
      "201166",
      "201600",
      "201935",
      "201976",
      "202341",
      "202391",
      "202700",
      "202718",
      "202962",
      "203080",
      "203091",
      "203093",
      "203317"
    ]
  },
  "d5cb7b5e-338a-4f62-9ad0-415963cc682f": {
    "team": "1610612745",
    "year": "2013",
    "players": [
      "2694",
      "2730",
      "101128",
      "201600",
      "201935",
      "201956",
      "201976",
      "202391",
      "202700",
      "202706",
      "202718",
      "203093",
      "203477",
      "203496",
      "203584"
    ]
  },
  "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee": {
    "team": "1610612745",
    "year": "2014",
    "players": [
      "1891",
      "2730",
      "2746",
      "2772",
      "201147",
      "201595",
      "201935",
      "201976",
      "202700",
      "203093",
      "203123",
      "203143",
      "203909",
      "203910",
      "203991"
    ]
  },
  "887509b8-d63d-40e8-8a65-4a266146bdb2": {
    "team": "1610612745",
    "year": "2015",
    "players": [
      "1891",
      "2730",
      "2746",
      "2772",
      "201147",
      "201563",
      "201935",
      "201976",
      "202700",
      "202726",
      "203093",
      "203909",
      "203991",
      "1626149",
      "1626155"
    ]
  },
  "4eca3491-dad7-43db-bf8d-73ff082df4f0": {
    "team": "1610612745",
    "year": "2016",
    "players": [
      "2403",
      "2772",
      "101150",
      "201569",
      "201583",
      "201628",
      "201935",
      "201976",
      "203991",
      "1626149",
      "1626155",
      "1627778",
      "1627786",
      "1627787",
      "1627819"
    ]
  },
  "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d": {
    "team": "1610612745",
    "year": "2017",
    "players": [
      "2207",
      "2403",
      "2772",
      "101108",
      "101123",
      "200782",
      "201569",
      "201583",
      "201601",
      "201935",
      "203900",
      "203991",
      "204028",
      "1626154",
      "1627753",
      "1627778",
      "1628935"
    ]
  },
  "7555fde4-17ee-408f-a2cb-c48414c8073d": {
    "team": "1610612745",
    "year": "2018",
    "players": [
      "2403",
      "101108",
      "101123",
      "200782",
      "201569",
      "201935",
      "202697",
      "202702",
      "203085",
      "203991",
      "1626187",
      "1627863",
      "1628392",
      "1628979",
      "1629053",
      "1629109",
      "1629185"
    ]
  },
  "07d89fb9-f2fc-4a5a-b886-c540634f94a9": {
    "team": "1610612745",
    "year": "2019",
    "players": [
      "2199",
      "200757",
      "200782",
      "201145",
      "201566",
      "201569",
      "201935",
      "201960",
      "203085",
      "203463",
      "203496",
      "203998",
      "1626187",
      "1627863",
      "1628392",
      "1629598",
      "1629739"
    ]
  },
  "94f7f595-0ac0-4c93-8c34-bc941216950a": {
    "team": "1610612746",
    "year": "1970",
    "players": [
      "76219",
      "76289",
      "76466",
      "76531",
      "76788",
      "76815",
      "77061",
      "77080",
      "77227",
      "77406",
      "77427",
      "77489",
      "78152",
      "78460",
      "78579"
    ]
  },
  "e5b7f06b-9021-490e-b017-00f08519f3d1": {
    "team": "1610612746",
    "year": "1971",
    "players": [
      "76289",
      "76376",
      "76531",
      "76788",
      "76983",
      "77029",
      "77061",
      "77080",
      "77227",
      "78188",
      "78200",
      "78460"
    ]
  },
  "683a627e-6110-4e8e-890b-ab8162df16d5": {
    "team": "1610612746",
    "year": "1972",
    "players": [
      "76746",
      "76788",
      "76983",
      "77013",
      "77029",
      "77080",
      "77227",
      "77297",
      "77498",
      "78188",
      "78200",
      "78606"
    ]
  },
  "a7302500-52fe-4776-937c-0c2c59b29346": {
    "team": "1610612746",
    "year": "1973",
    "players": [
      "76383",
      "76568",
      "76793",
      "76908",
      "76985",
      "77227",
      "77428",
      "77498",
      "77554",
      "78039",
      "78200",
      "78592"
    ]
  },
  "e172999e-d18d-439a-83cc-746ead7f5c56": {
    "team": "1610612746",
    "year": "1974",
    "players": [
      "76383",
      "76568",
      "76957",
      "76985",
      "77463",
      "77498",
      "77554",
      "78039",
      "78084",
      "78200",
      "78485",
      "78592"
    ]
  },
  "1704d9ad-eb88-4144-8369-9d87109b6bf6": {
    "team": "1610612746",
    "year": "1975",
    "players": [
      "76012",
      "76383",
      "76568",
      "76807",
      "76985",
      "77316",
      "77498",
      "77553",
      "77554",
      "78084",
      "78200",
      "78466",
      "78485"
    ]
  },
  "62960c2f-47a2-40f2-8e56-42aae6c8f50f": {
    "team": "1610612746",
    "year": "1976",
    "players": [
      "76002",
      "76012",
      "76076",
      "76504",
      "76568",
      "76740",
      "76802",
      "76806",
      "77149",
      "77705",
      "78143",
      "78200",
      "78547"
    ]
  },
  "0c82b7b8-0084-4eef-82c7-89296e03c73e": {
    "team": "1610612746",
    "year": "1977",
    "players": [
      "76076",
      "76104",
      "76263",
      "76822",
      "77156",
      "77201",
      "77290",
      "77396",
      "77510",
      "77523",
      "77561",
      "77689",
      "77782",
      "78200",
      "78547",
      "78577"
    ]
  },
  "b667b56e-60ab-48af-874e-fcef6ceccd70": {
    "team": "1610612746",
    "year": "1978",
    "players": [
      "76165",
      "76753",
      "77323",
      "77689",
      "77729",
      "77760",
      "77858",
      "78200",
      "78296",
      "78467",
      "78475",
      "78516",
      "78520",
      "78548"
    ]
  },
  "ef880e29-8409-4464-a009-ac827384a450": {
    "team": "1610612746",
    "year": "1979",
    "players": [
      "76104",
      "76290",
      "76352",
      "76753",
      "77689",
      "77760",
      "77858",
      "78202",
      "78296",
      "78450",
      "78475",
      "78520",
      "78548"
    ]
  },
  "5cf2bd86-cf09-4347-b1d0-6a95eb905528": {
    "team": "1610612746",
    "year": "1980",
    "players": [
      "76162",
      "76266",
      "76290",
      "76534",
      "76985",
      "77689",
      "77882",
      "77910",
      "78199",
      "78296",
      "78516",
      "78520",
      "78548"
    ]
  },
  "de2e838d-3259-430d-8634-0759e68893b8": {
    "team": "1610612746",
    "year": "1981",
    "players": [
      "1472",
      "76262",
      "76266",
      "76290",
      "76471",
      "76534",
      "76589",
      "77024",
      "77363",
      "77689",
      "78191",
      "78199",
      "78296",
      "78516",
      "78527",
      "78548",
      "78610"
    ]
  },
  "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e": {
    "team": "1610612746",
    "year": "1982",
    "players": [
      "187",
      "1472",
      "76045",
      "76262",
      "76266",
      "76443",
      "76589",
      "76895",
      "77034",
      "77043",
      "77203",
      "77635",
      "77689",
      "78450",
      "78516",
      "78610"
    ]
  },
  "364bdc09-f2ed-4033-8fe4-88cf3367864b": {
    "team": "1610612746",
    "year": "1983",
    "players": [
      "187",
      "894",
      "76266",
      "76583",
      "77034",
      "77203",
      "77239",
      "77401",
      "77527",
      "77547",
      "77721",
      "78183",
      "78364",
      "78450",
      "78512",
      "78516"
    ]
  },
  "52a6b4e1-d155-47b0-9819-f0cc15bc07d5": {
    "team": "1610612746",
    "year": "1984",
    "players": [
      "262",
      "76252",
      "76365",
      "76583",
      "76641",
      "76834",
      "77160",
      "77670",
      "77721",
      "78183",
      "78450",
      "78462",
      "78512",
      "78538"
    ]
  },
  "88744ceb-db9d-450d-9e19-5e7aaa7cae90": {
    "team": "1610612746",
    "year": "1985",
    "players": [
      "104",
      "262",
      "76252",
      "76291",
      "76476",
      "76641",
      "76834",
      "77160",
      "77191",
      "77487",
      "77670",
      "77721",
      "78183",
      "78320",
      "78512",
      "78532"
    ]
  },
  "a8878857-2e27-4452-a526-4d80a3f6b73a": {
    "team": "1610612746",
    "year": "1986",
    "players": [
      "104",
      "262",
      "940",
      "1118",
      "76497",
      "76599",
      "76713",
      "76834",
      "77087",
      "77160",
      "77166",
      "77866",
      "78395",
      "78512",
      "78614"
    ]
  },
  "24f004b8-d504-43af-a4c2-a15d3b8e382d": {
    "team": "1610612746",
    "year": "1987",
    "players": [
      "80",
      "104",
      "199",
      "262",
      "341",
      "940",
      "1123",
      "76316",
      "76415",
      "76497",
      "76599",
      "76713",
      "76834",
      "76884",
      "77674",
      "77852",
      "78395",
      "78507",
      "78614"
    ]
  },
  "03a36ce1-3d63-4f28-b362-56b1356f738d": {
    "team": "1610612746",
    "year": "1988",
    "players": [
      "80",
      "104",
      "199",
      "202",
      "293",
      "330",
      "341",
      "941",
      "76094",
      "76497",
      "76790",
      "76831",
      "77398",
      "77721",
      "78016",
      "78507",
      "78556",
      "78654"
    ]
  },
  "26c5d00c-d04d-45ae-bc04-83d0ee557440": {
    "team": "1610612746",
    "year": "1989",
    "players": [
      "80",
      "104",
      "166",
      "202",
      "293",
      "330",
      "341",
      "76094",
      "76292",
      "76643",
      "76782",
      "76790",
      "76960",
      "77372",
      "77474",
      "77545",
      "77970",
      "78634"
    ]
  },
  "abe1ba5f-8fba-4201-8de8-4454bbecb4ea": {
    "team": "1610612746",
    "year": "1990",
    "players": [
      "80",
      "166",
      "178",
      "202",
      "293",
      "330",
      "919",
      "1091",
      "76090",
      "76094",
      "76320",
      "76782",
      "76790",
      "77260",
      "77474"
    ]
  },
  "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5": {
    "team": "1610612746",
    "year": "1991",
    "players": [
      "80",
      "166",
      "178",
      "202",
      "229",
      "293",
      "330",
      "470",
      "919",
      "1034",
      "76448",
      "77260",
      "77493",
      "77970",
      "78633"
    ]
  },
  "41a9044f-c2f4-4848-8f9e-8db6346645c0": {
    "team": "1610612746",
    "year": "1992",
    "players": [
      "80",
      "166",
      "202",
      "330",
      "349",
      "771",
      "914",
      "919",
      "1010",
      "1114",
      "76431",
      "78404",
      "78464",
      "78554"
    ]
  },
  "ccf16096-3596-4978-bbc4-14e41d80d693": {
    "team": "1610612746",
    "year": "1993",
    "players": [
      "143",
      "166",
      "202",
      "349",
      "448",
      "771",
      "914",
      "919",
      "1010",
      "1080",
      "1122",
      "1677",
      "76016",
      "77478",
      "78347",
      "78554"
    ]
  },
  "ca789ab7-ce93-4361-be31-0d5042cf56ae": {
    "team": "1610612746",
    "year": "1994",
    "players": [
      "15",
      "35",
      "71",
      "143",
      "202",
      "441",
      "448",
      "763",
      "771",
      "900",
      "907",
      "919",
      "1010",
      "1677",
      "77478",
      "78197"
    ]
  },
  "44875f13-0e20-4679-bbfd-0c88cc9ba953": {
    "team": "1610612746",
    "year": "1995",
    "players": [
      "15",
      "71",
      "143",
      "176",
      "183",
      "441",
      "448",
      "699",
      "700",
      "907",
      "914",
      "915",
      "919",
      "78403"
    ]
  },
  "02141ffc-f48a-44c1-a9a2-9e7ddf471927": {
    "team": "1610612746",
    "year": "1996",
    "players": [
      "15",
      "71",
      "143",
      "239",
      "316",
      "404",
      "441",
      "448",
      "699",
      "907",
      "915",
      "919",
      "953"
    ]
  },
  "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1": {
    "team": "1610612746",
    "year": "1997",
    "players": [
      "71",
      "239",
      "381",
      "441",
      "915",
      "953",
      "1037",
      "1134",
      "1508",
      "1520",
      "1530",
      "1569"
    ]
  },
  "73ff1242-2442-4b77-acdb-1e2fc0f74294": {
    "team": "1610612746",
    "year": "1998",
    "players": [
      "15",
      "428",
      "441",
      "915",
      "953",
      "1508",
      "1520",
      "1569",
      "1607",
      "1709",
      "1730",
      "1838"
    ]
  },
  "54990428-f851-46a0-94d9-186a7dbc0229": {
    "team": "1610612746",
    "year": "1999",
    "players": [
      "15",
      "138",
      "432",
      "976",
      "1507",
      "1508",
      "1564",
      "1569",
      "1709",
      "1838",
      "1869",
      "1885"
    ]
  },
  "d8ba1838-f94f-4127-8b11-5e9cd4635536": {
    "team": "1610612746",
    "year": "2000",
    "players": [
      "15",
      "399",
      "400",
      "685",
      "976",
      "1569",
      "1709",
      "1730",
      "1885",
      "1985",
      "2032",
      "2039",
      "2047"
    ]
  },
  "0bb46113-7e43-49e4-80fe-a23bbd15d43c": {
    "team": "1610612746",
    "year": "2001",
    "players": [
      "15",
      "77",
      "976",
      "1709",
      "1762",
      "1863",
      "1882",
      "1894",
      "1918",
      "1942",
      "2032",
      "2047"
    ]
  },
  "6d6518fd-faa9-4fbd-991f-20932543250e": {
    "team": "1610612746",
    "year": "2002",
    "players": [
      "15",
      "179",
      "399",
      "685",
      "1535",
      "1709",
      "1762",
      "1882",
      "1885",
      "1889",
      "1894",
      "2039",
      "2047",
      "2060",
      "2404",
      "2408",
      "2471"
    ]
  },
  "a4087b28-f9f0-4681-9283-5ad95ce7d650": {
    "team": "1610612746",
    "year": "2003",
    "players": [
      "77",
      "1542",
      "1882",
      "1894",
      "2039",
      "2047",
      "2060",
      "2067",
      "2250",
      "2404",
      "2408",
      "2440",
      "2549"
    ]
  },
  "1e6ac4af-bcdd-401d-b757-21a9d0087883": {
    "team": "1610612746",
    "year": "2004",
    "players": [
      "1442",
      "1594",
      "1630",
      "1882",
      "2055",
      "2060",
      "2250",
      "2404",
      "2549",
      "2624",
      "2733",
      "2763"
    ]
  },
  "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8": {
    "team": "1610612746",
    "year": "2005",
    "players": [
      "208",
      "452",
      "962",
      "1442",
      "1749",
      "1882",
      "1894",
      "2209",
      "2549",
      "2624",
      "2733",
      "101137",
      "101189"
    ]
  },
  "4cb979b9-ca00-4b88-8661-de8bde01b339": {
    "team": "1610612746",
    "year": "2006",
    "players": [
      "208",
      "1425",
      "1501",
      "1749",
      "1882",
      "1894",
      "2078",
      "2549",
      "2624",
      "2733",
      "101117",
      "101137",
      "101189",
      "101215",
      "200781"
    ]
  },
  "a522fda6-7649-4704-9b9f-262e60317e8a": {
    "team": "1610612746",
    "year": "2007",
    "players": [
      "1501",
      "1510",
      "1749",
      "1882",
      "1894",
      "2424",
      "2470",
      "2549",
      "2624",
      "2694",
      "2733",
      "200781",
      "201154",
      "201173",
      "201174"
    ]
  },
  "1d15b0c9-d7b1-462a-bfef-69941adc38c5": {
    "team": "1610612746",
    "year": "2008",
    "players": [
      "948",
      "1729",
      "1730",
      "1884",
      "2216",
      "2410",
      "2549",
      "101165",
      "200776",
      "200779",
      "201154",
      "201446",
      "201569",
      "201599"
    ]
  },
  "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0": {
    "team": "1610612746",
    "year": "2009",
    "players": [
      "1730",
      "1884",
      "2400",
      "2446",
      "2549",
      "2566",
      "2581",
      "200776",
      "200779",
      "200783",
      "201569",
      "201599",
      "201628",
      "201933"
    ]
  },
  "2cefe883-cd66-4007-b4b2-371761a1f717": {
    "team": "1610612746",
    "year": "2010",
    "players": [
      "2549",
      "2567",
      "2590",
      "101113",
      "101155",
      "200081",
      "200751",
      "200783",
      "201569",
      "201599",
      "201933",
      "202329",
      "202339",
      "202378"
    ]
  },
  "3e5888f2-9bf8-4adb-bc20-e74ba3870105": {
    "team": "1610612746",
    "year": "2011",
    "players": [
      "1497",
      "2030",
      "2250",
      "2406",
      "2501",
      "2590",
      "101108",
      "101155",
      "200751",
      "201156",
      "201599",
      "201933",
      "202339",
      "202717",
      "202727"
    ]
  },
  "6d8101b2-0835-4661-8dd5-46d4653e0851": {
    "team": "1610612746",
    "year": "2012",
    "players": [
      "255",
      "1497",
      "1885",
      "2037",
      "2406",
      "2440",
      "2584",
      "101108",
      "101142",
      "200797",
      "201599",
      "201933",
      "201969",
      "202339",
      "203146"
    ]
  },
  "f3020eca-0611-420b-8333-8de8e62b54b2": {
    "team": "1610612746",
    "year": "2013",
    "players": [
      "2037",
      "2045",
      "2440",
      "2584",
      "101108",
      "101122",
      "200755",
      "200797",
      "201162",
      "201175",
      "201599",
      "201933",
      "201954",
      "203493"
    ]
  },
  "0a28b192-f961-4a95-bbaa-b9a12af736d3": {
    "team": "1610612746",
    "year": "2014",
    "players": [
      "2037",
      "2045",
      "2440",
      "2563",
      "101108",
      "200755",
      "201150",
      "201175",
      "201599",
      "201933",
      "201991",
      "202327",
      "202706",
      "203085",
      "203912"
    ]
  },
  "28013d64-7f98-45fb-bdf8-b76b92f39380": {
    "team": "1610612746",
    "year": "2015",
    "players": [
      "1718",
      "2037",
      "101108",
      "200755",
      "201145",
      "201599",
      "201601",
      "201933",
      "201965",
      "202325",
      "202332",
      "203085",
      "203143",
      "203912",
      "1626183"
    ]
  },
  "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748": {
    "team": "1610612746",
    "year": "2016",
    "players": [
      "1718",
      "2037",
      "101108",
      "101109",
      "101138",
      "101187",
      "200755",
      "201578",
      "201599",
      "201601",
      "201933",
      "202325",
      "203085",
      "1627744",
      "1627754"
    ]
  },
  "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae": {
    "team": "1610612746",
    "year": "2017",
    "players": [
      "101150",
      "201568",
      "201599",
      "201976",
      "202325",
      "202340",
      "202699",
      "203085",
      "203710",
      "1626149",
      "1626155",
      "1626246",
      "1627820",
      "1628393",
      "1628414",
      "1628462"
    ]
  },
  "60943860-af22-4302-bd4c-e9d7fa67fb17": {
    "team": "1610612746",
    "year": "2018",
    "players": [
      "101150",
      "201163",
      "201568",
      "201976",
      "202066",
      "203210",
      "203585",
      "1626149",
      "1627820",
      "1627826",
      "1628405",
      "1628414",
      "1628983",
      "1629010",
      "1629013",
      "1629116"
    ]
  },
  "fb00bf45-e0ac-49b0-a60c-03eccab39697": {
    "team": "1610612746",
    "year": "2019",
    "players": [
      "101150",
      "201149",
      "201976",
      "202331",
      "202335",
      "202694",
      "202695",
      "202704",
      "203210",
      "203585",
      "1626149",
      "1627826",
      "1628405",
      "1629013",
      "1629599",
      "1629611",
      "1629662"
    ]
  },
  "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503": {
    "team": "1610612747",
    "year": "1948",
    "players": [
      "76188",
      "76344",
      "76624",
      "76655",
      "76711",
      "76738",
      "76778",
      "77123",
      "77211",
      "77867",
      "78071",
      "78185",
      "78344",
      "600012"
    ]
  },
  "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9": {
    "team": "1610612747",
    "year": "1949",
    "players": [
      "76344",
      "76711",
      "76823",
      "76850",
      "76962",
      "77123",
      "77480",
      "77593",
      "77867",
      "78071",
      "78447",
      "600012"
    ]
  },
  "0c2ad895-0fc8-49c0-9976-48e929a0c370": {
    "team": "1610612747",
    "year": "1950",
    "players": [
      "76711",
      "76850",
      "76962",
      "77090",
      "77123",
      "77480",
      "77593",
      "77773",
      "77867",
      "600012"
    ]
  },
  "56568a86-b30e-48a7-9f7d-80f79aa00339": {
    "team": "1610612747",
    "year": "1951",
    "players": [
      "76962",
      "77032",
      "77090",
      "77480",
      "77593",
      "77859",
      "77867",
      "78063",
      "78091",
      "78169",
      "600012"
    ]
  },
  "896b2d35-cd68-45f6-8ed3-70cf67383fa2": {
    "team": "1610612747",
    "year": "1952",
    "players": [
      "76962",
      "77032",
      "77045",
      "77480",
      "77593",
      "77867",
      "78063",
      "78091",
      "78169",
      "600012"
    ]
  },
  "1b383d65-6ca5-46ca-b345-acf8e129e382": {
    "team": "1610612747",
    "year": "1953",
    "players": [
      "77045",
      "77414",
      "77480",
      "77593",
      "77867",
      "78063",
      "78086",
      "78169",
      "600012"
    ]
  },
  "22dd26a7-1c99-45b7-a759-dce48ccb6829": {
    "team": "1610612747",
    "year": "1954",
    "players": [
      "76345",
      "77032",
      "77045",
      "77219",
      "77414",
      "77480",
      "77593",
      "77867",
      "78086",
      "78169",
      "78278"
    ]
  },
  "156bc9fb-9be0-4d47-ae6c-e255ee721988": {
    "team": "1610612747",
    "year": "1955",
    "players": [
      "76701",
      "76783",
      "77032",
      "77055",
      "77219",
      "77414",
      "77480",
      "77579",
      "77593",
      "78086",
      "78169",
      "78564",
      "600012"
    ]
  },
  "e396acb8-9dc8-42c0-b311-cdf0a86cfce8": {
    "team": "1610612747",
    "year": "1956",
    "players": [
      "76611",
      "76783",
      "77219",
      "77371",
      "77414",
      "77579",
      "77593",
      "77818",
      "78086",
      "78169",
      "78564"
    ]
  },
  "8f51d7d4-966b-43d1-84b2-9267b61db974": {
    "team": "1610612747",
    "year": "1957",
    "players": [
      "76273",
      "76313",
      "76561",
      "76679",
      "76723",
      "76744",
      "76783",
      "77082",
      "77095",
      "77311",
      "77371",
      "77593",
      "78086",
      "78232"
    ]
  },
  "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c": {
    "team": "1610612747",
    "year": "1958",
    "players": [
      "76127",
      "76658",
      "76723",
      "76744",
      "76783",
      "76929",
      "77082",
      "77311",
      "77371",
      "77593"
    ]
  },
  "efb6857f-25a7-443d-a111-1cd36484ccc8": {
    "team": "1610612747",
    "year": "1959",
    "players": [
      "76127",
      "76658",
      "76704",
      "76723",
      "76744",
      "76783",
      "76929",
      "76977",
      "77082",
      "77311",
      "77340",
      "77371",
      "77457",
      "78113",
      "78203"
    ]
  },
  "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf": {
    "team": "1610612747",
    "year": "1960",
    "players": [
      "76020",
      "76127",
      "76704",
      "76977",
      "77082",
      "77165",
      "77172",
      "77311",
      "77340",
      "77371",
      "78113",
      "78497"
    ]
  },
  "f2f930e4-58d0-4668-8866-f9d5e0a8da29": {
    "team": "1610612747",
    "year": "1961",
    "players": [
      "76127",
      "76704",
      "76977",
      "77082",
      "77172",
      "77311",
      "77340",
      "77562",
      "78113",
      "78203",
      "78497",
      "78630"
    ]
  },
  "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1": {
    "team": "1610612747",
    "year": "1962",
    "players": [
      "76107",
      "76127",
      "76663",
      "77058",
      "77082",
      "77172",
      "77311",
      "77340",
      "78113",
      "78497",
      "78526"
    ]
  },
  "a9afe90e-7f89-4f3f-87e7-6a97c519159f": {
    "team": "1610612747",
    "year": "1963",
    "players": [
      "76107",
      "76127",
      "76663",
      "76809",
      "77270",
      "77311",
      "77340",
      "77700",
      "77927",
      "78113",
      "78497",
      "78526"
    ]
  },
  "12d8f06a-c574-4a71-bd2f-9b827d839693": {
    "team": "1610612747",
    "year": "1964",
    "players": [
      "76107",
      "76127",
      "76663",
      "76896",
      "76983",
      "77093",
      "77270",
      "77340",
      "77700",
      "78497",
      "78526"
    ]
  },
  "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d": {
    "team": "1610612747",
    "year": "1965",
    "players": [
      "76127",
      "76206",
      "76663",
      "76691",
      "76832",
      "76983",
      "77093",
      "77270",
      "77340",
      "78497",
      "78526"
    ]
  },
  "e0535701-30f0-4faf-8df8-bf9256a7d8c9": {
    "team": "1610612747",
    "year": "1966",
    "players": [
      "76105",
      "76127",
      "76187",
      "76376",
      "76397",
      "76716",
      "76832",
      "76977",
      "76983",
      "77093",
      "77340",
      "78497"
    ]
  },
  "b58e37fe-d855-4948-b0f9-9422b49423ba": {
    "team": "1610612747",
    "year": "1967",
    "players": [
      "76035",
      "76105",
      "76127",
      "76397",
      "76458",
      "76832",
      "76926",
      "76977",
      "77093",
      "78497",
      "78501"
    ]
  },
  "0607a2e4-4900-4392-af54-76ee097fe467": {
    "team": "1610612747",
    "year": "1968",
    "players": [
      "76035",
      "76127",
      "76363",
      "76375",
      "76458",
      "76466",
      "76647",
      "76680",
      "76977",
      "77013",
      "78497"
    ]
  },
  "35c1b1f9-52bb-4cce-a910-da3b5e26cb00": {
    "team": "1610612747",
    "year": "1969",
    "players": [
      "76127",
      "76375",
      "76458",
      "76647",
      "76680",
      "76788",
      "77427",
      "77505",
      "77973",
      "78497"
    ]
  },
  "122a5267-d509-4769-a4ca-4f7932f65492": {
    "team": "1610612747",
    "year": "1970",
    "players": [
      "76127",
      "76375",
      "76680",
      "76832",
      "76916",
      "77012",
      "77257",
      "77505",
      "77554",
      "77962",
      "77973",
      "78497",
      "78651"
    ]
  },
  "a289a8c1-f96f-4f91-b664-d6c98ed35f2f": {
    "team": "1610612747",
    "year": "1971",
    "players": [
      "76127",
      "76375",
      "76402",
      "76663",
      "76680",
      "76832",
      "76916",
      "77554",
      "77962",
      "77988",
      "78367",
      "78497"
    ]
  },
  "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3": {
    "team": "1610612747",
    "year": "1972",
    "players": [
      "76286",
      "76375",
      "76680",
      "76832",
      "76853",
      "76916",
      "77554",
      "77883",
      "77962",
      "77988",
      "78497"
    ]
  },
  "c39c5026-a493-40db-84cd-272c3b126805": {
    "team": "1610612747",
    "year": "1973",
    "players": [
      "76253",
      "76458",
      "76832",
      "76853",
      "76916",
      "76978",
      "77413",
      "77883",
      "77962",
      "78188",
      "78467",
      "78497"
    ]
  },
  "bfd3d82f-7763-4318-9f54-51769b18a6ce": {
    "team": "1610612747",
    "year": "1974",
    "players": [
      "76027",
      "76133",
      "76253",
      "76330",
      "76832",
      "76916",
      "76972",
      "77338",
      "77413",
      "77962",
      "78045",
      "78188",
      "78467",
      "78600"
    ]
  },
  "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb": {
    "team": "1610612747",
    "year": "1975",
    "players": [
      "76003",
      "76027",
      "76330",
      "76734",
      "76754",
      "76832",
      "77325",
      "77338",
      "77523",
      "77570",
      "77962",
      "78000",
      "78045",
      "78460",
      "78467",
      "78495",
      "78567"
    ]
  },
  "4f6e72ef-a5a4-4e43-93fa-c41c52026b77": {
    "team": "1610612747",
    "year": "1976",
    "players": [
      "76003",
      "76005",
      "76027",
      "76379",
      "76734",
      "77325",
      "77330",
      "77668",
      "77978",
      "78045",
      "78294",
      "78460",
      "78467"
    ]
  },
  "fff2243e-9747-44b5-8882-14d1d9d99603": {
    "team": "1610612747",
    "year": "1977",
    "players": [
      "76003",
      "76005",
      "76350",
      "76504",
      "76516",
      "76734",
      "77074",
      "77721",
      "78532"
    ]
  },
  "e92adc84-abb2-4977-92b5-c33434e3233a": {
    "team": "1610612747",
    "year": "1978",
    "players": [
      "76003",
      "76204",
      "76350",
      "76361",
      "76444",
      "76504",
      "76734",
      "77074",
      "77721",
      "77883",
      "77998",
      "78532"
    ]
  },
  "c3077e01-3d45-4725-9d9e-90a13761dbe8": {
    "team": "1610612747",
    "year": "1979",
    "players": [
      "76003",
      "76321",
      "76392",
      "76444",
      "76981",
      "77040",
      "77142",
      "77721",
      "78532"
    ]
  },
  "9894db71-7342-451a-9249-92e7308dbbe9": {
    "team": "1610612747",
    "year": "1980",
    "players": [
      "76003",
      "76248",
      "76355",
      "76392",
      "76444",
      "76947",
      "77040",
      "77100",
      "77142",
      "77334",
      "77721",
      "77808",
      "78532"
    ]
  },
  "7868abb4-8d7e-4e07-bc83-5338460cb2b8": {
    "team": "1610612747",
    "year": "1981",
    "players": [
      "76003",
      "76248",
      "76444",
      "77135",
      "77142",
      "77205",
      "77324",
      "77334",
      "77498",
      "77530",
      "77541",
      "77721",
      "77905",
      "78532"
    ]
  },
  "5060be0c-335c-47ca-aaaa-f6b63ce254ba": {
    "team": "1610612747",
    "year": "1982",
    "players": [
      "1460",
      "76003",
      "76121",
      "76444",
      "77135",
      "77142",
      "77182",
      "77205",
      "77334",
      "77498",
      "77530",
      "77618",
      "77721",
      "77905",
      "78532"
    ]
  },
  "48eedca2-29c5-4d77-a299-70678b08bfa7": {
    "team": "1610612747",
    "year": "1983",
    "players": [
      "2",
      "1460",
      "76003",
      "76444",
      "76786",
      "77142",
      "77205",
      "77324",
      "77498",
      "77530",
      "77689",
      "77905",
      "78233",
      "78532"
    ]
  },
  "d4d129f1-7a59-40e4-b659-766f7affa51a": {
    "team": "1610612747",
    "year": "1984",
    "players": [
      "2",
      "1460",
      "76003",
      "76444",
      "77142",
      "77183",
      "77324",
      "77373",
      "77498",
      "77530",
      "77707",
      "77905",
      "78233",
      "78532"
    ]
  },
  "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd": {
    "team": "1610612747",
    "year": "1985",
    "players": [
      "2",
      "920",
      "1460",
      "76003",
      "76444",
      "76901",
      "76992",
      "77142",
      "77324",
      "77373",
      "77420",
      "77530",
      "77905",
      "78233"
    ]
  },
  "b1898857-82db-453b-849b-591e70ae6807": {
    "team": "1610612747",
    "year": "1986",
    "players": [
      "2",
      "920",
      "1091",
      "1460",
      "1631",
      "76003",
      "76237",
      "76444",
      "77142",
      "77484",
      "77905",
      "78331"
    ]
  },
  "7d322c7b-346e-4ea5-8ea5-7853b1283a3e": {
    "team": "1610612747",
    "year": "1987",
    "players": [
      "2",
      "920",
      "1091",
      "1460",
      "1631",
      "76003",
      "76338",
      "76444",
      "77142",
      "77332",
      "77484",
      "77905",
      "78331",
      "78348",
      "78429"
    ]
  },
  "4c991cf6-4082-4074-822a-6c24976b3fc2": {
    "team": "1610612747",
    "year": "1988",
    "players": [
      "2",
      "920",
      "1460",
      "76003",
      "76338",
      "76444",
      "77142",
      "77332",
      "77558",
      "77970",
      "78331",
      "78615"
    ]
  },
  "07a1b22f-c056-4d55-a1b1-d9dc332965a8": {
    "team": "1610612747",
    "year": "1989",
    "players": [
      "2",
      "124",
      "920",
      "1460",
      "76294",
      "76444",
      "76599",
      "77142",
      "77502",
      "77558",
      "77757",
      "78331",
      "78418",
      "78615"
    ]
  },
  "46c73e8e-0262-4d24-bba5-f501568ed553": {
    "team": "1610612747",
    "year": "1990",
    "players": [
      "2",
      "64",
      "124",
      "380",
      "920",
      "922",
      "1460",
      "76599",
      "77142",
      "78304",
      "78317",
      "78331"
    ]
  },
  "c3b8e442-4b20-42f1-99ae-03e014396f4a": {
    "team": "1610612747",
    "year": "1991",
    "players": [
      "2",
      "9",
      "64",
      "124",
      "359",
      "380",
      "917",
      "920",
      "922",
      "1460",
      "76332",
      "77785",
      "77986",
      "78304",
      "600009"
    ]
  },
  "3979ce94-914f-45be-ba16-62480c3df040": {
    "team": "1610612747",
    "year": "1992",
    "players": [
      "2",
      "9",
      "57",
      "104",
      "124",
      "229",
      "324",
      "380",
      "920",
      "922",
      "1030",
      "1460",
      "76180"
    ]
  },
  "761250ad-879b-4092-a761-56abea7f36ad": {
    "team": "1610612747",
    "year": "1993",
    "players": [
      "7",
      "9",
      "57",
      "89",
      "124",
      "176",
      "229",
      "248",
      "324",
      "380",
      "674",
      "922",
      "1460",
      "76217",
      "77905"
    ]
  },
  "227615b9-c36f-451e-a0ec-5ef4a2dd81fa": {
    "team": "1610612747",
    "year": "1994",
    "players": [
      "9",
      "76",
      "89",
      "124",
      "176",
      "224",
      "248",
      "292",
      "324",
      "380",
      "705",
      "922",
      "1380",
      "76217",
      "76431",
      "77905"
    ]
  },
  "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff": {
    "team": "1610612747",
    "year": "1995",
    "players": [
      "9",
      "76",
      "89",
      "122",
      "124",
      "224",
      "248",
      "292",
      "324",
      "400",
      "701",
      "916",
      "922",
      "77142"
    ]
  },
  "beeac2ab-2ad5-45cf-81d4-7804f85813de": {
    "team": "1610612747",
    "year": "1996",
    "players": [
      "2",
      "89",
      "109",
      "224",
      "399",
      "406",
      "760",
      "916",
      "922",
      "965",
      "969",
      "977"
    ]
  },
  "ba46beed-7438-4f93-97b2-4e91442f0a11": {
    "team": "1610612747",
    "year": "1997",
    "players": [
      "89",
      "109",
      "224",
      "296",
      "399",
      "406",
      "468",
      "712",
      "916",
      "922",
      "965",
      "977"
    ]
  },
  "b5b72829-0d6f-4b56-b6d1-d6483bb38438": {
    "team": "1610612747",
    "year": "1998",
    "players": [
      "109",
      "157",
      "296",
      "399",
      "406",
      "462",
      "779",
      "965",
      "969",
      "977",
      "1731",
      "1739"
    ]
  },
  "96a8ae7a-2b5e-49e6-8019-aed10a10b536": {
    "team": "1610612747",
    "year": "1999",
    "players": [
      "109",
      "166",
      "216",
      "296",
      "406",
      "779",
      "920",
      "927",
      "965",
      "969",
      "977",
      "1904"
    ]
  },
  "245559d4-40e5-4af9-8719-a54fe451cb29": {
    "team": "1610612747",
    "year": "2000",
    "players": [
      "109",
      "166",
      "216",
      "270",
      "296",
      "323",
      "406",
      "965",
      "977",
      "1731",
      "1904",
      "2058"
    ]
  },
  "b9be769f-c86c-4e4a-8f05-1024b9d4332e": {
    "team": "1610612747",
    "year": "2001",
    "players": [
      "109",
      "216",
      "283",
      "296",
      "406",
      "782",
      "955",
      "965",
      "977",
      "1904",
      "2058",
      "2098"
    ]
  },
  "2393458a-964e-4591-aa45-9b38a23e4a91": {
    "team": "1610612747",
    "year": "2002",
    "players": [
      "109",
      "145",
      "216",
      "296",
      "406",
      "955",
      "965",
      "977",
      "1904",
      "2058",
      "2062",
      "2066",
      "2098",
      "2416",
      "2457",
      "2462"
    ]
  },
  "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8": {
    "team": "1610612747",
    "year": "2003",
    "players": [
      "56",
      "252",
      "270",
      "296",
      "406",
      "935",
      "965",
      "977",
      "1904",
      "2098",
      "2416",
      "2441",
      "2567",
      "2575"
    ]
  },
  "4fc89f5d-17f8-4ef5-a561-d2787bc910ba": {
    "team": "1610612747",
    "year": "2004",
    "players": [
      "124",
      "258",
      "977",
      "1088",
      "1904",
      "1908",
      "2036",
      "2098",
      "2406",
      "2567",
      "2575",
      "2756"
    ]
  },
  "f2364869-d15e-4237-be86-d502b6074d04": {
    "team": "1610612747",
    "year": "2005",
    "players": [
      "243",
      "754",
      "977",
      "1885",
      "1904",
      "2036",
      "2198",
      "2470",
      "2567",
      "2575",
      "2756",
      "101115",
      "101142"
    ]
  },
  "74d2c7ce-3eea-4099-b898-8842a34143f8": {
    "team": "1610612747",
    "year": "2006",
    "players": [
      "243",
      "977",
      "1742",
      "1885",
      "2036",
      "2198",
      "2209",
      "2230",
      "2470",
      "2567",
      "2575",
      "2756",
      "101115",
      "101142",
      "200770"
    ]
  },
  "4206ee94-a61a-464f-99f8-016d0e855498": {
    "team": "1610612747",
    "year": "2007",
    "players": [
      "965",
      "977",
      "1885",
      "1956",
      "2036",
      "2200",
      "2209",
      "2575",
      "2756",
      "2772",
      "2788",
      "101115",
      "101142",
      "200770",
      "201207"
    ]
  },
  "0f3fc843-6d34-46a8-8aac-d07a35a96378": {
    "team": "1610612747",
    "year": "2008",
    "players": [
      "965",
      "977",
      "1885",
      "2200",
      "2575",
      "2694",
      "2756",
      "2772",
      "2788",
      "101115",
      "200747",
      "200769",
      "200770",
      "201180"
    ]
  },
  "7c717aa8-5048-422a-9ec1-af77b479d5c0": {
    "team": "1610612747",
    "year": "2009",
    "players": [
      "965",
      "977",
      "1885",
      "1897",
      "2200",
      "2575",
      "2694",
      "2756",
      "2788",
      "101115",
      "200747",
      "200769",
      "200770"
    ]
  },
  "d91b6884-86c1-4e6b-8c1b-82a8574ff68b": {
    "team": "1610612747",
    "year": "2010",
    "players": [
      "689",
      "693",
      "965",
      "977",
      "1885",
      "1897",
      "2200",
      "2440",
      "2575",
      "2581",
      "101115",
      "200769",
      "201234",
      "202365",
      "202382"
    ]
  },
  "ac885666-cac9-4f64-8d38-9179a8c79cf9": {
    "team": "1610612747",
    "year": "2011",
    "players": [
      "977",
      "1897",
      "2200",
      "2211",
      "2440",
      "2581",
      "101115",
      "201177",
      "201196",
      "201941",
      "201963",
      "202365",
      "202721",
      "202726"
    ]
  },
  "70af2982-c5b1-40e5-a2fb-508f22146f3b": {
    "team": "1610612747",
    "year": "2012",
    "players": [
      "959",
      "977",
      "1712",
      "1897",
      "2200",
      "2581",
      "2730",
      "2768",
      "201941",
      "201947",
      "201975",
      "202365",
      "202721",
      "202726",
      "203135"
    ]
  },
  "2b916dcd-2382-4695-8b82-9c3f683f3f13": {
    "team": "1610612747",
    "year": "2013",
    "players": [
      "959",
      "977",
      "2200",
      "2549",
      "200770",
      "201156",
      "201941",
      "201975",
      "202325",
      "202333",
      "202705",
      "203088",
      "203135",
      "203145",
      "203527"
    ]
  },
  "b462e94b-f32c-4a5a-99c9-5e5188884419": {
    "team": "1610612747",
    "year": "2014",
    "players": [
      "977",
      "2430",
      "101179",
      "201156",
      "201941",
      "201961",
      "202325",
      "202334",
      "202391",
      "203135",
      "203505",
      "203527",
      "203903",
      "203913",
      "203944",
      "204028"
    ]
  },
  "395561f6-a7d9-4329-adf6-0ffd5ff8faed": {
    "team": "1610612747",
    "year": "2015",
    "players": [
      "977",
      "1897",
      "101138",
      "101150",
      "201156",
      "201579",
      "203135",
      "203527",
      "203903",
      "203944",
      "204028",
      "1626148",
      "1626156",
      "1626204",
      "1626273"
    ]
  },
  "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a": {
    "team": "1610612747",
    "year": "2016",
    "players": [
      "1897",
      "2736",
      "201147",
      "201156",
      "202389",
      "203080",
      "203898",
      "203903",
      "203944",
      "204028",
      "1626156",
      "1626204",
      "1627742",
      "1627826",
      "1628021"
    ]
  },
  "caf58514-10b8-4a99-8841-2c49c45bc519": {
    "team": "1610612747",
    "year": "2017",
    "players": [
      "2736",
      "101112",
      "201281",
      "201572",
      "202738",
      "203484",
      "203898",
      "203944",
      "204037",
      "1627742",
      "1627780",
      "1627826",
      "1627936",
      "1628366",
      "1628398",
      "1628404",
      "1628418"
    ]
  },
  "3078bd19-8c1f-4efb-9dfa-25030cefbd5e": {
    "team": "1610612747",
    "year": "2018",
    "players": [
      "2199",
      "2544",
      "200765",
      "201580",
      "202362",
      "203484",
      "203488",
      "203493",
      "1627742",
      "1627936",
      "1628366",
      "1628398",
      "1628404",
      "1629021",
      "1629067",
      "1629140",
      "1629203"
    ]
  },
  "db38d209-7326-41b8-948f-588f9a050e00": {
    "team": "1610612747",
    "year": "2019",
    "players": [
      "2544",
      "2730",
      "200765",
      "201162",
      "201580",
      "201980",
      "202340",
      "202693",
      "203076",
      "203079",
      "203484",
      "1626188",
      "1627936",
      "1628398",
      "1628961",
      "1629659",
      "1629719"
    ]
  },
  "652536cb-d53b-4de7-a147-555aacb21048": {
    "team": "1610612748",
    "year": "1988",
    "players": [
      "3",
      "236",
      "938",
      "1117",
      "1631",
      "76483",
      "76862",
      "76968",
      "77694",
      "77872",
      "78279",
      "78295",
      "78465",
      "600009"
    ]
  },
  "57881dc6-8022-4f8e-b510-97723b8c870e": {
    "team": "1610612748",
    "year": "1989",
    "players": [
      "3",
      "236",
      "426",
      "428",
      "779",
      "938",
      "1631",
      "76483",
      "76748",
      "76911",
      "78029",
      "78279",
      "600009"
    ]
  },
  "e7b9ec7d-a840-4995-babb-299a22930d17": {
    "team": "1610612748",
    "year": "1990",
    "players": [
      "3",
      "173",
      "236",
      "416",
      "426",
      "428",
      "779",
      "902",
      "938",
      "1628",
      "1631",
      "77251",
      "78279",
      "78429"
    ]
  },
  "50a8ba42-0d1f-40ac-b38f-8a4f3f159030": {
    "team": "1610612748",
    "year": "1991",
    "players": [
      "3",
      "120",
      "173",
      "216",
      "236",
      "416",
      "779",
      "902",
      "938",
      "1628",
      "1972",
      "76079",
      "76152",
      "77251",
      "78279"
    ]
  },
  "10d493d6-08b9-410c-b14a-a2353115d39c": {
    "team": "1610612748",
    "year": "1992",
    "players": [
      "3",
      "114",
      "120",
      "173",
      "216",
      "236",
      "416",
      "761",
      "779",
      "902",
      "927",
      "938",
      "77251"
    ]
  },
  "ed6e82de-af93-48c5-ba4e-66022e13d1b4": {
    "team": "1610612748",
    "year": "1993",
    "players": [
      "3",
      "114",
      "120",
      "173",
      "216",
      "416",
      "761",
      "779",
      "902",
      "927",
      "938",
      "77251"
    ]
  },
  "edbdfda6-7b68-4ac6-889b-15601e808663": {
    "team": "1610612748",
    "year": "1994",
    "players": [
      "38",
      "114",
      "173",
      "182",
      "234",
      "289",
      "333",
      "340",
      "761",
      "779",
      "902",
      "927"
    ]
  },
  "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4": {
    "team": "1610612748",
    "year": "1995",
    "players": [
      "7",
      "173",
      "297",
      "351",
      "364",
      "380",
      "390",
      "423",
      "702",
      "703",
      "895",
      "896",
      "1005",
      "1034"
    ]
  },
  "2c689fdc-403a-46a2-bd96-1f8f4ddcd209": {
    "team": "1610612748",
    "year": "1996",
    "players": [
      "105",
      "136",
      "173",
      "180",
      "291",
      "297",
      "335",
      "469",
      "702",
      "896",
      "1109",
      "1110",
      "1134"
    ]
  },
  "789b1203-8d4e-4907-af10-4cf24e1e21d2": {
    "team": "1610612748",
    "year": "1997",
    "players": [
      "55",
      "105",
      "136",
      "173",
      "190",
      "297",
      "371",
      "469",
      "702",
      "785",
      "896",
      "1110"
    ]
  },
  "f48f5f46-8f24-47f6-8584-6fdcced3e2f0": {
    "team": "1610612748",
    "year": "1998",
    "players": [
      "105",
      "136",
      "173",
      "190",
      "221",
      "297",
      "345",
      "469",
      "702",
      "777",
      "896",
      "1110"
    ]
  },
  "24ca2337-7f93-4fce-81a3-bc39766d397d": {
    "team": "1610612748",
    "year": "1999",
    "players": [
      "105",
      "136",
      "190",
      "221",
      "297",
      "469",
      "896",
      "901",
      "1110",
      "1477",
      "1853",
      "1934"
    ]
  },
  "b32e30f7-90ef-4cd8-9404-c94a1eb714bd": {
    "team": "1610612748",
    "year": "2000",
    "players": [
      "76",
      "105",
      "190",
      "193",
      "224",
      "258",
      "297",
      "896",
      "920",
      "1477",
      "1853",
      "2067"
    ]
  },
  "49672545-bb7d-43e6-a8c6-9e18594ef023": {
    "team": "1610612748",
    "year": "2001",
    "players": [
      "224",
      "297",
      "383",
      "393",
      "1735",
      "1752",
      "1853",
      "2067",
      "2081",
      "2124",
      "2229"
    ]
  },
  "f47b8402-fe32-4053-91ea-76db13aaf7eb": {
    "team": "1610612748",
    "year": "2002",
    "players": [
      "111",
      "224",
      "258",
      "297",
      "696",
      "1735",
      "1752",
      "1853",
      "2064",
      "2067",
      "2081",
      "2124",
      "2229",
      "2253",
      "2256",
      "2406",
      "2446"
    ]
  },
  "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6": {
    "team": "1610612748",
    "year": "2003",
    "players": [
      "224",
      "258",
      "902",
      "955",
      "961",
      "1747",
      "1885",
      "1917",
      "2124",
      "2254",
      "2406",
      "2446",
      "2548",
      "2602",
      "2617"
    ]
  },
  "eccc0600-ee8d-4366-a3d8-cb9e66f1b862": {
    "team": "1610612748",
    "year": "2004",
    "players": [
      "120",
      "224",
      "297",
      "363",
      "406",
      "1000",
      "1720",
      "1800",
      "2039",
      "2446",
      "2548",
      "2617",
      "2748"
    ]
  },
  "374e93e9-dae6-4b55-a6ea-9f090ad3709c": {
    "team": "1610612748",
    "year": "2005",
    "players": [
      "56",
      "297",
      "406",
      "952",
      "1000",
      "1507",
      "1715",
      "1720",
      "1899",
      "2548",
      "2574",
      "2617",
      "2748",
      "101134"
    ]
  },
  "d4fda0d9-e426-471e-b6ab-a16b93412363": {
    "team": "1610612748",
    "year": "2006",
    "players": [
      "56",
      "224",
      "297",
      "406",
      "952",
      "1715",
      "1720",
      "1899",
      "2548",
      "2574",
      "2617",
      "2748",
      "2853",
      "101134",
      "200809"
    ]
  },
  "afdba645-cf3b-45d9-8aa5-8c355af0c5ba": {
    "team": "1610612748",
    "year": "2007",
    "players": [
      "297",
      "1548",
      "1715",
      "1729",
      "1890",
      "2548",
      "2556",
      "2617",
      "2748",
      "2853",
      "2867",
      "200792",
      "200809",
      "201161",
      "201186",
      "201202",
      "201336"
    ]
  },
  "24a63bd2-7dc0-4207-af30-c5baf3d57ef0": {
    "team": "1610612748",
    "year": "2008",
    "players": [
      "979",
      "1548",
      "2048",
      "2548",
      "2592",
      "2617",
      "2748",
      "101129",
      "200081",
      "200809",
      "200821",
      "201161",
      "201202",
      "201563",
      "201596"
    ]
  },
  "aaba7a68-44ab-4582-a519-af97ad7ac8f3": {
    "team": "1610612748",
    "year": "2009",
    "players": [
      "979",
      "1747",
      "2047",
      "2048",
      "2306",
      "2548",
      "2592",
      "2617",
      "2748",
      "101183",
      "200821",
      "201161",
      "201202",
      "201563",
      "201596",
      "202238"
    ]
  },
  "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b": {
    "team": "1610612748",
    "year": "2010",
    "players": [
      "436",
      "956",
      "980",
      "1710",
      "2034",
      "2048",
      "2067",
      "2544",
      "2547",
      "2548",
      "2592",
      "2617",
      "201202",
      "201596",
      "202354"
    ]
  },
  "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863": {
    "team": "1610612748",
    "year": "2011",
    "players": [
      "436",
      "2034",
      "2201",
      "2203",
      "2544",
      "2547",
      "2548",
      "2592",
      "2617",
      "101142",
      "201202",
      "201596",
      "202227",
      "202354",
      "202708"
    ]
  },
  "cee68385-dff8-4a7d-ac14-a73bb6a69edc": {
    "team": "1610612748",
    "year": "2012",
    "players": [
      "436",
      "951",
      "1740",
      "2034",
      "2203",
      "2365",
      "2544",
      "2547",
      "2548",
      "2592",
      "2617",
      "201202",
      "201596",
      "202363",
      "202708"
    ]
  },
  "af9f6951-8f66-4c7a-bff9-df3b4ff3e368": {
    "team": "1610612748",
    "year": "2013",
    "players": [
      "951",
      "1740",
      "2203",
      "2365",
      "2544",
      "2547",
      "2548",
      "2592",
      "2617",
      "201141",
      "201563",
      "201596",
      "201962",
      "202708",
      "203120"
    ]
  },
  "c5b89585-a505-4afc-a546-0291ba751b57": {
    "team": "1610612748",
    "year": "2014",
    "players": [
      "2365",
      "2547",
      "2548",
      "2617",
      "2736",
      "201177",
      "201563",
      "201596",
      "201609",
      "201611",
      "202355",
      "203516",
      "203894",
      "204020",
      "204054"
    ]
  },
  "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7": {
    "team": "1610612748",
    "year": "2015",
    "players": [
      "2207",
      "2405",
      "2547",
      "2548",
      "2617",
      "2736",
      "2748",
      "101123",
      "201177",
      "201609",
      "202355",
      "204020",
      "1626159",
      "1626196",
      "1627362"
    ]
  },
  "bff7c457-7acf-4c98-986e-1ce75d4973d5": {
    "team": "1610612748",
    "year": "2016",
    "players": [
      "2547",
      "2617",
      "201177",
      "201609",
      "201949",
      "201961",
      "202337",
      "202355",
      "203079",
      "203186",
      "203585",
      "204020",
      "1626159",
      "1626196",
      "1627855"
    ]
  },
  "64494d90-4847-4b7e-817d-270d082d53b1": {
    "team": "1610612748",
    "year": "2017",
    "players": [
      "2548",
      "2617",
      "201609",
      "201949",
      "201961",
      "202337",
      "202355",
      "203079",
      "203482",
      "203585",
      "204020",
      "1626159",
      "1626175",
      "1626196",
      "1627884",
      "1628389",
      "1628476"
    ]
  },
  "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73": {
    "team": "1610612748",
    "year": "2018",
    "players": [
      "2548",
      "2617",
      "201583",
      "201609",
      "201949",
      "202355",
      "203079",
      "203482",
      "1626159",
      "1626196",
      "1627884",
      "1628389",
      "1628999",
      "1629130",
      "1629134"
    ]
  },
  "d1566529-2393-422f-9177-00c89290a8dc": {
    "team": "1610612748",
    "year": "2019",
    "players": [
      "2617",
      "2738",
      "201609",
      "202710",
      "203086",
      "203109",
      "203482",
      "203524",
      "1627884",
      "1628389",
      "1629130",
      "1629134",
      "1629216",
      "1629639",
      "1629644",
      "1629734",
      "1629735"
    ]
  },
  "9d1d5a18-cd11-48f1-aa5a-cff4be466b00": {
    "team": "1610612749",
    "year": "1968",
    "players": [
      "76382",
      "76485",
      "76668",
      "77533",
      "77601",
      "77716",
      "77815",
      "77988",
      "78003",
      "78190",
      "78568"
    ]
  },
  "c7ab28ba-96bf-465d-b793-b95781330560": {
    "team": "1610612749",
    "year": "1969",
    "players": [
      "76002",
      "76003",
      "76065",
      "76382",
      "76466",
      "76485",
      "76500",
      "76865",
      "77533",
      "77988",
      "78003",
      "78190",
      "78568"
    ]
  },
  "30c679a6-4b92-42bc-b03d-303894f5aed9": {
    "team": "1610612749",
    "year": "1970",
    "players": [
      "76003",
      "76027",
      "76206",
      "76485",
      "76500",
      "76865",
      "77533",
      "78190",
      "78477",
      "78597",
      "78648",
      "600015"
    ]
  },
  "100e1bc9-a0c1-46cd-9361-42b784f75cf6": {
    "team": "1610612749",
    "year": "1971",
    "players": [
      "76003",
      "76027",
      "76187",
      "76500",
      "76576",
      "77198",
      "77259",
      "77416",
      "77533",
      "77699",
      "600015"
    ]
  },
  "7dafa3ad-5454-4b95-a524-c76cf80fe558": {
    "team": "1610612749",
    "year": "1972",
    "players": [
      "76003",
      "76027",
      "76485",
      "76500",
      "76522",
      "76883",
      "77198",
      "77365",
      "77533",
      "77835",
      "78306",
      "600015"
    ]
  },
  "75e6490e-9bf2-4901-8762-6daf8eec1606": {
    "team": "1610612749",
    "year": "1973",
    "players": [
      "76003",
      "76027",
      "76485",
      "76500",
      "76522",
      "76603",
      "77365",
      "77533",
      "77835",
      "78306",
      "78567",
      "600015"
    ]
  },
  "bb2e2269-8e70-4d93-a583-5f824737ab1e": {
    "team": "1610612749",
    "year": "1974",
    "players": [
      "76003",
      "76263",
      "76485",
      "76500",
      "76522",
      "76603",
      "77316",
      "77533",
      "77883",
      "77944",
      "78042",
      "78327",
      "78460",
      "78495",
      "78567"
    ]
  },
  "113518f0-81cb-4e6d-9a60-ae57bf8f20e4": {
    "team": "1610612749",
    "year": "1975",
    "players": [
      "76252",
      "76263",
      "76500",
      "76522",
      "76747",
      "77492",
      "77533",
      "77586",
      "77883",
      "77944",
      "78188",
      "78600"
    ]
  },
  "743aba57-7e93-4575-8cb7-e3de53d6223c": {
    "team": "1610612749",
    "year": "1976",
    "players": [
      "76252",
      "76263",
      "76296",
      "76356",
      "76500",
      "76522",
      "76673",
      "76789",
      "77396",
      "77525",
      "77586",
      "77689",
      "77944",
      "78188",
      "78449",
      "78600"
    ]
  },
  "71e13c20-8234-43de-9d74-74839ced5c07": {
    "team": "1610612749",
    "year": "1977",
    "players": [
      "76154",
      "76252",
      "76296",
      "76673",
      "76806",
      "76899",
      "77160",
      "77344",
      "77586",
      "78449",
      "78600"
    ]
  },
  "39873456-69db-4f6d-b278-943dbfa788f7": {
    "team": "1610612749",
    "year": "1978",
    "players": [
      "76154",
      "76159",
      "76252",
      "76296",
      "76806",
      "76899",
      "77148",
      "77160",
      "77944",
      "78205",
      "78406",
      "78449",
      "78600"
    ]
  },
  "b890c31f-8e3d-4a47-8dda-02e70c490b9d": {
    "team": "1610612749",
    "year": "1979",
    "players": [
      "76252",
      "76296",
      "76365",
      "76483",
      "77160",
      "77586",
      "77626",
      "78449",
      "78468",
      "78600"
    ]
  },
  "3fa351f6-06f4-4879-80b9-9ec7b8bc8778": {
    "team": "1610612749",
    "year": "1980",
    "players": [
      "76252",
      "76296",
      "76365",
      "76483",
      "76686",
      "77160",
      "77168",
      "77626",
      "78600",
      "600005",
      "600010"
    ]
  },
  "e7850597-6081-4332-9f69-ba361aaaa0bc": {
    "team": "1610612749",
    "year": "1981",
    "players": [
      "175",
      "76252",
      "76296",
      "76365",
      "76474",
      "76483",
      "76500",
      "77160",
      "77168",
      "77490",
      "77626",
      "78204",
      "78237",
      "78600",
      "600005"
    ]
  },
  "48d933d7-4450-441c-8ef1-2d743521a134": {
    "team": "1610612749",
    "year": "1982",
    "players": [
      "175",
      "76252",
      "76365",
      "76462",
      "76471",
      "76736",
      "77024",
      "77160",
      "77623",
      "77626",
      "77829",
      "77879",
      "78600",
      "600005"
    ]
  },
  "ffa6228e-39da-4145-8319-41a17387cede": {
    "team": "1610612749",
    "year": "1983",
    "players": [
      "175",
      "76054",
      "76247",
      "76252",
      "76365",
      "76616",
      "76887",
      "77160",
      "77623",
      "77626",
      "77879",
      "78015",
      "600005"
    ]
  },
  "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663": {
    "team": "1610612749",
    "year": "1984",
    "players": [
      "175",
      "187",
      "894",
      "76247",
      "76518",
      "76616",
      "76670",
      "76713",
      "76887",
      "77034",
      "77588",
      "77623",
      "77626",
      "77879"
    ]
  },
  "fac543c5-7a72-481e-87a4-e3d382c18eec": {
    "team": "1610612749",
    "year": "1985",
    "players": [
      "175",
      "187",
      "894",
      "76247",
      "76518",
      "76713",
      "76822",
      "77034",
      "77183",
      "77332",
      "77623",
      "77626",
      "77879",
      "77946",
      "78030",
      "78462"
    ]
  },
  "0cb17c3a-eb4e-4627-8baf-29d4e33466cd": {
    "team": "1610612749",
    "year": "1986",
    "players": [
      "101",
      "187",
      "894",
      "76231",
      "76247",
      "76252",
      "76418",
      "76822",
      "76990",
      "76992",
      "77034",
      "77419",
      "77527",
      "77623",
      "77626",
      "77879",
      "77946",
      "78149",
      "78192",
      "78481"
    ]
  },
  "777d6706-d922-46f9-8db4-e079c5f46c74": {
    "team": "1610612749",
    "year": "1987",
    "players": [
      "187",
      "894",
      "1474",
      "1984",
      "76247",
      "77081",
      "77419",
      "77456",
      "77623",
      "77626",
      "77632",
      "77879",
      "77946",
      "78149",
      "78271"
    ]
  },
  "7d91f1ee-4372-4388-bc8e-63bd70ae722a": {
    "team": "1610612749",
    "year": "1988",
    "players": [
      "81",
      "122",
      "187",
      "894",
      "1474",
      "76247",
      "76268",
      "76616",
      "76874",
      "77057",
      "77081",
      "77623",
      "77626",
      "77879",
      "78149",
      "78377"
    ]
  },
  "ec353978-5363-474f-aac3-88a8126172d3": {
    "team": "1610612749",
    "year": "1989",
    "players": [
      "38",
      "81",
      "122",
      "246",
      "894",
      "926",
      "1474",
      "76268",
      "76412",
      "76616",
      "77057",
      "77081",
      "77301",
      "77879",
      "78146",
      "78149"
    ]
  },
  "3692013e-96e8-4e2b-8f7c-24755fc74d17": {
    "team": "1610612749",
    "year": "1990",
    "players": [
      "7",
      "38",
      "81",
      "107",
      "122",
      "783",
      "926",
      "1667",
      "76431",
      "76504",
      "77081",
      "77301",
      "78149",
      "78247"
    ]
  },
  "da7fed67-9107-4124-becb-84e8169908af": {
    "team": "1610612749",
    "year": "1991",
    "players": [
      "7",
      "38",
      "81",
      "107",
      "122",
      "783",
      "926",
      "1474",
      "1667",
      "76431",
      "77081",
      "77335",
      "77449",
      "77872"
    ]
  },
  "bdfd6caf-a9ad-489d-9676-4c8528e2ae39": {
    "team": "1610612749",
    "year": "1992",
    "players": [
      "7",
      "38",
      "103",
      "122",
      "138",
      "195",
      "400",
      "468",
      "783",
      "785",
      "898",
      "77449",
      "77776",
      "77890",
      "78615"
    ]
  },
  "c414034d-9405-454b-81a1-00b89efc08b5": {
    "team": "1610612749",
    "year": "1993",
    "players": [
      "38",
      "80",
      "103",
      "195",
      "323",
      "400",
      "452",
      "468",
      "785",
      "898",
      "1432",
      "76433",
      "76826"
    ]
  },
  "20b624e6-187e-4570-9266-08913829ee83": {
    "team": "1610612749",
    "year": "1994",
    "players": [
      "55",
      "103",
      "175",
      "195",
      "211",
      "271",
      "291",
      "299",
      "452",
      "468",
      "785",
      "1425",
      "76801",
      "78633"
    ]
  },
  "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458": {
    "team": "1610612749",
    "year": "1995",
    "players": [
      "46",
      "55",
      "104",
      "187",
      "195",
      "271",
      "299",
      "404",
      "428",
      "452",
      "704",
      "705",
      "77946"
    ]
  },
  "e1717c3e-2243-4b10-81ce-a08a3d07bef0": {
    "team": "1610612749",
    "year": "1996",
    "players": [
      "116",
      "271",
      "288",
      "299",
      "341",
      "359",
      "386",
      "428",
      "452",
      "457",
      "768",
      "951"
    ]
  },
  "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494": {
    "team": "1610612749",
    "year": "1997",
    "players": [
      "210",
      "238",
      "288",
      "386",
      "457",
      "688",
      "894",
      "911",
      "951",
      "994",
      "1036",
      "1532"
    ]
  },
  "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18": {
    "team": "1610612749",
    "year": "1998",
    "players": [
      "208",
      "209",
      "281",
      "288",
      "299",
      "423",
      "688",
      "906",
      "911",
      "951",
      "1501",
      "1714"
    ]
  },
  "0e3f21e2-6591-49bc-9708-ec6cc72cdc33": {
    "team": "1610612749",
    "year": "1999",
    "players": [
      "208",
      "219",
      "281",
      "299",
      "330",
      "911",
      "951",
      "1032",
      "1501",
      "1714",
      "1726",
      "1747"
    ]
  },
  "e82a839f-a912-4d8f-bd5c-7f2ce933ed74": {
    "team": "1610612749",
    "year": "2000",
    "players": [
      "208",
      "281",
      "283",
      "299",
      "679",
      "911",
      "951",
      "998",
      "1032",
      "1501",
      "1747",
      "2038"
    ]
  },
  "b636d686-efcb-4e44-ab91-bbeaa4f88f4b": {
    "team": "1610612749",
    "year": "2001",
    "players": [
      "21",
      "193",
      "208",
      "299",
      "911",
      "951",
      "998",
      "1032",
      "1501",
      "1747",
      "2038",
      "2072"
    ]
  },
  "6aec4466-47f0-4c79-afbf-16f4fa0a29ed": {
    "team": "1610612749",
    "year": "2002",
    "players": [
      "193",
      "208",
      "389",
      "679",
      "911",
      "951",
      "1501",
      "1538",
      "1563",
      "1919",
      "2038",
      "2366",
      "2409",
      "2429",
      "2436",
      "2441"
    ]
  },
  "65732e29-68b3-4187-b1b4-a7c5c458aeb4": {
    "team": "1610612749",
    "year": "2003",
    "players": [
      "389",
      "693",
      "1065",
      "1496",
      "1510",
      "1730",
      "1800",
      "2046",
      "2072",
      "2091",
      "2409",
      "2429",
      "2551"
    ]
  },
  "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945": {
    "team": "1610612749",
    "year": "2004",
    "players": [
      "389",
      "924",
      "1065",
      "1916",
      "2033",
      "2046",
      "2072",
      "2091",
      "2429",
      "2558",
      "2585",
      "2590"
    ]
  },
  "fe70708d-44fa-4308-85a4-df0100fc59b8": {
    "team": "1610612749",
    "year": "2005",
    "players": [
      "389",
      "693",
      "911",
      "1983",
      "2048",
      "2072",
      "2250",
      "2294",
      "2412",
      "2429",
      "2551",
      "2590",
      "101106"
    ]
  },
  "b0ee51a2-4e6b-4a6a-a766-18894f7550ee": {
    "team": "1610612749",
    "year": "2006",
    "players": [
      "1730",
      "1739",
      "1863",
      "2072",
      "2250",
      "2294",
      "2429",
      "2590",
      "2696",
      "2876",
      "101106",
      "101111",
      "101141",
      "200786",
      "200806"
    ]
  },
  "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c": {
    "team": "1610612749",
    "year": "2007",
    "players": [
      "1913",
      "2046",
      "2063",
      "2072",
      "2250",
      "2294",
      "2429",
      "2590",
      "2767",
      "2852",
      "101106",
      "101111",
      "201146",
      "201196"
    ]
  },
  "a93bbfbb-84d5-429d-a5f0-310c2c4034a0": {
    "team": "1610612749",
    "year": "2008",
    "players": [
      "1800",
      "1922",
      "2072",
      "2124",
      "2210",
      "2294",
      "2429",
      "2557",
      "2586",
      "101106",
      "101111",
      "101136",
      "201196",
      "201570",
      "201601"
    ]
  },
  "99c7795f-1136-4419-84cb-2b176eba2129": {
    "team": "1610612749",
    "year": "2009",
    "players": [
      "703",
      "711",
      "2056",
      "2072",
      "2294",
      "2422",
      "2429",
      "2557",
      "2568",
      "2767",
      "101106",
      "101141",
      "201601",
      "201616",
      "201943"
    ]
  },
  "3b676f62-e076-4dce-94be-5d06e04fc018": {
    "team": "1610612749",
    "year": "2010",
    "players": [
      "1863",
      "1894",
      "2039",
      "2072",
      "2400",
      "2422",
      "2568",
      "101106",
      "101141",
      "201601",
      "201604",
      "201943",
      "201972",
      "202336"
    ]
  },
  "2e023de2-4ded-40cd-a695-c36f062148dd": {
    "team": "1610612749",
    "year": "2011",
    "players": [
      "2198",
      "2399",
      "2400",
      "2568",
      "2733",
      "2757",
      "101141",
      "101145",
      "201601",
      "201943",
      "201972",
      "202327",
      "202336",
      "202699",
      "202720"
    ]
  },
  "a139cb1e-1d24-4d95-a51e-af40949889f2": {
    "team": "1610612749",
    "year": "2012",
    "players": [
      "2038",
      "2223",
      "2399",
      "2400",
      "2605",
      "101141",
      "101145",
      "200755",
      "201601",
      "201943",
      "202327",
      "202336",
      "202397",
      "202970",
      "203089"
    ]
  },
  "0642a459-b265-4cac-adbd-de39b087debf": {
    "team": "1610612749",
    "year": "2013",
    "players": [
      "2568",
      "2585",
      "101141",
      "201196",
      "201564",
      "202327",
      "202336",
      "202399",
      "202688",
      "202874",
      "203089",
      "203114",
      "203489",
      "203507",
      "203545"
    ]
  },
  "d35292b8-6161-46c5-938c-e771e7bac15a": {
    "team": "1610612749",
    "year": "2014",
    "players": [
      "2585",
      "101141",
      "201162",
      "201564",
      "201573",
      "203089",
      "203101",
      "203114",
      "203268",
      "203487",
      "203507",
      "203898",
      "203948",
      "203953",
      "203996"
    ]
  },
  "3f5caa17-b7cc-45f6-a19b-f85149a5e28c": {
    "team": "1610612749",
    "year": "2015",
    "players": [
      "200779",
      "201564",
      "201573",
      "202328",
      "202349",
      "203089",
      "203101",
      "203114",
      "203487",
      "203507",
      "203898",
      "203948",
      "203953",
      "203996",
      "1626173"
    ]
  },
  "f08ff061-361a-4172-9765-ffea895a1265": {
    "team": "1610612749",
    "year": "2016",
    "players": [
      "1891",
      "201150",
      "201563",
      "202328",
      "203089",
      "203114",
      "203141",
      "203503",
      "203507",
      "203521",
      "203953",
      "1626173",
      "1627748",
      "1627763",
      "1627780"
    ]
  },
  "0e43ebeb-653c-4269-a881-1aef85d79db7": {
    "team": "1610612749",
    "year": "2017",
    "players": [
      "1891",
      "201943",
      "202339",
      "203089",
      "203092",
      "203114",
      "203498",
      "203503",
      "203507",
      "203521",
      "203953",
      "204098",
      "1627748",
      "1627763",
      "1627850",
      "1628391",
      "1628425"
    ]
  },
  "4eeead22-09ec-4f45-95dc-12de108dc38f": {
    "team": "1610612749",
    "year": "2018",
    "players": [
      "2200",
      "101141",
      "201572",
      "201588",
      "202339",
      "202703",
      "203114",
      "203503",
      "203507",
      "204025",
      "1626192",
      "1627763",
      "1628391",
      "1628425",
      "1628978",
      "1629045"
    ]
  },
  "b414ac61-29b6-44e4-a605-c7f0581fbf97": {
    "team": "1610612749",
    "year": "2019",
    "players": [
      "2594",
      "101107",
      "101141",
      "201572",
      "201577",
      "201588",
      "202083",
      "202339",
      "203114",
      "203507",
      "203648",
      "1626192",
      "1628391",
      "1628412",
      "1628425",
      "1628978",
      "1629244"
    ]
  },
  "8e1b3223-5273-4a8c-b563-50f9edfd074e": {
    "team": "1610612750",
    "year": "1989",
    "players": [
      "28",
      "71",
      "140",
      "417",
      "895",
      "76237",
      "76247",
      "76338",
      "77370",
      "77415",
      "77674",
      "78022",
      "78110"
    ]
  },
  "b7e6cae1-4596-4cf6-a0ad-785d01ebed61": {
    "team": "1610612750",
    "year": "1990",
    "players": [
      "28",
      "71",
      "280",
      "417",
      "418",
      "738",
      "895",
      "76247",
      "76338",
      "76410",
      "76821",
      "76827",
      "77674",
      "78320"
    ]
  },
  "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c": {
    "team": "1610612750",
    "year": "1991",
    "players": [
      "26",
      "28",
      "71",
      "280",
      "417",
      "418",
      "1821",
      "1847",
      "76247",
      "76277",
      "76338",
      "76748",
      "76821",
      "77674"
    ]
  },
  "3b167c9d-66a7-4736-b5f2-604aa433bb39": {
    "team": "1610612750",
    "year": "1992",
    "players": [
      "26",
      "28",
      "52",
      "280",
      "363",
      "456",
      "737",
      "1814",
      "1847",
      "76183",
      "77486",
      "78110",
      "78415"
    ]
  },
  "54f0f70e-ad29-449d-8754-ee6a602fe375": {
    "team": "1610612750",
    "year": "1993",
    "players": [
      "28",
      "52",
      "351",
      "363",
      "375",
      "456",
      "1479",
      "1814",
      "1847",
      "76517",
      "76748",
      "76903",
      "77109",
      "77486",
      "101258"
    ]
  },
  "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090": {
    "team": "1610612750",
    "year": "1994",
    "players": [
      "28",
      "52",
      "239",
      "323",
      "351",
      "363",
      "375",
      "399",
      "1090",
      "1479",
      "1593",
      "1814",
      "76782",
      "76903",
      "77174"
    ]
  },
  "e170cb31-d4ef-499a-8662-716298adebe7": {
    "team": "1610612750",
    "year": "1995",
    "players": [
      "28",
      "35",
      "52",
      "66",
      "339",
      "345",
      "375",
      "417",
      "457",
      "706",
      "707",
      "708",
      "892",
      "78179"
    ]
  },
  "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3": {
    "team": "1610612750",
    "year": "1996",
    "players": [
      "28",
      "339",
      "345",
      "381",
      "417",
      "685",
      "708",
      "713",
      "950",
      "1037",
      "1049",
      "1051"
    ]
  },
  "c9048bdc-2836-4609-9734-21853a995553": {
    "team": "1610612750",
    "year": "1997",
    "players": [
      "52",
      "67",
      "223",
      "324",
      "345",
      "417",
      "674",
      "685",
      "708",
      "914",
      "950",
      "1545"
    ]
  },
  "a8cc9002-2b04-4ee9-ac89-c06a3312b35d": {
    "team": "1610612750",
    "year": "1998",
    "players": [
      "67",
      "210",
      "324",
      "381",
      "417",
      "693",
      "708",
      "907",
      "1051",
      "1517",
      "1725",
      "1754"
    ]
  },
  "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf": {
    "team": "1610612750",
    "year": "1999",
    "players": [
      "67",
      "210",
      "324",
      "417",
      "693",
      "708",
      "907",
      "1051",
      "1517",
      "1725",
      "1887",
      "1895"
    ]
  },
  "98863912-8e18-4e6d-99fe-c3dd0bb35ace": {
    "team": "1610612750",
    "year": "2000",
    "players": [
      "111",
      "210",
      "324",
      "417",
      "708",
      "1051",
      "1073",
      "1497",
      "1725",
      "1732",
      "1887",
      "1895"
    ]
  },
  "1c86cb7f-aa90-45ec-8522-d0b639467917": {
    "team": "1610612750",
    "year": "2001",
    "players": [
      "123",
      "324",
      "417",
      "693",
      "708",
      "718",
      "1497",
      "1531",
      "1725",
      "1732",
      "1887",
      "2254"
    ]
  },
  "8f9aa8f6-6917-4467-b970-3855520a730d": {
    "team": "1610612750",
    "year": "2002",
    "players": [
      "210",
      "324",
      "383",
      "693",
      "708",
      "718",
      "988",
      "1073",
      "1531",
      "1607",
      "1725",
      "1732",
      "1887",
      "2080",
      "2230",
      "2254"
    ]
  },
  "9039135f-bde3-4971-bf34-a59c10eaaa54": {
    "team": "1610612750",
    "year": "2003",
    "players": [
      "84",
      "208",
      "239",
      "697",
      "708",
      "718",
      "911",
      "932",
      "1607",
      "1709",
      "1887",
      "2058",
      "2239",
      "2569"
    ]
  },
  "9d211135-ddd3-4d77-ab15-5f84131f2de6": {
    "team": "1610612750",
    "year": "2004",
    "players": [
      "84",
      "208",
      "697",
      "708",
      "911",
      "1519",
      "1709",
      "1853",
      "1887",
      "2204",
      "2239",
      "2569"
    ]
  },
  "1ed38523-9af0-4cfb-ac38-c13d8d80315a": {
    "team": "1610612750",
    "year": "2005",
    "players": [
      "708",
      "1548",
      "1607",
      "1729",
      "1853",
      "2058",
      "2060",
      "2204",
      "2239",
      "2556",
      "2648",
      "2770",
      "101119",
      "101152"
    ]
  },
  "3e4b502a-18e0-4f72-b057-966f0972f973": {
    "team": "1610612750",
    "year": "2006",
    "players": [
      "708",
      "1548",
      "1607",
      "1729",
      "2058",
      "2060",
      "2239",
      "101119",
      "101152",
      "200751",
      "200783"
    ]
  },
  "97bb2be7-c9f5-4788-be33-8c731aa5e9d6": {
    "team": "1610612750",
    "year": "2007",
    "players": [
      "952",
      "1720",
      "1761",
      "2058",
      "2060",
      "2742",
      "2744",
      "2745",
      "101119",
      "101155",
      "200751",
      "200783",
      "201147",
      "201181"
    ]
  },
  "6b0a0176-c625-4185-97c7-e44301ac931f": {
    "team": "1610612750",
    "year": "2008",
    "players": [
      "1563",
      "2034",
      "2058",
      "2073",
      "2215",
      "2742",
      "2744",
      "101155",
      "200749",
      "200751",
      "200760",
      "200783",
      "201147",
      "201567",
      "201628"
    ]
  },
  "e3fc8c17-a818-4d6b-b322-de577d3bfee1": {
    "team": "1610612750",
    "year": "2009",
    "players": [
      "2073",
      "2545",
      "2562",
      "2744",
      "2863",
      "101155",
      "200762",
      "200797",
      "201147",
      "201196",
      "201567",
      "201605",
      "201880",
      "201938",
      "201961"
    ]
  },
  "ea89e2df-b552-4760-8e5d-5646fac7e0e5": {
    "team": "1610612750",
    "year": "2010",
    "players": [
      "2545",
      "2557",
      "2742",
      "101110",
      "201229",
      "201563",
      "201567",
      "201576",
      "201593",
      "201938",
      "201961",
      "202325",
      "202351"
    ]
  },
  "bf279b0d-e75c-486c-90a8-ef9e42a95cc4": {
    "team": "1610612750",
    "year": "2011",
    "players": [
      "1802",
      "2545",
      "2557",
      "101110",
      "200826",
      "201229",
      "201563",
      "201567",
      "201576",
      "201593",
      "201937",
      "201961",
      "202325",
      "202682",
      "202723"
    ]
  },
  "c7ea7be2-56d1-4574-b226-2038cb0e053c": {
    "team": "1610612750",
    "year": "2012",
    "players": [
      "1905",
      "2557",
      "101153",
      "200750",
      "200826",
      "201567",
      "201593",
      "201880",
      "201937",
      "201967",
      "201978",
      "202419",
      "202682",
      "202723",
      "203144"
    ]
  },
  "75b48a6d-8ce0-43e8-9531-1a1b43248644": {
    "team": "1610612750",
    "year": "2013",
    "players": [
      "2755",
      "101142",
      "200826",
      "201147",
      "201567",
      "201593",
      "201601",
      "201785",
      "201937",
      "201967",
      "201978",
      "203133",
      "203144",
      "203476",
      "203498"
    ]
  },
  "0bdf6350-c69b-4b66-b2f9-a35d44294191": {
    "team": "1610612750",
    "year": "2014",
    "players": [
      "708",
      "2755",
      "201593",
      "201937",
      "201978",
      "202390",
      "202620",
      "203120",
      "203133",
      "203461",
      "203476",
      "203485",
      "203498",
      "203897",
      "203940",
      "203952"
    ]
  },
  "d6b9a95f-5c95-4743-9999-f4582523ddc0": {
    "team": "1610612750",
    "year": "2015",
    "players": [
      "708",
      "2419",
      "201593",
      "201937",
      "202357",
      "202962",
      "203476",
      "203498",
      "203897",
      "203940",
      "203952",
      "204014",
      "1626145",
      "1626157"
    ]
  },
  "f5d1f46a-b453-44f3-8ec7-88d388748e5e": {
    "team": "1610612750",
    "year": "2016",
    "players": [
      "201575",
      "201593",
      "201937",
      "201941",
      "201956",
      "202332",
      "202357",
      "203476",
      "203498",
      "203897",
      "203940",
      "203952",
      "1626145",
      "1626157",
      "1627739"
    ]
  },
  "f9d8a546-a0a6-4d34-8621-eff1ee6b8198": {
    "team": "1610612750",
    "year": "2017",
    "players": [
      "2037",
      "201166",
      "201565",
      "201952",
      "201959",
      "202332",
      "202357",
      "202710",
      "203476",
      "203952",
      "1626145",
      "1626148",
      "1626157",
      "1627875",
      "1628383",
      "1628518"
    ]
  },
  "0c0a29ba-0c70-454d-8ff0-cbe844e4429f": {
    "team": "1610612750",
    "year": "2018",
    "players": [
      "2736",
      "201229",
      "201565",
      "201573",
      "201952",
      "201959",
      "203476",
      "203496",
      "203710",
      "203952",
      "203967",
      "1626145",
      "1626157",
      "1628249",
      "1628966",
      "1629006",
      "1629123",
      "1629244"
    ]
  },
  "4eebffb2-c1ae-4aff-872a-963dbe9919ca": {
    "team": "1610612750",
    "year": "2019",
    "players": [
      "201949",
      "202323",
      "1626156",
      "1626157",
      "1627736",
      "1627774",
      "1627823",
      "1628980",
      "1629006",
      "1629016",
      "1629020",
      "1629103",
      "1629162",
      "1629633",
      "1629669",
      "1629675"
    ]
  },
  "97c2ee90-873f-4c01-92e4-890bb08dda89": {
    "team": "1610612751",
    "year": "1976",
    "players": [
      "76054",
      "76095",
      "76120",
      "76502",
      "76529",
      "76747",
      "76975",
      "77078",
      "77192",
      "77561",
      "78167",
      "78306",
      "78400",
      "78546",
      "78606"
    ]
  },
  "f6db8edf-a145-4fdb-b531-74c601ef91a1": {
    "team": "1610612751",
    "year": "1977",
    "players": [
      "76120",
      "76481",
      "76975",
      "77028",
      "77078",
      "77149",
      "77205",
      "77264",
      "77701",
      "77875",
      "77876",
      "78400",
      "78471",
      "78575",
      "78606"
    ]
  },
  "cac91d50-2f65-4d2c-b139-8486bcc4842f": {
    "team": "1610612751",
    "year": "1978",
    "players": [
      "76120",
      "76225",
      "76656",
      "77106",
      "77149",
      "77205",
      "77264",
      "77627",
      "78167",
      "78400",
      "78471",
      "78575"
    ]
  },
  "2e047c1e-b4df-4b65-b41d-6ca65f168b3a": {
    "team": "1610612751",
    "year": "1979",
    "players": [
      "76120",
      "76225",
      "76656",
      "77106",
      "77149",
      "77205",
      "77235",
      "77420",
      "77691",
      "77710",
      "77848",
      "77986",
      "78157",
      "78204",
      "78400",
      "78575"
    ]
  },
  "30f87621-72fd-4d91-987d-50cce0bf1714": {
    "team": "1610612751",
    "year": "1980",
    "players": [
      "76435",
      "76656",
      "76826",
      "77184",
      "77205",
      "77420",
      "77498",
      "77635",
      "77710",
      "77754",
      "77986",
      "78400",
      "78436",
      "600009"
    ]
  },
  "c70377d9-fc43-426e-bc2c-9ccc5031ef2e": {
    "team": "1610612751",
    "year": "1981",
    "players": [
      "433",
      "76085",
      "76170",
      "76435",
      "76443",
      "76826",
      "77263",
      "77326",
      "77754",
      "78348",
      "78400",
      "78436",
      "78571",
      "600010"
    ]
  },
  "180d7461-2299-4b8c-9aef-267bf4d09d28": {
    "team": "1610612751",
    "year": "1982",
    "players": [
      "433",
      "76170",
      "76435",
      "76539",
      "76826",
      "77263",
      "77754",
      "77854",
      "77952",
      "78400",
      "78436",
      "78577",
      "600010"
    ]
  },
  "20889c5b-74ce-45e7-8127-3b987894fca9": {
    "team": "1610612751",
    "year": "1983",
    "players": [
      "433",
      "2891",
      "76170",
      "76435",
      "76539",
      "76826",
      "77163",
      "77263",
      "77754",
      "77911",
      "77952",
      "78436",
      "78577"
    ]
  },
  "8566310c-0a28-4492-9afd-453f232588e5": {
    "team": "1610612751",
    "year": "1984",
    "players": [
      "433",
      "76170",
      "76249",
      "76435",
      "76539",
      "76826",
      "77149",
      "77263",
      "77328",
      "77541",
      "77754",
      "77911",
      "77952",
      "78062",
      "78381",
      "78581"
    ]
  },
  "f2ded0f3-c782-462b-8040-e231fa98bbf5": {
    "team": "1610612751",
    "year": "1985",
    "players": [
      "433",
      "76170",
      "76368",
      "76435",
      "76539",
      "76826",
      "77168",
      "77214",
      "77263",
      "77754",
      "77911",
      "77952",
      "78381"
    ]
  },
  "92c435c1-938a-4392-8fd2-ef2c944fa4d3": {
    "team": "1610612751",
    "year": "1986",
    "players": [
      "433",
      "76085",
      "76170",
      "76268",
      "76412",
      "76539",
      "76670",
      "76826",
      "77263",
      "77456",
      "77541",
      "78381",
      "78465",
      "78571",
      "78611",
      "78615"
    ]
  },
  "5c6068d0-8b6b-4473-8dce-c22be7e22766": {
    "team": "1610612751",
    "year": "1987",
    "players": [
      "433",
      "76082",
      "76170",
      "76231",
      "76237",
      "76426",
      "76670",
      "77030",
      "77054",
      "77516",
      "77541",
      "77633",
      "77754",
      "78445",
      "78464",
      "78465",
      "78615"
    ]
  },
  "3a5f986c-a993-4fb5-8e76-1b8cc7720fef": {
    "team": "1610612751",
    "year": "1988",
    "players": [
      "356",
      "433",
      "1593",
      "76082",
      "76353",
      "76370",
      "76431",
      "76770",
      "77030",
      "77054",
      "77180",
      "77361",
      "77530"
    ]
  },
  "faa38736-32f4-41a2-8cca-8649fdee48af": {
    "team": "1610612751",
    "year": "1989",
    "players": [
      "193",
      "201",
      "302",
      "356",
      "917",
      "939",
      "1114",
      "1593",
      "76217",
      "76288",
      "76342",
      "76431",
      "76803",
      "77030",
      "77054",
      "78139",
      "78297",
      "78611"
    ]
  },
  "4c6f87e3-5009-4111-80db-5390790d46bb": {
    "team": "1610612751",
    "year": "1990",
    "players": [
      "146",
      "201",
      "302",
      "356",
      "371",
      "917",
      "934",
      "76217",
      "76801",
      "76803",
      "77030",
      "77362",
      "77845",
      "78310"
    ]
  },
  "24e281c7-c9c7-4698-ae12-a3104e79e69f": {
    "team": "1610612751",
    "year": "1991",
    "players": [
      "72",
      "201",
      "302",
      "356",
      "371",
      "912",
      "934",
      "76217",
      "76703",
      "76801",
      "77358",
      "77845"
    ]
  },
  "45b3a59c-7578-494d-9525-3352a060f871": {
    "team": "1610612751",
    "year": "1992",
    "players": [
      "72",
      "201",
      "328",
      "356",
      "359",
      "368",
      "420",
      "716",
      "912",
      "934",
      "76217",
      "76385",
      "76801",
      "77053",
      "77264",
      "77358",
      "77845"
    ]
  },
  "5b2ffd57-2eb1-49c2-bee9-bc575e99301f": {
    "team": "1610612751",
    "year": "1993",
    "players": [
      "72",
      "104",
      "133",
      "136",
      "236",
      "271",
      "288",
      "328",
      "356",
      "368",
      "420",
      "777",
      "934",
      "77114"
    ]
  },
  "6b1946cc-c9da-4ef6-946c-6fecb44b4720": {
    "team": "1610612751",
    "year": "1994",
    "players": [
      "72",
      "104",
      "136",
      "164",
      "212",
      "236",
      "288",
      "328",
      "356",
      "368",
      "420",
      "777",
      "925",
      "934",
      "76726"
    ]
  },
  "d8471143-7caa-497d-8696-5d0bb039d3c2": {
    "team": "1610612751",
    "year": "1995",
    "players": [
      "37",
      "136",
      "164",
      "212",
      "234",
      "236",
      "288",
      "313",
      "328",
      "420",
      "438",
      "709",
      "762",
      "78493"
    ]
  },
  "dd957ea2-38de-446a-a5a7-2822ee255cb0": {
    "team": "1610612751",
    "year": "1996",
    "players": [
      "170",
      "208",
      "212",
      "236",
      "376",
      "383",
      "423",
      "438",
      "754",
      "763",
      "917",
      "954",
      "1365"
    ]
  },
  "48bd29bb-6817-4388-af0d-0fb73d383119": {
    "team": "1610612751",
    "year": "1997",
    "players": [
      "208",
      "262",
      "383",
      "420",
      "423",
      "428",
      "446",
      "710",
      "938",
      "954",
      "967",
      "1496"
    ]
  },
  "482b62f2-7d27-4986-a11b-04e7b24f96a8": {
    "team": "1610612751",
    "year": "1998",
    "players": [
      "49",
      "197",
      "383",
      "386",
      "446",
      "713",
      "938",
      "950",
      "954",
      "971",
      "994",
      "1061",
      "1496"
    ]
  },
  "8285d0bf-5d8e-42f8-99c9-f29312b63146": {
    "team": "1610612751",
    "year": "1999",
    "players": [
      "29",
      "49",
      "197",
      "271",
      "383",
      "386",
      "428",
      "446",
      "950",
      "994",
      "1496",
      "1915"
    ]
  },
  "d0523481-86c7-447a-b0d9-81625e9e333c": {
    "team": "1610612751",
    "year": "2000",
    "players": [
      "77",
      "271",
      "446",
      "1110",
      "1425",
      "1496",
      "1536",
      "1735",
      "1915",
      "1975",
      "2066",
      "2109"
    ]
  },
  "c50a0f15-a679-4d28-b025-26daa9d217d5": {
    "team": "1610612751",
    "year": "2001",
    "players": [
      "446",
      "467",
      "681",
      "954",
      "1425",
      "1496",
      "1533",
      "1928",
      "2030",
      "2210",
      "2215",
      "2220",
      "2243"
    ]
  },
  "f9be6046-cf31-4b59-9535-b62182ab35ff": {
    "team": "1610612751",
    "year": "2002",
    "players": [
      "87",
      "164",
      "446",
      "467",
      "681",
      "915",
      "954",
      "994",
      "1425",
      "1911",
      "2030",
      "2210",
      "2215",
      "2220",
      "2243",
      "2447"
    ]
  },
  "88c51dfd-298e-43ce-94fa-3499edb1b267": {
    "team": "1610612751",
    "year": "2003",
    "players": [
      "93",
      "297",
      "446",
      "467",
      "915",
      "954",
      "1425",
      "2030",
      "2210",
      "2215",
      "2220",
      "2243",
      "2447",
      "2565"
    ]
  },
  "99c6aea9-27d0-453f-a643-716447513a5f": {
    "team": "1610612751",
    "year": "2004",
    "players": [
      "361",
      "467",
      "696",
      "1521",
      "1713",
      "1934",
      "2074",
      "2210",
      "2215",
      "2243",
      "2420",
      "2565",
      "2873"
    ]
  },
  "2ea2e46b-335d-4954-9b4e-4d0611c84ea9": {
    "team": "1610612751",
    "year": "2005",
    "players": [
      "361",
      "441",
      "467",
      "1519",
      "1521",
      "1713",
      "1909",
      "2210",
      "2215",
      "2411",
      "2420",
      "2565",
      "101120"
    ]
  },
  "dcd3f345-f8eb-4d80-b466-74386669a31a": {
    "team": "1610612751",
    "year": "2006",
    "players": [
      "361",
      "467",
      "1630",
      "1713",
      "2067",
      "2210",
      "2215",
      "2411",
      "2420",
      "2774",
      "101120",
      "101148",
      "200766",
      "200767",
      "200801"
    ]
  },
  "658a886a-3fad-4c2d-ae09-85c553c4e7e4": {
    "team": "1610612751",
    "year": "2007",
    "players": [
      "353",
      "1713",
      "2031",
      "2205",
      "2210",
      "2239",
      "2411",
      "2420",
      "2734",
      "200766",
      "200767",
      "200772",
      "201157"
    ]
  },
  "6729ad33-cbf8-4795-b814-64b937327c6c": {
    "team": "1610612751",
    "year": "2008",
    "players": [
      "1713",
      "2039",
      "2059",
      "2239",
      "2250",
      "2553",
      "2734",
      "200767",
      "200772",
      "201146",
      "201157",
      "201572",
      "201583",
      "201604"
    ]
  },
  "ea819ad7-3ab1-4ad8-a15e-f443f42933d4": {
    "team": "1610612751",
    "year": "2009",
    "players": [
      "1499",
      "2039",
      "2239",
      "2250",
      "2553",
      "2734",
      "2743",
      "200767",
      "200809",
      "201146",
      "201572",
      "201584",
      "201604",
      "201944"
    ]
  },
  "ea311dd0-c904-40b7-9c82-69884c7fe4eb": {
    "team": "1610612751",
    "year": "2010",
    "players": [
      "2429",
      "2566",
      "2743",
      "2756",
      "101114",
      "101130",
      "101211",
      "200770",
      "201148",
      "201238",
      "201572",
      "201627",
      "202178",
      "202345",
      "202386"
    ]
  },
  "c5b4e2c0-91e5-453d-ad22-ac66b6068540": {
    "team": "1610612751",
    "year": "2011",
    "players": [
      "2052",
      "2222",
      "2743",
      "101114",
      "101123",
      "101130",
      "200749",
      "200770",
      "201572",
      "201627",
      "202178",
      "202345",
      "202356",
      "202705",
      "202716"
    ]
  },
  "3d30044e-ba2e-4407-8b2e-aa9d09c23710": {
    "team": "1610612751",
    "year": "2012",
    "players": [
      "711",
      "2207",
      "2222",
      "2501",
      "2586",
      "2743",
      "101114",
      "101154",
      "201228",
      "201572",
      "202705",
      "203116",
      "203126",
      "203129",
      "203141"
    ]
  },
  "7842004f-64a2-41a8-8552-327d53b7a972": {
    "team": "1610612751",
    "year": "2013",
    "players": [
      "708",
      "1718",
      "1905",
      "2207",
      "2215",
      "2733",
      "101114",
      "101154",
      "101187",
      "201572",
      "201977",
      "203104",
      "203141",
      "203268",
      "203486"
    ]
  },
  "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7": {
    "team": "1610612751",
    "year": "2014",
    "players": [
      "2207",
      "101114",
      "101127",
      "101187",
      "201152",
      "201572",
      "201947",
      "202366",
      "202711",
      "202721",
      "203141",
      "203486",
      "203508",
      "203900",
      "203928"
    ]
  },
  "648726e0-d700-4ab3-8c10-d5cfa4b7a83b": {
    "team": "1610612751",
    "year": "2015",
    "players": [
      "101127",
      "201152",
      "201572",
      "201961",
      "202388",
      "202711",
      "203080",
      "203156",
      "203186",
      "203499",
      "203508",
      "203900",
      "203930",
      "1626178",
      "1626191"
    ]
  },
  "90b7b2c5-144a-47b4-be9d-7c030d7bde18": {
    "team": "1610612751",
    "year": "2016",
    "players": [
      "200751",
      "201572",
      "202344",
      "202391",
      "203094",
      "203112",
      "203120",
      "203462",
      "203909",
      "203915",
      "203925",
      "203930",
      "1626178",
      "1627747",
      "1627785"
    ]
  },
  "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1": {
    "team": "1610612751",
    "year": "2017",
    "players": [
      "201960",
      "201967",
      "202389",
      "202391",
      "203112",
      "203459",
      "203915",
      "203917",
      "203925",
      "1626143",
      "1626156",
      "1626178",
      "1627747",
      "1627785",
      "1627821",
      "1628386",
      "1628495"
    ]
  },
  "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e": {
    "team": "1610612751",
    "year": "2018",
    "players": [
      "201162",
      "201960",
      "202334",
      "203459",
      "203894",
      "203915",
      "203925",
      "1626156",
      "1626178",
      "1626203",
      "1626210",
      "1627747",
      "1628386",
      "1629033",
      "1629058",
      "1629066"
    ]
  },
  "206a57a6-ccc5-413b-a413-5deab8b03a78": {
    "team": "1610612751",
    "year": "2019",
    "players": [
      "201142",
      "201163",
      "201599",
      "202066",
      "202681",
      "203915",
      "203925",
      "1627747",
      "1627752",
      "1627789",
      "1628386",
      "1629033",
      "1629058",
      "1629066",
      "1629185",
      "1629651",
      "1629725"
    ]
  },
  "5863b9f0-adf0-4042-9912-f445b7c8ee24": {
    "team": "1610612752",
    "year": "1946",
    "players": [
      "76255",
      "76322",
      "76408",
      "76757",
      "76836",
      "77011",
      "77221",
      "77291",
      "77453",
      "77671",
      "77793",
      "78078",
      "78276",
      "78401"
    ]
  },
  "bb996e83-b963-4fd6-b44b-484116ff5a4e": {
    "team": "1610612752",
    "year": "1947",
    "players": [
      "76243",
      "76322",
      "76836",
      "77048",
      "77291",
      "77322",
      "77613",
      "77723",
      "77793",
      "78276",
      "78291",
      "78401"
    ]
  },
  "df1fce20-7cea-47cc-8e99-cf6d772b3162": {
    "team": "1610612752",
    "year": "1948",
    "players": [
      "76243",
      "76422",
      "76773",
      "77116",
      "77291",
      "77322",
      "77529",
      "77723",
      "77793",
      "77968",
      "78023",
      "78141",
      "78291",
      "78401"
    ]
  },
  "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7": {
    "team": "1610612752",
    "year": "1949",
    "players": [
      "76210",
      "76243",
      "76585",
      "76773",
      "77116",
      "77425",
      "77537",
      "77723",
      "77968",
      "78153",
      "78401",
      "78405"
    ]
  },
  "f4a89590-ecfb-4322-be0d-7356b8bb3b04": {
    "team": "1610612752",
    "year": "1950",
    "players": [
      "76210",
      "76404",
      "76655",
      "76773",
      "77218",
      "77346",
      "77425",
      "77537",
      "77968",
      "78153",
      "78405",
      "78638"
    ]
  },
  "9c8cdc7d-791d-4211-a59b-51e7400aff3d": {
    "team": "1610612752",
    "year": "1951",
    "players": [
      "76210",
      "76404",
      "76773",
      "77218",
      "77425",
      "77536",
      "77537",
      "78082",
      "78153",
      "78405",
      "78638"
    ]
  },
  "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac": {
    "team": "1610612752",
    "year": "1952",
    "players": [
      "76210",
      "76243",
      "76404",
      "76773",
      "77536",
      "77537",
      "77903",
      "78153",
      "78280",
      "78405",
      "78638"
    ]
  },
  "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d": {
    "team": "1610612752",
    "year": "1953",
    "players": [
      "76008",
      "76081",
      "76210",
      "76243",
      "76404",
      "76773",
      "77536",
      "77537",
      "78075",
      "78153",
      "78187",
      "78405"
    ]
  },
  "bd85e665-05e8-4770-a5d0-dba1fabfc9aa": {
    "team": "1610612752",
    "year": "1954",
    "players": [
      "76049",
      "76081",
      "76243",
      "76393",
      "76404",
      "76434",
      "76704",
      "76773",
      "76891",
      "76986",
      "77287",
      "77537",
      "77843",
      "78142",
      "78380"
    ]
  },
  "07b33c54-ebb1-4a81-b9e1-f1864b60cec8": {
    "team": "1610612752",
    "year": "1955",
    "players": [
      "76069",
      "76081",
      "76243",
      "76404",
      "76611",
      "76704",
      "76773",
      "77537",
      "77843",
      "78061",
      "78106",
      "78142",
      "78405"
    ]
  },
  "92e7e3b2-600e-499a-b1d1-968fbfa14e64": {
    "team": "1610612752",
    "year": "1956",
    "players": [
      "76081",
      "76156",
      "76243",
      "76404",
      "76704",
      "76773",
      "76902",
      "77210",
      "77537",
      "77693",
      "78106",
      "78128",
      "78216"
    ]
  },
  "6004a19c-3b95-40b2-b7d8-96f6b0ce494b": {
    "team": "1610612752",
    "year": "1957",
    "players": [
      "76243",
      "76704",
      "76758",
      "76902",
      "77089",
      "77500",
      "77693",
      "78106",
      "78128",
      "78216",
      "78223",
      "78391"
    ]
  },
  "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012": {
    "team": "1610612752",
    "year": "1958",
    "players": [
      "76168",
      "76243",
      "76245",
      "76694",
      "76704",
      "76902",
      "77500",
      "77693",
      "78106",
      "78113",
      "78216",
      "78223",
      "78391"
    ]
  },
  "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b": {
    "team": "1610612752",
    "year": "1959",
    "players": [
      "76034",
      "76143",
      "76243",
      "76694",
      "76800",
      "76867",
      "76902",
      "77500",
      "77693",
      "77792",
      "77906",
      "78106",
      "78216",
      "78391"
    ]
  },
  "2fb8b948-e857-417f-8af2-422f0825a7c1": {
    "team": "1610612752",
    "year": "1960",
    "players": [
      "76143",
      "76243",
      "76297",
      "76783",
      "76800",
      "76867",
      "76902",
      "77093",
      "77562",
      "77693",
      "77792",
      "78013",
      "78106",
      "78391"
    ]
  },
  "816164e0-e2ee-413e-8662-b51fbf40b841": {
    "team": "1610612752",
    "year": "1961",
    "players": [
      "76182",
      "76295",
      "76297",
      "76314",
      "76318",
      "76319",
      "76867",
      "76902",
      "77093",
      "77210",
      "77279",
      "77479",
      "77693",
      "78209",
      "78259"
    ]
  },
  "ba894f72-662a-4d86-8791-ddc3f2492c47": {
    "team": "1610612752",
    "year": "1962",
    "players": [
      "76295",
      "76297",
      "76318",
      "76319",
      "76428",
      "76729",
      "76828",
      "76867",
      "76902",
      "77038",
      "78037",
      "78142",
      "78260"
    ]
  },
  "67233742-0a5f-4ab9-a3f8-682956af416d": {
    "team": "1610612752",
    "year": "1963",
    "players": [
      "76206",
      "76297",
      "76319",
      "76382",
      "76428",
      "76647",
      "76828",
      "76867",
      "76950",
      "77015",
      "77051",
      "77531",
      "78037"
    ]
  },
  "7c3c8b10-137d-4f0f-8249-452818bc279f": {
    "team": "1610612752",
    "year": "1964",
    "players": [
      "76105",
      "76206",
      "76289",
      "76297",
      "76382",
      "76647",
      "76828",
      "76867",
      "77015",
      "77051",
      "77297",
      "77307",
      "77929"
    ]
  },
  "833e0d65-644b-4fde-b265-1327632fc59c": {
    "team": "1610612752",
    "year": "1965",
    "players": [
      "76105",
      "76107",
      "76144",
      "76289",
      "76382",
      "76403",
      "76647",
      "76828",
      "76867",
      "77297",
      "77929",
      "78238",
      "78398"
    ]
  },
  "054fdbaa-a097-480c-b2de-7711c6b6734b": {
    "team": "1610612752",
    "year": "1966",
    "players": [
      "76018",
      "76107",
      "76144",
      "76289",
      "76466",
      "76560",
      "77161",
      "77297",
      "77625",
      "77929",
      "78045",
      "78238",
      "78398"
    ]
  },
  "4d62aaac-8788-4aab-bdec-4738995edafd": {
    "team": "1610612752",
    "year": "1967",
    "players": [
      "76107",
      "76144",
      "76219",
      "76233",
      "76289",
      "76328",
      "76466",
      "76750",
      "77106",
      "77161",
      "77297",
      "77929",
      "78045",
      "78398"
    ]
  },
  "81553d34-2d0b-40e2-a229-d33af0d3a5f8": {
    "team": "1610612752",
    "year": "1968",
    "players": [
      "76107",
      "76219",
      "76233",
      "76750",
      "77061",
      "77106",
      "77489",
      "77929",
      "77966",
      "78045"
    ]
  },
  "3fb360bc-873c-4a39-b17b-8acdb404a070": {
    "team": "1610612752",
    "year": "1969",
    "players": [
      "76107",
      "76219",
      "76233",
      "76545",
      "76750",
      "77061",
      "77106",
      "77489",
      "77929",
      "77966",
      "78045",
      "78238",
      "78461"
    ]
  },
  "c89d57fe-9137-4336-8384-9435a1aab760": {
    "team": "1610612752",
    "year": "1970",
    "players": [
      "76107",
      "76233",
      "76545",
      "76715",
      "76750",
      "77106",
      "77483",
      "77884",
      "77929",
      "77966",
      "78045",
      "78238",
      "78560"
    ]
  },
  "b200486c-7ae8-4f2d-9cda-011f4c747d12": {
    "team": "1610612752",
    "year": "1971",
    "players": [
      "76107",
      "76233",
      "76545",
      "76715",
      "76750",
      "77106",
      "77418",
      "77483",
      "77578",
      "77596",
      "77884",
      "77929",
      "77966",
      "78238"
    ]
  },
  "096c68b6-fffe-4947-b2cc-873274da6b3b": {
    "team": "1610612752",
    "year": "1972",
    "players": [
      "76107",
      "76162",
      "76233",
      "76545",
      "76750",
      "76806",
      "77106",
      "77418",
      "77578",
      "77894",
      "77929",
      "77960",
      "78596",
      "600006"
    ]
  },
  "98a19076-a6e8-4bc0-b991-e249597a93b2": {
    "team": "1610612752",
    "year": "1973",
    "players": [
      "76107",
      "76142",
      "76162",
      "76233",
      "76529",
      "76545",
      "76750",
      "76788",
      "76806",
      "77106",
      "77418",
      "77535",
      "77578",
      "77929",
      "77960",
      "78596",
      "600006"
    ]
  },
  "95ba34ae-5f31-43cb-847c-c1a2a9782aaa": {
    "team": "1610612752",
    "year": "1974",
    "players": [
      "76142",
      "76233",
      "76509",
      "76529",
      "76750",
      "76806",
      "77106",
      "77875",
      "77960",
      "78238",
      "78596",
      "600006"
    ]
  },
  "52649402-55a4-4d97-a5fd-f7cf9779a672": {
    "team": "1610612752",
    "year": "1975",
    "players": [
      "76106",
      "76130",
      "76142",
      "76233",
      "76529",
      "76728",
      "76750",
      "76806",
      "76981",
      "77106",
      "77494",
      "78138",
      "78432",
      "78596",
      "600006"
    ]
  },
  "cf09c412-e6a0-40e9-bffe-8c9810d57c4c": {
    "team": "1610612752",
    "year": "1976",
    "players": [
      "76130",
      "76233",
      "76303",
      "76750",
      "76981",
      "77106",
      "77350",
      "77498",
      "77553",
      "77554",
      "77578",
      "78133",
      "78432",
      "600006"
    ]
  },
  "92a4fe2d-0b2a-433e-9c8a-aa51656713eb": {
    "team": "1610612752",
    "year": "1977",
    "players": [
      "76130",
      "76303",
      "76402",
      "76830",
      "76981",
      "77106",
      "77289",
      "77498",
      "77554",
      "78133",
      "78571",
      "600006"
    ]
  },
  "b001782c-95ad-444b-814b-98ecf23fa9ce": {
    "team": "1610612752",
    "year": "1978",
    "players": [
      "76099",
      "76130",
      "76300",
      "76402",
      "76822",
      "76830",
      "77289",
      "77582",
      "77952",
      "78036",
      "78481",
      "78571",
      "600006"
    ]
  },
  "8bc6e000-7cda-4b92-8998-a07f5c80c68d": {
    "team": "1610612752",
    "year": "1979",
    "players": [
      "76362",
      "76402",
      "76447",
      "76553",
      "76822",
      "77087",
      "77289",
      "77582",
      "77952",
      "78481",
      "78570",
      "78571",
      "600006"
    ]
  },
  "116bb713-d75c-4ec4-80e2-0f9ef482f36b": {
    "team": "1610612752",
    "year": "1980",
    "players": [
      "76360",
      "76362",
      "76553",
      "76822",
      "77952",
      "78047",
      "78068",
      "78481",
      "78570",
      "78571",
      "78614"
    ]
  },
  "ba72d0e2-ca41-42ee-aab5-931f1dda7bff": {
    "team": "1610612752",
    "year": "1981",
    "players": [
      "76228",
      "76360",
      "76362",
      "76447",
      "76553",
      "77289",
      "77420",
      "77710",
      "77952",
      "78047",
      "78068",
      "78200",
      "78481",
      "78500",
      "78570"
    ]
  },
  "6a10cc0f-c034-4cee-992e-4023a7280654": {
    "team": "1610612752",
    "year": "1982",
    "players": [
      "76362",
      "76530",
      "76899",
      "77264",
      "77770",
      "77993",
      "78136",
      "78303",
      "78375",
      "78481",
      "78500",
      "78570",
      "600009"
    ]
  },
  "c6d6c649-e897-4dc0-8a1d-78fd1a096757": {
    "team": "1610612752",
    "year": "1983",
    "players": [
      "76362",
      "76707",
      "76899",
      "77264",
      "77435",
      "77770",
      "77993",
      "78375",
      "78437",
      "78481",
      "78571",
      "600009",
      "600010"
    ]
  },
  "edf9e3a0-e760-40c6-b84c-fbc4efa6a152": {
    "team": "1610612752",
    "year": "1984",
    "players": [
      "76085",
      "76094",
      "76355",
      "76370",
      "76483",
      "76899",
      "77264",
      "77770",
      "77993",
      "78375",
      "78437",
      "78534",
      "600009"
    ]
  },
  "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa": {
    "team": "1610612752",
    "year": "1985",
    "players": [
      "121",
      "738",
      "786",
      "76085",
      "76094",
      "76362",
      "76411",
      "76483",
      "76868",
      "76899",
      "77560",
      "77770",
      "78375",
      "78437",
      "600009"
    ]
  },
  "b2182580-6b74-4d01-905a-961e5a283423": {
    "team": "1610612752",
    "year": "1986",
    "players": [
      "121",
      "738",
      "786",
      "76362",
      "76483",
      "76847",
      "76993",
      "77264",
      "77481",
      "77560",
      "77757",
      "77770",
      "78162",
      "78375",
      "78440",
      "78534",
      "78620",
      "600009"
    ]
  },
  "f4e181bd-77c9-4efb-9f15-b44531dc1d44": {
    "team": "1610612752",
    "year": "1987",
    "players": [
      "121",
      "271",
      "349",
      "786",
      "76342",
      "76362",
      "76483",
      "76586",
      "76876",
      "77560",
      "77770",
      "78102",
      "78352",
      "78375",
      "78440"
    ]
  },
  "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8": {
    "team": "1610612752",
    "year": "1988",
    "players": [
      "121",
      "271",
      "349",
      "393",
      "786",
      "891",
      "939",
      "76320",
      "76876",
      "78375",
      "78404",
      "78440",
      "78534"
    ]
  },
  "e3c84d66-79c8-4bee-9d21-c17271bd2581": {
    "team": "1610612752",
    "year": "1989",
    "players": [
      "121",
      "271",
      "349",
      "786",
      "891",
      "76320",
      "76385",
      "76861",
      "77893",
      "78375",
      "78404",
      "78440",
      "78534"
    ]
  },
  "efbce694-cabd-4657-b606-4479029ed83a": {
    "team": "1610612752",
    "year": "1990",
    "players": [
      "121",
      "285",
      "317",
      "349",
      "786",
      "891",
      "1054",
      "76385",
      "76861",
      "77893",
      "78375",
      "78404",
      "78440",
      "78534"
    ]
  },
  "67b5af29-cb30-4af3-aa8c-89db35f1c392": {
    "team": "1610612752",
    "year": "1991",
    "players": [
      "21",
      "121",
      "193",
      "317",
      "349",
      "786",
      "891",
      "1365",
      "76583",
      "76635",
      "77516",
      "77545",
      "78404",
      "78589"
    ]
  },
  "aa38ce66-0adc-43cf-85f8-4f26278982cb": {
    "team": "1610612752",
    "year": "1992",
    "players": [
      "21",
      "93",
      "121",
      "193",
      "293",
      "317",
      "470",
      "891",
      "944",
      "1006",
      "76176",
      "76338",
      "77260"
    ]
  },
  "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c": {
    "team": "1610612752",
    "year": "1993",
    "players": [
      "21",
      "41",
      "93",
      "121",
      "157",
      "193",
      "293",
      "317",
      "470",
      "891",
      "944",
      "1006",
      "76176",
      "76770"
    ]
  },
  "f069f784-df01-44a4-91d1-40aa38531c90": {
    "team": "1610612752",
    "year": "1994",
    "players": [
      "21",
      "41",
      "42",
      "57",
      "93",
      "97",
      "121",
      "157",
      "193",
      "293",
      "317",
      "369",
      "891",
      "1006"
    ]
  },
  "536e3edb-a661-42a2-b457-b070521bb61c": {
    "team": "1610612752",
    "year": "1995",
    "players": [
      "93",
      "97",
      "121",
      "157",
      "193",
      "202",
      "317",
      "335",
      "369",
      "462",
      "891",
      "1006"
    ]
  },
  "50e3d81f-1bce-4ff8-8547-7cc7832160f6": {
    "team": "1610612752",
    "year": "1996",
    "players": [
      "121",
      "164",
      "275",
      "317",
      "369",
      "418",
      "433",
      "891",
      "913",
      "961",
      "962",
      "1006"
    ]
  },
  "9c446324-5f9e-4bc7-adfe-2949d87cc9d7": {
    "team": "1610612752",
    "year": "1997",
    "players": [
      "121",
      "164",
      "168",
      "187",
      "194",
      "201",
      "275",
      "317",
      "369",
      "433",
      "891",
      "913"
    ]
  },
  "ca4e311a-018b-4a3f-a84c-249324a9e1ed": {
    "team": "1610612752",
    "year": "1998",
    "players": [
      "84",
      "121",
      "164",
      "201",
      "275",
      "369",
      "703",
      "766",
      "913",
      "948",
      "1006",
      "1594"
    ]
  },
  "38367904-90af-4a44-b7af-467809d3ee0d": {
    "team": "1610612752",
    "year": "1999",
    "players": [
      "84",
      "121",
      "164",
      "201",
      "275",
      "369",
      "457",
      "703",
      "913",
      "948",
      "961",
      "1594"
    ]
  },
  "0f731ab8-543b-4166-be23-b69ac91e3bb9": {
    "team": "1610612752",
    "year": "2000",
    "players": [
      "84",
      "275",
      "280",
      "349",
      "369",
      "703",
      "779",
      "913",
      "948",
      "969",
      "970",
      "1594",
      "2068"
    ]
  },
  "c2463e87-71de-4e61-9e19-5a14ef789b3a": {
    "team": "1610612752",
    "year": "2001",
    "players": [
      "84",
      "221",
      "275",
      "280",
      "349",
      "369",
      "458",
      "703",
      "969",
      "970",
      "1000",
      "1683"
    ]
  },
  "1fed5db0-b968-4bc2-b296-10632898c907": {
    "team": "1610612752",
    "year": "2002",
    "players": [
      "84",
      "221",
      "275",
      "369",
      "458",
      "686",
      "703",
      "969",
      "970",
      "998",
      "1000",
      "1720",
      "1753",
      "1906",
      "2068",
      "2421"
    ]
  },
  "958e2e11-1460-4482-a252-e109fa13aef6": {
    "team": "1610612752",
    "year": "2003",
    "players": [
      "87",
      "275",
      "358",
      "452",
      "703",
      "950",
      "970",
      "983",
      "1000",
      "1501",
      "1737",
      "2035",
      "2421",
      "2456",
      "2552"
    ]
  },
  "b6891896-8a10-4dba-b6e6-c01d5f85ba3b": {
    "team": "1610612752",
    "year": "2004",
    "players": [
      "703",
      "950",
      "966",
      "990",
      "1501",
      "1508",
      "1743",
      "1983",
      "2037",
      "2552",
      "2772",
      "2866"
    ]
  },
  "e552d1cb-2458-423d-a8d0-88f93f4640b2": {
    "team": "1610612752",
    "year": "2005",
    "players": [
      "147",
      "950",
      "990",
      "1508",
      "1744",
      "1883",
      "2037",
      "2047",
      "2137",
      "2201",
      "2417",
      "2866",
      "101112",
      "101126",
      "101135"
    ]
  },
  "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4": {
    "team": "1610612752",
    "year": "2006",
    "players": [
      "950",
      "990",
      "1509",
      "1744",
      "1883",
      "2037",
      "2047",
      "2201",
      "2407",
      "101112",
      "101126",
      "101135",
      "200764",
      "200776",
      "201043"
    ]
  },
  "2bf746ba-45da-4f5f-9b41-89612cebece8": {
    "team": "1610612752",
    "year": "2007",
    "players": [
      "950",
      "990",
      "1744",
      "2037",
      "2047",
      "2201",
      "2216",
      "2407",
      "2410",
      "101126",
      "101135",
      "200764",
      "200776",
      "201043",
      "201163"
    ]
  },
  "1c88500c-202c-426a-b9f7-cdaa825a0df9": {
    "team": "1610612752",
    "year": "2008",
    "players": [
      "1716",
      "1733",
      "1749",
      "2047",
      "2201",
      "2404",
      "2407",
      "2768",
      "101126",
      "101135",
      "200754",
      "201163",
      "201568",
      "201621",
      "201805"
    ]
  },
  "d52a9555-ff77-4418-a883-c18272a57975": {
    "team": "1610612752",
    "year": "2009",
    "players": [
      "1503",
      "1733",
      "1886",
      "2067",
      "2201",
      "2768",
      "2853",
      "101135",
      "200771",
      "201163",
      "201568",
      "201592",
      "201611",
      "201962"
    ]
  },
  "a52ff111-cd72-4740-8ea6-3f19233c78c0": {
    "team": "1610612752",
    "year": "2010",
    "players": [
      "1497",
      "1853",
      "2405",
      "2407",
      "2427",
      "2546",
      "101142",
      "200749",
      "200761",
      "200764",
      "201611",
      "201962",
      "201974",
      "202360",
      "202361"
    ]
  },
  "b4719220-3f70-4b0f-8fb8-bf85cac62a70": {
    "team": "1610612752",
    "year": "2011",
    "players": [
      "1710",
      "1884",
      "2199",
      "2405",
      "2407",
      "2429",
      "2546",
      "2747",
      "200779",
      "201962",
      "202361",
      "202366",
      "202391",
      "202697",
      "202725"
    ]
  },
  "b0f967e1-ee8b-4d1e-850f-b36d7650298f": {
    "team": "1610612752",
    "year": "2012",
    "players": [
      "467",
      "948",
      "2030",
      "2047",
      "2199",
      "2405",
      "2546",
      "2747",
      "2853",
      "101109",
      "200778",
      "200779",
      "202697",
      "203142",
      "203143"
    ]
  },
  "dfed58fd-d7c6-45f4-ba66-809742a9320b": {
    "team": "1610612752",
    "year": "2013",
    "players": [
      "1885",
      "2030",
      "2223",
      "2405",
      "2546",
      "2747",
      "101181",
      "200745",
      "200769",
      "201961",
      "202332",
      "202697",
      "202719",
      "203143",
      "203315",
      "203499",
      "203501"
    ]
  },
  "490624e3-33f6-49ad-b637-d7afef77804f": {
    "team": "1610612752",
    "year": "2014",
    "players": [
      "2546",
      "101181",
      "200745",
      "200811",
      "201160",
      "202332",
      "202498",
      "203112",
      "203144",
      "203495",
      "203499",
      "203501",
      "203921",
      "204037",
      "204038"
    ]
  },
  "f8dc0860-8831-4890-9e99-c896637ea3e3": {
    "team": "1610612752",
    "year": "2015",
    "players": [
      "2546",
      "2756",
      "101181",
      "200811",
      "201167",
      "201577",
      "202338",
      "202498",
      "202682",
      "203100",
      "203124",
      "203921",
      "204001",
      "204038",
      "1626170"
    ]
  },
  "8952c2a7-aa60-4f01-a85a-75c1361c70f5": {
    "team": "1610612752",
    "year": "2016",
    "players": [
      "2546",
      "2756",
      "201149",
      "201565",
      "201584",
      "202498",
      "203124",
      "203200",
      "204001",
      "1626184",
      "1626195",
      "1626254",
      "1627758",
      "1627850",
      "1627851"
    ]
  },
  "62811488-fdf8-4824-9860-e73dfc40e088": {
    "team": "1610612752",
    "year": "2017",
    "players": [
      "101127",
      "201149",
      "201563",
      "201584",
      "202498",
      "202683",
      "203124",
      "203501",
      "203504",
      "204001",
      "1626144",
      "1627758",
      "1627786",
      "1628373",
      "1628422",
      "1628436",
      "1628439"
    ]
  },
  "7bb11b5b-69f1-4190-9b23-8b26f69f1880": {
    "team": "1610612752",
    "year": "2018",
    "players": [
      "201599",
      "202498",
      "203098",
      "203943",
      "1626144",
      "1626209",
      "1627740",
      "1628372",
      "1628373",
      "1628422",
      "1628436",
      "1628439",
      "1628443",
      "1628656",
      "1628995",
      "1629011",
      "1629019"
    ]
  },
  "d03b2c81-799a-49e6-9f1f-c1b8c4918a89": {
    "team": "1610612752",
    "year": "2019",
    "players": [
      "201959",
      "201961",
      "203090",
      "203493",
      "203901",
      "203944",
      "1626171",
      "1628372",
      "1628373",
      "1628422",
      "1628443",
      "1628995",
      "1629011",
      "1629019",
      "1629624",
      "1629628",
      "1629649"
    ]
  },
  "5e5ac014-7b8a-4554-a779-d450c1b517cd": {
    "team": "1610612753",
    "year": "1989",
    "players": [
      "98",
      "101",
      "767",
      "76009",
      "76050",
      "76453",
      "76876",
      "77946",
      "78198",
      "78310",
      "78381",
      "78417",
      "78528"
    ]
  },
  "139cf619-7d06-46d0-8eb5-7beaf7afe964": {
    "team": "1610612753",
    "year": "1990",
    "players": [
      "98",
      "101",
      "192",
      "767",
      "1123",
      "76009",
      "76050",
      "77558",
      "77946",
      "78198",
      "78381",
      "78417",
      "78528"
    ]
  },
  "8dabde25-68d6-46b8-af0a-488c49653a62": {
    "team": "1610612753",
    "year": "1991",
    "players": [
      "98",
      "101",
      "183",
      "192",
      "194",
      "767",
      "914",
      "925",
      "1123",
      "76009",
      "76450",
      "77946",
      "78198",
      "78381",
      "78417"
    ]
  },
  "e140a3b1-5989-4956-a54f-45f979d33ba1": {
    "team": "1610612753",
    "year": "1992",
    "players": [
      "70",
      "98",
      "101",
      "140",
      "183",
      "192",
      "194",
      "406",
      "767",
      "1036",
      "1123",
      "78347",
      "78381",
      "78621"
    ]
  },
  "8a1dd266-db70-441b-b5dd-519d06aedf50": {
    "team": "1610612753",
    "year": "1993",
    "players": [
      "98",
      "101",
      "138",
      "140",
      "192",
      "194",
      "358",
      "406",
      "700",
      "903",
      "1036",
      "1123",
      "1474",
      "78014",
      "78381"
    ]
  },
  "a953bcdf-106a-4d19-bcd4-22e0a8abbe14": {
    "team": "1610612753",
    "year": "1994",
    "players": [
      "98",
      "138",
      "140",
      "192",
      "194",
      "216",
      "240",
      "270",
      "353",
      "358",
      "406",
      "700",
      "903",
      "78014",
      "78381"
    ]
  },
  "1a590baf-faf7-46e2-a591-e9ff55372b10": {
    "team": "1610612753",
    "year": "1995",
    "players": [
      "41",
      "98",
      "140",
      "192",
      "194",
      "216",
      "240",
      "270",
      "341",
      "353",
      "358",
      "402",
      "406",
      "710",
      "78381"
    ]
  },
  "4b67a5bb-ca70-47aa-9934-95aef577269a": {
    "team": "1610612753",
    "year": "1996",
    "players": [
      "7",
      "98",
      "192",
      "216",
      "270",
      "353",
      "358",
      "400",
      "786",
      "938",
      "967",
      "1043"
    ]
  },
  "34e56408-94b7-440b-a1ad-f7ecbb56e21b": {
    "team": "1610612753",
    "year": "1997",
    "players": [
      "7",
      "98",
      "128",
      "157",
      "270",
      "400",
      "448",
      "786",
      "899",
      "1511",
      "1535",
      "1563"
    ]
  },
  "bc2b5072-fbcc-4964-9813-750dd05c3fac": {
    "team": "1610612753",
    "year": "1998",
    "players": [
      "7",
      "98",
      "270",
      "353",
      "358",
      "400",
      "448",
      "769",
      "1122",
      "1134",
      "1720",
      "1723"
    ]
  },
  "0e8a0256-38ef-4041-b0d7-cb1359c01e10": {
    "team": "1610612753",
    "year": "1999",
    "players": [
      "42",
      "353",
      "400",
      "448",
      "680",
      "1088",
      "1500",
      "1511",
      "1533",
      "1720",
      "1727",
      "1894"
    ]
  },
  "58327b11-17d0-4efc-a314-62009ef21c2a": {
    "team": "1610612753",
    "year": "2000",
    "players": [
      "42",
      "244",
      "353",
      "448",
      "680",
      "690",
      "692",
      "1503",
      "1607",
      "1720",
      "1727",
      "2034"
    ]
  },
  "5b743435-d30b-4ed8-b575-f58b32c5af35": {
    "team": "1610612753",
    "year": "2001",
    "players": [
      "42",
      "121",
      "146",
      "270",
      "353",
      "690",
      "692",
      "1114",
      "1503",
      "1607",
      "1727",
      "2034",
      "2212",
      "2219"
    ]
  },
  "40e68a58-8b3f-418c-8207-7bb3574d4098": {
    "team": "1610612753",
    "year": "2002",
    "players": [
      "255",
      "270",
      "353",
      "431",
      "692",
      "1503",
      "1521",
      "1727",
      "1918",
      "2034",
      "2071",
      "2212",
      "2219",
      "2241",
      "2415",
      "2469"
    ]
  },
  "bd150971-76b2-441a-a1ec-6d5b3d4a28a6": {
    "team": "1610612753",
    "year": "2003",
    "players": [
      "255",
      "399",
      "436",
      "692",
      "1503",
      "1727",
      "1731",
      "1760",
      "2052",
      "2212",
      "2400",
      "2558",
      "2585",
      "2586",
      "2639"
    ]
  },
  "90c13ad0-73d1-41cd-ab8b-53372f677c75": {
    "team": "1610612753",
    "year": "2004",
    "players": [
      "278",
      "1499",
      "1509",
      "1727",
      "1883",
      "2052",
      "2435",
      "2599",
      "2730",
      "2749",
      "2857",
      "2891"
    ]
  },
  "d29615e8-492c-4cfb-b435-dbf0fac373f2": {
    "team": "1610612753",
    "year": "2005",
    "players": [
      "255",
      "278",
      "448",
      "1499",
      "1727",
      "2039",
      "2045",
      "2052",
      "2306",
      "2435",
      "2545",
      "2730",
      "2749",
      "2772",
      "101143"
    ]
  },
  "6c5fba75-facf-42a7-a037-48dd10294ade": {
    "team": "1610612753",
    "year": "2006",
    "players": [
      "255",
      "448",
      "1499",
      "1727",
      "2039",
      "2045",
      "2306",
      "2545",
      "2586",
      "2730",
      "2749",
      "2772",
      "101143",
      "200755",
      "200788"
    ]
  },
  "028a8849-766d-4111-bb14-ff1df46068c5": {
    "team": "1610612753",
    "year": "2007",
    "players": [
      "1499",
      "1502",
      "1727",
      "1740",
      "2039",
      "2045",
      "2230",
      "2306",
      "2567",
      "2586",
      "2730",
      "2749",
      "101162",
      "200755",
      "200788"
    ]
  },
  "52121faf-ebcf-4269-8b4a-f0484668809c": {
    "team": "1610612753",
    "year": "2008",
    "players": [
      "1499",
      "1502",
      "1533",
      "1731",
      "1740",
      "1747",
      "2045",
      "2554",
      "2730",
      "2749",
      "101162",
      "200755",
      "200978",
      "201584"
    ]
  },
  "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1": {
    "team": "1610612753",
    "year": "2009",
    "players": [
      "1502",
      "1533",
      "1713",
      "1715",
      "1740",
      "2440",
      "2554",
      "2730",
      "2749",
      "101138",
      "101162",
      "200755",
      "201583"
    ]
  },
  "a13aadde-4723-4b90-b05c-185b76537abe": {
    "team": "1610612753",
    "year": "2010",
    "players": [
      "2045",
      "2047",
      "2124",
      "2202",
      "2240",
      "2730",
      "2749",
      "2768",
      "101138",
      "200755",
      "201583",
      "201947",
      "202350"
    ]
  },
  "e938ceb5-43c8-48c0-9214-12c48bc2b2d7": {
    "team": "1610612753",
    "year": "2011",
    "players": [
      "2045",
      "2047",
      "2202",
      "2730",
      "2749",
      "2768",
      "101144",
      "200755",
      "201175",
      "201583",
      "201947",
      "202350",
      "202397",
      "202712",
      "202732"
    ]
  },
  "286981d2-4163-449b-ae90-84e38b5204fc": {
    "team": "1610612753",
    "year": "2012",
    "players": [
      "1733",
      "2045",
      "2749",
      "2757",
      "201167",
      "201175",
      "202696",
      "202699",
      "202734",
      "203090",
      "203094",
      "203117",
      "203124",
      "203199"
    ]
  },
  "5fff07e8-267c-43b9-9b16-95da13845b0e": {
    "team": "1610612753",
    "year": "2013",
    "players": [
      "2749",
      "101131",
      "101179",
      "201167",
      "202696",
      "202699",
      "202734",
      "203090",
      "203094",
      "203117",
      "203124",
      "203473",
      "203506"
    ]
  },
  "a77872d3-628b-475d-95a3-5a07cf809667": {
    "team": "1610612753",
    "year": "2014",
    "players": [
      "2557",
      "2584",
      "2732",
      "101112",
      "202696",
      "202699",
      "203090",
      "203094",
      "203095",
      "203124",
      "203473",
      "203506",
      "203901",
      "203906",
      "203932"
    ]
  },
  "ef7374a1-417b-4cf4-b556-208b918b32d6": {
    "team": "1610612753",
    "year": "2015",
    "players": [
      "101141",
      "201160",
      "201228",
      "201943",
      "202696",
      "203094",
      "203095",
      "203473",
      "203506",
      "203894",
      "203901",
      "203906",
      "203932",
      "1626209"
    ]
  },
  "dfe8eef2-e042-4ae8-ba9a-ab496354b3da": {
    "team": "1610612753",
    "year": "2016",
    "players": [
      "201145",
      "201228",
      "201571",
      "201975",
      "202687",
      "202696",
      "203082",
      "203095",
      "203901",
      "203932",
      "204014",
      "1626209",
      "1627757",
      "1627868",
      "1627875"
    ]
  },
  "65d4beea-3e80-42e8-87ae-d493354f9056": {
    "team": "1610612753",
    "year": "2017",
    "players": [
      "201167",
      "201571",
      "201578",
      "202687",
      "202696",
      "203082",
      "203095",
      "203613",
      "203920",
      "203932",
      "1626209",
      "1628371",
      "1628411",
      "1628503",
      "1628681"
    ]
  },
  "77986a72-d455-47c5-bf8a-92d32f6e27b9": {
    "team": "1610612753",
    "year": "2018",
    "players": [
      "201571",
      "202389",
      "202696",
      "203082",
      "203095",
      "203487",
      "203920",
      "203932",
      "1626170",
      "1626185",
      "1628365",
      "1628371",
      "1628411",
      "1628505",
      "1628518",
      "1628964",
      "1628982"
    ]
  },
  "320943e2-fd6a-4354-a265-fc9b02f5ab63": {
    "team": "1610612753",
    "year": "2019",
    "players": [
      "201571",
      "202329",
      "202696",
      "203082",
      "203095",
      "203487",
      "203516",
      "203920",
      "203932",
      "1628365",
      "1628371",
      "1628411",
      "1628964",
      "1628982",
      "1629109",
      "1629168",
      "1629724"
    ]
  },
  "d2f4a19d-2dc4-4849-a134-4dd576dade5d": {
    "team": "1610612754",
    "year": "1976",
    "players": [
      "76040",
      "76151",
      "76317",
      "76667",
      "76727",
      "76878",
      "76910",
      "77028",
      "77201",
      "77290",
      "77380",
      "77998",
      "78025",
      "78575",
      "600010"
    ]
  },
  "758e5736-0f3d-4311-9782-923d1ff9cbc0": {
    "team": "1610612754",
    "year": "1977",
    "players": [
      "229",
      "76095",
      "76140",
      "76151",
      "76352",
      "76727",
      "76878",
      "77705",
      "77998",
      "78025",
      "78208",
      "78215",
      "78294",
      "78585",
      "600010"
    ]
  },
  "b53d3c18-a4f1-40bf-a266-2555fa606018": {
    "team": "1610612754",
    "year": "1978",
    "players": [
      "229",
      "76095",
      "76330",
      "76516",
      "76526",
      "76673",
      "76878",
      "77290",
      "77897",
      "78215",
      "600010"
    ]
  },
  "d3da55d4-ca59-42db-b517-15302adf51f1": {
    "team": "1610612754",
    "year": "1979",
    "players": [
      "229",
      "76095",
      "76231",
      "76330",
      "76361",
      "76386",
      "76526",
      "76966",
      "77136",
      "77168",
      "77290",
      "77320",
      "77532",
      "78643"
    ]
  },
  "321bccfb-8638-4330-a1da-00ee8d4007e8": {
    "team": "1610612754",
    "year": "1980",
    "players": [
      "229",
      "76005",
      "76095",
      "76231",
      "76317",
      "76526",
      "77136",
      "77148",
      "77290",
      "77532",
      "77692",
      "77770",
      "78146"
    ]
  },
  "ac1fa6b8-5cf5-4d30-badf-99be378ae917": {
    "team": "1610612754",
    "year": "1981",
    "players": [
      "1006",
      "76317",
      "76355",
      "76526",
      "77136",
      "77148",
      "77290",
      "77532",
      "77770",
      "77786",
      "78146",
      "78365"
    ]
  },
  "c24811b3-b1da-43ab-9ab2-9a68328f351c": {
    "team": "1610612754",
    "year": "1982",
    "players": [
      "1006",
      "76239",
      "76321",
      "76355",
      "76619",
      "77148",
      "77236",
      "77290",
      "77640",
      "78087",
      "78146",
      "78172"
    ]
  },
  "84846342-36c1-4dc5-96ab-243c663b2065": {
    "team": "1610612754",
    "year": "1983",
    "players": [
      "1006",
      "76355",
      "76425",
      "77110",
      "77148",
      "77236",
      "77318",
      "77415",
      "77541",
      "78146",
      "78250",
      "78257",
      "78320",
      "78430"
    ]
  },
  "85ec4e60-957a-4941-aa38-d850c52dbd29": {
    "team": "1610612754",
    "year": "1984",
    "players": [
      "313",
      "1006",
      "76268",
      "76621",
      "76637",
      "76785",
      "76861",
      "77107",
      "77236",
      "77239",
      "78146",
      "78241",
      "78257",
      "78320",
      "78430"
    ]
  },
  "04c77a5b-af49-4a23-a09d-009b7ad9eabd": {
    "team": "1610612754",
    "year": "1985",
    "players": [
      "47",
      "313",
      "1006",
      "76046",
      "76296",
      "76785",
      "76861",
      "77236",
      "77481",
      "77509",
      "77950",
      "78241",
      "78257"
    ]
  },
  "29876b5c-fbfc-46fc-9f3b-279e3667230a": {
    "team": "1610612754",
    "year": "1986",
    "players": [
      "47",
      "313",
      "456",
      "1006",
      "1068",
      "1381",
      "76046",
      "76266",
      "76861",
      "77236",
      "77438",
      "77950",
      "78048",
      "78257",
      "78414"
    ]
  },
  "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3": {
    "team": "1610612754",
    "year": "1987",
    "players": [
      "47",
      "101",
      "313",
      "397",
      "456",
      "1006",
      "1068",
      "1381",
      "76046",
      "76861",
      "78031",
      "78257",
      "78504"
    ]
  },
  "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5": {
    "team": "1610612754",
    "year": "1988",
    "players": [
      "22",
      "78",
      "96",
      "101",
      "313",
      "397",
      "456",
      "1068",
      "1456",
      "76752",
      "76861",
      "77652",
      "78247",
      "78352"
    ]
  },
  "ce5d240f-959a-498b-8cec-8d0416972633": {
    "team": "1610612754",
    "year": "1989",
    "players": [
      "22",
      "45",
      "78",
      "96",
      "313",
      "397",
      "456",
      "1068",
      "1456",
      "76874",
      "77691",
      "77720",
      "78059"
    ]
  },
  "6a844b97-e06e-4e01-8c3c-e52f881fa4c3": {
    "team": "1610612754",
    "year": "1990",
    "players": [
      "22",
      "45",
      "52",
      "78",
      "96",
      "313",
      "397",
      "456",
      "1068",
      "1456",
      "76574",
      "77757",
      "78059",
      "78555"
    ]
  },
  "8cdb62b7-9540-440c-b349-e1dc0adefb98": {
    "team": "1610612754",
    "year": "1991",
    "players": [
      "22",
      "45",
      "52",
      "78",
      "96",
      "313",
      "397",
      "456",
      "905",
      "1068",
      "1456",
      "76875",
      "78555"
    ]
  },
  "8214cfa1-0339-49d6-9f2a-32f550179000": {
    "team": "1610612754",
    "year": "1992",
    "players": [
      "22",
      "45",
      "71",
      "78",
      "96",
      "313",
      "397",
      "417",
      "905",
      "907",
      "1068",
      "76875",
      "78555"
    ]
  },
  "044dce90-7443-4814-8454-a974b791ec76": {
    "team": "1610612754",
    "year": "1993",
    "players": [
      "2",
      "22",
      "71",
      "78",
      "213",
      "313",
      "321",
      "365",
      "397",
      "417",
      "905",
      "906",
      "907",
      "76431",
      "78555"
    ]
  },
  "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca": {
    "team": "1610612754",
    "year": "1994",
    "players": [
      "2",
      "22",
      "78",
      "213",
      "273",
      "313",
      "349",
      "365",
      "397",
      "417",
      "905",
      "906",
      "1110",
      "1123",
      "78554"
    ]
  },
  "6eed6f6d-540f-4623-9701-f237a7a62728": {
    "team": "1610612754",
    "year": "1995",
    "players": [
      "22",
      "154",
      "213",
      "273",
      "349",
      "365",
      "368",
      "397",
      "696",
      "697",
      "698",
      "894",
      "905",
      "906"
    ]
  },
  "d9fa9226-92c2-4798-93b6-dff7c00c0b8f": {
    "team": "1610612754",
    "year": "1996",
    "players": [
      "22",
      "147",
      "213",
      "273",
      "349",
      "397",
      "696",
      "697",
      "905",
      "956",
      "1032",
      "1064"
    ]
  },
  "8b339a5a-034a-4b56-be5d-5c7a5b869c0f": {
    "team": "1610612754",
    "year": "1997",
    "players": [
      "22",
      "147",
      "213",
      "349",
      "365",
      "397",
      "696",
      "697",
      "770",
      "904",
      "905",
      "998"
    ]
  },
  "8fb79121-88d0-4730-abb0-a8f76039384e": {
    "team": "1610612754",
    "year": "1998",
    "players": [
      "22",
      "64",
      "147",
      "213",
      "349",
      "365",
      "397",
      "696",
      "697",
      "904",
      "905",
      "1506"
    ]
  },
  "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2": {
    "team": "1610612754",
    "year": "1999",
    "players": [
      "22",
      "64",
      "147",
      "349",
      "365",
      "397",
      "440",
      "696",
      "904",
      "905",
      "1506",
      "1886"
    ]
  },
  "d05863bb-8dce-49be-840f-100a7de3cc34": {
    "team": "1610612754",
    "year": "2000",
    "players": [
      "64",
      "147",
      "365",
      "397",
      "440",
      "696",
      "721",
      "979",
      "1506",
      "1733",
      "1886",
      "1902"
    ]
  },
  "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff": {
    "team": "1610612754",
    "year": "2001",
    "players": [
      "397",
      "435",
      "979",
      "1500",
      "1506",
      "1563",
      "1743",
      "1802",
      "1886",
      "1897",
      "1902",
      "2056",
      "2224",
      "2249"
    ]
  },
  "ce4310a5-7984-4648-bf19-4667a3f014f1": {
    "team": "1610612754",
    "year": "2002",
    "players": [
      "323",
      "397",
      "932",
      "979",
      "1065",
      "1500",
      "1506",
      "1733",
      "1802",
      "1886",
      "1897",
      "1902",
      "2056",
      "2224",
      "2249",
      "2410"
    ]
  },
  "5fa09823-d32b-4dc4-8f59-662596e140c9": {
    "team": "1610612754",
    "year": "2003",
    "players": [
      "72",
      "397",
      "979",
      "1506",
      "1513",
      "1533",
      "1733",
      "1886",
      "1897",
      "1902",
      "2056",
      "2224",
      "2249",
      "2410",
      "2592"
    ]
  },
  "428529ad-4841-486d-bcc4-9924eafbec27": {
    "team": "1610612754",
    "year": "2004",
    "players": [
      "397",
      "905",
      "979",
      "1506",
      "1513",
      "1533",
      "1536",
      "1897",
      "1902",
      "2109",
      "2224",
      "2410",
      "2592",
      "2823"
    ]
  },
  "3d331f49-e3c4-41db-8e85-694ca2e24972": {
    "team": "1610612754",
    "year": "2005",
    "players": [
      "978",
      "979",
      "1506",
      "1513",
      "1533",
      "1536",
      "1902",
      "2109",
      "2224",
      "2410",
      "2758",
      "101122",
      "101180"
    ]
  },
  "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e": {
    "team": "1610612754",
    "year": "2006",
    "players": [
      "353",
      "979",
      "1766",
      "1902",
      "2211",
      "2224",
      "2399",
      "2605",
      "2693",
      "2758",
      "101113",
      "101122",
      "101158",
      "101185",
      "200761"
    ]
  },
  "23eb2ba2-6730-48ab-83ac-946c3e799268": {
    "team": "1610612754",
    "year": "2007",
    "players": [
      "979",
      "1902",
      "2211",
      "2224",
      "2399",
      "2416",
      "2436",
      "2605",
      "2758",
      "101113",
      "101122",
      "101143",
      "101211",
      "101261",
      "200761"
    ]
  },
  "dbea6066-cd75-4d65-9f35-343a7e0b5413": {
    "team": "1610612754",
    "year": "2008",
    "players": [
      "1725",
      "1766",
      "1902",
      "2211",
      "2224",
      "2399",
      "2551",
      "2605",
      "101122",
      "101127",
      "101143",
      "101211",
      "201177",
      "201575",
      "201579"
    ]
  },
  "2b6a416e-d405-4fbc-b5be-5f2c557d5e21": {
    "team": "1610612754",
    "year": "2009",
    "players": [
      "1902",
      "2211",
      "2248",
      "2399",
      "2551",
      "2563",
      "101122",
      "101129",
      "200780",
      "201177",
      "201575",
      "201579",
      "201946",
      "201985"
    ]
  },
  "6b39df4c-f728-4eb1-a069-b6973299bd78": {
    "team": "1610612754",
    "year": "2010",
    "players": [
      "1899",
      "1902",
      "2399",
      "2551",
      "2563",
      "101122",
      "200780",
      "201177",
      "201575",
      "201579",
      "201946",
      "201954",
      "201985",
      "202331",
      "202362"
    ]
  },
  "18ccc342-b735-4763-bc05-89b9f428f609": {
    "team": "1610612754",
    "year": "2011",
    "players": [
      "1902",
      "2561",
      "2563",
      "2571",
      "101122",
      "200811",
      "201178",
      "201579",
      "201588",
      "201946",
      "201954",
      "201965",
      "201985",
      "202331",
      "202362"
    ]
  },
  "1af6fcdb-4544-4bb5-b5db-da7deaa96456": {
    "team": "1610612754",
    "year": "2012",
    "players": [
      "2561",
      "101122",
      "101123",
      "101133",
      "201571",
      "201579",
      "201588",
      "201946",
      "201965",
      "201970",
      "202331",
      "202362",
      "203101",
      "203111",
      "203162"
    ]
  },
  "7bfdc927-91d7-4515-ba15-1c25c356b27c": {
    "team": "1610612754",
    "year": "2013",
    "players": [
      "2446",
      "2449",
      "2561",
      "101115",
      "101133",
      "201228",
      "201579",
      "201588",
      "202323",
      "202331",
      "202362",
      "202388",
      "202730",
      "203142",
      "203524"
    ]
  },
  "e51ac3aa-e071-4c99-a480-5254e51cb1d3": {
    "team": "1610612754",
    "year": "2014",
    "players": [
      "2449",
      "2561",
      "101133",
      "101139",
      "201155",
      "201228",
      "201579",
      "201588",
      "202331",
      "202388",
      "202730",
      "203142",
      "203524",
      "203963",
      "204014"
    ]
  },
  "4a8979df-b35b-47d6-acbf-06105e98a732": {
    "team": "1610612754",
    "year": "2015",
    "players": [
      "101133",
      "101139",
      "101145",
      "201155",
      "201588",
      "201941",
      "201951",
      "202331",
      "202730",
      "203524",
      "203922",
      "203963",
      "1626167",
      "1626176",
      "1626202"
    ]
  },
  "2107ed47-f77b-4c98-acfc-e2fbe052aaea": {
    "team": "1610612754",
    "year": "2016",
    "players": [
      "2744",
      "101139",
      "101145",
      "201152",
      "201166",
      "201952",
      "202331",
      "202338",
      "202362",
      "202730",
      "203922",
      "1626167",
      "1626176",
      "1626202",
      "1627777"
    ]
  },
  "471119b9-d0fc-4ba2-a21c-d6635ae5d18e": {
    "team": "1610612754",
    "year": "2017",
    "players": [
      "2744",
      "201152",
      "201954",
      "202344",
      "202362",
      "202709",
      "202711",
      "203506",
      "203922",
      "1626167",
      "1626202",
      "1627734",
      "1627816",
      "1628387",
      "1628388",
      "1628410",
      "1628500"
    ]
  },
  "5bc8153c-5498-47f3-b9ab-73dd9a009945": {
    "team": "1610612754",
    "year": "2018",
    "players": [
      "201152",
      "201954",
      "202083",
      "202709",
      "202711",
      "203124",
      "203506",
      "203926",
      "1626167",
      "1627734",
      "1628388",
      "1628410",
      "1628432",
      "1628988",
      "1628993"
    ]
  },
  "58b309fd-0cb6-4071-8912-360baeb068ca": {
    "team": "1610612754",
    "year": "2019",
    "players": [
      "203087",
      "203200",
      "203506",
      "203926",
      "203933",
      "203960",
      "204456",
      "1626167",
      "1627734",
      "1627763",
      "1628388",
      "1628410",
      "1628513",
      "1628968",
      "1628988",
      "1628993",
      "1629048"
    ]
  },
  "edd1e6a4-e5e5-42f0-a74a-cea065f94d71": {
    "team": "1610612755",
    "year": "1949",
    "players": [
      "76373",
      "76391",
      "76452",
      "76768",
      "76937",
      "77375",
      "77436",
      "77841",
      "77913",
      "78076",
      "78117"
    ]
  },
  "98673c29-08ee-48ce-9891-81b0ab92d951": {
    "team": "1610612755",
    "year": "1950",
    "players": [
      "76326",
      "76373",
      "76391",
      "76768",
      "76937",
      "77212",
      "77436",
      "77913",
      "78076",
      "78094",
      "78117"
    ]
  },
  "740a5424-9663-4fb6-b4e0-0f0ffd429c5e": {
    "team": "1610612755",
    "year": "1951",
    "players": [
      "76326",
      "76373",
      "76768",
      "77212",
      "77268",
      "77775",
      "77913",
      "77999",
      "78066",
      "78076",
      "78117"
    ]
  },
  "b7540846-94ec-42ec-b008-ff241f8152ea": {
    "team": "1610612755",
    "year": "1952",
    "players": [
      "76241",
      "76373",
      "76768",
      "77212",
      "77268",
      "77394",
      "77397",
      "77775",
      "77999",
      "78076",
      "78117"
    ]
  },
  "1e38bf86-dd1f-48c5-a30a-fb663e2283e8": {
    "team": "1610612755",
    "year": "1953",
    "players": [
      "76630",
      "76768",
      "76837",
      "77247",
      "77268",
      "77292",
      "77347",
      "77394",
      "77482",
      "77695",
      "77736",
      "77775",
      "78076",
      "78117"
    ]
  },
  "bb00331b-e519-4f8f-b671-c89f34e10d57": {
    "team": "1610612755",
    "year": "1954",
    "players": [
      "76693",
      "76768",
      "77247",
      "77248",
      "77268",
      "77394",
      "77775",
      "77999",
      "78076",
      "78117",
      "78153",
      "78374"
    ]
  },
  "44a75fca-6d85-41a5-81a9-66a6b6ab9f10": {
    "team": "1610612755",
    "year": "1955",
    "players": [
      "76429",
      "76693",
      "77247",
      "77248",
      "77268",
      "77394",
      "77999",
      "78076",
      "78117",
      "78374"
    ]
  },
  "63ab2d0f-d691-48e0-9892-45f784ea03d5": {
    "team": "1610612755",
    "year": "1956",
    "players": [
      "76006",
      "76160",
      "76429",
      "76962",
      "77049",
      "77052",
      "77248",
      "77394",
      "77791",
      "77919",
      "78066",
      "78076",
      "78117",
      "78374"
    ]
  },
  "957840b6-7508-4ff9-8683-2a1f5f6f074a": {
    "team": "1610612755",
    "year": "1957",
    "players": [
      "76160",
      "76429",
      "76454",
      "76962",
      "77052",
      "77248",
      "77394",
      "77791",
      "78076",
      "78117"
    ]
  },
  "1dbc8441-2b65-4d63-a97c-fba1b28af868": {
    "team": "1610612755",
    "year": "1958",
    "players": [
      "76160",
      "76429",
      "76454",
      "76555",
      "76566",
      "76882",
      "77052",
      "77231",
      "77248",
      "77791",
      "78076",
      "78117"
    ]
  },
  "a45c9665-5951-47aa-ad90-2e2f872cee40": {
    "team": "1610612755",
    "year": "1959",
    "players": [
      "76107",
      "76160",
      "76324",
      "76454",
      "76566",
      "76882",
      "77052",
      "77248",
      "77791",
      "77919",
      "78076",
      "78117",
      "78628"
    ]
  },
  "22982acd-6ca6-4a1b-adc8-55a5f701d8b2": {
    "team": "1610612755",
    "year": "1960",
    "players": [
      "76107",
      "76160",
      "76324",
      "76454",
      "76774",
      "76882",
      "76919",
      "77248",
      "77906",
      "77977",
      "78076"
    ]
  },
  "b4492284-7d41-45e4-b3d7-fd93667614a1": {
    "team": "1610612755",
    "year": "1961",
    "players": [
      "76160",
      "76454",
      "76774",
      "76838",
      "76882",
      "76919",
      "77248",
      "77706",
      "77772",
      "77977",
      "78076",
      "78121"
    ]
  },
  "1ac58220-677b-4b39-88b8-e0f140cc4134": {
    "team": "1610612755",
    "year": "1962",
    "players": [
      "76160",
      "76382",
      "76454",
      "76774",
      "76882",
      "77248",
      "77583",
      "77706",
      "77977",
      "78076",
      "78121",
      "78435",
      "78458"
    ]
  },
  "084db916-a6ce-4651-bbc2-a41c3e21369a": {
    "team": "1610612755",
    "year": "1963",
    "players": [
      "76160",
      "76454",
      "76566",
      "76774",
      "76880",
      "76882",
      "77248",
      "77706",
      "78076",
      "78121",
      "78435",
      "78458",
      "78509"
    ]
  },
  "f63ac8fb-d11e-4170-9686-e89dcbf20b22": {
    "team": "1610612755",
    "year": "1964",
    "players": [
      "76160",
      "76454",
      "76459",
      "76774",
      "76880",
      "76882",
      "77103",
      "77200",
      "77248",
      "78435",
      "78458"
    ]
  },
  "343311f1-9773-47b8-ae18-71fe7ebdd3ca": {
    "team": "1610612755",
    "year": "1965",
    "players": [
      "76160",
      "76240",
      "76375",
      "76487",
      "76774",
      "76882",
      "77015",
      "77103",
      "77198",
      "78435",
      "78455",
      "78458",
      "78485"
    ]
  },
  "62fe75ba-1452-4175-8c96-ffc331d417fb": {
    "team": "1610612755",
    "year": "1966",
    "players": [
      "76375",
      "76454",
      "76487",
      "76774",
      "76882",
      "76908",
      "77103",
      "77198",
      "77576",
      "78435",
      "78485"
    ]
  },
  "56bc558b-98d9-4a75-9033-fbec8e9fe666": {
    "team": "1610612755",
    "year": "1967",
    "players": [
      "76375",
      "76454",
      "76487",
      "76714",
      "76882",
      "76908",
      "77103",
      "77198",
      "77576",
      "77935",
      "78435"
    ]
  },
  "e08b4914-59a6-4d7e-836c-db576c3c3723": {
    "team": "1610612755",
    "year": "1968",
    "players": [
      "76397",
      "76487",
      "76867",
      "76882",
      "76908",
      "76922",
      "77093",
      "77103",
      "77198",
      "77921",
      "78435"
    ]
  },
  "a8ccae69-3758-45d9-b363-2847b583dcf8": {
    "team": "1610612755",
    "year": "1969",
    "players": [
      "76397",
      "76487",
      "76882",
      "76908",
      "77012",
      "77093",
      "77103",
      "77198",
      "77747",
      "78088",
      "78466",
      "78579"
    ]
  },
  "8eeab17f-aa62-4d99-9e73-e5182968de63": {
    "team": "1610612755",
    "year": "1970",
    "players": [
      "76035",
      "76078",
      "76397",
      "76487",
      "76566",
      "76882",
      "76908",
      "77000",
      "77070",
      "77103",
      "77198",
      "77747",
      "78466"
    ]
  },
  "a66f3932-1385-40eb-a71f-3de93d22fbc1": {
    "team": "1610612755",
    "year": "1971",
    "players": [
      "76078",
      "76253",
      "76356",
      "76397",
      "76487",
      "76740",
      "76882",
      "77000",
      "77103",
      "77411",
      "78042",
      "78466",
      "78606",
      "78629"
    ]
  },
  "4eeaa401-c180-46ca-9ec8-b1442a9cfada": {
    "team": "1610612755",
    "year": "1972",
    "players": [
      "76222",
      "76253",
      "76356",
      "76458",
      "76663",
      "76872",
      "76882",
      "76923",
      "77351",
      "77411",
      "77489",
      "77884",
      "78084",
      "78220",
      "78367",
      "78399"
    ]
  },
  "992f03c2-845c-48f9-8e9d-5f3af3edf011": {
    "team": "1610612755",
    "year": "1973",
    "players": [
      "76222",
      "76257",
      "76339",
      "76356",
      "76421",
      "76663",
      "76756",
      "77200",
      "77259",
      "77489",
      "77618",
      "77894",
      "78399"
    ]
  },
  "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8": {
    "team": "1610612755",
    "year": "1974",
    "players": [
      "76222",
      "76257",
      "76356",
      "76365",
      "76421",
      "76487",
      "76622",
      "76663",
      "77357",
      "77618",
      "77729",
      "78184",
      "78370",
      "78454"
    ]
  },
  "41977d46-c447-4c82-8cb1-8952bd2b335e": {
    "team": "1610612755",
    "year": "1975",
    "players": [
      "76119",
      "76290",
      "76356",
      "76365",
      "76421",
      "76487",
      "76539",
      "76663",
      "76753",
      "77357",
      "77532",
      "77618",
      "77729"
    ]
  },
  "36aee3a5-2cc5-470b-8cd3-d51212e5b942": {
    "team": "1610612755",
    "year": "1976",
    "players": [
      "76106",
      "76162",
      "76290",
      "76365",
      "76421",
      "76539",
      "76616",
      "76681",
      "76753",
      "76767",
      "77175",
      "77532",
      "77618"
    ]
  },
  "c2941028-2be6-41b6-86ce-d32002b00893": {
    "team": "1610612755",
    "year": "1977",
    "players": [
      "76162",
      "76290",
      "76365",
      "76421",
      "76539",
      "76681",
      "76753",
      "77175",
      "77532",
      "77618",
      "77653"
    ]
  },
  "00e74371-e5e5-40e2-8c0f-ea6ea078dd79": {
    "team": "1610612755",
    "year": "1978",
    "players": [
      "76162",
      "76290",
      "76365",
      "76385",
      "76421",
      "76539",
      "76681",
      "77175",
      "77193",
      "77618",
      "78157"
    ]
  },
  "66ccd0f7-ed8e-49bb-9118-1ef961b339e4": {
    "team": "1610612755",
    "year": "1979",
    "players": [
      "76162",
      "76385",
      "76421",
      "76539",
      "76681",
      "77175",
      "77193",
      "77618",
      "77950",
      "78167",
      "78222",
      "78356"
    ]
  },
  "7495da12-4bc8-4edf-a24e-b24655504ff8": {
    "team": "1610612755",
    "year": "1980",
    "players": [
      "940",
      "76385",
      "76421",
      "76539",
      "76681",
      "77043",
      "77162",
      "77175",
      "77193",
      "77618",
      "77950",
      "78351"
    ]
  },
  "7e9a1af7-4f46-430d-8892-71c61f76cc90": {
    "team": "1610612755",
    "year": "1981",
    "players": [
      "940",
      "76095",
      "76385",
      "76539",
      "76641",
      "76681",
      "77043",
      "77162",
      "77175",
      "77193",
      "77618",
      "77950",
      "78351"
    ]
  },
  "bf317af6-865c-4121-a962-e203a54c91a3": {
    "team": "1610612755",
    "year": "1982",
    "players": [
      "940",
      "76385",
      "76641",
      "76681",
      "77092",
      "77136",
      "77163",
      "77193",
      "77449",
      "77558",
      "77950",
      "78351"
    ]
  },
  "cf936114-2a00-471e-8491-371c9e98f705": {
    "team": "1610612755",
    "year": "1983",
    "players": [
      "9",
      "279",
      "76385",
      "76641",
      "76681",
      "77092",
      "77136",
      "77193",
      "77449",
      "77484",
      "77916",
      "77950",
      "78351",
      "78569"
    ]
  },
  "a92bb844-1670-48c1-a7ad-5c4848bf2332": {
    "team": "1610612755",
    "year": "1984",
    "players": [
      "9",
      "787",
      "76385",
      "76681",
      "76980",
      "77136",
      "77148",
      "77193",
      "77449",
      "77950",
      "78351",
      "78569",
      "78611"
    ]
  },
  "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001": {
    "team": "1610612755",
    "year": "1985",
    "players": [
      "9",
      "767",
      "787",
      "76355",
      "76385",
      "76681",
      "77136",
      "77193",
      "77449",
      "77498",
      "78264",
      "78332",
      "78351",
      "78601",
      "78611",
      "78634"
    ]
  },
  "3d24a988-08f7-4aad-ae1e-c528947c7844": {
    "team": "1610612755",
    "year": "1986",
    "players": [
      "306",
      "766",
      "787",
      "76385",
      "76681",
      "76753",
      "76869",
      "77030",
      "77333",
      "77516",
      "77558",
      "77986",
      "78041",
      "78351",
      "78423"
    ]
  },
  "b42378b8-dafb-4789-b4ee-3aba286db3a9": {
    "team": "1610612755",
    "year": "1987",
    "players": [
      "355",
      "738",
      "766",
      "787",
      "1846",
      "76385",
      "76412",
      "76826",
      "76991",
      "76993",
      "77263",
      "77558",
      "77986",
      "78351",
      "78423"
    ]
  },
  "74e6c624-b880-43b1-8ff2-fb83fd0cedda": {
    "team": "1610612755",
    "year": "1988",
    "players": [
      "418",
      "738",
      "765",
      "766",
      "787",
      "1846",
      "2652",
      "76046",
      "76385",
      "76412",
      "76826",
      "76993",
      "77986",
      "78029",
      "78183"
    ]
  },
  "5dccfcf4-5eac-4345-af96-969f8232576c": {
    "team": "1610612755",
    "year": "1989",
    "players": [
      "328",
      "418",
      "738",
      "765",
      "787",
      "1008",
      "76046",
      "76448",
      "76770",
      "76826",
      "77719",
      "77822",
      "78140",
      "78183"
    ]
  },
  "a78ca2e7-4e0e-4c60-905a-10253e6311e9": {
    "team": "1610612755",
    "year": "1990",
    "players": [
      "288",
      "328",
      "420",
      "765",
      "787",
      "1008",
      "1680",
      "76046",
      "76195",
      "76874",
      "76961",
      "77053",
      "77822",
      "77936",
      "78377"
    ]
  },
  "6d174ccb-f4e3-4736-8cea-81a9bcec83a2": {
    "team": "1610612755",
    "year": "1991",
    "players": [
      "285",
      "288",
      "420",
      "765",
      "787",
      "1008",
      "1593",
      "1680",
      "76046",
      "76195",
      "77053",
      "77822",
      "78041",
      "78523"
    ]
  },
  "f646b106-33f9-49c7-9873-99a35bf12813": {
    "team": "1610612755",
    "year": "1992",
    "players": [
      "204",
      "221",
      "285",
      "288",
      "457",
      "765",
      "897",
      "1008",
      "1593",
      "76046",
      "76195",
      "77208",
      "77822",
      "78534"
    ]
  },
  "f8980f9a-2074-4965-8469-9086e1c70f28": {
    "team": "1610612755",
    "year": "1993",
    "players": [
      "37",
      "117",
      "221",
      "287",
      "344",
      "688",
      "762",
      "897",
      "1008",
      "1134",
      "76195",
      "76646",
      "77254",
      "77449",
      "78615"
    ]
  },
  "4290a9e9-24bd-4fec-ada4-6bfc734308c1": {
    "team": "1610612755",
    "year": "1994",
    "players": [
      "37",
      "81",
      "117",
      "189",
      "221",
      "281",
      "308",
      "344",
      "412",
      "416",
      "762",
      "897",
      "1114",
      "76001",
      "76732",
      "76770",
      "76952"
    ]
  },
  "3fde7d6c-cdb9-4c15-9771-931ab5279c62": {
    "team": "1610612755",
    "year": "1995",
    "players": [
      "30",
      "78",
      "101",
      "137",
      "221",
      "281",
      "291",
      "308",
      "357",
      "460",
      "711",
      "763",
      "777",
      "925",
      "934",
      "1027",
      "1120",
      "1479"
    ]
  },
  "9e3f5154-3fa3-44ca-a44f-98320c951308": {
    "team": "1610612755",
    "year": "1996",
    "players": [
      "77",
      "154",
      "221",
      "262",
      "281",
      "701",
      "707",
      "711",
      "934",
      "947",
      "971",
      "1361"
    ]
  },
  "135aed72-0bdb-4c31-818e-756fc1a923da": {
    "team": "1610612755",
    "year": "1997",
    "players": [
      "104",
      "243",
      "281",
      "689",
      "693",
      "707",
      "727",
      "934",
      "947",
      "1501",
      "1515",
      "1529"
    ]
  },
  "39fae275-e32e-40e1-aa2b-f961e2251fa2": {
    "team": "1610612755",
    "year": "1998",
    "players": [
      "238",
      "243",
      "248",
      "265",
      "328",
      "689",
      "727",
      "761",
      "947",
      "1532",
      "1716",
      "1737"
    ]
  },
  "bd5de45d-9c1a-49ff-918d-c586a109c7af": {
    "team": "1610612755",
    "year": "1999",
    "players": [
      "238",
      "243",
      "248",
      "389",
      "689",
      "727",
      "761",
      "947",
      "1085",
      "1563",
      "1908",
      "1928"
    ]
  },
  "81b6284b-5f88-485c-a000-31a7a960e90a": {
    "team": "1610612755",
    "year": "2000",
    "players": [
      "87",
      "238",
      "243",
      "248",
      "727",
      "761",
      "947",
      "1563",
      "1908",
      "1928",
      "1934",
      "1952"
    ]
  },
  "84b74c33-754c-4c8e-babe-0fe581adab51": {
    "team": "1610612755",
    "year": "2001",
    "players": [
      "87",
      "243",
      "365",
      "727",
      "916",
      "934",
      "947",
      "1723",
      "1907",
      "1952",
      "2049",
      "2074",
      "2223",
      "2245",
      "2264"
    ]
  },
  "8f3601f5-3c15-4767-80c3-9436658cb172": {
    "team": "1610612755",
    "year": "2002",
    "players": [
      "42",
      "95",
      "243",
      "727",
      "934",
      "947",
      "981",
      "1496",
      "1609",
      "1730",
      "1761",
      "1895",
      "1928",
      "2223",
      "2245",
      "2264",
      "2422",
      "2450"
    ]
  },
  "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd": {
    "team": "1610612755",
    "year": "2003",
    "players": [
      "243",
      "299",
      "727",
      "934",
      "947",
      "1043",
      "1531",
      "1761",
      "1903",
      "1928",
      "1985",
      "2223",
      "2422",
      "2584",
      "2594"
    ]
  },
  "31b7b9bc-492b-4509-99ca-b219bfcf6739": {
    "team": "1610612755",
    "year": "2004",
    "players": [
      "185",
      "243",
      "915",
      "947",
      "1531",
      "1563",
      "2214",
      "2223",
      "2422",
      "2584",
      "2594",
      "2668",
      "2738"
    ]
  },
  "1fa437ae-dc38-4d7c-a9de-8e845431d373": {
    "team": "1610612755",
    "year": "2005",
    "players": [
      "185",
      "947",
      "1563",
      "2212",
      "2214",
      "2223",
      "2422",
      "2440",
      "2584",
      "2594",
      "2738",
      "101150",
      "101183"
    ]
  },
  "2b6f0635-6784-4895-aaf2-6ce866b103d8": {
    "team": "1610612755",
    "year": "2006",
    "players": [
      "673",
      "693",
      "1563",
      "1889",
      "2212",
      "2223",
      "2584",
      "2594",
      "2738",
      "101150",
      "101183",
      "200760",
      "200784",
      "200811"
    ]
  },
  "18a2842d-ab87-49a7-87e8-257d7f5bfe8c": {
    "team": "1610612755",
    "year": "2007",
    "players": [
      "1563",
      "1889",
      "1916",
      "2223",
      "2501",
      "2584",
      "2738",
      "101150",
      "101183",
      "200760",
      "200811",
      "201152",
      "201160",
      "201195"
    ]
  },
  "c73e446c-a68a-4f40-895b-c4f1af1a5fb1": {
    "team": "1610612755",
    "year": "2008",
    "players": [
      "689",
      "923",
      "1882",
      "1889",
      "2223",
      "2416",
      "2501",
      "2584",
      "2738",
      "2767",
      "101150",
      "201152",
      "201160",
      "201578"
    ]
  },
  "5d899f8e-5e64-42dc-8573-762857c42fff": {
    "team": "1610612755",
    "year": "2009",
    "players": [
      "947",
      "1882",
      "1922",
      "2223",
      "2574",
      "2584",
      "2738",
      "101150",
      "200760",
      "201152",
      "201160",
      "201578",
      "201950",
      "201975"
    ]
  },
  "cd76ad8c-2906-4fbf-b478-25b1e443c7c2": {
    "team": "1610612755",
    "year": "2010",
    "players": [
      "1498",
      "1499",
      "1882",
      "2443",
      "2574",
      "2738",
      "2804",
      "101150",
      "201150",
      "201152",
      "201578",
      "201950",
      "201975",
      "202323",
      "202342"
    ]
  },
  "c57d0d27-d323-4563-82ba-db2c87f6ec68": {
    "team": "1610612755",
    "year": "2011",
    "players": [
      "1499",
      "1882",
      "2738",
      "101150",
      "201150",
      "201152",
      "201950",
      "201970",
      "201975",
      "202323",
      "202342",
      "202696",
      "202730",
      "202918"
    ]
  },
  "2216683d-34cb-4284-b507-8c9bf140d784": {
    "team": "1610612755",
    "year": "2012",
    "players": [
      "2198",
      "2202",
      "2748",
      "2767",
      "2863",
      "101115",
      "201150",
      "201152",
      "201156",
      "201950",
      "202323",
      "202724",
      "202730",
      "203102",
      "203200"
    ]
  },
  "341a849d-e970-4a90-8bee-8a209ea47cb1": {
    "team": "1610612755",
    "year": "2013",
    "players": [
      "2202",
      "201152",
      "201957",
      "202341",
      "202343",
      "202363",
      "203100",
      "203102",
      "203138",
      "203156",
      "203457",
      "203487",
      "203519",
      "203561",
      "203810"
    ]
  },
  "23894f15-3411-4056-9e26-0a374667fc93": {
    "team": "1610612755",
    "year": "2014",
    "players": [
      "2202",
      "201601",
      "202397",
      "203080",
      "203100",
      "203128",
      "203138",
      "203156",
      "203457",
      "203477",
      "203496",
      "203922",
      "203924",
      "203954",
      "203960"
    ]
  },
  "b4ff765f-4502-4299-a2e5-383cd5889dea": {
    "team": "1610612755",
    "year": "2015",
    "players": [
      "1882",
      "201171",
      "202397",
      "203088",
      "203138",
      "203457",
      "203477",
      "203496",
      "203917",
      "203924",
      "203954",
      "204456",
      "1626143",
      "1626158",
      "1626174"
    ]
  },
  "ebfee957-1808-4754-a8dc-3fafb2017bf2": {
    "team": "1610612755",
    "year": "2016",
    "players": [
      "200771",
      "201168",
      "201573",
      "201945",
      "203496",
      "203917",
      "203954",
      "203967",
      "204456",
      "1626143",
      "1626147",
      "1626158",
      "1627732",
      "1627789",
      "1627816",
      "1627848"
    ]
  },
  "c5dd1f08-5d86-4593-9196-a30b8080e6e0": {
    "team": "1610612755",
    "year": "2017",
    "players": [
      "101141",
      "101161",
      "200755",
      "201158",
      "201573",
      "203496",
      "203954",
      "203967",
      "204456",
      "1626147",
      "1626158",
      "1627732",
      "1627743",
      "1627788",
      "1627789",
      "1628365"
    ]
  },
  "5a728193-2607-49fa-b4d1-2298f8044312": {
    "team": "1610612755",
    "year": "2018",
    "players": [
      "101161",
      "200755",
      "202328",
      "202699",
      "202710",
      "203118",
      "203516",
      "203613",
      "203954",
      "204456",
      "1626246",
      "1627732",
      "1627788",
      "1628413",
      "1629003",
      "1629015",
      "1629312"
    ]
  },
  "04ce94c4-287c-4e35-bc8f-6f68f579fda7": {
    "team": "1610612755",
    "year": "2019",
    "players": [
      "201143",
      "202692",
      "202699",
      "203118",
      "203124",
      "203526",
      "203658",
      "203922",
      "203954",
      "1626196",
      "1627732",
      "1627788",
      "1629003",
      "1629015",
      "1629621",
      "1629680"
    ]
  },
  "20955b01-8d24-45f5-ba05-3a4253c801f7": {
    "team": "1610612756",
    "year": "1968",
    "players": [
      "76163",
      "76747",
      "76832",
      "76883",
      "77161",
      "77293",
      "77342",
      "77543",
      "78213",
      "78398",
      "78459",
      "78579"
    ]
  },
  "c5964149-f86c-413e-9d7c-c8d07f4113ef": {
    "team": "1610612756",
    "year": "1969",
    "players": [
      "76376",
      "76747",
      "76832",
      "76972",
      "77161",
      "77543",
      "78151",
      "78398",
      "78432",
      "600011"
    ]
  },
  "f5458f44-ac3e-4c53-a59d-73946dd09d03": {
    "team": "1610612756",
    "year": "1970",
    "players": [
      "76458",
      "76956",
      "76965",
      "76972",
      "77066",
      "78151",
      "78298",
      "78321",
      "78398",
      "78432",
      "78501",
      "600011"
    ]
  },
  "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e": {
    "team": "1610612756",
    "year": "1971",
    "players": [
      "76458",
      "76956",
      "76965",
      "76972",
      "77350",
      "77636",
      "78097",
      "78151",
      "78398",
      "78432",
      "78477",
      "78501",
      "600011"
    ]
  },
  "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c": {
    "team": "1610612756",
    "year": "1972",
    "players": [
      "76330",
      "76675",
      "76965",
      "76972",
      "77150",
      "77350",
      "78097",
      "78267",
      "78398",
      "78432",
      "78495",
      "600011"
    ]
  },
  "bd877b88-34bc-4231-95a1-6063a90eca41": {
    "team": "1610612756",
    "year": "1973",
    "players": [
      "76095",
      "76330",
      "76374",
      "76395",
      "76680",
      "76965",
      "76972",
      "77575",
      "77784",
      "77923",
      "78097",
      "78398",
      "78432",
      "600011"
    ]
  },
  "51cca0bc-794a-45dd-b4c3-6caab1dc4792": {
    "team": "1610612756",
    "year": "1974",
    "players": [
      "76078",
      "76095",
      "76680",
      "76978",
      "77101",
      "77575",
      "77784",
      "77835",
      "78065",
      "78097",
      "78398",
      "78546"
    ]
  },
  "7c99413d-eb44-4cb6-96e8-20bc92679f74": {
    "team": "1610612756",
    "year": "1975",
    "players": [
      "76011",
      "76078",
      "76680",
      "76978",
      "77424",
      "77835",
      "78065",
      "78143",
      "78215",
      "78398",
      "78500",
      "78501"
    ]
  },
  "4bf319d2-c1c2-499e-aef3-f5e87baadb22": {
    "team": "1610612756",
    "year": "1976",
    "players": [
      "76011",
      "76078",
      "76680",
      "76699",
      "76985",
      "77364",
      "77835",
      "78084",
      "78215",
      "78305",
      "78398",
      "78399",
      "78500"
    ]
  },
  "bcc8b7c3-72af-437c-9340-7a5f3168f0de": {
    "team": "1610612756",
    "year": "1977",
    "players": [
      "1453",
      "76011",
      "76078",
      "76242",
      "76317",
      "76739",
      "76888",
      "76985",
      "77364",
      "77835",
      "78095",
      "78500"
    ]
  },
  "e8500690-7102-4acf-ae8b-f1907cf60bcf": {
    "team": "1610612756",
    "year": "1978",
    "players": [
      "1453",
      "76011",
      "76242",
      "76317",
      "76739",
      "76985",
      "77308",
      "77510",
      "78095",
      "78500"
    ]
  },
  "bbb2dde4-01ca-4734-babd-de880d259b13": {
    "team": "1610612756",
    "year": "1979",
    "players": [
      "1453",
      "76011",
      "76242",
      "76317",
      "76436",
      "76985",
      "77022",
      "77308",
      "77993",
      "78095",
      "78500"
    ]
  },
  "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3": {
    "team": "1610612756",
    "year": "1980",
    "players": [
      "1453",
      "76011",
      "76436",
      "77022",
      "77141",
      "77235",
      "77308",
      "77438",
      "77718",
      "77993",
      "78095"
    ]
  },
  "aca2f6cb-0959-42f9-a0a5-49c843300715": {
    "team": "1610612756",
    "year": "1981",
    "players": [
      "1453",
      "76011",
      "76231",
      "76436",
      "76625",
      "77141",
      "77235",
      "77308",
      "77438",
      "77520",
      "77685",
      "77993",
      "78095"
    ]
  },
  "d4bedead-ad99-4717-ae90-da27e0c99bec": {
    "team": "1610612756",
    "year": "1982",
    "players": [
      "229",
      "1453",
      "76011",
      "77022",
      "77141",
      "77308",
      "77420",
      "77438",
      "77685",
      "77864",
      "78095",
      "78314",
      "78512"
    ]
  },
  "35e49d21-3b65-44c4-8cd2-e401df91e976": {
    "team": "1610612756",
    "year": "1983",
    "players": [
      "229",
      "1453",
      "76011",
      "76742",
      "77022",
      "77420",
      "77438",
      "77685",
      "77864",
      "77984",
      "78059",
      "78095",
      "78500"
    ]
  },
  "bb297948-2e0c-4f3b-8cf3-628ff1bb680f": {
    "team": "1610612756",
    "year": "1984",
    "players": [
      "229",
      "1453",
      "76011",
      "76742",
      "77047",
      "77081",
      "77178",
      "77420",
      "77438",
      "77685",
      "77864",
      "77984",
      "78059",
      "78095",
      "78634"
    ]
  },
  "2f38049d-f39e-4d79-9815-11d4fdf4013f": {
    "team": "1610612756",
    "year": "1985",
    "players": [
      "229",
      "291",
      "1453",
      "76011",
      "76621",
      "76742",
      "76824",
      "77081",
      "77178",
      "77685",
      "77864",
      "77984",
      "78059",
      "78323",
      "78352",
      "78407"
    ]
  },
  "90b30987-294d-488e-ade2-2e9dd74bb802": {
    "team": "1610612756",
    "year": "1986",
    "players": [
      "204",
      "229",
      "291",
      "912",
      "930",
      "1453",
      "76011",
      "76138",
      "76831",
      "77081",
      "77685",
      "78059",
      "78323",
      "78407"
    ]
  },
  "ca66d0d0-8947-439e-8245-2fecd01da018": {
    "team": "1610612756",
    "year": "1987",
    "players": [
      "134",
      "204",
      "288",
      "698",
      "770",
      "895",
      "1453",
      "76011",
      "76085",
      "76436",
      "76472",
      "77034",
      "77481",
      "77637",
      "78323"
    ]
  },
  "43630741-81fb-49b9-b45e-d413e1873e42": {
    "team": "1610612756",
    "year": "1988",
    "players": [
      "70",
      "105",
      "134",
      "204",
      "288",
      "457",
      "698",
      "770",
      "895",
      "897",
      "930",
      "1472",
      "76472",
      "76528",
      "76618",
      "77697"
    ]
  },
  "1a846bd5-9033-48bc-b23f-c363d42f51c8": {
    "team": "1610612756",
    "year": "1989",
    "players": [
      "100",
      "105",
      "134",
      "204",
      "285",
      "457",
      "698",
      "770",
      "897",
      "1472",
      "76123",
      "77530",
      "77649",
      "77905"
    ]
  },
  "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb": {
    "team": "1610612756",
    "year": "1990",
    "players": [
      "76",
      "105",
      "134",
      "204",
      "457",
      "770",
      "897",
      "1100",
      "1365",
      "1472",
      "1861",
      "76353",
      "77697",
      "77905"
    ]
  },
  "87bc76d9-706c-4de2-aa16-82991b201ea2": {
    "team": "1610612756",
    "year": "1991",
    "players": [
      "76",
      "105",
      "134",
      "204",
      "457",
      "770",
      "897",
      "1054",
      "1472",
      "1861",
      "76316",
      "77697",
      "77905"
    ]
  },
  "0ff89067-4030-4b97-85ef-a563b3488116": {
    "team": "1610612756",
    "year": "1992",
    "players": [
      "30",
      "76",
      "105",
      "134",
      "770",
      "787",
      "932",
      "1054",
      "1118",
      "1472",
      "1861",
      "76017",
      "77146",
      "78261"
    ]
  },
  "30517724-5902-4ca6-b448-35847695fb4a": {
    "team": "1610612756",
    "year": "1993",
    "players": [
      "76",
      "105",
      "134",
      "170",
      "386",
      "770",
      "787",
      "920",
      "932",
      "1030",
      "1054",
      "1635",
      "76017",
      "77003",
      "77146"
    ]
  },
  "a7a2aca5-5373-4d2f-b138-bf2492160e90": {
    "team": "1610612756",
    "year": "1994",
    "players": [
      "7",
      "30",
      "47",
      "105",
      "134",
      "170",
      "226",
      "330",
      "386",
      "445",
      "460",
      "787",
      "920",
      "76017",
      "78286"
    ]
  },
  "2fe4653c-2393-4ccf-9ffd-8aa87af3189b": {
    "team": "1610612756",
    "year": "1995",
    "players": [
      "47",
      "73",
      "134",
      "170",
      "330",
      "386",
      "445",
      "474",
      "712",
      "713",
      "714",
      "715",
      "787",
      "920"
    ]
  },
  "78c68458-e60d-4462-a703-8501fdadf08e": {
    "team": "1610612756",
    "year": "1996",
    "players": [
      "47",
      "73",
      "76",
      "95",
      "134",
      "330",
      "364",
      "445",
      "467",
      "684",
      "959",
      "1479"
    ]
  },
  "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e": {
    "team": "1610612756",
    "year": "1997",
    "players": [
      "45",
      "73",
      "95",
      "134",
      "192",
      "361",
      "364",
      "467",
      "686",
      "959",
      "1479",
      "1527",
      "77597"
    ]
  },
  "51b4adc7-a756-4c8e-a8d7-edca548a0d69": {
    "team": "1610612756",
    "year": "1998",
    "players": [
      "26",
      "45",
      "170",
      "330",
      "339",
      "356",
      "361",
      "364",
      "467",
      "988",
      "1727",
      "1871"
    ]
  },
  "5cd70763-5c6d-404c-ae94-b800444a7144": {
    "team": "1610612756",
    "year": "1999",
    "players": [
      "26",
      "103",
      "134",
      "358",
      "361",
      "467",
      "915",
      "916",
      "932",
      "988",
      "1753",
      "1890"
    ]
  },
  "426fcece-7698-41e8-8ebb-254a273fe06b": {
    "team": "1610612756",
    "year": "2000",
    "players": [
      "53",
      "201",
      "219",
      "339",
      "361",
      "386",
      "467",
      "915",
      "960",
      "1890",
      "2054",
      "2091"
    ]
  },
  "10dc108f-d7b9-48ae-9cd8-49df5b3caf60": {
    "team": "1610612756",
    "year": "2001",
    "players": [
      "105",
      "358",
      "448",
      "950",
      "961",
      "1890",
      "1960",
      "2054",
      "2063",
      "2207",
      "2228",
      "2343"
    ]
  },
  "d8e8bc4a-30c8-477b-bf4d-faa03fef3558": {
    "team": "1610612756",
    "year": "2002",
    "players": [
      "281",
      "339",
      "358",
      "448",
      "753",
      "924",
      "950",
      "1890",
      "2054",
      "2061",
      "2063",
      "2207",
      "2228",
      "2405",
      "2418",
      "2486"
    ]
  },
  "8a26266a-e912-45d0-accf-1224abfe833e": {
    "team": "1610612756",
    "year": "2003",
    "players": [
      "458",
      "686",
      "1721",
      "1751",
      "1890",
      "2051",
      "2063",
      "2207",
      "2405",
      "2418",
      "2560",
      "2571",
      "2573"
    ]
  },
  "47756ea8-085e-4ada-8481-5695ff0d6ae1": {
    "team": "1610612756",
    "year": "2004",
    "players": [
      "448",
      "754",
      "959",
      "962",
      "1890",
      "2047",
      "2063",
      "2207",
      "2212",
      "2321",
      "2405",
      "2571"
    ]
  },
  "c8282335-91e7-4db5-923a-a392aae710c6": {
    "team": "1610612756",
    "year": "2005",
    "players": [
      "258",
      "703",
      "959",
      "1501",
      "1890",
      "1952",
      "2067",
      "2401",
      "2405",
      "2469",
      "2564",
      "2571",
      "2592"
    ]
  },
  "dc7d40bc-b88f-466d-804f-bae3041ea47b": {
    "team": "1610612756",
    "year": "2006",
    "players": [
      "15",
      "147",
      "703",
      "959",
      "1752",
      "1890",
      "1908",
      "1952",
      "2405",
      "2469",
      "2556",
      "2564",
      "2571",
      "2592"
    ]
  },
  "ec837e73-81d2-4056-a56a-4e4dcbf8f706": {
    "team": "1610612756",
    "year": "2007",
    "players": [
      "15",
      "255",
      "406",
      "959",
      "1730",
      "1752",
      "1921",
      "1952",
      "2405",
      "2564",
      "2571",
      "2669",
      "201169",
      "201199"
    ]
  },
  "1517c08b-f620-4a30-ac0f-2da4d9ecf77b": {
    "team": "1610612756",
    "year": "2008",
    "players": [
      "255",
      "406",
      "959",
      "2031",
      "2202",
      "2405",
      "2440",
      "2571",
      "200811",
      "201162",
      "201169",
      "201577",
      "201609"
    ]
  },
  "3929fd1d-99b1-42e3-b041-3e593ae01cfc": {
    "team": "1610612756",
    "year": "2009",
    "players": [
      "255",
      "959",
      "2202",
      "2260",
      "2405",
      "2571",
      "101112",
      "101204",
      "200811",
      "201162",
      "201577",
      "201609",
      "201947",
      "201981"
    ]
  },
  "4c5661ac-769c-43ec-b80f-43ea1f9cd916": {
    "team": "1610612756",
    "year": "2010",
    "players": [
      "255",
      "959",
      "1713",
      "2554",
      "2735",
      "101112",
      "101124",
      "101162",
      "201162",
      "201166",
      "201577",
      "202081",
      "202220",
      "202371"
    ]
  },
  "fa166c7c-372a-489f-b069-635a9196ea73": {
    "team": "1610612756",
    "year": "2011",
    "players": [
      "255",
      "959",
      "2072",
      "2735",
      "2742",
      "101112",
      "101124",
      "101162",
      "101179",
      "200769",
      "201162",
      "201577",
      "202693"
    ]
  },
  "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553": {
    "team": "1610612756",
    "year": "2012",
    "players": [
      "979",
      "2449",
      "101112",
      "101162",
      "200769",
      "200782",
      "201162",
      "201563",
      "201609",
      "201632",
      "202325",
      "202693",
      "202694",
      "203088",
      "203197"
    ]
  },
  "cd1c6261-0878-4024-8b67-e754584c830b": {
    "team": "1610612756",
    "year": "2013",
    "players": [
      "2571",
      "2731",
      "101112",
      "101123",
      "101183",
      "200782",
      "201609",
      "202091",
      "202339",
      "202397",
      "202693",
      "202694",
      "203101",
      "203458",
      "203462"
    ]
  },
  "27be790c-5dc5-4dc6-aeb1-46c90a8d1882": {
    "team": "1610612756",
    "year": "2014",
    "players": [
      "2853",
      "101122",
      "101123",
      "200782",
      "201148",
      "201977",
      "202077",
      "202339",
      "202688",
      "202693",
      "202694",
      "203458",
      "203462",
      "203493",
      "203933"
    ]
  },
  "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1": {
    "team": "1610612756",
    "year": "2015",
    "players": [
      "2199",
      "101179",
      "200782",
      "201978",
      "202339",
      "202688",
      "202720",
      "203098",
      "203141",
      "203458",
      "203462",
      "203933",
      "1626164",
      "1626210"
    ]
  },
  "58ed739f-5da8-4431-9d04-f20d9505d73b": {
    "team": "1610612756",
    "year": "2016",
    "players": [
      "2199",
      "2571",
      "101179",
      "201162",
      "202339",
      "202407",
      "202688",
      "203458",
      "203933",
      "1626164",
      "1626210",
      "1627733",
      "1627737",
      "1627755",
      "1627884"
    ]
  },
  "3c677db4-7d86-4321-8c93-65ab6d27f0ae": {
    "team": "1610612756",
    "year": "2017",
    "players": [
      "2199",
      "201162",
      "202688",
      "203458",
      "203584",
      "203901",
      "203933",
      "1626164",
      "1626210",
      "1627733",
      "1627737",
      "1627755",
      "1627863",
      "1627885",
      "1628367",
      "1628409",
      "1628432"
    ]
  },
  "f6d5681a-88c5-4a65-856a-0362e1012713": {
    "team": "1610612756",
    "year": "2018",
    "players": [
      "2037",
      "202690",
      "203584",
      "203933",
      "204020",
      "1626158",
      "1626162",
      "1626164",
      "1627733",
      "1628367",
      "1628969",
      "1628994",
      "1629001",
      "1629028",
      "1629034",
      "1629059"
    ]
  },
  "fdf322f1-9f93-4535-913c-53228db4b4fb": {
    "team": "1610612756",
    "year": "2019",
    "players": [
      "201937",
      "203382",
      "203967",
      "1626162",
      "1626163",
      "1626164",
      "1627767",
      "1628969",
      "1628975",
      "1629028",
      "1629059",
      "1629607",
      "1629660",
      "1629661",
      "1629665",
      "1629745"
    ]
  },
  "cc46ef6e-8b22-4041-80e0-59f4e62dfd40": {
    "team": "1610612757",
    "year": "1970",
    "players": [
      "76015",
      "76106",
      "76663",
      "76674",
      "76817",
      "76883",
      "76922",
      "77288",
      "77454",
      "77543",
      "77676",
      "77844",
      "78084",
      "78269"
    ]
  },
  "35623c7a-d844-4fde-87eb-a7ebd20a24f4": {
    "team": "1610612757",
    "year": "1971",
    "players": [
      "76015",
      "76883",
      "77093",
      "77288",
      "77466",
      "77505",
      "77543",
      "77844",
      "78084",
      "78207",
      "78245",
      "78520",
      "78631"
    ]
  },
  "448aca36-b464-4106-91e1-7fc632e2c7d5": {
    "team": "1610612757",
    "year": "1972",
    "players": [
      "76015",
      "76519",
      "76533",
      "76577",
      "77162",
      "77475",
      "77696",
      "77844",
      "78190",
      "78207",
      "78245",
      "78385",
      "78520"
    ]
  },
  "dd803214-fd70-44fe-988b-85ae27a94245": {
    "team": "1610612757",
    "year": "1973",
    "players": [
      "76519",
      "76761",
      "77152",
      "77162",
      "77350",
      "77475",
      "77696",
      "77844",
      "77973",
      "78145",
      "78190",
      "78245",
      "78413",
      "78520"
    ]
  },
  "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f": {
    "team": "1610612757",
    "year": "1974",
    "players": [
      "76036",
      "76403",
      "77152",
      "77424",
      "77475",
      "77696",
      "77844",
      "78190",
      "78245",
      "78450",
      "78520",
      "78530"
    ]
  },
  "cbf01feb-6986-43c2-8350-23e707cf8272": {
    "team": "1610612757",
    "year": "1975",
    "players": [
      "76036",
      "76403",
      "76895",
      "76971",
      "77043",
      "77197",
      "77360",
      "77475",
      "77696",
      "77844",
      "78190",
      "78245",
      "78450",
      "78520"
    ]
  },
  "83cc8718-e77c-472a-b77e-ed262ef4cfba": {
    "team": "1610612757",
    "year": "1976",
    "players": [
      "76330",
      "76526",
      "76815",
      "76895",
      "77043",
      "77194",
      "77420",
      "77492",
      "77696",
      "78245",
      "78387",
      "78442",
      "78450"
    ]
  },
  "a643026c-452d-404f-9bec-ce36434b7ef9": {
    "team": "1610612757",
    "year": "1977",
    "players": [
      "76330",
      "76526",
      "76587",
      "76618",
      "76895",
      "77043",
      "77420",
      "77696",
      "77786",
      "78084",
      "78245",
      "78387",
      "78442",
      "78450"
    ]
  },
  "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd": {
    "team": "1610612757",
    "year": "1978",
    "players": [
      "76041",
      "76249",
      "76618",
      "76895",
      "77043",
      "77136",
      "77420",
      "77554",
      "77696",
      "77786",
      "78208",
      "78245",
      "78331",
      "78387"
    ]
  },
  "4ac401d3-899b-427d-89d6-584fe69ebf75": {
    "team": "1610612757",
    "year": "1979",
    "players": [
      "76121",
      "76248",
      "76249",
      "76618",
      "76895",
      "77043",
      "77126",
      "77323",
      "77786",
      "77819",
      "78245",
      "78387",
      "78467"
    ]
  },
  "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e": {
    "team": "1610612757",
    "year": "1980",
    "players": [
      "76121",
      "76474",
      "76895",
      "76928",
      "76954",
      "77323",
      "77691",
      "77786",
      "77819",
      "77911",
      "78331",
      "78467"
    ]
  },
  "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f": {
    "team": "1610612757",
    "year": "1981",
    "players": [
      "76078",
      "76084",
      "76121",
      "76895",
      "76901",
      "76954",
      "77323",
      "77332",
      "77691",
      "77819",
      "77911",
      "78331",
      "78395",
      "78414",
      "78467"
    ]
  },
  "80997709-1fc9-4ada-b330-ff77310ab65f": {
    "team": "1610612757",
    "year": "1982",
    "players": [
      "76317",
      "76350",
      "76441",
      "77215",
      "77332",
      "77376",
      "77527",
      "77691",
      "77731",
      "77819",
      "78331",
      "78364",
      "78395",
      "78414"
    ]
  },
  "ce9f3810-9f45-4e5a-8479-92c52c78194c": {
    "team": "1610612757",
    "year": "1983",
    "players": [
      "17",
      "76350",
      "76441",
      "77332",
      "77376",
      "77691",
      "77731",
      "77819",
      "77863",
      "78331",
      "78395",
      "78414"
    ]
  },
  "3e1b381e-5446-4d31-84cd-e21a71c4c0d3": {
    "team": "1610612757",
    "year": "1984",
    "players": [
      "17",
      "306",
      "760",
      "76217",
      "76350",
      "77731",
      "77819",
      "78080",
      "78323",
      "78331",
      "78395",
      "78404"
    ]
  },
  "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2": {
    "team": "1610612757",
    "year": "1985",
    "players": [
      "17",
      "306",
      "345",
      "760",
      "76217",
      "76350",
      "77154",
      "77175",
      "77819",
      "78331",
      "78395",
      "78404"
    ]
  },
  "3367b969-0b6f-4aa4-927e-679d12463f2b": {
    "team": "1610612757",
    "year": "1986",
    "players": [
      "17",
      "345",
      "404",
      "760",
      "76167",
      "76217",
      "76350",
      "77047",
      "77134",
      "77175",
      "77472",
      "77819",
      "78027",
      "78404",
      "78635"
    ]
  },
  "04ef3e81-d8fb-48a8-ba78-694468f81686": {
    "team": "1610612757",
    "year": "1987",
    "players": [
      "17",
      "333",
      "345",
      "404",
      "760",
      "76045",
      "77047",
      "77134",
      "77175",
      "77178",
      "77420",
      "77673",
      "78146",
      "78404",
      "78582"
    ]
  },
  "148d19d7-f6a8-401d-b449-7e8b111f7959": {
    "team": "1610612757",
    "year": "1988",
    "players": [
      "17",
      "95",
      "345",
      "404",
      "760",
      "76045",
      "76217",
      "76237",
      "76709",
      "77134",
      "77175",
      "78146",
      "78250",
      "78504",
      "78633"
    ]
  },
  "a666e4ba-2891-4445-8ab6-4489a56cbbc6": {
    "team": "1610612757",
    "year": "1989",
    "players": [
      "17",
      "95",
      "345",
      "361",
      "404",
      "433",
      "760",
      "76441",
      "77096",
      "77170",
      "77845",
      "78633"
    ]
  },
  "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed": {
    "team": "1610612757",
    "year": "1990",
    "players": [
      "17",
      "95",
      "345",
      "361",
      "404",
      "433",
      "760",
      "1453",
      "76001",
      "76017",
      "76441",
      "78633"
    ]
  },
  "f2508938-bae2-4775-8446-97b4a2c9038e": {
    "team": "1610612757",
    "year": "1991",
    "players": [
      "17",
      "95",
      "123",
      "345",
      "361",
      "404",
      "433",
      "760",
      "941",
      "76001",
      "76017",
      "76441",
      "78273"
    ]
  },
  "ce6303d7-7e69-45e6-9f99-e7a14a040764": {
    "team": "1610612757",
    "year": "1992",
    "players": [
      "17",
      "53",
      "95",
      "145",
      "341",
      "345",
      "361",
      "393",
      "404",
      "433",
      "760",
      "77138",
      "78035",
      "78201"
    ]
  },
  "5861ab34-d588-4bda-9eb3-5d8dba9d98a2": {
    "team": "1610612757",
    "year": "1993",
    "players": [
      "17",
      "95",
      "145",
      "201",
      "265",
      "345",
      "361",
      "381",
      "393",
      "433",
      "760",
      "1114",
      "78201",
      "78329"
    ]
  },
  "60149951-fa60-4cf7-acb8-a9a2a8051eab": {
    "team": "1610612757",
    "year": "1994",
    "players": [
      "95",
      "201",
      "229",
      "243",
      "265",
      "345",
      "361",
      "381",
      "393",
      "433",
      "760",
      "901",
      "1667"
    ]
  },
  "3eff7ebb-bedf-4522-b837-8677e8944c50": {
    "team": "1610612757",
    "year": "1995",
    "players": [
      "112",
      "201",
      "243",
      "265",
      "361",
      "381",
      "393",
      "433",
      "716",
      "717",
      "718",
      "719",
      "771",
      "76433"
    ]
  },
  "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582": {
    "team": "1610612757",
    "year": "1996",
    "players": [
      "72",
      "112",
      "201",
      "278",
      "348",
      "361",
      "375",
      "716",
      "717",
      "718",
      "739",
      "979"
    ]
  },
  "2b334b77-09f5-45ee-bb8d-ecb462e5125d": {
    "team": "1610612757",
    "year": "1997",
    "players": [
      "175",
      "202",
      "258",
      "278",
      "375",
      "717",
      "739",
      "757",
      "979",
      "1005",
      "1509",
      "1594"
    ]
  },
  "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b": {
    "team": "1610612757",
    "year": "1998",
    "players": [
      "21",
      "202",
      "258",
      "278",
      "375",
      "717",
      "739",
      "754",
      "757",
      "979",
      "1005",
      "1509"
    ]
  },
  "75d32015-dc9a-454e-ae76-aa74fe068fc4": {
    "team": "1610612757",
    "year": "1999",
    "players": [
      "21",
      "96",
      "120",
      "202",
      "258",
      "278",
      "717",
      "739",
      "757",
      "937",
      "979",
      "1719"
    ]
  },
  "d057f1eb-687d-446c-aa7c-09ea489e7e39": {
    "team": "1610612757",
    "year": "2000",
    "players": [
      "21",
      "96",
      "120",
      "176",
      "278",
      "393",
      "717",
      "739",
      "757",
      "781",
      "905",
      "937"
    ]
  },
  "e92ea5fa-6340-4a15-b911-8471d044338f": {
    "team": "1610612757",
    "year": "2001",
    "players": [
      "70",
      "201",
      "348",
      "431",
      "739",
      "757",
      "905",
      "937",
      "1507",
      "1594",
      "1719",
      "1739",
      "2057",
      "2216",
      "2257"
    ]
  },
  "719d3baf-1086-49d1-8f27-82662eb959d1": {
    "team": "1610612757",
    "year": "2002",
    "players": [
      "182",
      "201",
      "717",
      "739",
      "757",
      "905",
      "937",
      "976",
      "1043",
      "1498",
      "1507",
      "1520",
      "1719",
      "1739",
      "2216",
      "2257",
      "2417",
      "2499"
    ]
  },
  "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae": {
    "team": "1610612757",
    "year": "2003",
    "players": [
      "689",
      "757",
      "905",
      "949",
      "1507",
      "1735",
      "1739",
      "2032",
      "2109",
      "2216",
      "2241",
      "2417",
      "2424",
      "2566",
      "2724"
    ]
  },
  "7f469a5a-51d3-4e48-81cc-36627dd4855c": {
    "team": "1610612757",
    "year": "2004",
    "players": [
      "689",
      "757",
      "949",
      "1507",
      "1739",
      "2032",
      "2038",
      "2499",
      "2566",
      "2742",
      "2751",
      "2775"
    ]
  },
  "b184df4d-4f3f-47a7-8be3-e3151cdbee20": {
    "team": "1610612757",
    "year": "2005",
    "players": [
      "689",
      "702",
      "1730",
      "2032",
      "2038",
      "2216",
      "2413",
      "2566",
      "2581",
      "2742",
      "2751",
      "2775",
      "101110",
      "101127"
    ]
  },
  "be3b4d44-fa5a-4530-9f15-472d9df9726e": {
    "team": "1610612757",
    "year": "2006",
    "players": [
      "1711",
      "2032",
      "2038",
      "2048",
      "2137",
      "2216",
      "2410",
      "2424",
      "2566",
      "101110",
      "101127",
      "101195",
      "200746",
      "200750",
      "200771"
    ]
  },
  "1158f2a2-b551-455d-8934-8d7630f69ba5": {
    "team": "1610612757",
    "year": "2007",
    "players": [
      "1711",
      "2038",
      "2566",
      "2581",
      "2592",
      "101110",
      "101112",
      "101127",
      "101144",
      "200746",
      "200750",
      "200771",
      "201141",
      "201177"
    ]
  },
  "e04c4504-ea6d-4181-8788-5cef35516215": {
    "team": "1610612757",
    "year": "2008",
    "players": [
      "1711",
      "1913",
      "2038",
      "2566",
      "2581",
      "101110",
      "101112",
      "101183",
      "200746",
      "200750",
      "200771",
      "201141",
      "201164",
      "201573",
      "201587"
    ]
  },
  "4027029b-e749-405b-abd7-419c00e4f631": {
    "team": "1610612757",
    "year": "2009",
    "players": [
      "436",
      "948",
      "1889",
      "2038",
      "101110",
      "101143",
      "200746",
      "200750",
      "201141",
      "201164",
      "201573",
      "201587",
      "201965",
      "201967",
      "201988"
    ]
  },
  "8e1bb40f-2c63-4904-9e81-67592b326bda": {
    "team": "1610612757",
    "year": "2010",
    "players": [
      "948",
      "1889",
      "2222",
      "2853",
      "200746",
      "200750",
      "201141",
      "201164",
      "201587",
      "201988",
      "202083",
      "202337",
      "202343",
      "202356",
      "202419"
    ]
  },
  "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676": {
    "team": "1610612757",
    "year": "2011",
    "players": [
      "703",
      "2037",
      "2038",
      "101109",
      "200746",
      "200761",
      "200783",
      "201581",
      "201587",
      "201934",
      "201938",
      "202083",
      "202337",
      "202343",
      "202701"
    ]
  },
  "6f3a808f-3d1d-4041-ba62-bd244ca7c76d": {
    "team": "1610612757",
    "year": "2012",
    "players": [
      "2407",
      "2562",
      "200746",
      "200777",
      "201581",
      "201587",
      "201953",
      "201964",
      "202083",
      "202337",
      "202343",
      "202701",
      "203081",
      "203086",
      "203115"
    ]
  },
  "62ee5039-cd9c-4bdd-a3ee-b241a2901eed": {
    "team": "1610612757",
    "year": "2013",
    "players": [
      "2248",
      "2590",
      "2748",
      "200746",
      "200777",
      "201577",
      "201587",
      "201964",
      "202083",
      "203080",
      "203081",
      "203086",
      "203115",
      "203459",
      "203468"
    ]
  },
  "496ae576-436a-46a6-94da-38f07f54a41b": {
    "team": "1610612757",
    "year": "2014",
    "players": [
      "2549",
      "2581",
      "2748",
      "200746",
      "200777",
      "201167",
      "201577",
      "201587",
      "202083",
      "202087",
      "203081",
      "203086",
      "203459",
      "203468",
      "204025"
    ]
  },
  "cbb4c930-842b-4d16-a892-29ffd4897a48": {
    "team": "1610612757",
    "year": "2015",
    "players": [
      "2549",
      "201945",
      "202329",
      "202334",
      "203081",
      "203086",
      "203090",
      "203148",
      "203459",
      "203468",
      "203486",
      "203943",
      "1626146",
      "1626192",
      "1626242"
    ]
  },
  "bf8f9985-d8ac-46b8-a125-085201212231": {
    "team": "1610612757",
    "year": "2016",
    "players": [
      "202323",
      "202329",
      "202334",
      "203081",
      "203086",
      "203090",
      "203105",
      "203459",
      "203468",
      "203894",
      "203943",
      "203994",
      "1626192",
      "1627774",
      "1627817"
    ]
  },
  "1a813a61-ec5f-471d-a82b-7f1f04400c3d": {
    "team": "1610612757",
    "year": "2017",
    "players": [
      "202323",
      "202329",
      "202334",
      "203081",
      "203086",
      "203090",
      "203468",
      "203894",
      "203912",
      "203994",
      "1626192",
      "1627735",
      "1627774",
      "1627834",
      "1628380",
      "1628403"
    ]
  },
  "869c9f07-5ffd-4f84-8929-3a72ecf08add": {
    "team": "1610612757",
    "year": "2018",
    "players": [
      "202323",
      "202329",
      "202683",
      "203081",
      "203086",
      "203090",
      "203468",
      "203552",
      "203918",
      "203994",
      "1627746",
      "1627774",
      "1628380",
      "1629014",
      "1629018"
    ]
  },
  "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33": {
    "team": "1610612757",
    "year": "2019",
    "players": [
      "2546",
      "2772",
      "202355",
      "203081",
      "203468",
      "203918",
      "203994",
      "1626209",
      "1628380",
      "1628403",
      "1629014",
      "1629018",
      "1629117",
      "1629642",
      "1629650",
      "1629658"
    ]
  },
  "9a8eca6a-8c6e-42b3-bea5-c106318f71f9": {
    "team": "1610612758",
    "year": "1948",
    "players": [
      "76331",
      "76492",
      "76514",
      "76615",
      "76720",
      "77050",
      "77132",
      "77375",
      "77736",
      "77967",
      "78453"
    ]
  },
  "06b16798-5051-4dbc-8f77-316dfd3f76a0": {
    "team": "1610612758",
    "year": "1949",
    "players": [
      "76264",
      "76331",
      "76414",
      "76492",
      "76514",
      "76615",
      "77050",
      "77132",
      "77967",
      "78063",
      "78453"
    ]
  },
  "6146894f-3e97-4aab-a027-77228d1955a6": {
    "team": "1610612758",
    "year": "1950",
    "players": [
      "76331",
      "76414",
      "76514",
      "77050",
      "77132",
      "77559",
      "77723",
      "77967",
      "78063",
      "78453"
    ]
  },
  "817867c3-ef94-48b9-81aa-768f4f8f758e": {
    "team": "1610612758",
    "year": "1951",
    "players": [
      "76414",
      "76514",
      "76937",
      "77050",
      "77132",
      "77723",
      "77902",
      "77912",
      "77967",
      "78225",
      "78453"
    ]
  },
  "a0311ade-2019-4595-ab92-26a1696f3ce7": {
    "team": "1610612758",
    "year": "1952",
    "players": [
      "76394",
      "76414",
      "76514",
      "76937",
      "77050",
      "77132",
      "77551",
      "77967",
      "78225",
      "78453"
    ]
  },
  "18b844a4-6bd1-4a67-acac-df8b594d9cc6": {
    "team": "1610612758",
    "year": "1953",
    "players": [
      "76394",
      "76414",
      "76514",
      "76937",
      "77551",
      "77925",
      "77967",
      "78225",
      "78284",
      "78453"
    ]
  },
  "16101391-6b81-4a3f-a8d4-55f40ee7e8af": {
    "team": "1610612758",
    "year": "1954",
    "players": [
      "76394",
      "76414",
      "76514",
      "76999",
      "77467",
      "77551",
      "77682",
      "77967",
      "78225",
      "78232",
      "78453"
    ]
  },
  "516c9243-1276-46d0-8dbe-f1db0a8384ad": {
    "team": "1610612758",
    "year": "1955",
    "players": [
      "76524",
      "76723",
      "76958",
      "77573",
      "77932",
      "77955",
      "78153",
      "78232",
      "78388",
      "78453",
      "600016"
    ]
  },
  "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b": {
    "team": "1610612758",
    "year": "1956",
    "players": [
      "76313",
      "76723",
      "76877",
      "77467",
      "77506",
      "77862",
      "77932",
      "77955",
      "78232",
      "78388",
      "78453",
      "600016"
    ]
  },
  "21936ffd-2bd4-4505-a647-b4cd447d157b": {
    "team": "1610612758",
    "year": "1957",
    "players": [
      "76605",
      "77268",
      "77414",
      "77467",
      "77573",
      "77816",
      "77818",
      "77862",
      "77932",
      "77955",
      "78388",
      "600016"
    ]
  },
  "dc41b99f-710f-4f81-b125-45b52cf09053": {
    "team": "1610612758",
    "year": "1958",
    "players": [
      "76191",
      "76546",
      "76668",
      "77467",
      "77506",
      "77792",
      "77799",
      "77802",
      "77862",
      "78244",
      "78388"
    ]
  },
  "46567045-4810-4a4b-8e8f-96ca1bdccdb2": {
    "team": "1610612758",
    "year": "1959",
    "players": [
      "76191",
      "76668",
      "77210",
      "77799",
      "77862",
      "77927",
      "78013",
      "78244",
      "78252",
      "78388",
      "78529"
    ]
  },
  "d7b5f409-d638-4a98-a3ad-ecf937bae6ce": {
    "team": "1610612758",
    "year": "1960",
    "players": [
      "76191",
      "76206",
      "76532",
      "76668",
      "76694",
      "77210",
      "77927",
      "78244",
      "78388",
      "78529",
      "600015"
    ]
  },
  "020d6aa2-861a-4e30-8595-c38b6904fb12": {
    "team": "1610612758",
    "year": "1961",
    "players": [
      "76191",
      "76206",
      "76293",
      "76668",
      "77727",
      "77927",
      "78178",
      "78388",
      "78522",
      "78640",
      "600015"
    ]
  },
  "855d0133-3349-470d-9362-7617b3f72e7f": {
    "team": "1610612758",
    "year": "1962",
    "players": [
      "76191",
      "76206",
      "76293",
      "76668",
      "76977",
      "77765",
      "77862",
      "77927",
      "78178",
      "78342",
      "78388",
      "600015"
    ]
  },
  "2645a4da-5c26-4f80-8c7a-2490722103ad": {
    "team": "1610612758",
    "year": "1963",
    "players": [
      "76063",
      "76191",
      "76668",
      "76977",
      "77418",
      "77765",
      "78178",
      "78244",
      "78309",
      "78388",
      "600015"
    ]
  },
  "4eff069e-7043-4696-b69b-76eb51998133": {
    "team": "1610612758",
    "year": "1964",
    "players": [
      "76063",
      "76191",
      "76668",
      "76916",
      "76977",
      "77418",
      "77765",
      "78178",
      "78309",
      "78388",
      "78579",
      "600015"
    ]
  },
  "4321cda2-7706-48e9-be7e-e1ae7595f2ef": {
    "team": "1610612758",
    "year": "1965",
    "players": [
      "76063",
      "76566",
      "76668",
      "76916",
      "76977",
      "77418",
      "77533",
      "78178",
      "78309",
      "78388",
      "78579",
      "600015"
    ]
  },
  "38c6a8b5-9c04-429f-9873-476923c33722": {
    "team": "1610612758",
    "year": "1966",
    "players": [
      "76566",
      "76916",
      "77380",
      "77412",
      "77418",
      "77533",
      "77988",
      "78178",
      "78457",
      "78495",
      "78579",
      "600015"
    ]
  },
  "b630b545-ede0-408a-a797-133d5fdeaa10": {
    "team": "1610612758",
    "year": "1967",
    "players": [
      "76382",
      "76566",
      "76576",
      "76747",
      "76859",
      "76916",
      "77099",
      "77412",
      "77418",
      "77988",
      "78003",
      "78178",
      "78399",
      "78495",
      "78651",
      "600015"
    ]
  },
  "e8c1fb6f-a628-4bbf-bda3-873d7c698604": {
    "team": "1610612758",
    "year": "1968",
    "players": [
      "76002",
      "76566",
      "76576",
      "76740",
      "76759",
      "77012",
      "77418",
      "78159",
      "78178",
      "78372",
      "78399",
      "78495",
      "600015"
    ]
  },
  "69c1ae9b-9539-4b62-ac92-7c0085dce1aa": {
    "team": "1610612758",
    "year": "1969",
    "players": [
      "76048",
      "76566",
      "76740",
      "76815",
      "76867",
      "77270",
      "77894",
      "78385",
      "78399",
      "78406",
      "600003",
      "600015"
    ]
  },
  "ebe915d7-7456-4c43-b39f-1cc85c7dde39": {
    "team": "1610612758",
    "year": "1970",
    "players": [
      "76054",
      "76064",
      "76113",
      "76175",
      "76740",
      "76867",
      "77091",
      "77093",
      "77326",
      "77815",
      "77988",
      "78399",
      "78406",
      "78574"
    ]
  },
  "146cb658-0a9a-4f26-8556-39497dc3cfa4": {
    "team": "1610612758",
    "year": "1971",
    "players": [
      "76054",
      "76367",
      "76622",
      "76747",
      "76867",
      "76908",
      "77185",
      "77326",
      "77534",
      "77580",
      "78298",
      "78399",
      "78406",
      "78561"
    ]
  },
  "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60": {
    "team": "1610612758",
    "year": "1972",
    "players": [
      "76054",
      "76187",
      "76622",
      "76807",
      "76867",
      "76908",
      "77259",
      "77295",
      "77326",
      "77915",
      "77964",
      "78069",
      "78144",
      "78561"
    ]
  },
  "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba": {
    "team": "1610612758",
    "year": "1973",
    "players": [
      "76054",
      "76140",
      "76187",
      "76505",
      "76622",
      "77295",
      "77297",
      "77326",
      "77451",
      "77561",
      "77636",
      "77915",
      "78313",
      "78439",
      "78561"
    ]
  },
  "66c852c8-74e2-482e-8745-846263fa3f58": {
    "team": "1610612758",
    "year": "1974",
    "players": [
      "76015",
      "76054",
      "76140",
      "76505",
      "77295",
      "77302",
      "77326",
      "77489",
      "77561",
      "78439",
      "78482"
    ]
  },
  "dd460500-38ea-4afb-99af-548234a3036d": {
    "team": "1610612758",
    "year": "1975",
    "players": [
      "76054",
      "76165",
      "76505",
      "76908",
      "76940",
      "77162",
      "77302",
      "77326",
      "77561",
      "77973",
      "77997",
      "78439",
      "78482",
      "78592"
    ]
  },
  "cdddb982-7e73-438a-a4ff-84b2d2b2276e": {
    "team": "1610612758",
    "year": "1976",
    "players": [
      "76112",
      "76165",
      "76204",
      "76628",
      "76940",
      "77162",
      "77326",
      "77504",
      "77997",
      "78296",
      "78468",
      "78482"
    ]
  },
  "67dffcf3-787e-41ff-96c5-6b26de5d8cef": {
    "team": "1610612758",
    "year": "1977",
    "players": [
      "76027",
      "76170",
      "76204",
      "76305",
      "76940",
      "77320",
      "77326",
      "77504",
      "77688",
      "77944",
      "77997",
      "78468",
      "78482"
    ]
  },
  "76734480-3ae8-4ed7-b5b4-513aa11bce19": {
    "team": "1610612758",
    "year": "1978",
    "players": [
      "76027",
      "76140",
      "76170",
      "76305",
      "76736",
      "77028",
      "77326",
      "77547",
      "77688",
      "77926",
      "77997",
      "78468",
      "78482"
    ]
  },
  "1df46296-cc39-4688-95c6-3656430e4ae2": {
    "team": "1610612758",
    "year": "1979",
    "players": [
      "76170",
      "76305",
      "76475",
      "76736",
      "76802",
      "76873",
      "76899",
      "77273",
      "77326",
      "77547",
      "77926",
      "77997",
      "78482",
      "600010"
    ]
  },
  "19686855-a120-434c-8ad4-5f15e61b9a38": {
    "team": "1610612758",
    "year": "1980",
    "players": [
      "76170",
      "76590",
      "76736",
      "76899",
      "77273",
      "77326",
      "77582",
      "78057",
      "78449",
      "78482",
      "78510",
      "78519"
    ]
  },
  "d4565b40-6c6d-4d7b-906e-eb1a4a817365": {
    "team": "1610612758",
    "year": "1981",
    "players": [
      "698",
      "76556",
      "76590",
      "76599",
      "76736",
      "76899",
      "77134",
      "77163",
      "77273",
      "77331",
      "77401",
      "77582",
      "78519",
      "78614"
    ]
  },
  "48f67883-4176-4790-ac27-c6cec5e58bd8": {
    "team": "1610612758",
    "year": "1982",
    "players": [
      "78",
      "698",
      "76556",
      "76590",
      "76599",
      "77134",
      "77273",
      "77401",
      "77582",
      "77697",
      "78250",
      "78571",
      "78614"
    ]
  },
  "e81896fc-7ddb-4509-a099-1e8914f1dcd7": {
    "team": "1610612758",
    "year": "1983",
    "players": [
      "78",
      "698",
      "76317",
      "76599",
      "77290",
      "77582",
      "77588",
      "77697",
      "77756",
      "77998",
      "78281",
      "78310",
      "78614"
    ]
  },
  "c49c485d-48ce-4cdb-ac2e-27d3b6da259e": {
    "team": "1610612758",
    "year": "1984",
    "players": [
      "78",
      "698",
      "901",
      "76317",
      "76599",
      "77290",
      "77558",
      "77582",
      "77692",
      "77697",
      "77756",
      "77870",
      "78281",
      "78310",
      "78414",
      "78614"
    ]
  },
  "b9189460-149e-4b0b-88c7-ad91b38bd16f": {
    "team": "1610612758",
    "year": "1985",
    "players": [
      "78",
      "149",
      "170",
      "698",
      "901",
      "76242",
      "76439",
      "76599",
      "77001",
      "77235",
      "77756",
      "78310",
      "78390",
      "78614"
    ]
  },
  "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22": {
    "team": "1610612758",
    "year": "1986",
    "players": [
      "78",
      "170",
      "698",
      "901",
      "76588",
      "76632",
      "76641",
      "77756",
      "77881",
      "78007",
      "78183",
      "78250",
      "78310",
      "78390",
      "78578"
    ]
  },
  "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47": {
    "team": "1610612758",
    "year": "1987",
    "players": [
      "78",
      "170",
      "181",
      "291",
      "901",
      "76057",
      "76641",
      "77002",
      "77104",
      "77530",
      "77704",
      "77757",
      "77881",
      "78183",
      "78310",
      "78390"
    ]
  },
  "65fe3c0f-07c5-469c-b786-9542d2d55742": {
    "team": "1610612758",
    "year": "1988",
    "players": [
      "38",
      "47",
      "181",
      "219",
      "1622",
      "76017",
      "76025",
      "76157",
      "76812",
      "77104",
      "77839",
      "77881"
    ]
  },
  "6bb8d6a2-1271-4871-ad5c-4b3444e44dff": {
    "team": "1610612758",
    "year": "1989",
    "players": [
      "47",
      "156",
      "219",
      "442",
      "1123",
      "1622",
      "76017",
      "76025",
      "77104",
      "77881",
      "78055",
      "78264",
      "78352",
      "78379"
    ]
  },
  "57c1be08-60cf-40ad-a097-a95ef9958532": {
    "team": "1610612758",
    "year": "1990",
    "players": [
      "41",
      "47",
      "82",
      "156",
      "190",
      "306",
      "1489",
      "1848",
      "76334",
      "76542",
      "76752",
      "76942",
      "77372",
      "77496",
      "78055",
      "78611",
      "600009"
    ]
  },
  "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504": {
    "team": "1610612758",
    "year": "1991",
    "players": [
      "41",
      "47",
      "190",
      "368",
      "432",
      "753",
      "782",
      "892",
      "1063",
      "1489",
      "77054",
      "77129",
      "77372",
      "78333"
    ]
  },
  "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d": {
    "team": "1610612758",
    "year": "1992",
    "players": [
      "41",
      "47",
      "55",
      "190",
      "432",
      "753",
      "782",
      "892",
      "1005",
      "1489",
      "77019",
      "77261",
      "77372",
      "77905"
    ]
  },
  "2678d1f0-b641-4c9f-9473-9217e379be6c": {
    "team": "1610612758",
    "year": "1993",
    "players": [
      "46",
      "47",
      "178",
      "190",
      "310",
      "753",
      "782",
      "892",
      "1005",
      "1027",
      "1489",
      "76247",
      "76308",
      "77372",
      "78194",
      "78227"
    ]
  },
  "c2c2d971-f7bc-4a78-90d0-18edc4d177c1": {
    "team": "1610612758",
    "year": "1994",
    "players": [
      "63",
      "74",
      "178",
      "190",
      "258",
      "310",
      "753",
      "782",
      "892",
      "1005",
      "1027",
      "1489",
      "77358",
      "78379"
    ]
  },
  "2283cacb-c40a-4e46-a0ba-c1389b23f8ed": {
    "team": "1610612758",
    "year": "1995",
    "players": [
      "36",
      "63",
      "178",
      "182",
      "190",
      "258",
      "310",
      "333",
      "721",
      "722",
      "723",
      "780",
      "782",
      "1489"
    ]
  },
  "5f51079c-a258-4dd0-bbf6-7316c28a4693": {
    "team": "1610612758",
    "year": "1996",
    "players": [
      "51",
      "63",
      "81",
      "178",
      "182",
      "258",
      "310",
      "721",
      "722",
      "782",
      "1089",
      "1489"
    ]
  },
  "3c6f5772-9b65-4def-8409-05c17bc09fef": {
    "team": "1610612758",
    "year": "1997",
    "players": [
      "143",
      "178",
      "182",
      "722",
      "782",
      "901",
      "971",
      "997",
      "1444",
      "1505",
      "1533",
      "1565"
    ]
  },
  "eebacc20-e4d3-4417-9b52-c5705ef3d740": {
    "team": "1610612758",
    "year": "1998",
    "players": [
      "124",
      "137",
      "185",
      "468",
      "722",
      "978",
      "1138",
      "1444",
      "1505",
      "1513",
      "1715",
      "1744"
    ]
  },
  "147ffabd-b9bd-4692-b986-85204cc78855": {
    "team": "1610612758",
    "year": "1999",
    "players": [
      "98",
      "124",
      "185",
      "239",
      "468",
      "722",
      "895",
      "960",
      "978",
      "1444",
      "1513",
      "1715"
    ]
  },
  "775e1767-1db9-44e9-b74e-932f413dc114": {
    "team": "1610612758",
    "year": "2000",
    "players": [
      "57",
      "98",
      "124",
      "185",
      "239",
      "468",
      "978",
      "1444",
      "1513",
      "1517",
      "1715",
      "2045"
    ]
  },
  "f1a90a9e-bf92-4127-8554-405c6a6d67ef": {
    "team": "1610612758",
    "year": "2001",
    "players": [
      "57",
      "85",
      "124",
      "185",
      "359",
      "978",
      "1444",
      "1513",
      "1517",
      "1710",
      "2043",
      "2045",
      "2222"
    ]
  },
  "792077d5-a2d6-410d-84fd-6271f14ffeca": {
    "team": "1610612758",
    "year": "2002",
    "players": [
      "57",
      "85",
      "124",
      "185",
      "978",
      "987",
      "1444",
      "1513",
      "1517",
      "1619",
      "1710",
      "1721",
      "1745",
      "2043",
      "2045",
      "2222",
      "2451"
    ]
  },
  "417cf680-3d48-451b-b32a-534de216ffdb": {
    "team": "1610612758",
    "year": "2003",
    "players": [
      "57",
      "124",
      "185",
      "324",
      "763",
      "978",
      "1517",
      "1710",
      "1802",
      "1934",
      "2074",
      "2222",
      "2443"
    ]
  },
  "8de840b0-bf96-42ee-9a11-49735c4c8b63": {
    "team": "1610612758",
    "year": "2004",
    "players": [
      "722",
      "731",
      "978",
      "1517",
      "1710",
      "1730",
      "1749",
      "1802",
      "1903",
      "2067",
      "2230",
      "2443",
      "2755",
      "2845"
    ]
  },
  "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59": {
    "team": "1610612758",
    "year": "2005",
    "players": [
      "722",
      "949",
      "958",
      "1710",
      "1719",
      "1802",
      "1897",
      "1903",
      "2078",
      "2441",
      "2755",
      "101128",
      "101179"
    ]
  },
  "fff327c0-dd6f-411a-96ea-179675fe9739": {
    "team": "1610612758",
    "year": "2006",
    "players": [
      "722",
      "949",
      "958",
      "1710",
      "1802",
      "1897",
      "1903",
      "2422",
      "2755",
      "101128",
      "101179",
      "200763",
      "200818"
    ]
  },
  "a50184cf-bda0-43f5-970c-379a18a8c902": {
    "team": "1610612758",
    "year": "2007",
    "players": [
      "949",
      "953",
      "1533",
      "1630",
      "1802",
      "1897",
      "1903",
      "2422",
      "2755",
      "2757",
      "101128",
      "200749",
      "200763",
      "201150"
    ]
  },
  "5e5e9d7a-7e23-4c30-84c1-20b4541bc317": {
    "team": "1610612758",
    "year": "2008",
    "players": [
      "1517",
      "1903",
      "1916",
      "2755",
      "2757",
      "2804",
      "101113",
      "101119",
      "101128",
      "200759",
      "201150",
      "201574",
      "201590"
    ]
  },
  "09275de7-3dd0-4c9c-8b46-54f21b4b3a14": {
    "team": "1610612758",
    "year": "2009",
    "players": [
      "2137",
      "2757",
      "2804",
      "101118",
      "101128",
      "201150",
      "201171",
      "201187",
      "201574",
      "201590",
      "201936",
      "201956",
      "201972"
    ]
  },
  "f682d9da-cb2e-4f60-b710-6accad2d386b": {
    "team": "1610612758",
    "year": "2010",
    "players": [
      "2223",
      "2605",
      "2757",
      "101128",
      "200817",
      "201574",
      "201590",
      "201616",
      "201936",
      "201956",
      "201977",
      "202326",
      "202355"
    ]
  },
  "8ef85512-070b-4f28-9454-9d4cc78d5562": {
    "team": "1610612758",
    "year": "2011",
    "players": [
      "2422",
      "2566",
      "101128",
      "101236",
      "201574",
      "201590",
      "201936",
      "201944",
      "201977",
      "202326",
      "202355",
      "202690",
      "202715",
      "202738"
    ]
  },
  "afc314f8-d2d1-427f-b7b2-2233ba35248d": {
    "team": "1610612758",
    "year": "2012",
    "players": [
      "2422",
      "2566",
      "101236",
      "201574",
      "201936",
      "201949",
      "201962",
      "201977",
      "202326",
      "202332",
      "202335",
      "202690",
      "202738"
    ]
  },
  "e5874b6a-365d-44c5-bc64-9ffa2196f29f": {
    "team": "1610612758",
    "year": "2013",
    "players": [
      "1891",
      "2501",
      "2566",
      "200752",
      "201171",
      "201189",
      "201574",
      "202326",
      "202682",
      "202738",
      "203099",
      "203112",
      "203186",
      "203463",
      "203492"
    ]
  },
  "3aadb71f-93d3-4f2e-826e-a896f5df554c": {
    "team": "1610612758",
    "year": "2014",
    "players": [
      "1889",
      "2501",
      "200752",
      "200797",
      "201171",
      "201574",
      "201954",
      "201956",
      "202326",
      "202682",
      "203463",
      "203492",
      "203917",
      "203961",
      "204065"
    ]
  },
  "34a215f4-c672-4508-a765-ed7809c0e939": {
    "team": "1610612758",
    "year": "2015",
    "players": [
      "2406",
      "200752",
      "200765",
      "201158",
      "201585",
      "201954",
      "201956",
      "202326",
      "202341",
      "203112",
      "203463",
      "203552",
      "203961",
      "1626161",
      "1626251"
    ]
  },
  "e224a1c9-d8ae-4918-aaf5-70bed34a01bb": {
    "team": "1610612758",
    "year": "2016",
    "players": [
      "200752",
      "201167",
      "201585",
      "201936",
      "201951",
      "201954",
      "202066",
      "203463",
      "204038",
      "1626161",
      "1627741",
      "1627746",
      "1627781",
      "1627834"
    ]
  },
  "ddaa00aa-d14d-44d5-809c-c6831499cac0": {
    "team": "1610612758",
    "year": "2017",
    "players": [
      "1713",
      "2216",
      "201585",
      "202066",
      "202697",
      "203960",
      "203992",
      "203998",
      "204022",
      "1626161",
      "1627741",
      "1627746",
      "1628368",
      "1628382",
      "1628385",
      "1628412",
      "1628502"
    ]
  },
  "a33db084-b2b6-45ed-8752-743576aa1a5d": {
    "team": "1610612758",
    "year": "2018",
    "players": [
      "201147",
      "201585",
      "202357",
      "202692",
      "203084",
      "203992",
      "1626161",
      "1627741",
      "1627786",
      "1627812",
      "1628368",
      "1628385",
      "1628403",
      "1628412",
      "1628963",
      "1629117",
      "1629168"
    ]
  },
  "c4e00e27-136a-44e7-875f-03ce99bc45ac": {
    "team": "1610612758",
    "year": "2019",
    "players": [
      "202357",
      "202709",
      "203084",
      "203145",
      "203458",
      "203953",
      "203992",
      "1626158",
      "1627741",
      "1627812",
      "1628368",
      "1628385",
      "1628963",
      "1629610",
      "1629657",
      "1629713"
    ]
  },
  "02822d1e-e608-4487-ba05-46ffbf5312fe": {
    "team": "1610612759",
    "year": "1976",
    "players": [
      "76257",
      "76499",
      "76505",
      "76567",
      "76771",
      "76804",
      "77223",
      "77246",
      "77701",
      "77756",
      "77817",
      "78150",
      "78456"
    ]
  },
  "e823e658-8b58-4b0e-ba9a-63cc0292ae34": {
    "team": "1610612759",
    "year": "1977",
    "players": [
      "76257",
      "76499",
      "76567",
      "76628",
      "76771",
      "76804",
      "77223",
      "77246",
      "77350",
      "77756",
      "77817",
      "78150",
      "78161"
    ]
  },
  "ed829e6a-a009-4d2d-b830-02d39fbaf148": {
    "team": "1610612759",
    "year": "1978",
    "players": [
      "76257",
      "76499",
      "76567",
      "76771",
      "76804",
      "76873",
      "77246",
      "77653",
      "77756",
      "77817",
      "78150"
    ]
  },
  "38ac7402-b81e-4254-b636-95330a997974": {
    "team": "1610612759",
    "year": "1979",
    "players": [
      "76523",
      "76686",
      "76771",
      "76804",
      "76889",
      "77246",
      "77255",
      "77732",
      "77756",
      "77817",
      "77827",
      "77944",
      "78143",
      "78150"
    ]
  },
  "a6b98be6-4acd-49be-92b6-decd2522e4df": {
    "team": "1610612759",
    "year": "1980",
    "players": [
      "76249",
      "76453",
      "76771",
      "76802",
      "76804",
      "76889",
      "77149",
      "77163",
      "77633",
      "77756",
      "77944",
      "78150",
      "78527"
    ]
  },
  "ff30d636-cd58-403e-b6d1-3bf850cd43eb": {
    "team": "1610612759",
    "year": "1981",
    "players": [
      "76093",
      "76242",
      "76453",
      "76804",
      "76889",
      "77149",
      "77633",
      "77756",
      "77904",
      "78632"
    ]
  },
  "cc0330a4-e337-4c24-a4bb-37c8de5dc661": {
    "team": "1610612759",
    "year": "1982",
    "players": [
      "76093",
      "76474",
      "76567",
      "76616",
      "76804",
      "76889",
      "77184",
      "77615",
      "77633",
      "77817",
      "77848",
      "77904",
      "77994",
      "78059",
      "78204",
      "600014"
    ]
  },
  "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2": {
    "team": "1610612759",
    "year": "1983",
    "players": [
      "122",
      "76093",
      "76124",
      "76249",
      "76804",
      "77184",
      "77389",
      "77399",
      "77419",
      "77558",
      "77603",
      "77615",
      "77633",
      "77820",
      "78484",
      "78556",
      "600014"
    ]
  },
  "5c09ec26-5505-4508-98c5-db244798d9d1": {
    "team": "1610612759",
    "year": "1984",
    "players": [
      "926",
      "76093",
      "76436",
      "76804",
      "77092",
      "77184",
      "77191",
      "77615",
      "77633",
      "77820",
      "78314",
      "78364",
      "600014"
    ]
  },
  "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8": {
    "team": "1610612759",
    "year": "1985",
    "players": [
      "895",
      "926",
      "941",
      "76260",
      "76881",
      "77076",
      "77134",
      "77484",
      "77615",
      "77633",
      "78279",
      "600014"
    ]
  },
  "1c2a533a-883a-4690-a94e-edebbb2ec9bd": {
    "team": "1610612759",
    "year": "1986",
    "players": [
      "783",
      "926",
      "1008",
      "1474",
      "76158",
      "76260",
      "76881",
      "77173",
      "77542",
      "77615",
      "77633",
      "77697",
      "78279",
      "600014"
    ]
  },
  "473f0beb-d4eb-46a7-b66f-d6831084a451": {
    "team": "1610612759",
    "year": "1987",
    "players": [
      "246",
      "783",
      "926",
      "939",
      "1008",
      "76158",
      "76179",
      "76518",
      "76881",
      "76901",
      "77615",
      "77697",
      "77719",
      "77939",
      "78279",
      "78584",
      "78644"
    ]
  },
  "a244a9bb-0ce8-42c7-aa8e-35291e38f08a": {
    "team": "1610612759",
    "year": "1988",
    "players": [
      "137",
      "194",
      "246",
      "335",
      "783",
      "926",
      "1008",
      "1091",
      "76042",
      "76426",
      "76901",
      "77263",
      "77614",
      "77691",
      "78022",
      "78175",
      "78418",
      "78516"
    ]
  },
  "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8": {
    "team": "1610612759",
    "year": "1989",
    "players": [
      "187",
      "199",
      "251",
      "335",
      "393",
      "764",
      "766",
      "783",
      "1097",
      "76172",
      "77175",
      "77354",
      "77633"
    ]
  },
  "c5fcd661-3f22-42bc-99bf-414a346732e2": {
    "team": "1610612759",
    "year": "1990",
    "players": [
      "187",
      "251",
      "335",
      "368",
      "393",
      "422",
      "763",
      "764",
      "766",
      "925",
      "939",
      "76876",
      "76881",
      "77374",
      "77879"
    ]
  },
  "61f90119-05dd-4779-bfd7-fcf297701a65": {
    "team": "1610612759",
    "year": "1991",
    "players": [
      "140",
      "156",
      "187",
      "251",
      "335",
      "357",
      "393",
      "764",
      "76097",
      "76446",
      "76876",
      "77167",
      "77879",
      "78375"
    ]
  },
  "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90": {
    "team": "1610612759",
    "year": "1992",
    "players": [
      "107",
      "116",
      "156",
      "187",
      "219",
      "251",
      "335",
      "422",
      "462",
      "694",
      "764",
      "1380",
      "76138",
      "77777",
      "78195"
    ]
  },
  "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388": {
    "team": "1610612759",
    "year": "1993",
    "players": [
      "23",
      "43",
      "107",
      "156",
      "187",
      "219",
      "335",
      "462",
      "764",
      "917",
      "1380",
      "1861",
      "76726",
      "77707"
    ]
  },
  "d6428cec-3758-4908-ac44-81c67877df4e": {
    "team": "1610612759",
    "year": "1994",
    "players": [
      "23",
      "43",
      "187",
      "219",
      "251",
      "335",
      "422",
      "456",
      "458",
      "462",
      "470",
      "764",
      "917",
      "1022",
      "76482",
      "77449"
    ]
  },
  "fabeef09-a907-4448-9caa-ee4c92dfffc2": {
    "team": "1610612759",
    "year": "1995",
    "players": [
      "42",
      "61",
      "219",
      "246",
      "251",
      "293",
      "422",
      "456",
      "470",
      "724",
      "725",
      "764",
      "781"
    ]
  },
  "c1c3a2ed-15e5-4d31-8d78-09dde994a4be": {
    "team": "1610612759",
    "year": "1996",
    "players": [
      "42",
      "61",
      "137",
      "219",
      "246",
      "251",
      "293",
      "422",
      "724",
      "781",
      "994",
      "1122"
    ]
  },
  "34728380-7c3f-4b0d-afce-5b4eb9af171b": {
    "team": "1610612759",
    "year": "1997",
    "players": [
      "38",
      "42",
      "61",
      "219",
      "422",
      "456",
      "764",
      "781",
      "990",
      "1002",
      "1114",
      "1495"
    ]
  },
  "9821bc62-c9f6-4e8c-b656-b8ffc595fd73": {
    "team": "1610612759",
    "year": "1998",
    "players": [
      "53",
      "70",
      "251",
      "422",
      "760",
      "764",
      "781",
      "990",
      "1114",
      "1495",
      "1498",
      "1562"
    ]
  },
  "a4c2ebb6-4746-4a22-bea4-4b26da8efa29": {
    "team": "1610612759",
    "year": "1999",
    "players": [
      "53",
      "251",
      "345",
      "422",
      "760",
      "764",
      "955",
      "990",
      "1114",
      "1495",
      "1498",
      "1760"
    ]
  },
  "6031a224-5eee-4f57-8eda-ecc1fb9562e1": {
    "team": "1610612759",
    "year": "2000",
    "players": [
      "70",
      "198",
      "251",
      "345",
      "422",
      "764",
      "955",
      "990",
      "1035",
      "1495",
      "1498",
      "1507"
    ]
  },
  "1204693e-5234-4041-9acb-b8a3eea3566c": {
    "team": "1610612759",
    "year": "2001",
    "players": [
      "95",
      "120",
      "198",
      "345",
      "685",
      "764",
      "990",
      "1043",
      "1477",
      "1495",
      "1498",
      "1520",
      "2078",
      "2225"
    ]
  },
  "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b": {
    "team": "1610612759",
    "year": "2002",
    "players": [
      "70",
      "120",
      "190",
      "198",
      "764",
      "788",
      "990",
      "1477",
      "1495",
      "1536",
      "1630",
      "1938",
      "2049",
      "2057",
      "2225",
      "2370",
      "2484"
    ]
  },
  "884e585b-12f2-4f77-acba-9ab3eb9d71ae": {
    "team": "1610612759",
    "year": "2003",
    "players": [
      "109",
      "369",
      "788",
      "990",
      "1477",
      "1495",
      "1725",
      "1752",
      "1938",
      "2045",
      "2078",
      "2225",
      "2484",
      "2679",
      "2682"
    ]
  },
  "58289a19-dade-4b36-bd1f-10ba99958a24": {
    "team": "1610612759",
    "year": "2004",
    "players": [
      "109",
      "299",
      "699",
      "763",
      "1477",
      "1495",
      "1725",
      "1737",
      "1752",
      "1938",
      "2225",
      "2366",
      "2484",
      "2757"
    ]
  },
  "187b2b41-379c-4869-a6d8-8469aec4efbc": {
    "team": "1610612759",
    "year": "2005",
    "players": [
      "89",
      "109",
      "677",
      "699",
      "714",
      "1477",
      "1495",
      "1737",
      "1752",
      "1938",
      "2225",
      "2588",
      "2757",
      "101177"
    ]
  },
  "626775a6-4271-4d9f-ba9d-a8b119b162c2": {
    "team": "1610612759",
    "year": "2006",
    "players": [
      "109",
      "699",
      "714",
      "1477",
      "1495",
      "1521",
      "1922",
      "1938",
      "2225",
      "2408",
      "2588",
      "2757",
      "2866",
      "101177",
      "200778"
    ]
  },
  "13c01798-8ae0-4b74-aea4-30da75742856": {
    "team": "1610612759",
    "year": "2007",
    "players": [
      "109",
      "699",
      "703",
      "714",
      "757",
      "1477",
      "1495",
      "1521",
      "1938",
      "2035",
      "2137",
      "2225",
      "2588",
      "101133",
      "101177"
    ]
  },
  "2d3887fe-0c7c-4e89-af3a-55503c9d6b87": {
    "team": "1610612759",
    "year": "2008",
    "players": [
      "703",
      "714",
      "1477",
      "1495",
      "1521",
      "1938",
      "2137",
      "2225",
      "2400",
      "2427",
      "2588",
      "101133",
      "101177",
      "201173",
      "201588"
    ]
  },
  "4822c589-71e6-4c95-ad9f-fe4f724202aa": {
    "team": "1610612759",
    "year": "2009",
    "players": [
      "686",
      "1495",
      "1938",
      "2210",
      "2225",
      "2427",
      "2586",
      "2588",
      "101133",
      "201588",
      "201612",
      "201971",
      "201998",
      "202066",
      "202087"
    ]
  },
  "7730ce78-78ed-4bca-a1a8-2c0df5d31c52": {
    "team": "1610612759",
    "year": "2010",
    "players": [
      "686",
      "1495",
      "1938",
      "2210",
      "2225",
      "2588",
      "200779",
      "200809",
      "201168",
      "201588",
      "201971",
      "201980",
      "202341",
      "202364",
      "202390"
    ]
  },
  "eaa71d89-5839-4a85-945e-b783a1864495": {
    "team": "1610612759",
    "year": "2011",
    "players": [
      "1495",
      "1536",
      "1938",
      "2225",
      "2564",
      "2588",
      "201168",
      "201182",
      "201971",
      "201980",
      "201988",
      "202341",
      "202390",
      "202695",
      "202709"
    ]
  },
  "4231849b-3ead-4d05-b93f-0c6a52ae3008": {
    "team": "1610612759",
    "year": "2012",
    "players": [
      "1495",
      "1503",
      "1938",
      "2225",
      "2564",
      "2588",
      "201168",
      "201971",
      "201980",
      "201986",
      "201988",
      "202390",
      "202695",
      "202709",
      "203382"
    ]
  },
  "5f2d0085-7d27-4458-be57-9fb5ff3d09ab": {
    "team": "1610612759",
    "year": "2013",
    "players": [
      "1495",
      "1938",
      "2225",
      "2564",
      "2588",
      "201158",
      "201168",
      "201948",
      "201965",
      "201980",
      "201988",
      "202345",
      "202695",
      "202709",
      "203382"
    ]
  },
  "76edbc4f-6dd2-4da2-8392-ed0d57db862a": {
    "team": "1610612759",
    "year": "2014",
    "players": [
      "1495",
      "1938",
      "2225",
      "2564",
      "2588",
      "201158",
      "201168",
      "201965",
      "201980",
      "201988",
      "202130",
      "202695",
      "202709",
      "203382",
      "203937"
    ]
  },
  "513319a3-e55b-4c85-93d5-b45d51fa457e": {
    "team": "1610612759",
    "year": "2015",
    "players": [
      "1495",
      "1889",
      "1938",
      "2225",
      "2561",
      "2564",
      "2588",
      "2755",
      "200746",
      "201980",
      "201988",
      "202695",
      "203613",
      "203937",
      "1626246"
    ]
  },
  "2727fadf-a3e8-4564-9084-0145ec943ae4": {
    "team": "1610612759",
    "year": "2016",
    "players": [
      "1938",
      "2200",
      "2225",
      "101135",
      "200746",
      "201202",
      "201980",
      "201988",
      "202695",
      "202722",
      "203473",
      "203613",
      "203937",
      "1627749",
      "1627854"
    ]
  },
  "acfee44c-b308-4a58-a6b3-e1fcb450b8f8": {
    "team": "1610612759",
    "year": "2017",
    "players": [
      "1938",
      "2200",
      "2225",
      "200746",
      "200752",
      "201980",
      "201988",
      "202695",
      "202722",
      "203464",
      "203530",
      "203937",
      "1626199",
      "1627749",
      "1627854",
      "1627856",
      "1628401"
    ]
  },
  "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b": {
    "team": "1610612759",
    "year": "2018",
    "players": [
      "200746",
      "200752",
      "201158",
      "201942",
      "201967",
      "201988",
      "202347",
      "202700",
      "202722",
      "1627749",
      "1627751",
      "1627854",
      "1628401",
      "1628500",
      "1629002",
      "1629022",
      "1629234"
    ]
  },
  "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e": {
    "team": "1610612759",
    "year": "2019",
    "players": [
      "200746",
      "200752",
      "201158",
      "201942",
      "201988",
      "1626168",
      "1627749",
      "1627751",
      "1627854",
      "1628401",
      "1629002",
      "1629022",
      "1629234",
      "1629640",
      "1629677",
      "1629683"
    ]
  },
  "c9d83e93-3057-4fd1-83c3-31ceb80562fa": {
    "team": "1610612760",
    "year": "1967",
    "players": [
      "76018",
      "76983",
      "77313",
      "77410",
      "77584",
      "77676",
      "77765",
      "78042",
      "78335",
      "78372",
      "78485",
      "78579"
    ]
  },
  "5df3e966-18d8-42d1-a237-835fc00837b6": {
    "team": "1610612760",
    "year": "1968",
    "players": [
      "76915",
      "76956",
      "77227",
      "77244",
      "77313",
      "77410",
      "77584",
      "77658",
      "77676",
      "78042",
      "78335",
      "78530",
      "78651"
    ]
  },
  "3829b824-1221-432d-8dc1-388a4d2f1cce": {
    "team": "1610612760",
    "year": "1969",
    "players": [
      "76027",
      "76206",
      "76403",
      "76915",
      "76956",
      "77244",
      "77584",
      "77676",
      "78042",
      "78213",
      "78335",
      "78530",
      "78592",
      "78651"
    ]
  },
  "6e488406-7055-45fd-b876-5b97997c0cf1": {
    "team": "1610612760",
    "year": "1970",
    "players": [
      "76002",
      "76403",
      "76477",
      "76735",
      "76981",
      "76985",
      "77295",
      "77584",
      "78042",
      "78213",
      "78335",
      "78530",
      "78592"
    ]
  },
  "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4": {
    "team": "1610612760",
    "year": "1971",
    "players": [
      "76002",
      "76272",
      "76403",
      "76477",
      "76735",
      "76981",
      "76985",
      "77295",
      "77523",
      "78213",
      "78530",
      "78592"
    ]
  },
  "061ae24a-cfe3-4632-95c1-7b43f58c91c0": {
    "team": "1610612760",
    "year": "1972",
    "players": [
      "76130",
      "76256",
      "76272",
      "76477",
      "76607",
      "76747",
      "76981",
      "76985",
      "77523",
      "77540",
      "78213",
      "78239",
      "78592",
      "78622"
    ]
  },
  "015afdf3-3da7-4590-9ac2-2a1976094cf3": {
    "team": "1610612760",
    "year": "1973",
    "players": [
      "76256",
      "76272",
      "76747",
      "76807",
      "76981",
      "76983",
      "77080",
      "77468",
      "77523",
      "77540",
      "78213",
      "78239",
      "78473",
      "78560"
    ]
  },
  "cc792a0f-fa98-4caf-ad55-38fe38f01714": {
    "team": "1610612760",
    "year": "1974",
    "players": [
      "76256",
      "76272",
      "76305",
      "76397",
      "76559",
      "76747",
      "76860",
      "76981",
      "77080",
      "77111",
      "77540",
      "78168",
      "78349",
      "78473"
    ]
  },
  "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c": {
    "team": "1610612760",
    "year": "1975",
    "players": [
      "76002",
      "76095",
      "76272",
      "76305",
      "76343",
      "76559",
      "76815",
      "76860",
      "77080",
      "77733",
      "77759",
      "78103",
      "78168",
      "78473"
    ]
  },
  "8718c236-d862-42de-a84d-ef29c83369b9": {
    "team": "1610612760",
    "year": "1976",
    "players": [
      "76109",
      "76272",
      "76305",
      "76860",
      "76873",
      "77141",
      "77412",
      "77733",
      "77759",
      "78103",
      "78349",
      "78473",
      "78475",
      "78531"
    ]
  },
  "6306dadf-1ee7-4678-b035-ef14578746a4": {
    "team": "1610612760",
    "year": "1977",
    "players": [
      "76272",
      "76722",
      "76873",
      "76966",
      "77141",
      "78103",
      "78149",
      "78151",
      "78349",
      "78481",
      "78549",
      "78602"
    ]
  },
  "5e43acaa-2104-49ad-b07f-b37c04e28912": {
    "team": "1610612760",
    "year": "1978",
    "players": [
      "76078",
      "76272",
      "76941",
      "76966",
      "77141",
      "77152",
      "77328",
      "77989",
      "78133",
      "78149",
      "78151",
      "78213",
      "78442",
      "78549"
    ]
  },
  "278e33a4-dd30-4a35-b7be-2e7b71623707": {
    "team": "1610612760",
    "year": "1979",
    "players": [
      "76085",
      "76272",
      "77141",
      "77152",
      "77167",
      "77328",
      "78133",
      "78149",
      "78151",
      "78442",
      "78549"
    ]
  },
  "6cb87ca5-b208-46a1-b393-022423c35c0d": {
    "team": "1610612760",
    "year": "1980",
    "players": [
      "76078",
      "76085",
      "76272",
      "76583",
      "76587",
      "76944",
      "77024",
      "77152",
      "77167",
      "78133",
      "78143",
      "78149",
      "78442",
      "78500",
      "78513"
    ]
  },
  "9cf046e5-f475-41ef-a3d3-f517368a157b": {
    "team": "1610612760",
    "year": "1981",
    "players": [
      "76272",
      "76583",
      "76944",
      "77152",
      "77896",
      "78133",
      "78149",
      "78423",
      "78442",
      "78549"
    ]
  },
  "a59119a0-b26d-4a60-b162-d2df226404ce": {
    "team": "1610612760",
    "year": "1982",
    "players": [
      "76272",
      "76583",
      "76885",
      "76971",
      "77239",
      "77896",
      "78133",
      "78149",
      "78199",
      "78326",
      "78423",
      "78549"
    ]
  },
  "3c25ef9a-4c70-4f76-8ce4-a86f272927ce": {
    "team": "1610612760",
    "year": "1983",
    "players": [
      "1472",
      "76230",
      "76272",
      "76971",
      "76980",
      "77135",
      "77273",
      "77518",
      "78149",
      "78279",
      "78326",
      "78423",
      "78549",
      "78610"
    ]
  },
  "4e8fe930-0176-4e3b-9fda-73e7317840a4": {
    "team": "1610612760",
    "year": "1984",
    "players": [
      "783",
      "1472",
      "76177",
      "76443",
      "76993",
      "77273",
      "77516",
      "77518",
      "78093",
      "78149",
      "78215",
      "78279",
      "78423",
      "78610",
      "78633"
    ]
  },
  "0d39d553-7b93-4206-b525-d87f764e4d5d": {
    "team": "1610612760",
    "year": "1985",
    "players": [
      "783",
      "1365",
      "1472",
      "76993",
      "77149",
      "77469",
      "77516",
      "77852",
      "77870",
      "78149",
      "78215",
      "78261",
      "78423",
      "78610",
      "78633"
    ]
  },
  "f318ac55-9b8f-47e9-8d51-5bd60cb174f6": {
    "team": "1610612760",
    "year": "1986",
    "players": [
      "107",
      "175",
      "203",
      "1365",
      "1472",
      "77136",
      "77144",
      "77280",
      "77420",
      "77852",
      "78087",
      "78241",
      "78556",
      "78633"
    ]
  },
  "d58ee353-d825-4a50-96dc-975b331f83f2": {
    "team": "1610612760",
    "year": "1987",
    "players": [
      "9",
      "107",
      "175",
      "178",
      "203",
      "365",
      "1365",
      "1472",
      "77136",
      "78087",
      "78556",
      "78633"
    ]
  },
  "90c8da1b-ce02-4f81-83f9-fadd291577cf": {
    "team": "1610612760",
    "year": "1988",
    "players": [
      "9",
      "107",
      "175",
      "178",
      "203",
      "262",
      "365",
      "422",
      "1365",
      "76091",
      "76378",
      "77419",
      "77946",
      "78087"
    ]
  },
  "345866de-4a0b-47e3-bd9b-84a98c5ddf2e": {
    "team": "1610612760",
    "year": "1989",
    "players": [
      "9",
      "107",
      "178",
      "203",
      "262",
      "344",
      "365",
      "422",
      "431",
      "1365",
      "76497",
      "76695",
      "77134",
      "77571"
    ]
  },
  "8f0aed89-b637-4e96-8fe6-5e1303c9146a": {
    "team": "1610612760",
    "year": "1990",
    "players": [
      "9",
      "56",
      "104",
      "203",
      "262",
      "344",
      "365",
      "431",
      "698",
      "894",
      "76453",
      "76497",
      "77571"
    ]
  },
  "b3bd95d0-2928-4e01-8f1d-47b167775984": {
    "team": "1610612760",
    "year": "1991",
    "players": [
      "55",
      "56",
      "104",
      "203",
      "262",
      "344",
      "365",
      "431",
      "698",
      "894",
      "1101",
      "76268",
      "76497",
      "77294"
    ]
  },
  "643eb0b6-e8a4-4b54-9c28-e76bcaae7520": {
    "team": "1610612760",
    "year": "1992",
    "players": [
      "56",
      "64",
      "88",
      "203",
      "262",
      "344",
      "355",
      "365",
      "431",
      "698",
      "894",
      "1101",
      "77789"
    ]
  },
  "0c8fc0cf-daa5-4e88-8b60-1caaa6717655": {
    "team": "1610612760",
    "year": "1993",
    "players": [
      "12",
      "56",
      "64",
      "88",
      "96",
      "203",
      "262",
      "355",
      "383",
      "431",
      "894",
      "911",
      "1101",
      "76732"
    ]
  },
  "85e9eec7-2e27-4506-89d1-ebedbb46f2ed": {
    "team": "1610612760",
    "year": "1994",
    "players": [
      "36",
      "56",
      "64",
      "88",
      "96",
      "112",
      "203",
      "355",
      "383",
      "431",
      "780",
      "911",
      "1101",
      "76362"
    ]
  },
  "c67c6a76-8882-48ad-a5da-dcb477aa577b": {
    "team": "1610612760",
    "year": "1995",
    "players": [
      "56",
      "64",
      "88",
      "96",
      "203",
      "355",
      "431",
      "726",
      "727",
      "765",
      "766",
      "783",
      "911"
    ]
  },
  "28c686d2-151c-45b0-b976-f1ca5ae19603": {
    "team": "1610612760",
    "year": "1996",
    "players": [
      "29",
      "37",
      "56",
      "64",
      "96",
      "187",
      "203",
      "431",
      "727",
      "765",
      "766",
      "1125"
    ]
  },
  "9329091c-5340-456a-ba55-bcdfcd2a8a82": {
    "team": "1610612760",
    "year": "1997",
    "players": [
      "21",
      "29",
      "56",
      "64",
      "96",
      "107",
      "203",
      "452",
      "760",
      "765",
      "1425"
    ]
  },
  "240cc5c0-6f74-48dc-acb9-305f7fe9a912": {
    "team": "1610612760",
    "year": "1998",
    "players": [
      "56",
      "96",
      "178",
      "180",
      "452",
      "765",
      "931",
      "1003",
      "1425",
      "1735",
      "1740",
      "1741"
    ]
  },
  "cb5320e4-53d6-4150-bc3f-639602fe7d4b": {
    "team": "1610612760",
    "year": "1999",
    "players": [
      "56",
      "270",
      "323",
      "452",
      "456",
      "699",
      "1023",
      "1739",
      "1740",
      "1741",
      "1742",
      "1953"
    ]
  },
  "85b7144d-faef-40f3-a8c8-043f97bbcd40": {
    "team": "1610612760",
    "year": "2000",
    "players": [
      "56",
      "121",
      "452",
      "699",
      "766",
      "1023",
      "1739",
      "1740",
      "1742",
      "2046",
      "2071",
      "2106"
    ]
  },
  "c04784ce-a740-496a-b8dd-354463d7169d": {
    "team": "1610612760",
    "year": "2001",
    "players": [
      "56",
      "452",
      "699",
      "988",
      "1542",
      "1738",
      "1740",
      "1742",
      "1744",
      "2046",
      "2071",
      "2209",
      "2248"
    ]
  },
  "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd": {
    "team": "1610612760",
    "year": "2002",
    "players": [
      "56",
      "72",
      "699",
      "958",
      "1542",
      "1738",
      "1740",
      "1744",
      "1750",
      "1801",
      "1916",
      "2046",
      "2173",
      "2209",
      "2218",
      "2501"
    ]
  },
  "980b1ae7-558b-4c53-a0ab-a0be44d100e4": {
    "team": "1610612760",
    "year": "2003",
    "players": [
      "699",
      "951",
      "958",
      "1498",
      "1738",
      "1740",
      "1744",
      "1910",
      "1916",
      "2209",
      "2436",
      "2499",
      "2501",
      "2555",
      "2557"
    ]
  },
  "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4": {
    "team": "1610612760",
    "year": "2004",
    "players": [
      "951",
      "958",
      "1498",
      "1504",
      "1740",
      "1744",
      "2043",
      "2209",
      "2436",
      "2501",
      "2555",
      "2557",
      "2741",
      "2863"
    ]
  },
  "47dca733-a8bb-4e17-9d48-e6cc54aed42b": {
    "team": "1610612760",
    "year": "2005",
    "players": [
      "951",
      "1504",
      "1630",
      "1740",
      "2248",
      "2366",
      "2404",
      "2555",
      "2557",
      "2741",
      "2863",
      "101130",
      "101230"
    ]
  },
  "3ceb35ca-c6f1-4729-a9ce-14945a8621ab": {
    "team": "1610612760",
    "year": "2006",
    "players": [
      "951",
      "988",
      "1504",
      "1740",
      "2248",
      "2366",
      "2404",
      "2555",
      "2557",
      "2741",
      "2810",
      "2863",
      "101130",
      "101153",
      "200754"
    ]
  },
  "d606503c-c3bd-4127-a2f2-5770156c6dcd": {
    "team": "1610612760",
    "year": "2007",
    "players": [
      "923",
      "1559",
      "1922",
      "2248",
      "2404",
      "2555",
      "2557",
      "2648",
      "2741",
      "2863",
      "101130",
      "101153",
      "200754",
      "201142",
      "201145"
    ]
  },
  "23acb720-d0cf-4182-9809-8a82ffe3779c": {
    "team": "1610612760",
    "year": "2008",
    "players": [
      "990",
      "1088",
      "2046",
      "2248",
      "2420",
      "2555",
      "2733",
      "2741",
      "2863",
      "200757",
      "201142",
      "201145",
      "201566",
      "201591",
      "201602"
    ]
  },
  "9d178503-abbf-4f3b-865a-c13b7c1da8ac": {
    "team": "1610612760",
    "year": "2009",
    "players": [
      "1563",
      "2041",
      "2420",
      "2555",
      "200757",
      "201142",
      "201145",
      "201203",
      "201566",
      "201586",
      "201591",
      "201602",
      "201935",
      "201953",
      "201957"
    ]
  },
  "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf": {
    "team": "1610612760",
    "year": "2010",
    "players": [
      "1737",
      "2555",
      "2570",
      "2767",
      "101126",
      "200757",
      "201142",
      "201161",
      "201566",
      "201586",
      "201935",
      "201953",
      "201957",
      "201987",
      "202332"
    ]
  },
  "7f23a5fb-d7d1-4096-955b-6836e40d9f9d": {
    "team": "1610612760",
    "year": "2011",
    "players": [
      "965",
      "1737",
      "2555",
      "2570",
      "2767",
      "200757",
      "201142",
      "201161",
      "201566",
      "201586",
      "201935",
      "201953",
      "202332",
      "202351",
      "202704"
    ]
  },
  "58c24127-2a5a-4b63-a84e-ce7fb8091ddc": {
    "team": "1610612760",
    "year": "2012",
    "players": [
      "965",
      "2555",
      "2570",
      "2755",
      "200757",
      "200758",
      "201142",
      "201566",
      "201586",
      "201934",
      "202350",
      "202704",
      "202732",
      "203087",
      "203103"
    ]
  },
  "ea74fd30-6765-4dc3-948b-9faed7ec7b7e": {
    "team": "1610612760",
    "year": "2013",
    "players": [
      "965",
      "2406",
      "2555",
      "2570",
      "200757",
      "201142",
      "201566",
      "201586",
      "201934",
      "202704",
      "203087",
      "203103",
      "203460",
      "203500",
      "203511"
    ]
  },
  "40187213-bcbb-43d1-a283-a7682ab728e0": {
    "team": "1610612760",
    "year": "2014",
    "players": [
      "2555",
      "200779",
      "201142",
      "201566",
      "201571",
      "201586",
      "201627",
      "202683",
      "202713",
      "203079",
      "203087",
      "203103",
      "203460",
      "203500",
      "203956"
    ]
  },
  "d8d5355f-4f4e-4c00-9a9c-7d700c555d59": {
    "team": "1610612760",
    "year": "2015",
    "players": [
      "1737",
      "2555",
      "200751",
      "201142",
      "201566",
      "201586",
      "201627",
      "202683",
      "202713",
      "203079",
      "203460",
      "203500",
      "203956",
      "203962",
      "1626166"
    ]
  },
  "0e161bfd-52f6-41c1-ba70-177db3f7526c": {
    "team": "1610612760",
    "year": "2016",
    "players": [
      "2555",
      "201566",
      "201959",
      "202683",
      "202708",
      "202713",
      "203460",
      "203500",
      "203506",
      "203518",
      "203902",
      "203924",
      "203926",
      "203962",
      "1627734"
    ]
  },
  "77e6e4de-42ee-4c42-be39-b046215914f2": {
    "team": "1610612760",
    "year": "2017",
    "players": [
      "2546",
      "2555",
      "101109",
      "201147",
      "201566",
      "202331",
      "202335",
      "202713",
      "203460",
      "203500",
      "203518",
      "203924",
      "203962",
      "1626177",
      "1627772",
      "1628390",
      "1628408"
    ]
  },
  "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199": {
    "team": "1610612760",
    "year": "2018",
    "players": [
      "101109",
      "201566",
      "202331",
      "202335",
      "202693",
      "203457",
      "203460",
      "203471",
      "203500",
      "203924",
      "1627846",
      "1628390",
      "1628393",
      "1628977",
      "1629055",
      "1629126"
    ]
  },
  "132cab8d-a67b-4237-8434-a38471a710a7": {
    "team": "1610612760",
    "year": "2019",
    "players": [
      "101108",
      "201568",
      "203457",
      "203460",
      "203471",
      "203488",
      "203500",
      "1627846",
      "1628390",
      "1628977",
      "1628983",
      "1628987",
      "1629126",
      "1629647",
      "1629652",
      "1629676"
    ]
  },
  "4a934d85-87c5-4059-9963-b76f50593f0b": {
    "team": "1610612761",
    "year": "1995",
    "players": [
      "57",
      "145",
      "435",
      "440",
      "728",
      "729",
      "730",
      "757",
      "768",
      "926",
      "932",
      "76683",
      "77776"
    ]
  },
  "dbff03be-c19e-4e69-9c80-539569f223da": {
    "team": "1610612761",
    "year": "1996",
    "players": [
      "32",
      "57",
      "412",
      "435",
      "461",
      "704",
      "729",
      "757",
      "948",
      "1005",
      "1073",
      "1381"
    ]
  },
  "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5": {
    "team": "1610612761",
    "year": "1997",
    "players": [
      "57",
      "244",
      "718",
      "932",
      "948",
      "961",
      "1073",
      "1497",
      "1503",
      "1519",
      "1541",
      "1612"
    ]
  },
  "241e4430-9349-4627-901a-466c5486c0f8": {
    "team": "1610612761",
    "year": "1998",
    "players": [
      "57",
      "244",
      "788",
      "891",
      "961",
      "1073",
      "1503",
      "1519",
      "1541",
      "1565",
      "1713"
    ]
  },
  "f9c9aa9d-d215-423c-9918-dec5a3ecaaae": {
    "team": "1610612761",
    "year": "1999",
    "players": [
      "57",
      "177",
      "209",
      "213",
      "244",
      "788",
      "891",
      "1503",
      "1519",
      "1541",
      "1565",
      "1713"
    ]
  },
  "f7880972-72ca-4727-996c-8a7e93fda19e": {
    "team": "1610612761",
    "year": "2000",
    "players": [
      "145",
      "164",
      "209",
      "213",
      "376",
      "891",
      "966",
      "1541",
      "1565",
      "1713",
      "1721",
      "2050"
    ]
  },
  "03919be3-368f-4f38-9235-7c7e490247ee": {
    "team": "1610612761",
    "year": "2001",
    "players": [
      "164",
      "165",
      "209",
      "213",
      "376",
      "966",
      "1541",
      "1565",
      "1713",
      "1721",
      "1760",
      "1983",
      "2050",
      "2214"
    ]
  },
  "c840df16-2e76-4e5a-8ca0-c35c950288a9": {
    "team": "1610612761",
    "year": "2002",
    "players": [
      "165",
      "213",
      "283",
      "376",
      "441",
      "966",
      "1541",
      "1572",
      "1713",
      "1741",
      "1983",
      "2050",
      "2055",
      "2214",
      "2423"
    ]
  },
  "25d76ca2-0777-49ab-84f3-6abb0a1cc94f": {
    "team": "1610612761",
    "year": "2003",
    "players": [
      "147",
      "393",
      "441",
      "688",
      "916",
      "923",
      "1541",
      "1713",
      "1901",
      "1960",
      "2040",
      "2050",
      "2425",
      "2427",
      "2547"
    ]
  },
  "15b3f603-0e98-4097-a9a7-c6c2689d36ae": {
    "team": "1610612761",
    "year": "2004",
    "players": [
      "147",
      "441",
      "677",
      "1425",
      "1747",
      "1960",
      "2050",
      "2241",
      "2547",
      "2588",
      "2737",
      "2776"
    ]
  },
  "53990650-b132-46c8-9435-b3308f7a0b10": {
    "team": "1610612761",
    "year": "2005",
    "players": [
      "239",
      "1541",
      "1725",
      "2050",
      "2229",
      "2254",
      "2547",
      "2743",
      "2776",
      "2857",
      "101111",
      "101121",
      "101181"
    ]
  },
  "dbbeab72-0d20-45a0-b2e4-c51e0162026c": {
    "team": "1610612761",
    "year": "2006",
    "players": [
      "239",
      "1515",
      "1725",
      "2050",
      "2413",
      "2547",
      "2551",
      "2739",
      "2743",
      "2776",
      "101121",
      "101166",
      "101181",
      "200745",
      "200816"
    ]
  },
  "0e001846-f440-4bca-ae94-6cf01edf7123": {
    "team": "1610612761",
    "year": "2007",
    "players": [
      "1515",
      "1725",
      "1766",
      "2056",
      "2547",
      "2551",
      "2568",
      "2574",
      "2743",
      "101121",
      "101181",
      "200081",
      "200745"
    ]
  },
  "91c165fd-b08a-4c73-84bf-a029d5600436": {
    "team": "1610612761",
    "year": "2008",
    "players": [
      "1515",
      "1890",
      "2063",
      "2547",
      "2556",
      "2574",
      "2743",
      "101121",
      "101146",
      "101181",
      "200745",
      "200753",
      "200763",
      "200822",
      "201605"
    ]
  },
  "18266baf-bfab-4bf0-95b6-3b7f9f282af4": {
    "team": "1610612761",
    "year": "2009",
    "players": [
      "1725",
      "2045",
      "2501",
      "2547",
      "2556",
      "101120",
      "101127",
      "101161",
      "101181",
      "200745",
      "200753",
      "201158",
      "201595",
      "201603",
      "201942"
    ]
  },
  "5f979ffb-67ac-4f2e-8f07-8a63b2f10670": {
    "team": "1610612761",
    "year": "2010",
    "players": [
      "2501",
      "2571",
      "101132",
      "101161",
      "101181",
      "200745",
      "201153",
      "201573",
      "201582",
      "201595",
      "201603",
      "201942",
      "201949",
      "202334",
      "202374"
    ]
  },
  "819d71de-4194-483f-b197-3c61d2f97675": {
    "team": "1610612761",
    "year": "2011",
    "players": [
      "2048",
      "101132",
      "101161",
      "101181",
      "101187",
      "200745",
      "201189",
      "201573",
      "201814",
      "201942",
      "201949",
      "202334",
      "202374",
      "202386"
    ]
  },
  "896773e3-d58b-484d-b4ce-cba82ed75933": {
    "team": "1610612761",
    "year": "2012",
    "players": [
      "2554",
      "2742",
      "101132",
      "101161",
      "101187",
      "101249",
      "200745",
      "200752",
      "200768",
      "201189",
      "201942",
      "202361",
      "202685",
      "203082",
      "203112"
    ]
  },
  "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831": {
    "team": "1610612761",
    "year": "2013",
    "players": [
      "2422",
      "101161",
      "101236",
      "200768",
      "200779",
      "201942",
      "201946",
      "201986",
      "202335",
      "202349",
      "202361",
      "202685",
      "202779",
      "202933",
      "203082"
    ]
  },
  "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a": {
    "team": "1610612761",
    "year": "2014",
    "players": [
      "101150",
      "101161",
      "101236",
      "200768",
      "201880",
      "201942",
      "201946",
      "201949",
      "202335",
      "202349",
      "202361",
      "202685",
      "203082",
      "203512",
      "203998"
    ]
  },
  "5ac68c0d-43c1-490d-87f9-5b431453d831": {
    "team": "1610612761",
    "year": "2015",
    "players": [
      "2449",
      "200768",
      "201574",
      "201942",
      "201949",
      "201960",
      "202335",
      "202685",
      "202687",
      "202709",
      "203082",
      "203512",
      "203998",
      "1626153",
      "1626181"
    ]
  },
  "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e": {
    "team": "1610612761",
    "year": "2016",
    "players": [
      "200768",
      "200782",
      "201586",
      "201942",
      "201960",
      "202335",
      "202685",
      "202709",
      "203512",
      "203998",
      "1626153",
      "1626181",
      "1627751",
      "1627783",
      "1627832"
    ]
  },
  "42265145-0668-4113-bd04-6a1fa3d9f3d6": {
    "team": "1610612761",
    "year": "2017",
    "players": [
      "101139",
      "200768",
      "201586",
      "201942",
      "202685",
      "203485",
      "203512",
      "1626153",
      "1626181",
      "1626259",
      "1627751",
      "1627781",
      "1627783",
      "1627832",
      "1628035",
      "1628384"
    ]
  },
  "7855a129-e474-423e-a119-3ea4ddf812d3": {
    "team": "1610612761",
    "year": "2018",
    "players": [
      "200768",
      "201188",
      "201586",
      "201975",
      "201980",
      "202391",
      "202695",
      "203961",
      "1626181",
      "1626259",
      "1627775",
      "1627783",
      "1627832",
      "1628070",
      "1628384",
      "1628449"
    ]
  },
  "00aa6bed-2e74-4a5e-82ef-2bb680b41d67": {
    "team": "1610612761",
    "year": "2019",
    "players": [
      "200768",
      "201188",
      "201586",
      "1626169",
      "1626178",
      "1626181",
      "1626259",
      "1627775",
      "1627783",
      "1627832",
      "1628384",
      "1628449",
      "1628778",
      "1629052",
      "1629056",
      "1629608",
      "1629744"
    ]
  },
  "cf18926c-add0-4350-9f9c-f87df758e703": {
    "team": "1610612762",
    "year": "1974",
    "players": [
      "76106",
      "76144",
      "76162",
      "76187",
      "76223",
      "76413",
      "76458",
      "76761",
      "77115",
      "77162",
      "77259",
      "77365",
      "77459",
      "77636",
      "77701",
      "77973",
      "78239",
      "78432",
      "78561",
      "600011"
    ]
  },
  "352c468a-24d4-4f1c-8cd5-7348731395a5": {
    "team": "1610612762",
    "year": "1975",
    "players": [
      "76140",
      "76162",
      "76222",
      "76413",
      "76458",
      "77115",
      "77235",
      "77459",
      "77528",
      "77636",
      "77701",
      "78239",
      "78561"
    ]
  },
  "4100a46a-2845-4fc5-b5a7-28c783b0e827": {
    "team": "1610612762",
    "year": "1976",
    "players": [
      "76140",
      "76222",
      "76413",
      "76832",
      "76889",
      "77067",
      "77115",
      "77235",
      "77459",
      "77528",
      "77636",
      "78239",
      "78433",
      "78561"
    ]
  },
  "5517a85a-4623-414c-89a6-b90bb5a42eca": {
    "team": "1610612762",
    "year": "1977",
    "players": [
      "76083",
      "76222",
      "76832",
      "76889",
      "77115",
      "77235",
      "77459",
      "77528",
      "77582",
      "77993",
      "78065",
      "78473"
    ]
  },
  "a8b9d60d-1449-49af-89fc-945fadd03a04": {
    "team": "1610612762",
    "year": "1978",
    "players": [
      "76083",
      "76321",
      "76832",
      "76879",
      "76889",
      "76948",
      "76981",
      "77115",
      "77235",
      "77364",
      "77459",
      "77528",
      "77993",
      "78305"
    ]
  },
  "3d64e1d0-7221-4ce7-831f-5d02089042ba": {
    "team": "1610612762",
    "year": "1979",
    "players": [
      "76204",
      "76213",
      "76257",
      "76276",
      "76336",
      "76504",
      "76516",
      "76541",
      "76544",
      "76767",
      "76806",
      "76948",
      "77258",
      "77264",
      "77459",
      "77873",
      "78431",
      "78516",
      "78545"
    ]
  },
  "79272c8a-a0ac-4650-b0a6-e971f46652f5": {
    "team": "1610612762",
    "year": "1980",
    "players": [
      "76151",
      "76204",
      "76257",
      "76441",
      "76504",
      "76619",
      "76874",
      "76890",
      "76948",
      "77215",
      "77602",
      "77715",
      "77873",
      "78425",
      "78537"
    ]
  },
  "16161b6e-7707-4383-b96c-3f7cf17fe149": {
    "team": "1610612762",
    "year": "1981",
    "players": [
      "7",
      "76368",
      "76504",
      "76619",
      "76874",
      "76890",
      "76948",
      "77715",
      "77873",
      "77997",
      "78537",
      "78609",
      "78618"
    ]
  },
  "97b94a55-c8d4-46d9-a717-bc83dc562b5e": {
    "team": "1610612762",
    "year": "1982",
    "players": [
      "76043",
      "76504",
      "76598",
      "76631",
      "76632",
      "76874",
      "76890",
      "77235",
      "77692",
      "77873",
      "78537",
      "78548",
      "78563"
    ]
  },
  "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291": {
    "team": "1610612762",
    "year": "1983",
    "players": [
      "1847",
      "76043",
      "76213",
      "76504",
      "76598",
      "76631",
      "76632",
      "76874",
      "76890",
      "76942",
      "77235",
      "78537"
    ]
  },
  "f924bbf7-3dec-4b9e-86de-5f7627a8e00a": {
    "team": "1610612762",
    "year": "1984",
    "players": [
      "122",
      "304",
      "1847",
      "76043",
      "76504",
      "76598",
      "76631",
      "76874",
      "76890",
      "76942",
      "77235",
      "77456",
      "77817",
      "78537"
    ]
  },
  "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a": {
    "team": "1610612762",
    "year": "1985",
    "players": [
      "122",
      "252",
      "304",
      "1847",
      "76436",
      "76504",
      "76631",
      "76874",
      "76942",
      "76980",
      "77092",
      "77456",
      "78102",
      "78537"
    ]
  },
  "324375ef-30c9-4fcb-86c6-f30147491d44": {
    "team": "1610612762",
    "year": "1986",
    "players": [
      "209",
      "252",
      "304",
      "1847",
      "76154",
      "76631",
      "76874",
      "76890",
      "76942",
      "77092",
      "78102",
      "78369"
    ]
  },
  "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f": {
    "team": "1610612762",
    "year": "1987",
    "players": [
      "252",
      "304",
      "1847",
      "76631",
      "76874",
      "76890",
      "76942",
      "77077",
      "77092",
      "77294",
      "78022",
      "78369",
      "78386"
    ]
  },
  "eecb8d44-e66b-417f-bd80-36f97052a98a": {
    "team": "1610612762",
    "year": "1988",
    "players": [
      "252",
      "287",
      "304",
      "1479",
      "1847",
      "76631",
      "76695",
      "76890",
      "76942",
      "77092",
      "77294",
      "77372",
      "77771"
    ]
  },
  "955486b2-508c-4776-884b-e521d5730bf8": {
    "team": "1610612762",
    "year": "1989",
    "players": [
      "252",
      "287",
      "304",
      "898",
      "1479",
      "1847",
      "76282",
      "76631",
      "76890",
      "76942",
      "77145",
      "77771",
      "78035"
    ]
  },
  "05c9fbb0-bdb9-4253-bbcb-347adf349bff": {
    "team": "1610612762",
    "year": "1990",
    "players": [
      "117",
      "252",
      "304",
      "342",
      "898",
      "1479",
      "1847",
      "76268",
      "76483",
      "76631",
      "76890",
      "77664",
      "77776",
      "77794",
      "78035"
    ]
  },
  "e34e570c-e94f-47f1-927d-d070fd2321f8": {
    "team": "1610612762",
    "year": "1991",
    "players": [
      "117",
      "128",
      "252",
      "304",
      "738",
      "785",
      "895",
      "898",
      "1134",
      "1479",
      "76482",
      "76631",
      "78035"
    ]
  },
  "49df35a0-09d0-4bff-b842-9b35d2edba0f": {
    "team": "1610612762",
    "year": "1992",
    "players": [
      "117",
      "128",
      "180",
      "252",
      "304",
      "895",
      "1080",
      "1128",
      "1134",
      "1474",
      "1479",
      "76583",
      "76631",
      "77081"
    ]
  },
  "d37f2fb1-fc17-4273-9d03-81e57c07a9c5": {
    "team": "1610612762",
    "year": "1993",
    "players": [
      "128",
      "180",
      "204",
      "252",
      "280",
      "304",
      "895",
      "935",
      "1007",
      "1128",
      "1425",
      "1472",
      "1603",
      "76772",
      "76875",
      "77081",
      "77644"
    ]
  },
  "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd": {
    "team": "1610612762",
    "year": "1994",
    "players": [
      "128",
      "156",
      "180",
      "204",
      "228",
      "252",
      "280",
      "304",
      "320",
      "898",
      "935",
      "1472",
      "1603",
      "76583"
    ]
  },
  "2dc3f7c6-7dae-4698-9a2d-5b616de948e8": {
    "team": "1610612762",
    "year": "1995",
    "players": [
      "128",
      "156",
      "204",
      "228",
      "252",
      "280",
      "304",
      "320",
      "323",
      "342",
      "356",
      "458",
      "731",
      "935"
    ]
  },
  "c4f3c460-0922-4bda-b7fc-59077cd6815e": {
    "team": "1610612762",
    "year": "1996",
    "players": [
      "156",
      "204",
      "228",
      "252",
      "304",
      "323",
      "356",
      "458",
      "731",
      "935",
      "1000",
      "1128"
    ]
  },
  "25f4a679-efde-4cd0-b491-1731aa9569e6": {
    "team": "1610612762",
    "year": "1997",
    "players": [
      "156",
      "204",
      "228",
      "252",
      "304",
      "323",
      "356",
      "458",
      "731",
      "935",
      "1000",
      "1521"
    ]
  },
  "f217a398-a097-4cd7-9b69-8aa3ea215b7b": {
    "team": "1610612762",
    "year": "1998",
    "players": [
      "204",
      "228",
      "252",
      "304",
      "323",
      "458",
      "731",
      "935",
      "957",
      "1000",
      "1521",
      "1847"
    ]
  },
  "30107e00-802b-466d-b025-30b5f60b2c06": {
    "team": "1610612762",
    "year": "1999",
    "players": [
      "178",
      "204",
      "228",
      "252",
      "288",
      "304",
      "458",
      "731",
      "935",
      "1521",
      "1900",
      "1909"
    ]
  },
  "7b92b5ee-7230-42dd-8439-cafc541fed3f": {
    "team": "1610612762",
    "year": "2000",
    "players": [
      "128",
      "178",
      "180",
      "252",
      "304",
      "317",
      "330",
      "731",
      "923",
      "935",
      "1521",
      "2052"
    ]
  },
  "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28": {
    "team": "1610612762",
    "year": "2001",
    "players": [
      "252",
      "304",
      "317",
      "680",
      "731",
      "923",
      "935",
      "1601",
      "1900",
      "1905",
      "1909",
      "2052",
      "2260"
    ]
  },
  "27fa6c21-b209-4a5a-8c48-fd62a812709b": {
    "team": "1610612762",
    "year": "2002",
    "players": [
      "41",
      "252",
      "304",
      "349",
      "384",
      "680",
      "731",
      "1514",
      "1723",
      "1905",
      "1909",
      "2052",
      "2221",
      "2260",
      "2306",
      "2414"
    ]
  },
  "f79cdf67-f3bc-4d80-9a09-b01eca8ee460": {
    "team": "1610612762",
    "year": "2003",
    "players": [
      "339",
      "731",
      "1630",
      "1723",
      "1905",
      "1913",
      "1921",
      "1952",
      "2221",
      "2260",
      "2306",
      "2414",
      "2562",
      "2590"
    ]
  },
  "28eacab0-70b6-464e-9364-355a0c9123d3": {
    "team": "1610612762",
    "year": "2004",
    "players": [
      "458",
      "988",
      "1052",
      "1723",
      "1921",
      "1952",
      "2246",
      "2260",
      "2414",
      "2693",
      "2743",
      "2745"
    ]
  },
  "77de9913-6212-4795-a7e5-3cd689ad5bcb": {
    "team": "1610612762",
    "year": "2005",
    "players": [
      "1723",
      "1905",
      "1921",
      "1960",
      "2246",
      "2260",
      "2430",
      "2484",
      "2693",
      "2737",
      "101114",
      "101139",
      "101261"
    ]
  },
  "88f678db-e0f1-4a17-8f1d-38d09b14cd5e": {
    "team": "1610612762",
    "year": "2006",
    "players": [
      "965",
      "1723",
      "1905",
      "1921",
      "2246",
      "2260",
      "2430",
      "2737",
      "101114",
      "101139",
      "200758",
      "200793",
      "200794"
    ]
  },
  "95eab72c-d15a-4057-bc71-c9cea00af893": {
    "team": "1610612762",
    "year": "2007",
    "players": [
      "1723",
      "1905",
      "2078",
      "2246",
      "2260",
      "2430",
      "2594",
      "101114",
      "101139",
      "101179",
      "200758",
      "200794",
      "201165",
      "201178"
    ]
  },
  "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce": {
    "team": "1610612762",
    "year": "2008",
    "players": [
      "1510",
      "1723",
      "1905",
      "2246",
      "2260",
      "2430",
      "2594",
      "101114",
      "101139",
      "101179",
      "200758",
      "200794",
      "201165",
      "201178",
      "201585"
    ]
  },
  "0e711c53-a43f-41a3-aba9-ff2789f269d4": {
    "team": "1610612762",
    "year": "2009",
    "players": [
      "1905",
      "2246",
      "2430",
      "2594",
      "101114",
      "101139",
      "101179",
      "200794",
      "201178",
      "201585",
      "201785",
      "202083",
      "202178"
    ]
  },
  "92dd460c-1f73-4e8e-b205-0338061e8ac5": {
    "team": "1610612762",
    "year": "2010",
    "players": [
      "1905",
      "1922",
      "1952",
      "2246",
      "2248",
      "2734",
      "2744",
      "101139",
      "101179",
      "200794",
      "201178",
      "202324",
      "202330",
      "202379"
    ]
  },
  "d46a5105-de7f-4d91-abee-dd4eb7ff82de": {
    "team": "1610612762",
    "year": "2011",
    "players": [
      "1952",
      "2224",
      "2248",
      "2572",
      "2734",
      "2744",
      "101139",
      "200794",
      "201336",
      "201960",
      "202324",
      "202330",
      "202379",
      "202683",
      "202692"
    ]
  },
  "83706098-479a-4732-9529-09c78cfdb502": {
    "team": "1610612762",
    "year": "2012",
    "players": [
      "2224",
      "2248",
      "2590",
      "2744",
      "101107",
      "200751",
      "200794",
      "201960",
      "202077",
      "202324",
      "202330",
      "202379",
      "202683",
      "202692",
      "203122"
    ]
  },
  "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d": {
    "team": "1610612762",
    "year": "2013",
    "players": [
      "2210",
      "101107",
      "101249",
      "201575",
      "202324",
      "202330",
      "202379",
      "202683",
      "202692",
      "202952",
      "203197",
      "203497",
      "203504",
      "203513",
      "203546"
    ]
  },
  "15bd6cda-c4f0-4c22-9921-d720a0b70509": {
    "team": "1610612762",
    "year": "2014",
    "players": [
      "202324",
      "202330",
      "202344",
      "202379",
      "202407",
      "202692",
      "203187",
      "203497",
      "203504",
      "203511",
      "203918",
      "203955",
      "203957",
      "204022",
      "204060"
    ]
  },
  "8443b84f-b107-4647-9a68-51bc436e7c61": {
    "team": "1610612762",
    "year": "2015",
    "players": [
      "202324",
      "202330",
      "202344",
      "202353",
      "202692",
      "202714",
      "203187",
      "203481",
      "203497",
      "203504",
      "203526",
      "203918",
      "203957",
      "204060",
      "1626168"
    ]
  },
  "272b357c-17be-4ce8-acf5-24791690c53b": {
    "team": "1610612762",
    "year": "2016",
    "players": [
      "2207",
      "2564",
      "201588",
      "202324",
      "202330",
      "202692",
      "202714",
      "203481",
      "203497",
      "203526",
      "203918",
      "203957",
      "204060",
      "1626168",
      "1627762"
    ]
  },
  "1bef196c-7fb3-4331-867f-b7ebbf83fa10": {
    "team": "1610612762",
    "year": "2017",
    "players": [
      "200757",
      "201937",
      "201973",
      "202324",
      "202327",
      "202692",
      "203109",
      "203497",
      "203526",
      "203957",
      "204060",
      "204065",
      "1626220",
      "1627777",
      "1628378",
      "1628396",
      "1628571"
    ]
  },
  "3003ae4a-7020-4b96-9041-d3bcf427a8f3": {
    "team": "1610612762",
    "year": "2018",
    "players": [
      "2594",
      "200757",
      "201937",
      "202324",
      "202327",
      "203109",
      "203497",
      "203526",
      "203957",
      "204060",
      "1626220",
      "1627777",
      "1628378",
      "1628396",
      "1628463",
      "1628513",
      "1628960"
    ]
  },
  "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4": {
    "team": "1610612762",
    "year": "2019",
    "players": [
      "201144",
      "202334",
      "202711",
      "203497",
      "203903",
      "204060",
      "1626144",
      "1626220",
      "1627777",
      "1628378",
      "1628396",
      "1628430",
      "1629625",
      "1629671",
      "1629714",
      "1629730",
      "1629752"
    ]
  },
  "aa19bf6b-dbe0-4212-9893-45bd3fa75119": {
    "team": "1610612763",
    "year": "1995",
    "players": [
      "2",
      "12",
      "21",
      "138",
      "316",
      "346",
      "732",
      "733",
      "734",
      "735",
      "785",
      "786",
      "898",
      "930"
    ]
  },
  "b884431e-1319-4251-a977-cdd919c3468e": {
    "team": "1610612763",
    "year": "1996",
    "players": [
      "21",
      "195",
      "287",
      "324",
      "432",
      "734",
      "735",
      "898",
      "949",
      "964",
      "997",
      "1425",
      "78009"
    ]
  },
  "fcc3f55b-00b3-4afd-a4e9-f544dacf8792": {
    "team": "1610612763",
    "year": "1997",
    "players": [
      "63",
      "195",
      "248",
      "310",
      "432",
      "735",
      "763",
      "898",
      "949",
      "1096",
      "1498",
      "1683"
    ]
  },
  "fc8d0225-3f5d-4f86-881e-de5501b3eba7": {
    "team": "1610612763",
    "year": "1998",
    "players": [
      "63",
      "143",
      "432",
      "685",
      "763",
      "949",
      "1518",
      "1545",
      "1710",
      "1732",
      "1764",
      "1626122"
    ]
  },
  "378d09ae-9325-475b-960c-5806574aab51": {
    "team": "1610612763",
    "year": "1999",
    "players": [
      "28",
      "192",
      "685",
      "735",
      "949",
      "970",
      "1710",
      "1722",
      "1732",
      "1918",
      "1960"
    ]
  },
  "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad": {
    "team": "1610612763",
    "year": "2000",
    "players": [
      "3",
      "51",
      "236",
      "735",
      "763",
      "949",
      "1065",
      "1134",
      "1710",
      "1722",
      "1800",
      "2031"
    ]
  },
  "721f24b6-955d-43a4-901d-da6918201f42": {
    "team": "1610612763",
    "year": "2001",
    "players": [
      "3",
      "763",
      "953",
      "1510",
      "1715",
      "1934",
      "2031",
      "2109",
      "2200",
      "2203",
      "2226",
      "2238"
    ]
  },
  "69c4432f-a9df-4bdd-b947-ef61debf2424": {
    "team": "1610612763",
    "year": "2002",
    "players": [
      "445",
      "763",
      "953",
      "1510",
      "1715",
      "1722",
      "1921",
      "2031",
      "2109",
      "2200",
      "2203",
      "2248",
      "2347",
      "2400",
      "2425",
      "2442",
      "2456"
    ]
  },
  "0c0266f7-815a-4bf4-8339-eb026f9738d6": {
    "team": "1610612763",
    "year": "2003",
    "players": [
      "448",
      "953",
      "1715",
      "1719",
      "1899",
      "2031",
      "2034",
      "2054",
      "2200",
      "2203",
      "2248",
      "2415",
      "2559",
      "2563",
      "2604"
    ]
  },
  "a7e77cd3-31c9-4bd1-bfd8-072687578419": {
    "team": "1610612763",
    "year": "2004",
    "players": [
      "953",
      "1715",
      "1719",
      "1899",
      "2031",
      "2034",
      "2073",
      "2200",
      "2203",
      "2248",
      "2563",
      "2766"
    ]
  },
  "b00a755a-14e3-4682-bed6-49df9136ad09": {
    "team": "1610612763",
    "year": "2005",
    "players": [
      "224",
      "757",
      "953",
      "1088",
      "1517",
      "2034",
      "2054",
      "2073",
      "2200",
      "2203",
      "2563",
      "2766",
      "101124",
      "101160",
      "101194"
    ]
  },
  "a5a047a0-58fc-499b-ae59-8ebed7fc0394": {
    "team": "1610612763",
    "year": "2006",
    "players": [
      "757",
      "1088",
      "2031",
      "2034",
      "2073",
      "2200",
      "2454",
      "2563",
      "101124",
      "101160",
      "200752",
      "200768",
      "200792",
      "200814"
    ]
  },
  "d98d42ba-33de-433f-9c54-23973925049a": {
    "team": "1610612763",
    "year": "2007",
    "players": [
      "2034",
      "2073",
      "2198",
      "2215",
      "2418",
      "2545",
      "2810",
      "101124",
      "200752",
      "200768",
      "201144",
      "201159"
    ]
  },
  "824e5cff-47e8-4169-8451-74a294a985e8": {
    "team": "1610612763",
    "year": "2008",
    "players": [
      "1761",
      "2032",
      "2036",
      "2060",
      "2366",
      "2545",
      "2624",
      "101124",
      "200752",
      "201144",
      "201188",
      "201564",
      "201589",
      "201632"
    ]
  },
  "2fd59817-7492-4a2f-a8f2-25aac87eea3c": {
    "team": "1610612763",
    "year": "2009",
    "players": [
      "2212",
      "2216",
      "2224",
      "200752",
      "200758",
      "200766",
      "201144",
      "201188",
      "201564",
      "201589",
      "201632",
      "201934",
      "201960",
      "201970",
      "201991"
    ]
  },
  "bce414a0-1435-4744-9faf-b7027085c341": {
    "team": "1610612763",
    "year": "2010",
    "players": [
      "2203",
      "2216",
      "2754",
      "200752",
      "200796",
      "201144",
      "201188",
      "201564",
      "201589",
      "201632",
      "201970",
      "202333",
      "202349",
      "202397"
    ]
  },
  "fdbb84b4-a3cc-4660-a3ba-719af8281289": {
    "team": "1610612763",
    "year": "2011",
    "players": [
      "2216",
      "2240",
      "2754",
      "200752",
      "201144",
      "201188",
      "201564",
      "201578",
      "201589",
      "201632",
      "201967",
      "201991",
      "202347",
      "202729",
      "202951"
    ]
  },
  "323505fb-0be2-4c98-96be-9a14a40d1c78": {
    "team": "1610612763",
    "year": "2012",
    "players": [
      "2039",
      "2216",
      "2419",
      "2754",
      "201144",
      "201188",
      "201573",
      "201589",
      "201590",
      "201948",
      "202334",
      "202347",
      "202720",
      "203100",
      "203186"
    ]
  },
  "2b385c6a-6d38-4a83-8530-3b3d54ade9b3": {
    "team": "1610612763",
    "year": "2013",
    "players": [
      "2034",
      "2216",
      "2419",
      "2754",
      "2757",
      "201144",
      "201188",
      "201584",
      "201585",
      "201949",
      "201979",
      "202334",
      "202347",
      "202720",
      "203479"
    ]
  },
  "04bba9e9-1218-49fc-af8a-f49a0a705f00": {
    "team": "1610612763",
    "year": "2014",
    "players": [
      "1713",
      "2216",
      "2754",
      "2757",
      "201144",
      "201145",
      "201188",
      "201584",
      "201585",
      "201979",
      "202720",
      "203210",
      "203893",
      "203919",
      "203950"
    ]
  },
  "5d5ed08f-254a-4915-8dfd-3bd2d549bb42": {
    "team": "1610612763",
    "year": "2015",
    "players": [
      "1713",
      "2216",
      "2365",
      "2440",
      "2754",
      "200770",
      "201144",
      "201148",
      "201188",
      "202362",
      "203210",
      "203798",
      "203919",
      "203955",
      "204098",
      "1626185"
    ]
  },
  "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d": {
    "team": "1610612763",
    "year": "2016",
    "players": [
      "1713",
      "2216",
      "2754",
      "201144",
      "201148",
      "201188",
      "202718",
      "203210",
      "203516",
      "203584",
      "1626150",
      "1626185",
      "1627735",
      "1627738",
      "1627782"
    ]
  },
  "c5fcad90-ec25-4e4c-9858-41d43a980a21": {
    "team": "1610612763",
    "year": "2017",
    "players": [
      "201144",
      "201188",
      "201596",
      "201936",
      "202705",
      "202718",
      "203104",
      "203210",
      "203463",
      "1626150",
      "1626185",
      "1627362",
      "1627738",
      "1627782",
      "1627988",
      "1628397",
      "1628415",
      "1628424"
    ]
  },
  "60ef2ec8-e872-4112-b28c-8e665c403fba": {
    "team": "1610612763",
    "year": "2018",
    "players": [
      "101139",
      "201144",
      "201149",
      "202340",
      "202685",
      "202718",
      "203092",
      "203200",
      "203937",
      "203998",
      "1626153",
      "1627395",
      "1628397",
      "1628415",
      "1628416",
      "1628975",
      "1628991",
      "1629139"
    ]
  },
  "3a80cffe-adec-482f-ac76-04c11a407dd1": {
    "team": "1610612763",
    "year": "2019",
    "players": [
      "202685",
      "203476",
      "203937",
      "1626145",
      "1626159",
      "1628367",
      "1628415",
      "1628960",
      "1628991",
      "1629001",
      "1629007",
      "1629139",
      "1629630",
      "1629634",
      "1629723",
      "1629741"
    ]
  },
  "e09484d0-0da2-4916-ac91-c96f2c6a1674": {
    "team": "1610612764",
    "year": "1946",
    "players": [
      "77234"
    ]
  },
  "21aca949-94aa-48fb-ace9-f2a8b20b1e77": {
    "team": "1610612764",
    "year": "1947",
    "players": [
      "77011",
      "78023"
    ]
  },
  "2bfdc9d7-2f64-4e8f-a827-0ec752496589": {
    "team": "1610612764",
    "year": "1948",
    "players": [
      "78355"
    ]
  },
  "cf598b10-dc59-4143-a286-b7dfcbbb6989": {
    "team": "1610612764",
    "year": "1949",
    "players": [
      "77452",
      "77713",
      "77778",
      "78092"
    ]
  },
  "2e5c49fe-84b9-40c8-ae1f-a2a502758b96": {
    "team": "1610612764",
    "year": "1950",
    "players": [
      "77753"
    ]
  },
  "e5799d65-732c-4105-b48e-fe6c0e46eee6": {
    "team": "1610612764",
    "year": "1961",
    "players": [
      "76144",
      "76203",
      "76340",
      "76532",
      "76546",
      "76877",
      "77131",
      "77371",
      "77862",
      "78064",
      "78382",
      "78391",
      "78438"
    ]
  },
  "f32d2ba2-4f9f-404a-9cdd-43307e95a7df": {
    "team": "1610612764",
    "year": "1962",
    "players": [
      "76144",
      "76464",
      "76577",
      "76708",
      "76877",
      "76946",
      "77272",
      "77371",
      "77457",
      "77531",
      "77700",
      "77737",
      "78064",
      "78170",
      "78244",
      "78489"
    ]
  },
  "fa4aff89-3225-4265-8365-6d1dcb219e6b": {
    "team": "1610612764",
    "year": "1963",
    "players": [
      "76144",
      "76324",
      "76427",
      "76577",
      "76877",
      "76946",
      "77038",
      "77150",
      "77295",
      "77411",
      "77842",
      "78142",
      "78270",
      "78335"
    ]
  },
  "b204df04-9a5e-48f7-814f-b547b0690ab8": {
    "team": "1610612764",
    "year": "1964",
    "players": [
      "76144",
      "76227",
      "76319",
      "76712",
      "76877",
      "76946",
      "77026",
      "77070",
      "77084",
      "77150",
      "77198",
      "77411",
      "77751"
    ]
  },
  "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc": {
    "team": "1610612764",
    "year": "1965",
    "players": [
      "76227",
      "76712",
      "77023",
      "77070",
      "77150",
      "77248",
      "77411",
      "77566",
      "77751",
      "78173",
      "78219"
    ]
  },
  "1afcc38d-6d62-4ced-a2db-41985c45adb4": {
    "team": "1610612764",
    "year": "1966",
    "players": [
      "76073",
      "76108",
      "76458",
      "76647",
      "76663",
      "76712",
      "76867",
      "77150",
      "77411",
      "77463",
      "77751",
      "78100",
      "78458"
    ]
  },
  "67287c3d-6ead-43c1-8eff-47519e8bbeea": {
    "team": "1610612764",
    "year": "1967",
    "players": [
      "76647",
      "76663",
      "76712",
      "77150",
      "77411",
      "77454",
      "77463",
      "77543",
      "77751",
      "78100",
      "78499",
      "78617",
      "600006"
    ]
  },
  "ddf45ab5-dc0d-42fc-8207-30146fcaf938": {
    "team": "1610612764",
    "year": "1968",
    "players": [
      "76108",
      "76663",
      "76712",
      "77150",
      "77411",
      "77454",
      "77463",
      "77768",
      "77892",
      "78100",
      "78392",
      "78617",
      "600006"
    ]
  },
  "0403c301-445c-4c5d-8144-e5805bdc801c": {
    "team": "1610612764",
    "year": "1969",
    "players": [
      "76356",
      "76531",
      "76663",
      "76984",
      "77150",
      "77411",
      "77454",
      "77463",
      "78100",
      "78392",
      "600006"
    ]
  },
  "ab91fee8-d6df-4549-ad42-04456017afc3": {
    "team": "1610612764",
    "year": "1970",
    "players": [
      "76105",
      "76356",
      "77147",
      "77150",
      "77411",
      "77463",
      "77596",
      "78253",
      "78372",
      "78392",
      "78641",
      "600006"
    ]
  },
  "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3": {
    "team": "1610612764",
    "year": "1971",
    "players": [
      "76386",
      "76603",
      "77150",
      "77413",
      "77463",
      "77676",
      "77965",
      "78392",
      "78641",
      "78651",
      "600006"
    ]
  },
  "24d3a3e9-4c00-40db-8b8b-1169b6c6a889": {
    "team": "1610612764",
    "year": "1972",
    "players": [
      "76386",
      "76397",
      "76531",
      "76603",
      "76979",
      "77413",
      "77813",
      "77876",
      "77965",
      "77966",
      "78238",
      "78392",
      "78651"
    ]
  },
  "024d58a0-dd09-4396-a891-82ba087f7279": {
    "team": "1610612764",
    "year": "1973",
    "players": [
      "76386",
      "76397",
      "76979",
      "77305",
      "77351",
      "77701",
      "77813",
      "77876",
      "77965",
      "77966",
      "78238",
      "78392",
      "78475",
      "78495"
    ]
  },
  "852a9389-6b8e-49c6-a029-8397ecfa81d7": {
    "team": "1610612764",
    "year": "1974",
    "players": [
      "76386",
      "76623",
      "76807",
      "76965",
      "76979",
      "77186",
      "77305",
      "77876",
      "77966",
      "77993",
      "78392",
      "78470",
      "78475"
    ]
  },
  "fd666397-8775-4cb6-9c9b-67881c31f005": {
    "team": "1610612764",
    "year": "1975",
    "players": [
      "76166",
      "76386",
      "76887",
      "76965",
      "76979",
      "77186",
      "77305",
      "77314",
      "77966",
      "77993",
      "78392",
      "78475"
    ]
  },
  "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66": {
    "team": "1610612764",
    "year": "1976",
    "players": [
      "76166",
      "76386",
      "76887",
      "76979",
      "77186",
      "77324",
      "77788",
      "77966",
      "78392",
      "78485",
      "78623"
    ]
  },
  "dd527857-adb8-40c3-9804-8aa4212b8486": {
    "team": "1610612764",
    "year": "1977",
    "players": [
      "76091",
      "76386",
      "76500",
      "76887",
      "76979",
      "76996",
      "77324",
      "77788",
      "78392",
      "78441",
      "78623"
    ]
  },
  "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0": {
    "team": "1610612764",
    "year": "1978",
    "players": [
      "76091",
      "76386",
      "76453",
      "76500",
      "76887",
      "76979",
      "76996",
      "77133",
      "77324",
      "77848",
      "78392",
      "78623"
    ]
  },
  "324480c3-ba0a-4b35-8ae3-aeccec46e409": {
    "team": "1610612764",
    "year": "1979",
    "players": [
      "76083",
      "76091",
      "76140",
      "76212",
      "76453",
      "76500",
      "76887",
      "76979",
      "77324",
      "77876",
      "78392",
      "78623"
    ]
  },
  "a52d7bc3-ed81-4836-b70b-85c958af2548": {
    "team": "1610612764",
    "year": "1980",
    "players": [
      "328",
      "76091",
      "76261",
      "76279",
      "76348",
      "76418",
      "76500",
      "76887",
      "76979",
      "77324",
      "77504",
      "77515",
      "77876",
      "77975",
      "78307",
      "78392",
      "78575"
    ]
  },
  "f0072d27-be45-4cee-b65f-0d3b87790dcb": {
    "team": "1610612764",
    "year": "1981",
    "players": [
      "328",
      "76091",
      "76392",
      "76418",
      "76518",
      "76887",
      "76981",
      "77040",
      "77146",
      "77419",
      "78041",
      "78307",
      "78605"
    ]
  },
  "2d7fe7d6-364f-45d0-bc74-80d3d484ad95": {
    "team": "1610612764",
    "year": "1982",
    "players": [
      "328",
      "76091",
      "76124",
      "76418",
      "76463",
      "76518",
      "76887",
      "76981",
      "77146",
      "77300",
      "77389",
      "77419",
      "77876",
      "78041",
      "78215",
      "78307",
      "78462"
    ]
  },
  "3dbbb282-12b5-47b4-bf1f-c0597f04b407": {
    "team": "1610612764",
    "year": "1983",
    "players": [
      "117",
      "328",
      "76091",
      "76518",
      "76543",
      "76810",
      "77146",
      "77300",
      "77553",
      "78041",
      "78068",
      "78215",
      "78462",
      "78581"
    ]
  },
  "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228": {
    "team": "1610612764",
    "year": "1984",
    "players": [
      "117",
      "279",
      "328",
      "76091",
      "76231",
      "76418",
      "76543",
      "77146",
      "77553",
      "77986",
      "78041",
      "78116",
      "78549",
      "78550"
    ]
  },
  "355ade3f-1412-4279-a7f6-512d2936bf86": {
    "team": "1610612764",
    "year": "1985",
    "players": [
      "117",
      "279",
      "76195",
      "76231",
      "76543",
      "76869",
      "76884",
      "77146",
      "77148",
      "77541",
      "77553",
      "77654",
      "77986",
      "78025",
      "78041",
      "78548",
      "78549"
    ]
  },
  "4567ce1a-2538-43ab-b37e-44c2913ab119": {
    "team": "1610612764",
    "year": "1986",
    "players": [
      "117",
      "149",
      "279",
      "767",
      "941",
      "76195",
      "76435",
      "77146",
      "77449",
      "77670",
      "77754",
      "78025",
      "78418",
      "78554"
    ]
  },
  "f7c8f98e-7d46-478b-b8da-4b66953a08a3": {
    "team": "1610612764",
    "year": "1987",
    "players": [
      "117",
      "177",
      "279",
      "306",
      "767",
      "76019",
      "76195",
      "77146",
      "77264",
      "77449",
      "77670",
      "78437",
      "78554"
    ]
  },
  "c45ea96e-2a6c-4f31-ae56-29735314ea58": {
    "team": "1610612764",
    "year": "1988",
    "players": [
      "117",
      "265",
      "279",
      "289",
      "306",
      "767",
      "76019",
      "76703",
      "77178",
      "77264",
      "78437",
      "78554"
    ]
  },
  "44e38364-f6e8-4d07-bb01-e7f82356367c": {
    "team": "1610612764",
    "year": "1989",
    "players": [
      "67",
      "117",
      "265",
      "279",
      "289",
      "306",
      "76019",
      "77060",
      "77264",
      "78021",
      "78386",
      "78437",
      "78554"
    ]
  },
  "72f5f604-1980-4a2a-96e2-0bf7912e17c6": {
    "team": "1610612764",
    "year": "1990",
    "players": [
      "67",
      "265",
      "279",
      "289",
      "323",
      "442",
      "906",
      "1683",
      "76019",
      "76672",
      "77096",
      "77264",
      "78181",
      "78437",
      "78554"
    ]
  },
  "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1": {
    "team": "1610612764",
    "year": "1991",
    "players": [
      "67",
      "149",
      "265",
      "279",
      "289",
      "323",
      "364",
      "400",
      "442",
      "766",
      "1125",
      "76672",
      "77263",
      "78055",
      "78194",
      "78377"
    ]
  },
  "8e3fc24c-bb3d-42b0-9312-939b187e5f05": {
    "team": "1610612764",
    "year": "1992",
    "players": [
      "77",
      "85",
      "149",
      "265",
      "279",
      "339",
      "364",
      "442",
      "931",
      "1125",
      "1628",
      "1683",
      "76009",
      "76316",
      "76450",
      "77096",
      "77130",
      "78194"
    ]
  },
  "4dba6c84-3a6a-46de-b210-f408a2b2efb7": {
    "team": "1610612764",
    "year": "1993",
    "players": [
      "49",
      "55",
      "77",
      "85",
      "149",
      "339",
      "348",
      "364",
      "384",
      "404",
      "442",
      "931",
      "1125",
      "1845",
      "76046",
      "77057",
      "77789",
      "78440"
    ]
  },
  "fa3c62ff-1a19-4597-a827-0005f973f6aa": {
    "team": "1610612764",
    "year": "1994",
    "players": [
      "29",
      "49",
      "77",
      "101",
      "141",
      "185",
      "348",
      "364",
      "384",
      "404",
      "436",
      "931",
      "1125",
      "1680",
      "78440"
    ]
  },
  "5e793d50-83f9-46e0-8a72-4097b1de6b69": {
    "team": "1610612764",
    "year": "1995",
    "players": [
      "29",
      "43",
      "49",
      "85",
      "100",
      "123",
      "185",
      "289",
      "340",
      "348",
      "384",
      "436",
      "736",
      "737",
      "738",
      "739",
      "899",
      "1067"
    ]
  },
  "18aabc1b-08aa-48fe-8323-6d9ca97b248f": {
    "team": "1610612764",
    "year": "1996",
    "players": [
      "31",
      "43",
      "49",
      "100",
      "145",
      "185",
      "265",
      "384",
      "393",
      "436",
      "1112",
      "1114"
    ]
  },
  "3a362135-e048-4453-af47-87555fdc1775": {
    "team": "1610612764",
    "year": "1997",
    "players": [
      "43",
      "145",
      "185",
      "265",
      "289",
      "384",
      "393",
      "426",
      "436",
      "1032",
      "1112",
      "1539"
    ]
  },
  "1bf4dfed-a893-4196-b0d4-e40d589ac7cf": {
    "team": "1610612764",
    "year": "1998",
    "players": [
      "43",
      "100",
      "145",
      "384",
      "393",
      "715",
      "782",
      "901",
      "976",
      "1112",
      "1751",
      "1872",
      "77108"
    ]
  },
  "0b7a4d32-1992-4984-b46d-503707641e87": {
    "team": "1610612764",
    "year": "1999",
    "players": [
      "43",
      "145",
      "436",
      "690",
      "782",
      "1134",
      "1425",
      "1562",
      "1751",
      "1888",
      "1916",
      "1919"
    ]
  },
  "7ed5ef3c-5de1-4273-bf55-8c8d75804565": {
    "team": "1610612764",
    "year": "2000",
    "players": [
      "63",
      "93",
      "363",
      "461",
      "919",
      "1751",
      "1838",
      "1888",
      "1919",
      "2042",
      "2065",
      "2128"
    ]
  },
  "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f": {
    "team": "1610612764",
    "year": "2001",
    "players": [
      "43",
      "363",
      "461",
      "1731",
      "1751",
      "1838",
      "1888",
      "2041",
      "2042",
      "2198",
      "2217",
      "2250"
    ]
  },
  "f9e28ec1-e1a5-4d53-9a41-e95fe478c646": {
    "team": "1610612764",
    "year": "2002",
    "players": [
      "43",
      "363",
      "711",
      "893",
      "935",
      "1024",
      "1716",
      "1731",
      "1751",
      "2041",
      "2073",
      "2198",
      "2217",
      "2237",
      "2250",
      "2407",
      "2413"
    ]
  },
  "559e5d80-d64b-4c62-9122-9669d7a168dd": {
    "team": "1610612764",
    "year": "2003",
    "players": [
      "43",
      "348",
      "363",
      "711",
      "1716",
      "2041",
      "2198",
      "2217",
      "2240",
      "2407",
      "2413",
      "2437",
      "2553",
      "2581"
    ]
  },
  "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12": {
    "team": "1610612764",
    "year": "2004",
    "players": [
      "324",
      "1712",
      "1716",
      "1913",
      "1919",
      "2041",
      "2198",
      "2217",
      "2240",
      "2407",
      "2413",
      "2581"
    ]
  },
  "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2": {
    "team": "1610612764",
    "year": "2005",
    "players": [
      "1498",
      "1712",
      "1913",
      "1916",
      "2041",
      "2217",
      "2240",
      "2406",
      "2407",
      "2852",
      "2873",
      "101154",
      "101182"
    ]
  },
  "9ba5d620-d5f8-43d2-b943-459e066d28fe": {
    "team": "1610612764",
    "year": "2006",
    "players": [
      "1498",
      "1712",
      "1913",
      "1916",
      "2041",
      "2052",
      "2217",
      "2240",
      "2406",
      "2427",
      "2443",
      "2553",
      "101154",
      "101182",
      "200837"
    ]
  },
  "0b186d86-fd9e-42c1-b360-819024c5089c": {
    "team": "1610612764",
    "year": "2007",
    "players": [
      "1498",
      "1712",
      "2041",
      "2052",
      "2217",
      "2240",
      "2406",
      "2427",
      "2443",
      "101154",
      "200762",
      "201156",
      "201187"
    ]
  },
  "8b74a71f-9020-45e3-bbba-a5a962da11de": {
    "team": "1610612764",
    "year": "2008",
    "players": [
      "1712",
      "2041",
      "2052",
      "2217",
      "2229",
      "2240",
      "2406",
      "2413",
      "2443",
      "101154",
      "200762",
      "201156",
      "201159",
      "201187",
      "201580"
    ]
  },
  "811b428c-0d87-46f4-9c9a-208cb1e9e38b": {
    "team": "1610612764",
    "year": "2009",
    "players": [
      "1863",
      "2034",
      "2240",
      "2572",
      "2624",
      "2733",
      "101154",
      "101177",
      "101189",
      "200751",
      "201154",
      "201156",
      "201159",
      "201580",
      "201858",
      "202132"
    ]
  },
  "4aecdd98-f356-4a06-93a4-7dee3adb6741": {
    "team": "1610612764",
    "year": "2010",
    "players": [
      "1740",
      "2230",
      "2572",
      "101154",
      "201146",
      "201156",
      "201203",
      "201580",
      "201785",
      "202082",
      "202322",
      "202338",
      "202344",
      "202348",
      "202380"
    ]
  },
  "0cca6630-b178-49fe-a6b3-37fee4aa7b7c": {
    "team": "1610612764",
    "year": "2011",
    "players": [
      "1740",
      "2230",
      "2403",
      "2567",
      "101154",
      "101189",
      "201165",
      "201858",
      "202322",
      "202338",
      "202344",
      "202348",
      "202686",
      "202698",
      "202714"
    ]
  },
  "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8": {
    "team": "1610612764",
    "year": "2012",
    "players": [
      "2215",
      "2403",
      "2571",
      "2731",
      "2772",
      "101110",
      "201858",
      "201985",
      "202066",
      "202322",
      "202338",
      "202344",
      "202686",
      "202698",
      "203078"
    ]
  },
  "2a6a6206-a7c4-40af-9928-fc2c809ce17a": {
    "team": "1610612764",
    "year": "2013",
    "players": [
      "1733",
      "1889",
      "2400",
      "2403",
      "2772",
      "101110",
      "101162",
      "202066",
      "202322",
      "202338",
      "202344",
      "202698",
      "203078",
      "203318",
      "203490"
    ]
  },
  "8d3a3080-9e74-4512-8869-83aad854d0a0": {
    "team": "1610612764",
    "year": "2014",
    "players": [
      "1718",
      "2400",
      "2403",
      "2446",
      "2743",
      "101110",
      "101162",
      "101198",
      "201196",
      "201971",
      "202066",
      "202322",
      "202338",
      "203078",
      "203490"
    ]
  },
  "d5add497-b2c3-4523-b2ef-584c47479c7d": {
    "team": "1610612764",
    "year": "2015",
    "players": [
      "2400",
      "2403",
      "101162",
      "101187",
      "201162",
      "201196",
      "201581",
      "201977",
      "202066",
      "202322",
      "202693",
      "203078",
      "203490",
      "204067",
      "1626162"
    ]
  },
  "be0a6795-9a40-4756-9ab4-11a5554c68a6": {
    "team": "1610612764",
    "year": "2016",
    "players": [
      "101133",
      "101162",
      "201160",
      "201943",
      "202322",
      "202693",
      "202711",
      "203078",
      "203107",
      "203490",
      "203504",
      "1626162",
      "1626191",
      "1627815",
      "1627849"
    ]
  },
  "32483b7a-3a83-49cb-9899-b81ebb1c12ad": {
    "team": "1610612764",
    "year": "2017",
    "players": [
      "101133",
      "101162",
      "201160",
      "201196",
      "201951",
      "201975",
      "202322",
      "202693",
      "203078",
      "203107",
      "203118",
      "203490",
      "204025",
      "1626162",
      "1626191",
      "1628421"
    ]
  },
  "6631c074-5c99-48db-b77d-c92e855be5bd": {
    "team": "1610612764",
    "year": "2018",
    "players": [
      "2730",
      "2772",
      "101133",
      "201145",
      "202322",
      "202325",
      "203078",
      "203107",
      "203895",
      "203953",
      "1626155",
      "1626171",
      "1626184",
      "1628418",
      "1628421",
      "1628972",
      "1629341"
    ]
  },
  "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919": {
    "team": "1610612764",
    "year": "2019",
    "players": [
      "101133",
      "202322",
      "202397",
      "202722",
      "203078",
      "203894",
      "1627780",
      "1628394",
      "1628418",
      "1628972",
      "1629010",
      "1629021",
      "1629060",
      "1629067",
      "1629140",
      "1629678",
      "1629726"
    ]
  },
  "a94975cb-3f3a-4d21-b65d-a170a7dca922": {
    "team": "1610612765",
    "year": "1948",
    "players": [
      "76060",
      "76173",
      "76473",
      "76920",
      "77004",
      "77283",
      "77443",
      "77717",
      "77828",
      "78177",
      "78360",
      "78368",
      "78573"
    ]
  },
  "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e": {
    "team": "1610612765",
    "year": "1949",
    "players": [
      "76060",
      "76346",
      "76959",
      "77004",
      "77283",
      "77683",
      "77758",
      "78075",
      "78427"
    ]
  },
  "648376a8-6ee8-4cad-97d2-369540020f50": {
    "team": "1610612765",
    "year": "1950",
    "players": [
      "76060",
      "76311",
      "76744",
      "77139",
      "77249",
      "77285",
      "77572",
      "77675",
      "77758",
      "77778",
      "77959",
      "78075"
    ]
  },
  "02a3a04b-002a-4afa-8188-8f11ccfafda1": {
    "team": "1610612765",
    "year": "1951",
    "players": [
      "76250",
      "76406",
      "76636",
      "76705",
      "76744",
      "77139",
      "77249",
      "77256",
      "78075",
      "78125",
      "78163"
    ]
  },
  "e527a536-aa8c-43ac-8b79-dd0230b9780e": {
    "team": "1610612765",
    "year": "1952",
    "players": [
      "76214",
      "76250",
      "76452",
      "76636",
      "76705",
      "76744",
      "76894",
      "77139",
      "77256",
      "77573",
      "77853",
      "78075",
      "78094",
      "78125"
    ]
  },
  "57494c03-afc0-41de-b544-659990d5e952": {
    "team": "1610612765",
    "year": "1953",
    "players": [
      "76110",
      "76250",
      "76744",
      "77089",
      "77573",
      "77624",
      "77675",
      "77853",
      "78094",
      "78163",
      "78628",
      "78638"
    ]
  },
  "3d186bdd-f80f-49e5-b6bb-1d08328eea91": {
    "team": "1610612765",
    "year": "1954",
    "players": [
      "76250",
      "76744",
      "76760",
      "77062",
      "77089",
      "77573",
      "77853",
      "78010",
      "78020",
      "78447",
      "78628",
      "78638"
    ]
  },
  "0a699404-02aa-4953-8be2-08a5fc726ea3": {
    "team": "1610612765",
    "year": "1955",
    "players": [
      "76062",
      "76164",
      "76250",
      "76442",
      "76561",
      "76744",
      "77045",
      "77062",
      "77089",
      "77722",
      "77853",
      "78225",
      "78628",
      "78638"
    ]
  },
  "edb95a5d-c995-43f0-9378-d27051d259ab": {
    "team": "1610612765",
    "year": "1956",
    "players": [
      "76561",
      "76744",
      "77062",
      "77089",
      "77247",
      "77722",
      "77999",
      "78020",
      "78142",
      "78312",
      "78628"
    ]
  },
  "08ee244c-3cfb-449d-8fe6-85e1d66dccd3": {
    "team": "1610612765",
    "year": "1957",
    "players": [
      "76069",
      "76197",
      "76404",
      "76611",
      "76633",
      "76773",
      "77049",
      "77062",
      "77210",
      "77247",
      "77537",
      "77722",
      "78142",
      "78312",
      "78628"
    ]
  },
  "03f980b2-d8d0-47cc-a777-71a16e029764": {
    "team": "1610612765",
    "year": "1958",
    "players": [
      "76324",
      "76611",
      "76693",
      "77049",
      "77210",
      "77394",
      "77537",
      "77555",
      "77722",
      "78142",
      "78628"
    ]
  },
  "29b33649-2e3d-444b-81b4-caec18163bdd": {
    "team": "1610612765",
    "year": "1959",
    "players": [
      "76020",
      "76429",
      "76546",
      "76611",
      "77070",
      "77247",
      "77394",
      "77537",
      "77555",
      "77722",
      "78142",
      "78590"
    ]
  },
  "a4934f64-d807-440b-9639-6e4052c2501e": {
    "team": "1610612765",
    "year": "1960",
    "players": [
      "76546",
      "76611",
      "76712",
      "77070",
      "77202",
      "77359",
      "77555",
      "77639",
      "77722",
      "77751",
      "78142"
    ]
  },
  "e6ca1168-2bff-4198-9727-665654e83794": {
    "team": "1610612765",
    "year": "1961",
    "players": [
      "76611",
      "76647",
      "76712",
      "77070",
      "77202",
      "77359",
      "77639",
      "77722",
      "77751",
      "78100",
      "78142"
    ]
  },
  "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0": {
    "team": "1610612765",
    "year": "1962",
    "players": [
      "76545",
      "76595",
      "76611",
      "76647",
      "76712",
      "77070",
      "77093",
      "77202",
      "77411",
      "77639",
      "77751",
      "78100"
    ]
  },
  "e389ff50-8e08-469e-aba4-9d1231b91c1a": {
    "team": "1610612765",
    "year": "1963",
    "players": [
      "76318",
      "76545",
      "76610",
      "76712",
      "76945",
      "77070",
      "77093",
      "77202",
      "77596",
      "77639",
      "77751",
      "78100"
    ]
  },
  "3902314e-3111-45fa-9433-86d9fdae07db": {
    "team": "1610612765",
    "year": "1964",
    "players": [
      "76318",
      "76329",
      "76545",
      "76577",
      "76610",
      "76945",
      "77202",
      "77295",
      "77596",
      "77639",
      "77927",
      "78100",
      "78335"
    ]
  },
  "61110628-8f7d-478f-b171-52455d3bf9d0": {
    "team": "1610612765",
    "year": "1965",
    "players": [
      "76108",
      "76302",
      "76318",
      "76329",
      "76545",
      "77295",
      "77596",
      "77928",
      "78100",
      "78268",
      "78335",
      "78399",
      "78409",
      "78459",
      "78651"
    ]
  },
  "65f693c5-b99c-4553-b1db-458345ed8c34": {
    "team": "1610612765",
    "year": "1966",
    "players": [
      "76166",
      "76545",
      "76945",
      "77023",
      "77037",
      "77596",
      "77676",
      "77928",
      "78268",
      "78399",
      "78409",
      "78651"
    ]
  },
  "e7ef6b6a-a34b-48bc-95f7-9636abc598f5": {
    "team": "1610612765",
    "year": "1967",
    "players": [
      "76166",
      "76357",
      "76545",
      "76577",
      "76592",
      "77406",
      "77596",
      "77811",
      "78268",
      "78439"
    ]
  },
  "49db955c-2a3f-4693-b68f-834ab5003c4d": {
    "team": "1610612765",
    "year": "1968",
    "players": [
      "76144",
      "76166",
      "76545",
      "76577",
      "76592",
      "76774",
      "76916",
      "77297",
      "77548",
      "77596",
      "77636",
      "77765",
      "78439",
      "78543"
    ]
  },
  "d80f75e6-baf7-4843-8122-aa743aea3477": {
    "team": "1610612765",
    "year": "1969",
    "players": [
      "76144",
      "76166",
      "76577",
      "76916",
      "77013",
      "77297",
      "77406",
      "77548",
      "77596",
      "77618",
      "77636",
      "77658",
      "77892",
      "77945",
      "78439",
      "78617"
    ]
  },
  "3e022c30-7d7f-4ede-91a9-ccf9727e34c9": {
    "team": "1610612765",
    "year": "1970",
    "players": [
      "76166",
      "76577",
      "76603",
      "77013",
      "77297",
      "77464",
      "77618",
      "77636",
      "77658",
      "77892",
      "78439",
      "600005"
    ]
  },
  "652a2a9f-cb88-4141-a32c-1b9b3d44a226": {
    "team": "1610612765",
    "year": "1971",
    "players": [
      "76166",
      "76525",
      "76577",
      "77013",
      "77297",
      "77464",
      "77618",
      "77658",
      "77733",
      "77892",
      "78028",
      "78439",
      "78580",
      "600005"
    ]
  },
  "5d519571-b461-4e3b-963a-2173662c4b55": {
    "team": "1610612765",
    "year": "1972",
    "players": [
      "76012",
      "76166",
      "76525",
      "76733",
      "76740",
      "77338",
      "77464",
      "77580",
      "77658",
      "77688",
      "77733",
      "78028",
      "78313",
      "600005"
    ]
  },
  "1f0e40f0-6d7d-4ef3-a95e-f86255508185": {
    "team": "1610612765",
    "year": "1973",
    "players": [
      "76012",
      "76166",
      "76525",
      "76733",
      "77240",
      "77338",
      "77580",
      "77688",
      "77733",
      "78028",
      "78366",
      "600005"
    ]
  },
  "94f36f95-2ce4-4f55-8a83-19b910b11438": {
    "team": "1610612765",
    "year": "1974",
    "players": [
      "76012",
      "76166",
      "76525",
      "76634",
      "76733",
      "77388",
      "77580",
      "77627",
      "77733",
      "78028",
      "78366",
      "600005"
    ]
  },
  "e8407b6f-093b-43a2-ac16-f610ffc2510b": {
    "team": "1610612765",
    "year": "1975",
    "players": [
      "76286",
      "76397",
      "76563",
      "76634",
      "76733",
      "76917",
      "77198",
      "77580",
      "77627",
      "77875",
      "77876",
      "78028",
      "78322",
      "78366",
      "78546",
      "600005"
    ]
  },
  "553ffb4a-c13e-4629-9598-f1f64182ce7f": {
    "team": "1610612765",
    "year": "1976",
    "players": [
      "76104",
      "76286",
      "76351",
      "76364",
      "76590",
      "76634",
      "76733",
      "77627",
      "77875",
      "77876",
      "78111",
      "78157",
      "78366",
      "600005"
    ]
  },
  "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3": {
    "team": "1610612765",
    "year": "1977",
    "players": [
      "76211",
      "76259",
      "76351",
      "76590",
      "76634",
      "76733",
      "76802",
      "77627",
      "77733",
      "77873",
      "77883",
      "78143",
      "78157",
      "78167",
      "600005"
    ]
  },
  "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689": {
    "team": "1610612765",
    "year": "1978",
    "players": [
      "1381",
      "76221",
      "76248",
      "76351",
      "76590",
      "76802",
      "76874",
      "76975",
      "77044",
      "77069",
      "77561",
      "77873",
      "77876",
      "78135",
      "78294",
      "78390",
      "78431",
      "600005"
    ]
  },
  "46b6d84f-cfa5-4bec-b169-b870c218e89b": {
    "team": "1610612765",
    "year": "1979",
    "players": [
      "1381",
      "76154",
      "76590",
      "76608",
      "76685",
      "76928",
      "77071",
      "77239",
      "77364",
      "77450",
      "77498",
      "77528",
      "77627",
      "77989",
      "78390",
      "600005"
    ]
  },
  "c163d65f-d6fd-4a44-968a-2897543255d4": {
    "team": "1610612765",
    "year": "1980",
    "players": [
      "1381",
      "76154",
      "76174",
      "76599",
      "76765",
      "77010",
      "77071",
      "77158",
      "77239",
      "77348",
      "77364",
      "77623",
      "77996",
      "78390",
      "78623"
    ]
  },
  "b49a0527-aa53-424d-aeb3-bc7f3d439938": {
    "team": "1610612765",
    "year": "1981",
    "players": [
      "1381",
      "76154",
      "76350",
      "76913",
      "76947",
      "76980",
      "77167",
      "77184",
      "77215",
      "77239",
      "77364",
      "78318",
      "78369",
      "78390",
      "78623",
      "100263"
    ]
  },
  "f7a58b16-8257-42be-b944-16319d0abd63": {
    "team": "1610612765",
    "year": "1982",
    "players": [
      "894",
      "1381",
      "76154",
      "77167",
      "77171",
      "77377",
      "77490",
      "77786",
      "78048",
      "78191",
      "78318",
      "78348",
      "78369",
      "78390",
      "78533",
      "78647",
      "100263"
    ]
  },
  "1a9e125c-e465-42e3-a800-c69234389252": {
    "team": "1610612765",
    "year": "1983",
    "players": [
      "940",
      "1381",
      "76074",
      "76154",
      "77043",
      "77167",
      "77377",
      "78048",
      "78314",
      "78318",
      "78348",
      "78369",
      "78390",
      "100263"
    ]
  },
  "be51cb27-403f-47e3-bf9c-8331e9c4ce17": {
    "team": "1610612765",
    "year": "1984",
    "players": [
      "940",
      "1381",
      "76154",
      "76338",
      "77167",
      "77189",
      "78015",
      "78025",
      "78250",
      "78318",
      "78369",
      "78390",
      "100263"
    ]
  },
  "b074de7d-b368-48a0-aa16-3542d57bfc7b": {
    "team": "1610612765",
    "year": "1985",
    "players": [
      "247",
      "328",
      "940",
      "1381",
      "76154",
      "76338",
      "76469",
      "76810",
      "77167",
      "77707",
      "78048",
      "78318",
      "78369",
      "100263"
    ]
  },
  "999df20d-d256-45ec-8e64-111bdc7471d7": {
    "team": "1610612765",
    "year": "1986",
    "players": [
      "23",
      "247",
      "328",
      "927",
      "76338",
      "76504",
      "76876",
      "77167",
      "77565",
      "77707",
      "77719",
      "78093",
      "78299",
      "78318",
      "100263"
    ]
  },
  "60bcac51-ec80-4f70-b3eb-9d73254ac5c8": {
    "team": "1610612765",
    "year": "1987",
    "players": [
      "23",
      "229",
      "247",
      "328",
      "927",
      "76138",
      "76504",
      "76539",
      "77167",
      "77383",
      "77707",
      "78048",
      "78318",
      "100263"
    ]
  },
  "b8efa707-8ce1-4e0b-922d-9d113ca8a294": {
    "team": "1610612765",
    "year": "1988",
    "players": [
      "23",
      "52",
      "229",
      "247",
      "328",
      "927",
      "1381",
      "76016",
      "76539",
      "76552",
      "76960",
      "77167",
      "78318",
      "100263"
    ]
  },
  "ffc81b35-207d-48f8-8091-52733fd65961": {
    "team": "1610612765",
    "year": "1989",
    "players": [
      "23",
      "229",
      "247",
      "927",
      "76016",
      "76138",
      "76881",
      "76968",
      "76993",
      "77167",
      "77261",
      "78318",
      "100263"
    ]
  },
  "73a256d9-f328-424c-a259-734aa5e88c4c": {
    "team": "1610612765",
    "year": "1990",
    "players": [
      "23",
      "229",
      "247",
      "927",
      "1381",
      "76016",
      "76138",
      "76183",
      "76968",
      "76993",
      "77167",
      "78014",
      "78318",
      "100263"
    ]
  },
  "db85dc84-6de1-4038-8c49-8f636dd20715": {
    "team": "1610612765",
    "year": "1991",
    "players": [
      "23",
      "247",
      "737",
      "927",
      "76016",
      "76138",
      "76183",
      "76993",
      "78110",
      "78316",
      "78318",
      "78437",
      "78615",
      "100263"
    ]
  },
  "36c469a4-c0e6-4546-91c7-83d0d3151d8f": {
    "team": "1610612765",
    "year": "1992",
    "players": [
      "23",
      "178",
      "247",
      "371",
      "926",
      "1116",
      "1821",
      "76016",
      "76821",
      "77647",
      "78041",
      "78318",
      "78633",
      "100263"
    ]
  },
  "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3": {
    "team": "1610612765",
    "year": "1993",
    "players": [
      "116",
      "246",
      "247",
      "251",
      "275",
      "279",
      "283",
      "371",
      "432",
      "929",
      "1855",
      "76412",
      "77386",
      "77776",
      "78318",
      "100263"
    ]
  },
  "7086572d-ca1e-44e5-86cd-1b8426bc9934": {
    "team": "1610612765",
    "year": "1994",
    "players": [
      "46",
      "223",
      "247",
      "255",
      "275",
      "283",
      "287",
      "371",
      "770",
      "912",
      "932",
      "1008",
      "1096",
      "1855",
      "1861"
    ]
  },
  "0c8c4333-8ea1-4a4c-8375-46dfd48391ff": {
    "team": "1610612765",
    "year": "1995",
    "players": [
      "247",
      "255",
      "275",
      "283",
      "287",
      "371",
      "688",
      "689",
      "690",
      "691",
      "770",
      "901",
      "1855",
      "76097"
    ]
  },
  "f03a4206-d842-4a99-8713-66ae264320ff": {
    "team": "1610612765",
    "year": "1996",
    "players": [
      "3",
      "243",
      "247",
      "255",
      "283",
      "328",
      "371",
      "688",
      "689",
      "690",
      "901",
      "966"
    ]
  },
  "a644392d-ec60-49ae-9bce-bb9584c4d3cc": {
    "team": "1610612765",
    "year": "1997",
    "players": [
      "183",
      "247",
      "255",
      "283",
      "328",
      "690",
      "711",
      "907",
      "966",
      "1513",
      "1525",
      "1667"
    ]
  },
  "09154feb-3670-47a9-a0ed-55f55f2002b1": {
    "team": "1610612765",
    "year": "1998",
    "players": [
      "146",
      "183",
      "247",
      "255",
      "283",
      "363",
      "376",
      "690",
      "711",
      "919",
      "966",
      "1525"
    ]
  },
  "13b195d0-c278-4b5d-85e3-20eeddc3ba7b": {
    "team": "1610612765",
    "year": "1999",
    "players": [
      "146",
      "180",
      "255",
      "283",
      "363",
      "371",
      "376",
      "688",
      "711",
      "919",
      "966",
      "1630"
    ]
  },
  "9359cd35-305f-4f57-847e-21ec13abdc68": {
    "team": "1610612765",
    "year": "2000",
    "players": [
      "146",
      "344",
      "693",
      "711",
      "722",
      "961",
      "1088",
      "1112",
      "1600",
      "1630",
      "2043",
      "2073"
    ]
  },
  "30c53eba-69ab-45fc-9d62-0fa9faa176d1": {
    "team": "1610612765",
    "year": "2001",
    "players": [
      "344",
      "361",
      "468",
      "688",
      "711",
      "722",
      "1088",
      "1112",
      "1442",
      "1800",
      "2206",
      "2349"
    ]
  },
  "6d018b74-602e-4da7-810d-8489c0aab560": {
    "team": "1610612765",
    "year": "2002",
    "players": [
      "93",
      "361",
      "468",
      "688",
      "690",
      "722",
      "1088",
      "1112",
      "1442",
      "1497",
      "1888",
      "2143",
      "2246",
      "2419"
    ]
  },
  "42f3a743-7773-4c2d-a0c7-7b57ee5f580f": {
    "team": "1610612765",
    "year": "2003",
    "players": [
      "283",
      "722",
      "739",
      "922",
      "1032",
      "1112",
      "1497",
      "1762",
      "1888",
      "2229",
      "2246",
      "2419",
      "2545"
    ]
  },
  "15e8507c-358e-47f0-807e-4a609d0a4581": {
    "team": "1610612765",
    "year": "2004",
    "players": [
      "283",
      "686",
      "739",
      "922",
      "1032",
      "1112",
      "1497",
      "1888",
      "2306",
      "2419",
      "2545",
      "2568",
      "2648",
      "2798"
    ]
  },
  "162e7cb4-a05c-426d-bb17-42b80e47aef5": {
    "team": "1610612765",
    "year": "2005",
    "players": [
      "283",
      "686",
      "739",
      "905",
      "960",
      "1112",
      "1497",
      "1509",
      "1888",
      "2230",
      "2419",
      "2568",
      "101131"
    ]
  },
  "532f6daa-fa7a-422a-9326-f0c577396c95": {
    "team": "1610612765",
    "year": "2006",
    "players": [
      "185",
      "283",
      "686",
      "739",
      "905",
      "1497",
      "1737",
      "1888",
      "2419",
      "2436",
      "2648",
      "101131",
      "101161",
      "200807"
    ]
  },
  "a497fd4a-1e3a-470a-a658-dbd552e1ac21": {
    "team": "1610612765",
    "year": "2007",
    "players": [
      "283",
      "686",
      "689",
      "739",
      "1497",
      "1888",
      "2413",
      "2419",
      "2553",
      "101131",
      "101161",
      "200798",
      "200835",
      "201155",
      "201167"
    ]
  },
  "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1": {
    "team": "1610612765",
    "year": "2008",
    "players": [
      "686",
      "739",
      "947",
      "1888",
      "2198",
      "2419",
      "101131",
      "101161",
      "101198",
      "200835",
      "201155",
      "201167",
      "201594"
    ]
  },
  "b791032a-997d-47da-b33e-2d34d9e2cd22": {
    "team": "1610612765",
    "year": "2009",
    "players": [
      "1088",
      "1112",
      "1888",
      "2198",
      "2404",
      "2419",
      "2732",
      "101111",
      "101131",
      "101198",
      "201155",
      "201948",
      "201969",
      "201973"
    ]
  },
  "443b1d56-f27e-4c16-a9ca-855a72cacb44": {
    "team": "1610612765",
    "year": "2010",
    "players": [
      "1112",
      "1503",
      "1888",
      "2404",
      "2419",
      "2732",
      "101111",
      "101131",
      "101198",
      "201155",
      "201948",
      "201969",
      "201973",
      "202328",
      "202358"
    ]
  },
  "11078f24-e0e4-4d1b-89cb-44eb30bed6c1": {
    "team": "1610612765",
    "year": "2011",
    "players": [
      "1112",
      "2419",
      "2732",
      "2863",
      "101111",
      "101131",
      "101198",
      "201041",
      "201155",
      "201948",
      "201973",
      "202328",
      "202688",
      "202731"
    ]
  },
  "28fbed8c-c7c6-480e-ac1d-0cb93972dcac": {
    "team": "1610612765",
    "year": "2012",
    "players": [
      "1894",
      "101111",
      "101131",
      "101181",
      "101198",
      "201155",
      "201973",
      "202328",
      "202688",
      "202713",
      "203083",
      "203114",
      "203119",
      "203139"
    ]
  },
  "e897fcee-4043-4882-b3ac-076b89980fff": {
    "team": "1610612765",
    "year": "2013",
    "players": [
      "1497",
      "2746",
      "101111",
      "101198",
      "201155",
      "201943",
      "201973",
      "202328",
      "202713",
      "202725",
      "203083",
      "203484",
      "203491",
      "203502",
      "203540"
    ]
  },
  "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7": {
    "team": "1610612765",
    "year": "2014",
    "players": [
      "2406",
      "2419",
      "101249",
      "200761",
      "201202",
      "201229",
      "201858",
      "201943",
      "201975",
      "202328",
      "202704",
      "203083",
      "203113",
      "203484",
      "203915"
    ]
  },
  "fbff0712-5fed-4e22-8afa-30387f9f0753": {
    "team": "1610612765",
    "year": "2015",
    "players": [
      "2581",
      "201202",
      "201229",
      "201975",
      "202694",
      "202699",
      "202704",
      "203083",
      "203382",
      "203484",
      "203485",
      "203493",
      "203915",
      "203946",
      "1626169",
      "1626199"
    ]
  },
  "55ce14d3-4bba-46a5-8e11-ea7e903edf7e": {
    "team": "1610612765",
    "year": "2016",
    "players": [
      "2757",
      "202397",
      "202694",
      "202699",
      "202704",
      "202720",
      "203083",
      "203382",
      "203484",
      "203493",
      "1626169",
      "1626199",
      "1626246",
      "1627740",
      "1627771"
    ]
  },
  "45324799-5d41-45eb-b5ff-b3095f173c43": {
    "team": "1610612765",
    "year": "2017",
    "players": [
      "2749",
      "201229",
      "201933",
      "202397",
      "202704",
      "202720",
      "202779",
      "203083",
      "203493",
      "203516",
      "203687",
      "203961",
      "204038",
      "1626169",
      "1627740",
      "1627770",
      "1628379"
    ]
  },
  "17ec0980-7e1f-455e-a186-b7156367018b": {
    "team": "1610612765",
    "year": "2018",
    "players": [
      "2585",
      "101181",
      "201933",
      "201961",
      "202397",
      "202704",
      "202720",
      "203083",
      "203564",
      "203922",
      "204038",
      "1627748",
      "1627785",
      "1628379",
      "1628971",
      "1629004",
      "1629017"
    ]
  },
  "de2d945e-04d3-4b3c-aba1-385a321827ad": {
    "team": "1610612765",
    "year": "2019",
    "players": [
      "201565",
      "201933",
      "202688",
      "203089",
      "203503",
      "203895",
      "204038",
      "1626174",
      "1627748",
      "1628379",
      "1628971",
      "1629004",
      "1629017",
      "1629635",
      "1629648",
      "1629663",
      "1629743"
    ]
  },
  "fff96d27-fc15-439f-b70c-c7f7b6d875a3": {
    "team": "1610612766",
    "year": "1988",
    "players": [
      "177",
      "209",
      "364",
      "940",
      "1118",
      "1123",
      "77047",
      "77053",
      "77383",
      "77415",
      "77905",
      "77936",
      "78031",
      "78347",
      "78369"
    ]
  },
  "440e1824-f688-481f-9c58-4951796e7ad1": {
    "team": "1610612766",
    "year": "1989",
    "players": [
      "52",
      "177",
      "209",
      "288",
      "364",
      "462",
      "705",
      "930",
      "76045",
      "76596",
      "77047",
      "77053",
      "77383",
      "77936",
      "78031",
      "78369",
      "78377"
    ]
  },
  "3007ea37-c35a-47e0-925b-da61b1dbbb1e": {
    "team": "1610612766",
    "year": "1990",
    "players": [
      "88",
      "177",
      "209",
      "271",
      "287",
      "364",
      "383",
      "462",
      "705",
      "930",
      "940",
      "1874",
      "76826",
      "76911",
      "78369"
    ]
  },
  "b4d79b93-f806-4a0d-a90c-18babfcbb170": {
    "team": "1610612766",
    "year": "1991",
    "players": [
      "97",
      "177",
      "209",
      "271",
      "287",
      "383",
      "386",
      "462",
      "913",
      "930",
      "76050",
      "76752",
      "76826",
      "77083",
      "77426"
    ]
  },
  "3e72397e-e399-4a1e-bd06-a9b455547453": {
    "team": "1610612766",
    "year": "1992",
    "players": [
      "177",
      "209",
      "271",
      "297",
      "383",
      "766",
      "913",
      "930",
      "76148",
      "76826",
      "76876",
      "77426"
    ]
  },
  "f7ef57d0-f5b8-4365-8f17-0a840c185f1e": {
    "team": "1610612766",
    "year": "1993",
    "players": [
      "177",
      "197",
      "209",
      "297",
      "698",
      "716",
      "765",
      "766",
      "783",
      "913",
      "930",
      "1034",
      "1667",
      "76148"
    ]
  },
  "18d1c64d-d0ad-45a1-8e81-9e9323769291": {
    "team": "1610612766",
    "year": "1994",
    "players": [
      "149",
      "177",
      "197",
      "209",
      "241",
      "297",
      "305",
      "341",
      "357",
      "765",
      "766",
      "913",
      "930",
      "76148",
      "78347"
    ]
  },
  "9c894a5d-df1a-404c-b058-78d127394ba6": {
    "team": "1610612766",
    "year": "1995",
    "players": [
      "72",
      "149",
      "177",
      "197",
      "209",
      "241",
      "305",
      "678",
      "761",
      "779",
      "912",
      "913",
      "924",
      "939",
      "1133"
    ]
  },
  "e0ac1aae-7503-4e71-9f1d-395edbcd104b": {
    "team": "1610612766",
    "year": "1996",
    "players": [
      "124",
      "140",
      "177",
      "193",
      "209",
      "380",
      "761",
      "779",
      "894",
      "912",
      "960",
      "990"
    ]
  },
  "25f7d175-5991-48e6-8d9b-391b80096be6": {
    "team": "1610612766",
    "year": "1997",
    "players": [
      "124",
      "133",
      "140",
      "184",
      "193",
      "209",
      "462",
      "761",
      "769",
      "779",
      "1133",
      "1576"
    ]
  },
  "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2": {
    "team": "1610612766",
    "year": "1998",
    "players": [
      "133",
      "184",
      "224",
      "359",
      "456",
      "695",
      "922",
      "934",
      "1133",
      "1593",
      "1729",
      "1802"
    ]
  },
  "c060723a-d5e6-4385-bc5e-59560b27d467": {
    "team": "1610612766",
    "year": "1999",
    "players": [
      "133",
      "193",
      "224",
      "359",
      "695",
      "922",
      "934",
      "957",
      "1729",
      "1802",
      "1884",
      "1944"
    ]
  },
  "d72cf956-8b27-48b5-a764-bfb7d694e1bf": {
    "team": "1610612766",
    "year": "2000",
    "players": [
      "133",
      "136",
      "197",
      "469",
      "695",
      "765",
      "901",
      "922",
      "934",
      "1884",
      "1944",
      "2048"
    ]
  },
  "23fd152a-aac1-4f72-97b9-7b02172ba092": {
    "team": "1610612766",
    "year": "2001",
    "players": [
      "133",
      "136",
      "248",
      "278",
      "469",
      "922",
      "1714",
      "1724",
      "1884",
      "1924",
      "2048",
      "2213"
    ]
  },
  "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52": {
    "team": "1610612766",
    "year": "2004",
    "players": [
      "724",
      "1510",
      "2056",
      "2078",
      "2124",
      "2222",
      "2408",
      "2574",
      "2586",
      "2679",
      "2731",
      "2774"
    ]
  },
  "2a8cce15-fec9-4173-8665-82815b3bfb24": {
    "team": "1610612766",
    "year": "2005",
    "players": [
      "1510",
      "1908",
      "2056",
      "2063",
      "2222",
      "2408",
      "2437",
      "2679",
      "2731",
      "2774",
      "101109",
      "101118",
      "101187",
      "101207"
    ]
  },
  "4bae2ffc-919f-42b5-98f5-aaa59862364e": {
    "team": "1610612766",
    "year": "2006",
    "players": [
      "970",
      "976",
      "1507",
      "1510",
      "2056",
      "2063",
      "2222",
      "2679",
      "2731",
      "101109",
      "101118",
      "101187",
      "200747",
      "200797",
      "200835"
    ]
  },
  "a16e37d5-d174-4f55-bfdc-ba838caae024": {
    "team": "1610612766",
    "year": "2007",
    "players": [
      "970",
      "1507",
      "1737",
      "1863",
      "2202",
      "2222",
      "2679",
      "2731",
      "101109",
      "101118",
      "200747",
      "200797",
      "201162",
      "201176"
    ]
  },
  "fc384a91-81b8-47cb-b999-2a497289f651": {
    "team": "1610612766",
    "year": "2008",
    "players": [
      "436",
      "1737",
      "1952",
      "2205",
      "2209",
      "2222",
      "2564",
      "2731",
      "101109",
      "101118",
      "200971",
      "201571",
      "201582",
      "201606",
      "201858"
    ]
  },
  "f93da3e3-c6f0-4b6a-a43c-73573c1aa575": {
    "team": "1610612766",
    "year": "2009",
    "players": [
      "689",
      "1536",
      "1716",
      "1737",
      "2199",
      "2205",
      "2222",
      "2564",
      "101109",
      "101211",
      "200748",
      "201571",
      "201582",
      "201945",
      "201974"
    ]
  },
  "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9": {
    "team": "1610612766",
    "year": "2010",
    "players": [
      "1536",
      "2038",
      "2059",
      "2198",
      "2205",
      "2564",
      "2679",
      "2733",
      "200748",
      "201187",
      "201571",
      "201591",
      "201945",
      "201967",
      "202066"
    ]
  },
  "ec301ccf-b3d6-4a12-ae55-a477e60e04c6": {
    "team": "1610612766",
    "year": "2011",
    "players": [
      "1894",
      "2059",
      "2205",
      "2679",
      "200081",
      "200748",
      "201571",
      "201591",
      "201945",
      "201957",
      "201974",
      "202130",
      "202687",
      "202689",
      "202809"
    ]
  },
  "0d64aa31-dbd0-4e9e-a9af-9a567e992095": {
    "team": "1610612766",
    "year": "2012",
    "players": [
      "2205",
      "2217",
      "2457",
      "2732",
      "200748",
      "201177",
      "201196",
      "201945",
      "201957",
      "202130",
      "202399",
      "202687",
      "202689",
      "203077",
      "203106"
    ]
  },
  "45ed0581-1916-49f0-9a2f-fb15342345c5": {
    "team": "1610612766",
    "year": "2013",
    "players": [
      "2217",
      "2457",
      "2557",
      "2744",
      "201177",
      "201229",
      "201591",
      "201604",
      "201945",
      "202390",
      "202687",
      "202689",
      "203077",
      "203106",
      "203469"
    ]
  },
  "34b5ca1c-2ef8-4e94-b64b-10030f65c864": {
    "team": "1610612766",
    "year": "2014",
    "players": [
      "2590",
      "2744",
      "101107",
      "101131",
      "201945",
      "202362",
      "202687",
      "202689",
      "203077",
      "203106",
      "203148",
      "203469",
      "203584",
      "203798",
      "203943"
    ]
  },
  "31164298-2c0d-4a44-b557-e740801b4b1d": {
    "team": "1610612766",
    "year": "2015",
    "players": [
      "2744",
      "101107",
      "201150",
      "201584",
      "201587",
      "201946",
      "202391",
      "202689",
      "203077",
      "203087",
      "203268",
      "203469",
      "203584",
      "1626151",
      "1626163"
    ]
  },
  "4c417b8c-07cf-4c43-a27c-2d30e7fc133e": {
    "team": "1610612766",
    "year": "2016",
    "players": [
      "101107",
      "201158",
      "201196",
      "201587",
      "202689",
      "203077",
      "203087",
      "203101",
      "203148",
      "203469",
      "203948",
      "1626163",
      "1626174",
      "1626203",
      "1627362"
    ]
  },
  "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9": {
    "team": "1610612766",
    "year": "2017",
    "players": [
      "2730",
      "101107",
      "201587",
      "202689",
      "202933",
      "203077",
      "203087",
      "203469",
      "203487",
      "1626163",
      "1626195",
      "1626203",
      "1627779",
      "1628370",
      "1628407",
      "1628493"
    ]
  },
  "7e4b59f0-0509-4845-8730-9663232e7b79": {
    "team": "1610612766",
    "year": "2018",
    "players": [
      "2225",
      "101107",
      "201587",
      "202687",
      "202689",
      "202714",
      "203077",
      "203087",
      "203469",
      "1626163",
      "1626195",
      "1628370",
      "1628407",
      "1628970",
      "1628984",
      "1629122",
      "1629147"
    ]
  },
  "215d58c2-6db2-44bf-94e9-79535092d38e": {
    "team": "1610612766",
    "year": "2019",
    "players": [
      "201587",
      "202687",
      "203469",
      "1626179",
      "1626195",
      "1628370",
      "1628407",
      "1628424",
      "1628970",
      "1628984",
      "1628997",
      "1628998",
      "1629023",
      "1629034",
      "1629147",
      "1629667"
    ]
  }
};