export default {
  "2": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "202",
    "BIRTH_DATE": "MAR 28, 1961",
    "EXP": "12",
    "SCHOOL": "Arizona State",
    "name": "Byron Scott",
    "rosters": [
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "3979ce94-914f-45be-ba16-62480c3df040",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119"
    ]
  },
  "3": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "248",
    "BIRTH_DATE": "MAR 12, 1966",
    "EXP": "8",
    "SCHOOL": "Eastern Michigan",
    "name": "Grant Long",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "652536cb-d53b-4de7-a147-555aacb21048",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad",
      "721f24b6-955d-43a4-901d-da6918201f42",
      "f03a4206-d842-4a99-8713-66ae264320ff"
    ]
  },
  "7": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 10, 1959",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Dan Schayes",
    "rosters": [
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "4b67a5bb-ca70-47aa-9934-95aef577269a",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "16161b6e-7707-4383-b96c-3f7cf17fe149"
    ]
  },
  "9": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 10, 1961",
    "EXP": "7",
    "SCHOOL": "West Virginia Tech",
    "name": "Sedale Threatt",
    "rosters": [
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "3979ce94-914f-45be-ba16-62480c3df040",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a"
    ]
  },
  "12": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 24, 1969",
    "EXP": "1",
    "SCHOOL": "Wake Forest",
    "name": "Chris King",
    "rosters": [
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119"
    ]
  },
  "15": {
    "NUM": "52",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 30, 1970",
    "EXP": "13",
    "SCHOOL": "Nebraska",
    "name": "Eric Piatkowski",
    "rosters": [
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706"
    ]
  },
  "17": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 22, 1962",
    "EXP": "10",
    "SCHOOL": "Houston",
    "name": "Clyde Drexler",
    "rosters": [
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2"
    ]
  },
  "21": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 15, 1967",
    "EXP": "5",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Greg Anthony",
    "rosters": [
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119",
      "b884431e-1319-4251-a977-cdd919c3468e"
    ]
  },
  "22": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "7-4",
    "WEIGHT": "265",
    "BIRTH_DATE": "AUG 23, 1966",
    "EXP": "11",
    "SCHOOL": "Marist",
    "name": "Rik Smits",
    "rosters": [
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2"
    ]
  },
  "23": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 13, 1961",
    "EXP": "6",
    "SCHOOL": "Southeastern Oklahoma State",
    "name": "Dennis Rodman",
    "rosters": [
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c",
      "db85dc84-6de1-4038-8c49-8f636dd20715",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "24": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "5-7",
    "WEIGHT": "160",
    "BIRTH_DATE": "NOV 02, 1968",
    "EXP": "2",
    "SCHOOL": "East Tennessee State",
    "name": "Keith Jennings",
    "rosters": [
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "92f101b9-7456-437b-8d91-9d5618b0b994"
    ]
  },
  "26": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "260",
    "BIRTH_DATE": "JAN 19, 1969",
    "EXP": "8",
    "SCHOOL": "New Mexico",
    "name": "Luc Longley",
    "rosters": [
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69",
      "5cd70763-5c6d-404c-ae94-b800444a7144"
    ]
  },
  "28": {
    "NUM": "2",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 27, 1967",
    "EXP": "10",
    "SCHOOL": "Villanova",
    "name": "Doug West",
    "rosters": [
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "378d09ae-9325-475b-960c-5806574aab51"
    ]
  },
  "29": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUL 30, 1972",
    "EXP": "1",
    "SCHOOL": "Marquette",
    "name": "Jim McIlvaine",
    "rosters": [
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "28c686d2-151c-45b0-b976-f1ca5ae19603",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69"
    ]
  },
  "30": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 19, 1969",
    "EXP": "1",
    "SCHOOL": "Oklahoma State",
    "name": "Richard Dumas",
    "rosters": [
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90"
    ]
  },
  "31": {
    "NUM": "43",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 15, 1969",
    "EXP": "4",
    "SCHOOL": "Stetson",
    "name": "Lorenzo Williams",
    "rosters": [
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f"
    ]
  },
  "32": {
    "NUM": "7",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 31, 1972",
    "EXP": "2",
    "SCHOOL": "Louisville",
    "name": "Clifford Rozier",
    "rosters": [
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "dbff03be-c19e-4e69-9c80-539569f223da"
    ]
  },
  "35": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 02, 1970",
    "EXP": "2",
    "SCHOOL": "Michigan",
    "name": "Eric Riley",
    "rosters": [
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "e170cb31-d4ef-499a-8662-716298adebe7"
    ]
  },
  "36": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 13, 1964",
    "EXP": "4",
    "SCHOOL": null,
    "name": "Sarunas Marciulionis",
    "rosters": [
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed"
    ]
  },
  "37": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "182",
    "BIRTH_DATE": "NOV 26, 1970",
    "EXP": "3",
    "SCHOOL": "Indiana",
    "name": "Greg Graham",
    "rosters": [
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "28c686d2-151c-45b0-b976-f1ca5ae19603"
    ]
  },
  "38": {
    "NUM": "54",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 29, 1964",
    "EXP": "8",
    "SCHOOL": "Iowa",
    "name": "Brad Lohaus",
    "rosters": [
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "65fe3c0f-07c5-469c-b786-9542d2d55742",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b"
    ]
  },
  "41": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUN 08, 1968",
    "EXP": "6",
    "SCHOOL": "Saint Louis",
    "name": "Anthony Bonner",
    "rosters": [
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b"
    ]
  },
  "42": {
    "NUM": "3",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 08, 1971",
    "EXP": "3",
    "SCHOOL": "Notre Dame",
    "name": "Monty Williams",
    "rosters": [
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b"
    ]
  },
  "43": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 05, 1971",
    "EXP": "10",
    "SCHOOL": "Clemson",
    "name": "Chris Whitney",
    "rosters": [
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f",
      "3a362135-e048-4453-af47-87555fdc1775",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf",
      "0b7a4d32-1992-4984-b46d-503707641e87",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "559e5d80-d64b-4c62-9122-9669d7a168dd"
    ]
  },
  "45": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 27, 1967",
    "EXP": "8",
    "SCHOOL": "Florida State",
    "name": "George McCloud",
    "rosters": [
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69"
    ]
  },
  "46": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "270",
    "BIRTH_DATE": "OCT 15, 1970",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Mike Peplowski",
    "rosters": [
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934"
    ]
  },
  "47": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 09, 1964",
    "EXP": "8",
    "SCHOOL": "Oklahoma",
    "name": "Wayman Tisdale",
    "rosters": [
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "65fe3c0f-07c5-469c-b786-9542d2d55742",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff",
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "2678d1f0-b641-4c9f-9473-9217e379be6c"
    ]
  },
  "49": {
    "NUM": "77",
    "POSITION": "C",
    "HEIGHT": "7-7",
    "WEIGHT": "303",
    "BIRTH_DATE": "FEB 14, 1971",
    "EXP": "3",
    "SCHOOL": null,
    "name": "Gheorghe Muresan",
    "rosters": [
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f"
    ]
  },
  "51": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "162",
    "BIRTH_DATE": "MAR 09, 1969",
    "EXP": "8",
    "SCHOOL": "Louisiana State",
    "name": "Mahmoud Abdul-Rauf",
    "rosters": [
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad"
    ]
  },
  "52": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 23, 1966",
    "EXP": "1",
    "SCHOOL": "Baylor",
    "name": "Micheal Williams",
    "rosters": [
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "c9048bdc-2836-4609-9734-21853a995553",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "53": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 26, 1963",
    "EXP": "9",
    "SCHOOL": "American International",
    "name": "Mario Elie",
    "rosters": [
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "426fcece-7698-41e8-8ebb-254a273fe06b",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29"
    ]
  },
  "54": {
    "NUM": "8",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "255",
    "BIRTH_DATE": "APR 06, 1972",
    "EXP": "5",
    "SCHOOL": "Providence",
    "name": "Dickey Simpkins",
    "rosters": [
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299"
    ]
  },
  "55": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "224",
    "BIRTH_DATE": "JAN 19, 1968",
    "EXP": "2",
    "SCHOOL": "Providence",
    "name": "Marty Conlon",
    "rosters": [
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b",
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "20b624e6-187e-4570-9266-08913829ee83",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7"
    ]
  },
  "56": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 23, 1968",
    "EXP": "12",
    "SCHOOL": "Oregon State",
    "name": "Gary Payton",
    "rosters": [
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "28c686d2-151c-45b0-b976-f1ca5ae19603",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40",
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd"
    ]
  },
  "57": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAY 09, 1970",
    "EXP": "7",
    "SCHOOL": "Pepperdine",
    "name": "Doug Christie",
    "rosters": [
      "3979ce94-914f-45be-ba16-62480c3df040",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "4a934d85-87c5-4059-9963-b76f50593f0b",
      "dbff03be-c19e-4e69-9c80-539569f223da",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae"
    ]
  },
  "61": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 14, 1966",
    "EXP": "6",
    "SCHOOL": "Houston",
    "name": "Carl Herrera",
    "rosters": [
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b"
    ]
  },
  "63": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 28, 1972",
    "EXP": "6",
    "SCHOOL": "Shaw",
    "name": "Michael Smith",
    "rosters": [
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565"
    ]
  },
  "64": {
    "NUM": "14",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUN 14, 1961",
    "EXP": "13",
    "SCHOOL": "North Carolina",
    "name": "Sam Perkins",
    "rosters": [
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "28c686d2-151c-45b0-b976-f1ca5ae19603",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82"
    ]
  },
  "65": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 08, 1971",
    "EXP": "3",
    "SCHOOL": "Louisville",
    "name": "Greg Minor",
    "rosters": [
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "ab598beb-863a-4658-a955-99fa97cb8d08"
    ]
  },
  "66": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 24, 1970",
    "EXP": "R",
    "SCHOOL": "Providence",
    "name": "Marques Bragg",
    "rosters": [
      "e170cb31-d4ef-499a-8662-716298adebe7"
    ]
  },
  "67": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 27, 1967",
    "EXP": "2",
    "SCHOOL": "Georgia Tech",
    "name": "Tom Hammonds",
    "rosters": [
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "c9048bdc-2836-4609-9734-21853a995553",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "44e38364-f6e8-4d07-bb01-e7f82356367c",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1"
    ]
  },
  "70": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 27, 1965",
    "EXP": "14",
    "SCHOOL": "Arizona",
    "name": "Steve Kerr",
    "rosters": [
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b"
    ]
  },
  "71": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 14, 1966",
    "EXP": "4",
    "SCHOOL": "California-Los Angeles",
    "name": "Pooh Richardson",
    "rosters": [
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "044dce90-7443-4814-8454-a974b791ec76"
    ]
  },
  "72": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "168",
    "BIRTH_DATE": "OCT 09, 1970",
    "EXP": "4",
    "SCHOOL": "Georgia Tech",
    "name": "Kenny Anderson",
    "rosters": [
      "ab598beb-863a-4658-a955-99fa97cb8d08",
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "9c894a5d-df1a-404c-b058-78d127394ba6"
    ]
  },
  "73": {
    "NUM": "18",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "AUG 09, 1962",
    "EXP": "11",
    "SCHOOL": "Tulane",
    "name": "Hot Rod Williams",
    "rosters": [
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e"
    ]
  },
  "74": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "181",
    "BIRTH_DATE": "JUL 31, 1972",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Derrick Phelps",
    "rosters": [
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1"
    ]
  },
  "76": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 02, 1969",
    "EXP": "6",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Cedric Ceballos",
    "rosters": [
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "78c68458-e60d-4462-a703-8501fdadf08e"
    ]
  },
  "77": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 03, 1969",
    "EXP": "2",
    "SCHOOL": "La Salle",
    "name": "Doug Overton",
    "rosters": [
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "d0523481-86c7-447a-b0d9-81625e9e333c",
      "9e3f5154-3fa3-44ca-a44f-98320c951308",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa"
    ]
  },
  "78": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 23, 1961",
    "EXP": "5",
    "SCHOOL": "Texas",
    "name": "LaSalle Thompson",
    "rosters": [
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "80": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 05, 1964",
    "EXP": "6",
    "SCHOOL": "Illinois",
    "name": "Ken Norman",
    "rosters": [
      "a859ae74-acea-4f80-abaa-92f971001064",
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "c414034d-9405-454b-81a1-00b89efc08b5"
    ]
  },
  "81": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 17, 1965",
    "EXP": "7",
    "SCHOOL": "Iowa State",
    "name": "Jeff Grayer",
    "rosters": [
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693"
    ]
  },
  "82": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "APR 26, 1963",
    "EXP": "5",
    "SCHOOL": "St. John's (NY)",
    "name": "Bill Wennington",
    "rosters": [
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "57c1be08-60cf-40ad-a097-a95ef9958532"
    ]
  },
  "84": {
    "NUM": "8",
    "POSITION": "F-G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 08, 1970",
    "EXP": "10",
    "SCHOOL": "Alabama",
    "name": "Latrell Sprewell",
    "rosters": [
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "1fed5db0-b968-4bc2-b296-10632898c907"
    ]
  },
  "85": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 09, 1968",
    "EXP": "2",
    "SCHOOL": "Oklahoma",
    "name": "Brent Price",
    "rosters": [
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69"
    ]
  },
  "87": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "265",
    "BIRTH_DATE": "JUN 25, 1966",
    "EXP": "10",
    "SCHOOL": "Georgetown",
    "name": "Dikembe Mutombo",
    "rosters": [
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "84b74c33-754c-4c8e-babe-0fe581adab51"
    ]
  },
  "88": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 03, 1967",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Steve Scheffler",
    "rosters": [
      "37167a67-466f-4920-b036-1481c8665cf9",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e"
    ]
  },
  "89": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 27, 1971",
    "EXP": "12",
    "SCHOOL": "Cincinnati",
    "name": "Nick Van Exel",
    "rosters": [
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "187b2b41-379c-4869-a6d8-8469aec4efbc"
    ]
  },
  "93": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "183",
    "BIRTH_DATE": "MAY 17, 1970",
    "EXP": "10",
    "SCHOOL": "North Carolina",
    "name": "Hubert Davis",
    "rosters": [
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565",
      "6d018b74-602e-4da7-810d-8489c0aab560"
    ]
  },
  "95": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "APR 25, 1965",
    "EXP": "13",
    "SCHOOL": "Seton Hall",
    "name": "Mark Bryant",
    "rosters": [
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "1204693e-5234-4041-9acb-b8a3eea3566c"
    ]
  },
  "96": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 21, 1963",
    "EXP": "13",
    "SCHOOL": "Washington",
    "name": "Detlef Schrempf",
    "rosters": [
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "28c686d2-151c-45b0-b976-f1ca5ae19603",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912"
    ]
  },
  "97": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 09, 1964",
    "EXP": "1",
    "SCHOOL": "New Orleans",
    "name": "Ronnie Grandison",
    "rosters": [
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170"
    ]
  },
  "98": {
    "NUM": "25",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "228",
    "BIRTH_DATE": "JAN 20, 1968",
    "EXP": "11",
    "SCHOOL": "Illinois",
    "name": "Nick Anderson",
    "rosters": [
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "4b67a5bb-ca70-47aa-9934-95aef577269a",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "775e1767-1db9-44e9-b74e-932f413dc114"
    ]
  },
  "100": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 26, 1966",
    "EXP": "8",
    "SCHOOL": "La Salle",
    "name": "Tim Legler",
    "rosters": [
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf"
    ]
  },
  "101": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 05, 1964",
    "EXP": "8",
    "SCHOOL": "Michigan State",
    "name": "Scott Skiles",
    "rosters": [
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa"
    ]
  },
  "103": {
    "NUM": "11",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "188",
    "BIRTH_DATE": "JAN 07, 1970",
    "EXP": "6",
    "SCHOOL": "Arkansas",
    "name": "Todd Day",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "20b624e6-187e-4570-9266-08913829ee83",
      "5cd70763-5c6d-404c-ae94-b800444a7144"
    ]
  },
  "104": {
    "NUM": "00",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 22, 1964",
    "EXP": "6",
    "SCHOOL": "Creighton",
    "name": "Benoit Benjamin",
    "rosters": [
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "3979ce94-914f-45be-ba16-62480c3df040",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984"
    ]
  },
  "105": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "222",
    "BIRTH_DATE": "SEP 09, 1965",
    "EXP": "13",
    "SCHOOL": "Central Michigan",
    "name": "Dan Majerle",
    "rosters": [
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60"
    ]
  },
  "107": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 06, 1960",
    "EXP": "14",
    "SCHOOL": "Tennessee",
    "name": "Dale Ellis",
    "rosters": [
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82"
    ]
  },
  "109": {
    "NUM": "25",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 25, 1970",
    "EXP": "15",
    "SCHOOL": "Alabama",
    "name": "Robert Horry",
    "rosters": [
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856"
    ]
  },
  "111": {
    "NUM": "3",
    "POSITION": "C-F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 05, 1970",
    "EXP": "8",
    "SCHOOL": "Notre Dame",
    "name": "LaPhonso Ellis",
    "rosters": [
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace"
    ]
  },
  "112": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "256",
    "BIRTH_DATE": "JUN 23, 1974",
    "EXP": "R",
    "SCHOOL": "Cincinnati",
    "name": "Dontonio Wingfield",
    "rosters": [
      "3eff7ebb-bedf-4522-b837-8677e8944c50",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed"
    ]
  },
  "114": {
    "NUM": "4-32",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 05, 1971",
    "EXP": "2",
    "SCHOOL": "Southern California",
    "name": "Harold Miner",
    "rosters": [
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "edbdfda6-7b68-4ac6-889b-15601e808663"
    ]
  },
  "116": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "227",
    "BIRTH_DATE": "NOV 30, 1964",
    "EXP": "3",
    "SCHOOL": "Nevada-Reno",
    "name": "David Wood",
    "rosters": [
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "117": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 28, 1961",
    "EXP": "6",
    "SCHOOL": "Mississippi State",
    "name": "Jeff Malone",
    "rosters": [
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "355ade3f-1412-4279-a7f6-512d2936bf86",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58",
      "44e38364-f6e8-4d07-bb01-e7f82356367c"
    ]
  },
  "120": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "221",
    "BIRTH_DATE": "MAR 31, 1969",
    "EXP": "11",
    "SCHOOL": "Michigan State",
    "name": "Steven Smith",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b"
    ]
  },
  "121": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "AUG 05, 1962",
    "EXP": "15",
    "SCHOOL": "Georgetown",
    "name": "Patrick Ewing",
    "rosters": [
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40"
    ]
  },
  "122": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "218",
    "BIRTH_DATE": "AUG 14, 1960",
    "EXP": "2",
    "SCHOOL": "Brigham Young",
    "name": "Fred Roberts",
    "rosters": [
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a"
    ]
  },
  "123": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 03, 1969",
    "EXP": "4",
    "SCHOOL": "Southern California",
    "name": "Robert Pack",
    "rosters": [
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69"
    ]
  },
  "124": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "260",
    "BIRTH_DATE": "FEB 03, 1968",
    "EXP": "8",
    "SCHOOL": null,
    "name": "Vlade Divac",
    "rosters": [
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "3979ce94-914f-45be-ba16-62480c3df040",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b",
      "25f7d175-5991-48e6-8d9b-391b80096be6"
    ]
  },
  "128": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 09, 1968",
    "EXP": "6",
    "SCHOOL": "Alabama",
    "name": "David Benoit",
    "rosters": [
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f"
    ]
  },
  "129": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 24, 1967",
    "EXP": "2",
    "SCHOOL": null,
    "name": "Dino Radja",
    "rosters": [
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "20f34d15-ed9b-4828-98e7-1ef7044902e6"
    ]
  },
  "132": {
    "NUM": "50",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 10, 1971",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Dwayne Morton",
    "rosters": [
      "92f101b9-7456-437b-8d91-9d5618b0b994"
    ]
  },
  "133": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "203",
    "BIRTH_DATE": "NOV 14, 1970",
    "EXP": "8",
    "SCHOOL": "Baylor",
    "name": "David Wesley",
    "rosters": [
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "25f7d175-5991-48e6-8d9b-391b80096be6",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2",
      "c060723a-d5e6-4385-bc5e-59560b27d467",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "134": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 04, 1966",
    "EXP": "11",
    "SCHOOL": "California",
    "name": "Kevin Johnson",
    "rosters": [
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "5cd70763-5c6d-404c-ae94-b800444a7144"
    ]
  },
  "136": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "239",
    "BIRTH_DATE": "OCT 14, 1969",
    "EXP": "8",
    "SCHOOL": "Louisiana Tech",
    "name": "P.J. Brown",
    "rosters": [
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "137": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 12, 1965",
    "EXP": "8",
    "SCHOOL": "Florida",
    "name": "Vernon Maxwell",
    "rosters": [
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be"
    ]
  },
  "138": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 18, 1969",
    "EXP": "3",
    "SCHOOL": "Seton Hall",
    "name": "Anthony Avent",
    "rosters": [
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119"
    ]
  },
  "140": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "218",
    "BIRTH_DATE": "MAY 22, 1966",
    "EXP": "7",
    "SCHOOL": "Notre Dame",
    "name": "Donald Royal",
    "rosters": [
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b",
      "25f7d175-5991-48e6-8d9b-391b80096be6"
    ]
  },
  "141": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 04, 1969",
    "EXP": "R",
    "SCHOOL": "Wake Forest",
    "name": "Anthony Tucker",
    "rosters": [
      "fa3c62ff-1a19-4597-a827-0005f973f6aa"
    ]
  },
  "143": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 12, 1971",
    "EXP": "5",
    "SCHOOL": "Seton Hall",
    "name": "Terry Dehere",
    "rosters": [
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7"
    ]
  },
  "145": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "228",
    "BIRTH_DATE": "JUL 25, 1971",
    "EXP": "7",
    "SCHOOL": "California-Los Angeles",
    "name": "Tracy Murray",
    "rosters": [
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "4a934d85-87c5-4059-9963-b76f50593f0b",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f",
      "3a362135-e048-4453-af47-87555fdc1775",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf",
      "0b7a4d32-1992-4984-b46d-503707641e87"
    ]
  },
  "146": {
    "NUM": "30",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "228",
    "BIRTH_DATE": "JUN 19, 1968",
    "EXP": "10",
    "SCHOOL": "Arizona",
    "name": "Jud Buechler",
    "rosters": [
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "09154feb-3670-47a9-a0ed-55f55f2002b1",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b",
      "9359cd35-305f-4f57-847e-21ec13abdc68"
    ]
  },
  "147": {
    "NUM": "5",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 30, 1973",
    "EXP": "10",
    "SCHOOL": "Michigan",
    "name": "Jalen Rose",
    "rosters": [
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae"
    ]
  },
  "149": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "165",
    "BIRTH_DATE": "JAN 19, 1963",
    "EXP": "10",
    "SCHOOL": "Boston College",
    "name": "Michael Adams",
    "rosters": [
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291",
      "9c894a5d-df1a-404c-b058-78d127394ba6"
    ]
  },
  "154": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "265",
    "BIRTH_DATE": "JUL 04, 1966",
    "EXP": "4",
    "SCHOOL": "Lamar",
    "name": "Adrian Caldwell",
    "rosters": [
      "955db219-1fe5-405f-a607-095000d7ba49",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "9e3f5154-3fa3-44ca-a44f-98320c951308"
    ]
  },
  "156": {
    "NUM": "55",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "270",
    "BIRTH_DATE": "JUL 23, 1961",
    "EXP": "13",
    "SCHOOL": "Wichita State",
    "name": "Antoine Carr",
    "rosters": [
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff",
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6"
    ]
  },
  "157": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "206",
    "BIRTH_DATE": "OCT 13, 1961",
    "EXP": "14",
    "SCHOOL": "Illinois",
    "name": "Derek Harper",
    "rosters": [
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b"
    ]
  },
  "160": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 19, 1970",
    "EXP": "1",
    "SCHOOL": "Wyoming",
    "name": "Tim Breaux",
    "rosters": [
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674"
    ]
  },
  "164": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 20, 1967",
    "EXP": "7",
    "SCHOOL": "Boise State",
    "name": "Chris Childs",
    "rosters": [
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee"
    ]
  },
  "165": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "JAN 21, 1963",
    "EXP": "18",
    "SCHOOL": "Houston",
    "name": "Hakeem Olajuwon",
    "rosters": [
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "79d8e657-ce27-4052-9492-c9833808173f",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9"
    ]
  },
  "166": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 20, 1964",
    "EXP": "14",
    "SCHOOL": "Miami (OH)",
    "name": "Ron Harper",
    "rosters": [
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "245559d4-40e5-4af9-8719-a54fe451cb29"
    ]
  },
  "168": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "216",
    "BIRTH_DATE": "JAN 25, 1970",
    "EXP": "4",
    "SCHOOL": "Arizona",
    "name": "Chris Mills",
    "rosters": [
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7"
    ]
  },
  "170": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "JAN 04, 1962",
    "EXP": "2",
    "SCHOOL": "Arkansas",
    "name": "Joe Kleine",
    "rosters": [
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "173": {
    "NUM": "2",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 15, 1967",
    "EXP": "8",
    "SCHOOL": "Alabama",
    "name": "Keith Askins",
    "rosters": [
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0"
    ]
  },
  "174": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 09, 1962",
    "EXP": "9",
    "SCHOOL": "Rutgers",
    "name": "John Battle",
    "rosters": [
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e"
    ]
  },
  "175": {
    "NUM": "53",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 01, 1958",
    "EXP": "7",
    "SCHOOL": "Arizona State",
    "name": "Alton Lister",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "20b624e6-187e-4570-9266-08913829ee83",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf"
    ]
  },
  "176": {
    "NUM": "4",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUL 06, 1970",
    "EXP": "5",
    "SCHOOL": "Pfeiffer",
    "name": "Antonio Harvey",
    "rosters": [
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39"
    ]
  },
  "177": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "5-3",
    "WEIGHT": "141",
    "BIRTH_DATE": "JAN 09, 1965",
    "EXP": "9",
    "SCHOOL": "Wake Forest",
    "name": "Muggsy Bogues",
    "rosters": [
      "0aa87910-76b7-4782-a081-a241102c4730",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "440e1824-f688-481f-9c58-4951796e7ad1",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170",
      "3e72397e-e399-4a1e-bd06-a9b455547453",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291",
      "9c894a5d-df1a-404c-b058-78d127394ba6",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b"
    ]
  },
  "178": {
    "NUM": "34",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 21, 1964",
    "EXP": "5",
    "SCHOOL": "Virginia",
    "name": "Olden Polynice",
    "rosters": [
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693",
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912",
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "179": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 10, 1970",
    "EXP": "10",
    "SCHOOL": "Virginia",
    "name": "Bryant Stith",
    "rosters": [
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "6d6518fd-faa9-4fbd-991f-20932543250e"
    ]
  },
  "180": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "194",
    "BIRTH_DATE": "JUL 15, 1969",
    "EXP": "7",
    "SCHOOL": "Virginia",
    "name": "John Crotty",
    "rosters": [
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b"
    ]
  },
  "181": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 08, 1965",
    "EXP": "1",
    "SCHOOL": "North Carolina",
    "name": "Kenny Smith",
    "rosters": [
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47",
      "65fe3c0f-07c5-469c-b786-9542d2d55742"
    ]
  },
  "182": {
    "NUM": "30",
    "POSITION": "F-G",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 01, 1969",
    "EXP": "6",
    "SCHOOL": "Syracuse",
    "name": "Billy Owens",
    "rosters": [
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "2f7ada02-daaa-431d-b4e1-61d409829b71",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693",
      "3c6f5772-9b65-4def-8409-05c17bc09fef"
    ]
  },
  "183": {
    "NUM": "8",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "APR 06, 1969",
    "EXP": "7",
    "SCHOOL": "Arizona",
    "name": "Bison Dele",
    "rosters": [
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc",
      "09154feb-3670-47a9-a0ed-55f55f2002b1"
    ]
  },
  "184": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "226",
    "BIRTH_DATE": "DEC 20, 1969",
    "EXP": "7",
    "SCHOOL": "Southern University",
    "name": "Bobby Phills",
    "rosters": [
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "25f7d175-5991-48e6-8d9b-391b80096be6",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2"
    ]
  },
  "185": {
    "NUM": "84",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAR 01, 1973",
    "EXP": "13",
    "SCHOOL": "Michigan",
    "name": "Chris Webber",
    "rosters": [
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f",
      "3a362135-e048-4453-af47-87555fdc1775",
      "532f6daa-fa7a-422a-9326-f0c577396c95"
    ]
  },
  "187": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAR 15, 1961",
    "EXP": "14",
    "SCHOOL": "DePaul",
    "name": "Terry Cummings",
    "rosters": [
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "28c686d2-151c-45b0-b976-f1ca5ae19603"
    ]
  },
  "189": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 30, 1971",
    "EXP": "R",
    "SCHOOL": "Texas",
    "name": "B.J. Tyler",
    "rosters": [
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1"
    ]
  },
  "190": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAY 31, 1968",
    "EXP": "11",
    "SCHOOL": "Temple",
    "name": "Duane Causwell",
    "rosters": [
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b"
    ]
  },
  "192": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "229",
    "BIRTH_DATE": "SEP 05, 1968",
    "EXP": "9",
    "SCHOOL": "Georgia Tech",
    "name": "Dennis Scott",
    "rosters": [
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "4b67a5bb-ca70-47aa-9934-95aef577269a",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "378d09ae-9325-475b-960c-5806574aab51"
    ]
  },
  "193": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "270",
    "BIRTH_DATE": "DEC 14, 1966",
    "EXP": "10",
    "SCHOOL": "Tennessee State",
    "name": "Anthony Mason",
    "rosters": [
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b",
      "25f7d175-5991-48e6-8d9b-391b80096be6",
      "c060723a-d5e6-4385-bc5e-59560b27d467"
    ]
  },
  "194": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 09, 1963",
    "EXP": "R",
    "SCHOOL": "Oklahoma",
    "name": "Anthony Bowie",
    "rosters": [
      "955db219-1fe5-405f-a607-095000d7ba49",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a"
    ]
  },
  "195": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 12, 1970",
    "EXP": "5",
    "SCHOOL": "Arkansas",
    "name": "Lee Mayberry",
    "rosters": [
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "20b624e6-187e-4570-9266-08913829ee83",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "b884431e-1319-4251-a977-cdd919c3468e",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792"
    ]
  },
  "197": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "JAN 12, 1971",
    "EXP": "7",
    "SCHOOL": "Connecticut",
    "name": "Scott Burrell",
    "rosters": [
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291",
      "9c894a5d-df1a-404c-b058-78d127394ba6",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf"
    ]
  },
  "198": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 17, 1966",
    "EXP": "12",
    "SCHOOL": "Duke",
    "name": "Danny Ferry",
    "rosters": [
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b"
    ]
  },
  "199": {
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 05, 1964",
    "EXP": "2",
    "SCHOOL": "Georgetown",
    "name": "Reggie Williams",
    "rosters": [
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8"
    ],
    "NUM": "34"
  },
  "201": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "FEB 22, 1965",
    "EXP": "15",
    "SCHOOL": "Yale",
    "name": "Chris Dudley",
    "rosters": [
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "426fcece-7698-41e8-8ebb-254a273fe06b",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "3eff7ebb-bedf-4522-b837-8677e8944c50",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1"
    ]
  },
  "202": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 21, 1965",
    "EXP": "11",
    "SCHOOL": "Michigan",
    "name": "Gary Grant",
    "rosters": [
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4"
    ]
  },
  "203": {
    "NUM": "10",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 03, 1964",
    "EXP": "11",
    "SCHOOL": "North Carolina State",
    "name": "Nate McMillan",
    "rosters": [
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "28c686d2-151c-45b0-b976-f1ca5ae19603",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82"
    ]
  },
  "204": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 03, 1963",
    "EXP": "13",
    "SCHOOL": "Iowa State",
    "name": "Jeff Hornacek",
    "rosters": [
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "30107e00-802b-466d-b025-30b5f60b2c06"
    ]
  },
  "208": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 18, 1969",
    "EXP": "4",
    "SCHOOL": "Florida State",
    "name": "Sam Cassell",
    "rosters": [
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "48bd29bb-6817-4388-af0d-0fb73d383119"
    ]
  },
  "209": {
    "NUM": "30",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 25, 1964",
    "EXP": "11",
    "SCHOOL": "Virginia Tech",
    "name": "Dell Curry",
    "rosters": [
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "440e1824-f688-481f-9c58-4951796e7ad1",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170",
      "3e72397e-e399-4a1e-bd06-a9b455547453",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291",
      "9c894a5d-df1a-404c-b058-78d127394ba6",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b",
      "25f7d175-5991-48e6-8d9b-391b80096be6"
    ]
  },
  "210": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "173",
    "BIRTH_DATE": "MAY 20, 1970",
    "EXP": "11",
    "SCHOOL": "Oregon",
    "name": "Terrell Brandon",
    "rosters": [
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "8f9aa8f6-6917-4467-b970-3855520a730d"
    ]
  },
  "211": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "FEB 01, 1970",
    "EXP": "R",
    "SCHOOL": "Pittsburgh",
    "name": "Eric Mobley",
    "rosters": [
      "20b624e6-187e-4570-9266-08913829ee83"
    ]
  },
  "212": {
    "NUM": "33",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "265",
    "BIRTH_DATE": "OCT 10, 1972",
    "EXP": "2",
    "SCHOOL": "George Washington",
    "name": "Yinka Dare",
    "rosters": [
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0"
    ]
  },
  "213": {
    "NUM": "33",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 31, 1968",
    "EXP": "9",
    "SCHOOL": "Texas-El Paso",
    "name": "Antonio Davis",
    "rosters": [
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9"
    ]
  },
  "216": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 22, 1966",
    "EXP": "7",
    "SCHOOL": "California-Santa Barbara",
    "name": "Brian Shaw",
    "rosters": [
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "4b67a5bb-ca70-47aa-9934-95aef577269a"
    ]
  },
  "219": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 09, 1966",
    "EXP": "7",
    "SCHOOL": "North Carolina State",
    "name": "Vinny Del Negro",
    "rosters": [
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "426fcece-7698-41e8-8ebb-254a273fe06b",
      "65fe3c0f-07c5-469c-b786-9542d2d55742",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b"
    ]
  },
  "221": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 08, 1970",
    "EXP": "4",
    "SCHOOL": "Southern Mississippi",
    "name": "Clar. Weatherspoon",
    "rosters": [
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "9e3f5154-3fa3-44ca-a44f-98320c951308"
    ]
  },
  "223": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 29, 1972",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "Bill Curley",
    "rosters": [
      "2f7ada02-daaa-431d-b4e1-61d409829b71",
      "c9048bdc-2836-4609-9734-21853a995553",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934"
    ]
  },
  "224": {
    "NUM": "6",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 20, 1971",
    "EXP": "5",
    "SCHOOL": "Temple",
    "name": "Eddie Jones",
    "rosters": [
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2",
      "c060723a-d5e6-4385-bc5e-59560b27d467"
    ]
  },
  "226": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAY 15, 1972",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Antonio Lang",
    "rosters": [
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90"
    ]
  },
  "228": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 22, 1970",
    "EXP": "7",
    "SCHOOL": "Stanford",
    "name": "Adam Keefe",
    "rosters": [
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "30107e00-802b-466d-b025-30b5f60b2c06"
    ]
  },
  "229": {
    "NUM": "53",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 22, 1955",
    "EXP": "13",
    "SCHOOL": "Washington",
    "name": "James Edwards",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "3979ce94-914f-45be-ba16-62480c3df040",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "b53d3c18-a4f1-40bf-a266-2555fa606018",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c"
    ]
  },
  "234": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "199",
    "BIRTH_DATE": "JUL 15, 1972",
    "EXP": "1",
    "SCHOOL": "Arizona",
    "name": "Khalid Reeves",
    "rosters": [
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "d8471143-7caa-497d-8696-5d0bb039d3c2"
    ]
  },
  "236": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 30, 1965",
    "EXP": "10",
    "SCHOOL": "DePaul",
    "name": "Kevin Edwards",
    "rosters": [
      "652536cb-d53b-4de7-a147-555aacb21048",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad"
    ]
  },
  "238": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAR 19, 1968",
    "EXP": "10",
    "SCHOOL": "Xavier",
    "name": "Tyrone Hill",
    "rosters": [
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "81b6284b-5f88-485c-a000-31a7a960e90a"
    ]
  },
  "239": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 06, 1971",
    "EXP": "11",
    "SCHOOL": "UCLA",
    "name": "Darrick Martin",
    "rosters": [
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c"
    ]
  },
  "240": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "193",
    "BIRTH_DATE": "JUL 19, 1970",
    "EXP": "1",
    "SCHOOL": "Oklahoma State",
    "name": "Brooks Thompson",
    "rosters": [
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10"
    ]
  },
  "241": {
    "NUM": "4",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "207",
    "BIRTH_DATE": "NOV 03, 1971",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Darrin Hancock",
    "rosters": [
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291",
      "9c894a5d-df1a-404c-b058-78d127394ba6"
    ]
  },
  "243": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "209",
    "BIRTH_DATE": "OCT 02, 1972",
    "EXP": "2",
    "SCHOOL": "Temple",
    "name": "Aaron McKie",
    "rosters": [
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "3eff7ebb-bedf-4522-b837-8677e8944c50",
      "f03a4206-d842-4a99-8713-66ae264320ff"
    ]
  },
  "244": {
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 29, 1968",
    "EXP": "9",
    "SCHOOL": "Jacksonville",
    "name": "Dee Brown",
    "rosters": [
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae"
    ],
    "NUM": "7"
  },
  "246": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUN 22, 1964",
    "EXP": "5",
    "SCHOOL": "Houston",
    "name": "Greg Anderson",
    "rosters": [
      "a859ae74-acea-4f80-abaa-92f971001064",
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "247": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 24, 1963",
    "EXP": "13",
    "SCHOOL": "McNeese State",
    "name": "Joe Dumars",
    "rosters": [
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c",
      "db85dc84-6de1-4038-8c49-8f636dd20715",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc",
      "09154feb-3670-47a9-a0ed-55f55f2002b1"
    ]
  },
  "248": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "228",
    "BIRTH_DATE": "SEP 03, 1970",
    "EXP": "8",
    "SCHOOL": "North Carolina",
    "name": "George Lynch",
    "rosters": [
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "251": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 02, 1968",
    "EXP": "4",
    "SCHOOL": "Arizona",
    "name": "Sean Elliott",
    "rosters": [
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "252": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "256",
    "BIRTH_DATE": "JUL 24, 1963",
    "EXP": "17",
    "SCHOOL": "Louisiana Tech",
    "name": "Karl Malone",
    "rosters": [
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "eecb8d44-e66b-417f-bd80-36f97052a98a",
      "955486b2-508c-4776-884b-e521d5730bf8",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b"
    ]
  },
  "255": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 05, 1972",
    "EXP": "5",
    "SCHOOL": "Duke",
    "name": "Grant Hill",
    "rosters": [
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc",
      "09154feb-3670-47a9-a0ed-55f55f2002b1",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b"
    ]
  },
  "258": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "254",
    "BIRTH_DATE": "MAR 05, 1972",
    "EXP": "2",
    "SCHOOL": "Xavier",
    "name": "Brian Grant",
    "rosters": [
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693"
    ]
  },
  "262": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "224",
    "BIRTH_DATE": "JAN 28, 1962",
    "EXP": "9",
    "SCHOOL": "San Diego State",
    "name": "Michael Cage",
    "rosters": [
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "9e3f5154-3fa3-44ca-a44f-98320c951308",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655"
    ]
  },
  "265": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 04, 1965",
    "EXP": "9",
    "SCHOOL": "Oklahoma",
    "name": "Harvey Grant",
    "rosters": [
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "3eff7ebb-bedf-4522-b837-8677e8944c50",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58",
      "44e38364-f6e8-4d07-bb01-e7f82356367c",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f",
      "3a362135-e048-4453-af47-87555fdc1775"
    ]
  },
  "270": {
    "NUM": "54",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 04, 1965",
    "EXP": "12",
    "SCHOOL": "Clemson",
    "name": "Horace Grant",
    "rosters": [
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "4b67a5bb-ca70-47aa-9934-95aef577269a",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b"
    ]
  },
  "271": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 28, 1963",
    "EXP": "6",
    "SCHOOL": "Richmond",
    "name": "Johnny Newman",
    "rosters": [
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "20b624e6-187e-4570-9266-08913829ee83",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "d0523481-86c7-447a-b0d9-81625e9e333c",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170",
      "3e72397e-e399-4a1e-bd06-a9b455547453"
    ]
  },
  "273": {
    "NUM": "27",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 28, 1965",
    "EXP": "8",
    "SCHOOL": "Georgia Tech",
    "name": "Duane Ferrell",
    "rosters": [
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f"
    ]
  },
  "275": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 20, 1971",
    "EXP": "2",
    "SCHOOL": "Tennessee",
    "name": "Allan Houston",
    "rosters": [
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff"
    ]
  },
  "278": {
    "NUM": "2",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "213",
    "BIRTH_DATE": "AUG 01, 1968",
    "EXP": "10",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Stacey Augmon",
    "rosters": [
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "a859ae74-acea-4f80-abaa-92f971001064",
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "279": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 03, 1957",
    "EXP": "10",
    "SCHOOL": "Albany State (GA)",
    "name": "Charles Jones",
    "rosters": [
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "355ade3f-1412-4279-a7f6-512d2936bf86",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58",
      "44e38364-f6e8-4d07-bb01-e7f82356367c",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "280": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "265",
    "BIRTH_DATE": "JAN 05, 1968",
    "EXP": "5",
    "SCHOOL": "Louisville",
    "name": "Felton Spencer",
    "rosters": [
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8"
    ]
  },
  "281": {
    "NUM": "47",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "MAR 21, 1968",
    "EXP": "12",
    "SCHOOL": "North Carolina",
    "name": "Scott Williams",
    "rosters": [
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "9e3f5154-3fa3-44ca-a44f-98320c951308",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558"
    ]
  },
  "283": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 03, 1970",
    "EXP": "14",
    "SCHOOL": "Jackson State",
    "name": "Lindsey Hunter",
    "rosters": [
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc",
      "09154feb-3670-47a9-a0ed-55f55f2002b1",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "532f6daa-fa7a-422a-9326-f0c577396c95",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21"
    ]
  },
  "285": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "5-7",
    "WEIGHT": "140",
    "BIRTH_DATE": "AUG 29, 1966",
    "EXP": "R",
    "SCHOOL": "College of New Jersey",
    "name": "Greg Grant",
    "rosters": [
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8"
    ]
  },
  "287": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "265",
    "BIRTH_DATE": "MAY 27, 1966",
    "EXP": "3",
    "SCHOOL": "Wyoming",
    "name": "Eric Leckner",
    "rosters": [
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "eecb8d44-e66b-417f-bd80-36f97052a98a",
      "955486b2-508c-4776-884b-e521d5730bf8",
      "b884431e-1319-4251-a977-cdd919c3468e",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170"
    ]
  },
  "288": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 28, 1964",
    "EXP": "2",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Armen Gilliam",
    "rosters": [
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "289": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "231",
    "BIRTH_DATE": "NOV 24, 1966",
    "EXP": "6",
    "SCHOOL": "New Orleans",
    "name": "Ledell Eackles",
    "rosters": [
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58",
      "44e38364-f6e8-4d07-bb01-e7f82356367c",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "3a362135-e048-4453-af47-87555fdc1775"
    ]
  },
  "291": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 27, 1963",
    "EXP": "2",
    "SCHOOL": "Villanova",
    "name": "Ed Pinckney",
    "rosters": [
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "20b624e6-187e-4570-9266-08913829ee83",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "292": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "255",
    "BIRTH_DATE": "OCT 22, 1971",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Anthony Miller",
    "rosters": [
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff"
    ]
  },
  "293": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "244",
    "BIRTH_DATE": "JUL 16, 1965",
    "EXP": "8",
    "SCHOOL": "Pittsburgh",
    "name": "Charles Smith",
    "rosters": [
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be"
    ]
  },
  "296": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 24, 1969",
    "EXP": "12",
    "SCHOOL": "North Carolina",
    "name": "Rick Fox",
    "rosters": [
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8"
    ]
  },
  "297": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "262",
    "BIRTH_DATE": "FEB 08, 1970",
    "EXP": "2",
    "SCHOOL": "Georgetown",
    "name": "Alonzo Mourning",
    "rosters": [
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "3e72397e-e399-4a1e-bd06-a9b455547453",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291"
    ]
  },
  "299": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 10, 1973",
    "EXP": "10",
    "SCHOOL": "Purdue",
    "name": "Glenn Robinson",
    "rosters": [
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "20b624e6-187e-4570-9266-08913829ee83",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "58289a19-dade-4b36-bd1f-10ba99958a24"
    ]
  },
  "302": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 20, 1967",
    "EXP": "2",
    "SCHOOL": "Oklahoma",
    "name": "Mookie Blaylock",
    "rosters": [
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "a859ae74-acea-4f80-abaa-92f971001064",
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "2f7ada02-daaa-431d-b4e1-61d409829b71",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f"
    ]
  },
  "304": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 26, 1962",
    "EXP": "18",
    "SCHOOL": "Gonzaga",
    "name": "John Stockton",
    "rosters": [
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "eecb8d44-e66b-417f-bd80-36f97052a98a",
      "955486b2-508c-4776-884b-e521d5730bf8",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b"
    ]
  },
  "305": {
    "NUM": "00",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 30, 1953",
    "EXP": "19",
    "SCHOOL": "Centenary (LA)",
    "name": "Robert Parish",
    "rosters": [
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444",
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291",
      "9c894a5d-df1a-404c-b058-78d127394ba6"
    ]
  },
  "306": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "165",
    "BIRTH_DATE": "JUL 24, 1962",
    "EXP": "5",
    "SCHOOL": "New Mexico State",
    "name": "Steve Colter",
    "rosters": [
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2",
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58",
      "44e38364-f6e8-4d07-bb01-e7f82356367c"
    ]
  },
  "308": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 20, 1972",
    "EXP": "1",
    "SCHOOL": "Duquesne",
    "name": "Derrick Alston",
    "rosters": [
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62"
    ]
  },
  "310": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "165",
    "BIRTH_DATE": "JUN 28, 1971",
    "EXP": "4",
    "SCHOOL": "Duke",
    "name": "Bobby Hurley",
    "rosters": [
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792"
    ]
  },
  "313": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 04, 1962",
    "EXP": "10",
    "SCHOOL": "Georgia",
    "name": "Vern Fleming",
    "rosters": [
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca"
    ]
  },
  "316": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "253",
    "BIRTH_DATE": "JUN 23, 1970",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Richard Manning",
    "rosters": [
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119"
    ]
  },
  "317": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 10, 1965",
    "EXP": "12",
    "SCHOOL": "Oklahoma State",
    "name": "John Starks",
    "rosters": [
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28"
    ]
  },
  "320": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 23, 1972",
    "EXP": "1",
    "SCHOOL": "South Carolina",
    "name": "Jamie Watson",
    "rosters": [
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8"
    ]
  },
  "321": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 19, 1970",
    "EXP": "R",
    "SCHOOL": "Oregon State",
    "name": "Scott Haskin",
    "rosters": [
      "044dce90-7443-4814-8454-a974b791ec76"
    ]
  },
  "323": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 03, 1968",
    "EXP": "1",
    "SCHOOL": "Texas-El Paso",
    "name": "Greg Foster",
    "rosters": [
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1"
    ]
  },
  "324": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "208",
    "BIRTH_DATE": "NOV 25, 1969",
    "EXP": "12",
    "SCHOOL": "Missouri",
    "name": "Anthony Peeler",
    "rosters": [
      "3979ce94-914f-45be-ba16-62480c3df040",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "c9048bdc-2836-4609-9734-21853a995553",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "b884431e-1319-4251-a977-cdd919c3468e",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12"
    ]
  },
  "328": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "SEP 21, 1958",
    "EXP": "16",
    "SCHOOL": "Hampton",
    "name": "Rick Mahorn",
    "rosters": [
      "45b3a59c-7578-494d-9525-3352a060f871",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "a52d7bc3-ed81-4836-b70b-85c958af2548",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc"
    ]
  },
  "330": {
    "NUM": "15",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "244",
    "BIRTH_DATE": "MAY 17, 1966",
    "EXP": "12",
    "SCHOOL": "Kansas",
    "name": "Danny Manning",
    "rosters": [
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f"
    ]
  },
  "333": {
    "NUM": "40",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 13, 1965",
    "EXP": "8",
    "SCHOOL": "Iowa",
    "name": "Kevin Gamble",
    "rosters": [
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed"
    ]
  },
  "335": {
    "NUM": "40",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 08, 1967",
    "EXP": "6",
    "SCHOOL": "Georgia",
    "name": "Willie Anderson",
    "rosters": [
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "d6428cec-3758-4908-ac44-81c67877df4e"
    ]
  },
  "339": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "DEC 19, 1969",
    "EXP": "1",
    "SCHOOL": "North Carolina State",
    "name": "Tom Gugliotta",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69",
      "426fcece-7698-41e8-8ebb-254a273fe06b",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7"
    ]
  },
  "340": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 17, 1967",
    "EXP": "3",
    "SCHOOL": "Kansas",
    "name": "Kevin Pritchard",
    "rosters": [
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69"
    ]
  },
  "341": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 17, 1964",
    "EXP": "6",
    "SCHOOL": "North Carolina",
    "name": "Joe Wolf",
    "rosters": [
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291"
    ]
  },
  "342": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 19, 1966",
    "EXP": "1",
    "SCHOOL": "Brigham Young",
    "name": "Andy Toolson",
    "rosters": [
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8"
    ]
  },
  "344": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "165",
    "BIRTH_DATE": "APR 13, 1967",
    "EXP": "12",
    "SCHOOL": "Boston College",
    "name": "Dana Barros",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "ab598beb-863a-4658-a955-99fa97cb8d08",
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "9359cd35-305f-4f57-847e-21ec13abdc68",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1"
    ]
  },
  "345": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 08, 1963",
    "EXP": "16",
    "SCHOOL": "Wisconsin-Stevens Point",
    "name": "Terry Porter",
    "rosters": [
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "c9048bdc-2836-4609-9734-21853a995553",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "1204693e-5234-4041-9acb-b8a3eea3566c"
    ]
  },
  "346": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 21, 1971",
    "EXP": "2",
    "SCHOOL": "Florida State",
    "name": "Doug Edwards",
    "rosters": [
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "a859ae74-acea-4f80-abaa-92f971001064",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119"
    ]
  },
  "348": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 15, 1970",
    "EXP": "7",
    "SCHOOL": "UCLA",
    "name": "Mitchell Butler",
    "rosters": [
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "559e5d80-d64b-4c62-9122-9669d7a168dd"
    ]
  },
  "349": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 01, 1965",
    "EXP": "15",
    "SCHOOL": "St. John's (NY)",
    "name": "Mark Jackson",
    "rosters": [
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b"
    ]
  },
  "351": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 29, 1967",
    "EXP": "5",
    "SCHOOL": "Oklahoma",
    "name": "Stacey King",
    "rosters": [
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090"
    ]
  },
  "353": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 22, 1968",
    "EXP": "12",
    "SCHOOL": "Fayetteville State",
    "name": "Darrell Armstrong",
    "rosters": [
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "4b67a5bb-ca70-47aa-9934-95aef577269a",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e"
    ]
  },
  "355": {
    "NUM": "2",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "235",
    "BIRTH_DATE": "FEB 28, 1966",
    "EXP": "6",
    "SCHOOL": "Memphis",
    "name": "Vincent Askew",
    "rosters": [
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b"
    ]
  },
  "356": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "228",
    "BIRTH_DATE": "JAN 20, 1966",
    "EXP": "9",
    "SCHOOL": "Auburn",
    "name": "Chris Morris",
    "rosters": [
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6"
    ]
  },
  "357": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "DEC 03, 1967",
    "EXP": "1",
    "SCHOOL": "Oral Roberts",
    "name": "Greg Sutton",
    "rosters": [
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291"
    ]
  },
  "358": {
    "NUM": "1",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 18, 1971",
    "EXP": "9",
    "SCHOOL": "Memphis",
    "name": "Anfernee Hardaway",
    "rosters": [
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "4b67a5bb-ca70-47aa-9934-95aef577269a",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "5cd70763-5c6d-404c-ae94-b800444a7144",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558"
    ]
  },
  "359": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 29, 1968",
    "EXP": "10",
    "SCHOOL": "North Carolina State",
    "name": "Chucky Brown",
    "rosters": [
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2",
      "c060723a-d5e6-4385-bc5e-59560b27d467"
    ]
  },
  "361": {
    "NUM": "30",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 16, 1966",
    "EXP": "13",
    "SCHOOL": "Connecticut",
    "name": "Clifford Robinson",
    "rosters": [
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69",
      "5cd70763-5c6d-404c-ae94-b800444a7144",
      "426fcece-7698-41e8-8ebb-254a273fe06b",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "3eff7ebb-bedf-4522-b837-8677e8944c50",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1",
      "6d018b74-602e-4da7-810d-8489c0aab560"
    ]
  },
  "363": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "AUG 17, 1969",
    "EXP": "7",
    "SCHOOL": "Duke",
    "name": "Christian Laettner",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "09154feb-3670-47a9-a0ed-55f55f2002b1",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b"
    ]
  },
  "364": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 05, 1967",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Rex Chapman",
    "rosters": [
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "440e1824-f688-481f-9c58-4951796e7ad1",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e"
    ]
  },
  "365": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 10, 1966",
    "EXP": "5",
    "SCHOOL": "Alabama",
    "name": "Derrick McKey",
    "rosters": [
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520"
    ]
  },
  "368": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "260",
    "BIRTH_DATE": "OCT 14, 1968",
    "EXP": "R",
    "SCHOOL": "Florida",
    "name": "Dwayne Schintzius",
    "rosters": [
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "c5fcd661-3f22-42bc-99bf-414a346732e2"
    ]
  },
  "369": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 12, 1970",
    "EXP": "9",
    "SCHOOL": "Florida State",
    "name": "Charlie Ward",
    "rosters": [
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae"
    ]
  },
  "371": {
    "NUM": "6",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "DEC 21, 1967",
    "EXP": "9",
    "SCHOOL": "Michigan",
    "name": "Terry Mills",
    "rosters": [
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b"
    ]
  },
  "375": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 12, 1971",
    "EXP": "5",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Isaiah Rider",
    "rosters": [
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b"
    ]
  },
  "376": {
    "NUM": "00",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "270",
    "BIRTH_DATE": "SEP 23, 1971",
    "EXP": "5",
    "SCHOOL": "North Carolina",
    "name": "Eric Montross",
    "rosters": [
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9",
      "09154feb-3670-47a9-a0ed-55f55f2002b1",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b"
    ]
  },
  "378": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 11, 1961",
    "EXP": "2",
    "SCHOOL": "Washington State",
    "name": "Craig Ehlo",
    "rosters": [
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "a859ae74-acea-4f80-abaa-92f971001064",
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64"
    ]
  },
  "380": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 14, 1968",
    "EXP": "6",
    "SCHOOL": "Marquette",
    "name": "Tony Smith",
    "rosters": [
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "3979ce94-914f-45be-ba16-62480c3df040",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b"
    ]
  },
  "381": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 31, 1970",
    "EXP": "2",
    "SCHOOL": "Alabama",
    "name": "James Robinson",
    "rosters": [
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "3eff7ebb-bedf-4522-b837-8677e8944c50"
    ]
  },
  "383": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "216",
    "BIRTH_DATE": "MAY 25, 1968",
    "EXP": "2",
    "SCHOOL": "Illinois",
    "name": "Kendall Gill",
    "rosters": [
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170",
      "3e72397e-e399-4a1e-bd06-a9b455547453"
    ]
  },
  "384": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 17, 1971",
    "EXP": "5",
    "SCHOOL": "Indiana",
    "name": "Calbert Cheaney",
    "rosters": [
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f",
      "3a362135-e048-4453-af47-87555fdc1775",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf"
    ]
  },
  "386": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "150",
    "BIRTH_DATE": "MAR 28, 1969",
    "EXP": "R",
    "SCHOOL": "Memphis",
    "name": "Elliot Perry",
    "rosters": [
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b",
      "426fcece-7698-41e8-8ebb-254a273fe06b",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170"
    ]
  },
  "389": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 18, 1968",
    "EXP": "6",
    "SCHOOL": null,
    "name": "Toni Kukoc",
    "rosters": [
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af"
    ]
  },
  "390": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 26, 1970",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Sasha Danilovic",
    "rosters": [
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4"
    ]
  },
  "393": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 11, 1966",
    "EXP": "10",
    "SCHOOL": "DePaul",
    "name": "Rod Strickland",
    "rosters": [
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "3eff7ebb-bedf-4522-b837-8677e8944c50",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f",
      "3a362135-e048-4453-af47-87555fdc1775",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf"
    ]
  },
  "397": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 24, 1965",
    "EXP": "17",
    "SCHOOL": "California-Los Angeles",
    "name": "Reggie Miller",
    "rosters": [
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27"
    ]
  },
  "399": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "SEP 09, 1969",
    "EXP": "11",
    "SCHOOL": "Arizona",
    "name": "Sean Rooks",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6"
    ]
  },
  "400": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 09, 1968",
    "EXP": "R",
    "SCHOOL": "Xavier",
    "name": "Derek Strong",
    "rosters": [
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "4b67a5bb-ca70-47aa-9934-95aef577269a",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1"
    ]
  },
  "402": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAY 17, 1963",
    "EXP": "10",
    "SCHOOL": "Southern Methodist",
    "name": "Jon Koncak",
    "rosters": [
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "a859ae74-acea-4f80-abaa-92f971001064",
      "1a590baf-faf7-46e2-a591-e9ff55372b10"
    ]
  },
  "404": {
    "NUM": "0",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "275",
    "BIRTH_DATE": "APR 01, 1964",
    "EXP": "8",
    "SCHOOL": "Eastern Illinois",
    "name": "Kevin Duckworth",
    "rosters": [
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa"
    ]
  },
  "406": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "325",
    "BIRTH_DATE": "MAR 06, 1972",
    "EXP": "16",
    "SCHOOL": "Louisiana State",
    "name": "Shaquille O'Neal",
    "rosters": [
      "88463408-f197-42d6-b955-433fadaa716d",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b"
    ]
  },
  "412": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "JAN 30, 1973",
    "EXP": "2",
    "SCHOOL": "Clemson",
    "name": "Sharone Wright",
    "rosters": [
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "dbff03be-c19e-4e69-9c80-539569f223da"
    ]
  },
  "416": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 26, 1968",
    "EXP": "4",
    "SCHOOL": "Minnesota",
    "name": "Willie Burton",
    "rosters": [
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1"
    ]
  },
  "417": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 02, 1963",
    "EXP": "5",
    "SCHOOL": "Mercer",
    "name": "Sam Mitchell",
    "rosters": [
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "c9048bdc-2836-4609-9734-21853a995553",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca"
    ]
  },
  "418": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "165",
    "BIRTH_DATE": "JUL 31, 1965",
    "EXP": "1",
    "SCHOOL": "California-Irvine",
    "name": "Scott Brooks",
    "rosters": [
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "5dccfcf4-5eac-4345-af96-969f8232576c"
    ]
  },
  "420": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 22, 1968",
    "EXP": "1",
    "SCHOOL": "St. John's (NY)",
    "name": "Jayson Williams",
    "rosters": [
      "45b3a59c-7578-494d-9525-3352a060f871",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2"
    ]
  },
  "422": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 25, 1965",
    "EXP": "1",
    "SCHOOL": "Southern",
    "name": "Avery Johnson",
    "rosters": [
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e"
    ]
  },
  "423": {
    "NUM": "15",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 03, 1967",
    "EXP": "6",
    "SCHOOL": "Old Dominion",
    "name": "Chris Gatling",
    "rosters": [
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "48bd29bb-6817-4388-af0d-0fb73d383119"
    ]
  },
  "426": {
    "NUM": "52",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 17, 1967",
    "EXP": "7",
    "SCHOOL": "Virginia Union",
    "name": "Terry Davis",
    "rosters": [
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "3a362135-e048-4453-af47-87555fdc1775"
    ]
  },
  "428": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 15, 1966",
    "EXP": "10",
    "SCHOOL": "Syracuse",
    "name": "Sherman Douglas",
    "rosters": [
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146"
    ]
  },
  "431": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "256",
    "BIRTH_DATE": "NOV 26, 1969",
    "EXP": "7",
    "SCHOOL": "Trinity Valley Community College",
    "name": "Shawn Kemp",
    "rosters": [
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "28c686d2-151c-45b0-b976-f1ca5ae19603"
    ]
  },
  "432": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 14, 1968",
    "EXP": "2",
    "SCHOOL": "North Carolina",
    "name": "Pete Chilcutt",
    "rosters": [
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "b884431e-1319-4251-a977-cdd919c3468e",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "433": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 08, 1960",
    "EXP": "14",
    "SCHOOL": "Maryland",
    "name": "Buck Williams",
    "rosters": [
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "3eff7ebb-bedf-4522-b837-8677e8944c50"
    ]
  },
  "434": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 25, 1972",
    "EXP": "1",
    "SCHOOL": "Missouri-Kansas City",
    "name": "Tony Dumas",
    "rosters": [
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00"
    ]
  },
  "435": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 06, 1971",
    "EXP": "2",
    "SCHOOL": "Tennessee State",
    "name": "Carlos Rogers",
    "rosters": [
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "4a934d85-87c5-4059-9963-b76f50593f0b",
      "dbff03be-c19e-4e69-9c80-539569f223da"
    ]
  },
  "436": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "253",
    "BIRTH_DATE": "FEB 07, 1973",
    "EXP": "14",
    "SCHOOL": "Michigan",
    "name": "Juwan Howard",
    "rosters": [
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f",
      "3a362135-e048-4453-af47-87555fdc1775",
      "0b7a4d32-1992-4984-b46d-503707641e87",
      "fc384a91-81b8-47cb-b999-2a497289f651"
    ]
  },
  "438": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 12, 1970",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Robert Werdann",
    "rosters": [
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0"
    ]
  },
  "440": {
    "NUM": "3",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 15, 1970",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Zan Tabak",
    "rosters": [
      "ab598beb-863a-4658-a955-99fa97cb8d08",
      "8cc5342c-8019-4957-bedc-7eced9f39f49",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "4a934d85-87c5-4059-9963-b76f50593f0b"
    ]
  },
  "441": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 20, 1973",
    "EXP": "9",
    "SCHOOL": "California",
    "name": "Lamond Murray",
    "rosters": [
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae"
    ]
  },
  "442": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 03, 1967",
    "EXP": "4",
    "SCHOOL": "Louisville",
    "name": "Pervis Ellison",
    "rosters": [
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7"
    ]
  },
  "445": {
    "NUM": "1",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 28, 1971",
    "EXP": "8",
    "SCHOOL": "Auburn",
    "name": "Wesley Person",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "69c4432f-a9df-4bdd-b947-ef61debf2424"
    ]
  },
  "446": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 18, 1970",
    "EXP": "10",
    "SCHOOL": "Long Beach State",
    "name": "Lucious Harris",
    "rosters": [
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "d0523481-86c7-447a-b0d9-81625e9e333c",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267"
    ]
  },
  "448": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 13, 1971",
    "EXP": "10",
    "SCHOOL": "Houston",
    "name": "Bo Outlaw",
    "rosters": [
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6"
    ]
  },
  "452": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 23, 1971",
    "EXP": "8",
    "SCHOOL": "Hartford",
    "name": "Vin Baker",
    "rosters": [
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "20b624e6-187e-4570-9266-08913829ee83",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40",
      "c04784ce-a740-496a-b8dd-354463d7169d"
    ]
  },
  "456": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 27, 1964",
    "EXP": "12",
    "SCHOOL": "Auburn",
    "name": "Chuck Person",
    "rosters": [
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2"
    ]
  },
  "457": {
    "NUM": "28",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 28, 1966",
    "EXP": "3",
    "SCHOOL": "Arkansas",
    "name": "Andrew Lang",
    "rosters": [
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "a859ae74-acea-4f80-abaa-92f971001064",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2"
    ]
  },
  "458": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 04, 1972",
    "EXP": "10",
    "SCHOOL": "Boston College",
    "name": "Howard Eisley",
    "rosters": [
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "28eacab0-70b6-464e-9364-355a0c9123d3"
    ]
  },
  "460": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 26, 1970",
    "EXP": "R",
    "SCHOOL": "Hawaii",
    "name": "Trevor Ruffin",
    "rosters": [
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90"
    ]
  },
  "461": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 17, 1970",
    "EXP": "8",
    "SCHOOL": "Murray State",
    "name": "Popeye Jones",
    "rosters": [
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "dbff03be-c19e-4e69-9c80-539569f223da",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f"
    ]
  },
  "462": {
    "NUM": "7",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "247",
    "BIRTH_DATE": "MAR 31, 1968",
    "EXP": "7",
    "SCHOOL": "North Carolina",
    "name": "J.R. Reid",
    "rosters": [
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "440e1824-f688-481f-9c58-4951796e7ad1",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170",
      "25f7d175-5991-48e6-8d9b-391b80096be6"
    ]
  },
  "467": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "212",
    "BIRTH_DATE": "MAR 23, 1973",
    "EXP": "6",
    "SCHOOL": "California",
    "name": "Jason Kidd",
    "rosters": [
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69",
      "5cd70763-5c6d-404c-ae94-b800444a7144",
      "426fcece-7698-41e8-8ebb-254a273fe06b"
    ]
  },
  "468": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 25, 1969",
    "EXP": "10",
    "SCHOOL": "Georgia Tech",
    "name": "Jon Barry",
    "rosters": [
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "20b624e6-187e-4570-9266-08913829ee83",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1",
      "6d018b74-602e-4da7-810d-8489c0aab560"
    ]
  },
  "469": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "247",
    "BIRTH_DATE": "NOV 29, 1972",
    "EXP": "8",
    "SCHOOL": "Kentucky",
    "name": "Jamal Mashburn",
    "rosters": [
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "470": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 13, 1961",
    "EXP": "12",
    "SCHOOL": "Marquette",
    "name": "Doc Rivers",
    "rosters": [
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2"
    ]
  },
  "474": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 10, 1968",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Stefano Rusconi",
    "rosters": [
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b"
    ]
  },
  "475": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 17, 1969",
    "EXP": "3",
    "SCHOOL": "Missouri",
    "name": "Doug Smith",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca"
    ]
  },
  "511": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 20, 1972",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Melvin Booker",
    "rosters": [
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674"
    ]
  },
  "671": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 02, 1973",
    "EXP": "R",
    "SCHOOL": "Colorado",
    "name": "Donnie Boyce",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b"
    ]
  },
  "672": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 05, 1967",
    "EXP": "9",
    "SCHOOL": "Iowa",
    "name": "Matt Bullard",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "e502556e-6647-4877-a1dc-fa3278e8fa26"
    ]
  },
  "673": {
    "NUM": "44",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "DEC 02, 1972",
    "EXP": "11",
    "SCHOOL": "Indiana",
    "name": "Alan Henderson",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "034cf132-3085-4071-860d-8f8cdea90094",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8"
    ]
  },
  "674": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 26, 1968",
    "EXP": "3",
    "SCHOOL": "New Mexico State",
    "name": "Reggie Jordan",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "c9048bdc-2836-4609-9734-21853a995553"
    ]
  },
  "675": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "242",
    "BIRTH_DATE": "JAN 18, 1973",
    "EXP": "R",
    "SCHOOL": "Virginia",
    "name": "Junior Burrough",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6"
    ]
  },
  "676": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "330",
    "BIRTH_DATE": "APR 03, 1975",
    "EXP": "2",
    "SCHOOL": "Pittsburgh",
    "name": "Thomas Hamilton",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "53d22344-d154-40b3-a170-e360f5ec1f92"
    ]
  },
  "677": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 17, 1972",
    "EXP": "9",
    "SCHOOL": "Providence",
    "name": "Eric Williams",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6",
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b",
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae"
    ]
  },
  "678": {
    "NUM": "25",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "272",
    "BIRTH_DATE": "AUG 02, 1973",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "George Zidek",
    "rosters": [
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "9c894a5d-df1a-404c-b058-78d127394ba6"
    ]
  },
  "679": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "256",
    "BIRTH_DATE": "JUN 12, 1973",
    "EXP": "7",
    "SCHOOL": "Alabama",
    "name": "Jason Caffey",
    "rosters": [
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "2f7ada02-daaa-431d-b4e1-61d409829b71",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed"
    ]
  },
  "680": {
    "NUM": "13",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "270",
    "BIRTH_DATE": "NOV 26, 1970",
    "EXP": "4",
    "SCHOOL": "Penn State",
    "name": "John Amaechi",
    "rosters": [
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b"
    ]
  },
  "681": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 17, 1972",
    "EXP": "4",
    "SCHOOL": "Connecticut",
    "name": "Donny Marshall",
    "rosters": [
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff"
    ]
  },
  "682": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 25, 1973",
    "EXP": "10",
    "SCHOOL": "Florida State",
    "name": "Bob Sura",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "4852e234-5b8e-4b03-b656-686bfe7506c0"
    ]
  },
  "683": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "OCT 26, 1965",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Darryl Johnson",
    "rosters": [
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb"
    ]
  },
  "684": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "DEC 30, 1972",
    "EXP": "1",
    "SCHOOL": "Iowa State",
    "name": "Loren Meyer",
    "rosters": [
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "78c68458-e60d-4462-a703-8501fdadf08e"
    ]
  },
  "685": {
    "NUM": "1",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 11, 1972",
    "EXP": "4",
    "SCHOOL": "Duke",
    "name": "Cherokee Parks",
    "rosters": [
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "c9048bdc-2836-4609-9734-21853a995553",
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7",
      "378d09ae-9325-475b-960c-5806574aab51"
    ]
  },
  "686": {
    "NUM": "24",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "SEP 07, 1974",
    "EXP": "12",
    "SCHOOL": "Alabama",
    "name": "Antonio McDyess",
    "rosters": [
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "532f6daa-fa7a-422a-9326-f0c577396c95",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1"
    ]
  },
  "687": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUN 22, 1970",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Rastko Cvetkovic",
    "rosters": [
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67"
    ]
  },
  "688": {
    "NUM": "12",
    "POSITION": "F-G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 22, 1968",
    "EXP": "8",
    "SCHOOL": "Georgia Southern",
    "name": "Michael Curry",
    "rosters": [
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1",
      "6d018b74-602e-4da7-810d-8489c0aab560"
    ]
  },
  "689": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 17, 1973",
    "EXP": "14",
    "SCHOOL": "Wyoming",
    "name": "Theo Ratliff",
    "rosters": [
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575"
    ]
  },
  "690": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "250",
    "BIRTH_DATE": "DEC 30, 1973",
    "EXP": "7",
    "SCHOOL": "Georgetown",
    "name": "Don Reid",
    "rosters": [
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "0b7a4d32-1992-4984-b46d-503707641e87",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc",
      "09154feb-3670-47a9-a0ed-55f55f2002b1",
      "6d018b74-602e-4da7-810d-8489c0aab560"
    ]
  },
  "691": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 14, 1972",
    "EXP": "R",
    "SCHOOL": "Massachusetts",
    "name": "Lou Roe",
    "rosters": [
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff"
    ]
  },
  "692": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "FEB 01, 1973",
    "EXP": "8",
    "SCHOOL": "Florida",
    "name": "Andrew DeClercq",
    "rosters": [
      "ab598beb-863a-4658-a955-99fa97cb8d08",
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6"
    ]
  },
  "693": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 26, 1975",
    "EXP": "5",
    "SCHOOL": "Maryland",
    "name": "Joe Smith",
    "rosters": [
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "9359cd35-305f-4f57-847e-21ec13abdc68"
    ]
  },
  "694": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 26, 1970",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "Sam Mack",
    "rosters": [
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90"
    ]
  },
  "695": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "192",
    "BIRTH_DATE": "DEC 14, 1967",
    "EXP": "6",
    "SCHOOL": "Washington",
    "name": "Eldridge Recasner",
    "rosters": [
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2",
      "c060723a-d5e6-4385-bc5e-59560b27d467",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf"
    ]
  },
  "696": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "184",
    "BIRTH_DATE": "JUL 12, 1972",
    "EXP": "5",
    "SCHOOL": "Georgia Tech",
    "name": "Travis Best",
    "rosters": [
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "d05863bb-8dce-49be-840f-100a7de3cc34"
    ]
  },
  "697": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "203",
    "BIRTH_DATE": "OCT 15, 1972",
    "EXP": "3",
    "SCHOOL": "Iowa State",
    "name": "Fred Hoiberg",
    "rosters": [
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299",
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e"
    ]
  },
  "698": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": " ",
    "BIRTH_DATE": "MAY 01, 1959",
    "EXP": "12",
    "SCHOOL": "Illinois",
    "name": "Eddie Johnson",
    "rosters": [
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e"
    ]
  },
  "699": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "203",
    "BIRTH_DATE": "DEC 31, 1971",
    "EXP": "8",
    "SCHOOL": "Oregon State",
    "name": "Brent Barry",
    "rosters": [
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40",
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4"
    ]
  },
  "700": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAY 15, 1970",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Keith Tower",
    "rosters": [
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14"
    ]
  },
  "701": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 06, 1972",
    "EXP": "1",
    "SCHOOL": "Western Carolina",
    "name": "Frankie King",
    "rosters": [
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "9e3f5154-3fa3-44ca-a44f-98320c951308"
    ]
  },
  "702": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 14, 1973",
    "EXP": "10",
    "SCHOOL": "Minnesota",
    "name": "Voshon Lenard",
    "rosters": [
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20"
    ]
  },
  "703": {
    "NUM": "40",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 04, 1972",
    "EXP": "13",
    "SCHOOL": "Texas Christian",
    "name": "Kurt Thomas",
    "rosters": [
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87"
    ]
  },
  "704": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 06, 1972",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Shawn Respert",
    "rosters": [
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "dbff03be-c19e-4e69-9c80-539569f223da"
    ]
  },
  "705": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 19, 1966",
    "EXP": "2",
    "SCHOOL": "Southern Mississippi",
    "name": "Randolph Keys",
    "rosters": [
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "440e1824-f688-481f-9c58-4951796e7ad1",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e"
    ]
  },
  "706": {
    "NUM": "53",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "184",
    "BIRTH_DATE": "JAN 28, 1973",
    "EXP": "R",
    "SCHOOL": "Pennsylvania",
    "name": "Jerome Allen",
    "rosters": [
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "e170cb31-d4ef-499a-8662-716298adebe7"
    ]
  },
  "707": {
    "NUM": "7",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 26, 1973",
    "EXP": "2",
    "SCHOOL": "Texas Tech",
    "name": "Mark Davis",
    "rosters": [
      "2f7ada02-daaa-431d-b4e1-61d409829b71",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "9e3f5154-3fa3-44ca-a44f-98320c951308",
      "135aed72-0bdb-4c31-818e-756fc1a923da"
    ]
  },
  "708": {
    "NUM": "2",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "253",
    "BIRTH_DATE": "MAY 19, 1976",
    "EXP": "18",
    "SCHOOL": "Farragut Career Academy",
    "name": "Kevin Garnett",
    "rosters": [
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "88463408-f197-42d6-b955-433fadaa716d",
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "c9048bdc-2836-4609-9734-21853a995553",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "3e4b502a-18e0-4f72-b057-966f0972f973",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "7842004f-64a2-41a8-8552-327d53b7a972"
    ]
  },
  "709": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "222",
    "BIRTH_DATE": "AUG 14, 1972",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Ed O'Bannon",
    "rosters": [
      "d8471143-7caa-497d-8696-5d0bb039d3c2"
    ]
  },
  "710": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 23, 1973",
    "EXP": "R",
    "SCHOOL": "Memphis",
    "name": "David Vaughn",
    "rosters": [
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "1a590baf-faf7-46e2-a591-e9ff55372b10"
    ]
  },
  "711": {
    "NUM": "42",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "218",
    "BIRTH_DATE": "NOV 05, 1974",
    "EXP": "6",
    "SCHOOL": "North Carolina",
    "name": "Jerry Stackhouse",
    "rosters": [
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "9e3f5154-3fa3-44ca-a44f-98320c951308",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc",
      "09154feb-3670-47a9-a0ed-55f55f2002b1",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b",
      "9359cd35-305f-4f57-847e-21ec13abdc68",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1"
    ]
  },
  "712": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "AUG 01, 1973",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Mario Bennett",
    "rosters": [
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b"
    ]
  },
  "713": {
    "NUM": "43",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "207",
    "BIRTH_DATE": "MAR 12, 1974",
    "EXP": "R",
    "SCHOOL": "Southern Illinois",
    "name": "Chris Carr",
    "rosters": [
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b"
    ]
  },
  "714": {
    "NUM": "4",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 06, 1973",
    "EXP": "13",
    "SCHOOL": "Wisconsin",
    "name": "Michael Finley",
    "rosters": [
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87"
    ]
  },
  "715": {
    "NUM": "51",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "253",
    "BIRTH_DATE": "OCT 28, 1971",
    "EXP": "1",
    "SCHOOL": "Boise State",
    "name": "John Coker",
    "rosters": [
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf"
    ]
  },
  "716": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 13, 1966",
    "EXP": "3",
    "SCHOOL": "Michigan",
    "name": "Rumeal Robinson",
    "rosters": [
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "3eff7ebb-bedf-4522-b837-8677e8944c50",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e"
    ]
  },
  "717": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "292",
    "BIRTH_DATE": "DEC 19, 1964",
    "EXP": "6",
    "SCHOOL": null,
    "name": "Arvydas Sabonis",
    "rosters": [
      "3eff7ebb-bedf-4522-b837-8677e8944c50",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "719d3baf-1086-49d1-8f27-82662eb959d1"
    ]
  },
  "718": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 22, 1974",
    "EXP": "2",
    "SCHOOL": "Ohio",
    "name": "Gary Trent",
    "rosters": [
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "3eff7ebb-bedf-4522-b837-8677e8944c50",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5"
    ]
  },
  "719": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "188",
    "BIRTH_DATE": "SEP 21, 1972",
    "EXP": "R",
    "SCHOOL": "Wake Forest",
    "name": "Randolph Childress",
    "rosters": [
      "3eff7ebb-bedf-4522-b837-8677e8944c50"
    ]
  },
  "721": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "152",
    "BIRTH_DATE": "FEB 14, 1973",
    "EXP": "1",
    "SCHOOL": "California-Los Angeles",
    "name": "Tyus Edney",
    "rosters": [
      "ab598beb-863a-4658-a955-99fa97cb8d08",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693"
    ]
  },
  "722": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "245",
    "BIRTH_DATE": "DEC 04, 1973",
    "EXP": "8",
    "SCHOOL": "Arkansas",
    "name": "Corliss Williamson",
    "rosters": [
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693",
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "9359cd35-305f-4f57-847e-21ec13abdc68",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1",
      "6d018b74-602e-4da7-810d-8489c0aab560",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f"
    ]
  },
  "723": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 26, 1972",
    "EXP": "R",
    "SCHOOL": "Arkansas",
    "name": "Clint McDaniel",
    "rosters": [
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed"
    ]
  },
  "724": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 22, 1973",
    "EXP": "6",
    "SCHOOL": "Virginia",
    "name": "Cory Alexander",
    "rosters": [
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52"
    ]
  },
  "725": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 12, 1970",
    "EXP": "1",
    "SCHOOL": "Pacific",
    "name": "Dell Demps",
    "rosters": [
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2"
    ]
  },
  "726": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 26, 1972",
    "EXP": "R",
    "SCHOOL": "Illinois-Chicago",
    "name": "Sherell Ford",
    "rosters": [
      "c67c6a76-8882-48ad-a5da-dcb477aa577b"
    ]
  },
  "727": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 24, 1973",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Eric Snow",
    "rosters": [
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "28c686d2-151c-45b0-b976-f1ca5ae19603"
    ]
  },
  "728": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 09, 1973",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Jimmy King",
    "rosters": [
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "4a934d85-87c5-4059-9963-b76f50593f0b"
    ]
  },
  "729": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 28, 1975",
    "EXP": "1",
    "SCHOOL": "Seward County Community College",
    "name": "Martin Lewis",
    "rosters": [
      "4a934d85-87c5-4059-9963-b76f50593f0b",
      "dbff03be-c19e-4e69-9c80-539569f223da"
    ]
  },
  "730": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 21, 1972",
    "EXP": "R",
    "SCHOOL": "Jackson State",
    "name": "Dwayne Whitfield",
    "rosters": [
      "4a934d85-87c5-4059-9963-b76f50593f0b"
    ]
  },
  "731": {
    "NUM": "00",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "280",
    "BIRTH_DATE": "MAR 06, 1973",
    "EXP": "8",
    "SCHOOL": "Kansas",
    "name": "Greg Ostertag",
    "rosters": [
      "8de840b0-bf96-42ee-9a11-49735c4c8b63",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460"
    ]
  },
  "732": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 23, 1971",
    "EXP": "R",
    "SCHOOL": "Southern Illinois",
    "name": "Ashraf Amaya",
    "rosters": [
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119"
    ]
  },
  "733": {
    "NUM": "40",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 23, 1971",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Cuonzo Martin",
    "rosters": [
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119"
    ]
  },
  "734": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 25, 1972",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Lawrence Moten",
    "rosters": [
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119",
      "b884431e-1319-4251-a977-cdd919c3468e"
    ]
  },
  "735": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "290",
    "BIRTH_DATE": "JUN 08, 1973",
    "EXP": "5",
    "SCHOOL": "Oklahoma State",
    "name": "Bryant Reeves",
    "rosters": [
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119",
      "b884431e-1319-4251-a977-cdd919c3468e",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792",
      "378d09ae-9325-475b-960c-5806574aab51",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad"
    ]
  },
  "736": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 24, 1969",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Cedric Lewis",
    "rosters": [
      "5e793d50-83f9-46e0-8a72-4097b1de6b69"
    ]
  },
  "737": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "244",
    "BIRTH_DATE": "APR 22, 1964",
    "EXP": "1",
    "SCHOOL": "Morehead State",
    "name": "Bob McCann",
    "rosters": [
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "db85dc84-6de1-4038-8c49-8f636dd20715"
    ]
  },
  "738": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 10, 1962",
    "EXP": "7",
    "SCHOOL": "California-Irvine",
    "name": "Bob Thornton",
    "rosters": [
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69"
    ]
  },
  "739": {
    "NUM": "30",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 17, 1974",
    "EXP": "13",
    "SCHOOL": "North Carolina",
    "name": "Rasheed Wallace",
    "rosters": [
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "532f6daa-fa7a-422a-9326-f0c577396c95",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1"
    ]
  },
  "753": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 22, 1968",
    "EXP": "3",
    "SCHOOL": "New Mexico State",
    "name": "Randy Brown",
    "rosters": [
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1"
    ]
  },
  "754": {
    "NUM": "19",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 14, 1970",
    "EXP": "6",
    "SCHOOL": "Ohio State",
    "name": "Jim Jackson",
    "rosters": [
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca",
      "39e32802-3fe0-492c-aed8-9d212f63dd00",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "f2364869-d15e-4237-be86-d502b6074d04",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b"
    ]
  },
  "757": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "174",
    "BIRTH_DATE": "SEP 03, 1973",
    "EXP": "11",
    "SCHOOL": "Arizona",
    "name": "Damon Stoudamire",
    "rosters": [
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "4a934d85-87c5-4059-9963-b76f50593f0b",
      "dbff03be-c19e-4e69-9c80-539569f223da",
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394"
    ]
  },
  "760": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 26, 1962",
    "EXP": "13",
    "SCHOOL": "Longwood",
    "name": "Jerome Kersey",
    "rosters": [
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82"
    ]
  },
  "761": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "245",
    "BIRTH_DATE": "SEP 10, 1969",
    "EXP": "5",
    "SCHOOL": "Georgia Tech",
    "name": "Matt Geiger",
    "rosters": [
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "9c894a5d-df1a-404c-b058-78d127394ba6",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b",
      "25f7d175-5991-48e6-8d9b-391b80096be6"
    ]
  },
  "762": {
    "NUM": "76",
    "POSITION": "C",
    "HEIGHT": "7-6",
    "WEIGHT": "248",
    "BIRTH_DATE": "MAR 22, 1972",
    "EXP": "1",
    "SCHOOL": "Brigham Young",
    "name": "Shawn Bradley",
    "rosters": [
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "d8471143-7caa-497d-8696-5d0bb039d3c2",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1"
    ]
  },
  "763": {
    "NUM": "44",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUL 31, 1967",
    "EXP": "10",
    "SCHOOL": "Maryland",
    "name": "Tony Massenburg",
    "rosters": [
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad",
      "721f24b6-955d-43a4-901d-da6918201f42",
      "69c4432f-a9df-4bdd-b947-ef61debf2424"
    ]
  },
  "764": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 06, 1965",
    "EXP": "13",
    "SCHOOL": "Navy",
    "name": "David Robinson",
    "rosters": [
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b"
    ]
  },
  "765": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 29, 1966",
    "EXP": "12",
    "SCHOOL": "Bradley",
    "name": "Hersey Hawkins",
    "rosters": [
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "28c686d2-151c-45b0-b976-f1ca5ae19603",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf"
    ]
  },
  "766": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 15, 1963",
    "EXP": "8",
    "SCHOOL": "Georgetown",
    "name": "David Wingate",
    "rosters": [
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "28c686d2-151c-45b0-b976-f1ca5ae19603",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "3e72397e-e399-4a1e-bd06-a9b455547453",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291"
    ]
  },
  "767": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 22, 1963",
    "EXP": "3",
    "SCHOOL": "South Alabama",
    "name": "Terry Catledge",
    "rosters": [
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58"
    ]
  },
  "768": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 23, 1970",
    "EXP": "2",
    "SCHOOL": "Iowa",
    "name": "Acie Earl",
    "rosters": [
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "4a934d85-87c5-4059-9963-b76f50593f0b"
    ]
  },
  "769": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 09, 1967",
    "EXP": "8",
    "SCHOOL": "Iowa",
    "name": "B.J. Armstrong",
    "rosters": [
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "25f7d175-5991-48e6-8d9b-391b80096be6"
    ]
  },
  "770": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "246",
    "BIRTH_DATE": "NOV 05, 1960",
    "EXP": "12",
    "SCHOOL": "Old Dominion",
    "name": "Mark West",
    "rosters": [
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff"
    ]
  },
  "771": {
    "NUM": "27",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "270",
    "BIRTH_DATE": "DEC 06, 1969",
    "EXP": "3",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Elmore Spencer",
    "rosters": [
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "3eff7ebb-bedf-4522-b837-8677e8944c50"
    ]
  },
  "777": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 12, 1970",
    "EXP": "2",
    "SCHOOL": "Kansas",
    "name": "Rex Walters",
    "rosters": [
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62"
    ]
  },
  "779": {
    "NUM": "41",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 28, 1967",
    "EXP": "8",
    "SCHOOL": "Michigan",
    "name": "Glen Rice",
    "rosters": [
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "9c894a5d-df1a-404c-b058-78d127394ba6",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b",
      "25f7d175-5991-48e6-8d9b-391b80096be6"
    ]
  },
  "780": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 22, 1969",
    "EXP": "2",
    "SCHOOL": "Oklahoma State",
    "name": "Byron Houston",
    "rosters": [
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed"
    ]
  },
  "781": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 29, 1965",
    "EXP": "10",
    "SCHOOL": "Vanderbilt",
    "name": "Will Perdue",
    "rosters": [
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "fabeef09-a907-4448-9caa-ee4c92dfffc2",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73"
    ]
  },
  "782": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 30, 1965",
    "EXP": "11",
    "SCHOOL": "Kansas State",
    "name": "Mitch Richmond",
    "rosters": [
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693",
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf",
      "0b7a4d32-1992-4984-b46d-503707641e87"
    ]
  },
  "783": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 14, 1959",
    "EXP": "9",
    "SCHOOL": "Penn State",
    "name": "Frank Brickowski",
    "rosters": [
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "0d39d553-7b93-4206-b525-d87f764e4d5d",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e"
    ]
  },
  "785": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 14, 1968",
    "EXP": "4",
    "SCHOOL": "Providence",
    "name": "Eric Murdock",
    "rosters": [
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "20b624e6-187e-4570-9266-08913829ee83",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119"
    ]
  },
  "786": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 11, 1963",
    "EXP": "9",
    "SCHOOL": "Tennessee-Chattanooga",
    "name": "Gerald Wilkins",
    "rosters": [
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "4b67a5bb-ca70-47aa-9934-95aef577269a",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119"
    ]
  },
  "787": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "252",
    "BIRTH_DATE": "FEB 20, 1963",
    "EXP": "11",
    "SCHOOL": "Auburn",
    "name": "Charles Barkley",
    "rosters": [
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b"
    ]
  },
  "788": {
    "NUM": "42",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "SEP 06, 1962",
    "EXP": "15",
    "SCHOOL": "Michigan State",
    "name": "Kevin Willis",
    "rosters": [
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae"
    ]
  },
  "891": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "DEC 18, 1963",
    "EXP": "15",
    "SCHOOL": "Virginia Union",
    "name": "Charles Oakley",
    "rosters": [
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae",
      "f7880972-72ca-4727-996c-8a7e93fda19e"
    ]
  },
  "892": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "5-7",
    "WEIGHT": "133",
    "BIRTH_DATE": "JUL 13, 1963",
    "EXP": "9",
    "SCHOOL": "North Carolina State",
    "name": "Spud Webb",
    "rosters": [
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "e170cb31-d4ef-499a-8662-716298adebe7",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1"
    ]
  },
  "893": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "216",
    "BIRTH_DATE": "FEB 17, 1963",
    "EXP": "14",
    "SCHOOL": "North Carolina",
    "name": "Michael Jordan",
    "rosters": [
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646"
    ]
  },
  "894": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 19, 1959",
    "EXP": "14",
    "SCHOOL": "Rice",
    "name": "Ricky Pierce",
    "rosters": [
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b"
    ]
  },
  "895": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 31, 1962",
    "EXP": "8",
    "SCHOOL": "DePaul",
    "name": "Tyrone Corbin",
    "rosters": [
      "a859ae74-acea-4f80-abaa-92f971001064",
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5"
    ]
  },
  "896": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 01, 1966",
    "EXP": "10",
    "SCHOOL": "Texas-El Paso",
    "name": "Tim Hardaway",
    "rosters": [
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd"
    ]
  },
  "897": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 04, 1965",
    "EXP": "3",
    "SCHOOL": "Temple",
    "name": "Tim Perry",
    "rosters": [
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2"
    ]
  },
  "898": {
    "NUM": "30",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "229",
    "BIRTH_DATE": "OCT 31, 1965",
    "EXP": "8",
    "SCHOOL": "East Carolina",
    "name": "Blue Edwards",
    "rosters": [
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "955486b2-508c-4776-884b-e521d5730bf8",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119",
      "b884431e-1319-4251-a977-cdd919c3468e",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792"
    ]
  },
  "899": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 15, 1964",
    "EXP": "9",
    "SCHOOL": "Georgia Tech",
    "name": "Mark Price",
    "rosters": [
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "5e793d50-83f9-46e0-8a72-4097b1de6b69"
    ]
  },
  "900": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 18, 1969",
    "EXP": "R",
    "SCHOOL": "North Carolina-Wilmington",
    "name": "Matt Fish",
    "rosters": [
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae"
    ]
  },
  "901": {
    "NUM": "52",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "248",
    "BIRTH_DATE": "AUG 05, 1962",
    "EXP": "16",
    "SCHOOL": "Providence",
    "name": "Otis Thorpe",
    "rosters": [
      "79d8e657-ce27-4052-9492-c9833808173f",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47",
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf"
    ]
  },
  "902": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "182",
    "BIRTH_DATE": "APR 22, 1968",
    "EXP": "13",
    "SCHOOL": "Virginia Tech",
    "name": "Bimbo Coles",
    "rosters": [
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6"
    ]
  },
  "903": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "262",
    "BIRTH_DATE": "JUL 12, 1969",
    "EXP": "1",
    "SCHOOL": "Louisiana State",
    "name": "Geert Hammink",
    "rosters": [
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14"
    ]
  },
  "904": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 30, 1963",
    "EXP": "14",
    "SCHOOL": "St. John's (NY)",
    "name": "Chris Mullin",
    "rosters": [
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2"
    ]
  },
  "905": {
    "NUM": "34",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "252",
    "BIRTH_DATE": "MAR 25, 1969",
    "EXP": "15",
    "SCHOOL": "Clemson",
    "name": "Dale Davis",
    "rosters": [
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "532f6daa-fa7a-422a-9326-f0c577396c95"
    ]
  },
  "906": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 23, 1966",
    "EXP": "1",
    "SCHOOL": "Oral Roberts",
    "name": "Haywoode Workman",
    "rosters": [
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "044dce90-7443-4814-8454-a974b791ec76",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "6eed6f6d-540f-4623-9701-f237a7a62728",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6"
    ]
  },
  "907": {
    "NUM": "14",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 01, 1970",
    "EXP": "5",
    "SCHOOL": "St. John's (NY)",
    "name": "Malik Sealy",
    "rosters": [
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "044dce90-7443-4814-8454-a974b791ec76",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc"
    ]
  },
  "911": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "DEC 21, 1967",
    "EXP": "2",
    "SCHOOL": "New Orleans",
    "name": "Ervin Johnson",
    "rosters": [
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed",
      "c67c6a76-8882-48ad-a5da-dcb477aa577b"
    ]
  },
  "912": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "241",
    "BIRTH_DATE": "JUL 22, 1964",
    "EXP": "5",
    "SCHOOL": "Syracuse",
    "name": "Rafael Addison",
    "rosters": [
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934",
      "9c894a5d-df1a-404c-b058-78d127394ba6",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b"
    ]
  },
  "913": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "251",
    "BIRTH_DATE": "MAR 14, 1969",
    "EXP": "4",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Larry Johnson",
    "rosters": [
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "9c446324-5f9e-4bc7-adfe-2949d87cc9d7",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170",
      "3e72397e-e399-4a1e-bd06-a9b455547453",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291",
      "9c894a5d-df1a-404c-b058-78d127394ba6"
    ]
  },
  "914": {
    "NUM": "53",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "290",
    "BIRTH_DATE": "FEB 07, 1970",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Stanley Roberts",
    "rosters": [
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "c9048bdc-2836-4609-9734-21853a995553",
      "8dabde25-68d6-46b8-af0a-488c49653a62"
    ]
  },
  "915": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUN 20, 1971",
    "EXP": "7",
    "SCHOOL": "Wake Forest",
    "name": "Rodney Rogers",
    "rosters": [
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "5cd70763-5c6d-404c-ae94-b800444a7144",
      "426fcece-7698-41e8-8ebb-254a273fe06b"
    ]
  },
  "916": {
    "NUM": "44",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "242",
    "BIRTH_DATE": "JAN 04, 1969",
    "EXP": "10",
    "SCHOOL": "Cincinnati",
    "name": "Corie Blount",
    "rosters": [
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "5cd70763-5c6d-404c-ae94-b800444a7144",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f"
    ]
  },
  "917": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 27, 1964",
    "EXP": "5",
    "SCHOOL": "California-Los Angeles",
    "name": "Jack Haley",
    "rosters": [
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "d6428cec-3758-4908-ac44-81c67877df4e"
    ]
  },
  "919": {
    "NUM": "35",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 27, 1968",
    "EXP": "10",
    "SCHOOL": "Michigan",
    "name": "Loy Vaught",
    "rosters": [
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae",
      "44875f13-0e20-4679-bbfd-0c88cc9ba953",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565",
      "09154feb-3670-47a9-a0ed-55f55f2002b1",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b"
    ]
  },
  "920": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 04, 1963",
    "EXP": "10",
    "SCHOOL": "Oregon State",
    "name": "A.C. Green",
    "rosters": [
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "3979ce94-914f-45be-ba16-62480c3df040",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "2fe4653c-2393-4ccf-9ffd-8aa87af3189b"
    ]
  },
  "921": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "OCT 19, 1965",
    "EXP": "7",
    "SCHOOL": "North Carolina",
    "name": "Brad Daugherty",
    "rosters": [
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d"
    ]
  },
  "922": {
    "NUM": "5",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "279",
    "BIRTH_DATE": "JUL 23, 1968",
    "EXP": "11",
    "SCHOOL": "Clemson",
    "name": "Elden Campbell",
    "rosters": [
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "3979ce94-914f-45be-ba16-62480c3df040",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2",
      "c060723a-d5e6-4385-bc5e-59560b27d467",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "923": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 18, 1973",
    "EXP": "7",
    "SCHOOL": "Connecticut",
    "name": "Donyell Marshall",
    "rosters": [
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "2f7ada02-daaa-431d-b4e1-61d409829b71",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28"
    ]
  },
  "924": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "182",
    "BIRTH_DATE": "SEP 06, 1971",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "Anthony Goldwire",
    "rosters": [
      "f6c05285-c034-4b22-912e-eb9157727aad",
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "9c894a5d-df1a-404c-b058-78d127394ba6"
    ]
  },
  "925": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 30, 1968",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Sean Higgins",
    "rosters": [
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "c5fcd661-3f22-42bc-99bf-414a346732e2"
    ]
  },
  "926": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 22, 1962",
    "EXP": "8",
    "SCHOOL": "Arkansas",
    "name": "Alvin Robertson",
    "rosters": [
      "ec353978-5363-474f-aac3-88a8126172d3",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "5c09ec26-5505-4508-98c5-db244798d9d1",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "4a934d85-87c5-4059-9963-b76f50593f0b",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "927": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 16, 1964",
    "EXP": "5",
    "SCHOOL": "Georgia Tech",
    "name": "John Salley",
    "rosters": [
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "edbdfda6-7b68-4ac6-889b-15601e808663",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c",
      "db85dc84-6de1-4038-8c49-8f636dd20715"
    ]
  },
  "928": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 20, 1972",
    "EXP": "R",
    "SCHOOL": "Georgetown",
    "name": "Robert Churchwell",
    "rosters": [
      "28e4506b-c071-48bb-9f41-64bf7ff44c90"
    ]
  },
  "929": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 28, 1965",
    "EXP": "2",
    "SCHOOL": "Tulsa",
    "name": "Tracy Moore",
    "rosters": [
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "930": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 23, 1964",
    "EXP": "7",
    "SCHOOL": "Old Dominion",
    "name": "Kenny Gattison",
    "rosters": [
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "aa19bf6b-dbe0-4212-9893-45bd3fa75119",
      "440e1824-f688-481f-9c58-4951796e7ad1",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170",
      "3e72397e-e399-4a1e-bd06-a9b455547453",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291"
    ]
  },
  "931": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 16, 1970",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "Don MacLean",
    "rosters": [
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa"
    ]
  },
  "932": {
    "NUM": "25",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "280",
    "BIRTH_DATE": "APR 06, 1970",
    "EXP": "2",
    "SCHOOL": "Arkansas",
    "name": "Oliver Miller",
    "rosters": [
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "5cd70763-5c6d-404c-ae94-b800444a7144",
      "4a934d85-87c5-4059-9963-b76f50593f0b",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934"
    ]
  },
  "934": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "270",
    "BIRTH_DATE": "JUN 21, 1967",
    "EXP": "10",
    "SCHOOL": "Syracuse",
    "name": "Derrick Coleman",
    "rosters": [
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "9e3f5154-3fa3-44ca-a44f-98320c951308",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2",
      "c060723a-d5e6-4385-bc5e-59560b27d467",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf"
    ]
  },
  "935": {
    "NUM": "2",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 31, 1970",
    "EXP": "9",
    "SCHOOL": "Long Beach State",
    "name": "Bryon Russell",
    "rosters": [
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd",
      "2dc3f7c6-7dae-4698-9a2d-5b616de948e8",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646"
    ]
  },
  "937": {
    "NUM": "33",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "228",
    "BIRTH_DATE": "SEP 25, 1965",
    "EXP": "15",
    "SCHOOL": "Central Arkansas",
    "name": "Scottie Pippen",
    "rosters": [
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "6bc52ba5-a306-4e12-aac5-fd81eab720a2",
      "7e6f9dcf-cf5e-4784-8376-dd4d3b0a88e7",
      "964e7d61-03f9-4a09-bc44-b746e8966b09",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "d057f1eb-687d-446c-aa7c-09ea489e7e39",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1"
    ]
  },
  "938": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "253",
    "BIRTH_DATE": "MAY 10, 1965",
    "EXP": "8",
    "SCHOOL": "Syracuse",
    "name": "Rony Seikaly",
    "rosters": [
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "28e4506b-c071-48bb-9f41-64bf7ff44c90",
      "652536cb-d53b-4de7-a147-555aacb21048",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4",
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "4b67a5bb-ca70-47aa-9934-95aef577269a"
    ]
  },
  "939": {
    "NUM": "20",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "192",
    "BIRTH_DATE": "SEP 15, 1963",
    "EXP": "7",
    "SCHOOL": "Arkansas-Little Rock",
    "name": "Pete Myers",
    "rosters": [
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "9c894a5d-df1a-404c-b058-78d127394ba6"
    ]
  },
  "940": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 03, 1957",
    "EXP": "9",
    "SCHOOL": "Detroit Mercy",
    "name": "Earl Cureton",
    "rosters": [
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "1a9e125c-e465-42e3-a800-c69234389252",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e"
    ]
  },
  "941": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "177",
    "BIRTH_DATE": "AUG 11, 1962",
    "EXP": "3",
    "SCHOOL": "Alabama",
    "name": "Ennis Whatley",
    "rosters": [
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "a859ae74-acea-4f80-abaa-92f971001064",
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "4567ce1a-2538-43ab-b37e-44c2913ab119"
    ]
  },
  "942": {
    "NUM": "51",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAY 17, 1970",
    "EXP": "R",
    "SCHOOL": "Delta State",
    "name": "Todd Mundt",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6"
    ]
  },
  "943": {
    "NUM": "9-33",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 27, 1972",
    "EXP": "R",
    "SCHOOL": "Jackson State",
    "name": "Ryan Lorthridge",
    "rosters": [
      "92f101b9-7456-437b-8d91-9d5618b0b994"
    ]
  },
  "944": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 26, 1970",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "Eric Anderson",
    "rosters": [
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c"
    ]
  },
  "947": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 07, 1975",
    "EXP": "12",
    "SCHOOL": "Georgetown",
    "name": "Allen Iverson",
    "rosters": [
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "9e3f5154-3fa3-44ca-a44f-98320c951308",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1"
    ]
  },
  "948": {
    "NUM": "21",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 22, 1974",
    "EXP": "1",
    "SCHOOL": "Massachusetts",
    "name": "Marcus Camby",
    "rosters": [
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "8e1bb40f-2c63-4904-9e81-67592b326bda",
      "dbff03be-c19e-4e69-9c80-539569f223da",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5"
    ]
  },
  "949": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 11, 1976",
    "EXP": "4",
    "SCHOOL": "California",
    "name": "Shareef Abdur-Rahim",
    "rosters": [
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "b884431e-1319-4251-a977-cdd919c3468e",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7",
      "378d09ae-9325-475b-960c-5806574aab51",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad"
    ]
  },
  "950": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 20, 1977",
    "EXP": "6",
    "SCHOOL": "Georgia Tech",
    "name": "Stephon Marbury",
    "rosters": [
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "c9048bdc-2836-4609-9734-21853a995553",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558"
    ]
  },
  "951": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 20, 1975",
    "EXP": "10",
    "SCHOOL": "Connecticut",
    "name": "Ray Allen",
    "rosters": [
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "88463408-f197-42d6-b955-433fadaa716d",
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "e1717c3e-2243-4b10-81ce-a08a3d07bef0",
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab"
    ]
  },
  "952": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "AUG 12, 1976",
    "EXP": "11",
    "SCHOOL": "Kentucky",
    "name": "Antoine Walker",
    "rosters": [
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b",
      "ab598beb-863a-4658-a955-99fa97cb8d08",
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6"
    ]
  },
  "953": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 04, 1975",
    "EXP": "9",
    "SCHOOL": "Memphis",
    "name": "Lorenzen Wright",
    "rosters": [
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "02141ffc-f48a-44c1-a9a2-9e7ddf471927",
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "721f24b6-955d-43a4-901d-da6918201f42",
      "69c4432f-a9df-4bdd-b947-ef61debf2424",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419",
      "b00a755a-14e3-4682-bed6-49df9136ad09"
    ]
  },
  "954": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 12, 1974",
    "EXP": "6",
    "SCHOOL": "Villanova",
    "name": "Kerry Kittles",
    "rosters": [
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267"
    ]
  },
  "955": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "260",
    "BIRTH_DATE": "FEB 25, 1976",
    "EXP": "4",
    "SCHOOL": "Louisville",
    "name": "Samaki Walker",
    "rosters": [
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1"
    ]
  },
  "956": {
    "NUM": "25",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "265",
    "BIRTH_DATE": "JUL 14, 1975",
    "EXP": "R",
    "SCHOOL": "Mississippi State",
    "name": "Erick Dampier",
    "rosters": [
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f"
    ]
  },
  "957": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUL 25, 1974",
    "EXP": "3",
    "SCHOOL": "North Carolina State",
    "name": "Todd Fuller",
    "rosters": [
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "c060723a-d5e6-4385-bc5e-59560b27d467"
    ]
  },
  "958": {
    "NUM": "9",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "285",
    "BIRTH_DATE": "MAR 21, 1975",
    "EXP": "8",
    "SCHOOL": "Wright State",
    "name": "Vitaly Potapenko",
    "rosters": [
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4"
    ]
  },
  "959": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "178",
    "BIRTH_DATE": "FEB 07, 1974",
    "EXP": "15",
    "SCHOOL": "Santa Clara",
    "name": "Steve Nash",
    "rosters": [
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "fa166c7c-372a-489f-b069-635a9196ea73"
    ]
  },
  "960": {
    "NUM": "00",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "189",
    "BIRTH_DATE": "JAN 28, 1974",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Tony Delk",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "0aa87910-76b7-4782-a081-a241102c4730",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "426fcece-7698-41e8-8ebb-254a273fe06b",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b"
    ]
  },
  "961": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 09, 1974",
    "EXP": "4",
    "SCHOOL": "Syracuse",
    "name": "John Wallace",
    "rosters": [
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "9359cd35-305f-4f57-847e-21ec13abdc68"
    ]
  },
  "962": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 01, 1974",
    "EXP": "8",
    "SCHOOL": "Kentucky",
    "name": "Walter McCarty",
    "rosters": [
      "ab598beb-863a-4658-a955-99fa97cb8d08",
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1"
    ]
  },
  "963": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 02, 1975",
    "EXP": "R",
    "SCHOOL": "Mississippi State",
    "name": "Dontae' Jones",
    "rosters": [
      "ab598beb-863a-4658-a955-99fa97cb8d08"
    ]
  },
  "964": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "AUG 19, 1973",
    "EXP": "R",
    "SCHOOL": "Alabama",
    "name": "Roy Rogers",
    "rosters": [
      "b884431e-1319-4251-a977-cdd919c3468e"
    ]
  },
  "965": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "211",
    "BIRTH_DATE": "AUG 09, 1974",
    "EXP": "10",
    "SCHOOL": "Arkansas-Little Rock",
    "name": "Derek Fisher",
    "rosters": [
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e"
    ]
  },
  "966": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "206",
    "BIRTH_DATE": "MAY 10, 1973",
    "EXP": "3",
    "SCHOOL": "Georgetown",
    "name": "Jerome Williams",
    "rosters": [
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9",
      "f03a4206-d842-4a99-8713-66ae264320ff",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc",
      "09154feb-3670-47a9-a0ed-55f55f2002b1",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b"
    ]
  },
  "967": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 13, 1973",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Brian Evans",
    "rosters": [
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "4b67a5bb-ca70-47aa-9934-95aef577269a"
    ]
  },
  "968": {
    "NUM": "30",
    "POSITION": "C",
    "HEIGHT": "7-4",
    "WEIGHT": "325",
    "BIRTH_DATE": "AUG 31, 1973",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Priest Lauderdale",
    "rosters": [
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83"
    ]
  },
  "969": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 13, 1974",
    "EXP": "6",
    "SCHOOL": "Connecticut",
    "name": "Travis Knight",
    "rosters": [
      "ab598beb-863a-4658-a955-99fa97cb8d08",
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "1fed5db0-b968-4bc2-b296-10632898c907"
    ]
  },
  "970": {
    "NUM": "24",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 31, 1974",
    "EXP": "11",
    "SCHOOL": "Georgetown",
    "name": "Othella Harrington",
    "rosters": [
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "378d09ae-9325-475b-960c-5806574aab51",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e",
      "a16e37d5-d174-4f55-bfdc-ba838caae024"
    ]
  },
  "971": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 23, 1974",
    "EXP": "1",
    "SCHOOL": "Washington State",
    "name": "Mark Hendrickson",
    "rosters": [
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "9e3f5154-3fa3-44ca-a44f-98320c951308",
      "3c6f5772-9b65-4def-8409-05c17bc09fef"
    ]
  },
  "976": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 22, 1974",
    "EXP": "9",
    "SCHOOL": "North Carolina",
    "name": "Jeff McInnis",
    "rosters": [
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e"
    ]
  },
  "977": {
    "NUM": "24",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "212",
    "BIRTH_DATE": "AUG 23, 1978",
    "EXP": "19",
    "SCHOOL": "Lower Merion",
    "name": "Kobe Bryant",
    "rosters": [
      "beeac2ab-2ad5-45cf-81d4-7804f85813de",
      "ba46beed-7438-4f93-97b2-4e91442f0a11",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed"
    ]
  },
  "978": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "229",
    "BIRTH_DATE": "JUN 09, 1977",
    "EXP": "6",
    "SCHOOL": null,
    "name": "Peja Stojakovic",
    "rosters": [
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63"
    ]
  },
  "979": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "226",
    "BIRTH_DATE": "OCT 13, 1978",
    "EXP": "3",
    "SCHOOL": "Eau Claire",
    "name": "Jermaine O'Neal",
    "rosters": [
      "88463408-f197-42d6-b955-433fadaa716d",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "2c29e8c1-e492-4a9a-b7aa-7de1c9d00582",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4"
    ]
  },
  "980": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUN 05, 1975",
    "EXP": "12",
    "SCHOOL": null,
    "name": "Zydrunas Ilgauskas",
    "rosters": [
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b"
    ]
  },
  "981": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "JAN 11, 1976",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Efthimios Rentzias",
    "rosters": [
      "8f3601f5-3c15-4767-80c3-9436658cb172"
    ]
  },
  "982": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 26, 1974",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Martin Muursepp",
    "rosters": [
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "473a3f11-84e1-44d9-ac95-2e57181d18a2"
    ]
  },
  "983": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "188",
    "BIRTH_DATE": "JUL 27, 1973",
    "EXP": "6",
    "SCHOOL": "West Florida",
    "name": "Moochie Norris",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "958e2e11-1460-4482-a252-e109fa13aef6"
    ]
  },
  "984": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "NOV 13, 1973",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Steve Hamer",
    "rosters": [
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b"
    ]
  },
  "986": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "DEC 19, 1973",
    "EXP": "R",
    "SCHOOL": "Mississippi Valley State",
    "name": "Marcus Mann",
    "rosters": [
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502",
      "0aa87910-76b7-4782-a081-a241102c4730"
    ]
  },
  "987": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 13, 1974",
    "EXP": "2",
    "SCHOOL": "Texas Tech",
    "name": "Jason Sasser",
    "rosters": [
      "792077d5-a2d6-410d-84fd-6271f14ffeca"
    ]
  },
  "988": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "209",
    "BIRTH_DATE": "APR 02, 1975",
    "EXP": "8",
    "SCHOOL": "Louisiana State",
    "name": "Randy Livingston",
    "rosters": [
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69",
      "5cd70763-5c6d-404c-ae94-b800444a7144",
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "28eacab0-70b6-464e-9364-355a0c9123d3"
    ]
  },
  "990": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 23, 1974",
    "EXP": "R",
    "SCHOOL": "Drexel",
    "name": "Malik Rose",
    "rosters": [
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "e0ac1aae-7503-4e71-9f1d-395edbcd104b"
    ]
  },
  "994": {
    "NUM": "50",
    "POSITION": "C-F",
    "HEIGHT": "6-8",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUL 03, 1974",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Jamie Feick",
    "rosters": [
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be"
    ]
  },
  "997": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 02, 1974",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Chris Robinson",
    "rosters": [
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "b884431e-1319-4251-a977-cdd919c3468e"
    ]
  },
  "998": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 11, 1972",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Mark Pope",
    "rosters": [
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "8b339a5a-034a-4b56-be5d-5c7a5b869c0f"
    ]
  },
  "1000": {
    "NUM": "40",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 31, 1973",
    "EXP": "2",
    "SCHOOL": "Georgia",
    "name": "Shandon Anderson",
    "rosters": [
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b"
    ]
  },
  "1002": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "187",
    "BIRTH_DATE": "AUG 31, 1973",
    "EXP": "R",
    "SCHOOL": "Arizona",
    "name": "Reggie Geary",
    "rosters": [
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b"
    ]
  },
  "1003": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "191",
    "BIRTH_DATE": "FEB 17, 1973",
    "EXP": "1",
    "SCHOOL": "Georgia Tech",
    "name": "Drew Barry",
    "rosters": [
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912"
    ]
  },
  "1005": {
    "NUM": "42",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 16, 1970",
    "EXP": "4",
    "SCHOOL": "Maryland",
    "name": "Walt Williams",
    "rosters": [
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1",
      "dbff03be-c19e-4e69-9c80-539569f223da"
    ]
  },
  "1006": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "242",
    "BIRTH_DATE": "FEB 16, 1958",
    "EXP": "6",
    "SCHOOL": "Ohio State",
    "name": "Herb Williams",
    "rosters": [
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "f069f784-df01-44a4-91d1-40aa38531c90",
      "536e3edb-a661-42a2-b457-b070521bb61c",
      "50e3d81f-1bce-4ff8-8547-7cc7832160f6",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "c24811b3-b1da-43ab-9ab2-9a68328f351c",
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3"
    ]
  },
  "1007": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "270",
    "BIRTH_DATE": "SEP 22, 1971",
    "EXP": "R",
    "SCHOOL": "Seton Hall",
    "name": "Luther Wright",
    "rosters": [
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5"
    ]
  },
  "1008": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "165",
    "BIRTH_DATE": "SEP 28, 1963",
    "EXP": "8",
    "SCHOOL": "Duke",
    "name": "Johnny Dawkins",
    "rosters": [
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934"
    ]
  },
  "1010": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 23, 1970",
    "EXP": "2",
    "SCHOOL": "La Salle",
    "name": "Randy Woods",
    "rosters": [
      "04ade0a1-b5eb-49bd-872d-e46e23abcb67",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae"
    ]
  },
  "1022": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAY 01, 1971",
    "EXP": "R",
    "SCHOOL": "Liberty",
    "name": "Julius Nwosu",
    "rosters": [
      "d6428cec-3758-4908-ac44-81c67877df4e"
    ]
  },
  "1023": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 27, 1968",
    "EXP": "3",
    "SCHOOL": "Delaware State",
    "name": "Emanual Davis",
    "rosters": [
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40"
    ]
  },
  "1024": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "285",
    "BIRTH_DATE": "JUL 17, 1973",
    "EXP": "2",
    "SCHOOL": "Grand Canyon",
    "name": "Horacio Llamas",
    "rosters": [
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646"
    ]
  },
  "1027": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 16, 1968",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "Trevor Wilson",
    "rosters": [
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1"
    ]
  },
  "1030": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 25, 1969",
    "EXP": "1",
    "SCHOOL": "Southern California",
    "name": "Duane Cooper",
    "rosters": [
      "3979ce94-914f-45be-ba16-62480c3df040",
      "30517724-5902-4ca6-b448-35847695fb4a"
    ]
  },
  "1032": {
    "NUM": "8",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 23, 1973",
    "EXP": "7",
    "SCHOOL": "Texas Tech",
    "name": "Darvin Ham",
    "rosters": [
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f",
      "3a362135-e048-4453-af47-87555fdc1775",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f",
      "15e8507c-358e-47f0-807e-4a609d0a4581"
    ]
  },
  "1034": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 28, 1969",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "LeRon Ellis",
    "rosters": [
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "aeed8845-6fcc-4e6f-bf4e-ef2cfc5794c4",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e"
    ]
  },
  "1035": {
    "NUM": "36",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 16, 1972",
    "EXP": "2",
    "SCHOOL": "St. John's (NY)",
    "name": "Shawnelle Scott",
    "rosters": [
      "a6a532fb-3cac-4383-8923-fdd53f964596",
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1"
    ]
  },
  "1036": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 07, 1970",
    "EXP": "1",
    "SCHOOL": "Georgia",
    "name": "Litterial Green",
    "rosters": [
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "8a1dd266-db70-441b-b5dd-519d06aedf50"
    ]
  },
  "1037": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "260",
    "BIRTH_DATE": "JAN 22, 1964",
    "EXP": "2",
    "SCHOOL": "Croatia",
    "name": "Stojko Vrankovic",
    "rosters": [
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3"
    ]
  },
  "1038": {
    "NUM": "29",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 02, 1973",
    "EXP": "R",
    "SCHOOL": "Long Beach State",
    "name": "Juaquin Hawkins",
    "rosters": [
      "1263d642-0f8f-47dd-b80b-8930a1814b1e"
    ]
  },
  "1041": {
    "NUM": "27",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 12, 1973",
    "EXP": "R",
    "SCHOOL": "Montevallo",
    "name": "Nate Driggers",
    "rosters": [
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b"
    ]
  },
  "1043": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "OCT 28, 1973",
    "EXP": "1",
    "SCHOOL": "Marquette",
    "name": "Amal McCaskill",
    "rosters": [
      "4b67a5bb-ca70-47aa-9934-95aef577269a",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "1204693e-5234-4041-9acb-b8a3eea3566c"
    ]
  },
  "1049": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 06, 1970",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Shane Heal",
    "rosters": [
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3"
    ]
  },
  "1051": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 27, 1966",
    "EXP": "4",
    "SCHOOL": "Indiana",
    "name": "Dean Garrett",
    "rosters": [
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "89160d0f-db62-4529-acb6-4226a6796989",
      "2e0f728f-b7d7-4406-ac63-4f7b11ccb7b3",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace"
    ]
  },
  "1052": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 16, 1973",
    "EXP": "1",
    "SCHOOL": "Western Michigan",
    "name": "Ben Handlogten",
    "rosters": [
      "28eacab0-70b6-464e-9364-355a0c9123d3"
    ]
  },
  "1054": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "238",
    "BIRTH_DATE": "OCT 28, 1969",
    "EXP": "3",
    "SCHOOL": "Maryland",
    "name": "Jerrod Mustaf",
    "rosters": [
      "efbce694-cabd-4657-b606-4479029ed83a",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "30517724-5902-4ca6-b448-35847695fb4a"
    ]
  },
  "1061": {
    "NUM": "51",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 25, 1974",
    "EXP": "1",
    "SCHOOL": "Temple",
    "name": "William Cunningham",
    "rosters": [
      "482b62f2-7d27-4986-a11b-04e7b24f96a8"
    ]
  },
  "1063": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 03, 1969",
    "EXP": "R",
    "SCHOOL": "Eastern Michigan",
    "name": "Carl Thomas",
    "rosters": [
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504"
    ]
  },
  "1064": {
    "NUM": "55",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 15, 1971",
    "EXP": "R",
    "SCHOOL": "Rice",
    "name": "Brent Scott",
    "rosters": [
      "d9fa9226-92c2-4798-93b6-dff7c00c0b8f"
    ]
  },
  "1065": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 25, 1973",
    "EXP": "4",
    "SCHOOL": "Nebraska",
    "name": "Erick Strickland",
    "rosters": [
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad"
    ]
  },
  "1067": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "232",
    "BIRTH_DATE": "FEB 19, 1971",
    "EXP": "R",
    "SCHOOL": "Oklahoma",
    "name": "Jeff Webster",
    "rosters": [
      "5e793d50-83f9-46e0-8a72-4097b1de6b69"
    ]
  },
  "1068": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 07, 1963",
    "EXP": "6",
    "SCHOOL": "Kansas",
    "name": "Greg Dreiling",
    "rosters": [
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000"
    ]
  },
  "1073": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "255",
    "BIRTH_DATE": "AUG 27, 1970",
    "EXP": "4",
    "SCHOOL": "Wyoming",
    "name": "Reggie Slater",
    "rosters": [
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "dbff03be-c19e-4e69-9c80-539569f223da",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5",
      "241e4430-9349-4627-901a-466c5486c0f8"
    ]
  },
  "1074": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 06, 1971",
    "EXP": "1",
    "SCHOOL": "Pennsylvania",
    "name": "Matt Maloney",
    "rosters": [
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299",
      "f4fda864-3f52-4244-b98b-9f40cdbfafc6",
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f"
    ]
  },
  "1077": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "224",
    "BIRTH_DATE": "DEC 12, 1972",
    "EXP": "R",
    "SCHOOL": "Arizona",
    "name": "Ray Owes",
    "rosters": [
      "3c0f756a-f6f9-4ef2-80d8-1cac30649502"
    ]
  },
  "1080": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 29, 1965",
    "EXP": "2",
    "SCHOOL": "St. Mary's (TX)",
    "name": "Henry James",
    "rosters": [
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f"
    ]
  },
  "1085": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 11, 1973",
    "EXP": "R",
    "SCHOOL": "Pennsylvania",
    "name": "Ira Bowman",
    "rosters": [
      "bd5de45d-9c1a-49ff-918d-c586a109c7af"
    ]
  },
  "1088": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 14, 1974",
    "EXP": "10",
    "SCHOOL": "South Florida",
    "name": "Chucky Atkins",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394",
      "9359cd35-305f-4f57-847e-21ec13abdc68",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1",
      "6d018b74-602e-4da7-810d-8489c0aab560",
      "b791032a-997d-47da-b33e-2d34d9e2cd22"
    ]
  },
  "1089": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "231",
    "BIRTH_DATE": "DEC 30, 1970",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Kevin Salvadori",
    "rosters": [
      "5f51079c-a258-4dd0-bbf6-7316c28a4693"
    ]
  },
  "1090": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 10, 1967",
    "EXP": "1",
    "SCHOOL": "Colorado State",
    "name": "Pat Durham",
    "rosters": [
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090"
    ]
  },
  "1091": {
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 31, 1962",
    "EXP": "3",
    "SCHOOL": "Canisius",
    "name": "Mike Smrek",
    "rosters": [
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a"
    ],
    "NUM": "52"
  },
  "1096": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "DEC 12, 1970",
    "EXP": "R",
    "SCHOOL": "Georgia Tech",
    "name": "Ivano Newbill",
    "rosters": [
      "d292b57f-9fae-4c5f-88d1-4ec7bb47e2f3",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934"
    ]
  },
  "1097": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 27, 1966",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Zarko Paspalj",
    "rosters": [
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8"
    ]
  },
  "1100": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 25, 1967",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Ian Lockhart",
    "rosters": [
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb"
    ]
  },
  "1101": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "260",
    "BIRTH_DATE": "APR 04, 1969",
    "EXP": "3",
    "SCHOOL": "Nebraska",
    "name": "Rich King",
    "rosters": [
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed"
    ]
  },
  "1106": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "7-0",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 25, 1969",
    "EXP": "3",
    "SCHOOL": "Temple",
    "name": "Donald Hodge",
    "rosters": [
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca"
    ]
  },
  "1108": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "244",
    "BIRTH_DATE": "JAN 03, 1970",
    "EXP": "1",
    "SCHOOL": "Nebraska",
    "name": "Tony Farmer",
    "rosters": [
      "2f7ada02-daaa-431d-b4e1-61d409829b71"
    ]
  },
  "1109": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 03, 1972",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "James Scott",
    "rosters": [
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209"
    ]
  },
  "1110": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 14, 1970",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Mark Strickland",
    "rosters": [
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "789b1203-8d4e-4907-af10-4cf24e1e21d2",
      "f48f5f46-8f24-47f6-8584-6fdcced3e2f0",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "d0523481-86c7-447a-b0d9-81625e9e333c",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca"
    ]
  },
  "1112": {
    "NUM": "6",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 10, 1974",
    "EXP": "15",
    "SCHOOL": "Virginia Union",
    "name": "Ben Wallace",
    "rosters": [
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f",
      "3a362135-e048-4453-af47-87555fdc1775",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf",
      "9359cd35-305f-4f57-847e-21ec13abdc68",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1",
      "6d018b74-602e-4da7-810d-8489c0aab560",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1"
    ]
  },
  "1114": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 27, 1967",
    "EXP": "6",
    "SCHOOL": "Georgetown",
    "name": "Jaren Jackson",
    "rosters": [
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "6c851fc6-72f6-46a0-9760-a9d43dd79674",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2",
      "34728380-7c3f-4b0d-afce-5b4eb9af171b",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "18aabc1b-08aa-48fe-8323-6d9ca97b248f"
    ]
  },
  "1116": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 11, 1967",
    "EXP": "R",
    "SCHOOL": "Minnesota",
    "name": "Melvin Newbern",
    "rosters": [
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "1117": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 31, 1964",
    "EXP": "R",
    "SCHOOL": "Minnesota",
    "name": "John Shasky",
    "rosters": [
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "652536cb-d53b-4de7-a147-555aacb21048"
    ]
  },
  "1118": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 25, 1964",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Tim Kempton",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3"
    ]
  },
  "1120": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 13, 1970",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Elmer Bennett",
    "rosters": [
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62"
    ]
  },
  "1122": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 12, 1960",
    "EXP": "13",
    "SCHOOL": "Georgia",
    "name": "Dominique Wilkins",
    "rosters": [
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "c1c3a2ed-15e5-4d31-8d78-09dde994a4be"
    ]
  },
  "1123": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 05, 1961",
    "EXP": "5",
    "SCHOOL": "Brigham Young",
    "name": "Greg Kite",
    "rosters": [
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3"
    ]
  },
  "1125": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 21, 1968",
    "EXP": "3",
    "SCHOOL": "Coppin State",
    "name": "Larry Stewart",
    "rosters": [
      "28c686d2-151c-45b0-b976-f1ca5ae19603",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa"
    ]
  },
  "1127": {
    "NUM": "51",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "265",
    "BIRTH_DATE": "DEC 13, 1970",
    "EXP": "R",
    "SCHOOL": "Georgia",
    "name": "Charles Claxton",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6"
    ]
  },
  "1128": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 15, 1970",
    "EXP": "2",
    "SCHOOL": "DePaul",
    "name": "Stephen Howard",
    "rosters": [
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "c4f3c460-0922-4bda-b7fc-59077cd6815e"
    ]
  },
  "1132": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "255",
    "BIRTH_DATE": "APR 11, 1973",
    "EXP": "R",
    "SCHOOL": "Xavier",
    "name": "Larry Sykes",
    "rosters": [
      "20f34d15-ed9b-4828-98e7-1ef7044902e6"
    ]
  },
  "1133": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 27, 1971",
    "EXP": "2",
    "SCHOOL": "Arkansas",
    "name": "Corey Beck",
    "rosters": [
      "9c894a5d-df1a-404c-b058-78d127394ba6",
      "25f7d175-5991-48e6-8d9b-391b80096be6",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2"
    ]
  },
  "1134": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "270",
    "BIRTH_DATE": "AUG 18, 1969",
    "EXP": "5",
    "SCHOOL": "Arizona State",
    "name": "Ike Austin",
    "rosters": [
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "2c689fdc-403a-46a2-bd96-1f8f4ddcd209",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad",
      "0b7a4d32-1992-4984-b46d-503707641e87"
    ]
  },
  "1136": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 01, 1968",
    "EXP": "R",
    "SCHOOL": "Augustana (SD)",
    "name": "Brett Szabo",
    "rosters": [
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b"
    ]
  },
  "1137": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 11, 1970",
    "EXP": "R",
    "SCHOOL": "Ohio State",
    "name": "Chris Jent",
    "rosters": [
      "a98c4621-6e20-4474-b8b9-b92e12d377b0"
    ]
  },
  "1138": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "178",
    "BIRTH_DATE": "OCT 28, 1972",
    "EXP": "1",
    "SCHOOL": "Xavier",
    "name": "Michael Hawkins",
    "rosters": [
      "6df860a0-dbc6-4c6f-baa2-d0da49a9c72b",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740"
    ]
  },
  "1361": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "265",
    "BIRTH_DATE": "SEP 27, 1968",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Mark Bradtke",
    "rosters": [
      "9e3f5154-3fa3-44ca-a44f-98320c951308"
    ]
  },
  "1365": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 04, 1963",
    "EXP": "4",
    "SCHOOL": "Wichita State",
    "name": "Xavier McDaniel",
    "rosters": [
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "dd957ea2-38de-446a-a5a7-2822ee255cb0",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "0d39d553-7b93-4206-b525-d87f764e4d5d",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e"
    ]
  },
  "1380": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 04, 1967",
    "EXP": "1",
    "SCHOOL": "Mt. San Antonio",
    "name": "Lloyd Daniels",
    "rosters": [
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388"
    ]
  },
  "1381": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 28, 1956",
    "EXP": "12",
    "SCHOOL": "Detroit Mercy",
    "name": "John Long",
    "rosters": [
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "dbff03be-c19e-4e69-9c80-539569f223da",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b",
      "c163d65f-d6fd-4a44-968a-2897543255d4",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "73a256d9-f328-424c-a259-734aa5e88c4c"
    ]
  },
  "1425": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 02, 1971",
    "EXP": "5",
    "SCHOOL": "Xavier",
    "name": "Aaron Williams",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "20b624e6-187e-4570-9266-08913829ee83",
      "d0523481-86c7-447a-b0d9-81625e9e333c",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "9329091c-5340-456a-ba55-bcdfcd2a8a82",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "b884431e-1319-4251-a977-cdd919c3468e",
      "0b7a4d32-1992-4984-b46d-503707641e87"
    ]
  },
  "1429": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 12, 1969",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Jimmy Oliver",
    "rosters": [
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "56805152-7f69-4038-9e73-74d65c84c8ba"
    ]
  },
  "1432": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 17, 1969",
    "EXP": "1",
    "SCHOOL": "Southern Mississippi",
    "name": "Joe Courtney",
    "rosters": [
      "17cb61c9-b5a6-49b6-9ee6-d4fab3cc88eb",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "c414034d-9405-454b-81a1-00b89efc08b5"
    ]
  },
  "1434": {
    "NUM": "12",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "270",
    "BIRTH_DATE": "MAY 09, 1973",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Dragan Tarlac",
    "rosters": [
      "67e5e245-e136-4b67-ba4d-f41d5ab61011"
    ]
  },
  "1442": {
    "NUM": "39",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "257",
    "BIRTH_DATE": "APR 09, 1972",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Zeljko Rebraca",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1",
      "6d018b74-602e-4da7-810d-8489c0aab560"
    ]
  },
  "1444": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 15, 1970",
    "EXP": "5",
    "SCHOOL": "Ohio State",
    "name": "Lawrence Funderburke",
    "rosters": [
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca"
    ]
  },
  "1449": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 07, 1956",
    "EXP": "12",
    "SCHOOL": "Indiana State",
    "name": "Larry Bird",
    "rosters": [
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "d830473c-10ae-439b-a8f4-bb837688cbf8"
    ]
  },
  "1450": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 19, 1957",
    "EXP": "12",
    "SCHOOL": "Minnesota",
    "name": "Kevin McHale",
    "rosters": [
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2"
    ]
  },
  "1453": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "193",
    "BIRTH_DATE": "SEP 09, 1954",
    "EXP": "13",
    "SCHOOL": "North Carolina",
    "name": "Walter Davis",
    "rosters": [
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "e8500690-7102-4acf-ae8b-f1907cf60bcf",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed"
    ]
  },
  "1456": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 28, 1959",
    "EXP": "8",
    "SCHOOL": "Indiana",
    "name": "Randy Wittman",
    "rosters": [
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98"
    ]
  },
  "1460": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 27, 1961",
    "EXP": "11",
    "SCHOOL": "North Carolina",
    "name": "James Worthy",
    "rosters": [
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "3979ce94-914f-45be-ba16-62480c3df040",
      "761250ad-879b-4092-a761-56abea7f36ad"
    ]
  },
  "1472": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 21, 1959",
    "EXP": "13",
    "SCHOOL": "Utah",
    "name": "Tom Chambers",
    "rosters": [
      "de2e838d-3259-430d-8634-0759e68893b8",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "0d39d553-7b93-4206-b525-d87f764e4d5d",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd"
    ]
  },
  "1474": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 23, 1964",
    "EXP": "5",
    "SCHOOL": "Montana",
    "name": "Larry Krystkowiak",
    "rosters": [
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "da7fed67-9107-4124-becb-84e8169908af",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f"
    ]
  },
  "1477": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 14, 1971",
    "EXP": "12",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Bruce Bowen",
    "rosters": [
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87"
    ]
  },
  "1478": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "208",
    "BIRTH_DATE": "JAN 24, 1972",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Stevin Smith",
    "rosters": [
      "fa4a30fe-1491-4395-b8f0-25eaeaa946f4"
    ]
  },
  "1479": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "257",
    "BIRTH_DATE": "JUL 19, 1963",
    "EXP": "6",
    "SCHOOL": "George Washington",
    "name": "Mike Brown",
    "rosters": [
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090",
      "3fde7d6c-cdb9-4c15-9771-931ab5279c62",
      "78c68458-e60d-4462-a703-8501fdadf08e",
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e",
      "eecb8d44-e66b-417f-bd80-36f97052a98a",
      "955486b2-508c-4776-884b-e521d5730bf8",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f"
    ]
  },
  "1489": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 14, 1968",
    "EXP": "6",
    "SCHOOL": "La Salle",
    "name": "Lionel Simmons",
    "rosters": [
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1",
      "2283cacb-c40a-4e46-a0ba-c1389b23f8ed",
      "5f51079c-a258-4dd0-bbf6-7316c28a4693"
    ]
  },
  "1495": {
    "NUM": "21",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "APR 25, 1976",
    "EXP": "18",
    "SCHOOL": "Wake Forest",
    "name": "Tim Duncan",
    "rosters": [
      "34728380-7c3f-4b0d-afce-5b4eb9af171b",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "513319a3-e55b-4c85-93d5-b45d51fa457e"
    ]
  },
  "1496": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "OCT 23, 1975",
    "EXP": "5",
    "SCHOOL": "Utah",
    "name": "Keith Van Horn",
    "rosters": [
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "48bd29bb-6817-4388-af0d-0fb73d383119",
      "482b62f2-7d27-4986-a11b-04e7b24f96a8",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "d0523481-86c7-447a-b0d9-81625e9e333c",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "8f3601f5-3c15-4767-80c3-9436658cb172"
    ]
  },
  "1497": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 25, 1976",
    "EXP": "16",
    "SCHOOL": "Colorado",
    "name": "Chauncey Billups",
    "rosters": [
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5",
      "6d018b74-602e-4da7-810d-8489c0aab560",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "532f6daa-fa7a-422a-9326-f0c577396c95",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "1498": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 19, 1975",
    "EXP": "10",
    "SCHOOL": "Bowling Green",
    "name": "Antonio Daniels",
    "rosters": [
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c"
    ]
  },
  "1499": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 11, 1976",
    "EXP": "13",
    "SCHOOL": "Texas Tech",
    "name": "Tony Battie",
    "rosters": [
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68"
    ]
  },
  "1500": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 18, 1976",
    "EXP": "5",
    "SCHOOL": "Kentucky",
    "name": "Ron Mercer",
    "rosters": [
      "ab598beb-863a-4658-a955-99fa97cb8d08",
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1"
    ]
  },
  "1501": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 26, 1977",
    "EXP": "8",
    "SCHOOL": "Villanova",
    "name": "Tim Thomas",
    "rosters": [
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "c8282335-91e7-4db5-923a-a392aae710c6"
    ]
  },
  "1502": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "270",
    "BIRTH_DATE": "MAR 09, 1975",
    "EXP": "12",
    "SCHOOL": "Colgate",
    "name": "Adonal Foyle",
    "rosters": [
      "0aa87910-76b7-4782-a081-a241102c4730",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "2f7ada02-daaa-431d-b4e1-61d409829b71",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1"
    ]
  },
  "1503": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "223",
    "BIRTH_DATE": "MAY 24, 1979",
    "EXP": "13",
    "SCHOOL": "Mount Zion Christian Academy",
    "name": "Tracy McGrady",
    "rosters": [
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "d52a9555-ff77-4418-a883-c18272a57975",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44"
    ]
  },
  "1504": {
    "NUM": "21",
    "POSITION": "C-F",
    "HEIGHT": "6-8",
    "WEIGHT": "260",
    "BIRTH_DATE": "MAR 27, 1976",
    "EXP": "9",
    "SCHOOL": "Cincinnati",
    "name": "Danny Fortson",
    "rosters": [
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab"
    ]
  },
  "1505": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "223",
    "BIRTH_DATE": "NOV 03, 1974",
    "EXP": "1",
    "SCHOOL": "San Jose State",
    "name": "Tariq Abdul-Wahad",
    "rosters": [
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740"
    ]
  },
  "1506": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 01, 1975",
    "EXP": "8",
    "SCHOOL": "Providence",
    "name": "Austin Croshere",
    "rosters": [
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "8fb79121-88d0-4730-abb0-a8f76039384e",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "3d331f49-e3c4-41db-8e85-694ca2e24972"
    ]
  },
  "1507": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 18, 1974",
    "EXP": "10",
    "SCHOOL": "Kentucky",
    "name": "Derek Anderson",
    "rosters": [
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "6031a224-5eee-4f57-8eda-ecc1fb9562e1",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e",
      "a16e37d5-d174-4f55-bfdc-ba838caae024"
    ]
  },
  "1508": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "265",
    "BIRTH_DATE": "OCT 30, 1976",
    "EXP": "7",
    "SCHOOL": "Michigan",
    "name": "Maurice Taylor",
    "rosters": [
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2"
    ]
  },
  "1509": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "275",
    "BIRTH_DATE": "AUG 26, 1974",
    "EXP": "8",
    "SCHOOL": "Iowa State",
    "name": "Kelvin Cato",
    "rosters": [
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "542bf9d6-44b9-4718-a1b6-2fdc98f7ec5b",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5"
    ]
  },
  "1510": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "170",
    "BIRTH_DATE": "NOV 08, 1975",
    "EXP": "9",
    "SCHOOL": "Stanford",
    "name": "Brevin Knight",
    "rosters": [
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "721f24b6-955d-43a4-901d-da6918201f42",
      "69c4432f-a9df-4bdd-b947-ef61debf2424",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52",
      "2a8cce15-fec9-4173-8665-82815b3bfb24",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e"
    ]
  },
  "1511": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 04, 1974",
    "EXP": "2",
    "SCHOOL": "Tennessee-Chattanooga",
    "name": "Johnny Taylor",
    "rosters": [
      "77b80143-e8fe-4061-a904-4d6d527f123c",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10"
    ]
  },
  "1512": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "249",
    "BIRTH_DATE": "JAN 01, 1975",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Chris Anstey",
    "rosters": [
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299",
      "473a3f11-84e1-44d9-ac95-2e57181d18a2",
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528"
    ]
  },
  "1513": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "265",
    "BIRTH_DATE": "FEB 12, 1975",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Scot Pollard",
    "rosters": [
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc"
    ]
  },
  "1514": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 06, 1974",
    "EXP": "1",
    "SCHOOL": "Wisconsin",
    "name": "Paul Grant",
    "rosters": [
      "27fa6c21-b209-4a5a-8c48-fd62a812709b"
    ]
  },
  "1515": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 19, 1975",
    "EXP": "5",
    "SCHOOL": "Bradley",
    "name": "Anthony Parker",
    "rosters": [
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "135aed72-0bdb-4c31-818e-756fc1a923da",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "91c165fd-b08a-4c73-84bf-a029d5600436"
    ]
  },
  "1516": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 27, 1975",
    "EXP": "1",
    "SCHOOL": "California",
    "name": "Ed Gray",
    "rosters": [
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0"
    ]
  },
  "1517": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 13, 1973",
    "EXP": "8",
    "SCHOOL": "Minnesota",
    "name": "Bobby Jackson",
    "rosters": [
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317",
      "b00a755a-14e3-4682-bed6-49df9136ad09"
    ]
  },
  "1518": {
    "NUM": "12",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 24, 1973",
    "EXP": "1",
    "SCHOOL": "Southern California",
    "name": "Rodrick Rhodes",
    "rosters": [
      "4bfccb84-ee2c-48d6-9279-43235ccb5f4f",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7"
    ]
  },
  "1519": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "265",
    "BIRTH_DATE": "SEP 08, 1975",
    "EXP": "2",
    "SCHOOL": "Minnesota",
    "name": "John Thomas",
    "rosters": [
      "9d211135-ddd3-4d77-ab15-5f84131f2de6",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae"
    ]
  },
  "1520": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "194",
    "BIRTH_DATE": "AUG 22, 1975",
    "EXP": "2",
    "SCHOOL": "New Mexico",
    "name": "Charles Smith",
    "rosters": [
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "1204693e-5234-4041-9acb-b8a3eea3566c"
    ]
  },
  "1521": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 11, 1975",
    "EXP": "3",
    "SCHOOL": "Kansas",
    "name": "Jacque Vaughn",
    "rosters": [
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "034cf132-3085-4071-860d-8f8cdea90094",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "25f4a679-efde-4cd0-b491-1731aa9569e6",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b",
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f"
    ]
  },
  "1522": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "226",
    "BIRTH_DATE": "OCT 09, 1974",
    "EXP": "1",
    "SCHOOL": "Maryland",
    "name": "Keith Booth",
    "rosters": [
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c"
    ]
  },
  "1525": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "209",
    "BIRTH_DATE": "FEB 22, 1975",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Charles O'Bannon",
    "rosters": [
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc",
      "09154feb-3670-47a9-a0ed-55f55f2002b1"
    ]
  },
  "1527": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 05, 1977",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Marko Milic",
    "rosters": [
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e"
    ]
  },
  "1528": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 26, 1974",
    "EXP": "R",
    "SCHOOL": "Austin Peay",
    "name": "Bubba Wells",
    "rosters": [
      "473a3f11-84e1-44d9-ac95-2e57181d18a2"
    ]
  },
  "1529": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "239",
    "BIRTH_DATE": "DEC 19, 1973",
    "EXP": "R",
    "SCHOOL": "Cal State-Bakersfield",
    "name": "Kebu Stewart",
    "rosters": [
      "135aed72-0bdb-4c31-818e-756fc1a923da"
    ]
  },
  "1530": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "196",
    "BIRTH_DATE": "NOV 05, 1973",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "James Collins",
    "rosters": [
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1"
    ]
  },
  "1531": {
    "NUM": "25",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "253",
    "BIRTH_DATE": "JAN 16, 1975",
    "EXP": "4",
    "SCHOOL": "Temple",
    "name": "Marc Jackson",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739"
    ]
  },
  "1532": {
    "NUM": "25",
    "POSITION": "F-G",
    "HEIGHT": "6-9",
    "WEIGHT": "254",
    "BIRTH_DATE": "OCT 20, 1974",
    "EXP": "1",
    "SCHOOL": "Tulane",
    "name": "Jerald Honeycutt",
    "rosters": [
      "a1fe1beb-f3ed-4791-a8b7-7bf9eb01e494",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2"
    ]
  },
  "1533": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 02, 1974",
    "EXP": "10",
    "SCHOOL": "College of Charleston",
    "name": "Anthony Johnson",
    "rosters": [
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "a50184cf-bda0-43f5-970c-379a18a8c902"
    ]
  },
  "1535": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 02, 1974",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Jason Lawson",
    "rosters": [
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b"
    ]
  },
  "1536": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 05, 1978",
    "EXP": "10",
    "SCHOOL": "Butler Community College",
    "name": "Stephen Jackson",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "d0523481-86c7-447a-b0d9-81625e9e333c",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9"
    ]
  },
  "1538": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 11, 1975",
    "EXP": "5",
    "SCHOOL": "Memphis",
    "name": "Cedric Henderson",
    "rosters": [
      "3c1cd7eb-779f-4052-bd0b-316146ce785e",
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed"
    ]
  },
  "1539": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "169",
    "BIRTH_DATE": "APR 29, 1976",
    "EXP": "R",
    "SCHOOL": "Providence",
    "name": "God Shammgod",
    "rosters": [
      "3a362135-e048-4453-af47-87555fdc1775"
    ]
  },
  "1540": {
    "NUM": "14",
    "POSITION": "F-G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 24, 1974",
    "EXP": "1",
    "SCHOOL": "Alabama",
    "name": "Eric Washington",
    "rosters": [
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "77b80143-e8fe-4061-a904-4d6d527f123c"
    ]
  },
  "1541": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 06, 1974",
    "EXP": "7",
    "SCHOOL": "Villanova",
    "name": "Alvin Williams",
    "rosters": [
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "53990650-b132-46c8-9435-b3308f7a0b10"
    ]
  },
  "1542": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "272",
    "BIRTH_DATE": "OCT 27, 1975",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Predrag Drobnjak",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd"
    ]
  },
  "1544": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 13, 1975",
    "EXP": "6",
    "SCHOOL": "Marquette",
    "name": "Chris Crawford",
    "rosters": [
      "8dec30fa-0b61-452d-ab5a-4c8b3f651aa9",
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "034cf132-3085-4071-860d-8f8cdea90094"
    ]
  },
  "1545": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "165",
    "BIRTH_DATE": "OCT 14, 1973",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "DeJuan Wheat",
    "rosters": [
      "c9048bdc-2836-4609-9734-21853a995553",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7"
    ]
  },
  "1548": {
    "NUM": "30",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 30, 1975",
    "EXP": "6",
    "SCHOOL": "Pittsburgh",
    "name": "Mark Blount",
    "rosters": [
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "3e4b502a-18e0-4f72-b057-966f0972f973"
    ]
  },
  "1559": {
    "NUM": "44",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 04, 1974",
    "EXP": "8",
    "SCHOOL": "Seton Hall",
    "name": "Adrian Griffin",
    "rosters": [
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd"
    ]
  },
  "1562": {
    "NUM": "51",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 25, 1972",
    "EXP": "1",
    "SCHOOL": "Nicholls State",
    "name": "Gerard King",
    "rosters": [
      "9821bc62-c9f6-4e8c-b656-b8ffc595fd73",
      "0b7a4d32-1992-4984-b46d-503707641e87"
    ]
  },
  "1563": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 27, 1972",
    "EXP": "12",
    "SCHOOL": "Connecticut",
    "name": "Kevin Ollie",
    "rosters": [
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "34e56408-94b7-440b-a1ad-f7ecbb56e21b",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac"
    ]
  },
  "1564": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 29, 1973",
    "EXP": "1",
    "SCHOOL": "Auburn-Montgomery",
    "name": "Etdrick Bohannon",
    "rosters": [
      "54990428-f851-46a0-94d9-186a7dbc0229"
    ]
  },
  "1565": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 25, 1975",
    "EXP": "4",
    "SCHOOL": "California",
    "name": "Michael Stewart",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "3c6f5772-9b65-4def-8409-05c17bc09fef",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee"
    ]
  },
  "1569": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "212",
    "BIRTH_DATE": "APR 03, 1976",
    "EXP": "3",
    "SCHOOL": "Central Connecticut State",
    "name": "Keith Closs",
    "rosters": [
      "d7e67edc-60da-4b0f-82ae-b06e29cfc4f1",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536"
    ]
  },
  "1572": {
    "NUM": "7",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "245",
    "BIRTH_DATE": "APR 02, 1975",
    "EXP": "R",
    "SCHOOL": "Central Michigan",
    "name": "Nate Huffman",
    "rosters": [
      "c840df16-2e76-4e5a-8ca0-c35c950288a9"
    ]
  },
  "1576": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAY 27, 1969",
    "EXP": "R",
    "SCHOOL": "South Carolina State",
    "name": "Travis Williams",
    "rosters": [
      "25f7d175-5991-48e6-8d9b-391b80096be6"
    ]
  },
  "1577": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "175",
    "BIRTH_DATE": "FEB 11, 1971",
    "EXP": "1",
    "SCHOOL": "Western Kentucky",
    "name": "Darnell Mee",
    "rosters": [
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4"
    ]
  },
  "1593": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "APR 22, 1966",
    "EXP": "5",
    "SCHOOL": "North Carolina State",
    "name": "Charles Shackleford",
    "rosters": [
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2"
    ]
  },
  "1594": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 14, 1972",
    "EXP": "4",
    "SCHOOL": "Temple",
    "name": "Rick Brunson",
    "rosters": [
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "ca4e311a-018b-4a3f-a84c-249324a9e1ed",
      "38367904-90af-4a44-b7af-467809d3ee0d",
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "2b334b77-09f5-45ee-bb8d-ecb462e5125d",
      "e92ea5fa-6340-4a15-b911-8471d044338f"
    ]
  },
  "1600": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 22, 1971",
    "EXP": "2",
    "SCHOOL": "Budapest AEH",
    "name": "Kornel David",
    "rosters": [
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "9359cd35-305f-4f57-847e-21ec13abdc68"
    ]
  },
  "1601": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 10, 1973",
    "EXP": "3",
    "SCHOOL": "Wake Forest",
    "name": "Rusty LaRue",
    "rosters": [
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28"
    ]
  },
  "1603": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 01, 1969",
    "EXP": "2",
    "SCHOOL": "Minnesota",
    "name": "Walter Bond",
    "rosters": [
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd"
    ]
  },
  "1607": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 13, 1976",
    "EXP": "4",
    "SCHOOL": "Southern Illinois",
    "name": "Troy Hudson",
    "rosters": [
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "3e4b502a-18e0-4f72-b057-966f0972f973",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "5b743435-d30b-4ed8-b575-f58b32c5af35"
    ]
  },
  "1609": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "OCT 01, 1972",
    "EXP": "2",
    "SCHOOL": "Cincinnati",
    "name": "Art Long",
    "rosters": [
      "8f3601f5-3c15-4767-80c3-9436658cb172"
    ]
  },
  "1612": {
    "NUM": "00",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "156",
    "BIRTH_DATE": "FEB 23, 1975",
    "EXP": "R",
    "SCHOOL": "Memphis",
    "name": "Chris Garner",
    "rosters": [
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "5b06fcef-b5a6-432b-b0d9-eeb7e378e1f5"
    ]
  },
  "1619": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "183",
    "BIRTH_DATE": "SEP 24, 1974",
    "EXP": "2",
    "SCHOOL": "Illinois",
    "name": "Kiwane Garris",
    "rosters": [
      "792077d5-a2d6-410d-84fd-6271f14ffeca"
    ]
  },
  "1622": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 29, 1961",
    "EXP": "6",
    "SCHOOL": "Louisville",
    "name": "Rodney McCray",
    "rosters": [
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "65fe3c0f-07c5-469c-b786-9542d2d55742",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff"
    ]
  },
  "1628": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 05, 1967",
    "EXP": "2",
    "SCHOOL": "Alabama-Birmingham",
    "name": "Alan Ogg",
    "rosters": [
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05"
    ]
  },
  "1630": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 04, 1975",
    "EXP": "2",
    "SCHOOL": "Nebraska",
    "name": "Mikki Moore",
    "rosters": [
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "13b195d0-c278-4b5d-85e3-20eeddc3ba7b",
      "9359cd35-305f-4f57-847e-21ec13abdc68"
    ]
  },
  "1631": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 01, 1963",
    "EXP": "4",
    "SCHOOL": "Louisville",
    "name": "Billy Thompson",
    "rosters": [
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "652536cb-d53b-4de7-a147-555aacb21048",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "e7b9ec7d-a840-4995-babb-299a22930d17"
    ]
  },
  "1635": {
    "NUM": "27",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "248",
    "BIRTH_DATE": "JUL 11, 1970",
    "EXP": "R",
    "SCHOOL": "Georgia Tech",
    "name": "Malcolm Mackey",
    "rosters": [
      "30517724-5902-4ca6-b448-35847695fb4a"
    ]
  },
  "1666": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 18, 1969",
    "EXP": "1",
    "SCHOOL": "UCLA",
    "name": "Gerald Madkins",
    "rosters": [
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "0aa87910-76b7-4782-a081-a241102c4730"
    ]
  },
  "1667": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "177",
    "BIRTH_DATE": "FEB 02, 1968",
    "EXP": "3",
    "SCHOOL": "Kansas State",
    "name": "Steve Henson",
    "rosters": [
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "60149951-fa60-4cf7-acb8-a9a2a8051eab",
      "a644392d-ec60-49ae-9bce-bb9584c4d3cc",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e"
    ]
  },
  "1677": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 07, 1970",
    "EXP": "1",
    "SCHOOL": "Morehouse",
    "name": "Harold Ellis",
    "rosters": [
      "444e98c3-fcb5-48e2-8abd-64b23dedcf83",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae"
    ]
  },
  "1680": {
    "NUM": "2-31",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 01, 1968",
    "EXP": "2",
    "SCHOOL": "Georgia Tech",
    "name": "Brian Oliver",
    "rosters": [
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa"
    ]
  },
  "1682": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 08, 1968",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Reggie Hanson",
    "rosters": [
      "ab598beb-863a-4658-a955-99fa97cb8d08"
    ]
  },
  "1683": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 11, 1968",
    "EXP": "2",
    "SCHOOL": "Centenary (LA)",
    "name": "Larry Robinson",
    "rosters": [
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "a98c4621-6e20-4474-b8b9-b92e12d377b0",
      "c2463e87-71de-4e61-9e19-5a14ef789b3a",
      "fcc3f55b-00b3-4afd-a4e9-f544dacf8792",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05"
    ]
  },
  "1709": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "270",
    "BIRTH_DATE": "APR 03, 1975",
    "EXP": "6",
    "SCHOOL": "Pacific",
    "name": "Michael Olowokandi",
    "rosters": [
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6"
    ]
  },
  "1710": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 13, 1978",
    "EXP": "2",
    "SCHOOL": "Arizona",
    "name": "Mike Bibby",
    "rosters": [
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7",
      "378d09ae-9325-475b-960c-5806574aab51",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad"
    ]
  },
  "1711": {
    "NUM": "9",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 29, 1976",
    "EXP": "10",
    "SCHOOL": "Kansas",
    "name": "Raef LaFrentz",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215"
    ]
  },
  "1712": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 12, 1976",
    "EXP": "10",
    "SCHOOL": "North Carolina",
    "name": "Antawn Jamison",
    "rosters": [
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "5cd72c30-3fd8-4449-bb2e-f890d4bb6095",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de"
    ]
  },
  "1713": {
    "NUM": "15",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 26, 1977",
    "EXP": "18",
    "SCHOOL": "North Carolina",
    "name": "Vince Carter",
    "rosters": [
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "e8169801-0d82-448f-b1b7-6fb64e81aac6",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "241e4430-9349-4627-901a-466c5486c0f8",
      "f9c9aa9d-d215-423c-9918-dec5a3ecaaae",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d"
    ]
  },
  "1714": {
    "NUM": "34",
    "POSITION": "F-C",
    "HEIGHT": "6-8",
    "WEIGHT": "284",
    "BIRTH_DATE": "FEB 01, 1977",
    "EXP": "3",
    "SCHOOL": "Michigan",
    "name": "Robert Traylor",
    "rosters": [
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "d3b9b507-31c0-4cd8-a25f-2cf8d40acb18",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "1715": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 18, 1975",
    "EXP": "6",
    "SCHOOL": "Florida",
    "name": "Jason Williams",
    "rosters": [
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "147ffabd-b9bd-4692-b986-85204cc78855",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "721f24b6-955d-43a4-901d-da6918201f42",
      "69c4432f-a9df-4bdd-b947-ef61debf2424",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419"
    ]
  },
  "1716": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 23, 1979",
    "EXP": "11",
    "SCHOOL": "Saint Louis",
    "name": "Larry Hughes",
    "rosters": [
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "2f7ada02-daaa-431d-b4e1-61d409829b71",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "89160d0f-db62-4529-acb6-4226a6796989",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575"
    ]
  },
  "1717": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 19, 1978",
    "EXP": "20",
    "SCHOOL": " ",
    "name": "Dirk Nowitzki",
    "rosters": [
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "52b4e695-cf53-49a6-8a86-ff4295a423ce"
    ]
  },
  "1718": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 13, 1977",
    "EXP": "16",
    "SCHOOL": "Kansas",
    "name": "Paul Pierce",
    "rosters": [
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "f2f0afff-3d0a-467c-81db-0d74f5985472",
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "88463408-f197-42d6-b955-433fadaa716d",
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "8d3a3080-9e74-4512-8869-83aad854d0a0"
    ]
  },
  "1719": {
    "NUM": "6",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 28, 1976",
    "EXP": "6",
    "SCHOOL": "Ball State",
    "name": "Bonzi Wells",
    "rosters": [
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "75d32015-dc9a-454e-ae76-aa74fe068fc4",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419"
    ]
  },
  "1720": {
    "NUM": "51",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "262",
    "BIRTH_DATE": "JUN 15, 1977",
    "EXP": "2",
    "SCHOOL": "Utah",
    "name": "Michael Doleac",
    "rosters": [
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "58327b11-17d0-4efc-a314-62009ef21c2a"
    ]
  },
  "1721": {
    "NUM": "7",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "221",
    "BIRTH_DATE": "APR 16, 1975",
    "EXP": "3",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Keon Clark",
    "rosters": [
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee"
    ]
  },
  "1722": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 25, 1975",
    "EXP": "4",
    "SCHOOL": "Arizona",
    "name": "Michael Dickerson",
    "rosters": [
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "378d09ae-9325-475b-960c-5806574aab51",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad",
      "69c4432f-a9df-4bdd-b947-ef61debf2424"
    ]
  },
  "1723": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 31, 1976",
    "EXP": "10",
    "SCHOOL": "Georgia Tech",
    "name": "Matt Harpring",
    "rosters": [
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "bc2b5072-fbcc-4964-9813-750dd05c3fac",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "28eacab0-70b6-464e-9364-355a0c9123d3",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce"
    ]
  },
  "1724": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "184",
    "BIRTH_DATE": "SEP 21, 1974",
    "EXP": "3",
    "SCHOOL": "Valparaiso",
    "name": "Bryce Drew",
    "rosters": [
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "1725": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAY 30, 1976",
    "EXP": "11",
    "SCHOOL": null,
    "name": "Rasho Nesterovic",
    "rosters": [
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4"
    ]
  },
  "1726": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "216",
    "BIRTH_DATE": "JUN 07, 1976",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Mirsad Turkcan",
    "rosters": [
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33"
    ]
  },
  "1727": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "238",
    "BIRTH_DATE": "AUG 23, 1976",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Pat Garrity",
    "rosters": [
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69"
    ]
  },
  "1728": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "221",
    "BIRTH_DATE": "NOV 17, 1975",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Roshown McLeod",
    "rosters": [
      "5e997f1e-341e-4eb5-a34c-c83b46ab3db0",
      "5a840cd1-bbf4-43f1-b005-40d97eead426"
    ]
  },
  "1729": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "197",
    "BIRTH_DATE": "SEP 23, 1979",
    "EXP": "1",
    "SCHOOL": "Iowa",
    "name": "Ricky Davis",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "3e4b502a-18e0-4f72-b057-966f0972f973",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2",
      "c060723a-d5e6-4385-bc5e-59560b27d467"
    ]
  },
  "1730": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "265",
    "BIRTH_DATE": "MAY 19, 1976",
    "EXP": "6",
    "SCHOOL": "Baylor",
    "name": "Brian Skinner",
    "rosters": [
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63"
    ]
  },
  "1731": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "178",
    "BIRTH_DATE": "MAY 03, 1977",
    "EXP": "4",
    "SCHOOL": "Nebraska",
    "name": "Tyronn Lue",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "1133cf87-e88e-4041-9357-78207256459c",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646"
    ]
  },
  "1732": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 19, 1974",
    "EXP": "1",
    "SCHOOL": "St. John's (NY)",
    "name": "Felipe Lopez",
    "rosters": [
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7",
      "378d09ae-9325-475b-960c-5806574aab51"
    ]
  },
  "1733": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "FEB 17, 1980",
    "EXP": "15",
    "SCHOOL": "Saint Patrick",
    "name": "Al Harrington",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "1133cf87-e88e-4041-9357-78207256459c",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "d52a9555-ff77-4418-a883-c18272a57975",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a"
    ]
  },
  "1734": {
    "NUM": "6",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "219",
    "BIRTH_DATE": "JUL 22, 1975",
    "EXP": "1",
    "SCHOOL": "Minnesota",
    "name": "Sam Jacobson",
    "rosters": [
      "2f7ada02-daaa-431d-b4e1-61d409829b71"
    ]
  },
  "1735": {
    "NUM": "5",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "236",
    "BIRTH_DATE": "MAY 08, 1976",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Vladimir Stepania",
    "rosters": [
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "d0523481-86c7-447a-b0d9-81625e9e333c",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912"
    ]
  },
  "1736": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 24, 1978",
    "EXP": "2",
    "SCHOOL": "Oregon State",
    "name": "Corey Benjamin",
    "rosters": [
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299",
      "67e5e245-e136-4b67-ba4d-f41d5ab61011"
    ]
  },
  "1737": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 05, 1977",
    "EXP": "11",
    "SCHOOL": "Kentucky",
    "name": "Nazr Mohammed",
    "rosters": [
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "39fae275-e32e-40e1-aa2b-f961e2251fa2",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "532f6daa-fa7a-422a-9326-f0c577396c95",
      "a16e37d5-d174-4f55-bfdc-ba838caae024",
      "fc384a91-81b8-47cb-b999-2a497289f651",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575"
    ]
  },
  "1738": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 29, 1976",
    "EXP": "2",
    "SCHOOL": "Mississippi",
    "name": "Ansu Sesay",
    "rosters": [
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4"
    ]
  },
  "1739": {
    "NUM": "21",
    "POSITION": "F-G",
    "HEIGHT": "6-5",
    "WEIGHT": "224",
    "BIRTH_DATE": "JUL 31, 1975",
    "EXP": "2",
    "SCHOOL": "Cincinnati",
    "name": "Ruben Patterson",
    "rosters": [
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "b5b72829-0d6f-4b56-b6d1-d6483bb38438",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40"
    ]
  },
  "1740": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 08, 1979",
    "EXP": "13",
    "SCHOOL": "Elsik",
    "name": "Rashard Lewis",
    "rosters": [
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40",
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c"
    ]
  },
  "1741": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "DEC 06, 1977",
    "EXP": "4",
    "SCHOOL": "California-Los Angeles",
    "name": "Jelani McCoy",
    "rosters": [
      "240cc5c0-6f74-48dc-acb9-305f7fe9a912",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9"
    ]
  },
  "1742": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "201",
    "BIRTH_DATE": "APR 05, 1975",
    "EXP": "3",
    "SCHOOL": "North Carolina",
    "name": "Shammond Williams",
    "rosters": [
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40",
      "c04784ce-a740-496a-b8dd-354463d7169d"
    ]
  },
  "1743": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "246",
    "BIRTH_DATE": "FEB 10, 1980",
    "EXP": "3",
    "SCHOOL": null,
    "name": "Bruno Sundov",
    "rosters": [
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "01f231b7-fdf5-4e89-8d19-3f25d72a9528",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff"
    ]
  },
  "1744": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "272",
    "BIRTH_DATE": "NOV 17, 1975",
    "EXP": "4",
    "SCHOOL": "Florida A&M",
    "name": "Jerome James",
    "rosters": [
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "eebacc20-e4d3-4417-9b52-c5705ef3d740",
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4"
    ]
  },
  "1745": {
    "NUM": "53",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUL 20, 1975",
    "EXP": "1",
    "SCHOOL": "Toledo",
    "name": "Casey Shaw",
    "rosters": [
      "792077d5-a2d6-410d-84fd-6271f14ffeca"
    ]
  },
  "1747": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUL 24, 1976",
    "EXP": "5",
    "SCHOOL": "Fresno State",
    "name": "Rafer Alston",
    "rosters": [
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "0e3f21e2-6591-49bc-9708-ec6cc72cdc33",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae"
    ]
  },
  "1749": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 01, 1975",
    "EXP": "6",
    "SCHOOL": "Rhode Island",
    "name": "Cuttino Mobley",
    "rosters": [
      "f9d171e9-8890-47d7-bbfa-313297290f40",
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63"
    ]
  },
  "1750": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "202",
    "BIRTH_DATE": "NOV 21, 1975",
    "EXP": "1",
    "SCHOOL": "Arizona",
    "name": "Miles Simon",
    "rosters": [
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd"
    ]
  },
  "1751": {
    "NUM": "55",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "290",
    "BIRTH_DATE": "FEB 19, 1976",
    "EXP": "4",
    "SCHOOL": "Georgetown",
    "name": "Jahidi White",
    "rosters": [
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf",
      "0b7a4d32-1992-4984-b46d-503707641e87",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646"
    ]
  },
  "1752": {
    "NUM": "40",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 23, 1975",
    "EXP": "5",
    "SCHOOL": "California",
    "name": "Sean Marks",
    "rosters": [
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "187b2b41-379c-4869-a6d8-8469aec4efbc"
    ]
  },
  "1753": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "213",
    "BIRTH_DATE": "NOV 19, 1975",
    "EXP": "1",
    "SCHOOL": "California-Los Angeles",
    "name": "Toby Bailey",
    "rosters": [
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "5cd70763-5c6d-404c-ae94-b800444a7144"
    ]
  },
  "1754": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "238",
    "BIRTH_DATE": "NOV 12, 1975",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Andrae Patterson",
    "rosters": [
      "a8cc9002-2b04-4ee9-ac89-c06a3312b35d"
    ]
  },
  "1756": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 24, 1975",
    "EXP": "R",
    "SCHOOL": "South Carolina",
    "name": "Ryan Stack",
    "rosters": [
      "ea288811-4d0e-4235-aa38-7f3580a89f0c"
    ]
  },
  "1757": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 05, 1975",
    "EXP": "R",
    "SCHOOL": "Texas Tech",
    "name": "Cory Carr",
    "rosters": [
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c"
    ]
  },
  "1760": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "184",
    "BIRTH_DATE": "DEC 20, 1975",
    "EXP": "2",
    "SCHOOL": "Eastern Michigan",
    "name": "Derrick Dial",
    "rosters": [
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "a4c2ebb6-4746-4a22-bea4-4b26da8efa29",
      "03919be3-368f-4f38-9235-7c7e490247ee"
    ]
  },
  "1761": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 16, 1976",
    "EXP": "9",
    "SCHOOL": "Clemson",
    "name": "Greg Buckner",
    "rosters": [
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "824e5cff-47e8-4169-8451-74a294a985e8"
    ]
  },
  "1762": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "212",
    "BIRTH_DATE": "APR 11, 1976",
    "EXP": "2",
    "SCHOOL": "Fresno State",
    "name": "Tremaine Fowlkes",
    "rosters": [
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f"
    ]
  },
  "1763": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 20, 1975",
    "EXP": "6",
    "SCHOOL": "Iowa",
    "name": "Ryan Bowen",
    "rosters": [
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef"
    ]
  },
  "1764": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "233",
    "BIRTH_DATE": "OCT 30, 1976",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "J.R. Henderson",
    "rosters": [
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7"
    ]
  },
  "1765": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 15, 1976",
    "EXP": "1",
    "SCHOOL": "Xavier",
    "name": "Torraye Braggs",
    "rosters": [
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc"
    ]
  },
  "1766": {
    "NUM": "9",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 29, 1976",
    "EXP": "2",
    "SCHOOL": "Michigan",
    "name": "Maceo Baston",
    "rosters": [
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "0e001846-f440-4bca-ae94-6cf01edf7123"
    ]
  },
  "1800": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 25, 1976",
    "EXP": "3",
    "SCHOOL": "Houston",
    "name": "Damon Jones",
    "rosters": [
      "1417f231-1a9a-40cc-829d-5a1c4c5422f9",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "43f4a4c3-dfb0-47cd-bf8e-97e0155d249b",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1"
    ]
  },
  "1801": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 06, 1974",
    "EXP": "1",
    "SCHOOL": "East Carolina",
    "name": "Jonathan Kerner",
    "rosters": [
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd"
    ]
  },
  "1802": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "261",
    "BIRTH_DATE": "APR 12, 1976",
    "EXP": "1",
    "SCHOOL": "Purdue",
    "name": "Brad Miller",
    "rosters": [
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "6536ba8a-0f91-4dc7-845e-fc3e4f2ba0b2",
      "c060723a-d5e6-4385-bc5e-59560b27d467"
    ]
  },
  "1806": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 25, 1968",
    "EXP": "R",
    "SCHOOL": "Dartmouth",
    "name": "James Blackwell",
    "rosters": [
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a"
    ]
  },
  "1814": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 17, 1970",
    "EXP": "2",
    "SCHOOL": "Connecticut",
    "name": "Chris Smith",
    "rosters": [
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090"
    ]
  },
  "1817": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "170",
    "BIRTH_DATE": "AUG 21, 1970",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Adonis Jordan",
    "rosters": [
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8"
    ]
  },
  "1819": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "NOV 30, 1967",
    "EXP": "R",
    "SCHOOL": "Phillips",
    "name": "John Turner",
    "rosters": [
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9"
    ]
  },
  "1820": {
    "NUM": "11",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 24, 1975",
    "EXP": "R",
    "SCHOOL": "Southern Mississippi",
    "name": "Kelly McCarty",
    "rosters": [
      "77b80143-e8fe-4061-a904-4d6d527f123c"
    ]
  },
  "1821": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 30, 1967",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Mark Randall",
    "rosters": [
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "1838": {
    "NUM": "8",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 31, 1976",
    "EXP": "3",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Tyrone Nesby",
    "rosters": [
      "73ff1242-2442-4b77-acdb-1e2fc0f74294",
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f"
    ]
  },
  "1839": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 04, 1967",
    "EXP": "4",
    "SCHOOL": "Louisiana Tech",
    "name": "Randy White",
    "rosters": [
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0"
    ]
  },
  "1844": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 28, 1971",
    "EXP": "R",
    "SCHOOL": "Georgia Tech",
    "name": "Fred Vinson",
    "rosters": [
      "a859ae74-acea-4f80-abaa-92f971001064"
    ]
  },
  "1845": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 24, 1965",
    "EXP": "R",
    "SCHOOL": "Seton Hall",
    "name": "Andrew Gaze",
    "rosters": [
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7"
    ]
  },
  "1846": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 02, 1964",
    "EXP": "1",
    "SCHOOL": "Washington",
    "name": "Chris Welp",
    "rosters": [
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda"
    ]
  },
  "1847": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "247",
    "BIRTH_DATE": "APR 07, 1961",
    "EXP": "11",
    "SCHOOL": "North Carolina State",
    "name": "Thurl Bailey",
    "rosters": [
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "eecb8d44-e66b-417f-bd80-36f97052a98a",
      "955486b2-508c-4776-884b-e521d5730bf8",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "f217a398-a097-4cd7-9b69-8aa3ea215b7b"
    ]
  },
  "1848": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 17, 1967",
    "EXP": "1",
    "SCHOOL": "Northern Colorado",
    "name": "Mike Higgins",
    "rosters": [
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "57c1be08-60cf-40ad-a097-a95ef9958532"
    ]
  },
  "1853": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 16, 1975",
    "EXP": "11",
    "SCHOOL": "Hawaii",
    "name": "Anthony Carter",
    "rosters": [
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0"
    ]
  },
  "1855": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 14, 1969",
    "EXP": "4",
    "SCHOOL": "Temple",
    "name": "Mark Macon",
    "rosters": [
      "37167a67-466f-4920-b036-1481c8665cf9",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff"
    ]
  },
  "1861": {
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 03, 1967",
    "EXP": "4",
    "SCHOOL": "Dayton",
    "name": "Negele Knight",
    "rosters": [
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "7086572d-ca1e-44e5-86cd-1b8426bc9934"
    ]
  },
  "1863": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "5-5",
    "WEIGHT": "133",
    "BIRTH_DATE": "JUN 02, 1976",
    "EXP": "9",
    "SCHOOL": "Eastern Michigan",
    "name": "Earl Boykins",
    "rosters": [
      "ea288811-4d0e-4235-aa38-7f3580a89f0c",
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b",
      "a16e37d5-d174-4f55-bfdc-ba838caae024"
    ]
  },
  "1864": {
    "NUM": "30",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 18, 1976",
    "EXP": "2",
    "SCHOOL": "Princeton",
    "name": "Steve Goodrich",
    "rosters": [
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e"
    ]
  },
  "1869": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 17, 1975",
    "EXP": "1",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Charles R. Jones",
    "rosters": [
      "5d5ecf5f-9104-462a-bf8c-b3073e27584c",
      "54990428-f851-46a0-94d9-186a7dbc0229"
    ]
  },
  "1871": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 28, 1975",
    "EXP": "R",
    "SCHOOL": "Pepperdine",
    "name": "Gerald Brown",
    "rosters": [
      "51b4adc7-a756-4c8e-a8d7-edca548a0d69"
    ]
  },
  "1872": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 06, 1976",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Randell Jackson",
    "rosters": [
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf"
    ]
  },
  "1874": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 14, 1966",
    "EXP": "1",
    "SCHOOL": "Georgia Southern",
    "name": "Jeff Sanders",
    "rosters": [
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e"
    ]
  },
  "1882": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "254",
    "BIRTH_DATE": "MAR 11, 1979",
    "EXP": "16",
    "SCHOOL": "Duke",
    "name": "Elton Brand",
    "rosters": [
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299",
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68",
      "b4ff765f-4502-4299-a2e5-383cd5889dea"
    ]
  },
  "1883": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 21, 1977",
    "EXP": "5",
    "SCHOOL": "Maryland",
    "name": "Steve Francis",
    "rosters": [
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75"
    ]
  },
  "1884": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "223",
    "BIRTH_DATE": "APR 13, 1979",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "Baron Davis",
    "rosters": [
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "c060723a-d5e6-4385-bc5e-59560b27d467",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "1885": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 06, 1979",
    "EXP": "14",
    "SCHOOL": "Rhode Island",
    "name": "Lamar Odom",
    "rosters": [
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "54990428-f851-46a0-94d9-186a7dbc0229",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b"
    ]
  },
  "1886": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "7-0",
    "WEIGHT": "219",
    "BIRTH_DATE": "JAN 30, 1981",
    "EXP": "4",
    "SCHOOL": "Picayune Memorial",
    "name": "Jonathan Bender",
    "rosters": [
      "d52a9555-ff77-4418-a883-c18272a57975",
      "fb79c90a-4e44-4b1b-886a-923b5c3f0fd2",
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9"
    ]
  },
  "1887": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "244",
    "BIRTH_DATE": "MAR 05, 1977",
    "EXP": "5",
    "SCHOOL": "Miami (OH)",
    "name": "Wally Szczerbiak",
    "rosters": [
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6"
    ]
  },
  "1888": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "193",
    "BIRTH_DATE": "FEB 14, 1978",
    "EXP": "11",
    "SCHOOL": "Connecticut",
    "name": "Richard Hamilton",
    "rosters": [
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "0b7a4d32-1992-4984-b46d-503707641e87",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f",
      "6d018b74-602e-4da7-810d-8489c0aab560",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "532f6daa-fa7a-422a-9326-f0c577396c95",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44"
    ]
  },
  "1889": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 19, 1976",
    "EXP": "14",
    "SCHOOL": "Utah",
    "name": "Andre Miller",
    "rosters": [
      "25e7a76c-35b5-4898-88be-f97f130ca905",
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "8e1bb40f-2c63-4904-9e81-67592b326bda",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a"
    ]
  },
  "1890": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "228",
    "BIRTH_DATE": "MAY 07, 1978",
    "EXP": "9",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Shawn Marion",
    "rosters": [
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "5cd70763-5c6d-404c-ae94-b800444a7144",
      "426fcece-7698-41e8-8ebb-254a273fe06b",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "91c165fd-b08a-4c73-84bf-a029d5600436"
    ]
  },
  "1891": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 15, 1977",
    "EXP": "14",
    "SCHOOL": "Arizona",
    "name": "Jason Terry",
    "rosters": [
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "034cf132-3085-4071-860d-8f8cdea90094",
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f"
    ]
  },
  "1892": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "197",
    "BIRTH_DATE": "MAY 13, 1976",
    "EXP": "2",
    "SCHOOL": "Duke",
    "name": "Trajan Langdon",
    "rosters": [
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57"
    ]
  },
  "1894": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 12, 1979",
    "EXP": "12",
    "SCHOOL": "Duke",
    "name": "Corey Maggette",
    "rosters": [
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "0e8a0256-38ef-4041-b0d7-cb1359c01e10",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6"
    ]
  },
  "1895": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 08, 1979",
    "EXP": "3",
    "SCHOOL": "Duke",
    "name": "William Avery",
    "rosters": [
      "e5b0839d-6df7-4e07-9a6f-efea5fb1cfdf",
      "98863912-8e18-4e6d-99fe-c3dd0bb35ace",
      "8f3601f5-3c15-4767-80c3-9436658cb172"
    ]
  },
  "1897": {
    "NUM": "93",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "248",
    "BIRTH_DATE": "NOV 13, 1979",
    "EXP": "8",
    "SCHOOL": "St. John's (NY)",
    "name": "Metta World Peace",
    "rosters": [
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "a50184cf-bda0-43f5-970c-379a18a8c902"
    ]
  },
  "1898": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "253",
    "BIRTH_DATE": "MAR 31, 1977",
    "EXP": "2",
    "SCHOOL": "Old Dominion",
    "name": "Cal Bowdler",
    "rosters": [
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "cfeb1dff-151c-4957-9363-84d2f20ef58d"
    ]
  },
  "1899": {
    "NUM": "41",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 13, 1977",
    "EXP": "5",
    "SCHOOL": "Xavier",
    "name": "James Posey",
    "rosters": [
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918",
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419"
    ]
  },
  "1900": {
    "NUM": "20",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 26, 1977",
    "EXP": "2",
    "SCHOOL": "Minnesota",
    "name": "Quincy Lewis",
    "rosters": [
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28"
    ]
  },
  "1901": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 22, 1978",
    "EXP": "4",
    "SCHOOL": "Georgia Tech",
    "name": "Dion Glover",
    "rosters": [
      "5a840cd1-bbf4-43f1-b005-40d97eead426",
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f"
    ]
  },
  "1902": {
    "NUM": "10",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "JAN 16, 1977",
    "EXP": "12",
    "SCHOOL": "S.W. Texas State",
    "name": "Jeff Foster",
    "rosters": [
      "d05863bb-8dce-49be-840f-100a7de3cc34",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "18ccc342-b735-4763-bc05-89b9f428f609"
    ]
  },
  "1903": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 25, 1977",
    "EXP": "9",
    "SCHOOL": "New Mexico",
    "name": "Kenny Thomas",
    "rosters": [
      "a97884c1-58e9-4a89-9737-96f55a224730",
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317"
    ]
  },
  "1904": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "AUG 29, 1977",
    "EXP": "6",
    "SCHOOL": "Augsburg",
    "name": "Devean George",
    "rosters": [
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "96a8ae7a-2b5e-49e6-8019-aed10a10b536",
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "f2364869-d15e-4237-be86-d502b6074d04"
    ]
  },
  "1905": {
    "NUM": "47",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "FEB 18, 1981",
    "EXP": "9",
    "SCHOOL": null,
    "name": "Andrei Kirilenko",
    "rosters": [
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5"
    ]
  },
  "1906": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "214",
    "BIRTH_DATE": "DEC 25, 1976",
    "EXP": "3",
    "SCHOOL": "Miami (FL)",
    "name": "Tim James",
    "rosters": [
      "1fed5db0-b968-4bc2-b296-10632898c907"
    ]
  },
  "1907": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 29, 1976",
    "EXP": "2",
    "SCHOOL": "Pittsburgh",
    "name": "Vonteego Cummings",
    "rosters": [
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "2f7ada02-daaa-431d-b4e1-61d409829b71",
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62",
      "84b74c33-754c-4c8e-babe-0fe581adab51"
    ]
  },
  "1908": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "218",
    "BIRTH_DATE": "FEB 10, 1979",
    "EXP": "6",
    "SCHOOL": "Georgia",
    "name": "Jumaine Jones",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "2a8cce15-fec9-4173-8665-82815b3bfb24"
    ]
  },
  "1909": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 19, 1976",
    "EXP": "3",
    "SCHOOL": "Kentucky",
    "name": "Scott Padgett",
    "rosters": [
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "30107e00-802b-466d-b025-30b5f60b2c06",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b"
    ]
  },
  "1910": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 02, 1980",
    "EXP": "1",
    "SCHOOL": "Martin Luther King",
    "name": "Leon Smith",
    "rosters": [
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4"
    ]
  },
  "1911": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 06, 1977",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "John Celestand",
    "rosters": [
      "f9be6046-cf31-4b59-9535-b62182ab35ff"
    ]
  },
  "1913": {
    "NUM": "51",
    "POSITION": "C-F",
    "HEIGHT": "6-8",
    "WEIGHT": "248",
    "BIRTH_DATE": "JAN 21, 1977",
    "EXP": "6",
    "SCHOOL": "Tulsa",
    "name": "Michael Ruffin",
    "rosters": [
      "a6ed7aab-a56e-46b4-b20f-e3a783ab6299",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe"
    ]
  },
  "1914": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 27, 1975",
    "EXP": "R",
    "SCHOOL": "Fresno State",
    "name": "Chris Herren",
    "rosters": [
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50"
    ]
  },
  "1915": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAY 30, 1975",
    "EXP": "1",
    "SCHOOL": "Northwestern",
    "name": "Evan Eschmeyer",
    "rosters": [
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "8285d0bf-5d8e-42f8-99c9-f29312b63146",
      "d0523481-86c7-447a-b0d9-81625e9e333c"
    ]
  },
  "1916": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAY 07, 1976",
    "EXP": "7",
    "SCHOOL": "Penn State",
    "name": "Calvin Booth",
    "rosters": [
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "0b7a4d32-1992-4984-b46d-503707641e87",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe"
    ]
  },
  "1917": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "284",
    "BIRTH_DATE": "JUL 08, 1977",
    "EXP": "3",
    "SCHOOL": null,
    "name": "Wang Zhi-zhi",
    "rosters": [
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6"
    ]
  },
  "1918": {
    "NUM": "54",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "270",
    "BIRTH_DATE": "AUG 22, 1975",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Obinna Ekezie",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "378d09ae-9325-475b-960c-5806574aab51"
    ]
  },
  "1919": {
    "NUM": "7",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "204",
    "BIRTH_DATE": "AUG 05, 1977",
    "EXP": "2",
    "SCHOOL": "Maryland",
    "name": "Laron Profit",
    "rosters": [
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "0b7a4d32-1992-4984-b46d-503707641e87",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12"
    ]
  },
  "1921": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "216",
    "BIRTH_DATE": "JUN 20, 1977",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Gordan Giricek",
    "rosters": [
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "28eacab0-70b6-464e-9364-355a0c9123d3",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "69c4432f-a9df-4bdd-b947-ef61debf2424"
    ]
  },
  "1922": {
    "NUM": "16",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 28, 1976",
    "EXP": "7",
    "SCHOOL": "California",
    "name": "Francisco Elson",
    "rosters": [
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5"
    ]
  },
  "1924": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "238",
    "BIRTH_DATE": "FEB 22, 1975",
    "EXP": "1",
    "SCHOOL": "Texas Christian",
    "name": "Lee Nailon",
    "rosters": [
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "1928": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "280",
    "BIRTH_DATE": "JAN 27, 1976",
    "EXP": "4",
    "SCHOOL": "Washington",
    "name": "Todd MacCulloch",
    "rosters": [
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "bd5de45d-9c1a-49ff-918d-c586a109c7af",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd"
    ]
  },
  "1930": {
    "NUM": "0",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "285",
    "BIRTH_DATE": "FEB 01, 1977",
    "EXP": "R",
    "SCHOOL": "Massachusetts",
    "name": "Lari Ketner",
    "rosters": [
      "25e7a76c-35b5-4898-88be-f97f130ca905"
    ]
  },
  "1934": {
    "NUM": "32",
    "POSITION": "F-G",
    "HEIGHT": "6-5",
    "WEIGHT": "189",
    "BIRTH_DATE": "NOV 02, 1977",
    "EXP": "2",
    "SCHOOL": "Creighton",
    "name": "Rodney Buford",
    "rosters": [
      "24ca2337-7f93-4fce-81a3-bc39766d397d",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "721f24b6-955d-43a4-901d-da6918201f42"
    ]
  },
  "1937": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "270",
    "BIRTH_DATE": "FEB 06, 1976",
    "EXP": "R",
    "SCHOOL": "Stanford",
    "name": "Tim Young",
    "rosters": [
      "2f7ada02-daaa-431d-b4e1-61d409829b71"
    ]
  },
  "1938": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 28, 1977",
    "EXP": "15",
    "SCHOOL": null,
    "name": "Manu Ginobili",
    "rosters": [
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8"
    ]
  },
  "1942": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "269",
    "BIRTH_DATE": "NOV 20, 1976",
    "EXP": "1",
    "SCHOOL": "Clemson",
    "name": "Harold Jamison",
    "rosters": [
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c"
    ]
  },
  "1944": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 19, 1976",
    "EXP": "1",
    "SCHOOL": "Central Oklahoma",
    "name": "Eddie Robinson",
    "rosters": [
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "c060723a-d5e6-4385-bc5e-59560b27d467",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf"
    ]
  },
  "1952": {
    "NUM": "19",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 19, 1976",
    "EXP": "8",
    "SCHOOL": "Florida International",
    "name": "Raja Bell",
    "rosters": [
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "81b6284b-5f88-485c-a000-31a7a960e90a",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "28eacab0-70b6-464e-9364-355a0c9123d3",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "fc384a91-81b8-47cb-b999-2a497289f651"
    ]
  },
  "1953": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 20, 1972",
    "EXP": "R",
    "SCHOOL": "Cuba",
    "name": "Lazaro Borrell",
    "rosters": [
      "cb5320e4-53d6-4150-bc3f-639602fe7d4b"
    ]
  },
  "1956": {
    "NUM": "14",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 20, 1975",
    "EXP": "7",
    "SCHOOL": "Miami (OH)",
    "name": "Ira Newble",
    "rosters": [
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "4206ee94-a61a-464f-99f8-016d0e855498"
    ]
  },
  "1960": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 07, 1978",
    "EXP": "R",
    "SCHOOL": "Colorado State",
    "name": "Milt Palacio",
    "rosters": [
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "378d09ae-9325-475b-960c-5806574aab51"
    ]
  },
  "1967": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "222",
    "BIRTH_DATE": "DEC 22, 1976",
    "EXP": "1",
    "SCHOOL": "Arkansas",
    "name": "Derek Hood",
    "rosters": [
      "04e3eeb2-6367-4dda-8490-df041615bb0b"
    ]
  },
  "1972": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 18, 1967",
    "EXP": "2",
    "SCHOOL": "Seton Hall",
    "name": "John Morton",
    "rosters": [
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030"
    ]
  },
  "1975": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "219",
    "BIRTH_DATE": "JUL 19, 1976",
    "EXP": "1",
    "SCHOOL": "Providence",
    "name": "Jamel Thomas",
    "rosters": [
      "d0523481-86c7-447a-b0d9-81625e9e333c"
    ]
  },
  "1983": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "204",
    "BIRTH_DATE": "JUN 07, 1976",
    "EXP": "2",
    "SCHOOL": "Detroit Mercy",
    "name": "Jermaine Jackson",
    "rosters": [
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9"
    ]
  },
  "1984": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 26, 1964",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "Rickie Winslow",
    "rosters": [
      "777d6706-d922-46f9-8db4-e079c5f46c74"
    ]
  },
  "1985": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 29, 1975",
    "EXP": "3",
    "SCHOOL": "St. John's, N.Y.",
    "name": "Zendon Hamilton",
    "rosters": [
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd"
    ]
  },
  "2030": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 30, 1977",
    "EXP": "13",
    "SCHOOL": "Cincinnati",
    "name": "Kenyon Martin",
    "rosters": [
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b"
    ]
  },
  "2031": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 21, 1979",
    "EXP": "6",
    "SCHOOL": "Louisiana State",
    "name": "Stromile Swift",
    "rosters": [
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "740ab1cb-bb0d-42d8-a011-5f8208d4f3ad",
      "721f24b6-955d-43a4-901d-da6918201f42",
      "69c4432f-a9df-4bdd-b947-ef61debf2424",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394"
    ]
  },
  "2032": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 09, 1981",
    "EXP": "6",
    "SCHOOL": "East St. Louis",
    "name": "Darius Miles",
    "rosters": [
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "824e5cff-47e8-4169-8451-74a294a985e8"
    ]
  },
  "2033": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "260",
    "BIRTH_DATE": "AUG 10, 1978",
    "EXP": "4",
    "SCHOOL": "Iowa State",
    "name": "Marcus Fizer",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945"
    ]
  },
  "2034": {
    "NUM": "6",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "218",
    "BIRTH_DATE": "FEB 19, 1980",
    "EXP": "9",
    "SCHOOL": "Florida",
    "name": "Mike Miller",
    "rosters": [
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "58327b11-17d0-4efc-a314-62009ef21c2a",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419",
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394",
      "d98d42ba-33de-433f-9c54-23973925049a",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b"
    ]
  },
  "2035": {
    "NUM": "1",
    "POSITION": "F-G",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 05, 1980",
    "EXP": "6",
    "SCHOOL": "Cincinnati",
    "name": "DerMarr Johnson",
    "rosters": [
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "cfeb1dff-151c-4957-9363-84d2f20ef58d",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "13c01798-8ae0-4b74-aea4-30da75742856"
    ]
  },
  "2036": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "265",
    "BIRTH_DATE": "JUL 16, 1979",
    "EXP": "8",
    "SCHOOL": "Texas",
    "name": "Chris Mihm",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "6cadbd16-46f0-408c-8d58-1f3b5be4e0b2",
      "f51b9d9f-c8f4-475d-943d-8750c0525b57",
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "824e5cff-47e8-4169-8451-74a294a985e8"
    ]
  },
  "2037": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 20, 1980",
    "EXP": "11",
    "SCHOOL": "Michigan",
    "name": "Jamal Crawford",
    "rosters": [
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676"
    ]
  },
  "2038": {
    "NUM": "10",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "245",
    "BIRTH_DATE": "OCT 10, 1979",
    "EXP": "10",
    "SCHOOL": "Minnesota",
    "name": "Joel Przybilla",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "e82a839f-a912-4d8f-bd5c-7f2ce933ed74",
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9"
    ]
  },
  "2039": {
    "NUM": "55",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 08, 1980",
    "EXP": "12",
    "SCHOOL": "Missouri",
    "name": "Keyon Dooling",
    "rosters": [
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "323505fb-0be2-4c98-96be-9a14a40d1c78"
    ]
  },
  "2040": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 15, 1978",
    "EXP": "3",
    "SCHOOL": "California-Los Angeles",
    "name": "Jerome Moiso",
    "rosters": [
      "5fbe659e-3a17-41b5-8578-5853b8e1b00c",
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f"
    ]
  },
  "2041": {
    "NUM": "36",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "APR 01, 1978",
    "EXP": "6",
    "SCHOOL": "Syracuse",
    "name": "Etan Thomas",
    "rosters": [
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de"
    ]
  },
  "2042": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 27, 1977",
    "EXP": "1",
    "SCHOOL": "Fresno State",
    "name": "Courtney Alexander",
    "rosters": [
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f"
    ]
  },
  "2043": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 07, 1977",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Mateen Cleaves",
    "rosters": [
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "9359cd35-305f-4f57-847e-21ec13abdc68"
    ]
  },
  "2044": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "7-0",
    "WEIGHT": "260",
    "BIRTH_DATE": "SEP 08, 1977",
    "EXP": "2",
    "SCHOOL": "Georgia Tech",
    "name": "Jason Collier",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e"
    ]
  },
  "2045": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 19, 1979",
    "EXP": "9",
    "SCHOOL": null,
    "name": "Hedo Turkoglu",
    "rosters": [
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "775e1767-1db9-44e9-b74e-932f413dc114",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4"
    ]
  },
  "2046": {
    "NUM": "34",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "222",
    "BIRTH_DATE": "OCT 11, 1977",
    "EXP": "8",
    "SCHOOL": "Oklahoma State",
    "name": "Desmond Mason",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40",
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "23acb720-d0cf-4182-9809-8a82ffe3779c"
    ]
  },
  "2047": {
    "NUM": "3",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 13, 1980",
    "EXP": "4",
    "SCHOOL": "DePaul",
    "name": "Quentin Richardson",
    "rosters": [
      "d8ba1838-f94f-4127-8b11-5e9cd4635536",
      "0bb46113-7e43-49e4-80fe-a23bbd15d43c",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1"
    ]
  },
  "2048": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "259",
    "BIRTH_DATE": "MAY 21, 1978",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Jamaal Magloire",
    "rosters": [
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "d72cf956-8b27-48b5-a764-bfb7d694e1bf",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "2049": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "166",
    "BIRTH_DATE": "MAY 08, 1978",
    "EXP": "1",
    "SCHOOL": "Hofstra",
    "name": "Speedy Claxton",
    "rosters": [
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b"
    ]
  },
  "2050": {
    "NUM": "24",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 26, 1977",
    "EXP": "6",
    "SCHOOL": "Michigan State",
    "name": "Morris Peterson",
    "rosters": [
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "f7880972-72ca-4727-996c-8a7e93fda19e",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c"
    ]
  },
  "2051": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 26, 1980",
    "EXP": "3",
    "SCHOOL": "Florida",
    "name": "Donnell Harvey",
    "rosters": [
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "8a26266a-e912-45d0-accf-1224abfe833e"
    ]
  },
  "2052": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "218",
    "BIRTH_DATE": "APR 03, 1981",
    "EXP": "8",
    "SCHOOL": "Washington Union",
    "name": "DeShawn Stevenson",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "7b92b5ee-7230-42dd-8439-cafc541fed3f",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de"
    ]
  },
  "2053": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "290",
    "BIRTH_DATE": "JUL 02, 1980",
    "EXP": "2",
    "SCHOOL": null,
    "name": "Dalibor Bagaric",
    "rosters": [
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565"
    ]
  },
  "2054": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "290",
    "BIRTH_DATE": "JUN 10, 1979",
    "EXP": "5",
    "SCHOOL": null,
    "name": "Jake Tsakalidis",
    "rosters": [
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "426fcece-7698-41e8-8ebb-254a273fe06b",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "b00a755a-14e3-4682-bed6-49df9136ad09"
    ]
  },
  "2055": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUN 16, 1975",
    "EXP": "2",
    "SCHOOL": "Auburn",
    "name": "Mamadou N'diaye",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9"
    ]
  },
  "2056": {
    "NUM": "7",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "252",
    "BIRTH_DATE": "OCT 02, 1979",
    "EXP": "5",
    "SCHOOL": null,
    "name": "Primoz Brezec",
    "rosters": [
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52",
      "2a8cce15-fec9-4173-8665-82815b3bfb24",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e"
    ]
  },
  "2057": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 21, 1978",
    "EXP": "2",
    "SCHOOL": "St. John's (NY)",
    "name": "Erick Barkley",
    "rosters": [
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b"
    ]
  },
  "2058": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "255",
    "BIRTH_DATE": "JAN 28, 1976",
    "EXP": "8",
    "SCHOOL": "Stanford",
    "name": "Mark Madsen",
    "rosters": [
      "245559d4-40e5-4af9-8719-a54fe451cb29",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "3e4b502a-18e0-4f72-b057-966f0972f973",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "6b0a0176-c625-4185-97c7-e44301ac931f"
    ]
  },
  "2059": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 11, 1976",
    "EXP": "11",
    "SCHOOL": "Oklahoma",
    "name": "Eduardo Najera",
    "rosters": [
      "f8978601-057c-4dc7-a94c-b0be55678c99",
      "080fa274-4c53-4190-ab9b-d64d6489f51f",
      "04e3eeb2-6367-4dda-8490-df041615bb0b",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6"
    ]
  },
  "2060": {
    "NUM": "55",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "224",
    "BIRTH_DATE": "DEC 10, 1978",
    "EXP": "6",
    "SCHOOL": null,
    "name": "Marko Jaric",
    "rosters": [
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "3e4b502a-18e0-4f72-b057-966f0972f973",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "824e5cff-47e8-4169-8451-74a294a985e8"
    ]
  },
  "2061": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 28, 1977",
    "EXP": "2",
    "SCHOOL": "Vanderbilt",
    "name": "Dan Langhi",
    "rosters": [
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558"
    ]
  },
  "2062": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 12, 1978",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "A.J. Guyton",
    "rosters": [
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "2393458a-964e-4591-aa45-9b38a23e4a91"
    ]
  },
  "2063": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "NOV 01, 1977",
    "EXP": "6",
    "SCHOOL": "Connecticut",
    "name": "Jake Voskuhl",
    "rosters": [
      "67e5e245-e136-4b67-ba4d-f41d5ab61011",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1",
      "91c165fd-b08a-4c73-84bf-a029d5600436",
      "2a8cce15-fec9-4173-8665-82815b3bfb24",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e"
    ]
  },
  "2064": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 25, 1979",
    "EXP": "1",
    "SCHOOL": "Connecticut",
    "name": "Khalid El-Amin",
    "rosters": [
      "f47b8402-fe32-4053-91ea-76db13aaf7eb"
    ]
  },
  "2065": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 15, 1976",
    "EXP": "R",
    "SCHOOL": "Louisiana-Monroe",
    "name": "Mike Smith",
    "rosters": [
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565"
    ]
  },
  "2066": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "224",
    "BIRTH_DATE": "MAR 18, 1978",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Soumaila Samake",
    "rosters": [
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "d0523481-86c7-447a-b0d9-81625e9e333c"
    ]
  },
  "2067": {
    "NUM": "50",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 14, 1978",
    "EXP": "4",
    "SCHOOL": "Arizona State",
    "name": "Eddie House",
    "rosters": [
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "b32e30f7-90ef-4cd8-9404-c94a1eb714bd",
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "d52a9555-ff77-4418-a883-c18272a57975",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63"
    ]
  },
  "2068": {
    "NUM": "7",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 26, 1978",
    "EXP": "2",
    "SCHOOL": "St. John's (NY)",
    "name": "Lavor Postell",
    "rosters": [
      "0f731ab8-543b-4166-be23-b69ac91e3bb9",
      "1fed5db0-b968-4bc2-b296-10632898c907"
    ]
  },
  "2069": {
    "NUM": "13",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "247",
    "BIRTH_DATE": "SEP 09, 1976",
    "EXP": "1",
    "SCHOOL": "Utah",
    "name": "Hanno Mottola",
    "rosters": [
      "634cdb54-39c9-4502-91b5-1c424e492da2",
      "cfeb1dff-151c-4957-9363-84d2f20ef58d"
    ]
  },
  "2071": {
    "NUM": "00",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAY 11, 1981",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Olumide Oyedeji",
    "rosters": [
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40",
      "c04784ce-a740-496a-b8dd-354463d7169d"
    ]
  },
  "2072": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 24, 1979",
    "EXP": "11",
    "SCHOOL": "Ohio State",
    "name": "Michael Redd",
    "rosters": [
      "b636d686-efcb-4e44-ab91-bbeaa4f88f4b",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "fa166c7c-372a-489f-b069-635a9196ea73"
    ]
  },
  "2073": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 02, 1977",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Brian Cardinal",
    "rosters": [
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419",
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394",
      "d98d42ba-33de-433f-9c54-23973925049a",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "9359cd35-305f-4f57-847e-21ec13abdc68"
    ]
  },
  "2074": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 12, 1977",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Jabari Smith",
    "rosters": [
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "417cf680-3d48-451b-b32a-534de216ffdb"
    ]
  },
  "2078": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 29, 1978",
    "EXP": "3",
    "SCHOOL": "Syracuse",
    "name": "Jason Hart",
    "rosters": [
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52"
    ]
  },
  "2080": {
    "NUM": "36",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "184",
    "BIRTH_DATE": "MAR 29, 1978",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Igor Rakocevic",
    "rosters": [
      "8f9aa8f6-6917-4467-b970-3855520a730d"
    ]
  },
  "2081": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "244",
    "BIRTH_DATE": "MAY 17, 1979",
    "EXP": "1",
    "SCHOOL": "Indian Hills Community College",
    "name": "Ernest Brown",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb"
    ]
  },
  "2082": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "270",
    "BIRTH_DATE": "APR 19, 1977",
    "EXP": "R",
    "SCHOOL": "Northern Arizona",
    "name": "Dan McClintock",
    "rosters": [
      "cd5e6171-eb18-4ed7-b180-d060bc1fd918"
    ]
  },
  "2084": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "MAY 09, 1978",
    "EXP": "R",
    "SCHOOL": "Auburn",
    "name": "Chris Porter",
    "rosters": [
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62"
    ]
  },
  "2091": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "256",
    "BIRTH_DATE": "JUN 24, 1976",
    "EXP": "R",
    "SCHOOL": "Saint Vincent",
    "name": "Daniel Santiago",
    "rosters": [
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "426fcece-7698-41e8-8ebb-254a273fe06b"
    ]
  },
  "2098": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "APR 04, 1979",
    "EXP": "4",
    "SCHOOL": "Ukraine",
    "name": "Slava Medvedenko",
    "rosters": [
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "b9be769f-c86c-4e4a-8f05-1024b9d4332e",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba"
    ]
  },
  "2101": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 03, 1978",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Paul McPherson",
    "rosters": [
      "f21a72e6-4c46-422e-9b29-b3a098dc7b62"
    ]
  },
  "2106": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "270",
    "BIRTH_DATE": "SEP 30, 1973",
    "EXP": "R",
    "SCHOOL": "Argentina",
    "name": "Ruben Wolkowyski",
    "rosters": [
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "85b7144d-faef-40f3-a8c8-043f97bbcd40"
    ]
  },
  "2109": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 16, 1978",
    "EXP": "2",
    "SCHOOL": "Weber State",
    "name": "Eddie Gill",
    "rosters": [
      "d0523481-86c7-447a-b0d9-81625e9e333c",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "721f24b6-955d-43a4-901d-da6918201f42",
      "69c4432f-a9df-4bdd-b947-ef61debf2424"
    ]
  },
  "2124": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUN 27, 1978",
    "EXP": "3",
    "SCHOOL": "Villanova",
    "name": "Malik Allen",
    "rosters": [
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52"
    ]
  },
  "2128": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 21, 1973",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "David Vanterpool",
    "rosters": [
      "7ed5ef3c-5de1-4273-bf55-8c8d75804565"
    ]
  },
  "2137": {
    "NUM": "5",
    "POSITION": "F-G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 09, 1977",
    "EXP": "4",
    "SCHOOL": "Portland State",
    "name": "Ime Udoka",
    "rosters": [
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87"
    ]
  },
  "2143": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 08, 1977",
    "EXP": "1",
    "SCHOOL": "Temple",
    "name": "Pepe Sanchez",
    "rosters": [
      "6d018b74-602e-4da7-810d-8489c0aab560"
    ]
  },
  "2173": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 01, 1975",
    "EXP": "1",
    "SCHOOL": "North Carolina-Charlotte",
    "name": "Sean Colson",
    "rosters": [
      "e502556e-6647-4877-a1dc-fa3278e8fa26",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd"
    ]
  },
  "2198": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "270",
    "BIRTH_DATE": "MAR 10, 1982",
    "EXP": "9",
    "SCHOOL": "Glynn Academy",
    "name": "Kwame Brown",
    "rosters": [
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "d98d42ba-33de-433f-9c54-23973925049a",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9"
    ]
  },
  "2199": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 02, 1982",
    "EXP": "8",
    "SCHOOL": "No College",
    "name": "Tyson Chandler",
    "rosters": [
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575"
    ]
  },
  "2200": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUL 06, 1980",
    "EXP": "5",
    "SCHOOL": " ",
    "name": "Pau Gasol",
    "rosters": [
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "721f24b6-955d-43a4-901d-da6918201f42",
      "69c4432f-a9df-4bdd-b947-ef61debf2424",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419",
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394"
    ]
  },
  "2201": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "295",
    "BIRTH_DATE": "DEC 05, 1982",
    "EXP": "8",
    "SCHOOL": "Thornwood",
    "name": "Eddy Curry",
    "rosters": [
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "d52a9555-ff77-4418-a883-c18272a57975"
    ]
  },
  "2202": {
    "NUM": "23",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 20, 1981",
    "EXP": "6",
    "SCHOOL": "Michigan State",
    "name": "Jason Richardson",
    "rosters": [
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "341a849d-e970-4a90-8bee-8a209ea47cb1",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc",
      "a16e37d5-d174-4f55-bfdc-ba838caae024"
    ]
  },
  "2203": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 09, 1978",
    "EXP": "9",
    "SCHOOL": "Duke",
    "name": "Shane Battier",
    "rosters": [
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "8632a477-9924-4d11-859a-78889ddfee93",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "721f24b6-955d-43a4-901d-da6918201f42",
      "69c4432f-a9df-4bdd-b947-ef61debf2424",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419",
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "bce414a0-1435-4744-9faf-b7027085c341"
    ]
  },
  "2204": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 30, 1982",
    "EXP": "3",
    "SCHOOL": "Seton Hall",
    "name": "Eddie Griffin",
    "rosters": [
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a"
    ]
  },
  "2205": {
    "NUM": "2",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "280",
    "BIRTH_DATE": "JAN 30, 1982",
    "EXP": "11",
    "SCHOOL": "Oak Hill Academy",
    "name": "DeSagana Diop",
    "rosters": [
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "fc384a91-81b8-47cb-b999-2a497289f651",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095"
    ]
  },
  "2206": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "238",
    "BIRTH_DATE": "JUN 28, 1980",
    "EXP": "R",
    "SCHOOL": "North Carolina-Charlotte",
    "name": "Rodney White",
    "rosters": [
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1"
    ]
  },
  "2207": {
    "NUM": "6",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 29, 1981",
    "EXP": "15",
    "SCHOOL": "Arkansas",
    "name": "Joe Johnson",
    "rosters": [
      "1133cf87-e88e-4041-9357-78207256459c",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1",
      "272b357c-17be-4ce8-acf5-24791690c53b"
    ]
  },
  "2208": {
    "NUM": "5",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "222",
    "BIRTH_DATE": "MAR 18, 1981",
    "EXP": "2",
    "SCHOOL": "Northwest Florida State",
    "name": "Kedrick Brown",
    "rosters": [
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2"
    ]
  },
  "2209": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 19, 1980",
    "EXP": "7",
    "SCHOOL": null,
    "name": "Vladimir Radmanovic",
    "rosters": [
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "728d43da-a0f1-4599-96c2-a09036340618",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "fc384a91-81b8-47cb-b999-2a497289f651"
    ]
  },
  "2210": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "234",
    "BIRTH_DATE": "JUN 21, 1980",
    "EXP": "12",
    "SCHOOL": "Arizona",
    "name": "Richard Jefferson",
    "rosters": [
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d"
    ]
  },
  "2211": {
    "NUM": "3",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 02, 1980",
    "EXP": "8",
    "SCHOOL": "Notre Dame",
    "name": "Troy Murphy",
    "rosters": [
      "88463408-f197-42d6-b955-433fadaa716d",
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21"
    ]
  },
  "2212": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 31, 1981",
    "EXP": "7",
    "SCHOOL": "DePaul",
    "name": "Steven Hunter",
    "rosters": [
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c"
    ]
  },
  "2213": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "242",
    "BIRTH_DATE": "MAR 10, 1979",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Kirk Haston",
    "rosters": [
      "53d22344-d154-40b3-a170-e360f5ec1f92",
      "23fd152a-aac1-4f72-97b9-7b02172ba092"
    ]
  },
  "2214": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 18, 1979",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Michael Bradley",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "03919be3-368f-4f38-9235-7c7e490247ee",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9"
    ]
  },
  "2215": {
    "NUM": "98",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "DEC 02, 1978",
    "EXP": "11",
    "SCHOOL": "Stanford",
    "name": "Jason Collins",
    "rosters": [
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "d98d42ba-33de-433f-9c54-23973925049a",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8"
    ]
  },
  "2216": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUL 16, 1981",
    "EXP": "15",
    "SCHOOL": "Michigan State",
    "name": "Zach Randolph",
    "rosters": [
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c",
      "bce414a0-1435-4744-9faf-b7027085c341",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289",
      "323505fb-0be2-4c98-96be-9a14a40d1c78",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d"
    ]
  },
  "2217": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "263",
    "BIRTH_DATE": "NOV 27, 1979",
    "EXP": "12",
    "SCHOOL": "North Carolina",
    "name": "Brendan Haywood",
    "rosters": [
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095",
      "45ed0581-1916-49f0-9a2f-fb15342345c5"
    ]
  },
  "2218": {
    "NUM": "40",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "194",
    "BIRTH_DATE": "MAR 23, 1981",
    "EXP": "1",
    "SCHOOL": "North Carolina",
    "name": "Joseph Forte",
    "rosters": [
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd"
    ]
  },
  "2219": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 13, 1979",
    "EXP": "1",
    "SCHOOL": "Southern Methodist",
    "name": "Jeryl Sasser",
    "rosters": [
      "5b743435-d30b-4ed8-b575-f58b32c5af35",
      "40e68a58-8b3f-418c-8207-7bb3574d4098"
    ]
  },
  "2220": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 16, 1980",
    "EXP": "2",
    "SCHOOL": "Pepperdine",
    "name": "Brandon Armstrong",
    "rosters": [
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267"
    ]
  },
  "2221": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "160",
    "BIRTH_DATE": "APR 15, 1980",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Raul Lopez",
    "rosters": [
      "27fa6c21-b209-4a5a-8c48-fd62a812709b",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460"
    ]
  },
  "2222": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 23, 1982",
    "EXP": "8",
    "SCHOOL": "Alabama",
    "name": "Gerald Wallace",
    "rosters": [
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "8e1bb40f-2c63-4904-9e81-67592b326bda",
      "f1a90a9e-bf92-4127-8554-405c6a6d67ef",
      "792077d5-a2d6-410d-84fd-6271f14ffeca",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52",
      "2a8cce15-fec9-4173-8665-82815b3bfb24",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e",
      "a16e37d5-d174-4f55-bfdc-ba838caae024",
      "fc384a91-81b8-47cb-b999-2a497289f651",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575"
    ]
  },
  "2223": {
    "NUM": "10",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAY 10, 1980",
    "EXP": "8",
    "SCHOOL": "Seton Hall",
    "name": "Samuel Dalembert",
    "rosters": [
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "f682d9da-cb2e-4f60-b710-6accad2d386b"
    ]
  },
  "2224": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 28, 1978",
    "EXP": "7",
    "SCHOOL": "Iowa State",
    "name": "Jamaal Tinsley",
    "rosters": [
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c"
    ]
  },
  "2225": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 17, 1982",
    "EXP": "17",
    "SCHOOL": " ",
    "name": "Tony Parker",
    "rosters": [
      "1204693e-5234-4041-9acb-b8a3eea3566c",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "7e4b59f0-0509-4845-8730-9663232e7b79"
    ]
  },
  "2226": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 20, 1978",
    "EXP": "R",
    "SCHOOL": "Clemson",
    "name": "Will Solomon",
    "rosters": [
      "721f24b6-955d-43a4-901d-da6918201f42"
    ]
  },
  "2228": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "280",
    "BIRTH_DATE": "MAY 29, 1981",
    "EXP": "1",
    "SCHOOL": "Houston",
    "name": "Alton Ford",
    "rosters": [
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558"
    ]
  },
  "2229": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "188",
    "BIRTH_DATE": "JUN 23, 1975",
    "EXP": "2",
    "SCHOOL": "Duquesne",
    "name": "Mike James",
    "rosters": [
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "49672545-bb7d-43e6-a8c6-9e18594ef023",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f"
    ]
  },
  "2230": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 08, 1978",
    "EXP": "2",
    "SCHOOL": "Texas",
    "name": "Maurice Evans",
    "rosters": [
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5"
    ]
  },
  "2237": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "265",
    "BIRTH_DATE": "MAY 06, 1979",
    "EXP": "1",
    "SCHOOL": "Yugoslavia",
    "name": "Ratko Varda",
    "rosters": [
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646"
    ]
  },
  "2238": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "219",
    "BIRTH_DATE": "APR 01, 1981",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Antonis Fotsis",
    "rosters": [
      "721f24b6-955d-43a4-901d-da6918201f42"
    ]
  },
  "2239": {
    "NUM": "44",
    "POSITION": "F-G",
    "HEIGHT": "6-5",
    "WEIGHT": "227",
    "BIRTH_DATE": "MAR 04, 1979",
    "EXP": "8",
    "SCHOOL": "Austin Peay",
    "name": "Trenton Hassell",
    "rosters": [
      "b6ec3c75-4153-44d9-88f4-59c364671885",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "3e4b502a-18e0-4f72-b057-966f0972f973",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4"
    ]
  },
  "2240": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 06, 1982",
    "EXP": "8",
    "SCHOOL": "Arizona",
    "name": "Gilbert Arenas",
    "rosters": [
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b"
    ]
  },
  "2241": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 28, 1982",
    "EXP": "1",
    "SCHOOL": "St. John's (NY)",
    "name": "Omar Cook",
    "rosters": [
      "a9f752e8-7260-43b1-ac76-bca349ff930a",
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae"
    ]
  },
  "2242": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "221",
    "BIRTH_DATE": "JAN 11, 1979",
    "EXP": "1",
    "SCHOOL": "Maryland",
    "name": "Terence Morris",
    "rosters": [
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e"
    ]
  },
  "2243": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 18, 1978",
    "EXP": "3",
    "SCHOOL": "Southern California",
    "name": "Brian Scalabrine",
    "rosters": [
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "c50a0f15-a679-4d28-b025-26daa9d217d5",
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "99c6aea9-27d0-453f-a643-716447513a5f"
    ]
  },
  "2244": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 11, 1979",
    "EXP": "2",
    "SCHOOL": "Southern California",
    "name": "Jeff Trepagnier",
    "rosters": [
      "e98d32ee-a54c-44c0-a2d4-de81113efba0"
    ]
  },
  "2245": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "202",
    "BIRTH_DATE": "JUN 28, 1979",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Damone Brown",
    "rosters": [
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "8f3601f5-3c15-4767-80c3-9436658cb172"
    ]
  },
  "2246": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "249",
    "BIRTH_DATE": "MAY 26, 1979",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Mehmet Okur",
    "rosters": [
      "28eacab0-70b6-464e-9364-355a0c9123d3",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "6d018b74-602e-4da7-810d-8489c0aab560",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f"
    ]
  },
  "2248": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 12, 1979",
    "EXP": "3",
    "SCHOOL": "California-Los Angeles",
    "name": "Earl Watson",
    "rosters": [
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "c04784ce-a740-496a-b8dd-354463d7169d",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502",
      "69c4432f-a9df-4bdd-b947-ef61debf2424",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419"
    ]
  },
  "2249": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "184",
    "BIRTH_DATE": "NOV 19, 1980",
    "EXP": "2",
    "SCHOOL": "Auburn",
    "name": "Jamison Brewer",
    "rosters": [
      "cb8c41c1-2ef8-4ea7-978a-ea7f00de6aff",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9"
    ]
  },
  "2250": {
    "NUM": "21",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 02, 1980",
    "EXP": "1",
    "SCHOOL": "DePaul",
    "name": "Bobby Simmons",
    "rosters": [
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "cdfc71f2-65f6-4f37-a339-0fa2d022ce5f",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646"
    ]
  },
  "2253": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "227",
    "BIRTH_DATE": "SEP 03, 1979",
    "EXP": "R",
    "SCHOOL": "California",
    "name": "Sean Lampley",
    "rosters": [
      "f47b8402-fe32-4053-91ea-76db13aaf7eb"
    ]
  },
  "2254": {
    "NUM": "3",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUN 21, 1978",
    "EXP": "4",
    "SCHOOL": "Arizona",
    "name": "Loren Woods",
    "rosters": [
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "1c86cb7f-aa90-45ec-8522-d0b639467917",
      "8f9aa8f6-6917-4467-b970-3855520a730d",
      "53990650-b132-46c8-9435-b3308f7a0b10"
    ]
  },
  "2256": {
    "NUM": "54",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 01, 1978",
    "EXP": "R",
    "SCHOOL": "Ohio State",
    "name": "Ken Johnson",
    "rosters": [
      "f47b8402-fe32-4053-91ea-76db13aaf7eb"
    ]
  },
  "2257": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "257",
    "BIRTH_DATE": "MAY 20, 1978",
    "EXP": "1",
    "SCHOOL": "Georgetown",
    "name": "Ruben Boumtje-Boumtje",
    "rosters": [
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "e92ea5fa-6340-4a15-b911-8471d044338f",
      "719d3baf-1086-49d1-8f27-82662eb959d1"
    ]
  },
  "2260": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "249",
    "BIRTH_DATE": "DEC 02, 1978",
    "EXP": "7",
    "SCHOOL": "Stanford",
    "name": "Jarron Collins",
    "rosters": [
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc",
      "edb3dde1-2bc0-4fdd-bbbe-7d462ac1ec28",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "28eacab0-70b6-464e-9364-355a0c9123d3",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce"
    ]
  },
  "2261": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 10, 1981",
    "EXP": "1",
    "SCHOOL": "Cincinnati",
    "name": "Kenny Satterfield",
    "rosters": [
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494"
    ]
  },
  "2264": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "265",
    "BIRTH_DATE": "SEP 09, 1978",
    "EXP": "1",
    "SCHOOL": "Georgia Tech",
    "name": "Alvin Jones",
    "rosters": [
      "84b74c33-754c-4c8e-babe-0fe581adab51",
      "8f3601f5-3c15-4767-80c3-9436658cb172"
    ]
  },
  "2294": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 12, 1979",
    "EXP": "5",
    "SCHOOL": "Michigan State",
    "name": "Charlie Bell",
    "rosters": [
      "728d43da-a0f1-4599-96c2-a09036340618",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "99c7795f-1136-4419-84cb-2b176eba2129"
    ]
  },
  "2306": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "202",
    "BIRTH_DATE": "JUL 30, 1979",
    "EXP": "3",
    "SCHOOL": "Florida International",
    "name": "Carlos Arroyo",
    "rosters": [
      "88463408-f197-42d6-b955-433fadaa716d",
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "27fa6c21-b209-4a5a-8c48-fd62a812709b",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "15e8507c-358e-47f0-807e-4a609d0a4581"
    ]
  },
  "2321": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 23, 1977",
    "EXP": "2",
    "SCHOOL": "Iowa State",
    "name": "Paul Shirley",
    "rosters": [
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1"
    ]
  },
  "2343": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 18, 1979",
    "EXP": "R",
    "SCHOOL": "Penn State",
    "name": "Joe Crispin",
    "rosters": [
      "10dc108f-d7b9-48ae-9cd8-49df5b3caf60"
    ]
  },
  "2347": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAY 26, 1978",
    "EXP": "1",
    "SCHOOL": "Mississippi State",
    "name": "Tang Hamilton",
    "rosters": [
      "69c4432f-a9df-4bdd-b947-ef61debf2424"
    ]
  },
  "2349": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "265",
    "BIRTH_DATE": "AUG 31, 1969",
    "EXP": "4",
    "SCHOOL": "Iowa State",
    "name": "Victor Alexander",
    "rosters": [
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "30c53eba-69ab-45fc-9d62-0fa9faa176d1"
    ]
  },
  "2351": {
    "NUM": "18",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 18, 1976",
    "EXP": "1",
    "SCHOOL": "Venezuela",
    "name": "Oscar Torres",
    "rosters": [
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e"
    ]
  },
  "2352": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 05, 1978",
    "EXP": "1",
    "SCHOOL": "Iowa",
    "name": "Dean Oliver",
    "rosters": [
      "89160d0f-db62-4529-acb6-4226a6796989",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0"
    ]
  },
  "2357": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "189",
    "BIRTH_DATE": "JUN 03, 1979",
    "EXP": "1",
    "SCHOOL": "McNeese State",
    "name": "Tierre Brown",
    "rosters": [
      "75f68605-ac26-42aa-a606-d75bdf9ed219",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e"
    ]
  },
  "2365": {
    "NUM": "7",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 07, 1978",
    "EXP": "13",
    "SCHOOL": "Blinn",
    "name": "Chris Andersen",
    "rosters": [
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42"
    ]
  },
  "2366": {
    "NUM": "29",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 07, 1979",
    "EXP": "6",
    "SCHOOL": "Rice",
    "name": "Mike Wilks",
    "rosters": [
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "824e5cff-47e8-4169-8451-74a294a985e8"
    ]
  },
  "2369": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 24, 1977",
    "EXP": "R",
    "SCHOOL": "Hofstra",
    "name": "Norman Richardson",
    "rosters": [
      "b6ec3c75-4153-44d9-88f4-59c364671885"
    ]
  },
  "2370": {
    "NUM": "27",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "290",
    "BIRTH_DATE": "NOV 20, 1975",
    "EXP": "1",
    "SCHOOL": "China",
    "name": "Mengke Bateer",
    "rosters": [
      "42000ca1-2c10-44de-ab6f-9b981adc311a",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b"
    ]
  },
  "2397": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "7-6",
    "WEIGHT": "310",
    "BIRTH_DATE": "SEP 12, 1980",
    "EXP": "8",
    "SCHOOL": null,
    "name": "Yao Ming",
    "rosters": [
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "34e936dc-3545-4fdb-ae48-4f0caf8342cc",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "8632a477-9924-4d11-859a-78889ddfee93",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b"
    ]
  },
  "2398": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 10, 1981",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Jay Williams",
    "rosters": [
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565"
    ]
  },
  "2399": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 15, 1980",
    "EXP": "8",
    "SCHOOL": "Duke",
    "name": "Mike Dunleavy",
    "rosters": [
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78"
    ]
  },
  "2400": {
    "NUM": "90",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 24, 1981",
    "EXP": "13",
    "SCHOOL": "Kansas",
    "name": "Drew Gooden",
    "rosters": [
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "69c4432f-a9df-4bdd-b947-ef61debf2424",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "8d3a3080-9e74-4512-8869-83aad854d0a0",
      "d5add497-b2c3-4523-b2ef-584c47479c7d"
    ]
  },
  "2401": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "7-0",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 14, 1983",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Nikoloz Tskitishvili",
    "rosters": [
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "c8282335-91e7-4db5-923a-a392aae710c6"
    ]
  },
  "2402": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 04, 1983",
    "EXP": "1",
    "SCHOOL": "Memphis",
    "name": "Dajuan Wagner",
    "rosters": [
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2"
    ]
  },
  "2403": {
    "NUM": "42",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 13, 1982",
    "EXP": "13",
    "SCHOOL": " ",
    "name": "Nene",
    "rosters": [
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "8d3a3080-9e74-4512-8869-83aad854d0a0",
      "d5add497-b2c3-4523-b2ef-584c47479c7d"
    ]
  },
  "2404": {
    "NUM": "9",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 03, 1982",
    "EXP": "8",
    "SCHOOL": "Maryland",
    "name": "Chris Wilcox",
    "rosters": [
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44"
    ]
  },
  "2405": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "249",
    "BIRTH_DATE": "NOV 16, 1982",
    "EXP": "7",
    "SCHOOL": "Cypress Creek",
    "name": "Amar'e Stoudemire",
    "rosters": [
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc"
    ]
  },
  "2406": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "228",
    "BIRTH_DATE": "MAR 13, 1980",
    "EXP": "12",
    "SCHOOL": "Connecticut",
    "name": "Caron Butler",
    "rosters": [
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7"
    ]
  },
  "2407": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 25, 1981",
    "EXP": "3",
    "SCHOOL": "Indiana",
    "name": "Jared Jeffries",
    "rosters": [
      "8632a477-9924-4d11-859a-78889ddfee93",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2"
    ]
  },
  "2408": {
    "NUM": "2",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "261",
    "BIRTH_DATE": "MAY 02, 1978",
    "EXP": "3",
    "SCHOOL": "Fresno State",
    "name": "Melvin Ely",
    "rosters": [
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "6d6518fd-faa9-4fbd-991f-20932543250e",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52",
      "2a8cce15-fec9-4173-8665-82815b3bfb24"
    ]
  },
  "2409": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 22, 1980",
    "EXP": "1",
    "SCHOOL": "Tennessee",
    "name": "Marcus Haislip",
    "rosters": [
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4"
    ]
  },
  "2410": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 11, 1979",
    "EXP": "4",
    "SCHOOL": "Oregon",
    "name": "Fred Jones",
    "rosters": [
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "ce4310a5-7984-4648-bf19-4667a3f014f1",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e"
    ]
  },
  "2411": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "221",
    "BIRTH_DATE": "JUL 03, 1980",
    "EXP": "5",
    "SCHOOL": null,
    "name": "Bostjan Nachbar",
    "rosters": [
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "1263d642-0f8f-47dd-b80b-8930a1814b1e",
      "a42c78f5-f6f2-4a3a-af3e-dc05bbc21b51",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4"
    ]
  },
  "2412": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "208",
    "BIRTH_DATE": "JAN 27, 1980",
    "EXP": "3",
    "SCHOOL": null,
    "name": "Jiri Welsch",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "ef8968c7-76a5-45a7-b76a-2fc6b2a2c3a0",
      "fe70708d-44fa-4308-85a4-df0100fc59b8"
    ]
  },
  "2413": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "165",
    "BIRTH_DATE": "OCT 09, 1978",
    "EXP": "5",
    "SCHOOL": "Maryland",
    "name": "Juan Dixon",
    "rosters": [
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c",
      "f9e28ec1-e1a5-4d53-9a41-e95fe478c646",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21"
    ]
  },
  "2414": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "242",
    "BIRTH_DATE": "SEP 13, 1980",
    "EXP": "1",
    "SCHOOL": "Stanford",
    "name": "Curtis Borchardt",
    "rosters": [
      "27fa6c21-b209-4a5a-8c48-fd62a812709b",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "28eacab0-70b6-464e-9364-355a0c9123d3"
    ]
  },
  "2415": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 24, 1979",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Ryan Humphrey",
    "rosters": [
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6"
    ]
  },
  "2416": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 30, 1980",
    "EXP": "5",
    "SCHOOL": "Missouri",
    "name": "Kareem Rush",
    "rosters": [
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1"
    ]
  },
  "2417": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "221",
    "BIRTH_DATE": "FEB 16, 1981",
    "EXP": "1",
    "SCHOOL": "Northeast Mississippi Community College",
    "name": "Qyntel Woods",
    "rosters": [
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae"
    ]
  },
  "2418": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 19, 1981",
    "EXP": "3",
    "SCHOOL": "Stanford",
    "name": "Casey Jacobsen",
    "rosters": [
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558",
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "d98d42ba-33de-433f-9c54-23973925049a"
    ]
  },
  "2419": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 28, 1980",
    "EXP": "12",
    "SCHOOL": "Kentucky",
    "name": "Tayshaun Prince",
    "rosters": [
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "323505fb-0be2-4c98-96be-9a14a40d1c78",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "6d018b74-602e-4da7-810d-8489c0aab560",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "532f6daa-fa7a-422a-9326-f0c577396c95",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7"
    ]
  },
  "2420": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 25, 1983",
    "EXP": "5",
    "SCHOOL": null,
    "name": "Nenad Krstic",
    "rosters": [
      "88463408-f197-42d6-b955-433fadaa716d",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac"
    ]
  },
  "2421": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "212",
    "BIRTH_DATE": "FEB 25, 1980",
    "EXP": "1",
    "SCHOOL": "Illinois",
    "name": "Frank Williams",
    "rosters": [
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "1fed5db0-b968-4bc2-b296-10632898c907",
      "958e2e11-1460-4482-a252-e109fa13aef6"
    ]
  },
  "2422": {
    "NUM": "25",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "207",
    "BIRTH_DATE": "DEC 12, 1979",
    "EXP": "11",
    "SCHOOL": "Miami (FL)",
    "name": "John Salmons",
    "rosters": [
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "8f3601f5-3c15-4767-80c3-9436658cb172",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831"
    ]
  },
  "2423": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 13, 1980",
    "EXP": "R",
    "SCHOOL": "Fresno State",
    "name": "Chris Jefferies",
    "rosters": [
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "c840df16-2e76-4e5a-8ca0-c35c950288a9"
    ]
  },
  "2424": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 16, 1978",
    "EXP": "4",
    "SCHOOL": "Gonzaga",
    "name": "Dan Dickau",
    "rosters": [
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e"
    ]
  },
  "2425": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAR 29, 1980",
    "EXP": "R",
    "SCHOOL": "Illinois",
    "name": "Robert Archibald",
    "rosters": [
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "69c4432f-a9df-4bdd-b947-ef61debf2424"
    ]
  },
  "2427": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "212",
    "BIRTH_DATE": "SEP 10, 1980",
    "EXP": "3",
    "SCHOOL": "Virginia",
    "name": "Roger Mason Jr.",
    "rosters": [
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c"
    ]
  },
  "2428": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 21, 1981",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Vincent Yarbrough",
    "rosters": [
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494"
    ]
  },
  "2429": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 02, 1978",
    "EXP": "9",
    "SCHOOL": "California-Los Angeles",
    "name": "Dan Gadzuric",
    "rosters": [
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70"
    ]
  },
  "2430": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "266",
    "BIRTH_DATE": "NOV 20, 1981",
    "EXP": "7",
    "SCHOOL": "Duke",
    "name": "Carlos Boozer",
    "rosters": [
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4"
    ]
  },
  "2431": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 23, 1980",
    "EXP": "19",
    "SCHOOL": null,
    "name": "David Andersen",
    "rosters": [
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "8632a477-9924-4d11-859a-78889ddfee93"
    ]
  },
  "2432": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 08, 1981",
    "EXP": "R",
    "SCHOOL": "Fresno State",
    "name": "Tito Maddox",
    "rosters": [
      "1263d642-0f8f-47dd-b80b-8930a1814b1e"
    ]
  },
  "2435": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "260",
    "BIRTH_DATE": "APR 05, 1980",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Mario Kasun",
    "rosters": [
      "90c13ad0-73d1-41cd-ab8b-53372f677c75",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2"
    ]
  },
  "2436": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "197",
    "BIRTH_DATE": "JUL 29, 1979",
    "EXP": "4",
    "SCHOOL": "Shaw",
    "name": "Flip Murray",
    "rosters": [
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "532f6daa-fa7a-422a-9326-f0c577396c95"
    ]
  },
  "2437": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "260",
    "BIRTH_DATE": "JAN 27, 1979",
    "EXP": "3",
    "SCHOOL": "Maryland",
    "name": "Lonny Baxter",
    "rosters": [
      "ca3d9aa6-f8c5-44a6-9a4e-6b6b905d0565",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "2a8cce15-fec9-4173-8665-82815b3bfb24"
    ]
  },
  "2440": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "226",
    "BIRTH_DATE": "MAR 09, 1980",
    "EXP": "12",
    "SCHOOL": "UCLA",
    "name": "Matt Barnes",
    "rosters": [
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42"
    ]
  },
  "2441": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 15, 1983",
    "EXP": "3",
    "SCHOOL": "California",
    "name": "Jamal Sampson",
    "rosters": [
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "6aec4466-47f0-4c79-afbf-16f4fa0a29ed",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59"
    ]
  },
  "2442": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAR 01, 1979",
    "EXP": "R",
    "SCHOOL": "Texas",
    "name": "Chris Owens",
    "rosters": [
      "69c4432f-a9df-4bdd-b947-ef61debf2424"
    ]
  },
  "2443": {
    "NUM": "9",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "248",
    "BIRTH_DATE": "FEB 14, 1978",
    "EXP": "5",
    "SCHOOL": "Wake Forest",
    "name": "Darius Songaila",
    "rosters": [
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "417cf680-3d48-451b-b32a-534de216ffdb",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de"
    ]
  },
  "2446": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 23, 1979",
    "EXP": "11",
    "SCHOOL": "La Salle",
    "name": "Rasual Butler",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "f47b8402-fe32-4053-91ea-76db13aaf7eb",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "8d3a3080-9e74-4512-8869-83aad854d0a0"
    ]
  },
  "2447": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 02, 1980",
    "EXP": "1",
    "SCHOOL": "Marshall",
    "name": "Tamar Slay",
    "rosters": [
      "f9be6046-cf31-4b59-9535-b62182ab35ff",
      "88c51dfd-298e-43ce-94fa-3499edb1b267"
    ]
  },
  "2449": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 30, 1980",
    "EXP": "8",
    "SCHOOL": null,
    "name": "Luis Scola",
    "rosters": [
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "8632a477-9924-4d11-859a-78889ddfee93",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "5ac68c0d-43c1-490d-87f9-5b431453d831"
    ]
  },
  "2450": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 08, 1979",
    "EXP": "R",
    "SCHOOL": "San Diego State",
    "name": "Randy Holcomb",
    "rosters": [
      "8f3601f5-3c15-4767-80c3-9436658cb172"
    ]
  },
  "2451": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "275",
    "BIRTH_DATE": "MAR 05, 1979",
    "EXP": "R",
    "SCHOOL": "Central Connecticut State",
    "name": "Corsley Edwards",
    "rosters": [
      "792077d5-a2d6-410d-84fd-6271f14ffeca"
    ]
  },
  "2452": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 19, 1980",
    "EXP": "1",
    "SCHOOL": "St. Bonaventure",
    "name": "J.R. Bremer",
    "rosters": [
      "3c2c33cd-d25a-42c1-821f-1b7e31dd00e6",
      "919b0c55-1add-47b4-99b3-0c5f42a02455"
    ]
  },
  "2453": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 21, 1976",
    "EXP": "R",
    "SCHOOL": "Hawaii",
    "name": "Predrag Savovic",
    "rosters": [
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494"
    ]
  },
  "2454": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 02, 1980",
    "EXP": "2",
    "SCHOOL": "Wingate",
    "name": "Junior Harrington",
    "rosters": [
      "2d910a29-a4be-43ee-bfe0-3c9cd2201494",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394"
    ]
  },
  "2456": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 22, 1979",
    "EXP": "R",
    "SCHOOL": "Poland",
    "name": "Cezary Trybanski",
    "rosters": [
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "69c4432f-a9df-4bdd-b947-ef61debf2424"
    ]
  },
  "2457": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 22, 1979",
    "EXP": "9",
    "SCHOOL": "Arkansas",
    "name": "Jannero Pargo",
    "rosters": [
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "2393458a-964e-4591-aa45-9b38a23e4a91",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095",
      "45ed0581-1916-49f0-9a2f-fb15342345c5"
    ]
  },
  "2462": {
    "NUM": "4",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "270",
    "BIRTH_DATE": "JUL 27, 1977",
    "EXP": "R",
    "SCHOOL": "Iowa",
    "name": "Guy Rucker",
    "rosters": [
      "2393458a-964e-4591-aa45-9b38a23e4a91"
    ]
  },
  "2466": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 12, 1976",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Maurice Carter",
    "rosters": [
      "e7ef5a2a-d156-4c29-b503-217499918405",
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981"
    ]
  },
  "2469": {
    "NUM": "11",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "DEC 14, 1973",
    "EXP": "2",
    "SCHOOL": "Auburn",
    "name": "Pat Burke",
    "rosters": [
      "40e68a58-8b3f-418c-8207-7bb3574d4098",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b"
    ]
  },
  "2470": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 01, 1981",
    "EXP": "3",
    "SCHOOL": "Fordham",
    "name": "Smush Parker",
    "rosters": [
      "062199f5-2cc4-4d5a-a186-5a1b84e31af1",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8"
    ]
  },
  "2471": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 21, 1977",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Mike Batiste",
    "rosters": [
      "6d6518fd-faa9-4fbd-991f-20932543250e"
    ]
  },
  "2484": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 30, 1978",
    "EXP": "3",
    "SCHOOL": "Texas-San Antonio",
    "name": "Devin Brown",
    "rosters": [
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "206cbb6e-3a36-4b1f-ae88-7d9d59e55f4b",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb"
    ]
  },
  "2486": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 28, 1979",
    "EXP": "R",
    "SCHOOL": "Oklahoma State",
    "name": "Maurice Baker",
    "rosters": [
      "d8e8bc4a-30c8-477b-bf4d-faa03fef3558"
    ]
  },
  "2492": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 05, 1980",
    "EXP": "R",
    "SCHOOL": "Auburn",
    "name": "Adam Harrington",
    "rosters": [
      "04e3eeb2-6367-4dda-8490-df041615bb0b"
    ]
  },
  "2499": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 14, 1977",
    "EXP": "R",
    "SCHOOL": "Gonzaga",
    "name": "Richie Frahm",
    "rosters": [
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "719d3baf-1086-49d1-8f27-82662eb959d1",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4"
    ]
  },
  "2501": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 18, 1980",
    "EXP": "8",
    "SCHOOL": "Iowa",
    "name": "Reggie Evans",
    "rosters": [
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "8807c565-4ce6-4b7d-b189-3b9f5ce44bfd",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670"
    ]
  },
  "2544": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "250",
    "BIRTH_DATE": "DEC 30, 1984",
    "EXP": "10",
    "SCHOOL": " ",
    "name": "LeBron James",
    "rosters": [
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "db38d209-7326-41b8-948f-588f9a050e00",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368"
    ]
  },
  "2545": {
    "NUM": "31",
    "POSITION": "F-C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 20, 1985",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Darko Milicic",
    "rosters": [
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "d98d42ba-33de-433f-9c54-23973925049a",
      "824e5cff-47e8-4169-8451-74a294a985e8",
      "42f3a743-7773-4c2d-a0c7-7b57ee5f580f",
      "15e8507c-358e-47f0-807e-4a609d0a4581"
    ]
  },
  "2546": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 29, 1984",
    "EXP": "14",
    "SCHOOL": "Syracuse",
    "name": "Carmelo Anthony",
    "rosters": [
      "e98d32ee-a54c-44c0-a2d4-de81113efba0",
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9",
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33",
      "77e6e4de-42ee-4c42-be39-b046215914f2"
    ]
  },
  "2547": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 24, 1984",
    "EXP": "6",
    "SCHOOL": "Georgia Tech",
    "name": "Chris Bosh",
    "rosters": [
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "25d76ca2-0777-49ab-84f3-6abb0a1cc94f",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "91c165fd-b08a-4c73-84bf-a029d5600436",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4"
    ]
  },
  "2548": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 17, 1982",
    "EXP": "15",
    "SCHOOL": "Marquette",
    "name": "Dwyane Wade",
    "rosters": [
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73"
    ]
  },
  "2549": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "265",
    "BIRTH_DATE": "APR 28, 1982",
    "EXP": "12",
    "SCHOOL": "Central Michigan",
    "name": "Chris Kaman",
    "rosters": [
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "a4087b28-f9f0-4681-9283-5ad95ce7d650",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "cbb4c930-842b-4d16-a892-29ffd4897a48"
    ]
  },
  "2550": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 02, 1981",
    "EXP": "11",
    "SCHOOL": "Kansas",
    "name": "Kirk Hinrich",
    "rosters": [
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406"
    ]
  },
  "2551": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "165",
    "BIRTH_DATE": "MAR 24, 1983",
    "EXP": "3",
    "SCHOOL": "Texas",
    "name": "T.J. Ford",
    "rosters": [
      "65732e29-68b3-4187-b1b4-a7c5c458aeb4",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c",
      "0e001846-f440-4bca-ae94-6cf01edf7123"
    ]
  },
  "2552": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "270",
    "BIRTH_DATE": "OCT 25, 1982",
    "EXP": "1",
    "SCHOOL": "Georgetown",
    "name": "Michael Sweetney",
    "rosters": [
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "958e2e11-1460-4482-a252-e109fa13aef6",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b"
    ]
  },
  "2553": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "228",
    "BIRTH_DATE": "AUG 09, 1981",
    "EXP": "4",
    "SCHOOL": "Georgia",
    "name": "Jarvis Hayes",
    "rosters": [
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21"
    ]
  },
  "2554": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 07, 1982",
    "EXP": "9",
    "SCHOOL": null,
    "name": "Mickael Pietrus",
    "rosters": [
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "919b0c55-1add-47b4-99b3-0c5f42a02455",
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "896773e3-d58b-484d-b4ce-cba82ed75933"
    ]
  },
  "2555": {
    "NUM": "4",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "OCT 26, 1980",
    "EXP": "13",
    "SCHOOL": "Kansas",
    "name": "Nick Collison",
    "rosters": [
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c",
      "77e6e4de-42ee-4c42-be39-b046215914f2"
    ]
  },
  "2556": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 19, 1981",
    "EXP": "6",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Marcus Banks",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "91c165fd-b08a-4c73-84bf-a029d5600436",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4"
    ]
  },
  "2557": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "FEB 13, 1981",
    "EXP": "10",
    "SCHOOL": "Oregon",
    "name": "Luke Ridnour",
    "rosters": [
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "980b1ae7-558b-4c53-a0ab-a0be44d100e4",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "45ed0581-1916-49f0-9a2f-fb15342345c5"
    ]
  },
  "2558": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 07, 1981",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Reece Gaines",
    "rosters": [
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6"
    ]
  },
  "2559": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 10, 1980",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "Troy Bell",
    "rosters": [
      "0c0266f7-815a-4bf4-8339-eb026f9738d6"
    ]
  },
  "2560": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 21, 1981",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Zarko Cabarkapa",
    "rosters": [
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "8a26266a-e912-45d0-accf-1224abfe833e"
    ]
  },
  "2561": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 29, 1980",
    "EXP": "12",
    "SCHOOL": "Xavier",
    "name": "David West",
    "rosters": [
      "1fb8921b-b6d4-4a72-921b-6b47f00f3981",
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "513319a3-e55b-4c85-93d5-b45d51fa457e"
    ]
  },
  "2562": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 15, 1983",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Sasha Pavlovic",
    "rosters": [
      "88463408-f197-42d6-b955-433fadaa716d",
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460"
    ]
  },
  "2563": {
    "NUM": "30",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 27, 1980",
    "EXP": "3",
    "SCHOOL": "Duke",
    "name": "Dahntay Jones",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "0c0266f7-815a-4bf4-8339-eb026f9738d6",
      "a7e77cd3-31c9-4bd1-bfd8-072687578419",
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394"
    ]
  },
  "2564": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 16, 1982",
    "EXP": "7",
    "SCHOOL": null,
    "name": "Boris Diaw",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094",
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "272b357c-17be-4ce8-acf5-24791690c53b",
      "fc384a91-81b8-47cb-b999-2a497289f651",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9"
    ]
  },
  "2565": {
    "NUM": "10",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 12, 1982",
    "EXP": "2",
    "SCHOOL": null,
    "name": "Zoran Planinic",
    "rosters": [
      "88c51dfd-298e-43ce-94fa-3499edb1b267",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9"
    ]
  },
  "2566": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "207",
    "BIRTH_DATE": "SEP 18, 1984",
    "EXP": "10",
    "SCHOOL": "Starkville",
    "name": "Travis Outlaw",
    "rosters": [
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f"
    ]
  },
  "2567": {
    "NUM": "43",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "DEC 04, 1980",
    "EXP": "8",
    "SCHOOL": "Illinois",
    "name": "Brian Cook",
    "rosters": [
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c"
    ]
  },
  "2568": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 29, 1982",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Carlos Delfino",
    "rosters": [
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "0642a459-b265-4cac-adbd-de39b087debf",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5"
    ]
  },
  "2569": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 18, 1984",
    "EXP": "1",
    "SCHOOL": "Westbury Christian School",
    "name": "Ndudi Ebi",
    "rosters": [
      "9039135f-bde3-4971-bf34-a59c10eaaa54",
      "9d211135-ddd3-4d77-ab15-5f84131f2de6"
    ]
  },
  "2570": {
    "NUM": "5",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "270",
    "BIRTH_DATE": "NOV 10, 1984",
    "EXP": "10",
    "SCHOOL": "No College",
    "name": "Kendrick Perkins",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e"
    ]
  },
  "2571": {
    "NUM": "28",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "194",
    "BIRTH_DATE": "NOV 28, 1982",
    "EXP": "9",
    "SCHOOL": null,
    "name": "Leandro Barbosa",
    "rosters": [
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "8a26266a-e912-45d0-accf-1224abfe833e",
      "47756ea8-085e-4ada-8481-5695ff0d6ae1",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8"
    ]
  },
  "2572": {
    "NUM": "5",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 28, 1980",
    "EXP": "7",
    "SCHOOL": "Wake Forest",
    "name": "Josh Howard",
    "rosters": [
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741"
    ]
  },
  "2573": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 05, 1985",
    "EXP": "R",
    "SCHOOL": "Madrid (ESP)",
    "name": "Maciej Lampe",
    "rosters": [
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "8a26266a-e912-45d0-accf-1224abfe833e"
    ]
  },
  "2574": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "213",
    "BIRTH_DATE": "FEB 02, 1981",
    "EXP": "1",
    "SCHOOL": "California-Los Angeles",
    "name": "Jason Kapono",
    "rosters": [
      "44ae4057-4588-42ee-bc25-8d3d96f2a8d2",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "91c165fd-b08a-4c73-84bf-a029d5600436",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52"
    ]
  },
  "2575": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 28, 1980",
    "EXP": "7",
    "SCHOOL": "Arizona",
    "name": "Luke Walton",
    "rosters": [
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "9265add0-ea2a-4cd9-a77e-a4d7f8bb8eb8",
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b"
    ]
  },
  "2580": {
    "NUM": "6",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 15, 1978",
    "EXP": "R",
    "SCHOOL": "Brigham Young",
    "name": "Travis Hansen",
    "rosters": [
      "034cf132-3085-4071-860d-8f8cdea90094"
    ]
  },
  "2581": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "172",
    "BIRTH_DATE": "FEB 26, 1980",
    "EXP": "12",
    "SCHOOL": "Maryland",
    "name": "Steve Blake",
    "rosters": [
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "559e5d80-d64b-4c62-9122-9669d7a168dd",
      "1e6b480b-d9a0-4e3e-8816-e0ea549ccf12",
      "fbff0712-5fed-4e22-8afa-30387f9f0753"
    ]
  },
  "2584": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "201",
    "BIRTH_DATE": "JUL 28, 1981",
    "EXP": "6",
    "SCHOOL": "Detroit Mercy",
    "name": "Willie Green",
    "rosters": [
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "5d899f8e-5e64-42dc-8573-762857c42fff"
    ]
  },
  "2585": {
    "NUM": "27",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "270",
    "BIRTH_DATE": "FEB 10, 1984",
    "EXP": "15",
    "SCHOOL": "Tbilisi, Georgia",
    "name": "Zaza Pachulia",
    "rosters": [
      "1133cf87-e88e-4041-9357-78207256459c",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "0642a459-b265-4cac-adbd-de39b087debf",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "2586": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 12, 1980",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Keith Bogans",
    "rosters": [
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52"
    ]
  },
  "2588": {
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 05, 1980",
    "EXP": "R",
    "SCHOOL": "Florida",
    "name": "Matt Bonner",
    "rosters": [
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae"
    ],
    "NUM": "16"
  },
  "2590": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "198",
    "BIRTH_DATE": "DEC 19, 1982",
    "EXP": "11",
    "SCHOOL": "Alabama",
    "name": "Mo Williams",
    "rosters": [
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "9dd90fdb-f74c-4ed5-8ba0-6d74d680c945",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "f79cdf67-f3bc-4d80-9a09-b01eca8ee460",
      "83706098-479a-4732-9529-09c78cfdb502",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864"
    ]
  },
  "2592": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 04, 1980",
    "EXP": "4",
    "SCHOOL": "Miami (FL)",
    "name": "James Jones",
    "rosters": [
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "5fa09823-d32b-4dc4-8f59-662596e140c9",
      "428529ad-4841-486d-bcc4-9924eafbec27",
      "c8282335-91e7-4db5-923a-a392aae710c6",
      "dc7d40bc-b88f-466d-804f-bae3041ea47b",
      "1158f2a2-b551-455d-8934-8d7630f69ba5"
    ]
  },
  "2594": {
    "NUM": "26",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "212",
    "BIRTH_DATE": "MAR 17, 1981",
    "EXP": "15",
    "SCHOOL": "Creighton",
    "name": "Kyle Korver",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "cbaecc6b-0ad5-4fea-bc4b-fb1d5a0e36dd",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "2599": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "266",
    "BIRTH_DATE": "NOV 24, 1980",
    "EXP": "1",
    "SCHOOL": "Ohio",
    "name": "Brandon Hunter",
    "rosters": [
      "512c61a7-8733-4bfa-8c89-ff1e9a4b0732",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75"
    ]
  },
  "2602": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "237",
    "BIRTH_DATE": "MAY 17, 1980",
    "EXP": "R",
    "SCHOOL": "North Dakota",
    "name": "Jerome Beasley",
    "rosters": [
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6"
    ]
  },
  "2604": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 03, 1980",
    "EXP": "R",
    "SCHOOL": "Ball State",
    "name": "Theron Smith",
    "rosters": [
      "0c0266f7-815a-4bf4-8339-eb026f9738d6"
    ]
  },
  "2605": {
    "NUM": "6",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 07, 1981",
    "EXP": "7",
    "SCHOOL": "Auburn",
    "name": "Marquis Daniels",
    "rosters": [
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "2b637960-e6ab-4aca-a17d-2aef872b9e36",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "f682d9da-cb2e-4f60-b710-6accad2d386b"
    ]
  },
  "2617": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 09, 1980",
    "EXP": "16",
    "SCHOOL": "Florida",
    "name": "Udonis Haslem",
    "rosters": [
      "9f94bcab-0bb6-4af6-b3ac-51788c80f1f6",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "d1566529-2393-422f-9177-00c89290a8dc"
    ]
  },
  "2624": {
    "NUM": "3",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "193",
    "BIRTH_DATE": "APR 30, 1981",
    "EXP": "5",
    "SCHOOL": "Southern Methodist",
    "name": "Quinton Ross",
    "rosters": [
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "824e5cff-47e8-4169-8451-74a294a985e8",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b"
    ]
  },
  "2639": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 08, 1979",
    "EXP": "R",
    "SCHOOL": "Utah",
    "name": "Britton Johnsen",
    "rosters": [
      "bd150971-76b2-441a-a1ec-6d5b3d4a28a6"
    ]
  },
  "2648": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "209",
    "BIRTH_DATE": "JAN 26, 1981",
    "EXP": "3",
    "SCHOOL": "Louisiana State",
    "name": "Ronald Dupree",
    "rosters": [
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "15e8507c-358e-47f0-807e-4a609d0a4581",
      "532f6daa-fa7a-422a-9326-f0c577396c95"
    ]
  },
  "2652": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 06, 1966",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Shelton Jones",
    "rosters": [
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda"
    ]
  },
  "2668": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "AUG 10, 1980",
    "EXP": "1",
    "SCHOOL": "Wyoming",
    "name": "Josh Davis",
    "rosters": [
      "31b7b9bc-492b-4509-99ca-b219bfcf6739"
    ]
  },
  "2669": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 13, 1980",
    "EXP": "4",
    "SCHOOL": "Tulane",
    "name": "Linton Johnson",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "7a2f643b-b036-4b7b-8c60-33358df26aba",
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706"
    ]
  },
  "2679": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "212",
    "BIRTH_DATE": "AUG 28, 1980",
    "EXP": "8",
    "SCHOOL": "Notre Dame",
    "name": "Matt Carroll",
    "rosters": [
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52",
      "2a8cce15-fec9-4173-8665-82815b3bfb24",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e",
      "a16e37d5-d174-4f55-bfdc-ba838caae024",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6"
    ]
  },
  "2682": {
    "NUM": "10",
    "POSITION": "G-F",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 04, 1980",
    "EXP": "R",
    "SCHOOL": "Brazil",
    "name": "Alex Garcia",
    "rosters": [
      "884e585b-12f2-4f77-acba-9ab3eb9d71ae"
    ]
  },
  "2693": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 05, 1979",
    "EXP": "2",
    "SCHOOL": "Bowling Green",
    "name": "Keith McLeod",
    "rosters": [
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "28eacab0-70b6-464e-9364-355a0c9123d3",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb"
    ]
  },
  "2694": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 25, 1983",
    "EXP": "4",
    "SCHOOL": "North Carolina State",
    "name": "Josh Powell",
    "rosters": [
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0"
    ]
  },
  "2696": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 23, 1979",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Lynn Greer",
    "rosters": [
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee"
    ]
  },
  "2724": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 22, 1977",
    "EXP": "R",
    "SCHOOL": "Detroit Mercy",
    "name": "Desmond Ferguson",
    "rosters": [
      "b6c7d8f4-74ee-4b79-a14e-71fe557bbeae"
    ]
  },
  "2730": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "265",
    "BIRTH_DATE": "DEC 08, 1985",
    "EXP": "13",
    "SCHOOL": " ",
    "name": "Dwight Howard",
    "rosters": [
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "db38d209-7326-41b8-948f-588f9a050e00",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "6631c074-5c99-48db-b77d-c92e855be5bd",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9"
    ]
  },
  "2731": {
    "NUM": "50",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "SEP 28, 1982",
    "EXP": "4",
    "SCHOOL": "Connecticut",
    "name": "Emeka Okafor",
    "rosters": [
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52",
      "2a8cce15-fec9-4173-8665-82815b3bfb24",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e",
      "a16e37d5-d174-4f55-bfdc-ba838caae024",
      "fc384a91-81b8-47cb-b999-2a497289f651"
    ]
  },
  "2732": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 04, 1983",
    "EXP": "8",
    "SCHOOL": "Connecticut",
    "name": "Ben Gordon",
    "rosters": [
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095"
    ]
  },
  "2733": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 11, 1985",
    "EXP": "5",
    "SCHOOL": "No College",
    "name": "Shaun Livingston",
    "rosters": [
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "1e6ac4af-bcdd-401d-b757-21a9d0087883",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9"
    ]
  },
  "2734": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "192",
    "BIRTH_DATE": "FEB 27, 1983",
    "EXP": "7",
    "SCHOOL": "Wisconsin",
    "name": "Devin Harris",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "4593ad14-6159-4388-bbcc-ff4643a59315",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de"
    ]
  },
  "2735": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 20, 1983",
    "EXP": "5",
    "SCHOOL": "Stanford",
    "name": "Josh Childress",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "1133cf87-e88e-4041-9357-78207256459c",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "fa166c7c-372a-489f-b069-635a9196ea73"
    ]
  },
  "2736": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "237",
    "BIRTH_DATE": "APR 16, 1985",
    "EXP": "14",
    "SCHOOL": "Duke",
    "name": "Luol Deng",
    "rosters": [
      "51581511-27eb-417b-be7a-c3792b97c046",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f"
    ]
  },
  "2737": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "270",
    "BIRTH_DATE": "AUG 12, 1980",
    "EXP": "2",
    "SCHOOL": "Brigham Young",
    "name": "Rafael Araujo",
    "rosters": [
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e"
    ]
  },
  "2738": {
    "NUM": "9",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "207",
    "BIRTH_DATE": "JAN 28, 1984",
    "EXP": "7",
    "SCHOOL": "Arizona",
    "name": "Andre Iguodala",
    "rosters": [
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "d1566529-2393-422f-9177-00c89290a8dc",
      "31b7b9bc-492b-4509-99ca-b219bfcf6739",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68"
    ]
  },
  "2739": {
    "NUM": "6",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 06, 1981",
    "EXP": "2",
    "SCHOOL": "Oregon",
    "name": "Luke Jackson",
    "rosters": [
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c"
    ]
  },
  "2740": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "242",
    "BIRTH_DATE": "APR 02, 1986",
    "EXP": "8",
    "SCHOOL": null,
    "name": "Andris Biedrins",
    "rosters": [
      "ef222756-c1ac-4a73-95d8-003390f0ea1c",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "728d43da-a0f1-4599-96c2-a09036340618",
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "674c9b5e-4ae4-466f-ae8c-c27783858243"
    ]
  },
  "2741": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "270",
    "BIRTH_DATE": "DEC 03, 1985",
    "EXP": "3",
    "SCHOOL": "Bakersfield",
    "name": "Robert Swift",
    "rosters": [
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "23acb720-d0cf-4182-9809-8a82ffe3779c"
    ]
  },
  "2742": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUN 09, 1985",
    "EXP": "8",
    "SCHOOL": "Abraham Lincoln",
    "name": "Sebastian Telfair",
    "rosters": [
      "86b93b83-92a1-4756-8676-68efd7025399",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "896773e3-d58b-484d-b4ce-cba82ed75933"
    ]
  },
  "2743": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "FEB 06, 1985",
    "EXP": "10",
    "SCHOOL": "Minnesota",
    "name": "Kris Humphries",
    "rosters": [
      "e024d958-351f-40c8-be44-4c56111346b2",
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "91c165fd-b08a-4c73-84bf-a029d5600436",
      "28eacab0-70b6-464e-9364-355a0c9123d3",
      "8d3a3080-9e74-4512-8869-83aad854d0a0"
    ]
  },
  "2744": {
    "NUM": "25",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "289",
    "BIRTH_DATE": "JAN 04, 1985",
    "EXP": "11",
    "SCHOOL": "No College",
    "name": "Al Jefferson",
    "rosters": [
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502",
      "45ed0581-1916-49f0-9a2f-fb15342345c5",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864",
      "31164298-2c0d-4a44-b557-e740801b4b1d"
    ]
  },
  "2745": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 05, 1983",
    "EXP": "R",
    "SCHOOL": "Nevada-Reno",
    "name": "Kirk Snyder",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "28eacab0-70b6-464e-9364-355a0c9123d3"
    ]
  },
  "2746": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 05, 1985",
    "EXP": "9",
    "SCHOOL": "No College",
    "name": "Josh Smith",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "1133cf87-e88e-4041-9357-78207256459c",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "2747": {
    "NUM": "8",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 09, 1985",
    "EXP": "9",
    "SCHOOL": "No College",
    "name": "JR Smith",
    "rosters": [
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba",
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b"
    ]
  },
  "2748": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 02, 1985",
    "EXP": "10",
    "SCHOOL": "South Kent School",
    "name": "Dorell Wright",
    "rosters": [
      "728d43da-a0f1-4599-96c2-a09036340618",
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "eccc0600-ee8d-4366-a3d8-cb9e66f1b862",
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b"
    ]
  },
  "2749": {
    "NUM": "41",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 09, 1982",
    "EXP": "13",
    "SCHOOL": null,
    "name": "Jameer Nelson",
    "rosters": [
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "45324799-5d41-45eb-b5ff-b3095f173c43"
    ]
  },
  "2751": {
    "NUM": "38",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 03, 1982",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Viktor Khryapa",
    "rosters": [
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20"
    ]
  },
  "2753": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 26, 1983",
    "EXP": "7",
    "SCHOOL": null,
    "name": "Delonte West",
    "rosters": [
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "88463408-f197-42d6-b955-433fadaa716d",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a"
    ]
  },
  "2754": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "213",
    "BIRTH_DATE": "JAN 11, 1982",
    "EXP": "12",
    "SCHOOL": "Oklahoma State",
    "name": "Tony Allen",
    "rosters": [
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "bce414a0-1435-4744-9faf-b7027085c341",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289",
      "323505fb-0be2-4c98-96be-9a14a40d1c78",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d"
    ]
  },
  "2755": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 01, 1983",
    "EXP": "8",
    "SCHOOL": "Western Carolina",
    "name": "Kevin Martin",
    "rosters": [
      "8632a477-9924-4d11-859a-78889ddfee93",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "8de840b0-bf96-42ee-9a11-49735c4c8b63",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc"
    ]
  },
  "2756": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 08, 1984",
    "EXP": "9",
    "SCHOOL": null,
    "name": "Sasha Vujacic",
    "rosters": [
      "4fc89f5d-17f8-4ef5-a561-d2787bc910ba",
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5"
    ]
  },
  "2757": {
    "NUM": "19",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 05, 1982",
    "EXP": "12",
    "SCHOOL": null,
    "name": "Beno Udrih",
    "rosters": [
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "f682d9da-cb2e-4f60-b710-6accad2d386b",
      "58289a19-dade-4b36-bd1f-10ba99958a24",
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e"
    ]
  },
  "2758": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "280",
    "BIRTH_DATE": "AUG 15, 1982",
    "EXP": "3",
    "SCHOOL": "Colorado",
    "name": "David Harrison",
    "rosters": [
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268"
    ]
  },
  "2760": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "273",
    "BIRTH_DATE": "SEP 28, 1982",
    "EXP": "11",
    "SCHOOL": " ",
    "name": "Anderson Varejao",
    "rosters": [
      "515e4e87-1f51-4a53-a360-a419dc17ac74",
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "51581511-27eb-417b-be7a-c3792b97c046",
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42"
    ]
  },
  "2761": {
    "NUM": "4",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 06, 1981",
    "EXP": "R",
    "SCHOOL": "Iowa State",
    "name": "Jackson Vroman",
    "rosters": [
      "8749b250-741f-47ab-a2f0-4f32ee5b3eba"
    ]
  },
  "2763": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 10, 1980",
    "EXP": "R",
    "SCHOOL": "Xavier",
    "name": "Lionel Chalmers",
    "rosters": [
      "1e6ac4af-bcdd-401d-b757-21a9d0087883"
    ]
  },
  "2764": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 27, 1983",
    "EXP": "1",
    "SCHOOL": "S.E.Illinois",
    "name": "Donta Smith",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "1133cf87-e88e-4041-9357-78207256459c"
    ]
  },
  "2766": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 25, 1980",
    "EXP": "1",
    "SCHOOL": "Memphis",
    "name": "Antonio Burks",
    "rosters": [
      "a7e77cd3-31c9-4bd1-bfd8-072687578419",
      "b00a755a-14e3-4682-bed6-49df9136ad09"
    ]
  },
  "2767": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 20, 1981",
    "EXP": "7",
    "SCHOOL": "Brigham Young",
    "name": "Royal Ivey",
    "rosters": [
      "79883e1e-2f57-488f-80fe-7e9c9148ff6b",
      "1133cf87-e88e-4041-9357-78207256459c",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d"
    ]
  },
  "2768": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 31, 1982",
    "EXP": "7",
    "SCHOOL": "Duke",
    "name": "Chris Duhon",
    "rosters": [
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "d52a9555-ff77-4418-a883-c18272a57975",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7"
    ]
  },
  "2770": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 16, 1982",
    "EXP": "1",
    "SCHOOL": "Mississippi",
    "name": "Justin Reed",
    "rosters": [
      "d1d4c11f-4d18-44d8-b927-bdb1bf9e223a",
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a"
    ]
  },
  "2772": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 30, 1985",
    "EXP": "14",
    "SCHOOL": "UCLA",
    "name": "Trevor Ariza",
    "rosters": [
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "8632a477-9924-4d11-859a-78889ddfee93",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "2774": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 26, 1980",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "Bernard Robinson",
    "rosters": [
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "f93a587a-40ff-4bbc-8ef6-fa6117ba3a52",
      "2a8cce15-fec9-4173-8665-82815b3bfb24"
    ]
  },
  "2775": {
    "NUM": "5",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "305",
    "BIRTH_DATE": "AUG 04, 1985",
    "EXP": "1",
    "SCHOOL": "Yonsei (KOR)",
    "name": "Ha Ha",
    "rosters": [
      "7f469a5a-51d3-4e48-81cc-36627dd4855c",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20"
    ]
  },
  "2776": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 22, 1981",
    "EXP": "2",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Pape Sow",
    "rosters": [
      "15b3f603-0e98-4097-a9a7-c6c2689d36ae",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c"
    ]
  },
  "2779": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 02, 1982",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Vassilis Spanoulis",
    "rosters": [
      "4852e234-5b8e-4b03-b656-686bfe7506c0"
    ]
  },
  "2784": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 11, 1981",
    "EXP": "R",
    "SCHOOL": "Manhattan",
    "name": "Luis Flores",
    "rosters": [
      "b8701d1f-d5cd-4826-b63a-3977c7671cd9"
    ]
  },
  "2788": {
    "NUM": "28",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "DEC 30, 1980",
    "EXP": "5",
    "SCHOOL": "Kinshasa, DRC",
    "name": "DJ Mbenga",
    "rosters": [
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "a735f539-4e33-4e2c-a87f-c0d19a0a64eb",
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0"
    ]
  },
  "2798": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 14, 1974",
    "EXP": "R",
    "SCHOOL": "William Paterson",
    "name": "Horace Jenkins",
    "rosters": [
      "15e8507c-358e-47f0-807e-4a609d0a4581"
    ]
  },
  "2804": {
    "NUM": "5",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 30, 1979",
    "EXP": "5",
    "SCHOOL": "Santa Fe, Argentina",
    "name": "Andres Nocioni",
    "rosters": [
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14"
    ]
  },
  "2810": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 12, 1981",
    "EXP": "1",
    "SCHOOL": "DePaul",
    "name": "Andre Brown",
    "rosters": [
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d98d42ba-33de-433f-9c54-23973925049a"
    ]
  },
  "2823": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "275",
    "BIRTH_DATE": "JUL 31, 1981",
    "EXP": "R",
    "SCHOOL": "Kent State",
    "name": "John Edwards",
    "rosters": [
      "1133cf87-e88e-4041-9357-78207256459c",
      "428529ad-4841-486d-bcc4-9924eafbec27"
    ]
  },
  "2845": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "214",
    "BIRTH_DATE": "APR 01, 1982",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Erik Daniels",
    "rosters": [
      "8de840b0-bf96-42ee-9a11-49735c4c8b63"
    ]
  },
  "2852": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 18, 1977",
    "EXP": "1",
    "SCHOOL": "Arizona State",
    "name": "Awvee Storey",
    "rosters": [
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2"
    ]
  },
  "2853": {
    "NUM": "40",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 14, 1981",
    "EXP": "4",
    "SCHOOL": "Memphis",
    "name": "Earl Barron",
    "rosters": [
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "d52a9555-ff77-4418-a883-c18272a57975",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "8e1bb40f-2c63-4904-9e81-67592b326bda"
    ]
  },
  "2857": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "173",
    "BIRTH_DATE": "FEB 21, 1982",
    "EXP": "1",
    "SCHOOL": "Seton Hall",
    "name": "Andre Barrett",
    "rosters": [
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75",
      "53990650-b132-46c8-9435-b3308f7a0b10"
    ]
  },
  "2863": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 11, 1980",
    "EXP": "7",
    "SCHOOL": "Georgia",
    "name": "Damien Wilkins",
    "rosters": [
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "e3e9e4cc-8773-4ff7-95df-40a717fe5ee4",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1"
    ]
  },
  "2866": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "MAR 10, 1985",
    "EXP": "2",
    "SCHOOL": "Southern Illinois",
    "name": "Jackie Butler",
    "rosters": [
      "b6891896-8a10-4dba-b6e6-c01d5f85ba3b",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2"
    ]
  },
  "2867": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 18, 1981",
    "EXP": "R",
    "SCHOOL": "Texas Tech",
    "name": "Kasib Powell",
    "rosters": [
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba"
    ]
  },
  "2873": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 23, 1975",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Billy Thomas",
    "rosters": [
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "99c6aea9-27d0-453f-a643-716447513a5f",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2"
    ]
  },
  "2876": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "APR 08, 1982",
    "EXP": "1",
    "SCHOOL": "Iowa",
    "name": "Jared Reiner",
    "rosters": [
      "c2deab32-c38d-40ff-aff3-0be69da10978",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee"
    ]
  },
  "2891": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 25, 1975",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "Mark Jones",
    "rosters": [
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "90c13ad0-73d1-41cd-ab8b-53372f677c75"
    ]
  },
  "76001": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 24, 1968",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Alaa Abdelnaby",
    "rosters": [
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e"
    ]
  },
  "76002": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 07, 1946",
    "EXP": "7",
    "SCHOOL": "Iowa State",
    "name": "Zaid Abdul-Aziz",
    "rosters": [
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604",
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c"
    ]
  },
  "76003": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 16, 1947",
    "EXP": "5",
    "SCHOOL": "California-Los Angeles",
    "name": "Kareem Abdul-Jabbar",
    "rosters": [
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77",
      "fff2243e-9747-44b5-8882-14d1d9d99603",
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "c3077e01-3d45-4725-9d9e-90a13761dbe8",
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "30c679a6-4b92-42bc-b03d-303894f5aed9",
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6",
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e"
    ]
  },
  "76005": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 06, 1954",
    "EXP": "4",
    "SCHOOL": "Indiana",
    "name": "Tom Abernethy",
    "rosters": [
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77",
      "fff2243e-9747-44b5-8882-14d1d9d99603",
      "321bccfb-8638-4330-a1da-00ee8d4007e8"
    ]
  },
  "76006": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 27, 1932",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Forest Able",
    "rosters": [
      "63ab2d0f-d691-48e0-9892-45f784ea03d5"
    ]
  },
  "76008": {
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "183",
    "BIRTH_DATE": "SEP 04, 1930",
    "EXP": "R",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Donald Ackerman",
    "rosters": [
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d"
    ]
  },
  "76009": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 15, 1962",
    "EXP": "5",
    "SCHOOL": "Oral Roberts",
    "name": "Mark Acres",
    "rosters": [
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05"
    ]
  },
  "76010": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 11, 1942",
    "EXP": "R",
    "SCHOOL": "Hillsdale",
    "name": "Charles Acton",
    "rosters": [
      "e511f2f0-158b-44a1-90e9-a101165211e2"
    ]
  },
  "76011": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 19, 1954",
    "EXP": "12",
    "SCHOOL": "Oklahoma",
    "name": "Alvan Adams",
    "rosters": [
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "e8500690-7102-4acf-ae8b-f1907cf60bcf",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "ca66d0d0-8947-439e-8245-2fecd01da018"
    ]
  },
  "76012": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 27, 1947",
    "EXP": "4",
    "SCHOOL": "Northwestern",
    "name": "Don Adams",
    "rosters": [
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185",
      "94f36f95-2ce4-4f55-8a83-19b910b11438"
    ]
  },
  "76015": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 16, 1946",
    "EXP": "6",
    "SCHOOL": "Loyola-Marymount",
    "name": "Rick Adelman",
    "rosters": [
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4",
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "66c852c8-74e2-482e-8745-846263fa3f58"
    ]
  },
  "76016": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "232",
    "BIRTH_DATE": "DEC 10, 1959",
    "EXP": "11",
    "SCHOOL": "DePaul",
    "name": "Mark Aguirre",
    "rosters": [
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c",
      "db85dc84-6de1-4038-8c49-8f636dd20715",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "76017": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 17, 1959",
    "EXP": "8",
    "SCHOOL": "Brigham Young",
    "name": "Danny Ainge",
    "rosters": [
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "a7a2aca5-5373-4d2f-b138-bf2492160e90",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "65fe3c0f-07c5-469c-b786-9542d2d55742",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff"
    ]
  },
  "76018": {
    "NUM": "10",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 31, 1944",
    "EXP": "1",
    "SCHOOL": "Morehead State",
    "name": "Henry Akin",
    "rosters": [
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa"
    ]
  },
  "76019": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "217",
    "BIRTH_DATE": "DEC 11, 1963",
    "EXP": "4",
    "SCHOOL": "Duke",
    "name": "Mark Alarie",
    "rosters": [
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58",
      "44e38364-f6e8-4d07-bb01-e7f82356367c",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6"
    ]
  },
  "76020": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 08, 1936",
    "EXP": "R",
    "SCHOOL": "Fresno State",
    "name": "Gary Alcorn",
    "rosters": [
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "29b33649-2e3d-444b-81b4-caec18163bdd"
    ]
  },
  "76021": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "FEB 26, 1959",
    "EXP": "R",
    "SCHOOL": "Connecticut",
    "name": "Chuck Aleksinas",
    "rosters": [
      "d4f7a688-d761-4a62-88cf-2893af24c272"
    ]
  },
  "76022": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 01, 1969",
    "EXP": "R",
    "SCHOOL": "South Florida",
    "name": "Gary Alexander",
    "rosters": [
      "2d01fe82-37d3-4641-8980-b545cafd6e1d"
    ]
  },
  "76024": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "183",
    "BIRTH_DATE": "NOV 23, 1964",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "Steve Alford",
    "rosters": [
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482"
    ]
  },
  "76025": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 26, 1965",
    "EXP": "1",
    "SCHOOL": "Florida State",
    "name": "Randy Allen",
    "rosters": [
      "65fe3c0f-07c5-469c-b786-9542d2d55742",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff"
    ]
  },
  "76027": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 26, 1947",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Lucius Allen",
    "rosters": [
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77",
      "30c679a6-4b92-42bc-b03d-303894f5aed9",
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6",
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "3829b824-1221-432d-8dc1-388a4d2f1cce"
    ]
  },
  "76028": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 17, 1946",
    "EXP": "R",
    "SCHOOL": "Marshall",
    "name": "Bob Allen",
    "rosters": [
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e"
    ]
  },
  "76029": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 02, 1949",
    "EXP": "R",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Odis Allison",
    "rosters": [
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd"
    ]
  },
  "76030": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 12, 1958",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Darrell Allums",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9"
    ]
  },
  "76034": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 24, 1937",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Bob Anderegg",
    "rosters": [
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b"
    ]
  },
  "76035": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 07, 1944",
    "EXP": "2",
    "SCHOOL": "Saint Joseph's",
    "name": "Cliff Anderson",
    "rosters": [
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "8eeab17f-aa62-4d99-9e73-e5182968de63"
    ]
  },
  "76036": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 01, 1951",
    "EXP": "1",
    "SCHOOL": "Southern California",
    "name": "Daniel Anderson",
    "rosters": [
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "cbf01feb-6986-43c2-8350-23e707cf8272"
    ]
  },
  "76037": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 28, 1960",
    "EXP": "R",
    "SCHOOL": "Southern California",
    "name": "Dwight Anderson",
    "rosters": [
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e"
    ]
  },
  "76040": {
    "NUM": "21-23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 09, 1953",
    "EXP": "1",
    "SCHOOL": "West Virginia",
    "name": "Jerome Anderson",
    "rosters": [
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d"
    ]
  },
  "76041": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 12, 1955",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Kim Anderson",
    "rosters": [
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd"
    ]
  },
  "76042": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "184",
    "BIRTH_DATE": "MAR 23, 1966",
    "EXP": "R",
    "SCHOOL": "Drexel",
    "name": "Michael Anderson",
    "rosters": [
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a"
    ]
  },
  "76043": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 23, 1960",
    "EXP": "2",
    "SCHOOL": "Bradley",
    "name": "Mitchell Anderson",
    "rosters": [
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a"
    ]
  },
  "76045": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 19, 1960",
    "EXP": "5",
    "SCHOOL": "California-Santa Barbara",
    "name": "Richard Anderson",
    "rosters": [
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "76046": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 15, 1958",
    "EXP": "9",
    "SCHOOL": "Fresno State",
    "name": "Ron Anderson",
    "rosters": [
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7"
    ]
  },
  "76048": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 11, 1946",
    "EXP": "R",
    "SCHOOL": "Creighton",
    "name": "Wally Anderzunas",
    "rosters": [
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa"
    ]
  },
  "76049": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 01, 1930",
    "EXP": "R",
    "SCHOOL": "Missouri State",
    "name": "Don Anielak",
    "rosters": [
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa"
    ]
  },
  "76050": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 08, 1967",
    "EXP": "2",
    "SCHOOL": "Alabama",
    "name": "Michael Ansley",
    "rosters": [
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170"
    ]
  },
  "76053": {
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 17, 1936",
    "EXP": "R",
    "SCHOOL": "Iowa State",
    "name": "Stacey Arceneaux",
    "rosters": [
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0"
    ]
  },
  "76054": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "150",
    "BIRTH_DATE": "SEP 02, 1948",
    "EXP": "5",
    "SCHOOL": "Texas-El Paso",
    "name": "Nate Archibald",
    "rosters": [
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "66c852c8-74e2-482e-8745-846263fa3f58",
      "dd460500-38ea-4afb-99af-548234a3036d"
    ]
  },
  "76055": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 19, 1948",
    "EXP": "3",
    "SCHOOL": "Cincinnati",
    "name": "Jim Ard",
    "rosters": [
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7"
    ]
  },
  "76056": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 09, 1928",
    "EXP": "9",
    "SCHOOL": "Villanova",
    "name": "Paul Arizin",
    "rosters": [
      "78cf5ce6-637f-459d-812a-47c6b4615dc4",
      "1c190e61-1a8b-4cd1-aba2-fae468830890",
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86",
      "05b98b66-c377-4196-b63f-7c9749f98f47",
      "59113a13-7bcb-4a63-908c-a566386976eb",
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447",
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae",
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5"
    ]
  },
  "76057": {
    "NUM": "53",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 20, 1965",
    "EXP": "R",
    "SCHOOL": "Niagara",
    "name": "Joe Arlauckas",
    "rosters": [
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "76059": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 05, 1955",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Tate Armstrong",
    "rosters": [
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d"
    ]
  },
  "76060": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "170",
    "BIRTH_DATE": "NOV 01, 1918",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "Paul Armstrong",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922",
      "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e",
      "648376a8-6ee8-4cad-97d2-369540020f50"
    ]
  },
  "76061": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 17, 1933",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Bob Armstrong",
    "rosters": [
      "59113a13-7bcb-4a63-908c-a566386976eb"
    ]
  },
  "76062": {
    "NUM": "15-18",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 30, 1933",
    "EXP": "R",
    "SCHOOL": "Penn State",
    "name": "Jesse Arnelle",
    "rosters": [
      "0a699404-02aa-4953-8be2-08a5fc726ea3"
    ]
  },
  "76063": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "DEC 19, 1938",
    "EXP": "2",
    "SCHOOL": "Texas",
    "name": "Jay Arnette",
    "rosters": [
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef"
    ]
  },
  "76064": {
    "NUM": "21-30",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 03, 1947",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Bob Arnzen",
    "rosters": [
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39"
    ]
  },
  "76065": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 15, 1947",
    "EXP": "R",
    "SCHOOL": "Tulane",
    "name": "John Arthurs",
    "rosters": [
      "c7ab28ba-96bf-465d-b793-b95781330560"
    ]
  },
  "76069": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 21, 1931",
    "EXP": "1",
    "SCHOOL": "Indiana State",
    "name": "Richard Atha",
    "rosters": [
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3"
    ]
  },
  "76070": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 07, 1936",
    "EXP": "10",
    "SCHOOL": "North Carolina A&T",
    "name": "Alvin Attles",
    "rosters": [
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae",
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5",
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "852d8746-a465-41f3-b644-085c99bbad27"
    ]
  },
  "76073": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "AUG 31, 1944",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "John Austin",
    "rosters": [
      "1afcc38d-6d62-4ced-a2db-41985c45adb4"
    ]
  },
  "76074": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 15, 1961",
    "EXP": "R",
    "SCHOOL": "Rice",
    "name": "Ken Austin",
    "rosters": [
      "1a9e125c-e465-42e3-a800-c69234389252"
    ]
  },
  "76076": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUL 22, 1952",
    "EXP": "1",
    "SCHOOL": "Pepperdine",
    "name": "William Averitt",
    "rosters": [
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "0c82b7b8-0084-4eef-82c7-89296e03c73e"
    ]
  },
  "76078": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "FEB 22, 1948",
    "EXP": "10",
    "SCHOOL": "Santa Clara",
    "name": "Dennis Awtrey",
    "rosters": [
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "6cb87ca5-b208-46a1-b393-022423c35c0d"
    ]
  },
  "76079": {
    "NUM": "00",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 23, 1968",
    "EXP": "1",
    "SCHOOL": "Tennessee Tech",
    "name": "Milos Babic",
    "rosters": [
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030"
    ]
  },
  "76080": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 10, 1924",
    "EXP": "R",
    "SCHOOL": "Fordham",
    "name": "Johnny Bach",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "76081": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 09, 1927",
    "EXP": "4",
    "SCHOOL": "Eastern Kentucky",
    "name": "Jim Baechtold",
    "rosters": [
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d",
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64"
    ]
  },
  "76082": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 23, 1960",
    "EXP": "6",
    "SCHOOL": "Boston College",
    "name": "John Bagley",
    "rosters": [
      "06369f70-ef9a-4da3-8cef-0a062e244b3c",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef"
    ]
  },
  "76083": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 18, 1951",
    "EXP": "4",
    "SCHOOL": "Texas-El Paso",
    "name": "Gus Bailey",
    "rosters": [
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "5517a85a-4623-414c-89a6-b90bb5a42eca",
      "a8b9d60d-1449-49af-89fc-945fadd03a04",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409"
    ]
  },
  "76084": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 23, 1958",
    "EXP": "R",
    "SCHOOL": "Tuskegee",
    "name": "Carl Bailey",
    "rosters": [
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f"
    ]
  },
  "76085": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 21, 1957",
    "EXP": "1",
    "SCHOOL": "Rutgers",
    "name": "James Bailey",
    "rosters": [
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "278e33a4-dd30-4a35-b7be-2e7b71623707",
      "6cb87ca5-b208-46a1-b393-022423c35c0d"
    ]
  },
  "76090": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 16, 1968",
    "EXP": "R",
    "SCHOOL": "North Carolina-Charlotte",
    "name": "Cedric Ball",
    "rosters": [
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea"
    ]
  },
  "76091": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 29, 1955",
    "EXP": "7",
    "SCHOOL": "Oregon",
    "name": "Greg Ballard",
    "rosters": [
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "dd527857-adb8-40c3-9804-8aa4212b8486",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409",
      "a52d7bc3-ed81-4836-b70b-85c958af2548",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228"
    ]
  },
  "76093": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 15, 1959",
    "EXP": "3",
    "SCHOOL": "Duke",
    "name": "Gene Banks",
    "rosters": [
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "5c09ec26-5505-4508-98c5-db244798d9d1"
    ]
  },
  "76094": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 01, 1960",
    "EXP": "1",
    "SCHOOL": "Saint Augustine's",
    "name": "Ken Bannister",
    "rosters": [
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa"
    ]
  },
  "76095": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 03, 1951",
    "EXP": "2",
    "SCHOOL": "Saint Joseph's",
    "name": "Mike Bantom",
    "rosters": [
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "b53d3c18-a4f1-40bf-a266-2555fa606018",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c"
    ]
  },
  "76096": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 27, 1927",
    "EXP": "R",
    "SCHOOL": "Cal State-Los Angeles",
    "name": "John Barber",
    "rosters": [
      "83150f4c-0043-4b65-834a-7af2f1b9b071"
    ]
  },
  "76097": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 05, 1968",
    "EXP": "2",
    "SCHOOL": "Illinois",
    "name": "Stephen Bardo",
    "rosters": [
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "0c8c4333-8ea1-4a4c-8375-46dfd48391ff"
    ]
  },
  "76099": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 11, 1955",
    "EXP": "1",
    "SCHOOL": "Hawaii",
    "name": "Tom Barker",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "b001782c-95ad-444b-814b-98ecf23fa9ce"
    ]
  },
  "76102": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 31, 1923",
    "EXP": "3",
    "SCHOOL": "UCLA",
    "name": "Don Barksdale",
    "rosters": [
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "0f305d34-33d7-4e16-a669-f7ab442c151d"
    ]
  },
  "76103": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 25, 1945",
    "EXP": "R",
    "SCHOOL": "Northeastern",
    "name": "Harry Barnes",
    "rosters": [
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d"
    ]
  },
  "76104": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 27, 1952",
    "EXP": "R",
    "SCHOOL": "Providence",
    "name": "Marvin Barnes",
    "rosters": [
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "ef880e29-8409-4464-a009-ac827384a450",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f"
    ]
  },
  "76105": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 13, 1941",
    "EXP": "6",
    "SCHOOL": "Texas-El Paso",
    "name": "Jim Barnes",
    "rosters": [
      "b576b451-62e4-4a16-98e2-535cecc8e551",
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "ab91fee8-d6df-4549-ad42-04456017afc3"
    ]
  },
  "76106": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUL 07, 1944",
    "EXP": "8",
    "SCHOOL": "Oregon",
    "name": "Jim Barnett",
    "rosters": [
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864",
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "52649402-55a4-4d97-a5fd-f7cf9779a672",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "76107": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 02, 1936",
    "EXP": "1",
    "SCHOOL": "Tennessee State",
    "name": "Dick Barnett",
    "rosters": [
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "a45c9665-5951-47aa-ad90-2e2f872cee40",
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2"
    ]
  },
  "76108": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 20, 1938",
    "EXP": "3",
    "SCHOOL": "Tennessee State",
    "name": "John Barnhill",
    "rosters": [
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "61110628-8f7d-478f-b171-52455d3bf9d0"
    ]
  },
  "76109": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 15, 1953",
    "EXP": "R",
    "SCHOOL": "Washington State",
    "name": "Norton Barnhill",
    "rosters": [
      "8718c236-d862-42de-a84d-ef29c83369b9"
    ]
  },
  "76110": {
    "NUM": "8-15",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 11, 1924",
    "EXP": "4",
    "SCHOOL": "Notre Dame",
    "name": "Leo Barnhorst",
    "rosters": [
      "57494c03-afc0-41de-b544-659990d5e952"
    ]
  },
  "76112": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 19, 1950",
    "EXP": "R",
    "SCHOOL": "Duquesne",
    "name": "Mike Barr",
    "rosters": [
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e"
    ]
  },
  "76113": {
    "NUM": "19",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 19, 1944",
    "EXP": "R",
    "SCHOOL": "Duquesne",
    "name": "Thomas Barr",
    "rosters": [
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39"
    ]
  },
  "76114": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "5-8",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 27, 1929",
    "EXP": "1",
    "SCHOOL": "Kansas State",
    "name": "Ernie Barrett",
    "rosters": [
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "75b95e75-2397-48c9-b255-676792b7226d"
    ]
  },
  "76118": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 29, 1948",
    "EXP": "R",
    "SCHOOL": "Oregon State",
    "name": "Vic Bartolome",
    "rosters": [
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd"
    ]
  },
  "76119": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 10, 1951",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Jerry Baskerville",
    "rosters": [
      "41977d46-c447-4c82-8cb1-8952bd2b335e"
    ]
  },
  "76120": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 01, 1951",
    "EXP": "3",
    "SCHOOL": "Georgia",
    "name": "Tim Bassett",
    "rosters": [
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a"
    ]
  },
  "76121": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 31, 1956",
    "EXP": "2",
    "SCHOOL": "Kentucky State",
    "name": "Billyray Bates",
    "rosters": [
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "4ac401d3-899b-427d-89d6-584fe69ebf75",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f"
    ]
  },
  "76123": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 10, 1964",
    "EXP": "R",
    "SCHOOL": "Illinois",
    "name": "Kenny Battle",
    "rosters": [
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8"
    ]
  },
  "76124": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 26, 1956",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Dave Batton",
    "rosters": [
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95"
    ]
  },
  "76125": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 17, 1946",
    "EXP": "1",
    "SCHOOL": "Temple",
    "name": "Johnny Baum",
    "rosters": [
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2"
    ]
  },
  "76127": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 16, 1934",
    "EXP": "13",
    "SCHOOL": "Seattle",
    "name": "Elgin Baylor",
    "rosters": [
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f"
    ]
  },
  "76128": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "168",
    "BIRTH_DATE": "MAR 15, 1965",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Sergei Bazarevich",
    "rosters": [
      "a859ae74-acea-4f80-abaa-92f971001064"
    ]
  },
  "76129": {
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 25, 1929",
    "EXP": "R",
    "SCHOOL": "West Virginia",
    "name": "Ed Beach",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b"
    ]
  },
  "76130": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 04, 1947",
    "EXP": "2",
    "SCHOOL": "Louisville",
    "name": "Butch Beard",
    "rosters": [
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864",
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "52649402-55a4-4d97-a5fd-f7cf9779a672",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "b001782c-95ad-444b-814b-98ecf23fa9ce",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0"
    ]
  },
  "76133": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 25, 1939",
    "EXP": "7",
    "SCHOOL": "Prairie View A&M",
    "name": "Zelmo Beaty",
    "rosters": [
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "fd982b48-258b-4fc9-b6d2-1be39fa32925",
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "385be451-82b9-4142-849d-362d686d325b",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce"
    ]
  },
  "76134": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 25, 1945",
    "EXP": "R",
    "SCHOOL": "Denver",
    "name": "Byron Beck",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967"
    ]
  },
  "76136": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 11, 1931",
    "EXP": "5",
    "SCHOOL": "Pennsylvania",
    "name": "Ernie Beck",
    "rosters": [
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f",
      "05b98b66-c377-4196-b63f-7c9749f98f47",
      "59113a13-7bcb-4a63-908c-a566386976eb",
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447",
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "16181ef3-9a5e-4560-bbac-f30072f1a572"
    ]
  },
  "76138": {
    "NUM": "00",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 14, 1963",
    "EXP": "4",
    "SCHOOL": "Memphis",
    "name": "William Bedford",
    "rosters": [
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c",
      "db85dc84-6de1-4038-8c49-8f636dd20715"
    ]
  },
  "76139": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 02, 1916",
    "EXP": "2",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Hank Beenders",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "76140": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 14, 1951",
    "EXP": "6",
    "SCHOOL": "Minnesota",
    "name": "Ron Behagen",
    "rosters": [
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "66c852c8-74e2-482e-8745-846263fa3f58",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409"
    ]
  },
  "76141": {
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 03, 1929",
    "EXP": "R",
    "SCHOOL": "Bradley",
    "name": "Elmer Behnke",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "76142": {
    "NUM": "46",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 02, 1951",
    "EXP": "2",
    "SCHOOL": "Drake",
    "name": "Dennis Bell",
    "rosters": [
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "52649402-55a4-4d97-a5fd-f7cf9779a672"
    ]
  },
  "76143": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 13, 1932",
    "EXP": "1",
    "SCHOOL": "North Carolina State",
    "name": "William Bell",
    "rosters": [
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "2fb8b948-e857-417f-8af2-422f0825a7c1"
    ]
  },
  "76144": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 24, 1939",
    "EXP": "8",
    "SCHOOL": "Indiana",
    "name": "Walt Bellamy",
    "rosters": [
      "fa657246-a721-4d64-b321-9a6be5364290",
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "e5799d65-732c-4105-b48e-fe6c0e46eee6",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "b204df04-9a5e-48f7-814f-b547b0690ab8",
      "49db955c-2a3f-4693-b68f-834ab5003c4d",
      "d80f75e6-baf7-4843-8122-aa743aea3477"
    ]
  },
  "76145": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 18, 1930",
    "EXP": "1",
    "SCHOOL": "Illinois",
    "name": "Irv Bemoras",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "83150f4c-0043-4b65-834a-7af2f1b9b071"
    ]
  },
  "76146": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 23, 1950",
    "EXP": "1",
    "SCHOOL": "Jacksonville",
    "name": "Leon Benbow",
    "rosters": [
      "66953847-11ae-490d-ba1c-76473d320167",
      "18b07887-8de0-4728-924b-f6f6bca06f1d"
    ]
  },
  "76148": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 01, 1969",
    "EXP": "2",
    "SCHOOL": "Wisconsin-Green Bay",
    "name": "Tony Bennett",
    "rosters": [
      "3e72397e-e399-4a1e-bd06-a9b455547453",
      "f7ef57d0-f5b8-4365-8f17-0a840c185f1e",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291"
    ]
  },
  "76151": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 04, 1955",
    "EXP": "2",
    "SCHOOL": "Pittsburgh",
    "name": "Mel Bennett",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5"
    ]
  },
  "76152": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 09, 1965",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Winston Bennett",
    "rosters": [
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030"
    ]
  },
  "76154": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "DEC 27, 1954",
    "EXP": "8",
    "SCHOOL": "Indiana",
    "name": "Kent Benson",
    "rosters": [
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b",
      "c163d65f-d6fd-4a44-968a-2897543255d4",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b"
    ]
  },
  "76155": {
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 22, 1926",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "Gene Berce",
    "rosters": [
      "6d008515-8165-4990-a179-942eeec92822"
    ]
  },
  "76156": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 16, 1932",
    "EXP": "R",
    "SCHOOL": "Utah",
    "name": "Gary Bergen",
    "rosters": [
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64"
    ]
  },
  "76157": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 06, 1964",
    "EXP": "R",
    "SCHOOL": "San Jose State",
    "name": "Ricky Berry",
    "rosters": [
      "65fe3c0f-07c5-469c-b786-9542d2d55742"
    ]
  },
  "76158": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 14, 1964",
    "EXP": "1",
    "SCHOOL": "St. John's (NY)",
    "name": "Walter Berry",
    "rosters": [
      "79d8e657-ce27-4052-9492-c9833808173f",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "473f0beb-d4eb-46a7-b66f-d6831084a451"
    ]
  },
  "76159": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "165",
    "BIRTH_DATE": "NOV 29, 1956",
    "EXP": "R",
    "SCHOOL": "California (PA)",
    "name": "Del Beshore",
    "rosters": [
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "39873456-69db-4f6d-b278-943dbfa788f7"
    ]
  },
  "76160": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 26, 1932",
    "EXP": "9",
    "SCHOOL": "Bowling Green",
    "name": "Al Bianchi",
    "rosters": [
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "a45c9665-5951-47aa-ad90-2e2f872cee40",
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2",
      "b4492284-7d41-45e4-b3d7-fd93667614a1",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a",
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca"
    ]
  },
  "76162": {
    "NUM": "45",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 24, 1949",
    "EXP": "3",
    "SCHOOL": "California-Los Angeles",
    "name": "Henry Bibby",
    "rosters": [
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "352c468a-24d4-4f1c-8cd5-7348731395a5"
    ]
  },
  "76163": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 12, 1945",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Ed Biedenbach",
    "rosters": [
      "20955b01-8d24-45f5-ba05-3a4253c801f7"
    ]
  },
  "76164": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 01, 1933",
    "EXP": "R",
    "SCHOOL": "Valparaiso",
    "name": "Don Bielke",
    "rosters": [
      "0a699404-02aa-4953-8be2-08a5fc726ea3"
    ]
  },
  "76165": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 26, 1953",
    "EXP": "1",
    "SCHOOL": "Pennsylvania",
    "name": "Bob Bigelow",
    "rosters": [
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e"
    ]
  },
  "76166": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 24, 1943",
    "EXP": "8",
    "SCHOOL": "Syracuse",
    "name": "Dave Bing",
    "rosters": [
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "fd666397-8775-4cb6-9c9b-67881c31f005",
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66",
      "65f693c5-b99c-4553-b1db-458345ed8c34",
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5",
      "49db955c-2a3f-4693-b68f-834ab5003c4d",
      "d80f75e6-baf7-4843-8122-aa743aea3477",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185",
      "94f36f95-2ce4-4f55-8a83-19b910b11438"
    ]
  },
  "76167": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 26, 1961",
    "EXP": "R",
    "SCHOOL": "North Carolina A&T",
    "name": "Joe Binion",
    "rosters": [
      "3367b969-0b6f-4aa4-927e-679d12463f2b"
    ]
  },
  "76168": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 02, 1935",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Jerry Bird",
    "rosters": [
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012"
    ]
  },
  "76170": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 09, 1955",
    "EXP": "3",
    "SCHOOL": "Houston",
    "name": "Otis Birdsong",
    "rosters": [
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "19686855-a120-434c-8ad4-5f15e61b9a38"
    ]
  },
  "76171": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 04, 1922",
    "EXP": "R",
    "SCHOOL": "Washington State",
    "name": "Gale Bishop",
    "rosters": [
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb"
    ]
  },
  "76172": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "252",
    "BIRTH_DATE": "MAR 26, 1962",
    "EXP": "4",
    "SCHOOL": "Indiana",
    "name": "Uwe Blab",
    "rosters": [
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8"
    ]
  },
  "76173": {
    "NUM": "29",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 15, 1921",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Charlie Black",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699",
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "76174": {
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 12, 1957",
    "EXP": "R",
    "SCHOOL": "Saint Joseph's",
    "name": "Norman Black",
    "rosters": [
      "c163d65f-d6fd-4a44-968a-2897543255d4"
    ]
  },
  "76175": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 09, 1941",
    "EXP": "R",
    "SCHOOL": "South Dakota State",
    "name": "Tom Black",
    "rosters": [
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39"
    ]
  },
  "76176": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 26, 1959",
    "EXP": "12",
    "SCHOOL": "Kansas State",
    "name": "Rolando Blackman",
    "rosters": [
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c"
    ]
  },
  "76177": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 27, 1963",
    "EXP": "R",
    "SCHOOL": "Wisconsin",
    "name": "Cory Blackwell",
    "rosters": [
      "4e8fe930-0176-4e3b-9fda-73e7317840a4"
    ]
  },
  "76179": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 15, 1965",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Nate Blackwell",
    "rosters": [
      "473f0beb-d4eb-46a7-b66f-d6831084a451"
    ]
  },
  "76180": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 27, 1970",
    "EXP": "R",
    "SCHOOL": "Monmouth",
    "name": "Alex Blackwell",
    "rosters": [
      "3979ce94-914f-45be-ba16-62480c3df040"
    ]
  },
  "76182": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 12, 1939",
    "EXP": "R",
    "SCHOOL": "Holy Cross",
    "name": "George Blaney",
    "rosters": [
      "816164e0-e2ee-413e-8662-b51fbf40b841"
    ]
  },
  "76183": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 09, 1966",
    "EXP": "1",
    "SCHOOL": "Texas",
    "name": "Lance Blanks",
    "rosters": [
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "73a256d9-f328-424c-a259-734aa5e88c4c",
      "db85dc84-6de1-4038-8c49-8f636dd20715"
    ]
  },
  "76184": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 21, 1966",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Ricky Blanton",
    "rosters": [
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7"
    ]
  },
  "76187": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "207",
    "BIRTH_DATE": "APR 16, 1944",
    "EXP": "8",
    "SCHOOL": "Southern California",
    "name": "John Block",
    "rosters": [
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "76188": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 14, 1915",
    "EXP": "1",
    "SCHOOL": "Temple",
    "name": "Mike Bloom",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503"
    ]
  },
  "76189": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 23, 1958",
    "EXP": "R",
    "SCHOOL": "Oregon State",
    "name": "Ray Blume",
    "rosters": [
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe"
    ]
  },
  "76190": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 25, 1924",
    "EXP": "3",
    "SCHOOL": "Temple",
    "name": "Nelson Bobb",
    "rosters": [
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388",
      "78cf5ce6-637f-459d-812a-47c6b4615dc4",
      "1c190e61-1a8b-4cd1-aba2-fae468830890",
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "76191": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 08, 1933",
    "EXP": "6",
    "SCHOOL": "Dayton",
    "name": "Arlen Bockhorn",
    "rosters": [
      "dc41b99f-710f-4f81-b125-45b52cf09053",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce",
      "020d6aa2-861a-4e30-8595-c38b6904fb12",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133"
    ]
  },
  "76192": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "265",
    "BIRTH_DATE": "AUG 23, 1945",
    "EXP": "9",
    "SCHOOL": "Tennessee",
    "name": "Tom Boerwinkle",
    "rosters": [
      "5ea792bd-890a-47b6-babe-2afabfd78f09",
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2",
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "66953847-11ae-490d-ba1c-76473d320167",
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7"
    ]
  },
  "76195": {
    "NUM": "10",
    "POSITION": "C",
    "HEIGHT": "7-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 16, 1962",
    "EXP": "2",
    "SCHOOL": "Bridgeport",
    "name": "Manute Bol",
    "rosters": [
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "f646b106-33f9-49c7-9873-99a35bf12813",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "355ade3f-1412-4279-a7f6-512d2936bf86",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3"
    ]
  },
  "76197": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 29, 1931",
    "EXP": "R",
    "SCHOOL": "Minnesota",
    "name": "Doug Bolstorff",
    "rosters": [
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3"
    ]
  },
  "76198": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 27, 1954",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Phil Bond",
    "rosters": [
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff"
    ]
  },
  "76201": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "192",
    "BIRTH_DATE": "MAY 31, 1942",
    "EXP": "1",
    "SCHOOL": "Cincinnati",
    "name": "Ron Bonham",
    "rosters": [
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd"
    ]
  },
  "76203": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 01, 1935",
    "EXP": "R",
    "SCHOOL": "Illinois",
    "name": "George H. Bon Salle",
    "rosters": [
      "e5799d65-732c-4105-b48e-fe6c0e46eee6"
    ]
  },
  "76204": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 06, 1946",
    "EXP": "4",
    "SCHOOL": "Idaho State",
    "name": "Ron Boone",
    "rosters": [
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5"
    ]
  },
  "76206": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 26, 1937",
    "EXP": "9",
    "SCHOOL": "Kansas State",
    "name": "Bob Boozer",
    "rosters": [
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "5ea792bd-890a-47b6-babe-2afabfd78f09",
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d",
      "30c679a6-4b92-42bc-b03d-303894f5aed9",
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce",
      "020d6aa2-861a-4e30-8595-c38b6904fb12",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "3829b824-1221-432d-8dc1-388a4d2f1cce"
    ]
  },
  "76207": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 29, 1927",
    "EXP": "1",
    "SCHOOL": "Muhlenberg",
    "name": "Jake Bornheimer",
    "rosters": [
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb",
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388"
    ]
  },
  "76209": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 25, 1924",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Costic Borsavage",
    "rosters": [
      "78cf5ce6-637f-459d-812a-47c6b4615dc4"
    ]
  },
  "76210": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 11, 1927",
    "EXP": "4",
    "SCHOOL": "Denver",
    "name": "Vince Boryla",
    "rosters": [
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac",
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d"
    ]
  },
  "76211": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 28, 1953",
    "EXP": "R",
    "SCHOOL": "New Mexico State",
    "name": "Jim Bostic",
    "rosters": [
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3"
    ]
  },
  "76212": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 18, 1956",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Lawrence Boston",
    "rosters": [
      "324480c3-ba0a-4b35-8ae3-aeccec46e409"
    ]
  },
  "76213": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 02, 1953",
    "EXP": "5",
    "SCHOOL": "South Carolina",
    "name": "Tom Boswell",
    "rosters": [
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291"
    ]
  },
  "76214": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 06, 1925",
    "EXP": "2",
    "SCHOOL": "Western Michigan",
    "name": "Don Boven",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e"
    ]
  },
  "76217": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 17, 1961",
    "EXP": "3",
    "SCHOOL": "Kentucky",
    "name": "Sam Bowie",
    "rosters": [
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "148d19d7-f6a8-401d-b449-7e8b111f7959"
    ]
  },
  "76219": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 19, 1943",
    "EXP": "3",
    "SCHOOL": "Wichita State",
    "name": "Nate Bowman",
    "rosters": [
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070"
    ]
  },
  "76221": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 21, 1954",
    "EXP": "R",
    "SCHOOL": "Detroit Mercy",
    "name": "Dennis Boyd",
    "rosters": [
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "76222": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 13, 1950",
    "EXP": "5",
    "SCHOOL": "Oregon State",
    "name": "Fred Boyd",
    "rosters": [
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827",
      "5517a85a-4623-414c-89a6-b90bb5a42eca"
    ]
  },
  "76223": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 25, 1952",
    "EXP": "R",
    "SCHOOL": "Boston University",
    "name": "Ken Boyd",
    "rosters": [
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "76224": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 24, 1922",
    "EXP": "1",
    "SCHOOL": "St. John's (NY)",
    "name": "Harry Boykoff",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b"
    ]
  },
  "76225": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 17, 1957",
    "EXP": "1",
    "SCHOOL": "San Francisco",
    "name": "Winford Boynes",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a"
    ]
  },
  "76226": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 01, 1950",
    "EXP": "2",
    "SCHOOL": "Tulsa",
    "name": "Steve Bracey",
    "rosters": [
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1"
    ]
  },
  "76227": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 26, 1942",
    "EXP": "1",
    "SCHOOL": "Ohio State",
    "name": "Gary Bradds",
    "rosters": [
      "b204df04-9a5e-48f7-814f-b547b0690ab8",
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc"
    ]
  },
  "76228": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 30, 1959",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Alex Bradley",
    "rosters": [
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff"
    ]
  },
  "76229": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 16, 1953",
    "EXP": "2",
    "SCHOOL": "Texas Southern",
    "name": "Alonzo Bradley",
    "rosters": [
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "08b96232-bec8-4c70-a55c-7f13e94a9137"
    ]
  },
  "76230": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 16, 1959",
    "EXP": "2",
    "SCHOOL": "Wyoming",
    "name": "Charles Bradley",
    "rosters": [
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce"
    ]
  },
  "76231": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 19, 1957",
    "EXP": "5",
    "SCHOOL": "North Carolina",
    "name": "Dudley Bradley",
    "rosters": [
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "76233": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 28, 1943",
    "EXP": "9",
    "SCHOOL": "Princeton",
    "name": "Bill Bradley",
    "rosters": [
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "52649402-55a4-4d97-a5fd-f7cf9779a672",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c"
    ]
  },
  "76237": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 17, 1963",
    "EXP": "2",
    "SCHOOL": "Maryland",
    "name": "Adrian Branch",
    "rosters": [
      "b1898857-82db-453b-849b-591e70ae6807",
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "148d19d7-f6a8-401d-b449-7e8b111f7959"
    ]
  },
  "76238": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 28, 1925",
    "EXP": "4",
    "SCHOOL": "Michigan State",
    "name": "Bob Brannum",
    "rosters": [
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc",
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b",
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "0f305d34-33d7-4e16-a669-f7ab442c151d"
    ]
  },
  "76239": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 24, 1958",
    "EXP": "1",
    "SCHOOL": "Southern Methodist",
    "name": "Brad Branson",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "c24811b3-b1da-43ab-9ab2-9a68328f351c"
    ]
  },
  "76240": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 07, 1942",
    "EXP": "R",
    "SCHOOL": "Elon",
    "name": "Jesse Branson",
    "rosters": [
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca"
    ]
  },
  "76241": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 03, 1931",
    "EXP": "R",
    "SCHOOL": "New York University",
    "name": "Jim Brasco",
    "rosters": [
      "b7540846-94ec-42ec-b008-ff241f8152ea"
    ]
  },
  "76242": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 17, 1955",
    "EXP": "4",
    "SCHOOL": "Stanford",
    "name": "Mike Bratz",
    "rosters": [
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "e8500690-7102-4acf-ae8b-f1907cf60bcf",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb"
    ]
  },
  "76243": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 25, 1927",
    "EXP": "11",
    "SCHOOL": "Colgate",
    "name": "Carl Braun",
    "rosters": [
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "bb996e83-b963-4fd6-b44b-484116ff5a4e",
      "df1fce20-7cea-47cc-8e99-cf6d772b3162",
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac",
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d",
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "2fb8b948-e857-417f-8af2-422f0825a7c1"
    ]
  },
  "76245": {
    "NUM": "15-16",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 23, 1936",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Pete Brennan",
    "rosters": [
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012"
    ]
  },
  "76246": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 06, 1930",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Tom Brennan",
    "rosters": [
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86"
    ]
  },
  "76247": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 11, 1960",
    "EXP": "10",
    "SCHOOL": "Minnesota",
    "name": "Randy Breuer",
    "rosters": [
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "2678d1f0-b641-4c9f-9473-9217e379be6c"
    ]
  },
  "76248": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 03, 1951",
    "EXP": "5",
    "SCHOOL": "Minnesota",
    "name": "Jim Brewer",
    "rosters": [
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86",
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "4ac401d3-899b-427d-89d6-584fe69ebf75",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "76249": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 16, 1955",
    "EXP": "5",
    "SCHOOL": "Arkansas",
    "name": "Ron Brewer",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "4ac401d3-899b-427d-89d6-584fe69ebf75",
      "a6b98be6-4acd-49be-92b6-decd2522e4df",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2"
    ]
  },
  "76250": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 01, 1923",
    "EXP": "6",
    "SCHOOL": "Louisiana State",
    "name": "Frank Brian",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "02a3a04b-002a-4afa-8188-8f11ccfafda1",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e",
      "57494c03-afc0-41de-b544-659990d5e952",
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91",
      "0a699404-02aa-4953-8be2-08a5fc726ea3"
    ]
  },
  "76252": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 17, 1953",
    "EXP": "11",
    "SCHOOL": "Louisville",
    "name": "Junior Bridgeman",
    "rosters": [
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "743aba57-7e93-4575-8cb7-e3de53d6223c",
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d",
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd"
    ]
  },
  "76253": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "228",
    "BIRTH_DATE": "APR 04, 1939",
    "EXP": "10",
    "SCHOOL": "Kansas",
    "name": "Bill Bridges",
    "rosters": [
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "fd982b48-258b-4fc9-b6d2-1be39fa32925",
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "385be451-82b9-4142-849d-362d686d325b",
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "fa657246-a721-4d64-b321-9a6be5364290",
      "c39c5026-a493-40db-84cd-272c3b126805",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada"
    ]
  },
  "76254": {
    "NUM": "8-16",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 22, 1923",
    "EXP": "R",
    "SCHOOL": "Charleston (WV)",
    "name": "Al Brightman",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "76255": {
    "NUM": "12-15",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "175",
    "BIRTH_DATE": "DEC 31, 1923",
    "EXP": "R",
    "SCHOOL": "Dartmouth",
    "name": "Audley Brindley",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24"
    ]
  },
  "76256": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 15, 1947",
    "EXP": "2",
    "SCHOOL": "Toledo",
    "name": "John Brisker",
    "rosters": [
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "cc792a0f-fa98-4caf-ad55-38fe38f01714"
    ]
  },
  "76257": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 23, 1951",
    "EXP": "6",
    "SCHOOL": "Virginia Tech",
    "name": "Allan Bristow",
    "rosters": [
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5"
    ]
  },
  "76258": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 18, 1944",
    "EXP": "R",
    "SCHOOL": "Johnson C. Smith",
    "name": "Tyrone Britt",
    "rosters": [
      "e511f2f0-158b-44a1-90e9-a101165211e2"
    ]
  },
  "76259": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 31, 1952",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Wayman Britt",
    "rosters": [
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3"
    ]
  },
  "76260": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 21, 1963",
    "EXP": "1",
    "SCHOOL": "South Carolina",
    "name": "Mike Brittain",
    "rosters": [
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd"
    ]
  },
  "76261": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 29, 1958",
    "EXP": "R",
    "SCHOOL": "Texas A&M",
    "name": "David Britton",
    "rosters": [
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "76262": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 24, 1958",
    "EXP": "1",
    "SCHOOL": "West Virginia Wesleyan",
    "name": "Jim Brogan",
    "rosters": [
      "de2e838d-3259-430d-8634-0759e68893b8",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e"
    ]
  },
  "76263": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "178",
    "BIRTH_DATE": "JAN 11, 1954",
    "EXP": "2",
    "SCHOOL": "Notre Dame",
    "name": "Gary Brokaw",
    "rosters": [
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "743aba57-7e93-4575-8cb7-e3de53d6223c"
    ]
  },
  "76264": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 11, 1920",
    "EXP": "1",
    "SCHOOL": "West Texas A&M",
    "name": "Price Brookfield",
    "rosters": [
      "06b16798-5051-4dbc-8f77-316dfd3f76a0"
    ]
  },
  "76265": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 12, 1969",
    "EXP": "2",
    "SCHOOL": "Louisiana-Lafayette",
    "name": "Kevin Brooks",
    "rosters": [
      "37167a67-466f-4920-b036-1481c8665cf9",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8"
    ]
  },
  "76266": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 17, 1958",
    "EXP": "4",
    "SCHOOL": "La Salle",
    "name": "Michael Brooks",
    "rosters": [
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a"
    ]
  },
  "76268": {
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 29, 1960",
    "EXP": "5",
    "SCHOOL": "Arkansas",
    "name": "Tony Brown",
    "rosters": [
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff"
    ],
    "NUM": "21"
  },
  "76272": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "182",
    "BIRTH_DATE": "AUG 07, 1948",
    "EXP": "12",
    "SCHOOL": "Iowa",
    "name": "Fred Brown",
    "rosters": [
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c",
      "8718c236-d862-42de-a84d-ef29c83369b9",
      "6306dadf-1ee7-4678-b035-ef14578746a4",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "278e33a4-dd30-4a35-b7be-2e7b71623707",
      "6cb87ca5-b208-46a1-b393-022423c35c0d",
      "9cf046e5-f475-41ef-a3d3-f517368a157b",
      "a59119a0-b26d-4a60-b162-d2df226404ce",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce"
    ]
  },
  "76273": {
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 30, 1935",
    "EXP": "R",
    "SCHOOL": "Wayne State (MI)",
    "name": "George Brown",
    "rosters": [
      "8f51d7d4-966b-43d1-84b2-9267b61db974"
    ]
  },
  "76276": {
    "NUM": "32-52",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 14, 1951",
    "EXP": "6",
    "SCHOOL": "Missouri",
    "name": "John Brown",
    "rosters": [
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "76277": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 03, 1969",
    "EXP": "R",
    "SCHOOL": "Slippery Rock",
    "name": "Myron Brown",
    "rosters": [
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c"
    ]
  },
  "76279": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 19, 1955",
    "EXP": "R",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Lewis Brown",
    "rosters": [
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "76282": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 05, 1965",
    "EXP": "R",
    "SCHOOL": "Idaho",
    "name": "Raymond Brown",
    "rosters": [
      "955486b2-508c-4776-884b-e521d5730bf8"
    ]
  },
  "76283": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 20, 1958",
    "EXP": "1",
    "SCHOOL": "Mississippi State",
    "name": "Rickey Brown",
    "rosters": [
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9"
    ]
  },
  "76285": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 27, 1929",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Stan Brown",
    "rosters": [
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a",
      "1c190e61-1a8b-4cd1-aba2-fae468830890"
    ]
  },
  "76286": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 23, 1950",
    "EXP": "2",
    "SCHOOL": "Kansas",
    "name": "Roger Brown",
    "rosters": [
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f"
    ]
  },
  "76288": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 13, 1967",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Stanley Brundy",
    "rosters": [
      "faa38736-32f4-41a2-8cca-8649fdee48af"
    ]
  },
  "76289": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 04, 1938",
    "EXP": "3",
    "SCHOOL": "DePaul",
    "name": "Emmette Bryant",
    "rosters": [
      "b576b451-62e4-4a16-98e2-535cecc8e551",
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "4d62aaac-8788-4aab-bdec-4738995edafd"
    ]
  },
  "76290": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 19, 1954",
    "EXP": "3",
    "SCHOOL": "La Salle",
    "name": "Joe Bryant",
    "rosters": [
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "ef880e29-8409-4464-a009-ac827384a450",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "41977d46-c447-4c82-8cb1-8952bd2b335e",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79"
    ]
  },
  "76291": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 14, 1959",
    "EXP": "2",
    "SCHOOL": "San Francisco",
    "name": "Wallace Bryant",
    "rosters": [
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90"
    ]
  },
  "76292": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 08, 1964",
    "EXP": "R",
    "SCHOOL": "Texas State",
    "name": "Torgeir Bryn",
    "rosters": [
      "26c5d00c-d04d-45ae-bc04-83d0ee557440"
    ]
  },
  "76293": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 01, 1937",
    "EXP": "1",
    "SCHOOL": "Tennessee State",
    "name": "Joe Buckhalter",
    "rosters": [
      "020d6aa2-861a-4e30-8595-c38b6904fb12",
      "855d0133-3349-470d-9362-7617b3f72e7f"
    ]
  },
  "76294": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 17, 1966",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Steve Bucknall",
    "rosters": [
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8"
    ]
  },
  "76295": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 17, 1938",
    "EXP": "1",
    "SCHOOL": "Jackson State",
    "name": "Cleveland Buckner",
    "rosters": [
      "816164e0-e2ee-413e-8662-b51fbf40b841",
      "ba894f72-662a-4d86-8791-ddc3f2492c47"
    ]
  },
  "76296": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 20, 1954",
    "EXP": "9",
    "SCHOOL": "Indiana",
    "name": "Quinn Buckner",
    "rosters": [
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "743aba57-7e93-4575-8cb7-e3de53d6223c",
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d",
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd"
    ]
  },
  "76297": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 28, 1938",
    "EXP": "4",
    "SCHOOL": "Wake Forest",
    "name": "Dave Budd",
    "rosters": [
      "2fb8b948-e857-417f-8af2-422f0825a7c1",
      "816164e0-e2ee-413e-8662-b51fbf40b841",
      "ba894f72-662a-4d86-8791-ddc3f2492c47",
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "7c3c8b10-137d-4f0f-8249-452818bc279f"
    ]
  },
  "76298": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 30, 1925",
    "EXP": "3",
    "SCHOOL": "Columbia",
    "name": "Walter Budko",
    "rosters": [
      "1c190e61-1a8b-4cd1-aba2-fae468830890"
    ]
  },
  "76300": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 15, 1956",
    "EXP": "R",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Greg Bunch",
    "rosters": [
      "b001782c-95ad-444b-814b-98ecf23fa9ce"
    ]
  },
  "76302": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAY 05, 1942",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Bill Buntin",
    "rosters": [
      "61110628-8f7d-478f-b171-52455d3bf9d0"
    ]
  },
  "76303": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 28, 1953",
    "EXP": "1",
    "SCHOOL": "Utah",
    "name": "Luther Burden",
    "rosters": [
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb"
    ]
  },
  "76304": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 22, 1958",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Roger Burkman",
    "rosters": [
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe"
    ]
  },
  "76305": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 24, 1952",
    "EXP": "2",
    "SCHOOL": "North Carolina State",
    "name": "Tom Burleson",
    "rosters": [
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c",
      "8718c236-d862-42de-a84d-ef29c83369b9"
    ]
  },
  "76307": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 03, 1958",
    "EXP": "R",
    "SCHOOL": "Saint Louis",
    "name": "David Burns",
    "rosters": [
      "051f86ce-ff57-4132-8a18-d40f37418bfc"
    ]
  },
  "76308": {
    "NUM": "53",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "260",
    "BIRTH_DATE": "AUG 24, 1971",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Evers Burns",
    "rosters": [
      "2678d1f0-b641-4c9f-9473-9217e379be6c"
    ]
  },
  "76309": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 21, 1945",
    "EXP": "R",
    "SCHOOL": "Northwestern",
    "name": "Jim Burns",
    "rosters": [
      "080d0104-5755-4078-9dd5-e5b36018755c"
    ]
  },
  "76311": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 07, 1924",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Art Burris",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699",
      "648376a8-6ee8-4cad-97d2-369540020f50"
    ]
  },
  "76313": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "228",
    "BIRTH_DATE": "JUN 29, 1934",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Bob Burrow",
    "rosters": [
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b"
    ]
  },
  "76314": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 13, 1939",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Ed Burton",
    "rosters": [
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "816164e0-e2ee-413e-8662-b51fbf40b841"
    ]
  },
  "76316": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 05, 1962",
    "EXP": "3",
    "SCHOOL": "Iona",
    "name": "Steve Burtt",
    "rosters": [
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05"
    ]
  },
  "76317": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 10, 1950",
    "EXP": "8",
    "SCHOOL": "Evansville",
    "name": "Don Buse",
    "rosters": [
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "e8500690-7102-4acf-ae8b-f1907cf60bcf",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e"
    ]
  },
  "76318": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 08, 1936",
    "EXP": "4",
    "SCHOOL": "Pikeville",
    "name": "Donnie Butcher",
    "rosters": [
      "816164e0-e2ee-413e-8662-b51fbf40b841",
      "ba894f72-662a-4d86-8791-ddc3f2492c47",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a",
      "3902314e-3111-45fa-9433-86d9fdae07db",
      "61110628-8f7d-478f-b171-52455d3bf9d0"
    ]
  },
  "76319": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 09, 1938",
    "EXP": "3",
    "SCHOOL": "Niagara",
    "name": "Al Butler",
    "rosters": [
      "816164e0-e2ee-413e-8662-b51fbf40b841",
      "ba894f72-662a-4d86-8791-ddc3f2492c47",
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "b204df04-9a5e-48f7-814f-b547b0690ab8"
    ]
  },
  "76320": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 11, 1966",
    "EXP": "1",
    "SCHOOL": "Stanford",
    "name": "Greg Butler",
    "rosters": [
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581"
    ]
  },
  "76321": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 30, 1956",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Marty Byrnes",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "c3077e01-3d45-4725-9d9e-90a13761dbe8",
      "c24811b3-b1da-43ab-9ab2-9a68328f351c",
      "a8b9d60d-1449-49af-89fc-945fadd03a04"
    ]
  },
  "76322": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "FEB 19, 1923",
    "EXP": "1",
    "SCHOOL": "Seton Hall",
    "name": "Tommy Byrnes",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "5863b9f0-adf0-4042-9912-f445b7c8ee24",
      "bb996e83-b963-4fd6-b44b-484116ff5a4e"
    ]
  },
  "76324": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 29, 1935",
    "EXP": "R",
    "SCHOOL": "Bradley",
    "name": "Barney Cable",
    "rosters": [
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "a45c9665-5951-47aa-ad90-2e2f872cee40",
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "03f980b2-d8d0-47cc-a777-71a16e029764"
    ]
  },
  "76326": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "FEB 04, 1925",
    "EXP": "1",
    "SCHOOL": "St. John's (NY)",
    "name": "Gerry Calabrese",
    "rosters": [
      "98673c29-08ee-48ce-9891-81b0ab92d951",
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e"
    ]
  },
  "76328": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 28, 1943",
    "EXP": "R",
    "SCHOOL": "Georgia Tech",
    "name": "Jim Caldwell",
    "rosters": [
      "4d62aaac-8788-4aab-bdec-4738995edafd"
    ]
  },
  "76329": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 01, 1941",
    "EXP": "1",
    "SCHOOL": "Arizona State",
    "name": "Joe Caldwell",
    "rosters": [
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "385be451-82b9-4142-849d-362d686d325b",
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "3902314e-3111-45fa-9433-86d9fdae07db",
      "61110628-8f7d-478f-b171-52455d3bf9d0"
    ]
  },
  "76330": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 01, 1950",
    "EXP": "5",
    "SCHOOL": "Pennsylvania",
    "name": "Corky Calhoun",
    "rosters": [
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "b53d3c18-a4f1-40bf-a266-2555fa606018",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9"
    ]
  },
  "76331": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 04, 1927",
    "EXP": "2",
    "SCHOOL": null,
    "name": "Bill Calhoun",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd",
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "d734ea86-9401-4fa7-846a-e69cc31d9800",
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0",
      "6146894f-3e97-4aab-a027-77228d1955a6"
    ]
  },
  "76332": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "165",
    "BIRTH_DATE": "NOV 18, 1969",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Demetrius Calip",
    "rosters": [
      "c3b8e442-4b20-42f1-99ae-03e014396f4a"
    ]
  },
  "76334": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 12, 1966",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Rick Calloway",
    "rosters": [
      "57c1be08-60cf-40ad-a097-a95ef9958532"
    ]
  },
  "76336": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "165",
    "BIRTH_DATE": "JUL 27, 1947",
    "EXP": "2",
    "SCHOOL": "Southern California",
    "name": "Mack Calvin",
    "rosters": [
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "7d5d2b23-3811-4485-8ebe-5300180b8967",
      "66743650-e493-4c91-a3ab-5a488a063464",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "76337": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "224",
    "BIRTH_DATE": "JAN 29, 1970",
    "EXP": "R",
    "SCHOOL": "Texas",
    "name": "Dexter Cambridge",
    "rosters": [
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c"
    ]
  },
  "76338": {
    "NUM": "00",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 07, 1962",
    "EXP": "2",
    "SCHOOL": "Ohio State",
    "name": "Tony Campbell",
    "rosters": [
      "6622ad02-9b8f-485d-ac5d-0ca5b498df8e",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "999df20d-d256-45ec-8e64-111bdc7471d7"
    ]
  },
  "76339": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 12, 1947",
    "EXP": "R",
    "SCHOOL": "La Salle",
    "name": "Larry Cannon",
    "rosters": [
      "992f03c2-845c-48f9-8e9d-5f3af3edf011"
    ]
  },
  "76340": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "5-9",
    "WEIGHT": "160",
    "BIRTH_DATE": "JUN 07, 1938",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Howie Carl",
    "rosters": [
      "e5799d65-732c-4105-b48e-fe6c0e46eee6"
    ]
  },
  "76342": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 27, 1959",
    "EXP": "3",
    "SCHOOL": "Virginia",
    "name": "Rick Carlisle",
    "rosters": [
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44"
    ]
  },
  "76343": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 17, 1951",
    "EXP": "R",
    "SCHOOL": "Oregon",
    "name": "Al Carlson",
    "rosters": [
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c"
    ]
  },
  "76344": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 22, 1921",
    "EXP": "2",
    "SCHOOL": "Minnesota",
    "name": "Don Carlson",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503",
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9"
    ]
  },
  "76345": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "6-3",
    "WEIGHT": "170",
    "BIRTH_DATE": "AUG 03, 1932",
    "EXP": "R",
    "SCHOOL": "Bradley",
    "name": "Bob Carney",
    "rosters": [
      "22dd26a7-1c99-45b7-a759-dce48ccb6829"
    ]
  },
  "76346": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 06, 1917",
    "EXP": "R",
    "SCHOOL": "Texas A&M-Commerce",
    "name": "Bob Carpenter",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e"
    ]
  },
  "76348": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 10, 1948",
    "EXP": "9",
    "SCHOOL": "Notre Dame",
    "name": "Austin Carr",
    "rosters": [
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86",
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "76350": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 15, 1955",
    "EXP": "4",
    "SCHOOL": "North Carolina State",
    "name": "Kenny Carr",
    "rosters": [
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "fff2243e-9747-44b5-8882-14d1d9d99603",
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938"
    ]
  },
  "76351": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 09, 1951",
    "EXP": "2",
    "SCHOOL": "Guilford",
    "name": "M.L. Carr",
    "rosters": [
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "76352": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 03, 1953",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "Bob Carrington",
    "rosters": [
      "ef880e29-8409-4464-a009-ac827384a450",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0"
    ]
  },
  "76353": {
    "NUM": "2",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 24, 1958",
    "EXP": "9",
    "SCHOOL": "Purdue",
    "name": "Joe Barry Carroll",
    "rosters": [
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb"
    ]
  },
  "76355": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 11, 1958",
    "EXP": "5",
    "SCHOOL": "Indiana",
    "name": "Butch Carter",
    "rosters": [
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "c24811b3-b1da-43ab-9ab2-9a68328f351c",
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001"
    ]
  },
  "76356": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 14, 1945",
    "EXP": "1",
    "SCHOOL": "Mount St. Mary's",
    "name": "Fred Carter",
    "rosters": [
      "743aba57-7e93-4575-8cb7-e3de53d6223c",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "41977d46-c447-4c82-8cb1-8952bd2b335e",
      "0403c301-445c-4c5d-8144-e5805bdc801c",
      "ab91fee8-d6df-4549-ad42-04456017afc3"
    ]
  },
  "76357": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 18, 1944",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "George Carter",
    "rosters": [
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5"
    ]
  },
  "76358": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 26, 1961",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Howard Carter",
    "rosters": [
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "606a6691-6b18-478d-b60f-85baee1be70c"
    ]
  },
  "76360": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 10, 1957",
    "EXP": "1",
    "SCHOOL": "St. John's (NY)",
    "name": "Reggie Carter",
    "rosters": [
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff"
    ]
  },
  "76361": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 31, 1956",
    "EXP": "1",
    "SCHOOL": "Virginia Military Institute",
    "name": "Ron Carter",
    "rosters": [
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "d3da55d4-ca59-42db-b517-15302adf51f1"
    ]
  },
  "76362": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 30, 1957",
    "EXP": "14",
    "SCHOOL": "San Francisco",
    "name": "Bill Cartwright",
    "rosters": [
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff",
      "6a10cc0f-c034-4cee-992e-4023a7280654",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "85e9eec7-2e27-4506-89d1-ebedbb46f2ed"
    ]
  },
  "76363": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 04, 1941",
    "EXP": "R",
    "SCHOOL": "Oregon State",
    "name": "Jay Carty",
    "rosters": [
      "0607a2e4-4900-4392-af54-76ee097fe467"
    ]
  },
  "76364": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 03, 1952",
    "EXP": "R",
    "SCHOOL": "Bowling Green",
    "name": "Cornelius Cash",
    "rosters": [
      "553ffb4a-c13e-4629-9598-f1f64182ce7f"
    ]
  },
  "76365": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "218",
    "BIRTH_DATE": "SEP 02, 1951",
    "EXP": "4",
    "SCHOOL": "Hardin-Simmons",
    "name": "Harvey Catchings",
    "rosters": [
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d",
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "41977d46-c447-4c82-8cb1-8952bd2b335e",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79"
    ]
  },
  "76367": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 18, 1948",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Sid Catlett",
    "rosters": [
      "146cb658-0a9a-4f26-8556-39497dc3cfa4"
    ]
  },
  "76368": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 17, 1958",
    "EXP": "R",
    "SCHOOL": "Auburn",
    "name": "Bobby Cattage",
    "rosters": [
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "16161b6e-7707-4383-b96c-3f7cf17fe149"
    ]
  },
  "76370": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 30, 1959",
    "EXP": "R",
    "SCHOOL": "Texas Southern",
    "name": "Ron Cavenall",
    "rosters": [
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152"
    ]
  },
  "76373": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 12, 1917",
    "EXP": "3",
    "SCHOOL": null,
    "name": "Al Cervi",
    "rosters": [
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "98673c29-08ee-48ce-9891-81b0ab92d951",
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e",
      "b7540846-94ec-42ec-b008-ff241f8152ea"
    ]
  },
  "76374": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "188",
    "BIRTH_DATE": "DEC 16, 1949",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Bill Chamberlain",
    "rosters": [
      "bd877b88-34bc-4231-95a1-6063a90eca41"
    ]
  },
  "76375": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 21, 1936",
    "EXP": "8",
    "SCHOOL": "Kansas",
    "name": "Wilt Chamberlain",
    "rosters": [
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae",
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5",
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "56bc558b-98d9-4a75-9033-fbec8e9fe666"
    ]
  },
  "76376": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 18, 1943",
    "EXP": "1",
    "SCHOOL": "Utah",
    "name": "Jerry Chambers",
    "rosters": [
      "fa657246-a721-4d64-b321-9a6be5364290",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "c5964149-f86c-413e-9d7c-c8d07f4113ef"
    ]
  },
  "76378": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 05, 1964",
    "EXP": "R",
    "SCHOOL": "Gonzaga",
    "name": "Mike Champion",
    "rosters": [
      "90c8da1b-ce02-4f81-83f9-fadd291577cf"
    ]
  },
  "76379": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 22, 1946",
    "EXP": "7",
    "SCHOOL": "Houston",
    "name": "Don Chaney",
    "rosters": [
      "b576b451-62e4-4a16-98e2-535cecc8e551",
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9",
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77"
    ]
  },
  "76382": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 31, 1941",
    "EXP": "5",
    "SCHOOL": "Wake Forest",
    "name": "Len Chappell",
    "rosters": [
      "fa657246-a721-4d64-b321-9a6be5364290",
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00",
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "b630b545-ede0-408a-a797-133d5fdeaa10"
    ]
  },
  "76383": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 10, 1951",
    "EXP": "2",
    "SCHOOL": "Fordham",
    "name": "Ken Charles",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6"
    ]
  },
  "76384": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 25, 1963",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Lorenzo Charles",
    "rosters": [
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6"
    ]
  },
  "76385": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 08, 1956",
    "EXP": "10",
    "SCHOOL": "West Texas A&M",
    "name": "Maurice Cheeks",
    "rosters": [
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda"
    ]
  },
  "76386": {
    "NUM": "45",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 30, 1950",
    "EXP": "7",
    "SCHOOL": "California",
    "name": "Phil Chenier",
    "rosters": [
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "024d58a0-dd09-4396-a891-82ba087f7279",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "fd666397-8775-4cb6-9c9b-67881c31f005",
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66",
      "dd527857-adb8-40c3-9804-8aa4212b8486",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0"
    ]
  },
  "76387": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 03, 1967",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Derrick Chievous",
    "rosters": [
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "79d8e657-ce27-4052-9492-c9833808173f"
    ]
  },
  "76391": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 05, 1925",
    "EXP": "1",
    "SCHOOL": "Canisius",
    "name": "Leroy Chollet",
    "rosters": [
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "98673c29-08ee-48ce-9891-81b0ab92d951"
    ]
  },
  "76392": {
    "NUM": "9",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 30, 1949",
    "EXP": "7",
    "SCHOOL": "Marquette",
    "name": "Jim Chones",
    "rosters": [
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86",
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "c3077e01-3d45-4725-9d9e-90a13761dbe8",
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb"
    ]
  },
  "76393": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 06, 1930",
    "EXP": "R",
    "SCHOOL": "Fordham",
    "name": "Fred Christ",
    "rosters": [
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa"
    ]
  },
  "76394": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 08, 1927",
    "EXP": "4",
    "SCHOOL": "Toledo",
    "name": "Cal Christensen",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699",
      "a0311ade-2019-4595-ab92-26a1696f3ce7",
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6",
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af"
    ]
  },
  "76395": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAY 11, 1944",
    "EXP": "3",
    "SCHOOL": "Grambling",
    "name": "Bob Christian",
    "rosters": [
      "fa657246-a721-4d64-b321-9a6be5364290",
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "bd877b88-34bc-4231-95a1-6063a90eca41"
    ]
  },
  "76397": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 15, 1941",
    "EXP": "9",
    "SCHOOL": "Minnesota",
    "name": "Archie Clark",
    "rosters": [
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "e08b4914-59a6-4d7e-836c-db576c3c3723",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1",
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "024d58a0-dd09-4396-a891-82ba087f7279",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b"
    ]
  },
  "76398": {
    "NUM": "40",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 10, 1960",
    "EXP": "1",
    "SCHOOL": "Mississippi",
    "name": "Carlos Clark",
    "rosters": [
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675"
    ]
  },
  "76402": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 13, 1949",
    "EXP": "8",
    "SCHOOL": "Ohio State",
    "name": "Jim Cleamons",
    "rosters": [
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86",
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "b001782c-95ad-444b-814b-98ecf23fa9ce",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d"
    ]
  },
  "76403": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 01, 1943",
    "EXP": "6",
    "SCHOOL": "Ohio Wesleyan",
    "name": "John Clemens",
    "rosters": [
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "5ea792bd-890a-47b6-babe-2afabfd78f09",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "cbf01feb-6986-43c2-8350-23e707cf8272",
      "3829b824-1221-432d-8dc1-388a4d2f1cce",
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4"
    ]
  },
  "76404": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 13, 1922",
    "EXP": "7",
    "SCHOOL": "Xavier (LA)",
    "name": "Nat Clifton",
    "rosters": [
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac",
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d",
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3"
    ]
  },
  "76406": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 08, 1922",
    "EXP": "2",
    "SCHOOL": "Rice",
    "name": "Bill Closs",
    "rosters": [
      "78cf5ce6-637f-459d-812a-47c6b4615dc4",
      "02a3a04b-002a-4afa-8188-8f11ccfafda1"
    ]
  },
  "76408": {
    "NUM": "3",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 18, 1917",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Marion Cluggish",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24"
    ]
  },
  "76409": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "198",
    "BIRTH_DATE": "JUN 10, 1951",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Ben Clyde",
    "rosters": [
      "6d722c8b-189c-46c8-b21a-1089287af8e0"
    ]
  },
  "76410": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "212",
    "BIRTH_DATE": "SEP 02, 1965",
    "EXP": "R",
    "SCHOOL": "Minnesota",
    "name": "Richard Coffey",
    "rosters": [
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61"
    ]
  },
  "76411": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 04, 1962",
    "EXP": "R",
    "SCHOOL": "Eastern Michigan",
    "name": "Fred Cofield",
    "rosters": [
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa"
    ]
  },
  "76412": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 14, 1961",
    "EXP": "4",
    "SCHOOL": "Maryland",
    "name": "Ben Coleman",
    "rosters": [
      "ec353978-5363-474f-aac3-88a8126172d3",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "76413": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 25, 1950",
    "EXP": "3",
    "SCHOOL": "Houston Baptist",
    "name": "E.C. Coleman",
    "rosters": [
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827"
    ]
  },
  "76414": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 23, 1924",
    "EXP": "5",
    "SCHOOL": "Louisville",
    "name": "Jack Coleman",
    "rosters": [
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b",
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0",
      "6146894f-3e97-4aab-a027-77228d1955a6",
      "817867c3-ef94-48b9-81aa-768f4f8f758e",
      "a0311ade-2019-4595-ab92-26a1696f3ce7",
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6",
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af"
    ]
  },
  "76415": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 27, 1961",
    "EXP": "R",
    "SCHOOL": "Kansas State",
    "name": "Norris Coleman",
    "rosters": [
      "24f004b8-d504-43af-a4c2-a15d3b8e382d"
    ]
  },
  "76416": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 14, 1954",
    "EXP": "R",
    "SCHOOL": "St. Thomas (FL)",
    "name": "Art Collins",
    "rosters": [
      "3c8804a4-d28c-4245-8592-95f990e5e797"
    ]
  },
  "76418": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 28, 1958",
    "EXP": "4",
    "SCHOOL": "Washington State",
    "name": "Don Collins",
    "rosters": [
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "a52d7bc3-ed81-4836-b70b-85c958af2548",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228"
    ]
  },
  "76419": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 24, 1946",
    "EXP": "1",
    "SCHOOL": "New Mexico State",
    "name": "Jimmy Collins",
    "rosters": [
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2",
      "940cea5e-14c6-4fd7-a44a-e5415c80a822"
    ]
  },
  "76421": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 28, 1951",
    "EXP": "7",
    "SCHOOL": "Illinois State",
    "name": "Doug Collins",
    "rosters": [
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "41977d46-c447-4c82-8cb1-8952bd2b335e",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "7495da12-4bc8-4edf-a24e-b24655504ff8"
    ]
  },
  "76422": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 23, 1926",
    "EXP": "R",
    "SCHOOL": "Bloomsburg",
    "name": "Joe Colone",
    "rosters": [
      "df1fce20-7cea-47cc-8e99-cf6d772b3162"
    ]
  },
  "76425": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 01, 1961",
    "EXP": "R",
    "SCHOOL": "Oklahoma State",
    "name": "Leroy Combs",
    "rosters": [
      "84846342-36c1-4dc5-96ab-243c663b2065"
    ]
  },
  "76426": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 17, 1964",
    "EXP": "1",
    "SCHOOL": "DePaul",
    "name": "Dallas Comegys",
    "rosters": [
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a"
    ]
  },
  "76427": {
    "NUM": "52",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 17, 1939",
    "EXP": "R",
    "SCHOOL": "Kansas State",
    "name": "Larry Comley",
    "rosters": [
      "fa4aff89-3225-4265-8365-6d1dcb219e6b"
    ]
  },
  "76428": {
    "NUM": "5",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 10, 1930",
    "EXP": "5",
    "SCHOOL": "Washington State",
    "name": "Gene Conley",
    "rosters": [
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b",
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7",
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "ba894f72-662a-4d86-8791-ddc3f2492c47",
      "67233742-0a5f-4ab9-a3f8-682956af416d"
    ]
  },
  "76429": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 02, 1933",
    "EXP": "4",
    "SCHOOL": "Fordham",
    "name": "Ed Conlin",
    "rosters": [
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae",
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "29b33649-2e3d-444b-81b4-caec18163bdd"
    ]
  },
  "76431": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 17, 1959",
    "EXP": "10",
    "SCHOOL": "Oregon State",
    "name": "Lester Conner",
    "rosters": [
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "044dce90-7443-4814-8454-a974b791ec76"
    ]
  },
  "76432": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 10, 1921",
    "EXP": "1",
    "SCHOOL": "Seton Hall",
    "name": "Chuck Connors",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654",
      "e032e6bf-840a-444b-8044-7f6795db68e6"
    ]
  },
  "76433": {
    "NUM": "38",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 19, 1967",
    "EXP": "3",
    "SCHOOL": "Arizona",
    "name": "Anthony Cook",
    "rosters": [
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "3eff7ebb-bedf-4522-b837-8677e8944c50"
    ]
  },
  "76434": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 26, 1929",
    "EXP": "R",
    "SCHOOL": "Utah State",
    "name": "Bert Cook",
    "rosters": [
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa"
    ]
  },
  "76435": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "184",
    "BIRTH_DATE": "AUG 06, 1958",
    "EXP": "6",
    "SCHOOL": "Portland",
    "name": "Darwin Cook",
    "rosters": [
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "4567ce1a-2538-43ab-b37e-44c2913ab119"
    ]
  },
  "76436": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 21, 1956",
    "EXP": "6",
    "SCHOOL": "Idaho State",
    "name": "Jeff Cook",
    "rosters": [
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "5c09ec26-5505-4508-98c5-db244798d9d1",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a"
    ]
  },
  "76437": {
    "NUM": "30-52",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 21, 1955",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Norm Cook",
    "rosters": [
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "66743650-e493-4c91-a3ab-5a488a063464"
    ]
  },
  "76439": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 27, 1963",
    "EXP": "R",
    "SCHOOL": "Saint Mary's (CA)",
    "name": "David Cooke",
    "rosters": [
      "b9189460-149e-4b0b-88c7-ad91b38bd16f"
    ]
  },
  "76440": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 14, 1948",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Joe Cooke",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1"
    ]
  },
  "76441": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 16, 1956",
    "EXP": "2",
    "SCHOOL": "New Orleans",
    "name": "Wayne Cooper",
    "rosters": [
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "f2508938-bae2-4775-8446-97b4a2c9038e",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5"
    ]
  },
  "76442": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 29, 1926",
    "EXP": "5",
    "SCHOOL": "Duquesne",
    "name": "Chuck Cooper",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800",
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7",
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc",
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b",
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "0a699404-02aa-4953-8be2-08a5fc726ea3"
    ]
  },
  "76443": {
    "NUM": "53",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 01, 1957",
    "EXP": "2",
    "SCHOOL": "Colorado",
    "name": "Joe Cooper",
    "rosters": [
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4"
    ]
  },
  "76444": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 15, 1956",
    "EXP": "11",
    "SCHOOL": "New Mexico",
    "name": "Michael Cooper",
    "rosters": [
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "c3077e01-3d45-4725-9d9e-90a13761dbe8",
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8"
    ]
  },
  "76446": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "275",
    "BIRTH_DATE": "OCT 30, 1964",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "Tom Copa",
    "rosters": [
      "61f90119-05dd-4779-bfd7-fcf297701a65"
    ]
  },
  "76447": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 20, 1955",
    "EXP": "1",
    "SCHOOL": "Rutgers",
    "name": "Hollis Copeland",
    "rosters": [
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff"
    ]
  },
  "76448": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 16, 1965",
    "EXP": "R",
    "SCHOOL": "Georgia State",
    "name": "Lanard Copeland",
    "rosters": [
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "5dccfcf4-5eac-4345-af96-969f8232576c"
    ]
  },
  "76450": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 28, 1968",
    "EXP": "1",
    "SCHOOL": "North Carolina State",
    "name": "Chris Corchiani",
    "rosters": [
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05"
    ]
  },
  "76452": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 01, 1928",
    "EXP": "2",
    "SCHOOL": "Georgetown",
    "name": "Ray Corley",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e"
    ]
  },
  "76453": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "APR 25, 1956",
    "EXP": "1",
    "SCHOOL": "DePaul",
    "name": "Dave Corzine",
    "rosters": [
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "a6b98be6-4acd-49be-92b6-decd2522e4df",
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409"
    ]
  },
  "76454": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "186",
    "BIRTH_DATE": "JUL 02, 1931",
    "EXP": "11",
    "SCHOOL": "Niagara",
    "name": "Larry Costello",
    "rosters": [
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86",
      "59113a13-7bcb-4a63-908c-a566386976eb",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "a45c9665-5951-47aa-ad90-2e2f872cee40",
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2",
      "b4492284-7d41-45e4-b3d7-fd93667614a1",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a",
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "56bc558b-98d9-4a75-9033-fbec8e9fe666"
    ]
  },
  "76457": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 12, 1951",
    "EXP": "R",
    "SCHOOL": "California",
    "name": "John Coughran",
    "rosters": [
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc"
    ]
  },
  "76458": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 16, 1941",
    "EXP": "2",
    "SCHOOL": "Oregon State",
    "name": "Mel Counts",
    "rosters": [
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00",
      "c39c5026-a493-40db-84cd-272c3b126805",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada",
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4"
    ]
  },
  "76459": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "188",
    "BIRTH_DATE": "SEP 21, 1942",
    "EXP": "R",
    "SCHOOL": "Saint Joseph's",
    "name": "Steve Courtin",
    "rosters": [
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22"
    ]
  },
  "76462": {
    "NUM": "36",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 25, 1948",
    "EXP": "10",
    "SCHOOL": "Florida State",
    "name": "Dave Cowens",
    "rosters": [
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9",
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "48d933d7-4450-441c-8ef1-2d743521a134"
    ]
  },
  "76463": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 29, 1955",
    "EXP": "R",
    "SCHOOL": "San Francisco",
    "name": "John Cox",
    "rosters": [
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95"
    ]
  },
  "76464": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 01, 1936",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Johnny Cox",
    "rosters": [
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "76465": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 27, 1955",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Wesley Cox",
    "rosters": [
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10"
    ]
  },
  "76466": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "189",
    "BIRTH_DATE": "DEC 23, 1940",
    "EXP": "1",
    "SCHOOL": "St. Bonaventure",
    "name": "Freddie Crawford",
    "rosters": [
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "4d62aaac-8788-4aab-bdec-4738995edafd"
    ]
  },
  "76468": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 18, 1950",
    "EXP": "R",
    "SCHOOL": "Colorado",
    "name": "Jim Creighton",
    "rosters": [
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c"
    ]
  },
  "76469": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "AUG 14, 1958",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "Ron Crevier",
    "rosters": [
      "b074de7d-b368-48a0-aa16-3542d57bfc7b"
    ]
  },
  "76470": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 31, 1923",
    "EXP": "R",
    "SCHOOL": "Iowa State",
    "name": "Hal Crisler",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "76471": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "5-8",
    "WEIGHT": "165",
    "BIRTH_DATE": "NOV 06, 1948",
    "EXP": "5",
    "SCHOOL": "New Mexico State",
    "name": "Charlie Criss",
    "rosters": [
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474",
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "48d933d7-4450-441c-8ef1-2d743521a134"
    ]
  },
  "76472": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "233",
    "BIRTH_DATE": "JUN 20, 1965",
    "EXP": "1",
    "SCHOOL": "Texas A&M",
    "name": "Winston Crite",
    "rosters": [
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "43630741-81fb-49b9-b45e-d413e1873e42"
    ]
  },
  "76473": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 19, 1925",
    "EXP": "R",
    "SCHOOL": "Western Michigan",
    "name": "Dillard Crocker",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699",
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd",
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "76474": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "280",
    "BIRTH_DATE": "JUL 04, 1955",
    "EXP": "3",
    "SCHOOL": "North Carolina",
    "name": "Jeffrey Crompton",
    "rosters": [
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661"
    ]
  },
  "76475": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 04, 1957",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Terry Crosby",
    "rosters": [
      "1df46296-cc39-4688-95c6-3656430e4ae2"
    ]
  },
  "76476": {
    "NUM": "38",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 01, 1961",
    "EXP": "R",
    "SCHOOL": "Maine",
    "name": "Jeff Cross",
    "rosters": [
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90"
    ]
  },
  "76477": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 28, 1948",
    "EXP": "2",
    "SCHOOL": "San Francisco",
    "name": "Pete Cross",
    "rosters": [
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0"
    ]
  },
  "76478": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 05, 1961",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Russell Cross",
    "rosters": [
      "aea08569-655f-4acc-8b71-27160b0a4dad"
    ]
  },
  "76479": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "165",
    "BIRTH_DATE": "JUL 04, 1924",
    "EXP": "2",
    "SCHOOL": "Pennsylvania",
    "name": "Francis Crossin",
    "rosters": [
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a",
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb",
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388"
    ]
  },
  "76481": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 22, 1954",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Mark Crow",
    "rosters": [
      "f6db8edf-a145-4fdb-b531-74c601ef91a1"
    ]
  },
  "76482": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "214",
    "BIRTH_DATE": "APR 13, 1969",
    "EXP": "R",
    "SCHOOL": "Kentucky Wesleyan",
    "name": "Corey Crowder",
    "rosters": [
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "e34e570c-e94f-47f1-927d-d070fd2321f8"
    ]
  },
  "76483": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 11, 1956",
    "EXP": "11",
    "SCHOOL": "Cincinnati",
    "name": "Pat Cummings",
    "rosters": [
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "652536cb-d53b-4de7-a147-555aacb21048",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d",
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff"
    ]
  },
  "76485": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 11, 1946",
    "EXP": "6",
    "SCHOOL": "Murray State",
    "name": "Dick Cunningham",
    "rosters": [
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00",
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "30c679a6-4b92-42bc-b03d-303894f5aed9",
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e"
    ]
  },
  "76487": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 03, 1943",
    "EXP": "8",
    "SCHOOL": "North Carolina",
    "name": "Billy Cunningham",
    "rosters": [
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "56bc558b-98d9-4a75-9033-fbec8e9fe666",
      "e08b4914-59a6-4d7e-836c-db576c3c3723",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "41977d46-c447-4c82-8cb1-8952bd2b335e"
    ]
  },
  "76488": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "275",
    "BIRTH_DATE": "SEP 26, 1965",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Radisav Curcic",
    "rosters": [
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c"
    ]
  },
  "76492": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 19, 1925",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Fran Curran",
    "rosters": [
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0"
    ]
  },
  "76496": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 31, 1926",
    "EXP": "R",
    "SCHOOL": "Duquesne",
    "name": "Ed Dahler",
    "rosters": [
      "1c190e61-1a8b-4cd1-aba2-fae468830890"
    ]
  },
  "76497": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 22, 1961",
    "EXP": "9",
    "SCHOOL": "San Francisco",
    "name": "Quintin Dailey",
    "rosters": [
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a",
      "b3bd95d0-2928-4e01-8f1d-47b167775984"
    ]
  },
  "76498": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 24, 1922",
    "EXP": "2",
    "SCHOOL": "Stanford",
    "name": "Howie Dallmar",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c",
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a",
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb"
    ]
  },
  "76499": {
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "NOV 20, 1944",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Lou Dampier",
    "rosters": [
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148"
    ]
  },
  "76500": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 15, 1947",
    "EXP": "11",
    "SCHOOL": "Norfolk State",
    "name": "Bob Dandridge",
    "rosters": [
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "30c679a6-4b92-42bc-b03d-303894f5aed9",
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6",
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "743aba57-7e93-4575-8cb7-e3de53d6223c",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "dd527857-adb8-40c3-9804-8aa4212b8486",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409",
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "76502": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 20, 1944",
    "EXP": "R",
    "SCHOOL": "New Mexico",
    "name": "Mel Daniels",
    "rosters": [
      "97c2ee90-873f-4c01-92e4-890bb08dda89"
    ]
  },
  "76504": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "208",
    "BIRTH_DATE": "FEB 26, 1955",
    "EXP": "11",
    "SCHOOL": "Notre Dame",
    "name": "Adrian Dantley",
    "rosters": [
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "fff2243e-9747-44b5-8882-14d1d9d99603",
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5",
      "16161b6e-7707-4383-b96c-3f7cf17fe149",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8"
    ]
  },
  "76505": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 08, 1951",
    "EXP": "3",
    "SCHOOL": "Marshall",
    "name": "Mike D'Antoni",
    "rosters": [
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "66c852c8-74e2-482e-8745-846263fa3f58",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "02822d1e-e608-4487-ba05-46ffbf5312fe"
    ]
  },
  "76506": {
    "NUM": "10",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "217",
    "BIRTH_DATE": "MAR 03, 1930",
    "EXP": "R",
    "SCHOOL": "Oklahoma State",
    "name": "Henry Darcey",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd"
    ]
  },
  "76509": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 02, 1951",
    "EXP": "R",
    "SCHOOL": "Virginia Commonwealth",
    "name": "Jesse Dark",
    "rosters": [
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa"
    ]
  },
  "76511": {
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "170",
    "BIRTH_DATE": "SEP 25, 1937",
    "EXP": "R",
    "SCHOOL": "Bowling Green",
    "name": "Jimmy Darrow",
    "rosters": [
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0"
    ]
  },
  "76514": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 15, 1920",
    "EXP": "6",
    "SCHOOL": "Seton Hall",
    "name": "Bob Davies",
    "rosters": [
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0",
      "6146894f-3e97-4aab-a027-77228d1955a6",
      "817867c3-ef94-48b9-81aa-768f4f8f758e",
      "a0311ade-2019-4595-ab92-26a1696f3ce7",
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6",
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af"
    ]
  },
  "76516": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 17, 1955",
    "EXP": "2",
    "SCHOOL": "Maryland",
    "name": "Brad Davis",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "fff2243e-9747-44b5-8882-14d1d9d99603",
      "b53d3c18-a4f1-40bf-a266-2555fa606018",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "76517": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 21, 1970",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Brian Davis",
    "rosters": [
      "54f0f70e-ad29-449d-8754-ee6a602fe375"
    ]
  },
  "76518": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 05, 1958",
    "EXP": "2",
    "SCHOOL": "Vanderbilt",
    "name": "Charlie Davis",
    "rosters": [
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407"
    ]
  },
  "76519": {
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "160",
    "BIRTH_DATE": "SEP 07, 1949",
    "EXP": "2",
    "SCHOOL": "Wake Forest",
    "name": "Charlie Davis",
    "rosters": [
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "dd803214-fd70-44fe-988b-85ae27a94245"
    ]
  },
  "76520": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 26, 1958",
    "EXP": "R",
    "SCHOOL": "Tennessee State",
    "name": "Monti Davis",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9"
    ]
  },
  "76521": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 11, 1949",
    "EXP": "4",
    "SCHOOL": "Houston",
    "name": "Double D Davis",
    "rosters": [
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444"
    ]
  },
  "76522": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 16, 1950",
    "EXP": "4",
    "SCHOOL": "Duquesne",
    "name": "Edward Davis",
    "rosters": [
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "743aba57-7e93-4575-8cb7-e3de53d6223c"
    ]
  },
  "76523": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 27, 1956",
    "EXP": "1",
    "SCHOOL": "Florida State",
    "name": "Harry Davis",
    "rosters": [
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "38ac7402-b81e-4254-b636-95330a997974"
    ]
  },
  "76524": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 22, 1932",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "James Davis",
    "rosters": [
      "516c9243-1276-46d0-8dbe-f1db0a8384ad"
    ]
  },
  "76525": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 18, 1941",
    "EXP": "7",
    "SCHOOL": "Colorado",
    "name": "Jim Davis",
    "rosters": [
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "385be451-82b9-4142-849d-362d686d325b",
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "fa657246-a721-4d64-b321-9a6be5364290",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185",
      "94f36f95-2ce4-4f55-8a83-19b910b11438"
    ]
  },
  "76526": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "OCT 21, 1955",
    "EXP": "1",
    "SCHOOL": "Dayton",
    "name": "Johnny Davis",
    "rosters": [
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "b53d3c18-a4f1-40bf-a266-2555fa606018",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9"
    ]
  },
  "76528": {
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 08, 1963",
    "EXP": "R",
    "SCHOOL": "Old Dominion",
    "name": "Mark Davis",
    "rosters": [
      "43630741-81fb-49b9-b45e-d413e1873e42"
    ]
  },
  "76529": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 09, 1950",
    "EXP": "2",
    "SCHOOL": "St. John's (NY)",
    "name": "Mel Davis",
    "rosters": [
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "52649402-55a4-4d97-a5fd-f7cf9779a672"
    ]
  },
  "76530": {
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 02, 1956",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Michael Davis",
    "rosters": [
      "6a10cc0f-c034-4cee-992e-4023a7280654"
    ]
  },
  "76531": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 26, 1946",
    "EXP": "3",
    "SCHOOL": "Virginia Union",
    "name": "Mike Davis",
    "rosters": [
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "0403c301-445c-4c5d-8144-e5805bdc801c",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889"
    ]
  },
  "76532": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 07, 1938",
    "EXP": "1",
    "SCHOOL": "Cincinnati",
    "name": "Ralph Davis",
    "rosters": [
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce",
      "e5799d65-732c-4105-b48e-fe6c0e46eee6"
    ]
  },
  "76533": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 02, 1950",
    "EXP": "R",
    "SCHOOL": "Weber State",
    "name": "Bob Davis",
    "rosters": [
      "448aca36-b464-4106-91e1-7fc632e2c7d5"
    ]
  },
  "76534": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "198",
    "BIRTH_DATE": "MAY 01, 1954",
    "EXP": "2",
    "SCHOOL": "Washington State",
    "name": "Ron Davis",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "de2e838d-3259-430d-8634-0759e68893b8"
    ]
  },
  "76536": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 05, 1931",
    "EXP": "4",
    "SCHOOL": "Texas A&M",
    "name": "Walt Davis",
    "rosters": [
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f",
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86",
      "05b98b66-c377-4196-b63f-7c9749f98f47",
      "59113a13-7bcb-4a63-908c-a566386976eb",
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447"
    ]
  },
  "76539": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "251",
    "BIRTH_DATE": "JAN 11, 1957",
    "EXP": "13",
    "SCHOOL": "Maynard Evans",
    "name": "Darryl Dawkins",
    "rosters": [
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "41977d46-c447-4c82-8cb1-8952bd2b335e",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294"
    ]
  },
  "76541": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 10, 1957",
    "EXP": "R",
    "SCHOOL": "Northern Illinois",
    "name": "Paul Dawkins",
    "rosters": [
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "76542": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 25, 1967",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Tony Dawson",
    "rosters": [
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "57c1be08-60cf-40ad-a097-a95ef9958532"
    ]
  },
  "76543": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 30, 1960",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "Darren Daye",
    "rosters": [
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "76544": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 06, 1957",
    "EXP": "R",
    "SCHOOL": "Utah",
    "name": "Greg Deane",
    "rosters": [
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "76545": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 16, 1940",
    "EXP": "6",
    "SCHOOL": "Detroit Mercy",
    "name": "Dave DeBusschere",
    "rosters": [
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a",
      "3902314e-3111-45fa-9433-86d9fdae07db",
      "61110628-8f7d-478f-b171-52455d3bf9d0",
      "65f693c5-b99c-4553-b1db-458345ed8c34",
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5",
      "49db955c-2a3f-4693-b68f-834ab5003c4d"
    ]
  },
  "76546": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 22, 1936",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "Archie Dees",
    "rosters": [
      "dc41b99f-710f-4f81-b125-45b52cf09053",
      "e5799d65-732c-4105-b48e-fe6c0e46eee6",
      "29b33649-2e3d-444b-81b4-caec18163bdd",
      "a4934f64-d807-440b-9639-6e4052c2501e"
    ]
  },
  "76551": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 05, 1926",
    "EXP": "R",
    "SCHOOL": "Wisconsin-River Falls",
    "name": "Nate DeLong",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "76552": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 24, 1966",
    "EXP": "R",
    "SCHOOL": "Wyoming",
    "name": "Fennis Dembo",
    "rosters": [
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294"
    ]
  },
  "76553": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 27, 1957",
    "EXP": "2",
    "SCHOOL": "Arizona",
    "name": "Larry Demic",
    "rosters": [
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff"
    ]
  },
  "76555": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 19, 1929",
    "EXP": "4",
    "SCHOOL": "King's (NY)",
    "name": "George Dempsey",
    "rosters": [
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86",
      "05b98b66-c377-4196-b63f-7c9749f98f47",
      "59113a13-7bcb-4a63-908c-a566386976eb",
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868"
    ]
  },
  "76556": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 18, 1958",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Kenny Dennard",
    "rosters": [
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "48f67883-4176-4790-ac27-c6cec5e58bd8"
    ]
  },
  "76558": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 18, 1949",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Randy Denton",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f"
    ]
  },
  "76559": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 11, 1952",
    "EXP": "1",
    "SCHOOL": "Seattle",
    "name": "Rod Derline",
    "rosters": [
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c"
    ]
  },
  "76560": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAY 13, 1945",
    "EXP": "R",
    "SCHOOL": "Rochester (NY)",
    "name": "Dave Deutsch",
    "rosters": [
      "054fdbaa-a097-480c-b2de-7711c6b6734b"
    ]
  },
  "76561": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 21, 1931",
    "EXP": "1",
    "SCHOOL": "George Washington",
    "name": "Walter Devlin",
    "rosters": [
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "0a699404-02aa-4953-8be2-08a5fc726ea3",
      "edb95a5d-c995-43f0-9378-d27051d259ab"
    ]
  },
  "76562": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 12, 1922",
    "EXP": "R",
    "SCHOOL": "Clark Atlanta",
    "name": "Hank DeZonie",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b"
    ]
  },
  "76563": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 27, 1951",
    "EXP": "R",
    "SCHOOL": "Charleston (WV)",
    "name": "Henry Dickerson",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b"
    ]
  },
  "76564": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "MAR 20, 1951",
    "EXP": "3",
    "SCHOOL": "Cincinnati",
    "name": "Derrek Dickey",
    "rosters": [
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864",
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444"
    ]
  },
  "76565": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 26, 1926",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Dick Dickey",
    "rosters": [
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc"
    ]
  },
  "76566": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "222",
    "BIRTH_DATE": "OCT 02, 1936",
    "EXP": "8",
    "SCHOOL": "Cincinnati",
    "name": "Connie Dierking",
    "rosters": [
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "a45c9665-5951-47aa-ad90-2e2f872cee40",
      "084db916-a6ce-4651-bbc2-a41c3e21369a",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef",
      "38c6a8b5-9c04-429f-9873-476923c33722",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa"
    ]
  },
  "76567": {
    "NUM": "25",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 23, 1948",
    "EXP": "6",
    "SCHOOL": "San Jose State",
    "name": "Coby Dietrick",
    "rosters": [
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661"
    ]
  },
  "76568": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 15, 1951",
    "EXP": "3",
    "SCHOOL": "Providence",
    "name": "Ernie DiGregorio",
    "rosters": [
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f"
    ]
  },
  "76569": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "170",
    "BIRTH_DATE": "OCT 15, 1958",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Mickey Dillard",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a"
    ]
  },
  "76574": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUN 15, 1967",
    "EXP": "1",
    "SCHOOL": "North Carolina-Charlotte",
    "name": "Byron Dinkins",
    "rosters": [
      "955db219-1fe5-405f-a607-095000d7ba49",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3"
    ]
  },
  "76575": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 22, 1950",
    "EXP": "R",
    "SCHOOL": "Voorhees",
    "name": "Jackie Dinkins",
    "rosters": [
      "940cea5e-14c6-4fd7-a44a-e5415c80a822"
    ]
  },
  "76576": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 15, 1943",
    "EXP": "1",
    "SCHOOL": "New Mexico Highlands",
    "name": "Bill Dinwiddie",
    "rosters": [
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604"
    ]
  },
  "76577": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "189",
    "BIRTH_DATE": "NOV 21, 1940",
    "EXP": "7",
    "SCHOOL": "Purdue",
    "name": "Terry Dischinger",
    "rosters": [
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "3902314e-3111-45fa-9433-86d9fdae07db",
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5",
      "49db955c-2a3f-4693-b68f-834ab5003c4d",
      "d80f75e6-baf7-4843-8122-aa743aea3477",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226"
    ]
  },
  "76578": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 09, 1929",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "Fred Diute",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800"
    ]
  },
  "76582": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 10, 1919",
    "EXP": "3",
    "SCHOOL": "Colorado",
    "name": "Bob Doll",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6",
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1"
    ]
  },
  "76583": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "275",
    "BIRTH_DATE": "AUG 16, 1957",
    "EXP": "13",
    "SCHOOL": "Washington State",
    "name": "James Donaldson",
    "rosters": [
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "6cb87ca5-b208-46a1-b393-022423c35c0d",
      "9cf046e5-f475-41ef-a3d3-f517368a157b",
      "a59119a0-b26d-4a60-b162-d2df226404ce",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "9b1970e7-fc1f-42c1-b8d3-dee500ab71dd"
    ]
  },
  "76584": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 11, 1926",
    "EXP": "3",
    "SCHOOL": "Ohio State",
    "name": "Bob Donham",
    "rosters": [
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7",
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc",
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b",
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71"
    ]
  },
  "76585": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 10, 1926",
    "EXP": "R",
    "SCHOOL": "Muhlenberg",
    "name": "Harry Donovan",
    "rosters": [
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7"
    ]
  },
  "76586": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "171",
    "BIRTH_DATE": "MAY 30, 1965",
    "EXP": "R",
    "SCHOOL": "Providence",
    "name": "Billy Donovan",
    "rosters": [
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44"
    ]
  },
  "76587": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 18, 1954",
    "EXP": "2",
    "SCHOOL": "Georgia",
    "name": "Jacky Dorsey",
    "rosters": [
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "6cb87ca5-b208-46a1-b393-022423c35c0d"
    ]
  },
  "76588": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 09, 1964",
    "EXP": "R",
    "SCHOOL": "Illinois",
    "name": "Bruce Douglas",
    "rosters": [
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22"
    ]
  },
  "76589": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUN 12, 1956",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "John Douglas",
    "rosters": [
      "de2e838d-3259-430d-8634-0759e68893b8",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e"
    ]
  },
  "76590": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 26, 1954",
    "EXP": "3",
    "SCHOOL": "Alabama",
    "name": "Leon Douglas",
    "rosters": [
      "19686855-a120-434c-8ad4-5f15e61b9a38",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "76592": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "198",
    "BIRTH_DATE": "AUG 16, 1945",
    "EXP": "1",
    "SCHOOL": "St. John's (NY)",
    "name": "Lloyd Dove",
    "rosters": [
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5",
      "49db955c-2a3f-4693-b68f-834ab5003c4d"
    ]
  },
  "76594": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 09, 1950",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "Steve Downing",
    "rosters": [
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0"
    ]
  },
  "76595": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 06, 1940",
    "EXP": "R",
    "SCHOOL": "Belmont Abbey",
    "name": "Danny Doyle",
    "rosters": [
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0"
    ]
  },
  "76596": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 29, 1966",
    "EXP": "R",
    "SCHOOL": "South Carolina",
    "name": "Terry Dozier",
    "rosters": [
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "76598": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 30, 1954",
    "EXP": "10",
    "SCHOOL": "Gardner-Webb",
    "name": "John Drew",
    "rosters": [
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474",
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a"
    ]
  },
  "76599": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 02, 1958",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Larry Drew",
    "rosters": [
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "c163d65f-d6fd-4a44-968a-2897543255d4"
    ]
  },
  "76603": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 28, 1947",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "Terry Driscoll",
    "rosters": [
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9"
    ]
  },
  "76604": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "250",
    "BIRTH_DATE": "APR 20, 1954",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Ralph Drollinger",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9"
    ]
  },
  "76605": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 25, 1933",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Dick Duckett",
    "rosters": [
      "21936ffd-2bd4-4505-a647-b4cd447d157b"
    ]
  },
  "76607": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 05, 1950",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Charles Dudley",
    "rosters": [
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444",
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0"
    ]
  },
  "76608": {
    "NUM": "40",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 29, 1956",
    "EXP": "R",
    "SCHOOL": "Detroit Mercy",
    "name": "Terry Duerod",
    "rosters": [
      "928904ed-6459-463d-af51-942ab0d40da2",
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "76609": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 05, 1922",
    "EXP": "R",
    "SCHOOL": "Tulane",
    "name": "Bob Duffy",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "76610": {
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 26, 1940",
    "EXP": "2",
    "SCHOOL": "Colgate",
    "name": "Bob Duffy",
    "rosters": [
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a",
      "3902314e-3111-45fa-9433-86d9fdae07db"
    ]
  },
  "76611": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 23, 1930",
    "EXP": "7",
    "SCHOOL": "Seton Hall",
    "name": "Walter Dukes",
    "rosters": [
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3",
      "03f980b2-d8d0-47cc-a777-71a16e029764",
      "29b33649-2e3d-444b-81b4-caec18163bdd",
      "a4934f64-d807-440b-9639-6e4052c2501e",
      "e6ca1168-2bff-4198-9727-665654e83794",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0"
    ]
  },
  "76615": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 17, 1922",
    "EXP": "1",
    "SCHOOL": "William & Mary",
    "name": "Andy Duncan",
    "rosters": [
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7",
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0"
    ]
  },
  "76616": {
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 21, 1954",
    "EXP": "6",
    "SCHOOL": "South Carolina",
    "name": "Mike Dunleavy",
    "rosters": [
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661"
    ]
  },
  "76617": {
    "NUM": "19",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 17, 1931",
    "EXP": "R",
    "SCHOOL": "Utah State",
    "name": "Pat Dunn",
    "rosters": [
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447"
    ]
  },
  "76618": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "192",
    "BIRTH_DATE": "FEB 01, 1955",
    "EXP": "2",
    "SCHOOL": "Alabama",
    "name": "T.R. Dunn",
    "rosters": [
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "4ac401d3-899b-427d-89d6-584fe69ebf75"
    ]
  },
  "76619": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 30, 1958",
    "EXP": "1",
    "SCHOOL": "Georgetown",
    "name": "John Duren",
    "rosters": [
      "c24811b3-b1da-43ab-9ab2-9a68328f351c",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5",
      "16161b6e-7707-4383-b96c-3f7cf17fe149"
    ]
  },
  "76621": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 20, 1960",
    "EXP": "1",
    "SCHOOL": "Brigham Young",
    "name": "Devin Durrant",
    "rosters": [
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f"
    ]
  },
  "76622": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 08, 1948",
    "EXP": "2",
    "SCHOOL": "La Salle",
    "name": "Ken Durrett",
    "rosters": [
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba"
    ]
  },
  "76623": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 31, 1952",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Dennis DuVal",
    "rosters": [
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7"
    ]
  },
  "76624": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 03, 1921",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Jack Dwan",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503"
    ]
  },
  "76625": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 11, 1959",
    "EXP": "R",
    "SCHOOL": "Long Beach State",
    "name": "Craig Dykema",
    "rosters": [
      "aca2f6cb-0959-42f9-a0a5-49c843300715"
    ]
  },
  "76626": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 17, 1930",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Gene Dyker",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25"
    ]
  },
  "76628": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 24, 1946",
    "EXP": "1",
    "SCHOOL": "Brigham Young",
    "name": "Jim Eakins",
    "rosters": [
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34"
    ]
  },
  "76630": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 28, 1927",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Ed Earle",
    "rosters": [
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8"
    ]
  },
  "76631": {
    "NUM": "53",
    "POSITION": "C",
    "HEIGHT": "7-4",
    "WEIGHT": "275",
    "BIRTH_DATE": "JAN 24, 1957",
    "EXP": "10",
    "SCHOOL": "California-Los Angeles",
    "name": "Mark Eaton",
    "rosters": [
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "eecb8d44-e66b-417f-bd80-36f97052a98a",
      "955486b2-508c-4776-884b-e521d5730bf8",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "e34e570c-e94f-47f1-927d-d070fd2321f8",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f"
    ]
  },
  "76632": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 08, 1959",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Jerry Eaves",
    "rosters": [
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291"
    ]
  },
  "76633": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 07, 1935",
    "EXP": "R",
    "SCHOOL": "Detroit Mercy",
    "name": "Bill Ebben",
    "rosters": [
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3"
    ]
  },
  "76634": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 10, 1952",
    "EXP": "3",
    "SCHOOL": "Missouri",
    "name": "Al Eberhard",
    "rosters": [
      "94f36f95-2ce4-4f55-8a83-19b910b11438",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3"
    ]
  },
  "76635": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "DEC 27, 1967",
    "EXP": "R",
    "SCHOOL": "Mississippi",
    "name": "Patrick Eddie",
    "rosters": [
      "67b5af29-cb30-4af3-aa8c-89db35f1c392"
    ]
  },
  "76636": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "189",
    "BIRTH_DATE": "DEC 27, 1922",
    "EXP": "3",
    "SCHOOL": "Illinois",
    "name": "Thomas Eddleman",
    "rosters": [
      "6d008515-8165-4990-a179-942eeec92822",
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "02a3a04b-002a-4afa-8188-8f11ccfafda1",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e"
    ]
  },
  "76637": {
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAY 24, 1962",
    "EXP": "R",
    "SCHOOL": "Virginia",
    "name": "Kent Edelin",
    "rosters": [
      "85ec4e60-957a-4941-aa38-d850c52dbd29"
    ]
  },
  "76638": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 28, 1960",
    "EXP": "1",
    "SCHOOL": "Purdue",
    "name": "Keith Edmonson",
    "rosters": [
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "606a6691-6b18-478d-b60f-85baee1be70c"
    ]
  },
  "76641": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 02, 1959",
    "EXP": "6",
    "SCHOOL": "Cleveland State",
    "name": "Franklin Edwards",
    "rosters": [
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "76643": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 03, 1969",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Jay Edwards",
    "rosters": [
      "26c5d00c-d04d-45ae-bc04-83d0ee557440"
    ]
  },
  "76646": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 22, 1971",
    "EXP": "R",
    "SCHOOL": "Wright State",
    "name": "Bill Edwards",
    "rosters": [
      "f8980f9a-2074-4965-8469-9086e1c70f28"
    ]
  },
  "76647": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 31, 1939",
    "EXP": "1",
    "SCHOOL": "Providence",
    "name": "Johnny Egan",
    "rosters": [
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00",
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "e6ca1168-2bff-4198-9727-665654e83794",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0"
    ]
  },
  "76649": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "198",
    "BIRTH_DATE": "MAR 10, 1923",
    "EXP": "1",
    "SCHOOL": "Purdue",
    "name": "Eddie Ehlers",
    "rosters": [
      "e032e6bf-840a-444b-8044-7f6795db68e6",
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "76651": {
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 21, 1933",
    "EXP": "R",
    "SCHOOL": "Southeast Missouri State",
    "name": "Dick Eichhorst",
    "rosters": [
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0"
    ]
  },
  "76653": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 24, 1918",
    "EXP": "R",
    "SCHOOL": "Hamline",
    "name": "Don Eliason",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "76655": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 18, 1922",
    "EXP": "1",
    "SCHOOL": "West Texas A&M",
    "name": "Ray Ellefson",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04"
    ]
  },
  "76656": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 18, 1955",
    "EXP": "2",
    "SCHOOL": "Arizona",
    "name": "Bob Elliott",
    "rosters": [
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "30f87621-72fd-4d91-987d-50cce0bf1714"
    ]
  },
  "76658": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 11, 1936",
    "EXP": "1",
    "SCHOOL": "Niagara",
    "name": "Alexander Ellis",
    "rosters": [
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8"
    ]
  },
  "76661": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 03, 1944",
    "EXP": "7",
    "SCHOOL": "San Francisco",
    "name": "Joe Ellis",
    "rosters": [
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "852d8746-a465-41f3-b644-085c99bbad27",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864"
    ]
  },
  "76663": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 10, 1940",
    "EXP": "7",
    "SCHOOL": "St. John's (NY)",
    "name": "Leroy Ellis",
    "rosters": [
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "41977d46-c447-4c82-8cb1-8952bd2b335e",
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "0403c301-445c-4c5d-8144-e5805bdc801c"
    ]
  },
  "76664": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "197",
    "BIRTH_DATE": "AUG 08, 1954",
    "EXP": "2",
    "SCHOOL": "Marquette",
    "name": "Bo Ellis",
    "rosters": [
      "66743650-e493-4c91-a3ab-5a488a063464",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f"
    ]
  },
  "76667": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 15, 1952",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Darrell Elston",
    "rosters": [
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d"
    ]
  },
  "76668": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 26, 1937",
    "EXP": "7",
    "SCHOOL": "Miami (FL)",
    "name": "Wayne Embry",
    "rosters": [
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00",
      "dc41b99f-710f-4f81-b125-45b52cf09053",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce",
      "020d6aa2-861a-4e30-8595-c38b6904fb12",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef"
    ]
  },
  "76670": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAR 01, 1959",
    "EXP": "4",
    "SCHOOL": "Wyoming",
    "name": "Chris Engler",
    "rosters": [
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766"
    ]
  },
  "76671": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "DEC 06, 1963",
    "EXP": "R",
    "SCHOOL": "California-Irvine",
    "name": "Wayne Englestad",
    "rosters": [
      "e5a6a74e-32e5-4965-829e-f866fe21f504"
    ]
  },
  "76672": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 11, 1967",
    "EXP": "1",
    "SCHOOL": "Virginia Union",
    "name": "A.J. English",
    "rosters": [
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1"
    ]
  },
  "76673": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 05, 1954",
    "EXP": "2",
    "SCHOOL": "South Carolina",
    "name": "Alex English",
    "rosters": [
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "743aba57-7e93-4575-8cb7-e3de53d6223c",
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "b53d3c18-a4f1-40bf-a266-2555fa606018"
    ]
  },
  "76674": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 26, 1946",
    "EXP": "R",
    "SCHOOL": "Rhode Island",
    "name": "Claude English",
    "rosters": [
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40"
    ]
  },
  "76675": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 20, 1950",
    "EXP": "R",
    "SCHOOL": "Texas-El Paso",
    "name": "Scott English",
    "rosters": [
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c"
    ]
  },
  "76676": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 04, 1970",
    "EXP": "2",
    "SCHOOL": "South Carolina",
    "name": "Jojo English",
    "rosters": [
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "fbcf2148-8ad7-45e7-9bd1-30d23b258343"
    ]
  },
  "76677": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 21, 1917",
    "EXP": "R",
    "SCHOOL": "Wisconsin",
    "name": "Gene Englund",
    "rosters": [
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1"
    ]
  },
  "76678": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 20, 1956",
    "EXP": "R",
    "SCHOOL": "Norfolk State",
    "name": "Ray Epps",
    "rosters": [
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10"
    ]
  },
  "76679": {
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 30, 1932",
    "EXP": "R",
    "SCHOOL": "Niagara",
    "name": "Bo Erias",
    "rosters": [
      "8f51d7d4-966b-43d1-84b2-9267b61db974"
    ]
  },
  "76680": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 19, 1944",
    "EXP": "11",
    "SCHOOL": "California-Los Angeles",
    "name": "Keith Erickson",
    "rosters": [
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22"
    ]
  },
  "76681": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 22, 1950",
    "EXP": "10",
    "SCHOOL": "Massachusetts",
    "name": "Julius Erving",
    "rosters": [
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "3d24a988-08f7-4aad-ae1e-c528947c7844"
    ]
  },
  "76683": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "198",
    "BIRTH_DATE": "MAR 01, 1969",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Vincenzo Esposito",
    "rosters": [
      "4a934d85-87c5-4059-9963-b76f50593f0b"
    ]
  },
  "76685": {
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "202",
    "BIRTH_DATE": "NOV 11, 1955",
    "EXP": "R",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Earl Evans",
    "rosters": [
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "76686": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 19, 1955",
    "EXP": "R",
    "SCHOOL": "Kansas State",
    "name": "Mike Evans",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "38ac7402-b81e-4254-b636-95330a997974"
    ]
  },
  "76689": {
    "NUM": "10",
    "POSITION": "F-G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "APR 04, 1905",
    "EXP": "1",
    "SCHOOL": "Rhode Island",
    "name": "Johnny Ezersky",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "76691": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 28, 1943",
    "EXP": "R",
    "SCHOOL": "Brigham Young",
    "name": "John Fairchild",
    "rosters": [
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d"
    ]
  },
  "76692": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 03, 1924",
    "EXP": "R",
    "SCHOOL": "City College of New York",
    "name": "Phil Farbman",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "76693": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 13, 1932",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "Dick Farley",
    "rosters": [
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "03f980b2-d8d0-47cc-a777-71a16e029764"
    ]
  },
  "76694": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 26, 1936",
    "EXP": "2",
    "SCHOOL": "San Francisco",
    "name": "Mike Farmer",
    "rosters": [
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "fd982b48-258b-4fc9-b6d2-1be39fa32925",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce"
    ]
  },
  "76695": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 23, 1964",
    "EXP": "1",
    "SCHOOL": "Alabama",
    "name": "Jim Farmer",
    "rosters": [
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "eecb8d44-e66b-417f-bd80-36f97052a98a"
    ]
  },
  "76697": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 10, 1940",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Dave Fedor",
    "rosters": [
      "60508db9-69f9-4133-ac4d-b9848b2add2d"
    ]
  },
  "76699": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 19, 1954",
    "EXP": "R",
    "SCHOOL": "Vanderbilt",
    "name": "Butch Feher",
    "rosters": [
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22"
    ]
  },
  "76701": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 06, 1931",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Ron Feiereisel",
    "rosters": [
      "156bc9fb-9be0-4d47-ae6c-e255ee721988"
    ]
  },
  "76702": {
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 02, 1929",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "George Feigenbaum",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd"
    ]
  },
  "76703": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 08, 1962",
    "EXP": "2",
    "SCHOOL": "Texas-El Paso",
    "name": "Dave Feitl",
    "rosters": [
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58"
    ]
  },
  "76704": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 10, 1930",
    "EXP": "5",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Ray Felix",
    "rosters": [
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012"
    ]
  },
  "76705": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 12, 1929",
    "EXP": "1",
    "SCHOOL": "Northwestern",
    "name": "Jake Fendley",
    "rosters": [
      "02a3a04b-002a-4afa-8188-8f11ccfafda1",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e"
    ]
  },
  "76706": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 08, 1922",
    "EXP": "R",
    "SCHOOL": "Manhattan",
    "name": "Bill Fenley",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "76707": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 01, 1953",
    "EXP": "5",
    "SCHOOL": "San Francisco",
    "name": "Eric Fernsten",
    "rosters": [
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757"
    ]
  },
  "76708": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 06, 1933",
    "EXP": "5",
    "SCHOOL": "Michigan State",
    "name": "Al Ferrari",
    "rosters": [
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b",
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "76709": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "7-1",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 24, 1964",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "Rolando Ferreira",
    "rosters": [
      "148d19d7-f6a8-401d-b449-7e8b111f7959"
    ]
  },
  "76711": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 29, 1925",
    "EXP": "2",
    "SCHOOL": "Utah",
    "name": "Arnie Ferrin",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503",
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9",
      "0c2ad895-0fc8-49c0-9976-48e929a0c370"
    ]
  },
  "76712": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 31, 1937",
    "EXP": "4",
    "SCHOOL": "Saint Louis",
    "name": "Bob Ferry",
    "rosters": [
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "b204df04-9a5e-48f7-814f-b547b0690ab8",
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "a4934f64-d807-440b-9639-6e4052c2501e",
      "e6ca1168-2bff-4198-9727-665654e83794",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a"
    ]
  },
  "76713": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 09, 1962",
    "EXP": "1",
    "SCHOOL": "California-Los Angeles",
    "name": "Kenny Fields",
    "rosters": [
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec"
    ]
  },
  "76714": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 05, 1944",
    "EXP": "R",
    "SCHOOL": "Tennessee Tech",
    "name": "Ron Filipek",
    "rosters": [
      "56bc558b-98d9-4a75-9033-fbec8e9fe666"
    ]
  },
  "76715": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 07, 1947",
    "EXP": "1",
    "SCHOOL": "Cheyney",
    "name": "Greg Fillmore",
    "rosters": [
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12"
    ]
  },
  "76716": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 20, 1942",
    "EXP": "R",
    "SCHOOL": "Dayton",
    "name": "Hank Finkel",
    "rosters": [
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9",
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9"
    ]
  },
  "76717": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 27, 1928",
    "EXP": "2",
    "SCHOOL": "St. John's (NY)",
    "name": "Danny Finn",
    "rosters": [
      "9398ab8b-2109-490e-8b37-d2bbaadb361f",
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f",
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86"
    ]
  },
  "76720": {
    "NUM": "10   12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 14, 1923",
    "EXP": "1",
    "SCHOOL": "Fordham",
    "name": "Bob Fitzgerald",
    "rosters": [
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9"
    ]
  },
  "76721": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 14, 1922",
    "EXP": "4",
    "SCHOOL": "New York University",
    "name": "Jerry Fleishman",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c",
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a",
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb",
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388",
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "76722": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 05, 1954",
    "EXP": "R",
    "SCHOOL": "Arizona",
    "name": "Al Fleming",
    "rosters": [
      "6306dadf-1ee7-4678-b035-ef14578746a4"
    ]
  },
  "76723": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "189",
    "BIRTH_DATE": "JUL 25, 1933",
    "EXP": "1",
    "SCHOOL": "Niagara",
    "name": "Ed Fleming",
    "rosters": [
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "516c9243-1276-46d0-8dbe-f1db0a8384ad",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b"
    ]
  },
  "76725": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 13, 1957",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Bruce Flowers",
    "rosters": [
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d"
    ]
  },
  "76726": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 06, 1960",
    "EXP": "11",
    "SCHOOL": "Georgetown",
    "name": "Sleepy Floyd",
    "rosters": [
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "79d8e657-ce27-4052-9492-c9833808173f",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "6b1946cc-c9da-4ef6-946c-6fecb44b4720",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388"
    ]
  },
  "76727": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 31, 1953",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Mike Flynn",
    "rosters": [
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0"
    ]
  },
  "76728": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 19, 1953",
    "EXP": "R",
    "SCHOOL": "Canisius",
    "name": "Larry Fogle",
    "rosters": [
      "52649402-55a4-4d97-a5fd-f7cf9779a672"
    ]
  },
  "76729": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 19, 1939",
    "EXP": "R",
    "SCHOOL": "Holy Cross",
    "name": "Jack Foley",
    "rosters": [
      "ba894f72-662a-4d86-8791-ddc3f2492c47"
    ]
  },
  "76731": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 01, 1948",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Levi Fontaine",
    "rosters": [
      "852d8746-a465-41f3-b644-085c99bbad27"
    ]
  },
  "76732": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 31, 1971",
    "EXP": "R",
    "SCHOOL": "Mississippi Valley State",
    "name": "Alphonso Ford",
    "rosters": [
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1",
      "0c8fc0cf-daa5-4e88-8b60-1caaa6717655"
    ]
  },
  "76733": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 11, 1949",
    "EXP": "5",
    "SCHOOL": "Villanova",
    "name": "Chris Ford",
    "rosters": [
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185",
      "94f36f95-2ce4-4f55-8a83-19b910b11438",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3"
    ]
  },
  "76734": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 31, 1952",
    "EXP": "3",
    "SCHOOL": "California-Santa Barbara",
    "name": "Don Ford",
    "rosters": [
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77",
      "fff2243e-9747-44b5-8882-14d1d9d99603",
      "e92adc84-abb2-4977-92b5-c33434e3233a"
    ]
  },
  "76735": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "APR 29, 1946",
    "EXP": "1",
    "SCHOOL": "Maryland",
    "name": "Jake Ford",
    "rosters": [
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4"
    ]
  },
  "76736": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "FEB 09, 1956",
    "EXP": "3",
    "SCHOOL": "North Carolina",
    "name": "Phil Ford",
    "rosters": [
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "19686855-a120-434c-8ad4-5f15e61b9a38",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365"
    ]
  },
  "76738": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 17, 1926",
    "EXP": "R",
    "SCHOOL": "New York University",
    "name": "Donnie Forman",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503"
    ]
  },
  "76739": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 08, 1954",
    "EXP": "1",
    "SCHOOL": "Grand Canyon",
    "name": "Bayard Forrest",
    "rosters": [
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "e8500690-7102-4acf-ae8b-f1907cf60bcf"
    ]
  },
  "76740": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 18, 1946",
    "EXP": "4",
    "SCHOOL": "Miami (OH)",
    "name": "Fred Foster",
    "rosters": [
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa",
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39",
      "5d519571-b461-4e3b-963a-2173662c4b55"
    ]
  },
  "76742": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "160",
    "BIRTH_DATE": "OCT 10, 1960",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "Rod Foster",
    "rosters": [
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f"
    ]
  },
  "76744": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 24, 1928",
    "EXP": "6",
    "SCHOOL": "La Salle",
    "name": "Larry Foust",
    "rosters": [
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "648376a8-6ee8-4cad-97d2-369540020f50",
      "02a3a04b-002a-4afa-8188-8f11ccfafda1",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e",
      "57494c03-afc0-41de-b544-659990d5e952",
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91",
      "0a699404-02aa-4953-8be2-08a5fc726ea3",
      "edb95a5d-c995-43f0-9378-d27051d259ab"
    ]
  },
  "76745": {
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUN 20, 1927",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Jerry Fowler",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "76746": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 29, 1949",
    "EXP": "R",
    "SCHOOL": "Jacksonville",
    "name": "Harold Fox",
    "rosters": [
      "683a627e-6110-4e8e-890b-ab8162df16d5"
    ]
  },
  "76747": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 07, 1943",
    "EXP": "7",
    "SCHOOL": "South Carolina",
    "name": "Jim Fox",
    "rosters": [
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "20955b01-8d24-45f5-ba05-3a4253c801f7",
      "c5964149-f86c-413e-9d7c-c8d07f4113ef",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "cc792a0f-fa98-4caf-ad55-38fe38f01714"
    ]
  },
  "76748": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 26, 1965",
    "EXP": "4",
    "SCHOOL": "Western Kentucky",
    "name": "Tellis Frank",
    "rosters": [
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "54f0f70e-ad29-449d-8754-ee6a602fe375"
    ]
  },
  "76750": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 29, 1945",
    "EXP": "9",
    "SCHOOL": "Southern Illinois",
    "name": "Walt Frazier",
    "rosters": [
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "52649402-55a4-4d97-a5fd-f7cf9779a672",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c"
    ]
  },
  "76751": {
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 24, 1942",
    "EXP": "R",
    "SCHOOL": "Grambling",
    "name": "Will Frazier",
    "rosters": [
      "754f9d27-7573-442d-8f17-5aeb6cb61e54"
    ]
  },
  "76752": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 07, 1964",
    "EXP": "2",
    "SCHOOL": "Pepperdine",
    "name": "Anthony Frederick",
    "rosters": [
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170"
    ]
  },
  "76753": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 09, 1953",
    "EXP": "11",
    "SCHOOL": "Guilford",
    "name": "World Free",
    "rosters": [
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "ef880e29-8409-4464-a009-ac827384a450",
      "41977d46-c447-4c82-8cb1-8952bd2b335e",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "3d24a988-08f7-4aad-ae1e-c528947c7844"
    ]
  },
  "76754": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 18, 1944",
    "EXP": "R",
    "SCHOOL": "Illinois",
    "name": "Donnie Freeman",
    "rosters": [
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb"
    ]
  },
  "76755": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 25, 1948",
    "EXP": "R",
    "SCHOOL": "Oregon State",
    "name": "Gary Freeman",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1"
    ]
  },
  "76756": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 05, 1950",
    "EXP": "R",
    "SCHOOL": "Vanderbilt",
    "name": "Rod Freeman",
    "rosters": [
      "992f03c2-845c-48f9-8e9d-5f3af3edf011"
    ]
  },
  "76757": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 26, 1921",
    "EXP": "R",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Frido Frey",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24"
    ]
  },
  "76758": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 14, 1935",
    "EXP": "R",
    "SCHOOL": "California",
    "name": "Larry Friend",
    "rosters": [
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b"
    ]
  },
  "76759": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 18, 1945",
    "EXP": "R",
    "SCHOOL": "Colorado",
    "name": "Pat Frink",
    "rosters": [
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604"
    ]
  },
  "76760": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 10, 1931",
    "EXP": "1",
    "SCHOOL": "Hamline",
    "name": "Jim Fritsche",
    "rosters": [
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91"
    ]
  },
  "76761": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 25, 1949",
    "EXP": "1",
    "SCHOOL": "Brigham Young",
    "name": "Bernie Fryer",
    "rosters": [
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "76764": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 26, 1921",
    "EXP": "7",
    "SCHOOL": "Murray State",
    "name": "Joe Fulks",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c",
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a",
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb",
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388",
      "78cf5ce6-637f-459d-812a-47c6b4615dc4",
      "1c190e61-1a8b-4cd1-aba2-fae468830890",
      "9398ab8b-2109-490e-8b37-d2bbaadb361f",
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f"
    ]
  },
  "76765": {
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 04, 1958",
    "EXP": "R",
    "SCHOOL": "Pepperdine",
    "name": "Tony Fuller",
    "rosters": [
      "c163d65f-d6fd-4a44-968a-2897543255d4"
    ]
  },
  "76767": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 18, 1954",
    "EXP": "3",
    "SCHOOL": "Michigan State",
    "name": "Terry Furlow",
    "rosters": [
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "76768": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAY 13, 1922",
    "EXP": "5",
    "SCHOOL": "Syracuse",
    "name": "Billy Gabor",
    "rosters": [
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "98673c29-08ee-48ce-9891-81b0ab92d951",
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e",
      "b7540846-94ec-42ec-b008-ff241f8152ea",
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8",
      "bb00331b-e519-4f8f-b671-c89f34e10d57"
    ]
  },
  "76770": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 01, 1965",
    "EXP": "4",
    "SCHOOL": "Loyola-Marymount",
    "name": "Corey Gaines",
    "rosters": [
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "bd667c0d-7fdb-4bc4-86f5-1f7fc940d91c",
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1"
    ]
  },
  "76771": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 18, 1950",
    "EXP": "4",
    "SCHOOL": "Elizabeth City State",
    "name": "Mike Gale",
    "rosters": [
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148",
      "38ac7402-b81e-4254-b636-95330a997974",
      "a6b98be6-4acd-49be-92b6-decd2522e4df"
    ]
  },
  "76772": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAY 30, 1969",
    "EXP": "R",
    "SCHOOL": "Creighton",
    "name": "Chad Gallagher",
    "rosters": [
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5"
    ]
  },
  "76773": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 26, 1927",
    "EXP": "9",
    "SCHOOL": "Truman State",
    "name": "Harry Gallatin",
    "rosters": [
      "df1fce20-7cea-47cc-8e99-cf6d772b3162",
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac",
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d",
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3"
    ]
  },
  "76774": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 16, 1937",
    "EXP": "10",
    "SCHOOL": "Oregon State",
    "name": "Dave Gambee",
    "rosters": [
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2",
      "b4492284-7d41-45e4-b3d7-fd93667614a1",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a",
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "49db955c-2a3f-4693-b68f-834ab5003c4d"
    ]
  },
  "76778": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 18, 1923",
    "EXP": "R",
    "SCHOOL": "DePauw",
    "name": "Earl Gardner",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503"
    ]
  },
  "76779": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 14, 1925",
    "EXP": "2",
    "SCHOOL": "Utah",
    "name": "Vern Gardner",
    "rosters": [
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388",
      "78cf5ce6-637f-459d-812a-47c6b4615dc4",
      "1c190e61-1a8b-4cd1-aba2-fae468830890"
    ]
  },
  "76780": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 13, 1918",
    "EXP": "2",
    "SCHOOL": "St. John's (NY)",
    "name": "Jack Garfinkel",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654",
      "e032e6bf-840a-444b-8044-7f6795db68e6",
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "76781": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 12, 1957",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Gary Garland",
    "rosters": [
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f"
    ]
  },
  "76782": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "DEC 19, 1964",
    "EXP": "6",
    "SCHOOL": "Missouri State",
    "name": "Winston Garland",
    "rosters": [
      "37167a67-466f-4920-b036-1481c8665cf9",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090"
    ]
  },
  "76783": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 29, 1932",
    "EXP": "5",
    "SCHOOL": "Minnesota",
    "name": "Dick Garmaker",
    "rosters": [
      "156bc9fb-9be0-4d47-ae6c-e255ee721988",
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8",
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "2fb8b948-e857-417f-8af2-422f0825a7c1"
    ]
  },
  "76785": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 22, 1960",
    "EXP": "3",
    "SCHOOL": "Wyoming",
    "name": "Bill Garnett",
    "rosters": [
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd"
    ]
  },
  "76786": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 11, 1956",
    "EXP": "3",
    "SCHOOL": "Oral Roberts",
    "name": "Calvin Garrett",
    "rosters": [
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "48eedca2-29c5-4d77-a299-70678b08bfa7"
    ]
  },
  "76788": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 31, 1947",
    "EXP": "4",
    "SCHOOL": "Southern Illinois",
    "name": "Eldo Garrett",
    "rosters": [
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00",
      "98a19076-a6e8-4bc0-b991-e249597a93b2"
    ]
  },
  "76789": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 16, 1950",
    "EXP": "4",
    "SCHOOL": "Florida State",
    "name": "Rowland Garrett",
    "rosters": [
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "66953847-11ae-490d-ba1c-76473d320167",
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "743aba57-7e93-4575-8cb7-e3de53d6223c"
    ]
  },
  "76790": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 07, 1966",
    "EXP": "2",
    "SCHOOL": "Rhode Island",
    "name": "Tom Garrick",
    "rosters": [
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea"
    ]
  },
  "76791": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 06, 1959",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "John Garris",
    "rosters": [
      "1eb2a245-2321-463c-b8b3-437375f0d653"
    ]
  },
  "76793": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 05, 1950",
    "EXP": "R",
    "SCHOOL": "Boston University",
    "name": "Jim Garvin",
    "rosters": [
      "a7302500-52fe-4776-937c-0c2c59b29346"
    ]
  },
  "76797": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 11, 1927",
    "EXP": "R",
    "SCHOOL": "Washington State",
    "name": "Ed Gayda",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b"
    ]
  },
  "76800": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 13, 1928",
    "EXP": "7",
    "SCHOOL": "La Salle",
    "name": "Jack George",
    "rosters": [
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f",
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86",
      "05b98b66-c377-4196-b63f-7c9749f98f47",
      "59113a13-7bcb-4a63-908c-a566386976eb",
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447",
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "2fb8b948-e857-417f-8af2-422f0825a7c1"
    ]
  },
  "76801": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 29, 1968",
    "EXP": "2",
    "SCHOOL": "Connecticut",
    "name": "Tate George",
    "rosters": [
      "20b624e6-187e-4570-9266-08913829ee83",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "45b3a59c-7578-494d-9525-3352a060f871"
    ]
  },
  "76802": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 27, 1953",
    "EXP": "2",
    "SCHOOL": "Virginia",
    "name": "Gus Gerard",
    "rosters": [
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "a6b98be6-4acd-49be-92b6-decd2522e4df",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "76803": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 28, 1963",
    "EXP": "1",
    "SCHOOL": "Texas-San Antonio",
    "name": "Derrick Gervin",
    "rosters": [
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "4c6f87e3-5009-4111-80db-5390790d46bb"
    ]
  },
  "76804": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "180",
    "BIRTH_DATE": "APR 27, 1952",
    "EXP": "8",
    "SCHOOL": "Eastern Michigan",
    "name": "George Gervin",
    "rosters": [
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148",
      "38ac7402-b81e-4254-b636-95330a997974",
      "a6b98be6-4acd-49be-92b6-decd2522e4df",
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "5c09ec26-5505-4508-98c5-db244798d9d1"
    ]
  },
  "76806": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 10, 1950",
    "EXP": "7",
    "SCHOOL": "Pacific",
    "name": "John Gianelli",
    "rosters": [
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "52649402-55a4-4d97-a5fd-f7cf9779a672",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "76807": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 20, 1948",
    "EXP": "3",
    "SCHOOL": "Texas-El Paso",
    "name": "Dick Gibbs",
    "rosters": [
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7"
    ]
  },
  "76808": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 25, 1923",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Dee Gibson",
    "rosters": [
      "6d008515-8165-4990-a179-942eeec92822"
    ]
  },
  "76809": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 30, 1940",
    "EXP": "R",
    "SCHOOL": "Western Carolina",
    "name": "Mel Gibson",
    "rosters": [
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f"
    ]
  },
  "76810": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 27, 1960",
    "EXP": "1",
    "SCHOOL": "South Carolina Upstate",
    "name": "Mike Gibson",
    "rosters": [
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b"
    ]
  },
  "76812": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 19, 1965",
    "EXP": "R",
    "SCHOOL": "Georgetown",
    "name": "Ben Gillery",
    "rosters": [
      "65fe3c0f-07c5-469c-b786-9542d2d55742"
    ]
  },
  "76815": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 05, 1946",
    "EXP": "6",
    "SCHOOL": "Purdue",
    "name": "Herm Gilliam",
    "rosters": [
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c"
    ]
  },
  "76817": {
    "NUM": "48",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 27, 1947",
    "EXP": "R",
    "SCHOOL": "Fort Valley State",
    "name": "Walt Gilmore",
    "rosters": [
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40"
    ]
  },
  "76819": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 21, 1956",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Jack Givens",
    "rosters": [
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474"
    ]
  },
  "76821": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "221",
    "BIRTH_DATE": "NOV 12, 1967",
    "EXP": "2",
    "SCHOOL": "Mississippi",
    "name": "Gerald Glass",
    "rosters": [
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "76822": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 10, 1955",
    "EXP": "3",
    "SCHOOL": "Southern Illinois",
    "name": "Mike Glenn",
    "rosters": [
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "b001782c-95ad-444b-814b-98ecf23fa9ce",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b"
    ]
  },
  "76823": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 10, 1927",
    "EXP": "R",
    "SCHOOL": "Loyola-Marymount",
    "name": "Norman Glick",
    "rosters": [
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9"
    ]
  },
  "76824": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 10, 1960",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Georgi Glouchkov",
    "rosters": [
      "2f38049d-f39e-4d79-9815-11d4fdf4013f"
    ]
  },
  "76825": {
    "NUM": "28",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 01, 1947",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Clarence Glover",
    "rosters": [
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9"
    ]
  },
  "76826": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 03, 1959",
    "EXP": "12",
    "SCHOOL": "Duke",
    "name": "Mike Gminski",
    "rosters": [
      "c414034d-9405-454b-81a1-00b89efc08b5",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e",
      "b4d79b93-f806-4a0d-a90c-18babfcbb170",
      "3e72397e-e399-4a1e-bd06-a9b455547453"
    ]
  },
  "76827": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 14, 1967",
    "EXP": "R",
    "SCHOOL": "Evansville",
    "name": "Dan Godfread",
    "rosters": [
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61"
    ]
  },
  "76828": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 13, 1933",
    "EXP": "9",
    "SCHOOL": "La Salle",
    "name": "Tom Gola",
    "rosters": [
      "05b98b66-c377-4196-b63f-7c9749f98f47",
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447",
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae",
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5",
      "ba894f72-662a-4d86-8791-ddc3f2492c47",
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "833e0d65-644b-4fde-b265-1327632fc59c"
    ]
  },
  "76830": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "218",
    "BIRTH_DATE": "AUG 30, 1955",
    "EXP": "1",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Glen Gondrezick",
    "rosters": [
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "b001782c-95ad-444b-814b-98ecf23fa9ce"
    ]
  },
  "76831": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 19, 1963",
    "EXP": "R",
    "SCHOOL": "Pepperdine",
    "name": "Grant Gondrezick",
    "rosters": [
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "90b30987-294d-488e-ade2-2e9dd74bb802"
    ]
  },
  "76832": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 23, 1943",
    "EXP": "13",
    "SCHOOL": "California-Los Angeles",
    "name": "Gail Goodrich",
    "rosters": [
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "c39c5026-a493-40db-84cd-272c3b126805",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "20955b01-8d24-45f5-ba05-3a4253c801f7",
      "c5964149-f86c-413e-9d7c-c8d07f4113ef",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827",
      "5517a85a-4623-414c-89a6-b90bb5a42eca",
      "a8b9d60d-1449-49af-89fc-945fadd03a04"
    ]
  },
  "76834": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 24, 1962",
    "EXP": "3",
    "SCHOOL": "Louisville",
    "name": "Lancaster Gordon",
    "rosters": [
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d"
    ]
  },
  "76836": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 28, 1920",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Leo Gottlieb",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24",
      "bb996e83-b963-4fd6-b44b-484116ff5a4e"
    ]
  },
  "76837": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 17, 1928",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Bato Govedarica",
    "rosters": [
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8"
    ]
  },
  "76838": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 15, 1930",
    "EXP": "12",
    "SCHOOL": null,
    "name": "Joe Graboski",
    "rosters": [
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f",
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86",
      "05b98b66-c377-4196-b63f-7c9749f98f47",
      "59113a13-7bcb-4a63-908c-a566386976eb",
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447",
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae",
      "b4492284-7d41-45e4-b3d7-fd93667614a1"
    ]
  },
  "76839": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 20, 1967",
    "EXP": "R",
    "SCHOOL": "Oklahoma",
    "name": "Ricky Grace",
    "rosters": [
      "06369f70-ef9a-4da3-8cef-0a062e244b3c"
    ]
  },
  "76841": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 05, 1965",
    "EXP": "R",
    "SCHOOL": "Auburn",
    "name": "Orlando Graham",
    "rosters": [
      "14984cdc-30be-46d0-8591-dfe9b9b7c482"
    ]
  },
  "76842": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 28, 1967",
    "EXP": "2",
    "SCHOOL": "Ohio",
    "name": "Paul Graham",
    "rosters": [
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "06369f70-ef9a-4da3-8cef-0a062e244b3c"
    ]
  },
  "76843": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 23, 1945",
    "EXP": "1",
    "SCHOOL": "New York University",
    "name": "Mal Graham",
    "rosters": [
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "b576b451-62e4-4a16-98e2-535cecc8e551"
    ]
  },
  "76844": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 04, 1960",
    "EXP": "R",
    "SCHOOL": "South Florida",
    "name": "Jim Grandholm",
    "rosters": [
      "24a0d010-462a-4f0b-89b1-6cd920460093"
    ]
  },
  "76847": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 27, 1961",
    "EXP": "2",
    "SCHOOL": "Villanova",
    "name": "Stewart Granger",
    "rosters": [
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "b2182580-6b74-4d01-905a-961e5a283423"
    ]
  },
  "76850": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 20, 1927",
    "EXP": "1",
    "SCHOOL": "Minnesota",
    "name": "Bud Grant",
    "rosters": [
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9",
      "0c2ad895-0fc8-49c0-9976-48e929a0c370"
    ]
  },
  "76852": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "223",
    "BIRTH_DATE": "AUG 07, 1967",
    "EXP": "R",
    "SCHOOL": "Utah",
    "name": "Josh Grant",
    "rosters": [
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c"
    ]
  },
  "76853": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 01, 1950",
    "EXP": "1",
    "SCHOOL": "Kentucky State",
    "name": "Travis Grant",
    "rosters": [
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "c39c5026-a493-40db-84cd-272c3b126805"
    ]
  },
  "76855": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 05, 1962",
    "EXP": "R",
    "SCHOOL": "Yale",
    "name": "Butch Graves",
    "rosters": [
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389"
    ]
  },
  "76859": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 23, 1945",
    "EXP": "R",
    "SCHOOL": "Oklahoma City",
    "name": "Gary Gray",
    "rosters": [
      "b630b545-ede0-408a-a797-133d5fdeaa10"
    ]
  },
  "76860": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "DEC 19, 1951",
    "EXP": "2",
    "SCHOOL": "Long Beach State",
    "name": "Leonard Gray",
    "rosters": [
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c",
      "8718c236-d862-42de-a84d-ef29c83369b9"
    ]
  },
  "76861": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 27, 1963",
    "EXP": "4",
    "SCHOOL": "California-Los Angeles",
    "name": "Stuart Gray",
    "rosters": [
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5"
    ]
  },
  "76862": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 08, 1967",
    "EXP": "R",
    "SCHOOL": "Memphis",
    "name": "Sylvester Gray",
    "rosters": [
      "652536cb-d53b-4de7-a147-555aacb21048"
    ]
  },
  "76863": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 20, 1922",
    "EXP": "R",
    "SCHOOL": "Harvard",
    "name": "Wyndol Gray",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "76865": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "206",
    "BIRTH_DATE": "SEP 15, 1947",
    "EXP": "1",
    "SCHOOL": "Rutgers",
    "name": "Bob Greacen",
    "rosters": [
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "30c679a6-4b92-42bc-b03d-303894f5aed9"
    ]
  },
  "76867": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 08, 1933",
    "EXP": "7",
    "SCHOOL": "Michigan State",
    "name": "Johnny Green",
    "rosters": [
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "2fb8b948-e857-417f-8af2-422f0825a7c1",
      "816164e0-e2ee-413e-8662-b51fbf40b841",
      "ba894f72-662a-4d86-8791-ddc3f2492c47",
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "e08b4914-59a6-4d7e-836c-db576c3c3723",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa",
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4"
    ]
  },
  "76868": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 19, 1959",
    "EXP": "R",
    "SCHOOL": "Texas-Pan American",
    "name": "Ken Green",
    "rosters": [
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa"
    ]
  },
  "76869": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 11, 1964",
    "EXP": "R",
    "SCHOOL": "Wake Forest",
    "name": "Kenny Green",
    "rosters": [
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "76872": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 13, 1946",
    "EXP": "R",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Luther Green",
    "rosters": [
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada"
    ]
  },
  "76873": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 06, 1951",
    "EXP": "1",
    "SCHOOL": "Louisiana Tech",
    "name": "Mike Green",
    "rosters": [
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148",
      "8718c236-d862-42de-a84d-ef29c83369b9",
      "6306dadf-1ee7-4678-b035-ef14578746a4"
    ]
  },
  "76874": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "AUG 18, 1954",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "Rickey Green",
    "rosters": [
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5",
      "16161b6e-7707-4383-b96c-3f7cf17fe149",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "76875": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 02, 1970",
    "EXP": "2",
    "SCHOOL": "Iona",
    "name": "Sean Green",
    "rosters": [
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5"
    ]
  },
  "76876": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 04, 1961",
    "EXP": "9",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Sidney Green",
    "rosters": [
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "3e72397e-e399-4a1e-bd06-a9b455547453"
    ]
  },
  "76877": {
    "NUM": "11-21",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 20, 1933",
    "EXP": "7",
    "SCHOOL": "Duquesne",
    "name": "Si Green",
    "rosters": [
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b",
      "e5799d65-732c-4105-b48e-fe6c0e46eee6",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "b204df04-9a5e-48f7-814f-b547b0690ab8"
    ]
  },
  "76878": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 04, 1953",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "Steve Green",
    "rosters": [
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "b53d3c18-a4f1-40bf-a266-2555fa606018"
    ]
  },
  "76879": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 08, 1956",
    "EXP": "R",
    "SCHOOL": "Southern University",
    "name": "Tommy Green",
    "rosters": [
      "a8b9d60d-1449-49af-89fc-945fadd03a04"
    ]
  },
  "76880": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 22, 1941",
    "EXP": "1",
    "SCHOOL": "Maryland",
    "name": "Jerry Greenspan",
    "rosters": [
      "084db916-a6ce-4651-bbc2-a41c3e21369a",
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22"
    ]
  },
  "76881": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "222",
    "BIRTH_DATE": "MAY 27, 1957",
    "EXP": "10",
    "SCHOOL": "California-Los Angeles",
    "name": "David Greenwood",
    "rosters": [
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "ffc81b35-207d-48f8-8091-52733fd65961"
    ]
  },
  "76882": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 26, 1936",
    "EXP": "14",
    "SCHOOL": "Marshall",
    "name": "Hal Greer",
    "rosters": [
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "a45c9665-5951-47aa-ad90-2e2f872cee40",
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2",
      "b4492284-7d41-45e4-b3d7-fd93667614a1",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a",
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "56bc558b-98d9-4a75-9033-fbec8e9fe666",
      "e08b4914-59a6-4d7e-836c-db576c3c3723",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada"
    ]
  },
  "76883": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 13, 1945",
    "EXP": "3",
    "SCHOOL": "South Carolina",
    "name": "Gary Gregor",
    "rosters": [
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "20955b01-8d24-45f5-ba05-3a4253c801f7",
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4"
    ]
  },
  "76884": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 26, 1958",
    "EXP": "R",
    "SCHOOL": "Wisconsin",
    "name": "Claude Gregory",
    "rosters": [
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "76885": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 28, 1961",
    "EXP": "R",
    "SCHOOL": "Oregon",
    "name": "John Greig",
    "rosters": [
      "a59119a0-b26d-4a60-b162-d2df226404ce"
    ]
  },
  "76886": {
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 22, 1930",
    "EXP": "R",
    "SCHOOL": "La Salle",
    "name": "Norm Grekin",
    "rosters": [
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f"
    ]
  },
  "76887": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 12, 1953",
    "EXP": "7",
    "SCHOOL": "Kentucky",
    "name": "Kevin Grevey",
    "rosters": [
      "ffa6228e-39da-4145-8319-41a17387cede",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fd666397-8775-4cb6-9c9b-67881c31f005",
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66",
      "dd527857-adb8-40c3-9804-8aa4212b8486",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409",
      "a52d7bc3-ed81-4836-b70b-85c958af2548",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95"
    ]
  },
  "76888": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 06, 1952",
    "EXP": "R",
    "SCHOOL": "Idaho State",
    "name": "Greg Griffin",
    "rosters": [
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de"
    ]
  },
  "76889": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 20, 1954",
    "EXP": "2",
    "SCHOOL": "Western Michigan",
    "name": "Paul Griffin",
    "rosters": [
      "38ac7402-b81e-4254-b636-95330a997974",
      "a6b98be6-4acd-49be-92b6-decd2522e4df",
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827",
      "5517a85a-4623-414c-89a6-b90bb5a42eca",
      "a8b9d60d-1449-49af-89fc-945fadd03a04"
    ]
  },
  "76890": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 16, 1958",
    "EXP": "9",
    "SCHOOL": "Louisville",
    "name": "Darrell Griffith",
    "rosters": [
      "79272c8a-a0ac-4650-b0a6-e971f46652f5",
      "16161b6e-7707-4383-b96c-3f7cf17fe149",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "eecb8d44-e66b-417f-bd80-36f97052a98a",
      "955486b2-508c-4776-884b-e521d5730bf8",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff"
    ]
  },
  "76891": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 15, 1928",
    "EXP": "R",
    "SCHOOL": "Dayton",
    "name": "Chuck Grigsby",
    "rosters": [
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa"
    ]
  },
  "76894": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 04, 1930",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Dick Groat",
    "rosters": [
      "e527a536-aa8c-43ac-8b79-dd0230b9780e"
    ]
  },
  "76895": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 03, 1953",
    "EXP": "6",
    "SCHOOL": "Long Beach State",
    "name": "Bob Gross",
    "rosters": [
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "cbf01feb-6986-43c2-8350-23e707cf8272",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "4ac401d3-899b-427d-89d6-584fe69ebf75",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f"
    ]
  },
  "76896": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 28, 1940",
    "EXP": "R",
    "SCHOOL": "Loyola-Marymount",
    "name": "Jerry Grote",
    "rosters": [
      "12d8f06a-c574-4a71-bd2f-9b827d839693"
    ]
  },
  "76899": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 24, 1955",
    "EXP": "4",
    "SCHOOL": "Tennessee",
    "name": "Ernie Grunfeld",
    "rosters": [
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "6a10cc0f-c034-4cee-992e-4023a7280654",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "19686855-a120-434c-8ad4-5f15e61b9a38",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365"
    ]
  },
  "76900": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 13, 1937",
    "EXP": "3",
    "SCHOOL": "George Washington",
    "name": "Gene Guarilia",
    "rosters": [
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "540c48fa-176d-4700-a617-fc71b5ac523b"
    ]
  },
  "76901": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "260",
    "BIRTH_DATE": "OCT 30, 1958",
    "EXP": "3",
    "SCHOOL": "Washington",
    "name": "Petur Gudmundsson",
    "rosters": [
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a"
    ]
  },
  "76902": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 29, 1932",
    "EXP": "6",
    "SCHOOL": "Iona",
    "name": "Richie Guerin",
    "rosters": [
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "fd982b48-258b-4fc9-b6d2-1be39fa32925",
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "385be451-82b9-4142-849d-362d686d325b",
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "2fb8b948-e857-417f-8af2-422f0825a7c1",
      "816164e0-e2ee-413e-8662-b51fbf40b841",
      "ba894f72-662a-4d86-8791-ddc3f2492c47"
    ]
  },
  "76903": {
    "NUM": "51",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 28, 1968",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Andres Guibert",
    "rosters": [
      "54f0f70e-ad29-449d-8754-ee6a602fe375",
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090"
    ]
  },
  "76904": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "AUG 18, 1969",
    "EXP": "1",
    "SCHOOL": "Minnesota-Duluth",
    "name": "Jay Guidinger",
    "rosters": [
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d"
    ]
  },
  "76906": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 22, 1937",
    "EXP": "R",
    "SCHOOL": "Iowa",
    "name": "Dave Gunther",
    "rosters": [
      "60508db9-69f9-4133-ac4d-b9848b2add2d"
    ]
  },
  "76908": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "175",
    "BIRTH_DATE": "FEB 25, 1944",
    "EXP": "9",
    "SCHOOL": "Saint Joseph's",
    "name": "Matt Guokas",
    "rosters": [
      "66953847-11ae-490d-ba1c-76473d320167",
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "56bc558b-98d9-4a75-9033-fbec8e9fe666",
      "e08b4914-59a6-4d7e-836c-db576c3c3723",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "dd460500-38ea-4afb-99af-548234a3036d"
    ]
  },
  "76909": {
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 11, 1915",
    "EXP": "R",
    "SCHOOL": "Saint Joseph's",
    "name": "Matt Guokas",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c"
    ]
  },
  "76910": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 10, 1953",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Rudy Hackett",
    "rosters": [
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d"
    ]
  },
  "76911": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 02, 1966",
    "EXP": "1",
    "SCHOOL": "Evansville",
    "name": "Scott Haffner",
    "rosters": [
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e"
    ]
  },
  "76912": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 09, 1931",
    "EXP": "9",
    "SCHOOL": "Kentucky",
    "name": "Cliff Hagan",
    "rosters": [
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "fd982b48-258b-4fc9-b6d2-1be39fa32925"
    ]
  },
  "76913": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUN 25, 1955",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "Glenn Hagan",
    "rosters": [
      "b49a0527-aa53-424d-aeb3-bc7f3d439938"
    ]
  },
  "76915": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "DEC 11, 1945",
    "EXP": "1",
    "SCHOOL": "Bowling Green",
    "name": "Al Hairston",
    "rosters": [
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "3829b824-1221-432d-8dc1-388a4d2f1cce"
    ]
  },
  "76916": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 31, 1942",
    "EXP": "5",
    "SCHOOL": "New York University",
    "name": "Happy Hairston",
    "rosters": [
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "c39c5026-a493-40db-84cd-272c3b126805",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef",
      "38c6a8b5-9c04-429f-9873-476923c33722",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "49db955c-2a3f-4693-b68f-834ab5003c4d",
      "d80f75e6-baf7-4843-8122-aa743aea3477"
    ]
  },
  "76917": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 08, 1951",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Lindsay Hairston",
    "rosters": [
      "e8407b6f-093b-43a2-ac16-f610ffc2510b"
    ]
  },
  "76919": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 30, 1933",
    "EXP": "1",
    "SCHOOL": "Oregon State",
    "name": "Harvey Halbrook",
    "rosters": [
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2",
      "b4492284-7d41-45e4-b3d7-fd93667614a1"
    ]
  },
  "76920": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "AUG 30, 1918",
    "EXP": "R",
    "SCHOOL": "Santa Clara",
    "name": "Bruce Hale",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "76922": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "199",
    "BIRTH_DATE": "MAR 30, 1945",
    "EXP": "2",
    "SCHOOL": "Utah State",
    "name": "Shaler Halimon",
    "rosters": [
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "e08b4914-59a6-4d7e-836c-db576c3c3723",
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40"
    ]
  },
  "76923": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "193",
    "BIRTH_DATE": "JUL 03, 1949",
    "EXP": "1",
    "SCHOOL": "Drake",
    "name": "Jeff Halliburton",
    "rosters": [
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada"
    ]
  },
  "76926": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 08, 1944",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Dennis Hamilton",
    "rosters": [
      "385be451-82b9-4142-849d-362d686d325b",
      "b58e37fe-d855-4948-b0f9-9422b49423ba"
    ]
  },
  "76928": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 20, 1957",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Roylee Hamilton",
    "rosters": [
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "76929": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 30, 1935",
    "EXP": "1",
    "SCHOOL": "Morehead State",
    "name": "Steve Hamilton",
    "rosters": [
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8"
    ]
  },
  "76935": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 06, 1922",
    "EXP": "1",
    "SCHOOL": "Oklahoma State",
    "name": "Cecil Hankins",
    "rosters": [
      "e032e6bf-840a-444b-8044-7f6795db68e6"
    ]
  },
  "76936": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 26, 1951",
    "EXP": "1",
    "SCHOOL": "Pennsylvania",
    "name": "Phil Hankinson",
    "rosters": [
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0"
    ]
  },
  "76937": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 19, 1923",
    "EXP": "4",
    "SCHOOL": "Southern California",
    "name": "Alex Hannum",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800",
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b",
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "98673c29-08ee-48ce-9891-81b0ab92d951",
      "817867c3-ef94-48b9-81aa-768f4f8f758e",
      "a0311ade-2019-4595-ab92-26a1696f3ce7",
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6"
    ]
  },
  "76940": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 21, 1952",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Glenn Hansen",
    "rosters": [
      "dd460500-38ea-4afb-99af-548234a3036d",
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef"
    ]
  },
  "76941": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 14, 1954",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Lars Hansen",
    "rosters": [
      "5e43acaa-2104-49ad-b07f-b37c04e28912"
    ]
  },
  "76942": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 18, 1961",
    "EXP": "6",
    "SCHOOL": "Iowa",
    "name": "Bob Hansen",
    "rosters": [
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "eecb8d44-e66b-417f-bd80-36f97052a98a",
      "955486b2-508c-4776-884b-e521d5730bf8"
    ]
  },
  "76944": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 06, 1957",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Bill Hanzlik",
    "rosters": [
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "6cb87ca5-b208-46a1-b393-022423c35c0d",
      "9cf046e5-f475-41ef-a3d3-f517368a157b"
    ]
  },
  "76945": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "249",
    "BIRTH_DATE": "MAY 04, 1942",
    "EXP": "2",
    "SCHOOL": "Eastern",
    "name": "Reggie Harding",
    "rosters": [
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a",
      "3902314e-3111-45fa-9433-86d9fdae07db",
      "65f693c5-b99c-4553-b1db-458345ed8c34"
    ]
  },
  "76946": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 13, 1938",
    "EXP": "2",
    "SCHOOL": "Grambling",
    "name": "Charlie Hardnett",
    "rosters": [
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "b204df04-9a5e-48f7-814f-b547b0690ab8"
    ]
  },
  "76947": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 25, 1957",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "Alan Hardy",
    "rosters": [
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938"
    ]
  },
  "76948": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 01, 1956",
    "EXP": "3",
    "SCHOOL": "San Francisco",
    "name": "James Hardy",
    "rosters": [
      "a8b9d60d-1449-49af-89fc-945fadd03a04",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5",
      "16161b6e-7707-4383-b96c-3f7cf17fe149"
    ]
  },
  "76949": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 20, 1920",
    "EXP": "1",
    "SCHOOL": "Texas",
    "name": "John Hargis",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b"
    ]
  },
  "76950": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 07, 1940",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Jerry Harkness",
    "rosters": [
      "67233742-0a5f-4ab9-a3f8-682956af416d"
    ]
  },
  "76951": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 14, 1946",
    "EXP": "R",
    "SCHOOL": "South Carolina",
    "name": "Skip Harlicka",
    "rosters": [
      "385be451-82b9-4142-849d-362d686d325b"
    ]
  },
  "76952": {
    "NUM": "40",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 06, 1969",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Jerome Harmon",
    "rosters": [
      "4290a9e9-24bd-4fec-ada4-6bfc734308c1"
    ]
  },
  "76954": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 09, 1957",
    "EXP": "1",
    "SCHOOL": "North Park",
    "name": "Mike Harper",
    "rosters": [
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f"
    ]
  },
  "76956": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 13, 1947",
    "EXP": "1",
    "SCHOOL": "Stanford",
    "name": "Art Harris",
    "rosters": [
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "3829b824-1221-432d-8dc1-388a4d2f1cce"
    ]
  },
  "76957": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 26, 1950",
    "EXP": "R",
    "SCHOOL": "Virginia Commonwealth",
    "name": "Bernie Harris",
    "rosters": [
      "e172999e-d18d-439a-83cc-746ead7f5c56"
    ]
  },
  "76958": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 11, 1933",
    "EXP": "R",
    "SCHOOL": "Dayton",
    "name": "Chris Harris",
    "rosters": [
      "516c9243-1276-46d0-8dbe-f1db0a8384ad"
    ]
  },
  "76959": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 16, 1927",
    "EXP": "R",
    "SCHOOL": "Oklahoma State",
    "name": "Bob Harris",
    "rosters": [
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7",
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc",
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b",
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e"
    ]
  },
  "76960": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 15, 1963",
    "EXP": "3",
    "SCHOOL": "Tulsa",
    "name": "Steve Harris",
    "rosters": [
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294"
    ]
  },
  "76961": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 13, 1967",
    "EXP": "R",
    "SCHOOL": "New Orleans",
    "name": "Tony Harris",
    "rosters": [
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9"
    ]
  },
  "76962": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 12, 1927",
    "EXP": "8",
    "SCHOOL": "Michigan",
    "name": "Bob Harrison",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "d734ea86-9401-4fa7-846a-e69cc31d9800",
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b",
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9",
      "0c2ad895-0fc8-49c0-9976-48e929a0c370",
      "56568a86-b30e-48a7-9f7d-80f79aa00339",
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2",
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a"
    ]
  },
  "76965": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 11, 1943",
    "EXP": "8",
    "SCHOOL": "Western Kentucky",
    "name": "Clem Haskins",
    "rosters": [
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "5ea792bd-890a-47b6-babe-2afabfd78f09",
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "fd666397-8775-4cb6-9c9b-67881c31f005"
    ]
  },
  "76966": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 11, 1955",
    "EXP": "1",
    "SCHOOL": "Providence",
    "name": "Joey Hassett",
    "rosters": [
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "6306dadf-1ee7-4678-b035-ef14578746a4",
      "5e43acaa-2104-49ad-b07f-b37c04e28912"
    ]
  },
  "76967": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 21, 1921",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Billy Hassett",
    "rosters": [
      "6d008515-8165-4990-a179-942eeec92822"
    ]
  },
  "76968": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 03, 1960",
    "EXP": "8",
    "SCHOOL": "Arkansas",
    "name": "Scott Hastings",
    "rosters": [
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "652536cb-d53b-4de7-a147-555aacb21048",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c"
    ]
  },
  "76969": {
    "NUM": "52",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 13, 1936",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Vern Hatton",
    "rosters": [
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae"
    ]
  },
  "76970": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "203",
    "BIRTH_DATE": "APR 08, 1940",
    "EXP": "15",
    "SCHOOL": "Ohio State",
    "name": "John Havlicek",
    "rosters": [
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "b576b451-62e4-4a16-98e2-535cecc8e551",
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9",
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "9661eddb-8e02-42a3-859a-311e00d67a47"
    ]
  },
  "76971": {
    "NUM": "10",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 26, 1950",
    "EXP": "9",
    "SCHOOL": "Washington",
    "name": "Steve Hawes",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474",
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "cbf01feb-6986-43c2-8350-23e707cf8272",
      "a59119a0-b26d-4a60-b162-d2df226404ce",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce"
    ]
  },
  "76972": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 17, 1942",
    "EXP": "4",
    "SCHOOL": "Iowa",
    "name": "Connie Hawkins",
    "rosters": [
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "c5964149-f86c-413e-9d7c-c8d07f4113ef",
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "bd877b88-34bc-4231-95a1-6063a90eca41"
    ]
  },
  "76975": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 30, 1954",
    "EXP": "3",
    "SCHOOL": "Illinois State",
    "name": "Robert Hawkins",
    "rosters": [
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "76977": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 22, 1936",
    "EXP": "6",
    "SCHOOL": "Notre Dame",
    "name": "Tom Hawkins",
    "rosters": [
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef"
    ]
  },
  "76978": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 19, 1951",
    "EXP": "2",
    "SCHOOL": "Southern Illinois",
    "name": "Nate Hawthorne",
    "rosters": [
      "c39c5026-a493-40db-84cd-272c3b126805",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74"
    ]
  },
  "76979": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 17, 1945",
    "EXP": "12",
    "SCHOOL": "Houston",
    "name": "Elvin Hayes",
    "rosters": [
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "024d58a0-dd09-4396-a891-82ba087f7279",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "fd666397-8775-4cb6-9c9b-67881c31f005",
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66",
      "dd527857-adb8-40c3-9804-8aa4212b8486",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409",
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "76980": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 02, 1955",
    "EXP": "R",
    "SCHOOL": "Idaho State",
    "name": "Steve Hayes",
    "rosters": [
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938"
    ]
  },
  "76981": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 22, 1949",
    "EXP": "11",
    "SCHOOL": "Detroit Mercy",
    "name": "Spencer Haywood",
    "rosters": [
      "c3077e01-3d45-4725-9d9e-90a13761dbe8",
      "52649402-55a4-4d97-a5fd-f7cf9779a672",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "a8b9d60d-1449-49af-89fc-945fadd03a04",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95"
    ]
  },
  "76982": {
    "NUM": "3-10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "172",
    "BIRTH_DATE": "MAR 02, 1927",
    "EXP": "R",
    "SCHOOL": "Indiana State",
    "name": "John W. Hazen",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "76983": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 15, 1942",
    "EXP": "9",
    "SCHOOL": "California-Los Angeles",
    "name": "Walt Hazzard",
    "rosters": [
      "385be451-82b9-4142-849d-362d686d325b",
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "fa657246-a721-4d64-b321-9a6be5364290",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3"
    ]
  },
  "76984": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 03, 1946",
    "EXP": "R",
    "SCHOOL": "Acadia (CAN)",
    "name": "Brian Heaney",
    "rosters": [
      "0403c301-445c-4c5d-8144-e5805bdc801c"
    ]
  },
  "76985": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "219",
    "BIRTH_DATE": "MAY 03, 1948",
    "EXP": "2",
    "SCHOOL": "Oklahoma",
    "name": "Garfield Heard",
    "rosters": [
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "e8500690-7102-4acf-ae8b-f1907cf60bcf",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0"
    ]
  },
  "76986": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "170",
    "BIRTH_DATE": "JAN 01, 1930",
    "EXP": "R",
    "SCHOOL": "Canisius",
    "name": "Herm Hedderick",
    "rosters": [
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa"
    ]
  },
  "76987": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 08, 1967",
    "EXP": "R",
    "SCHOOL": "Texas",
    "name": "Alvin Heggs",
    "rosters": [
      "6c851fc6-72f6-46a0-9760-a9d43dd79674"
    ]
  },
  "76988": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "AUG 26, 1934",
    "EXP": "8",
    "SCHOOL": "Holy Cross",
    "name": "Tom Heinsohn",
    "rosters": [
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7",
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "40748532-955b-4d14-ab45-0409adb5a075"
    ]
  },
  "76989": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 29, 1933",
    "EXP": "1",
    "SCHOOL": "Wake Forest",
    "name": "Dick Hemric",
    "rosters": [
      "75b95e75-2397-48c9-b255-676792b7226d",
      "bae9643c-0a73-40b8-aa1d-bb17197c309f"
    ]
  },
  "76990": {
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 03, 1965",
    "EXP": "R",
    "SCHOOL": "Georgia",
    "name": "Cedric Henderson",
    "rosters": [
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd"
    ]
  },
  "76991": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 21, 1964",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Dave Henderson",
    "rosters": [
      "b42378b8-dafb-4789-b4ee-3aba286db3a9"
    ]
  },
  "76992": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 05, 1959",
    "EXP": "1",
    "SCHOOL": "New Mexico",
    "name": "Jerome Henderson",
    "rosters": [
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd"
    ]
  },
  "76993": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 16, 1956",
    "EXP": "12",
    "SCHOOL": "Virginia Commonwealth",
    "name": "Gerald Henderson",
    "rosters": [
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "0d39d553-7b93-4206-b525-d87f764e4d5d",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c",
      "db85dc84-6de1-4038-8c49-8f636dd20715"
    ]
  },
  "76994": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 22, 1964",
    "EXP": "R",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Kevin Henderson",
    "rosters": [
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "105e54a0-ada0-4f0c-a038-906cd453091e"
    ]
  },
  "76996": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 26, 1952",
    "EXP": "4",
    "SCHOOL": "Hawaii",
    "name": "Tom Henderson",
    "rosters": [
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "dd527857-adb8-40c3-9804-8aa4212b8486",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0"
    ]
  },
  "76998": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 20, 1929",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Larry Hennessy",
    "rosters": [
      "05b98b66-c377-4196-b63f-7c9749f98f47"
    ]
  },
  "76999": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 10, 1929",
    "EXP": "1",
    "SCHOOL": "California",
    "name": "Don Henriksen",
    "rosters": [
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af"
    ]
  },
  "77000": {
    "NUM": "26",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 09, 1949",
    "EXP": "1",
    "SCHOOL": "Wisconsin",
    "name": "Al Henry",
    "rosters": [
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1"
    ]
  },
  "77001": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 16, 1960",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Carl Henry",
    "rosters": [
      "b9189460-149e-4b0b-88c7-ad91b38bd16f"
    ]
  },
  "77002": {
    "NUM": "43",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 21, 1963",
    "EXP": "1",
    "SCHOOL": "California-Santa Barbara",
    "name": "Conner Henry",
    "rosters": [
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "77003": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 08, 1967",
    "EXP": "R",
    "SCHOOL": "Oklahoma",
    "name": "Skeeter Henry",
    "rosters": [
      "30517724-5902-4ca6-b448-35847695fb4a"
    ]
  },
  "77004": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 27, 1924",
    "EXP": "1",
    "SCHOOL": "Rice",
    "name": "Bill Henry",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922",
      "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e"
    ]
  },
  "77007": {
    "NUM": "10",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 12, 1923",
    "EXP": "4",
    "SCHOOL": "Minnesota",
    "name": "Clarence Hermsen",
    "rosters": [
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7"
    ]
  },
  "77010": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 14, 1956",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Keith Herron",
    "rosters": [
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "c163d65f-d6fd-4a44-968a-2897543255d4"
    ]
  },
  "77011": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 29, 1922",
    "EXP": "1",
    "SCHOOL": "City College of New York",
    "name": "Sidney Hertzberg",
    "rosters": [
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1",
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7",
      "5863b9f0-adf0-4042-9912-f445b7c8ee24",
      "21aca949-94aa-48fb-ace9-f2a8b20b1e77"
    ]
  },
  "77012": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 21, 1942",
    "EXP": "3",
    "SCHOOL": "Davidson",
    "name": "Fred Hetzel",
    "rosters": [
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604"
    ]
  },
  "77013": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 08, 1944",
    "EXP": "3",
    "SCHOOL": "Southern California",
    "name": "Bill Hewitt",
    "rosters": [
      "66953847-11ae-490d-ba1c-76473d320167",
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "d80f75e6-baf7-4843-8122-aa743aea3477",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226"
    ]
  },
  "77014": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 07, 1924",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Jack Hewson",
    "rosters": [
      "e032e6bf-840a-444b-8044-7f6795db68e6"
    ]
  },
  "77015": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 24, 1941",
    "EXP": "2",
    "SCHOOL": "Duke",
    "name": "Art Heyman",
    "rosters": [
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca"
    ]
  },
  "77017": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 31, 1953",
    "EXP": "R",
    "SCHOOL": "Tulane",
    "name": "Phil Hicks",
    "rosters": [
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "1f08be28-cf75-4838-8319-73b4c61b0c71"
    ]
  },
  "77019": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 31, 1960",
    "EXP": "10",
    "SCHOOL": "Fresno State",
    "name": "Rod Higgins",
    "rosters": [
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "92f101b9-7456-437b-8d91-9d5618b0b994",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d"
    ]
  },
  "77021": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 31, 1955",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Kenny Higgs",
    "rosters": [
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc"
    ]
  },
  "77022": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 25, 1957",
    "EXP": "3",
    "SCHOOL": "Nevada-Reno",
    "name": "Johnny High",
    "rosters": [
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "35e49d21-3b65-44c4-8cd2-e401df91e976"
    ]
  },
  "77023": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "192",
    "BIRTH_DATE": "JAN 14, 1940",
    "EXP": "4",
    "SCHOOL": "Kansas",
    "name": "Wayne Hightower",
    "rosters": [
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc",
      "65f693c5-b99c-4553-b1db-458345ed8c34"
    ]
  },
  "77024": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 31, 1953",
    "EXP": "4",
    "SCHOOL": "Princeton",
    "name": "Armond Hill",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "6cb87ca5-b208-46a1-b393-022423c35c0d"
    ]
  },
  "77025": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 24, 1938",
    "EXP": "R",
    "SCHOOL": "Winston-Salem State",
    "name": "Cleo Hill",
    "rosters": [
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0"
    ]
  },
  "77026": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 07, 1941",
    "EXP": "1",
    "SCHOOL": "Oklahoma City",
    "name": "Gary Hill",
    "rosters": [
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "b204df04-9a5e-48f7-814f-b547b0690ab8"
    ]
  },
  "77027": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 12, 1916",
    "EXP": "1",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Art Hillhouse",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c",
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a"
    ]
  },
  "77028": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 29, 1949",
    "EXP": "2",
    "SCHOOL": "San Jose State",
    "name": "Darnell Hillman",
    "rosters": [
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19"
    ]
  },
  "77029": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 15, 1948",
    "EXP": "1",
    "SCHOOL": "Grambling",
    "name": "Fred Hilton",
    "rosters": [
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "683a627e-6110-4e8e-890b-ab8162df16d5"
    ]
  },
  "77030": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 02, 1961",
    "EXP": "3",
    "SCHOOL": "Rutgers",
    "name": "Roy Hinson",
    "rosters": [
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "3d24a988-08f7-4aad-ae1e-c528947c7844"
    ]
  },
  "77031": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "5-6",
    "WEIGHT": "165",
    "BIRTH_DATE": "JUL 31, 1921",
    "EXP": "R",
    "SCHOOL": "Brooklyn",
    "name": "Mel Hirsch",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "77032": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 16, 1929",
    "EXP": "4",
    "SCHOOL": "Kansas State",
    "name": "Lew Hitch",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "59113a13-7bcb-4a63-908c-a566386976eb",
      "56568a86-b30e-48a7-9f7d-80f79aa00339",
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2",
      "22dd26a7-1c99-45b7-a759-dce48ccb6829",
      "156bc9fb-9be0-4d47-ae6c-e255ee721988"
    ]
  },
  "77034": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 27, 1960",
    "EXP": "5",
    "SCHOOL": "Long Beach State",
    "name": "Craig Hodges",
    "rosters": [
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "ca66d0d0-8947-439e-8245-2fecd01da018"
    ]
  },
  "77035": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "5-9",
    "WEIGHT": "158",
    "BIRTH_DATE": "JUL 12, 1917",
    "EXP": "1",
    "SCHOOL": "Queens (NY)",
    "name": "Adolph Hoefer",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654",
      "e032e6bf-840a-444b-8044-7f6795db68e6"
    ]
  },
  "77036": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 05, 1925",
    "EXP": "5",
    "SCHOOL": "Purdue",
    "name": "Bear, The Body Hoffman",
    "rosters": [
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86"
    ]
  },
  "77037": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 29, 1941",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Bob Hogsett",
    "rosters": [
      "65f693c5-b99c-4553-b1db-458345ed8c34"
    ]
  },
  "77038": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 28, 1940",
    "EXP": "1",
    "SCHOOL": "Cincinnati",
    "name": "Paul Hogue",
    "rosters": [
      "ba894f72-662a-4d86-8791-ddc3f2492c47",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b"
    ]
  },
  "77040": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 06, 1956",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "Brad Holland",
    "rosters": [
      "c3077e01-3d45-4725-9d9e-90a13761dbe8",
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb"
    ]
  },
  "77042": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 08, 1951",
    "EXP": "3",
    "SCHOOL": "New Orleans",
    "name": "Wilbur Holland",
    "rosters": [
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d"
    ]
  },
  "77043": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 19, 1953",
    "EXP": "8",
    "SCHOOL": "Arizona State",
    "name": "Lionel Hollins",
    "rosters": [
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "cbf01feb-6986-43c2-8350-23e707cf8272",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "4ac401d3-899b-427d-89d6-584fe69ebf75",
      "1a9e125c-e465-42e3-a800-c69234389252"
    ]
  },
  "77044": {
    "NUM": "22-24",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 16, 1955",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "Essie Hollis",
    "rosters": [
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "77045": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 24, 1930",
    "EXP": "3",
    "SCHOOL": "Cincinnati",
    "name": "Jim Holstein",
    "rosters": [
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2",
      "1b383d65-6ca5-46ca-b345-acf8e129e382",
      "22dd26a7-1c99-45b7-a759-dce48ccb6829",
      "0a699404-02aa-4953-8be2-08a5fc726ea3"
    ]
  },
  "77046": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 26, 1946",
    "EXP": "R",
    "SCHOOL": "Jackson State",
    "name": "Alvin Holt",
    "rosters": [
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2"
    ]
  },
  "77047": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 04, 1961",
    "EXP": "5",
    "SCHOOL": "California-Los Angeles",
    "name": "Michael Holton",
    "rosters": [
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "77048": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 29, 1921",
    "EXP": "R",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Dick Holub",
    "rosters": [
      "bb996e83-b963-4fd6-b44b-484116ff5a4e"
    ]
  },
  "77049": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 26, 1934",
    "EXP": "2",
    "SCHOOL": "George Washington",
    "name": "Joe Holup",
    "rosters": [
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3",
      "03f980b2-d8d0-47cc-a777-71a16e029764"
    ]
  },
  "77050": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 10, 1920",
    "EXP": "4",
    "SCHOOL": "City College of New York",
    "name": "Red Holzman",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0",
      "6146894f-3e97-4aab-a027-77228d1955a6",
      "817867c3-ef94-48b9-81aa-768f4f8f758e",
      "a0311ade-2019-4595-ab92-26a1696f3ce7"
    ]
  },
  "77051": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 23, 1941",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Tom Hoover",
    "rosters": [
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "7c3c8b10-137d-4f0f-8249-452818bc279f"
    ]
  },
  "77052": {
    "NUM": "9",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 03, 1934",
    "EXP": "3",
    "SCHOOL": "Grambling",
    "name": "Bob Hopkins",
    "rosters": [
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "a45c9665-5951-47aa-ad90-2e2f872cee40"
    ]
  },
  "77053": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 13, 1964",
    "EXP": "2",
    "SCHOOL": "Nebraska",
    "name": "Dave Hoppen",
    "rosters": [
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "77054": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 22, 1965",
    "EXP": "4",
    "SCHOOL": "Ohio State",
    "name": "Dennis Hopson",
    "rosters": [
      "d861b69c-544d-4879-b875-00604c148259",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504"
    ]
  },
  "77055": {
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 24, 1932",
    "EXP": "R",
    "SCHOOL": "Dayton",
    "name": "Johnny Horan",
    "rosters": [
      "156bc9fb-9be0-4d47-ae6c-e255ee721988"
    ]
  },
  "77056": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 08, 1957",
    "EXP": "1",
    "SCHOOL": "South Carolina",
    "name": "Cedrick Hordges",
    "rosters": [
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc"
    ]
  },
  "77057": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 19, 1966",
    "EXP": "2",
    "SCHOOL": "Miami (FL)",
    "name": "Tito Horford",
    "rosters": [
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7"
    ]
  },
  "77058": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 24, 1938",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "Ron Horn",
    "rosters": [
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1"
    ]
  },
  "77060": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 17, 1967",
    "EXP": "R",
    "SCHOOL": "Iowa",
    "name": "Ed Horton",
    "rosters": [
      "44e38364-f6e8-4d07-bb01-e7f82356367c"
    ]
  },
  "77061": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 20, 1946",
    "EXP": "1",
    "SCHOOL": "Ohio State",
    "name": "Wilmer Hosket",
    "rosters": [
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070"
    ]
  },
  "77062": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 12, 1932",
    "EXP": "4",
    "SCHOOL": "Washington",
    "name": "Bob Houbregs",
    "rosters": [
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91",
      "0a699404-02aa-4953-8be2-08a5fc726ea3",
      "edb95a5d-c995-43f0-9378-d27051d259ab",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3"
    ]
  },
  "77064": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 31, 1967",
    "EXP": "R",
    "SCHOOL": "Penn State",
    "name": "Tom Hovasse",
    "rosters": [
      "a859ae74-acea-4f80-abaa-92f971001064"
    ]
  },
  "77065": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "204",
    "BIRTH_DATE": "OCT 19, 1967",
    "EXP": "1",
    "SCHOOL": "North Carolina State",
    "name": "Brian Howard",
    "rosters": [
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c"
    ]
  },
  "77066": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 08, 1948",
    "EXP": "R",
    "SCHOOL": "New Mexico",
    "name": "Greg Howard",
    "rosters": [
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "f5458f44-ac3e-4c53-a59d-73946dd09d03"
    ]
  },
  "77067": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "AUG 24, 1954",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Mo Howard",
    "rosters": [
      "4100a46a-2845-4fc5-b5a7-28c783b0e827"
    ]
  },
  "77069": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 05, 1956",
    "EXP": "R",
    "SCHOOL": "Austin Peay",
    "name": "Otis Howard",
    "rosters": [
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "77070": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 20, 1937",
    "EXP": "4",
    "SCHOOL": "Mississippi State",
    "name": "Bailey Howell",
    "rosters": [
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "b576b451-62e4-4a16-98e2-535cecc8e551",
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "b204df04-9a5e-48f7-814f-b547b0690ab8",
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc",
      "29b33649-2e3d-444b-81b4-caec18163bdd",
      "a4934f64-d807-440b-9639-6e4052c2501e",
      "e6ca1168-2bff-4198-9727-665654e83794",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a"
    ]
  },
  "77071": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 13, 1956",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "Phil Hubbard",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b",
      "c163d65f-d6fd-4a44-968a-2897543255d4"
    ]
  },
  "77074": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 11, 1944",
    "EXP": "12",
    "SCHOOL": "Minnesota",
    "name": "Lou Hudson",
    "rosters": [
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "385be451-82b9-4142-849d-362d686d325b",
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "fa657246-a721-4d64-b321-9a6be5364290",
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "fff2243e-9747-44b5-8882-14d1d9d99603",
      "e92adc84-abb2-4977-92b5-c33434e3233a"
    ]
  },
  "77076": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 19, 1962",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Alfredrick Hughes",
    "rosters": [
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8"
    ]
  },
  "77077": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "165",
    "BIRTH_DATE": "MAY 26, 1960",
    "EXP": "R",
    "SCHOOL": "Colorado State",
    "name": "Eddie Hughes",
    "rosters": [
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f"
    ]
  },
  "77078": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 04, 1952",
    "EXP": "1",
    "SCHOOL": "Wisconsin",
    "name": "Kim Hughes",
    "rosters": [
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1"
    ]
  },
  "77080": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 04, 1948",
    "EXP": "5",
    "SCHOOL": "Princeton",
    "name": "John Hummer",
    "rosters": [
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c"
    ]
  },
  "77081": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 17, 1962",
    "EXP": "9",
    "SCHOOL": "Colorado",
    "name": "Jay Humphries",
    "rosters": [
      "62e0eda5-c4e2-4330-bbc4-6e9582e2d93a",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "da7fed67-9107-4124-becb-84e8169908af",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "49df35a0-09d0-4bff-b842-9b35d2edba0f",
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5"
    ]
  },
  "77082": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 26, 1934",
    "EXP": "5",
    "SCHOOL": "West Virginia",
    "name": "Rod Hundley",
    "rosters": [
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1"
    ]
  },
  "77083": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 16, 1965",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Cedric Hunter",
    "rosters": [
      "b4d79b93-f806-4a0d-a90c-18babfcbb170"
    ]
  },
  "77084": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 16, 1942",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Les Hunter",
    "rosters": [
      "b204df04-9a5e-48f7-814f-b547b0690ab8"
    ]
  },
  "77087": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 08, 1957",
    "EXP": "R",
    "SCHOOL": "Texas Tech",
    "name": "Geoff Huston",
    "rosters": [
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d"
    ]
  },
  "77089": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 22, 1928",
    "EXP": "5",
    "SCHOOL": "Brigham Young",
    "name": "Mel Hutchins",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699",
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd",
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "57494c03-afc0-41de-b544-659990d5e952",
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91",
      "0a699404-02aa-4953-8be2-08a5fc726ea3",
      "edb95a5d-c995-43f0-9378-d27051d259ab"
    ]
  },
  "77090": {
    "NUM": "4-15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "OCT 06, 1928",
    "EXP": "1",
    "SCHOOL": "Hamline",
    "name": "Joe Hutton",
    "rosters": [
      "0c2ad895-0fc8-49c0-9976-48e929a0c370",
      "56568a86-b30e-48a7-9f7d-80f79aa00339"
    ]
  },
  "77091": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 21, 1948",
    "EXP": "R",
    "SCHOOL": "Eastern New Mexico",
    "name": "Greg Hyder",
    "rosters": [
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39"
    ]
  },
  "77092": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 15, 1956",
    "EXP": "6",
    "SCHOOL": "Virginia",
    "name": "Marc Iavaroni",
    "rosters": [
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "5c09ec26-5505-4508-98c5-db244798d9d1",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "eecb8d44-e66b-417f-bd80-36f97052a98a"
    ]
  },
  "77093": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 11, 1938",
    "EXP": "3",
    "SCHOOL": "California",
    "name": "Darrall Imhoff",
    "rosters": [
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "2fb8b948-e857-417f-8af2-422f0825a7c1",
      "816164e0-e2ee-413e-8662-b51fbf40b841",
      "e08b4914-59a6-4d7e-836c-db576c3c3723",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4",
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a"
    ]
  },
  "77094": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 12, 1951",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Tom Ingelsby",
    "rosters": [
      "662a66d9-119b-4346-9391-e2e73fb7a872"
    ]
  },
  "77095": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-0",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 31, 1931",
    "EXP": "R",
    "SCHOOL": "Jackson State",
    "name": "Joel Ingram",
    "rosters": [
      "8f51d7d4-966b-43d1-84b2-9267b61db974"
    ]
  },
  "77096": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 02, 1966",
    "EXP": "2",
    "SCHOOL": "Missouri",
    "name": "Byron Irvin",
    "rosters": [
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05"
    ]
  },
  "77097": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 25, 1948",
    "EXP": "8",
    "SCHOOL": "Kentucky",
    "name": "Dan Issel",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967",
      "66743650-e493-4c91-a3ab-5a488a063464",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "92aca97c-c546-4b12-80d7-3f47b2556f23"
    ]
  },
  "77098": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 22, 1968",
    "EXP": "1",
    "SCHOOL": "Saint Francis (PA)",
    "name": "Mike Iuzzolino",
    "rosters": [
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c"
    ]
  },
  "77099": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 29, 1943",
    "EXP": "R",
    "SCHOOL": "Wilberforce",
    "name": "Al Jackson",
    "rosters": [
      "b630b545-ede0-408a-a797-133d5fdeaa10"
    ]
  },
  "77100": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "JAN 17, 1958",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Tony Jackson",
    "rosters": [
      "9894db71-7342-451a-9249-92e7308dbbe9"
    ]
  },
  "77101": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 02, 1952",
    "EXP": "R",
    "SCHOOL": "Guilford",
    "name": "Greg Jackson",
    "rosters": [
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792"
    ]
  },
  "77103": {
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 31, 1941",
    "EXP": "7",
    "SCHOOL": "Texas-Pan American",
    "name": "Lucious Jackson",
    "rosters": [
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "56bc558b-98d9-4a75-9033-fbec8e9fe666",
      "e08b4914-59a6-4d7e-836c-db576c3c3723",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1"
    ]
  },
  "77104": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "183",
    "BIRTH_DATE": "JUL 13, 1964",
    "EXP": "2",
    "SCHOOL": "Georgetown",
    "name": "Michael Jackson",
    "rosters": [
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47",
      "65fe3c0f-07c5-469c-b786-9542d2d55742",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff"
    ]
  },
  "77105": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 06, 1964",
    "EXP": "R",
    "SCHOOL": "Arkansas-Little Rock",
    "name": "Myron Jackson",
    "rosters": [
      "4c8ca104-147e-44d8-9de3-0c6d6a888309"
    ]
  },
  "77106": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 17, 1945",
    "EXP": "9",
    "SCHOOL": "North Dakota",
    "name": "Phil Jackson",
    "rosters": [
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "52649402-55a4-4d97-a5fd-f7cf9779a672",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb"
    ]
  },
  "77107": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 26, 1962",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Ralph Jackson",
    "rosters": [
      "85ec4e60-957a-4941-aa38-d850c52dbd29"
    ]
  },
  "77108": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 16, 1976",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Randall Jackson",
    "rosters": [
      "1bf4dfed-a893-4196-b0d4-e40d589ac7cf"
    ]
  },
  "77109": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 10, 1970",
    "EXP": "R",
    "SCHOOL": "Alabama-Birmingham",
    "name": "Stanley Jackson",
    "rosters": [
      "54f0f70e-ad29-449d-8754-ee6a602fe375"
    ]
  },
  "77110": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 21, 1959",
    "EXP": "2",
    "SCHOOL": "Notre Dame",
    "name": "Tracy Jackson",
    "rosters": [
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "84846342-36c1-4dc5-96ab-243c663b2065"
    ]
  },
  "77111": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 18, 1951",
    "EXP": "R",
    "SCHOOL": "Ohio State",
    "name": "Wardell Jackson",
    "rosters": [
      "cc792a0f-fa98-4caf-ad55-38fe38f01714"
    ]
  },
  "77114": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 13, 1967",
    "EXP": "2",
    "SCHOOL": "Ohio",
    "name": "Dave Jamerson",
    "rosters": [
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "5b2ffd57-2eb1-49c2-bee9-bc575e99301f"
    ]
  },
  "77115": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 05, 1952",
    "EXP": "4",
    "SCHOOL": "Grambling",
    "name": "Aaron James",
    "rosters": [
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827",
      "5517a85a-4623-414c-89a6-b90bb5a42eca",
      "a8b9d60d-1449-49af-89fc-945fadd03a04"
    ]
  },
  "77116": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 15, 1925",
    "EXP": "1",
    "SCHOOL": "Marshall",
    "name": "Gene James",
    "rosters": [
      "df1fce20-7cea-47cc-8e99-cf6d772b3162",
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7"
    ]
  },
  "77123": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 22, 1920",
    "EXP": "3",
    "SCHOOL": "Minnesota",
    "name": "Tony Jaros",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503",
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9",
      "0c2ad895-0fc8-49c0-9976-48e929a0c370"
    ]
  },
  "77126": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 10, 1954",
    "EXP": "R",
    "SCHOOL": "Wisconsin-Parkside",
    "name": "Abdul Jeelani",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "4ac401d3-899b-427d-89d6-584fe69ebf75"
    ]
  },
  "77129": {
    "NUM": "51",
    "POSITION": "G",
    "HEIGHT": "7-0",
    "WEIGHT": "237",
    "BIRTH_DATE": "JUN 24, 1967",
    "EXP": "1",
    "SCHOOL": "Iowa",
    "name": "Les Jepsen",
    "rosters": [
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504"
    ]
  },
  "77130": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 03, 1964",
    "EXP": "6",
    "SCHOOL": "Alabama",
    "name": "Buck Johnson",
    "rosters": [
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "79d8e657-ce27-4052-9492-c9833808173f",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05"
    ]
  },
  "77131": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 21, 1931",
    "EXP": "3",
    "SCHOOL": "Portland",
    "name": "Andy Johnson",
    "rosters": [
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae",
      "e5799d65-732c-4105-b48e-fe6c0e46eee6"
    ]
  },
  "77132": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "236",
    "BIRTH_DATE": "MAY 17, 1920",
    "EXP": "4",
    "SCHOOL": "Bemidji State",
    "name": "Arnie Johnson",
    "rosters": [
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0",
      "6146894f-3e97-4aab-a027-77228d1955a6",
      "817867c3-ef94-48b9-81aa-768f4f8f758e",
      "a0311ade-2019-4595-ab92-26a1696f3ce7"
    ]
  },
  "77133": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 31, 1949",
    "EXP": "6",
    "SCHOOL": "California",
    "name": "Charlie Johnson",
    "rosters": [
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864",
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444",
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0"
    ]
  },
  "77134": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 03, 1957",
    "EXP": "8",
    "SCHOOL": "Oregon State",
    "name": "Steve Johnson",
    "rosters": [
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e"
    ]
  },
  "77135": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 18, 1956",
    "EXP": "2",
    "SCHOOL": "Missouri",
    "name": "Clay Johnson",
    "rosters": [
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce"
    ]
  },
  "77136": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 12, 1956",
    "EXP": "9",
    "SCHOOL": "Florida A&M",
    "name": "Clemon Johnson",
    "rosters": [
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6",
      "d58ee353-d825-4a50-96dc-975b331f83f2"
    ]
  },
  "77138": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 16, 1970",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Dave Johnson",
    "rosters": [
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "ce6303d7-7e69-45e6-9f99-e7a14a040764"
    ]
  },
  "77139": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "170",
    "BIRTH_DATE": "DEC 06, 1921",
    "EXP": "3",
    "SCHOOL": "Huntington",
    "name": "David Johnson",
    "rosters": [
      "648376a8-6ee8-4cad-97d2-369540020f50",
      "02a3a04b-002a-4afa-8188-8f11ccfafda1",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e"
    ]
  },
  "77141": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 18, 1954",
    "EXP": "3",
    "SCHOOL": "Pepperdine",
    "name": "Dennis Johnson",
    "rosters": [
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "8718c236-d862-42de-a84d-ef29c83369b9",
      "6306dadf-1ee7-4678-b035-ef14578746a4",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "278e33a4-dd30-4a35-b7be-2e7b71623707"
    ]
  },
  "77142": {
    "NUM": "32",
    "POSITION": "F-G",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 14, 1959",
    "EXP": "12",
    "SCHOOL": "Michigan State",
    "name": "Magic Johnson",
    "rosters": [
      "c3077e01-3d45-4725-9d9e-90a13761dbe8",
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "aa36a9db-cdb7-4e10-ae1d-cfefd56499ff"
    ]
  },
  "77144": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 24, 1955",
    "EXP": "9",
    "SCHOOL": "Auburn",
    "name": "Eddie Johnson",
    "rosters": [
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474",
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6"
    ]
  },
  "77145": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 07, 1966",
    "EXP": "R",
    "SCHOOL": "Nebraska",
    "name": "Eric Johnson",
    "rosters": [
      "955486b2-508c-4776-884b-e521d5730bf8"
    ]
  },
  "77146": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 23, 1958",
    "EXP": "6",
    "SCHOOL": "Wake Forest",
    "name": "Frank Johnson",
    "rosters": [
      "79d8e657-ce27-4052-9492-c9833808173f",
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "30517724-5902-4ca6-b448-35847695fb4a",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "355ade3f-1412-4279-a7f6-512d2936bf86",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3"
    ]
  },
  "77147": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 19, 1947",
    "EXP": "R",
    "SCHOOL": "Stephen F. Austin",
    "name": "George Johnson",
    "rosters": [
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "ab91fee8-d6df-4549-ad42-04456017afc3"
    ]
  },
  "77148": {
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 08, 1956",
    "EXP": "7",
    "SCHOOL": "St. John's (NY)",
    "name": "George Johnson",
    "rosters": [
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "c24811b3-b1da-43ab-9ab2-9a68328f351c",
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "77149": {
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 18, 1948",
    "EXP": "12",
    "SCHOOL": "Dillard",
    "name": "George Johnson",
    "rosters": [
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864",
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "a6b98be6-4acd-49be-92b6-decd2522e4df",
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb",
      "0d39d553-7b93-4206-b525-d87f764e4d5d"
    ]
  },
  "77150": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 13, 1935",
    "EXP": "8",
    "SCHOOL": "Idaho",
    "name": "Gus Johnson",
    "rosters": [
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "b204df04-9a5e-48f7-814f-b547b0690ab8",
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "0403c301-445c-4c5d-8144-e5805bdc801c",
      "ab91fee8-d6df-4549-ad42-04456017afc3",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3"
    ]
  },
  "77152": {
    "NUM": "27",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 18, 1947",
    "EXP": "11",
    "SCHOOL": "Iowa",
    "name": "John Johnson",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1",
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "278e33a4-dd30-4a35-b7be-2e7b71623707",
      "6cb87ca5-b208-46a1-b393-022423c35c0d",
      "9cf046e5-f475-41ef-a3d3-f517368a157b"
    ]
  },
  "77153": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 24, 1965",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Kannard Johnson",
    "rosters": [
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64"
    ]
  },
  "77154": {
    "NUM": "00",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 07, 1962",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Ken Johnson",
    "rosters": [
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2"
    ]
  },
  "77156": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 28, 1954",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Larry Johnson",
    "rosters": [
      "0c82b7b8-0084-4eef-82c7-89296e03c73e"
    ]
  },
  "77158": {
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 16, 1957",
    "EXP": "R",
    "SCHOOL": "Texas A&M-Commerce",
    "name": "Lee Johnson",
    "rosters": [
      "c163d65f-d6fd-4a44-968a-2897543255d4"
    ]
  },
  "77159": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 07, 1957",
    "EXP": "R",
    "SCHOOL": "Wichita State",
    "name": "Lynbert Johnson",
    "rosters": [
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc"
    ]
  },
  "77160": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "FEB 08, 1956",
    "EXP": "6",
    "SCHOOL": "California-Los Angeles",
    "name": "Marques Johnson",
    "rosters": [
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d",
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "ffa6228e-39da-4145-8319-41a17387cede"
    ]
  },
  "77161": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 17, 1943",
    "EXP": "3",
    "SCHOOL": "Creighton",
    "name": "Neil Johnson",
    "rosters": [
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "20955b01-8d24-45f5-ba05-3a4253c801f7",
      "c5964149-f86c-413e-9d7c-c8d07f4113ef"
    ]
  },
  "77162": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 11, 1949",
    "EXP": "2",
    "SCHOOL": "Temple",
    "name": "Ollie Johnson",
    "rosters": [
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e",
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "77163": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 25, 1957",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Reggie Johnson",
    "rosters": [
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "a6b98be6-4acd-49be-92b6-decd2522e4df"
    ]
  },
  "77164": {
    "NUM": "26",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 18, 1946",
    "EXP": "2",
    "SCHOOL": "Grambling",
    "name": "Rich Johnson",
    "rosters": [
      "b576b451-62e4-4a16-98e2-535cecc8e551",
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "0cd82dfb-5312-4478-8847-027549aeea25"
    ]
  },
  "77165": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 20, 1938",
    "EXP": "R",
    "SCHOOL": "Minnesota",
    "name": "Ron Johnson",
    "rosters": [
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf"
    ]
  },
  "77166": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 04, 1962",
    "EXP": "R",
    "SCHOOL": "San Diego State",
    "name": "Steffond Johnson",
    "rosters": [
      "a8878857-2e27-4452-a526-4d80a3f6b73a"
    ]
  },
  "77167": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 01, 1956",
    "EXP": "11",
    "SCHOOL": "Baylor",
    "name": "Vinnie Johnson",
    "rosters": [
      "61f90119-05dd-4779-bfd7-fcf297701a65",
      "278e33a4-dd30-4a35-b7be-2e7b71623707",
      "6cb87ca5-b208-46a1-b393-022423c35c0d",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c"
    ]
  },
  "77168": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 31, 1952",
    "EXP": "5",
    "SCHOOL": "Aurora",
    "name": "Mickey Johnson",
    "rosters": [
      "66953847-11ae-490d-ba1c-76473d320167",
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "d3da55d4-ca59-42db-b517-15302adf51f1"
    ]
  },
  "77169": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 04, 1929",
    "EXP": "7",
    "SCHOOL": "Ohio State",
    "name": "Neil Johnston",
    "rosters": [
      "1c190e61-1a8b-4cd1-aba2-fae468830890",
      "9398ab8b-2109-490e-8b37-d2bbaadb361f",
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f",
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86",
      "05b98b66-c377-4196-b63f-7c9749f98f47",
      "59113a13-7bcb-4a63-908c-a566386976eb",
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447",
      "dfaf5774-af9e-4334-82a0-3587b2230530"
    ]
  },
  "77170": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 18, 1966",
    "EXP": "R",
    "SCHOOL": "Tampa",
    "name": "Nate Johnston",
    "rosters": [
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6"
    ]
  },
  "77171": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "SEP 20, 1960",
    "EXP": "R",
    "SCHOOL": "Wake Forest",
    "name": "Jim Johnstone",
    "rosters": [
      "f7a58b16-8257-42be-b944-16319d0abd63"
    ]
  },
  "77172": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "JUL 20, 1938",
    "EXP": "2",
    "SCHOOL": "Ohio",
    "name": "Howie Jolliff",
    "rosters": [
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1"
    ]
  },
  "77173": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 13, 1962",
    "EXP": "R",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Anthony Jones",
    "rosters": [
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd"
    ]
  },
  "77174": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 03, 1971",
    "EXP": "R",
    "SCHOOL": "Kansas State",
    "name": "Askia Jones",
    "rosters": [
      "e0e9d8fe-af7c-4f7c-aea3-88862f2eb090"
    ]
  },
  "77175": {
    "NUM": "27",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "217",
    "BIRTH_DATE": "AUG 04, 1950",
    "EXP": "13",
    "SCHOOL": "Albany State (GA)",
    "name": "Caldwell Jones",
    "rosters": [
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8"
    ]
  },
  "77178": {
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 12, 1962",
    "EXP": "3",
    "SCHOOL": "Louisville",
    "name": "Charles Jones",
    "rosters": [
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58"
    ]
  },
  "77180": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 18, 1966",
    "EXP": "R",
    "SCHOOL": "Iowa",
    "name": "Bill Jones",
    "rosters": [
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef"
    ]
  },
  "77182": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 27, 1952",
    "EXP": "9",
    "SCHOOL": "Houston",
    "name": "Dwight Jones",
    "rosters": [
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba"
    ]
  },
  "77183": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 13, 1961",
    "EXP": "1",
    "SCHOOL": "District of Columbia",
    "name": "Earl Jones",
    "rosters": [
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "fac543c5-7a72-481e-87a4-e3d382c18eec"
    ]
  },
  "77184": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 17, 1956",
    "EXP": "1",
    "SCHOOL": "Nevada-Reno",
    "name": "Edgar Jones",
    "rosters": [
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "5c09ec26-5505-4508-98c5-db244798d9d1",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938"
    ]
  },
  "77185": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 09, 1949",
    "EXP": "R",
    "SCHOOL": "Assumption",
    "name": "Jake Jones",
    "rosters": [
      "146cb658-0a9a-4f26-8556-39497dc3cfa4"
    ]
  },
  "77186": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "188",
    "BIRTH_DATE": "JAN 01, 1945",
    "EXP": "2",
    "SCHOOL": "Grambling",
    "name": "Jimmy Jones",
    "rosters": [
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "fd666397-8775-4cb6-9c9b-67881c31f005",
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66"
    ]
  },
  "77187": {
    "NUM": "27",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 12, 1943",
    "EXP": "R",
    "SCHOOL": "Cal State-Los Angeles",
    "name": "Johnny Jones",
    "rosters": [
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a"
    ]
  },
  "77188": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 25, 1932",
    "EXP": "8",
    "SCHOOL": "San Francisco",
    "name": "K.C. Jones",
    "rosters": [
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7",
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "de3c97df-aa08-40c1-bb58-7d65bf023583"
    ]
  },
  "77189": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 09, 1953",
    "EXP": "5",
    "SCHOOL": "Albany State (GA)",
    "name": "Major Jones",
    "rosters": [
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17"
    ]
  },
  "77191": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 20, 1960",
    "EXP": "R",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Ozell Jones",
    "rosters": [
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "5c09ec26-5505-4508-98c5-db244798d9d1"
    ]
  },
  "77192": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 27, 1946",
    "EXP": "R",
    "SCHOOL": "Memphis",
    "name": "Rich Jones",
    "rosters": [
      "97c2ee90-873f-4c01-92e4-890bb08dda89"
    ]
  },
  "77193": {
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 18, 1951",
    "EXP": "9",
    "SCHOOL": "North Carolina",
    "name": "Bobby Jones",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967",
      "66743650-e493-4c91-a3ab-5a488a063464",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001"
    ]
  },
  "77194": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 02, 1954",
    "EXP": "R",
    "SCHOOL": "Saint Louis",
    "name": "Robin Jones",
    "rosters": [
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba"
    ]
  },
  "77195": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 28, 1945",
    "EXP": "R",
    "SCHOOL": "Oregon",
    "name": "Nick Jones",
    "rosters": [
      "852d8746-a465-41f3-b644-085c99bbad27",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "e511f2f0-158b-44a1-90e9-a101165211e2"
    ]
  },
  "77196": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "198",
    "BIRTH_DATE": "JUN 24, 1933",
    "EXP": "11",
    "SCHOOL": "North Carolina Central",
    "name": "Sam Jones",
    "rosters": [
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7",
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "b576b451-62e4-4a16-98e2-535cecc8e551"
    ]
  },
  "77197": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 17, 1942",
    "EXP": "R",
    "SCHOOL": "Oregon",
    "name": "Steve Jones",
    "rosters": [
      "cbf01feb-6986-43c2-8350-23e707cf8272"
    ]
  },
  "77198": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 14, 1942",
    "EXP": "9",
    "SCHOOL": "Villanova",
    "name": "Wali Jones",
    "rosters": [
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6",
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "56bc558b-98d9-4a75-9033-fbec8e9fe666",
      "e08b4914-59a6-4d7e-836c-db576c3c3723",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "b204df04-9a5e-48f7-814f-b547b0690ab8",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b"
    ]
  },
  "77200": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 22, 1942",
    "EXP": "1",
    "SCHOOL": "Toledo",
    "name": "Larry Jones",
    "rosters": [
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011"
    ]
  },
  "77201": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 27, 1947",
    "EXP": "R",
    "SCHOOL": "Albany State (GA)",
    "name": "Wil Jones",
    "rosters": [
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d"
    ]
  },
  "77202": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 29, 1936",
    "EXP": "4",
    "SCHOOL": "Northwestern",
    "name": "Willie Jones",
    "rosters": [
      "a4934f64-d807-440b-9639-6e4052c2501e",
      "e6ca1168-2bff-4198-9727-665654e83794",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a",
      "3902314e-3111-45fa-9433-86d9fdae07db"
    ]
  },
  "77203": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 01, 1959",
    "EXP": "1",
    "SCHOOL": "Vanderbilt",
    "name": "Willie Jones",
    "rosters": [
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b"
    ]
  },
  "77205": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "JAN 29, 1955",
    "EXP": "3",
    "SCHOOL": "Rutgers",
    "name": "Eddie Jordan",
    "rosters": [
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "30f87621-72fd-4d91-987d-50cce0bf1714"
    ]
  },
  "77208": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 23, 1968",
    "EXP": "R",
    "SCHOOL": "Oklahoma State",
    "name": "Thomas Jordan",
    "rosters": [
      "f646b106-33f9-49c7-9873-99a35bf12813"
    ]
  },
  "77209": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "198",
    "BIRTH_DATE": "FEB 19, 1956",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Walter Jordan",
    "rosters": [
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697"
    ]
  },
  "77210": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 12, 1933",
    "EXP": "2",
    "SCHOOL": "Whitworth",
    "name": "Phil Jordon",
    "rosters": [
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "816164e0-e2ee-413e-8662-b51fbf40b841",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3",
      "03f980b2-d8d0-47cc-a777-71a16e029764"
    ]
  },
  "77211": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 28, 1921",
    "EXP": "1",
    "SCHOOL": "DePaul",
    "name": "Johnny Jorgensen",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503"
    ]
  },
  "77212": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "228",
    "BIRTH_DATE": "MAY 18, 1925",
    "EXP": "4",
    "SCHOOL": "Iowa",
    "name": "Noble Jorgensen",
    "rosters": [
      "98673c29-08ee-48ce-9891-81b0ab92d951",
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e",
      "b7540846-94ec-42ec-b008-ff241f8152ea"
    ]
  },
  "77214": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "OCT 31, 1957",
    "EXP": "R",
    "SCHOOL": "Georgia Tech",
    "name": "Yvon Joseph",
    "rosters": [
      "f2ded0f3-c782-462b-8040-e231fa98bbf5"
    ]
  },
  "77215": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 23, 1956",
    "EXP": "3",
    "SCHOOL": "Utah",
    "name": "Jeff Judkins",
    "rosters": [
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938"
    ]
  },
  "77218": {
    "NUM": "6-17",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 22, 1928",
    "EXP": "3",
    "SCHOOL": "Holy Cross",
    "name": "George Kaftan",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6",
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d"
    ]
  },
  "77219": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "245",
    "BIRTH_DATE": "OCT 13, 1932",
    "EXP": "2",
    "SCHOOL": "Minnesota",
    "name": "Ed Kalafat",
    "rosters": [
      "22dd26a7-1c99-45b7-a759-dce48ccb6829",
      "156bc9fb-9be0-4d47-ae6c-e255ee721988",
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8"
    ]
  },
  "77221": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAY 18, 1919",
    "EXP": "R",
    "SCHOOL": "New York University",
    "name": "Ralph Kaplowitz",
    "rosters": [
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a",
      "5863b9f0-adf0-4042-9912-f445b7c8ee24"
    ]
  },
  "77223": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 12, 1951",
    "EXP": "1",
    "SCHOOL": "North Carolina",
    "name": "George Karl",
    "rosters": [
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34"
    ]
  },
  "77227": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 13, 1946",
    "EXP": "R",
    "SCHOOL": "Guilford",
    "name": "Bob Kauffman",
    "rosters": [
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "5df3e966-18d8-42d1-a237-835fc00837b6"
    ]
  },
  "77229": {
    "NUM": "53",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "218",
    "BIRTH_DATE": "FEB 02, 1959",
    "EXP": "1",
    "SCHOOL": "Lamar",
    "name": "Clarence Kea",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "416a441e-349c-43bf-8a25-d9b8fa253a5c"
    ]
  },
  "77230": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "178",
    "BIRTH_DATE": "JUN 18, 1929",
    "EXP": "R",
    "SCHOOL": "Princeton",
    "name": "Michael Kearns",
    "rosters": [
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86"
    ]
  },
  "77231": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 06, 1936",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Tommy Kearns",
    "rosters": [
      "1dbc8441-2b65-4d63-a97c-fba1b28af868"
    ]
  },
  "77233": {
    "NUM": "43",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 18, 1963",
    "EXP": "R",
    "SCHOOL": "Santa Clara",
    "name": "Harold Keeling",
    "rosters": [
      "562818d9-6fdb-4cb2-b851-4748b1c638f2"
    ]
  },
  "77234": {
    "POSITION": null,
    "HEIGHT": null,
    "WEIGHT": null,
    "BIRTH_DATE": "APR 05, 1905",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Ken Keller",
    "rosters": [
      "e09484d0-0da2-4916-ac91-c96f2c6a1674"
    ]
  },
  "77235": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 23, 1953",
    "EXP": "9",
    "SCHOOL": "Stanford",
    "name": "Rich Kelley",
    "rosters": [
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827",
      "5517a85a-4623-414c-89a6-b90bb5a42eca",
      "a8b9d60d-1449-49af-89fc-945fadd03a04",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a"
    ]
  },
  "77236": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 02, 1961",
    "EXP": "4",
    "SCHOOL": "Ohio State",
    "name": "Clark Kellogg",
    "rosters": [
      "c24811b3-b1da-43ab-9ab2-9a68328f351c",
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a"
    ]
  },
  "77237": {
    "NUM": "14-24",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "172",
    "BIRTH_DATE": "JUN 14, 1918",
    "EXP": "R",
    "SCHOOL": "Marshall",
    "name": "Gerard Kelly",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "77238": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 05, 1924",
    "EXP": "R",
    "SCHOOL": "New York University",
    "name": "Tom Kelly",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "77239": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 17, 1957",
    "EXP": "2",
    "SCHOOL": "Michigan State",
    "name": "Greg Kelser",
    "rosters": [
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "a59119a0-b26d-4a60-b162-d2df226404ce",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b",
      "c163d65f-d6fd-4a44-968a-2897543255d4",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938"
    ]
  },
  "77240": {
    "NUM": "5-30",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 11, 1949",
    "EXP": "R",
    "SCHOOL": "Central Michigan",
    "name": "Ben Kelso",
    "rosters": [
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185"
    ]
  },
  "77242": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "198",
    "BIRTH_DATE": "SEP 11, 1950",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Frank Kendrick",
    "rosters": [
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1"
    ]
  },
  "77243": {
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 23, 1949",
    "EXP": "R",
    "SCHOOL": "Texas Christian",
    "name": "Eugene Kennedy",
    "rosters": [
      "1f08be28-cf75-4838-8319-73b4c61b0c71"
    ]
  },
  "77244": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 12, 1947",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Joe Kennedy",
    "rosters": [
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "3829b824-1221-432d-8dc1-388a4d2f1cce"
    ]
  },
  "77245": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 17, 1938",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "William Kennedy",
    "rosters": [
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae"
    ]
  },
  "77246": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 13, 1952",
    "EXP": "3",
    "SCHOOL": "Memphis",
    "name": "Larry Kenon",
    "rosters": [
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148",
      "38ac7402-b81e-4254-b636-95330a997974"
    ]
  },
  "77247": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "187",
    "BIRTH_DATE": "DEC 01, 1930",
    "EXP": "5",
    "SCHOOL": "St. Bonaventure",
    "name": "Bill Kenville",
    "rosters": [
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8",
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "edb95a5d-c995-43f0-9378-d27051d259ab",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3",
      "29b33649-2e3d-444b-81b4-caec18163bdd"
    ]
  },
  "77248": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 17, 1932",
    "EXP": "11",
    "SCHOOL": "Illinois",
    "name": "Johnny Kerr",
    "rosters": [
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "a45c9665-5951-47aa-ad90-2e2f872cee40",
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2",
      "b4492284-7d41-45e4-b3d7-fd93667614a1",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a",
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22",
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc"
    ]
  },
  "77249": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 30, 1925",
    "EXP": "2",
    "SCHOOL": "Loyola-Chicago",
    "name": "Jack Kerris",
    "rosters": [
      "6d008515-8165-4990-a179-942eeec92822",
      "648376a8-6ee8-4cad-97d2-369540020f50",
      "02a3a04b-002a-4afa-8188-8f11ccfafda1"
    ]
  },
  "77251": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 13, 1967",
    "EXP": "3",
    "SCHOOL": "Georgia",
    "name": "Alec Kessler",
    "rosters": [
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "10d493d6-08b9-410c-b14a-a2353115d39c",
      "ed6e82de-af93-48c5-ba4e-66022e13d1b4"
    ]
  },
  "77254": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 09, 1970",
    "EXP": "R",
    "SCHOOL": "Middle Tennessee State",
    "name": "Warren Kidd",
    "rosters": [
      "f8980f9a-2074-4965-8469-9086e1c70f28"
    ]
  },
  "77255": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 08, 1951",
    "EXP": "R",
    "SCHOOL": "Oklahoma Baptist",
    "name": "Irv Kiffin",
    "rosters": [
      "38ac7402-b81e-4254-b636-95330a997974"
    ]
  },
  "77256": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "JAN 05, 1929",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Jack Kiley",
    "rosters": [
      "02a3a04b-002a-4afa-8188-8f11ccfafda1",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e"
    ]
  },
  "77257": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 11, 1948",
    "EXP": "R",
    "SCHOOL": "Stetson",
    "name": "Ernie Killum",
    "rosters": [
      "122a5267-d509-4769-a4ca-4f7932f65492"
    ]
  },
  "77258": {
    "NUM": "51",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 16, 1956",
    "EXP": "R",
    "SCHOOL": "Louisiana-Monroe",
    "name": "Carl Kilpatrick",
    "rosters": [
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "77259": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 07, 1942",
    "EXP": "8",
    "SCHOOL": "Connecticut",
    "name": "Toby Kimball",
    "rosters": [
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "77260": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 09, 1966",
    "EXP": "2",
    "SCHOOL": "Loyola-Marymount",
    "name": "Bo Kimble",
    "rosters": [
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "aa38ce66-0adc-43cf-85f8-4f26278982cb"
    ]
  },
  "77261": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "5-11",
    "WEIGHT": "153",
    "BIRTH_DATE": "APR 24, 1966",
    "EXP": "R",
    "SCHOOL": "Xavier",
    "name": "Stan Kimbrough",
    "rosters": [
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "ffc81b35-207d-48f8-8091-52733fd65961"
    ]
  },
  "77262": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 22, 1958",
    "EXP": "R",
    "SCHOOL": "North Carolina-Charlotte",
    "name": "Chad Kinch",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9"
    ]
  },
  "77263": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 17, 1959",
    "EXP": "8",
    "SCHOOL": "Maryland",
    "name": "Albert King",
    "rosters": [
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1"
    ]
  },
  "77264": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 04, 1956",
    "EXP": "12",
    "SCHOOL": "Tennessee",
    "name": "Bernard King",
    "rosters": [
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "6a10cc0f-c034-4cee-992e-4023a7280654",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58",
      "44e38364-f6e8-4d07-bb01-e7f82356367c",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6"
    ]
  },
  "77268": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 16, 1928",
    "EXP": "5",
    "SCHOOL": "Charleston (WV)",
    "name": "George King",
    "rosters": [
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e",
      "b7540846-94ec-42ec-b008-ff241f8152ea",
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8",
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "21936ffd-2bd4-4505-a647-b4cd447d157b"
    ]
  },
  "77270": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "FEB 07, 1941",
    "EXP": "6",
    "SCHOOL": "Tulsa",
    "name": "Jim King",
    "rosters": [
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2",
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa"
    ]
  },
  "77272": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 12, 1935",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Maury King",
    "rosters": [
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "77273": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 14, 1957",
    "EXP": "5",
    "SCHOOL": "Alabama",
    "name": "Reggie King",
    "rosters": [
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "19686855-a120-434c-8ad4-5f15e61b9a38",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4"
    ]
  },
  "77277": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 16, 1920",
    "EXP": "1",
    "SCHOOL": "Rice",
    "name": "Bob Kinney",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6",
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1"
    ]
  },
  "77278": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "173",
    "BIRTH_DATE": "SEP 03, 1924",
    "EXP": "2",
    "SCHOOL": "Illinois",
    "name": "Walt Kirk",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "77279": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 21, 1938",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Doug Kistler",
    "rosters": [
      "816164e0-e2ee-413e-8662-b51fbf40b841"
    ]
  },
  "77280": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 30, 1964",
    "EXP": "R",
    "SCHOOL": "South Florida",
    "name": "Curtis Kitchen",
    "rosters": [
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6"
    ]
  },
  "77283": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAY 21, 1923",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Leo Klier",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922",
      "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e"
    ]
  },
  "77285": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 06, 1926",
    "EXP": "1",
    "SCHOOL": "Indiana State",
    "name": "Duane Klueh",
    "rosters": [
      "648376a8-6ee8-4cad-97d2-369540020f50"
    ]
  },
  "77287": {
    "NUM": "3",
    "POSITION": "G-F",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 14, 1905",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Bob Knight",
    "rosters": [
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa"
    ]
  },
  "77288": {
    "NUM": "38",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 04, 1947",
    "EXP": "1",
    "SCHOOL": "Cal State-Los Angeles",
    "name": "Ron Knight",
    "rosters": [
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4"
    ]
  },
  "77289": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 03, 1955",
    "EXP": "3",
    "SCHOOL": "Notre Dame",
    "name": "Toby Knight",
    "rosters": [
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "b001782c-95ad-444b-814b-98ecf23fa9ce",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff"
    ]
  },
  "77290": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 09, 1952",
    "EXP": "8",
    "SCHOOL": "Pittsburgh",
    "name": "Billy Knight",
    "rosters": [
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "b53d3c18-a4f1-40bf-a266-2555fa606018",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "c24811b3-b1da-43ab-9ab2-9a68328f351c",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e"
    ]
  },
  "77291": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 15, 1921",
    "EXP": "2",
    "SCHOOL": "Detroit Mercy",
    "name": "Lee Knorek",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24",
      "bb996e83-b963-4fd6-b44b-484116ff5a4e",
      "df1fce20-7cea-47cc-8e99-cf6d772b3162"
    ]
  },
  "77292": {
    "NUM": "10",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 09, 1931",
    "EXP": "R",
    "SCHOOL": "Kansas State",
    "name": "Dick Knostman",
    "rosters": [
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8"
    ]
  },
  "77293": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 27, 1946",
    "EXP": "R",
    "SCHOOL": "Davidson",
    "name": "Rod Knowles",
    "rosters": [
      "20955b01-8d24-45f5-ba05-3a4253c801f7"
    ]
  },
  "77294": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 24, 1964",
    "EXP": "1",
    "SCHOOL": "Nebraska-Kearney",
    "name": "Bart Kofoed",
    "rosters": [
      "5f014df6-a40d-42f4-9def-c68b338e0ee2",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "b3bd95d0-2928-4e01-8f1d-47b167775984",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "eecb8d44-e66b-417f-bd80-36f97052a98a"
    ]
  },
  "77295": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "202",
    "BIRTH_DATE": "JUL 15, 1939",
    "EXP": "2",
    "SCHOOL": "Marquette",
    "name": "Don Kojis",
    "rosters": [
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "66c852c8-74e2-482e-8745-846263fa3f58",
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "3902314e-3111-45fa-9433-86d9fdae07db",
      "61110628-8f7d-478f-b171-52455d3bf9d0"
    ]
  },
  "77297": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 09, 1941",
    "EXP": "7",
    "SCHOOL": "Bowling Green",
    "name": "Howard Komives",
    "rosters": [
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "49db955c-2a3f-4693-b68f-834ab5003c4d",
      "d80f75e6-baf7-4843-8122-aa743aea3477",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226"
    ]
  },
  "77299": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 09, 1942",
    "EXP": "R",
    "SCHOOL": "Oklahoma City",
    "name": "Bud Koper",
    "rosters": [
      "6530f527-58ae-467d-8504-53e63dd6c84e"
    ]
  },
  "77300": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 12, 1960",
    "EXP": "1",
    "SCHOOL": "Detroit Mercy",
    "name": "Joe Kopicki",
    "rosters": [
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407"
    ]
  },
  "77301": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 27, 1967",
    "EXP": "1",
    "SCHOOL": "Vanderbilt",
    "name": "Frank Kornet",
    "rosters": [
      "ec353978-5363-474f-aac3-88a8126172d3",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17"
    ]
  },
  "77302": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "NOV 29, 1951",
    "EXP": "1",
    "SCHOOL": "Tennessee",
    "name": "Len Kosmalski",
    "rosters": [
      "66c852c8-74e2-482e-8745-846263fa3f58",
      "dd460500-38ea-4afb-99af-548234a3036d"
    ]
  },
  "77304": {
    "NUM": "9",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 22, 1922",
    "EXP": "R",
    "SCHOOL": "Culver-Stockton",
    "name": "Harold Kottman",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "77305": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 01, 1951",
    "EXP": "2",
    "SCHOOL": "Toledo",
    "name": "Tom Kozelko",
    "rosters": [
      "024d58a0-dd09-4396-a891-82ba087f7279",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "fd666397-8775-4cb6-9c9b-67881c31f005"
    ]
  },
  "77306": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 02, 1956",
    "EXP": "R",
    "SCHOOL": "Augustana (SD)",
    "name": "Arvid Kramer",
    "rosters": [
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f"
    ]
  },
  "77307": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 10, 1942",
    "EXP": "R",
    "SCHOOL": "New York University",
    "name": "Barry Kramer",
    "rosters": [
      "7c3c8b10-137d-4f0f-8249-452818bc279f"
    ]
  },
  "77308": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "203",
    "BIRTH_DATE": "NOV 30, 1955",
    "EXP": "4",
    "SCHOOL": "San Diego State",
    "name": "Joel Kramer",
    "rosters": [
      "e8500690-7102-4acf-ae8b-f1907cf60bcf",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "d4bedead-ad99-4717-ae90-da27e0c99bec"
    ]
  },
  "77311": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 08, 1935",
    "EXP": "6",
    "SCHOOL": "Southern Methodist",
    "name": "Jim Krebs",
    "rosters": [
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f"
    ]
  },
  "77312": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 04, 1957",
    "EXP": "R",
    "SCHOOL": "Drake",
    "name": "Wayne Kreklow",
    "rosters": [
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd"
    ]
  },
  "77313": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 28, 1943",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Tom Kron",
    "rosters": [
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "5df3e966-18d8-42d1-a237-835fc00837b6"
    ]
  },
  "77314": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 12, 1953",
    "EXP": "R",
    "SCHOOL": "Nebraska-Kearney",
    "name": "Tom Kropp",
    "rosters": [
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "fd666397-8775-4cb6-9c9b-67881c31f005"
    ]
  },
  "77316": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 06, 1947",
    "EXP": "5",
    "SCHOOL": "Bradley",
    "name": "Steve Kuberski",
    "rosters": [
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9",
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e"
    ]
  },
  "77318": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 03, 1961",
    "EXP": "R",
    "SCHOOL": "Connecticut",
    "name": "Bruce Kuczenski",
    "rosters": [
      "84846342-36c1-4dc5-96ab-243c663b2065"
    ]
  },
  "77319": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "193",
    "BIRTH_DATE": "JUN 25, 1925",
    "EXP": "3",
    "SCHOOL": "Saint Mary's (CA)",
    "name": "Frank Kudelka",
    "rosters": [
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7",
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "77320": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 06, 1955",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "John Kuester",
    "rosters": [
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef"
    ]
  },
  "77322": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 17, 1922",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Ray Kuka",
    "rosters": [
      "bb996e83-b963-4fd6-b44b-484116ff5a4e",
      "df1fce20-7cea-47cc-8e99-cf6d772b3162"
    ]
  },
  "77323": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 11, 1951",
    "EXP": "8",
    "SCHOOL": "Iowa",
    "name": "Kevin Kunnert",
    "rosters": [
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "4ac401d3-899b-427d-89d6-584fe69ebf75",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f"
    ]
  },
  "77324": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 24, 1954",
    "EXP": "4",
    "SCHOOL": "North Carolina",
    "name": "Mitch Kupchak",
    "rosters": [
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66",
      "dd527857-adb8-40c3-9804-8aa4212b8486",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409",
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "77325": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 16, 1953",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "Charles Kupec",
    "rosters": [
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77"
    ]
  },
  "77326": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 28, 1948",
    "EXP": "10",
    "SCHOOL": "New Mexico State",
    "name": "Sam Lacey",
    "rosters": [
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "66c852c8-74e2-482e-8745-846263fa3f58",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "19686855-a120-434c-8ad4-5f15e61b9a38"
    ]
  },
  "77327": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 07, 1938",
    "EXP": "2",
    "SCHOOL": "San Francisco",
    "name": "Fred LaCour",
    "rosters": [
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "60508db9-69f9-4133-ac4d-b9848b2add2d"
    ]
  },
  "77328": {
    "NUM": "23",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 10, 1955",
    "EXP": "2",
    "SCHOOL": "North Carolina",
    "name": "Tom LaGarde",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "66743650-e493-4c91-a3ab-5a488a063464",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "278e33a4-dd30-4a35-b7be-2e7b71623707"
    ]
  },
  "77330": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "APR 07, 1951",
    "EXP": "R",
    "SCHOOL": "Louisiana-Lafayette",
    "name": "Bo Lamar",
    "rosters": [
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77"
    ]
  },
  "77331": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 14, 1953",
    "EXP": "6",
    "SCHOOL": "Southern California",
    "name": "John Lambert",
    "rosters": [
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86",
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365"
    ]
  },
  "77332": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 09, 1959",
    "EXP": "2",
    "SCHOOL": "Virginia",
    "name": "Jeff Lamp",
    "rosters": [
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c"
    ]
  },
  "77333": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 02, 1960",
    "EXP": "R",
    "SCHOOL": "Arkansas-Little Rock",
    "name": "Jim Lampley",
    "rosters": [
      "3d24a988-08f7-4aad-ae1e-c528947c7844"
    ]
  },
  "77334": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 21, 1955",
    "EXP": "5",
    "SCHOOL": "Arizona State",
    "name": "Mark Landsberger",
    "rosters": [
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba"
    ]
  },
  "77335": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 04, 1966",
    "EXP": "3",
    "SCHOOL": "Pittsburgh",
    "name": "Jerome Lane",
    "rosters": [
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "da7fed67-9107-4124-becb-84e8169908af"
    ]
  },
  "77338": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 13, 1946",
    "EXP": "5",
    "SCHOOL": "Nebraska",
    "name": "Stu Lantz",
    "rosters": [
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185"
    ]
  },
  "77339": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "183",
    "BIRTH_DATE": "JUL 18, 1938",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "York Larese",
    "rosters": [
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5"
    ]
  },
  "77340": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 11, 1937",
    "EXP": "7",
    "SCHOOL": "Dartmouth",
    "name": "Rudy LaRusso",
    "rosters": [
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9"
    ]
  },
  "77341": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 07, 1953",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "John Laskowski",
    "rosters": [
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "c3238288-bce4-48df-bf77-11f4a2fd274e"
    ]
  },
  "77342": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 23, 1943",
    "EXP": "1",
    "SCHOOL": "Texas-El Paso",
    "name": "Dave Lattin",
    "rosters": [
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "20955b01-8d24-45f5-ba05-3a4253c801f7"
    ]
  },
  "77344": {
    "NUM": "5-12",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 11, 1954",
    "EXP": "R",
    "SCHOOL": "Hofstra",
    "name": "Rich Laurel",
    "rosters": [
      "71e13c20-8234-43de-9d74-74839ced5c07"
    ]
  },
  "77346": {
    "NUM": "6-16",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 11, 1926",
    "EXP": "1",
    "SCHOOL": "Yale",
    "name": "Tony Lavelli",
    "rosters": [
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04"
    ]
  },
  "77347": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 29, 1926",
    "EXP": "3",
    "SCHOOL": "Western Kentucky",
    "name": "Bob Lavoy",
    "rosters": [
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8"
    ]
  },
  "77348": {
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "228",
    "BIRTH_DATE": "DEC 08, 1952",
    "EXP": "R",
    "SCHOOL": "McNeese State",
    "name": "Ed Lawrence",
    "rosters": [
      "c163d65f-d6fd-4a44-968a-2897543255d4"
    ]
  },
  "77350": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 24, 1948",
    "EXP": "4",
    "SCHOOL": "Southern California",
    "name": "Dennis Layton",
    "rosters": [
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34"
    ]
  },
  "77351": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 27, 1945",
    "EXP": "1",
    "SCHOOL": "Niagara",
    "name": "Emanuel Leaks",
    "rosters": [
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada",
      "024d58a0-dd09-4396-a891-82ba087f7279"
    ]
  },
  "77352": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "163",
    "BIRTH_DATE": "JAN 31, 1935",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Hal Lear",
    "rosters": [
      "59113a13-7bcb-4a63-908c-a566386976eb"
    ]
  },
  "77353": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAY 27, 1957",
    "EXP": "9",
    "SCHOOL": "Oklahoma City",
    "name": "Allen Leavell",
    "rosters": [
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "79d8e657-ce27-4052-9492-c9833808173f"
    ]
  },
  "77354": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 05, 1966",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Jeff Lebo",
    "rosters": [
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8"
    ]
  },
  "77356": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 05, 1956",
    "EXP": "1",
    "SCHOOL": "Marquette",
    "name": "Butch Lee",
    "rosters": [
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "561af7fe-6914-4e3e-8250-12448f9d452c"
    ]
  },
  "77357": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 14, 1944",
    "EXP": "9",
    "SCHOOL": "Vanderbilt",
    "name": "Clyde Lee",
    "rosters": [
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "852d8746-a465-41f3-b644-085c99bbad27",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "41977d46-c447-4c82-8cb1-8952bd2b335e"
    ]
  },
  "77358": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 24, 1964",
    "EXP": "2",
    "SCHOOL": "Purdue",
    "name": "Doug Lee",
    "rosters": [
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1"
    ]
  },
  "77359": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 23, 1936",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "George Lee",
    "rosters": [
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "a4934f64-d807-440b-9639-6e4052c2501e",
      "e6ca1168-2bff-4198-9727-665654e83794"
    ]
  },
  "77360": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 12, 1951",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Greg Lee",
    "rosters": [
      "cbf01feb-6986-43c2-8350-23e707cf8272"
    ]
  },
  "77361": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 28, 1962",
    "EXP": "2",
    "SCHOOL": "Memphis",
    "name": "Keith Lee",
    "rosters": [
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef"
    ]
  },
  "77362": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 03, 1967",
    "EXP": "R",
    "SCHOOL": "Towson",
    "name": "Kurk Lee",
    "rosters": [
      "4c6f87e3-5009-4111-80db-5390790d46bb"
    ]
  },
  "77363": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 01, 1955",
    "EXP": "R",
    "SCHOOL": "San Diego State",
    "name": "Rock Lee",
    "rosters": [
      "de2e838d-3259-430d-8634-0759e68893b8"
    ]
  },
  "77364": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "193",
    "BIRTH_DATE": "NOV 02, 1952",
    "EXP": "5",
    "SCHOOL": "Oregon",
    "name": "Ron Lee",
    "rosters": [
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "a8b9d60d-1449-49af-89fc-945fadd03a04",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b",
      "c163d65f-d6fd-4a44-968a-2897543255d4",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938"
    ]
  },
  "77365": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 27, 1950",
    "EXP": "2",
    "SCHOOL": "Marshall",
    "name": "Russell Lee",
    "rosters": [
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "77366": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 17, 1927",
    "EXP": "1",
    "SCHOOL": "Dartmouth",
    "name": "Ed Leede",
    "rosters": [
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1",
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7"
    ]
  },
  "77369": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 01, 1942",
    "EXP": "1",
    "SCHOOL": "Campbell",
    "name": "George Lehmann",
    "rosters": [
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "385be451-82b9-4142-849d-362d686d325b"
    ]
  },
  "77370": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "250",
    "BIRTH_DATE": "FEB 16, 1967",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Gary Leonard",
    "rosters": [
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "8e1b3223-5273-4a8c-b563-50f9edfd074e"
    ]
  },
  "77371": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 17, 1932",
    "EXP": "6",
    "SCHOOL": "Indiana",
    "name": "Bob Leonard",
    "rosters": [
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8",
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "e5799d65-732c-4105-b48e-fe6c0e46eee6",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "77372": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "165",
    "BIRTH_DATE": "AUG 18, 1963",
    "EXP": "R",
    "SCHOOL": "Bradley",
    "name": "Jim Les",
    "rosters": [
      "a859ae74-acea-4f80-abaa-92f971001064",
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "eecb8d44-e66b-417f-bd80-36f97052a98a"
    ]
  },
  "77373": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 01, 1959",
    "EXP": "5",
    "SCHOOL": "Iowa",
    "name": "Ronnie Lester",
    "rosters": [
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd"
    ]
  },
  "77374": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "170",
    "BIRTH_DATE": "DEC 23, 1965",
    "EXP": "1",
    "SCHOOL": "Florida",
    "name": "Clifford Lett",
    "rosters": [
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "c5fcd661-3f22-42bc-99bf-414a346732e2"
    ]
  },
  "77375": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 11, 1920",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Andrew Levane",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd",
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9"
    ]
  },
  "77376": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "170",
    "BIRTH_DATE": "AUG 18, 1960",
    "EXP": "1",
    "SCHOOL": "Arizona State",
    "name": "Lafayette Lever",
    "rosters": [
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c"
    ]
  },
  "77377": {
    "NUM": "53",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 04, 1961",
    "EXP": "1",
    "SCHOOL": "Wichita State",
    "name": "Cliff Levingston",
    "rosters": [
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "6595b285-a439-44cb-85e6-c9a6a5292df4",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252"
    ]
  },
  "77379": {
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 06, 1921",
    "EXP": "1",
    "SCHOOL": "Eastern Kentucky",
    "name": "Fred Lewis",
    "rosters": [
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388"
    ]
  },
  "77380": {
    "NUM": "19",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 01, 1943",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Freddie Lewis",
    "rosters": [
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "38c6a8b5-9c04-429f-9873-476923c33722"
    ]
  },
  "77383": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 28, 1963",
    "EXP": "2",
    "SCHOOL": "La Salle",
    "name": "Ralph Lewis",
    "rosters": [
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "77384": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 21, 1965",
    "EXP": "5",
    "SCHOOL": "Northeastern",
    "name": "Reggie Lewis",
    "rosters": [
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "d830473c-10ae-439b-a8f4-bb837688cbf8",
      "5f014df6-a40d-42f4-9def-c68b338e0ee2"
    ]
  },
  "77385": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 20, 1945",
    "EXP": "2",
    "SCHOOL": "North Carolina",
    "name": "Bobby Lewis",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe"
    ]
  },
  "77386": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 27, 1968",
    "EXP": "3",
    "SCHOOL": "Illinois",
    "name": "Marcus Liberty",
    "rosters": [
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "77387": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 08, 1967",
    "EXP": "3",
    "SCHOOL": "Stanford",
    "name": "Todd Lichti",
    "rosters": [
      "aedf3815-98a8-4388-ba46-c3ae826b2947",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "37167a67-466f-4920-b036-1481c8665cf9",
      "5f767836-141f-43d2-8d66-70390ca5907c"
    ]
  },
  "77388": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 19, 1952",
    "EXP": "R",
    "SCHOOL": "Vanderbilt",
    "name": "Bill Ligon",
    "rosters": [
      "94f36f95-2ce4-4f55-8a83-19b910b11438"
    ]
  },
  "77389": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 10, 1958",
    "EXP": "R",
    "SCHOOL": "South Dakota State",
    "name": "Steve Lingenfelter",
    "rosters": [
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95"
    ]
  },
  "77392": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 09, 1925",
    "EXP": "1",
    "SCHOOL": "Wyoming",
    "name": "Ron Livingstone",
    "rosters": [
      "78cf5ce6-637f-459d-812a-47c6b4615dc4"
    ]
  },
  "77394": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 03, 1928",
    "EXP": "8",
    "SCHOOL": "West Virginia State",
    "name": "Earl Lloyd",
    "rosters": [
      "b7540846-94ec-42ec-b008-ff241f8152ea",
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8",
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a",
      "03f980b2-d8d0-47cc-a777-71a16e029764",
      "29b33649-2e3d-444b-81b4-caec18163bdd"
    ]
  },
  "77395": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 22, 1959",
    "EXP": "6",
    "SCHOOL": "Drake",
    "name": "Lewis Lloyd",
    "rosters": [
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "955db219-1fe5-405f-a607-095000d7ba49"
    ]
  },
  "77396": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 19, 1952",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Scott Lloyd",
    "rosters": [
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "743aba57-7e93-4575-8cb7-e3de53d6223c"
    ]
  },
  "77397": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 05, 1927",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Bob Lochmueller",
    "rosters": [
      "b7540846-94ec-42ec-b008-ff241f8152ea"
    ]
  },
  "77398": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 22, 1966",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Robert Lock",
    "rosters": [
      "03a36ce1-3d63-4f28-b362-56b1356f738d"
    ]
  },
  "77399": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "SEP 14, 1960",
    "EXP": "R",
    "SCHOOL": "Auburn",
    "name": "Darrell Lockhart",
    "rosters": [
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2"
    ]
  },
  "77401": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 15, 1959",
    "EXP": "1",
    "SCHOOL": "Alabama State",
    "name": "Kevin Loder",
    "rosters": [
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "48f67883-4176-4790-ac27-c6cec5e58bd8"
    ]
  },
  "77402": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 18, 1928",
    "EXP": "2",
    "SCHOOL": "San Francisco",
    "name": "Don Lofgran",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "77403": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 01, 1921",
    "EXP": "4",
    "SCHOOL": "Indiana",
    "name": "Johnny Logan",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b"
    ]
  },
  "77406": {
    "NUM": "26-36",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 08, 1944",
    "EXP": "1",
    "SCHOOL": "Wake Forest",
    "name": "Paul Long",
    "rosters": [
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5",
      "d80f75e6-baf7-4843-8122-aa743aea3477"
    ]
  },
  "77409": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 04, 1930",
    "EXP": "8",
    "SCHOOL": "Oregon",
    "name": "Jim Loscutoff",
    "rosters": [
      "75b95e75-2397-48c9-b255-676792b7226d",
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7",
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "3e94e421-e072-4018-95e3-f183762c70eb"
    ]
  },
  "77410": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 11, 1945",
    "EXP": "1",
    "SCHOOL": "Seattle",
    "name": "Plummer Lott",
    "rosters": [
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "5df3e966-18d8-42d1-a237-835fc00837b6"
    ]
  },
  "77411": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 28, 1940",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Kevin Loughery",
    "rosters": [
      "a66f3932-1385-40eb-a71f-3de93d22fbc1",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "b204df04-9a5e-48f7-814f-b547b0690ab8",
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "0403c301-445c-4c5d-8144-e5805bdc801c",
      "ab91fee8-d6df-4549-ad42-04456017afc3",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0"
    ]
  },
  "77412": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 08, 1942",
    "EXP": "10",
    "SCHOOL": "Southern University",
    "name": "Bob Love",
    "rosters": [
      "5ea792bd-890a-47b6-babe-2afabfd78f09",
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2",
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "66953847-11ae-490d-ba1c-76473d320167",
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "38c6a8b5-9c04-429f-9873-476923c33722",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "8718c236-d862-42de-a84d-ef29c83369b9"
    ]
  },
  "77413": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 09, 1949",
    "EXP": "1",
    "SCHOOL": "Oregon",
    "name": "Stan Love",
    "rosters": [
      "c39c5026-a493-40db-84cd-272c3b126805",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889"
    ]
  },
  "77414": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "234",
    "BIRTH_DATE": "SEP 07, 1929",
    "EXP": "4",
    "SCHOOL": "Kansas",
    "name": "Clyde Lovellette",
    "rosters": [
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "1b383d65-6ca5-46ca-b345-acf8e129e382",
      "22dd26a7-1c99-45b7-a759-dce48ccb6829",
      "156bc9fb-9be0-4d47-ae6c-e255ee721988",
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8",
      "21936ffd-2bd4-4505-a647-b4cd447d157b"
    ]
  },
  "77415": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 21, 1960",
    "EXP": "2",
    "SCHOOL": "North Carolina State",
    "name": "Sidney Lowe",
    "rosters": [
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3"
    ]
  },
  "77416": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 12, 1949",
    "EXP": "R",
    "SCHOOL": "Puget Sound",
    "name": "Chuck Lowery",
    "rosters": [
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6"
    ]
  },
  "77417": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 04, 1922",
    "EXP": "R",
    "SCHOOL": "Fordham",
    "name": "Al Lucas",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "77418": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 30, 1940",
    "EXP": "5",
    "SCHOOL": "Ohio State",
    "name": "Jerry Lucas",
    "rosters": [
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "852d8746-a465-41f3-b644-085c99bbad27",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef",
      "38c6a8b5-9c04-429f-9873-476923c33722",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604"
    ]
  },
  "77419": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 31, 1953",
    "EXP": "6",
    "SCHOOL": "Maryland",
    "name": "John Lucas",
    "rosters": [
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95"
    ]
  },
  "77420": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 18, 1952",
    "EXP": "10",
    "SCHOOL": "Marquette",
    "name": "Maurice Lucas",
    "rosters": [
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6"
    ]
  },
  "77421": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 27, 1939",
    "EXP": "1",
    "SCHOOL": "Houston",
    "name": "Ted Luckenbill",
    "rosters": [
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5",
      "60508db9-69f9-4133-ac4d-b9848b2add2d"
    ]
  },
  "77424": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "165",
    "BIRTH_DATE": "DEC 20, 1951",
    "EXP": "R",
    "SCHOOL": "Miami (OH)",
    "name": "Phil Lumpkin",
    "rosters": [
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f"
    ]
  },
  "77425": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "178",
    "BIRTH_DATE": "JUL 11, 1923",
    "EXP": "3",
    "SCHOOL": "New York University",
    "name": "Ray Lumpp",
    "rosters": [
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d"
    ]
  },
  "77426": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 24, 1968",
    "EXP": "1",
    "SCHOOL": "Minnesota",
    "name": "Kevin Lynch",
    "rosters": [
      "b4d79b93-f806-4a0d-a90c-18babfcbb170",
      "3e72397e-e399-4a1e-bd06-a9b455547453"
    ]
  },
  "77427": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 25, 1945",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Mike Lynn",
    "rosters": [
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00"
    ]
  },
  "77428": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 20, 1951",
    "EXP": "R",
    "SCHOOL": "Canisius",
    "name": "Mike Macaluso",
    "rosters": [
      "a7302500-52fe-4776-937c-0c2c59b29346"
    ]
  },
  "77429": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 22, 1928",
    "EXP": "6",
    "SCHOOL": "Saint Louis",
    "name": "Ed Macauley",
    "rosters": [
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7",
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc",
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b",
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "0f305d34-33d7-4e16-a669-f7ab442c151d",
      "75b95e75-2397-48c9-b255-676792b7226d"
    ]
  },
  "77430": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 20, 1930",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Ronnie MacGilvray",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800"
    ]
  },
  "77432": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 06, 1957",
    "EXP": "2",
    "SCHOOL": "East Carolina",
    "name": "Ollie Mack",
    "rosters": [
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "416a441e-349c-43bf-8a25-d9b8fa253a5c"
    ]
  },
  "77435": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 19, 1958",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Rudy Macklin",
    "rosters": [
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757"
    ]
  },
  "77436": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 07, 1923",
    "EXP": "1",
    "SCHOOL": "Seton Hall",
    "name": "Johnny Macknowski",
    "rosters": [
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "98673c29-08ee-48ce-9891-81b0ab92d951"
    ]
  },
  "77438": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "APR 09, 1957",
    "EXP": "4",
    "SCHOOL": "Kentucky",
    "name": "Kyle Macy",
    "rosters": [
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f"
    ]
  },
  "77442": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "202",
    "BIRTH_DATE": "NOV 24, 1959",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Dave Magley",
    "rosters": [
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d"
    ]
  },
  "77443": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 16, 1922",
    "EXP": "2",
    "SCHOOL": "Georgetown",
    "name": "John Mahnken",
    "rosters": [
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1",
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc",
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b",
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "77444": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 20, 1927",
    "EXP": "R",
    "SCHOOL": "Brown",
    "name": "Francis Mahoney",
    "rosters": [
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b"
    ]
  },
  "77449": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 23, 1955",
    "EXP": "11",
    "SCHOOL": null,
    "name": "Moses Malone",
    "rosters": [
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "da7fed67-9107-4124-becb-84e8169908af",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "d6428cec-3758-4908-ac44-81c67877df4e",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3"
    ]
  },
  "77450": {
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 21, 1956",
    "EXP": "R",
    "SCHOOL": "San Diego State",
    "name": "Steve Malovic",
    "rosters": [
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "77451": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 22, 1951",
    "EXP": "R",
    "SCHOOL": "Princeton",
    "name": "Ted Manakas",
    "rosters": [
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba"
    ]
  },
  "77452": {
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 03, 1919",
    "EXP": "1",
    "SCHOOL": "Oregon State",
    "name": "John Mandic",
    "rosters": [
      "cf598b10-dc59-4143-a286-b7dfcbbb6989"
    ]
  },
  "77453": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 25, 1925",
    "EXP": "R",
    "SCHOOL": "New York University",
    "name": "Frank Mangiapane",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24"
    ]
  },
  "77454": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 02, 1944",
    "EXP": "2",
    "SCHOOL": "Jackson State",
    "name": "Ed Manning",
    "rosters": [
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "0403c301-445c-4c5d-8144-e5805bdc801c"
    ]
  },
  "77456": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 22, 1960",
    "EXP": "2",
    "SCHOOL": "Utah",
    "name": "Pace Mannion",
    "rosters": [
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a"
    ]
  },
  "77457": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 07, 1935",
    "EXP": "1",
    "SCHOOL": "Northwestern",
    "name": "Nick Mantis",
    "rosters": [
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "77459": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "197",
    "BIRTH_DATE": "JUN 22, 1947",
    "EXP": "9",
    "SCHOOL": "Louisiana State",
    "name": "Pete Maravich",
    "rosters": [
      "fa657246-a721-4d64-b321-9a6be5364290",
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827",
      "5517a85a-4623-414c-89a6-b90bb5a42eca",
      "a8b9d60d-1449-49af-89fc-945fadd03a04",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "77460": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 13, 1966",
    "EXP": "1",
    "SCHOOL": "Iowa",
    "name": "Roy Marble",
    "rosters": [
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "98dcbf91-8e84-4cb9-a3cc-c3760e2680a8"
    ]
  },
  "77462": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "165",
    "BIRTH_DATE": "SEP 01, 1924",
    "EXP": "R",
    "SCHOOL": "Harvard",
    "name": "Saul Mariaschin",
    "rosters": [
      "e032e6bf-840a-444b-8044-7f6795db68e6"
    ]
  },
  "77463": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 12, 1944",
    "EXP": "5",
    "SCHOOL": "Duke",
    "name": "Jack Marin",
    "rosters": [
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "0403c301-445c-4c5d-8144-e5805bdc801c",
      "ab91fee8-d6df-4549-ad42-04456017afc3",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3"
    ]
  },
  "77464": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 26, 1948",
    "EXP": "2",
    "SCHOOL": "Eastern Michigan",
    "name": "Harvey Marlatt",
    "rosters": [
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226",
      "5d519571-b461-4e3b-963a-2173662c4b55"
    ]
  },
  "77465": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 10, 1954",
    "EXP": "R",
    "SCHOOL": "Manhattan",
    "name": "Eric Marsh",
    "rosters": [
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e"
    ]
  },
  "77466": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 26, 1946",
    "EXP": "R",
    "SCHOOL": "Southern California",
    "name": "Jim Marsh",
    "rosters": [
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4"
    ]
  },
  "77467": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 06, 1931",
    "EXP": "3",
    "SCHOOL": "Western Kentucky",
    "name": "Tom Marshall",
    "rosters": [
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b",
      "21936ffd-2bd4-4505-a647-b4cd447d157b",
      "dc41b99f-710f-4f81-b125-45b52cf09053"
    ]
  },
  "77468": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 22, 1948",
    "EXP": "R",
    "SCHOOL": "Oklahoma",
    "name": "Vester Marshall",
    "rosters": [
      "015afdf3-3da7-4590-9ac2-2a1976094cf3"
    ]
  },
  "77469": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "212",
    "BIRTH_DATE": "AUG 18, 1962",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Brian Martin",
    "rosters": [
      "0d39d553-7b93-4206-b525-d87f764e4d5d"
    ]
  },
  "77472": {
    "NUM": "10",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 25, 1962",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Fernando Martin",
    "rosters": [
      "3367b969-0b6f-4aa4-927e-679d12463f2b"
    ]
  },
  "77474": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 14, 1967",
    "EXP": "1",
    "SCHOOL": "Murray State",
    "name": "Jeff Martin",
    "rosters": [
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "abe1ba5f-8fba-4201-8de8-4454bbecb4ea"
    ]
  },
  "77475": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "208",
    "BIRTH_DATE": "MAR 30, 1950",
    "EXP": "3",
    "SCHOOL": "Loyola-Chicago",
    "name": "Larue Martin",
    "rosters": [
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "cbf01feb-6986-43c2-8350-23e707cf8272"
    ]
  },
  "77476": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 02, 1964",
    "EXP": "1",
    "SCHOOL": "Saint Joseph's",
    "name": "Mo Martin",
    "rosters": [
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda"
    ]
  },
  "77477": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 02, 1928",
    "EXP": "R",
    "SCHOOL": "Toledo",
    "name": "Phil Martin",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800"
    ]
  },
  "77478": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "OCT 07, 1969",
    "EXP": "1",
    "SCHOOL": "Minnesota",
    "name": "Bob Martin",
    "rosters": [
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae"
    ]
  },
  "77479": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 11, 1939",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "Ronald Martin",
    "rosters": [
      "816164e0-e2ee-413e-8662-b51fbf40b841"
    ]
  },
  "77480": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "170",
    "BIRTH_DATE": "OCT 22, 1925",
    "EXP": "6",
    "SCHOOL": "Texas",
    "name": "Slater Martin",
    "rosters": [
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9",
      "0c2ad895-0fc8-49c0-9976-48e929a0c370",
      "56568a86-b30e-48a7-9f7d-80f79aa00339",
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2",
      "1b383d65-6ca5-46ca-b345-acf8e129e382",
      "22dd26a7-1c99-45b7-a759-dce48ccb6829",
      "156bc9fb-9be0-4d47-ae6c-e255ee721988"
    ]
  },
  "77481": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 16, 1962",
    "EXP": "2",
    "SCHOOL": "Georgetown",
    "name": "Bill Martin",
    "rosters": [
      "b2182580-6b74-4d01-905a-961e5a283423",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "ca66d0d0-8947-439e-8245-2fecd01da018"
    ]
  },
  "77482": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "174",
    "BIRTH_DATE": "FEB 05, 1928",
    "EXP": "1",
    "SCHOOL": "Canisius",
    "name": "Al Masino",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd",
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8"
    ]
  },
  "77483": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 03, 1948",
    "EXP": "1",
    "SCHOOL": "Temple",
    "name": "Eddie Mast",
    "rosters": [
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12"
    ]
  },
  "77484": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "AUG 24, 1959",
    "EXP": "5",
    "SCHOOL": "Wisconsin",
    "name": "Wes Matthews",
    "rosters": [
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8"
    ]
  },
  "77486": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "250",
    "BIRTH_DATE": "FEB 19, 1969",
    "EXP": "1",
    "SCHOOL": "Texas-El Paso",
    "name": "Marlon Maxey",
    "rosters": [
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "54f0f70e-ad29-449d-8754-ee6a602fe375"
    ]
  },
  "77487": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 21, 1955",
    "EXP": "8",
    "SCHOOL": "North Carolina-Charlotte",
    "name": "Cedric Maxwell",
    "rosters": [
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90"
    ]
  },
  "77489": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 03, 1946",
    "EXP": "6",
    "SCHOOL": "Dayton",
    "name": "Don May",
    "rosters": [
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "66c852c8-74e2-482e-8745-846263fa3f58"
    ]
  },
  "77490": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 19, 1954",
    "EXP": "6",
    "SCHOOL": "Indiana",
    "name": "Scott May",
    "rosters": [
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "f7a58b16-8257-42be-b944-16319d0abd63"
    ]
  },
  "77492": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 17, 1953",
    "EXP": "1",
    "SCHOOL": "Furman",
    "name": "Clyde Mayes",
    "rosters": [
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba"
    ]
  },
  "77493": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 09, 1968",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Tharon Mayes",
    "rosters": [
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5"
    ]
  },
  "77494": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 11, 1948",
    "EXP": "R",
    "SCHOOL": "Tuskegee",
    "name": "Ken Mayfield",
    "rosters": [
      "52649402-55a4-4d97-a5fd-f7cf9779a672"
    ]
  },
  "77495": {
    "NUM": "36",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 17, 1957",
    "EXP": "R",
    "SCHOOL": "Iowa",
    "name": "Bill Mayfield",
    "rosters": [
      "6af54433-9189-4d97-aafc-8c3d7350a043"
    ]
  },
  "77496": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 19, 1968",
    "EXP": "R",
    "SCHOOL": "Texas",
    "name": "Travis Mays",
    "rosters": [
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "57c1be08-60cf-40ad-a097-a95ef9958532"
    ]
  },
  "77498": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 15, 1951",
    "EXP": "7",
    "SCHOOL": "North Carolina",
    "name": "Bob McAdoo",
    "rosters": [
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "77499": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 14, 1905",
    "EXP": "R",
    "SCHOOL": "Maryland-Eastern Shore",
    "name": "Ken McBride",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800"
    ]
  },
  "77500": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "178",
    "BIRTH_DATE": "JUL 05, 1935",
    "EXP": "2",
    "SCHOOL": "St. Bonaventure",
    "name": "Brendan McCann",
    "rosters": [
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b"
    ]
  },
  "77502": {
    "NUM": "49",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 19, 1967",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Mel McCants",
    "rosters": [
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8"
    ]
  },
  "77504": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 25, 1953",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "Andre McCarter",
    "rosters": [
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "77505": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 26, 1946",
    "EXP": "2",
    "SCHOOL": "Drake",
    "name": "Willie McCarter",
    "rosters": [
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4"
    ]
  },
  "77506": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 25, 1934",
    "EXP": "1",
    "SCHOOL": "Canisius",
    "name": "Johnny McCarthy",
    "rosters": [
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b",
      "dc41b99f-710f-4f81-b125-45b52cf09053"
    ]
  },
  "77509": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 07, 1963",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Dwayne McClain",
    "rosters": [
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd"
    ]
  },
  "77510": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 30, 1947",
    "EXP": "2",
    "SCHOOL": "Tennessee State",
    "name": "Ted, Hound Dog McClain",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967",
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "e8500690-7102-4acf-ae8b-f1907cf60bcf"
    ]
  },
  "77512": {
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 19, 1925",
    "EXP": "R",
    "SCHOOL": "Pennsylvania",
    "name": "Jack McCloskey",
    "rosters": [
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "77513": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 09, 1930",
    "EXP": "R",
    "SCHOOL": "Northwestern State",
    "name": "John McConathy",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "77514": {
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUL 01, 1928",
    "EXP": "R",
    "SCHOOL": "Marshall",
    "name": "Paul McConnell",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd"
    ]
  },
  "77515": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 22, 1957",
    "EXP": "R",
    "SCHOOL": "Alabama-Birmingham",
    "name": "Keith McCord",
    "rosters": [
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "77516": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 10, 1962",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "Tim McCormick",
    "rosters": [
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "79d8e657-ce27-4052-9492-c9833808173f",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "0d39d553-7b93-4206-b525-d87f764e4d5d"
    ]
  },
  "77517": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 11, 1950",
    "EXP": "1",
    "SCHOOL": "Cal State-Northridge",
    "name": "Paul McCracken",
    "rosters": [
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "298aa605-b35d-4f1f-978f-4f4883ddee16"
    ]
  },
  "77518": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 08, 1960",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Scooter McCray",
    "rosters": [
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4"
    ]
  },
  "77520": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 05, 1956",
    "EXP": "R",
    "SCHOOL": "Oklahoma",
    "name": "John McCullough",
    "rosters": [
      "aca2f6cb-0959-42f9-a0a5-49c843300715"
    ]
  },
  "77523": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "228",
    "BIRTH_DATE": "APR 02, 1948",
    "EXP": "2",
    "SCHOOL": "Western Kentucky",
    "name": "Jim McDaniels",
    "rosters": [
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3"
    ]
  },
  "77524": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 20, 1962",
    "EXP": "3",
    "SCHOOL": "California-Irvine",
    "name": "Ben McDonald",
    "rosters": [
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482"
    ]
  },
  "77525": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 18, 1952",
    "EXP": "2",
    "SCHOOL": "Long Beach State",
    "name": "Glenn McDonald",
    "rosters": [
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "743aba57-7e93-4575-8cb7-e3de53d6223c"
    ]
  },
  "77527": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 13, 1959",
    "EXP": "1",
    "SCHOOL": "Memphis",
    "name": "Hank McDowell",
    "rosters": [
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "80997709-1fc9-4ada-b330-ff77310ab65f"
    ]
  },
  "77528": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 04, 1953",
    "EXP": "4",
    "SCHOOL": "Central Michigan",
    "name": "Jim McElroy",
    "rosters": [
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827",
      "5517a85a-4623-414c-89a6-b90bb5a42eca",
      "a8b9d60d-1449-49af-89fc-945fadd03a04",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "77529": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 26, 1926",
    "EXP": "R",
    "SCHOOL": "Arkansas",
    "name": "Mel McGaha",
    "rosters": [
      "df1fce20-7cea-47cc-8e99-cf6d772b3162"
    ]
  },
  "77530": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 29, 1959",
    "EXP": "6",
    "SCHOOL": "Michigan",
    "name": "Mike McGee",
    "rosters": [
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "3a5f986c-a993-4fb5-8e76-1b8cc7720fef",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "77531": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 16, 1939",
    "EXP": "R",
    "SCHOOL": "Utah",
    "name": "Bill McGill",
    "rosters": [
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "67233742-0a5f-4ab9-a3f8-682956af416d",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "77532": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "AUG 12, 1950",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "George McGinnis",
    "rosters": [
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "d3da55d4-ca59-42db-b517-15302adf51f1",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "41977d46-c447-4c82-8cb1-8952bd2b335e",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893"
    ]
  },
  "77533": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 10, 1943",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "Jon McGlocklin",
    "rosters": [
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00",
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "30c679a6-4b92-42bc-b03d-303894f5aed9",
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6",
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef",
      "38c6a8b5-9c04-429f-9873-476923c33722"
    ]
  },
  "77534": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 14, 1949",
    "EXP": "R",
    "SCHOOL": "Wake Forest",
    "name": "Gil McGregor",
    "rosters": [
      "146cb658-0a9a-4f26-8556-39497dc3cfa4"
    ]
  },
  "77535": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 10, 1951",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "Allie McGuire",
    "rosters": [
      "98a19076-a6e8-4bc0-b991-e249597a93b2"
    ]
  },
  "77536": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 07, 1928",
    "EXP": "2",
    "SCHOOL": "St. John's (NY)",
    "name": "Al McGuire",
    "rosters": [
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac",
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d"
    ]
  },
  "77537": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 25, 1926",
    "EXP": "10",
    "SCHOOL": "St. John's (NY)",
    "name": "Dick McGuire",
    "rosters": [
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac",
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d",
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3",
      "03f980b2-d8d0-47cc-a777-71a16e029764",
      "29b33649-2e3d-444b-81b4-caec18163bdd"
    ]
  },
  "77540": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 21, 1949",
    "EXP": "3",
    "SCHOOL": "Eastern Michigan",
    "name": "Kenny McIntosh",
    "rosters": [
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "cc792a0f-fa98-4caf-ad55-38fe38f01714"
    ]
  },
  "77541": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 08, 1959",
    "EXP": "3",
    "SCHOOL": "Creighton",
    "name": "Kevin McKenna",
    "rosters": [
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "77542": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 16, 1963",
    "EXP": "R",
    "SCHOOL": "Loyola-Marymount",
    "name": "Forrest McKenzie",
    "rosters": [
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd"
    ]
  },
  "77543": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 06, 1944",
    "EXP": "R",
    "SCHOOL": "New York University",
    "name": "Stan McKenzie",
    "rosters": [
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "20955b01-8d24-45f5-ba05-3a4253c801f7",
      "c5964149-f86c-413e-9d7c-c8d07f4113ef",
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea"
    ]
  },
  "77545": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 21, 1964",
    "EXP": "1",
    "SCHOOL": "Southern Methodist",
    "name": "Carlton McKinney",
    "rosters": [
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392"
    ]
  },
  "77546": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 01, 1919",
    "EXP": "5",
    "SCHOOL": "North Carolina",
    "name": "Horace McKinney",
    "rosters": [
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7",
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc"
    ]
  },
  "77547": {
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "160",
    "BIRTH_DATE": "JUN 05, 1955",
    "EXP": "1",
    "SCHOOL": "Northwestern",
    "name": "Billy McKinney",
    "rosters": [
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "1df46296-cc39-4688-95c6-3656430e4ae2"
    ],
    "NUM": "25"
  },
  "77548": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 03, 1942",
    "EXP": "5",
    "SCHOOL": "Drake",
    "name": "Mccoy McLemore",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1",
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "49db955c-2a3f-4693-b68f-834ab5003c4d",
      "d80f75e6-baf7-4843-8122-aa743aea3477"
    ]
  },
  "77551": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 03, 1928",
    "EXP": "2",
    "SCHOOL": "St. John's (NY)",
    "name": "Jack McMahon",
    "rosters": [
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b",
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "a0311ade-2019-4595-ab92-26a1696f3ce7",
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6",
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af"
    ]
  },
  "77553": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 26, 1952",
    "EXP": "10",
    "SCHOOL": "Maryland",
    "name": "Tom McMillen",
    "rosters": [
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474",
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "77554": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 11, 1948",
    "EXP": "8",
    "SCHOOL": "Columbia",
    "name": "Jim McMillian",
    "rosters": [
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd"
    ]
  },
  "77555": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 11, 1936",
    "EXP": "2",
    "SCHOOL": "Bradley",
    "name": "Shellie McMillon",
    "rosters": [
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "03f980b2-d8d0-47cc-a777-71a16e029764",
      "29b33649-2e3d-444b-81b4-caec18163bdd",
      "a4934f64-d807-440b-9639-6e4052c2501e"
    ]
  },
  "77558": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 08, 1959",
    "EXP": "1",
    "SCHOOL": "California",
    "name": "Mark McNamara",
    "rosters": [
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2"
    ]
  },
  "77559": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 24, 1926",
    "EXP": "R",
    "SCHOOL": "San Francisco",
    "name": "Joe McNamee",
    "rosters": [
      "6146894f-3e97-4aab-a027-77228d1955a6"
    ]
  },
  "77560": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 15, 1961",
    "EXP": "2",
    "SCHOOL": "San Jose State",
    "name": "Chris McNealy",
    "rosters": [
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44"
    ]
  },
  "77561": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 31, 1951",
    "EXP": "5",
    "SCHOOL": "Marquette",
    "name": "Larry McNeill",
    "rosters": [
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "66c852c8-74e2-482e-8745-846263fa3f58",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "77562": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "OCT 22, 1938",
    "EXP": "R",
    "SCHOOL": "Saint Joseph's",
    "name": "Bob McNeill",
    "rosters": [
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "2fb8b948-e857-417f-8af2-422f0825a7c1"
    ]
  },
  "77563": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 14, 1930",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Carl McNulty",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800"
    ]
  },
  "77565": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 18, 1962",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Cozell McQueen",
    "rosters": [
      "999df20d-d256-45ec-8e64-111bdc7471d7"
    ]
  },
  "77566": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 08, 1943",
    "EXP": "R",
    "SCHOOL": "Miles",
    "name": "Thales McReynolds",
    "rosters": [
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc"
    ]
  },
  "77567": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 18, 1950",
    "EXP": "R",
    "SCHOOL": "Long Beach State",
    "name": "Eric McWilliams",
    "rosters": [
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a"
    ]
  },
  "77570": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 10, 1947",
    "EXP": "4",
    "SCHOOL": "Colorado",
    "name": "Cliff Meely",
    "rosters": [
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb"
    ]
  },
  "77571": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 04, 1964",
    "EXP": "1",
    "SCHOOL": "Illinois",
    "name": "Scott Meents",
    "rosters": [
      "345866de-4a0b-47e3-bd9b-84a98c5ddf2e",
      "8f0aed89-b637-4e96-8fe6-5e1303c9146a"
    ]
  },
  "77572": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 20, 1922",
    "EXP": "1",
    "SCHOOL": "Tennessee",
    "name": "Dick Mehen",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699",
      "648376a8-6ee8-4cad-97d2-369540020f50"
    ]
  },
  "77573": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "208",
    "BIRTH_DATE": "OCT 30, 1930",
    "EXP": "2",
    "SCHOOL": "Dayton",
    "name": "Don Meineke",
    "rosters": [
      "516c9243-1276-46d0-8dbe-f1db0a8384ad",
      "21936ffd-2bd4-4505-a647-b4cd447d157b",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e",
      "57494c03-afc0-41de-b544-659990d5e952",
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91"
    ]
  },
  "77575": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 19, 1951",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Gary Melchionni",
    "rosters": [
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792"
    ]
  },
  "77576": {
    "NUM": "28",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "165",
    "BIRTH_DATE": "OCT 19, 1944",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Bill Melchionni",
    "rosters": [
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "56bc558b-98d9-4a75-9033-fbec8e9fe666"
    ]
  },
  "77578": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 13, 1948",
    "EXP": "5",
    "SCHOOL": "Marquette",
    "name": "Dean Meminger",
    "rosters": [
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c"
    ]
  },
  "77579": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "168",
    "BIRTH_DATE": "APR 21, 1933",
    "EXP": "1",
    "SCHOOL": "Minnesota",
    "name": "Chuck Mencel",
    "rosters": [
      "156bc9fb-9be0-4d47-ae6c-e255ee721988",
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8"
    ]
  },
  "77580": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 16, 1949",
    "EXP": "4",
    "SCHOOL": "Auburn",
    "name": "John Mengelt",
    "rosters": [
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185",
      "94f36f95-2ce4-4f55-8a83-19b910b11438",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b"
    ]
  },
  "77582": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 26, 1953",
    "EXP": "2",
    "SCHOOL": "Southern Illinois",
    "name": "Joe Meriweather",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "b001782c-95ad-444b-814b-98ecf23fa9ce",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "19686855-a120-434c-8ad4-5f15e61b9a38",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "5517a85a-4623-414c-89a6-b90bb5a42eca"
    ]
  },
  "77583": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 16, 1940",
    "EXP": "R",
    "SCHOOL": "Tennessee State",
    "name": "Porter Meriwether",
    "rosters": [
      "1ac58220-677b-4b39-88b8-e0f140cc4134"
    ]
  },
  "77584": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 26, 1938",
    "EXP": "9",
    "SCHOOL": "St. Mary's (CA)",
    "name": "Tom Meschery",
    "rosters": [
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5",
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "3829b824-1221-432d-8dc1-388a4d2f1cce",
      "6e488406-7055-45fd-b876-5b97997c0cf1"
    ]
  },
  "77586": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 21, 1953",
    "EXP": "3",
    "SCHOOL": "California-Los Angeles",
    "name": "Dave Meyers",
    "rosters": [
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "743aba57-7e93-4575-8cb7-e3de53d6223c",
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d"
    ]
  },
  "77587": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 08, 1924",
    "EXP": "5",
    "SCHOOL": null,
    "name": "Stan Miasek",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd"
    ]
  },
  "77588": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 24, 1960",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "Larry Micheaux",
    "rosters": [
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7"
    ]
  },
  "77590": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 20, 1925",
    "EXP": "3",
    "SCHOOL": "DePaul",
    "name": "Ed Mikan",
    "rosters": [
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "78cf5ce6-637f-459d-812a-47c6b4615dc4",
      "1c190e61-1a8b-4cd1-aba2-fae468830890"
    ]
  },
  "77591": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 08, 1948",
    "EXP": "R",
    "SCHOOL": "Minnesota",
    "name": "Larry Mikan",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1"
    ]
  },
  "77593": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 21, 1928",
    "EXP": "9",
    "SCHOOL": "Hamline",
    "name": "Vern Mikkelsen",
    "rosters": [
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9",
      "0c2ad895-0fc8-49c0-9976-48e929a0c370",
      "56568a86-b30e-48a7-9f7d-80f79aa00339",
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2",
      "1b383d65-6ca5-46ca-b345-acf8e129e382",
      "22dd26a7-1c99-45b7-a759-dce48ccb6829",
      "156bc9fb-9be0-4d47-ae6c-e255ee721988",
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8",
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "713f8679-ab1e-4a38-9f5d-27d9ea96bd5c"
    ]
  },
  "77596": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 05, 1940",
    "EXP": "6",
    "SCHOOL": "Seattle",
    "name": "Eddie Miles",
    "rosters": [
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "ab91fee8-d6df-4549-ad42-04456017afc3",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a",
      "3902314e-3111-45fa-9433-86d9fdae07db",
      "61110628-8f7d-478f-b171-52455d3bf9d0",
      "65f693c5-b99c-4553-b1db-458345ed8c34",
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5",
      "49db955c-2a3f-4693-b68f-834ab5003c4d",
      "d80f75e6-baf7-4843-8122-aa743aea3477"
    ]
  },
  "77597": {
    "NUM": "20",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 07, 1977",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Marko Milic",
    "rosters": [
      "8e727f33-9a8b-4e6c-99ad-95e5208aaf0e"
    ]
  },
  "77601": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 19, 1943",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Jay Miller",
    "rosters": [
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00"
    ]
  },
  "77602": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 26, 1958",
    "EXP": "R",
    "SCHOOL": "Toledo",
    "name": "Dick Miller",
    "rosters": [
      "79272c8a-a0ac-4650-b0a6-e971f46652f5"
    ]
  },
  "77603": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 09, 1956",
    "EXP": "R",
    "SCHOOL": "Cincinnati",
    "name": "Bob Miller",
    "rosters": [
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2"
    ]
  },
  "77609": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 17, 1961",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Dirk Minniefield",
    "rosters": [
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "6cadde58-cc80-4e96-af67-6d093657445c"
    ]
  },
  "77610": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 23, 1922",
    "EXP": "1",
    "SCHOOL": "UCLA",
    "name": "Dave Minor",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd"
    ]
  },
  "77612": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 14, 1950",
    "EXP": "R",
    "SCHOOL": "Ohio State",
    "name": "Mark Minor",
    "rosters": [
      "685d7197-3cc2-4e98-8f77-699d694916ab"
    ]
  },
  "77613": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "5-7",
    "WEIGHT": "150",
    "BIRTH_DATE": "DEC 21, 1923",
    "EXP": "R",
    "SCHOOL": "Utah",
    "name": "Wat Misaka",
    "rosters": [
      "bb996e83-b963-4fd6-b44b-484116ff5a4e"
    ]
  },
  "77614": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 26, 1966",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Todd Mitchell",
    "rosters": [
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a"
    ]
  },
  "77615": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 01, 1956",
    "EXP": "9",
    "SCHOOL": "Auburn",
    "name": "Mike Mitchell",
    "rosters": [
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "5c09ec26-5505-4508-98c5-db244798d9d1",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "473f0beb-d4eb-46a7-b66f-d6831084a451"
    ]
  },
  "77618": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 30, 1947",
    "EXP": "2",
    "SCHOOL": "Toledo",
    "name": "Steve Mix",
    "rosters": [
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "41977d46-c447-4c82-8cb1-8952bd2b335e",
      "36aee3a5-2cc5-470b-8cd3-d51212e5b942",
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "d80f75e6-baf7-4843-8122-aa743aea3477",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226"
    ]
  },
  "77619": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 19, 1931",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Bill Mlkvy",
    "rosters": [
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "77621": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 05, 1954",
    "EXP": "R",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Larry Moffett",
    "rosters": [
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff"
    ]
  },
  "77622": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 13, 1921",
    "EXP": "3",
    "SCHOOL": "Youngstown State",
    "name": "Leo Mogus",
    "rosters": [
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388",
      "78cf5ce6-637f-459d-812a-47c6b4615dc4"
    ]
  },
  "77623": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "JAN 03, 1957",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Paul Mokeski",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "c163d65f-d6fd-4a44-968a-2897543255d4"
    ]
  },
  "77624": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 01, 1931",
    "EXP": "R",
    "SCHOOL": "Columbia",
    "name": "Jack Molinas",
    "rosters": [
      "57494c03-afc0-41de-b544-659990d5e952"
    ]
  },
  "77625": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 17, 1943",
    "EXP": "R",
    "SCHOOL": "Lewis",
    "name": "Wayne Molis",
    "rosters": [
      "054fdbaa-a097-480c-b2de-7711c6b6734b"
    ]
  },
  "77626": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 21, 1957",
    "EXP": "9",
    "SCHOOL": "Arkansas",
    "name": "Sidney Moncrief",
    "rosters": [
      "1da0f652-a611-4cc2-9f88-ea410f5a6c08",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d",
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a"
    ]
  },
  "77627": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 06, 1955",
    "EXP": "5",
    "SCHOOL": "Arizona",
    "name": "Eric Money",
    "rosters": [
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "94f36f95-2ce4-4f55-8a83-19b910b11438",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "77628": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 16, 1968",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Rodney Monroe",
    "rosters": [
      "6fd292ed-c920-4655-a87f-7bf233c75094"
    ]
  },
  "77630": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 22, 1940",
    "EXP": "R",
    "SCHOOL": "Texas-Pan American",
    "name": "Howie Montgomery",
    "rosters": [
      "60508db9-69f9-4133-ac4d-b9848b2add2d"
    ]
  },
  "77631": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 08, 1930",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Jim Mooney",
    "rosters": [
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "77632": {
    "NUM": "53",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 02, 1964",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Andre Moore",
    "rosters": [
      "777d6706-d922-46f9-8db4-e079c5f46c74"
    ]
  },
  "77633": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 03, 1958",
    "EXP": "8",
    "SCHOOL": "Texas",
    "name": "Johnny Moore",
    "rosters": [
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "a6b98be6-4acd-49be-92b6-decd2522e4df",
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "5c09ec26-5505-4508-98c5-db244798d9d1",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "6d7d2bff-ce1d-48d7-a30e-cebcd51cf3b8"
    ]
  },
  "77634": {
    "NUM": "4-14",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 24, 1932",
    "EXP": "2",
    "SCHOOL": "La Salle",
    "name": "Jackie Moore",
    "rosters": [
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86",
      "05b98b66-c377-4196-b63f-7c9749f98f47",
      "59113a13-7bcb-4a63-908c-a566386976eb"
    ]
  },
  "77635": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAY 05, 1957",
    "EXP": "R",
    "SCHOOL": "West Virginia",
    "name": "Lowes Moore",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "30f87621-72fd-4d91-987d-50cce0bf1714"
    ]
  },
  "77636": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 27, 1946",
    "EXP": "2",
    "SCHOOL": "Texas-Pan American",
    "name": "Otto Moore",
    "rosters": [
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827",
      "49db955c-2a3f-4693-b68f-834ab5003c4d",
      "d80f75e6-baf7-4843-8122-aa743aea3477",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9"
    ]
  },
  "77637": {
    "NUM": "53",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "260",
    "BIRTH_DATE": "JAN 16, 1962",
    "EXP": "R",
    "SCHOOL": "West Virginia State",
    "name": "Ron Moore",
    "rosters": [
      "ca66d0d0-8947-439e-8245-2fecd01da018"
    ]
  },
  "77639": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 11, 1938",
    "EXP": "4",
    "SCHOOL": "Louisiana Tech",
    "name": "Jackie Moreland",
    "rosters": [
      "a4934f64-d807-440b-9639-6e4052c2501e",
      "e6ca1168-2bff-4198-9727-665654e83794",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a",
      "3902314e-3111-45fa-9433-86d9fdae07db"
    ]
  },
  "77640": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 23, 1960",
    "EXP": "R",
    "SCHOOL": "Wake Forest",
    "name": "Guy Morgan",
    "rosters": [
      "c24811b3-b1da-43ab-9ab2-9a68328f351c"
    ]
  },
  "77641": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 27, 1948",
    "EXP": "1",
    "SCHOOL": "Jacksonville",
    "name": "Rex Morgan",
    "rosters": [
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9"
    ]
  },
  "77642": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 03, 1922",
    "EXP": "1",
    "SCHOOL": "New Mexico Tech",
    "name": "Elmo Morgenthaler",
    "rosters": [
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb"
    ]
  },
  "77644": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 22, 1969",
    "EXP": "R",
    "SCHOOL": "Pittsburgh",
    "name": "Darren Morningstar",
    "rosters": [
      "d37f2fb1-fc17-4273-9d03-81e57c07a9c5"
    ]
  },
  "77647": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "229",
    "BIRTH_DATE": "APR 02, 1969",
    "EXP": "R",
    "SCHOOL": "Arkansas",
    "name": "Isaiah Morris",
    "rosters": [
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "77648": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 26, 1932",
    "EXP": "1",
    "SCHOOL": "Idaho",
    "name": "Dwight Morrison",
    "rosters": [
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "0f305d34-33d7-4e16-a669-f7ab442c151d",
      "75b95e75-2397-48c9-b255-676792b7226d"
    ]
  },
  "77649": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 16, 1967",
    "EXP": "R",
    "SCHOOL": "Loyola-Maryland",
    "name": "Mike Morrison",
    "rosters": [
      "1a846bd5-9033-48bc-b23f-c363d42f51c8"
    ]
  },
  "77652": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 02, 1966",
    "EXP": "R",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Richard Morton",
    "rosters": [
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5"
    ]
  },
  "77653": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 26, 1955",
    "EXP": "1",
    "SCHOOL": "Seton Hall",
    "name": "Glenn Mosley",
    "rosters": [
      "c2941028-2be6-41b6-86ce-d32002b00893",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148"
    ]
  },
  "77654": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 11, 1958",
    "EXP": "R",
    "SCHOOL": "Northeastern",
    "name": "Perry Moss",
    "rosters": [
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "77658": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 12, 1944",
    "EXP": "6",
    "SCHOOL": "San Francisco",
    "name": "Erwin Mueller",
    "rosters": [
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "d80f75e6-baf7-4843-8122-aa743aea3477",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226",
      "5d519571-b461-4e3b-963a-2173662c4b55"
    ]
  },
  "77659": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "165",
    "BIRTH_DATE": "NOV 17, 1925",
    "EXP": "R",
    "SCHOOL": "Holy Cross",
    "name": "Joe Mullaney",
    "rosters": [
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1"
    ]
  },
  "77662": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 18, 1942",
    "EXP": "11",
    "SCHOOL": "Duke",
    "name": "Jeff Mullins",
    "rosters": [
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "fd982b48-258b-4fc9-b6d2-1be39fa32925",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "852d8746-a465-41f3-b644-085c99bbad27",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864",
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796"
    ]
  },
  "77664": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 05, 1967",
    "EXP": "R",
    "SCHOOL": "Southern California",
    "name": "Chris Munk",
    "rosters": [
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff"
    ]
  },
  "77665": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "170",
    "BIRTH_DATE": "JAN 05, 1922",
    "EXP": "1",
    "SCHOOL": "Dartmouth",
    "name": "George Munroe",
    "rosters": [
      "e032e6bf-840a-444b-8044-7f6795db68e6"
    ]
  },
  "77668": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 15, 1952",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Allen Murphy",
    "rosters": [
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77"
    ]
  },
  "77669": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "5-9",
    "WEIGHT": "165",
    "BIRTH_DATE": "MAY 09, 1948",
    "EXP": "12",
    "SCHOOL": "Niagara",
    "name": "Calvin Murphy",
    "rosters": [
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a"
    ]
  },
  "77670": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 26, 1962",
    "EXP": "3",
    "SCHOOL": "Boston College",
    "name": "Jay Murphy",
    "rosters": [
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3"
    ]
  },
  "77671": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 13, 1924",
    "EXP": "R",
    "SCHOOL": null,
    "name": "John Murphy",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24"
    ]
  },
  "77672": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 10, 1921",
    "EXP": "R",
    "SCHOOL": "Manhattan",
    "name": "Dick Murphy",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "77673": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 29, 1964",
    "EXP": "R",
    "SCHOOL": "Jacksonville",
    "name": "Ronnie Murphy",
    "rosters": [
      "04ef3e81-d8fb-48a8-ba78-694468f81686"
    ]
  },
  "77674": {
    "NUM": "9",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 24, 1963",
    "EXP": "3",
    "SCHOOL": "California-Irvine",
    "name": "Tod Murphy",
    "rosters": [
      "59cf984a-acd5-4aea-83fa-fa9f1638b97c",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "65bd9a8d-0eab-45dc-bcfd-6c924e1b695c"
    ]
  },
  "77675": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 20, 1928",
    "EXP": "1",
    "SCHOOL": "St. Bonaventure",
    "name": "Ken Murray",
    "rosters": [
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86",
      "648376a8-6ee8-4cad-97d2-369540020f50",
      "57494c03-afc0-41de-b544-659990d5e952"
    ]
  },
  "77676": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 07, 1943",
    "EXP": "R",
    "SCHOOL": "Detroit Mercy",
    "name": "Dorie Murrey",
    "rosters": [
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "3829b824-1221-432d-8dc1-388a4d2f1cce",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3",
      "65f693c5-b99c-4553-b1db-458345ed8c34"
    ]
  },
  "77677": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "5-9",
    "WEIGHT": "145",
    "BIRTH_DATE": "JUL 25, 1918",
    "EXP": "2",
    "SCHOOL": "Temple",
    "name": "Angelo Musi",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c",
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a",
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb"
    ]
  },
  "77682": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 06, 1933",
    "EXP": "R",
    "SCHOOL": "New York University",
    "name": "Boris Nachamkin",
    "rosters": [
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af"
    ]
  },
  "77683": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 18, 1928",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Jerry Nagel",
    "rosters": [
      "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e"
    ]
  },
  "77685": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 12, 1959",
    "EXP": "5",
    "SCHOOL": "Clemson",
    "name": "Larry Nance",
    "rosters": [
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "90b30987-294d-488e-ade2-2e9dd74bb802"
    ]
  },
  "77687": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 24, 1942",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Charles Nash",
    "rosters": [
      "6530f527-58ae-467d-8504-53e63dd6c84e"
    ]
  },
  "77688": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 24, 1950",
    "EXP": "1",
    "SCHOOL": "Hawaii",
    "name": "Bob Nash",
    "rosters": [
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185"
    ]
  },
  "77689": {
    "NUM": "31-35",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 14, 1950",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Swen Nater",
    "rosters": [
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "ef880e29-8409-4464-a009-ac827384a450",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "743aba57-7e93-4575-8cb7-e3de53d6223c"
    ]
  },
  "77690": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 21, 1972",
    "EXP": "R",
    "SCHOOL": "Louisiana-Monroe",
    "name": "Howard Nathan",
    "rosters": [
      "dbb6f07b-f7c6-48bb-ba15-d3224335d02b"
    ]
  },
  "77691": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 08, 1957",
    "EXP": "9",
    "SCHOOL": "Louisiana-Monroe",
    "name": "Calvin Natt",
    "rosters": [
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a"
    ]
  },
  "77692": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 05, 1958",
    "EXP": "1",
    "SCHOOL": "Louisiana-Monroe",
    "name": "Kenny Natt",
    "rosters": [
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e"
    ]
  },
  "77693": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 07, 1934",
    "EXP": "5",
    "SCHOOL": "California-Los Angeles",
    "name": "Willie Naulls",
    "rosters": [
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "2fb8b948-e857-417f-8af2-422f0825a7c1",
      "816164e0-e2ee-413e-8662-b51fbf40b841"
    ]
  },
  "77694": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "165",
    "BIRTH_DATE": "FEB 16, 1964",
    "EXP": "R",
    "SCHOOL": "Georgia Tech",
    "name": "Craig Neal",
    "rosters": [
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "652536cb-d53b-4de7-a147-555aacb21048"
    ]
  },
  "77695": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 21, 1930",
    "EXP": "R",
    "SCHOOL": "Wofford",
    "name": "Jim Neal",
    "rosters": [
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8"
    ]
  },
  "77696": {
    "NUM": "36",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 10, 1950",
    "EXP": "6",
    "SCHOOL": "Tennessee State",
    "name": "Lloyd Neal",
    "rosters": [
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "cbf01feb-6986-43c2-8350-23e707cf8272",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd"
    ]
  },
  "77697": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "238",
    "BIRTH_DATE": "FEB 19, 1960",
    "EXP": "4",
    "SCHOOL": "Kansas State",
    "name": "Ed Nealy",
    "rosters": [
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "43630741-81fb-49b9-b45e-d413e1873e42",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "473f0beb-d4eb-46a7-b66f-d6831084a451"
    ]
  },
  "77699": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 19, 1949",
    "EXP": "R",
    "SCHOOL": "Duquesne",
    "name": "Barry Nelson",
    "rosters": [
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6"
    ]
  },
  "77700": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 15, 1940",
    "EXP": "R",
    "SCHOOL": "Iowa",
    "name": "Don Nelson",
    "rosters": [
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "b576b451-62e4-4a16-98e2-535cecc8e551",
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9",
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "77701": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 28, 1951",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Louie Nelson",
    "rosters": [
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "024d58a0-dd09-4396-a891-82ba087f7279"
    ]
  },
  "77704": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "7-2",
    "WEIGHT": "260",
    "BIRTH_DATE": "FEB 16, 1965",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Martin Nessley",
    "rosters": [
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "77705": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 11, 1951",
    "EXP": "1",
    "SCHOOL": "Mississippi",
    "name": "Johnny Neumann",
    "rosters": [
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0"
    ]
  },
  "77706": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 30, 1938",
    "EXP": "2",
    "SCHOOL": "Stanford",
    "name": "Paul Neumann",
    "rosters": [
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "b4492284-7d41-45e4-b3d7-fd93667614a1",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a"
    ]
  },
  "77707": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "7-5",
    "WEIGHT": "217",
    "BIRTH_DATE": "JUN 13, 1959",
    "EXP": "4",
    "SCHOOL": "North Carolina State",
    "name": "Chuck Nevitt",
    "rosters": [
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "79d8e657-ce27-4052-9492-c9833808173f",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "ac4b5de2-f8d8-4a1a-bb9b-f1fb0f642388",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8"
    ]
  },
  "77710": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 02, 1949",
    "EXP": "10",
    "SCHOOL": "Utah",
    "name": "Mike Newlin",
    "rosters": [
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff"
    ]
  },
  "77712": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 11, 1946",
    "EXP": "R",
    "SCHOOL": "Columbia",
    "name": "Dave Newmark",
    "rosters": [
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "5ea792bd-890a-47b6-babe-2afabfd78f09"
    ]
  },
  "77713": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "222",
    "BIRTH_DATE": "APR 09, 1926",
    "EXP": "1",
    "SCHOOL": "Washington",
    "name": "Jack Nichols",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd",
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "0f305d34-33d7-4e16-a669-f7ab442c151d",
      "75b95e75-2397-48c9-b255-676792b7226d",
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "cf598b10-dc59-4143-a286-b7dfcbbb6989"
    ]
  },
  "77715": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 06, 1958",
    "EXP": "1",
    "SCHOOL": "Indiana State",
    "name": "Carl Nicks",
    "rosters": [
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5",
      "16161b6e-7707-4383-b96c-3f7cf17fe149"
    ]
  },
  "77716": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 02, 1946",
    "EXP": "R",
    "SCHOOL": "Saint Louis",
    "name": "Rich Niemann",
    "rosters": [
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00"
    ]
  },
  "77717": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "165",
    "BIRTH_DATE": "MAY 26, 1921",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "John Niemiera",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "77718": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 31, 1955",
    "EXP": "R",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Mike Niles",
    "rosters": [
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3"
    ]
  },
  "77719": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "218",
    "BIRTH_DATE": "MAR 13, 1958",
    "EXP": "5",
    "SCHOOL": "Arizona State",
    "name": "Kurt Nimphius",
    "rosters": [
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "999df20d-d256-45ec-8e64-111bdc7471d7"
    ]
  },
  "77720": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 11, 1967",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Dyron Nix",
    "rosters": [
      "ce5d240f-959a-498b-8cec-8d0416972633"
    ]
  },
  "77721": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "OCT 11, 1955",
    "EXP": "5",
    "SCHOOL": "Duquesne",
    "name": "Norm Nixon",
    "rosters": [
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "fff2243e-9747-44b5-8882-14d1d9d99603",
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "c3077e01-3d45-4725-9d9e-90a13761dbe8",
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba"
    ]
  },
  "77722": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 24, 1931",
    "EXP": "6",
    "SCHOOL": "Louisville",
    "name": "Chuck Noble",
    "rosters": [
      "0a699404-02aa-4953-8be2-08a5fc726ea3",
      "edb95a5d-c995-43f0-9378-d27051d259ab",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3",
      "03f980b2-d8d0-47cc-a777-71a16e029764",
      "29b33649-2e3d-444b-81b4-caec18163bdd",
      "a4934f64-d807-440b-9639-6e4052c2501e",
      "e6ca1168-2bff-4198-9727-665654e83794"
    ]
  },
  "77723": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 04, 1924",
    "EXP": "4",
    "SCHOOL": "Kentucky",
    "name": "Paul Noel",
    "rosters": [
      "bb996e83-b963-4fd6-b44b-484116ff5a4e",
      "df1fce20-7cea-47cc-8e99-cf6d772b3162",
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7",
      "6146894f-3e97-4aab-a027-77228d1955a6",
      "817867c3-ef94-48b9-81aa-768f4f8f758e"
    ]
  },
  "77724": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 09, 1927",
    "EXP": "R",
    "SCHOOL": "Georgia Tech",
    "name": "Jim Nolan",
    "rosters": [
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388"
    ]
  },
  "77727": {
    "NUM": "61",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 11, 1939",
    "EXP": "R",
    "SCHOOL": "Saint Louis",
    "name": "Robert Nordmann",
    "rosters": [
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "40748532-955b-4d14-ab45-0409adb5a075",
      "020d6aa2-861a-4e30-8595-c38b6904fb12"
    ]
  },
  "77729": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 24, 1953",
    "EXP": "1",
    "SCHOOL": "Arizona",
    "name": "Connie Norman",
    "rosters": [
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8",
      "41977d46-c447-4c82-8cb1-8952bd2b335e"
    ]
  },
  "77731": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 18, 1960",
    "EXP": "2",
    "SCHOOL": "Jackson State",
    "name": "Audie Norris",
    "rosters": [
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3"
    ]
  },
  "77732": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 18, 1957",
    "EXP": "R",
    "SCHOOL": "Jackson State",
    "name": "Sylvester Norris",
    "rosters": [
      "38ac7402-b81e-4254-b636-95330a997974"
    ]
  },
  "77733": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 08, 1947",
    "EXP": "6",
    "SCHOOL": "Alcorn State",
    "name": "Willie Norwood",
    "rosters": [
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c",
      "8718c236-d862-42de-a84d-ef29c83369b9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185",
      "94f36f95-2ce4-4f55-8a83-19b910b11438",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3"
    ]
  },
  "77734": {
    "NUM": "3",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 25, 1924",
    "EXP": "2",
    "SCHOOL": "Wyoming",
    "name": "George Nostrand",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "77735": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 19, 1920",
    "EXP": "2",
    "SCHOOL": "Duquesne",
    "name": "Stan Noszka",
    "rosters": [
      "e032e6bf-840a-444b-8044-7f6795db68e6",
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "77736": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "219",
    "BIRTH_DATE": "APR 23, 1915",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Mike Novak",
    "rosters": [
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388",
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8",
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9"
    ]
  },
  "77737": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "DEC 27, 1939",
    "EXP": "R",
    "SCHOOL": "Ohio State",
    "name": "Mel Nowell",
    "rosters": [
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "77738": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 25, 1963",
    "EXP": "R",
    "SCHOOL": "Texas Christian",
    "name": "Dennis Nutt",
    "rosters": [
      "4c8ca104-147e-44d8-9de3-0c6d6a888309"
    ]
  },
  "77742": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 07, 1928",
    "EXP": "R",
    "SCHOOL": "Colorado State",
    "name": "John O'Boyle",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd"
    ]
  },
  "77744": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 26, 1927",
    "EXP": "R",
    "SCHOOL": "Pepperdine",
    "name": "Bob O'Brien",
    "rosters": [
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a"
    ]
  },
  "77745": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "174",
    "BIRTH_DATE": "APR 13, 1928",
    "EXP": "R",
    "SCHOOL": "Holy Cross",
    "name": "Dermie O'Connell",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "77747": {
    "NUM": "28",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 29, 1946",
    "EXP": "1",
    "SCHOOL": "Santa Clara",
    "name": "Carlos Ogden",
    "rosters": [
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "8eeab17f-aa62-4d99-9e73-e5182968de63"
    ]
  },
  "77748": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 25, 1948",
    "EXP": "R",
    "SCHOOL": "Santa Clara",
    "name": "Ralph Ogden",
    "rosters": [
      "852d8746-a465-41f3-b644-085c99bbad27"
    ]
  },
  "77751": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 18, 1936",
    "EXP": "3",
    "SCHOOL": "Illinois",
    "name": "Don Ohl",
    "rosters": [
      "385be451-82b9-4142-849d-362d686d325b",
      "992d1ecc-4c9e-41e2-9a95-6165deedc409",
      "b204df04-9a5e-48f7-814f-b547b0690ab8",
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "a4934f64-d807-440b-9639-6e4052c2501e",
      "e6ca1168-2bff-4198-9727-665654e83794",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a"
    ]
  },
  "77753": {
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 16, 1926",
    "EXP": "R",
    "SCHOOL": "Georgetown",
    "name": "Tommy O'Keefe",
    "rosters": [
      "2e5c49fe-84b9-40c8-ae1f-a2a502758b96"
    ]
  },
  "77754": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "207",
    "BIRTH_DATE": "FEB 07, 1958",
    "EXP": "6",
    "SCHOOL": "North Carolina",
    "name": "Mike O'Koren",
    "rosters": [
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "4567ce1a-2538-43ab-b37e-44c2913ab119"
    ]
  },
  "77756": {
    "NUM": "53",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 21, 1956",
    "EXP": "5",
    "SCHOOL": "Minnesota",
    "name": "Mark Olberding",
    "rosters": [
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148",
      "38ac7402-b81e-4254-b636-95330a997974",
      "a6b98be6-4acd-49be-92b6-decd2522e4df",
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb"
    ]
  },
  "77757": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 04, 1957",
    "EXP": "7",
    "SCHOOL": "Seattle",
    "name": "Jawann Oldham",
    "rosters": [
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "77758": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 22, 1923",
    "EXP": "1",
    "SCHOOL": "Western Kentucky",
    "name": "Johnny Oldham",
    "rosters": [
      "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e",
      "648376a8-6ee8-4cad-97d2-369540020f50"
    ]
  },
  "77759": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 20, 1955",
    "EXP": "1",
    "SCHOOL": "Seattle",
    "name": "Frank Oleynick",
    "rosters": [
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c",
      "8718c236-d862-42de-a84d-ef29c83369b9"
    ]
  },
  "77760": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 01, 1955",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "John Olive",
    "rosters": [
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "ef880e29-8409-4464-a009-ac827384a450"
    ]
  },
  "77765": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 25, 1940",
    "EXP": "6",
    "SCHOOL": "Louisville",
    "name": "Enoch Olsen",
    "rosters": [
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "49db955c-2a3f-4693-b68f-834ab5003c4d"
    ]
  },
  "77766": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 25, 1948",
    "EXP": "R",
    "SCHOOL": "Manhattan",
    "name": "Grady O'Malley",
    "rosters": [
      "992d1ecc-4c9e-41e2-9a95-6165deedc409"
    ]
  },
  "77767": {
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 10, 1905",
    "EXP": "R",
    "SCHOOL": "California",
    "name": "Mike O'Neill",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd"
    ]
  },
  "77768": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 01, 1946",
    "EXP": "R",
    "SCHOOL": "Saint Louis",
    "name": "Barry Orms",
    "rosters": [
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938"
    ]
  },
  "77770": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 07, 1958",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Louis Orr",
    "rosters": [
      "6a10cc0f-c034-4cee-992e-4023a7280654",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917"
    ]
  },
  "77771": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 25, 1963",
    "EXP": "1",
    "SCHOOL": "Oregon State",
    "name": "Jose Ortiz",
    "rosters": [
      "eecb8d44-e66b-417f-bd80-36f97052a98a",
      "955486b2-508c-4776-884b-e521d5730bf8"
    ]
  },
  "77772": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 21, 1939",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Chuck Osborne",
    "rosters": [
      "b4492284-7d41-45e4-b3d7-fd93667614a1"
    ]
  },
  "77773": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 10, 1925",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Kevin O'Shea",
    "rosters": [
      "0c2ad895-0fc8-49c0-9976-48e929a0c370"
    ]
  },
  "77775": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 06, 1928",
    "EXP": "3",
    "SCHOOL": "Illinois",
    "name": "Wally Osterkorn",
    "rosters": [
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e",
      "b7540846-94ec-42ec-b008-ff241f8152ea",
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8",
      "bb00331b-e519-4f8f-b671-c89f34e10d57"
    ]
  },
  "77776": {
    "POSITION": null,
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAR 03, 1968",
    "EXP": "2",
    "SCHOOL": "Fordham",
    "name": "Dan O'Sullivan",
    "rosters": [
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "4a934d85-87c5-4059-9963-b76f50593f0b",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "77777": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "165",
    "BIRTH_DATE": "MAR 16, 1969",
    "EXP": "R",
    "SCHOOL": "Arizona",
    "name": "Matt Othick",
    "rosters": [
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90"
    ]
  },
  "77778": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 18, 1921",
    "EXP": "1",
    "SCHOOL": "Bowling Green",
    "name": "Don Otten",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699",
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd",
      "cf598b10-dc59-4143-a286-b7dfcbbb6989",
      "648376a8-6ee8-4cad-97d2-369540020f50"
    ]
  },
  "77780": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 16, 1927",
    "EXP": "R",
    "SCHOOL": "East Central",
    "name": "Claude Overton",
    "rosters": [
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "77782": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 26, 1953",
    "EXP": "R",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Eddie Owens",
    "rosters": [
      "0c82b7b8-0084-4eef-82c7-89296e03c73e"
    ]
  },
  "77783": {
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 02, 1925",
    "EXP": "1",
    "SCHOOL": "Baylor",
    "name": "James Owens",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "77784": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 01, 1950",
    "EXP": "1",
    "SCHOOL": "Arizona State",
    "name": "Jim Owens",
    "rosters": [
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792"
    ]
  },
  "77785": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 31, 1969",
    "EXP": "R",
    "SCHOOL": "UCLA",
    "name": "Keith Owens",
    "rosters": [
      "c3b8e442-4b20-42f1-99ae-03e014396f4a"
    ]
  },
  "77786": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 28, 1949",
    "EXP": "6",
    "SCHOOL": "South Carolina",
    "name": "Tom Owens",
    "rosters": [
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "4ac401d3-899b-427d-89d6-584fe69ebf75",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "f7a58b16-8257-42be-b944-16319d0abd63"
    ]
  },
  "77788": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 18, 1953",
    "EXP": "1",
    "SCHOOL": "Coppin State",
    "name": "Joe Pace",
    "rosters": [
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66",
      "dd527857-adb8-40c3-9804-8aa4212b8486"
    ]
  },
  "77789": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 21, 1965",
    "EXP": "2",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Gerald Paddio",
    "rosters": [
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "643eb0b6-e8a4-4b54-9c28-e76bcaae7520",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7"
    ]
  },
  "77791": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 08, 1932",
    "EXP": "5",
    "SCHOOL": "Holy Cross",
    "name": "Togo Palazzi",
    "rosters": [
      "0f305d34-33d7-4e16-a669-f7ab442c151d",
      "75b95e75-2397-48c9-b255-676792b7226d",
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "a45c9665-5951-47aa-ad90-2e2f872cee40"
    ]
  },
  "77792": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "224",
    "BIRTH_DATE": "JUN 08, 1933",
    "EXP": "R",
    "SCHOOL": "Dayton",
    "name": "Jim Palmer",
    "rosters": [
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "2fb8b948-e857-417f-8af2-422f0825a7c1",
      "dc41b99f-710f-4f81-b125-45b52cf09053"
    ]
  },
  "77793": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 14, 1921",
    "EXP": "2",
    "SCHOOL": "Princeton",
    "name": "John Palmer",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24",
      "bb996e83-b963-4fd6-b44b-484116ff5a4e",
      "df1fce20-7cea-47cc-8e99-cf6d772b3162"
    ]
  },
  "77794": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 23, 1968",
    "EXP": "R",
    "SCHOOL": "Dartmouth",
    "name": "Walter Palmer",
    "rosters": [
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff"
    ]
  },
  "77797": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 27, 1921",
    "EXP": "2",
    "SCHOOL": "Texas Wesleyan",
    "name": "Estes Parham",
    "rosters": [
      "78cf5ce6-637f-459d-812a-47c6b4615dc4"
    ]
  },
  "77799": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 11, 1933",
    "EXP": "4",
    "SCHOOL": "Missouri",
    "name": "Medford Park",
    "rosters": [
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b",
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "dc41b99f-710f-4f81-b125-45b52cf09053",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2"
    ]
  },
  "77800": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 22, 1955",
    "EXP": "5",
    "SCHOOL": "Texas A&M",
    "name": "Sonny Parker",
    "rosters": [
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444",
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9"
    ]
  },
  "77802": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 13, 1936",
    "EXP": "R",
    "SCHOOL": "Kansas State",
    "name": "Jack Parr",
    "rosters": [
      "dc41b99f-710f-4f81-b125-45b52cf09053"
    ]
  },
  "77804": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 13, 1925",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Charlie Parsley",
    "rosters": [
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388"
    ]
  },
  "77808": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 16, 1954",
    "EXP": "R",
    "SCHOOL": "Auburn",
    "name": "Myles Patrick",
    "rosters": [
      "9894db71-7342-451a-9249-92e7308dbbe9"
    ]
  },
  "77811": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 26, 1939",
    "EXP": "R",
    "SCHOOL": "Toledo",
    "name": "George Patterson",
    "rosters": [
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5"
    ]
  },
  "77812": {
    "NUM": "32-42",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 24, 1948",
    "EXP": "4",
    "SCHOOL": "California-Los Angeles",
    "name": "Steve Patterson",
    "rosters": [
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "18b07887-8de0-4728-924b-f6f6bca06f1d"
    ]
  },
  "77813": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 15, 1948",
    "EXP": "1",
    "SCHOOL": "Ouachita Baptist",
    "name": "Tommy Patterson",
    "rosters": [
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "024d58a0-dd09-4396-a891-82ba087f7279"
    ]
  },
  "77814": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 17, 1931",
    "EXP": "R",
    "SCHOOL": "Connecticut",
    "name": "Worthy Patterson",
    "rosters": [
      "ea187132-fedd-4cc9-a408-f8efdbf7552c"
    ]
  },
  "77815": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "219",
    "BIRTH_DATE": "JUN 14, 1946",
    "EXP": "1",
    "SCHOOL": "Northeastern State",
    "name": "Charlie Paulk",
    "rosters": [
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00",
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39"
    ]
  },
  "77816": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 21, 1935",
    "EXP": "R",
    "SCHOOL": "Manhattan",
    "name": "Jerry Paulson",
    "rosters": [
      "21936ffd-2bd4-4505-a647-b4cd447d157b"
    ]
  },
  "77817": {
    "NUM": "5",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 30, 1948",
    "EXP": "8",
    "SCHOOL": "St. John's (NY)",
    "name": "Billy Paultz",
    "rosters": [
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148",
      "38ac7402-b81e-4254-b636-95330a997974",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a"
    ]
  },
  "77818": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 19, 1932",
    "EXP": "1",
    "SCHOOL": "Dayton",
    "name": "Jim Paxson",
    "rosters": [
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8",
      "21936ffd-2bd4-4505-a647-b4cd447d157b"
    ]
  },
  "77819": {
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 09, 1957",
    "EXP": "7",
    "SCHOOL": "Dayton",
    "name": "Jim Paxson",
    "rosters": [
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "4ac401d3-899b-427d-89d6-584fe69ebf75",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2",
      "3367b969-0b6f-4aa4-927e-679d12463f2b"
    ]
  },
  "77820": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 29, 1960",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "John Paxson",
    "rosters": [
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "4478b73e-05fd-4148-a9c1-d3bf08421298",
      "d861b69c-544d-4879-b875-00604c148259",
      "c769ee1b-d32a-40c5-b659-0bc1f7f699b4",
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "8affb09c-9c6b-47cb-b77c-d44e08d962bc",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "5c09ec26-5505-4508-98c5-db244798d9d1"
    ]
  },
  "77821": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "174",
    "BIRTH_DATE": "NOV 20, 1926",
    "EXP": "1",
    "SCHOOL": "Bowling Green",
    "name": "Johnny Payak",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd"
    ]
  },
  "77822": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 25, 1966",
    "EXP": "3",
    "SCHOOL": "Louisville",
    "name": "Kenny Payne",
    "rosters": [
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "f646b106-33f9-49c7-9873-99a35bf12813"
    ]
  },
  "77823": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 19, 1950",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Tom Payne",
    "rosters": [
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6"
    ]
  },
  "77824": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 16, 1926",
    "EXP": "R",
    "SCHOOL": "Tulane",
    "name": "Mel Payton",
    "rosters": [
      "1c190e61-1a8b-4cd1-aba2-fae468830890"
    ]
  },
  "77827": {
    "NUM": "54",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 15, 1957",
    "EXP": "R",
    "SCHOOL": "Mississippi State",
    "name": "Wiley Peck",
    "rosters": [
      "38ac7402-b81e-4254-b636-95330a997974"
    ]
  },
  "77828": {
    "NUM": "30",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 03, 1916",
    "EXP": "R",
    "SCHOOL": "Manhattan",
    "name": "Jake Pelkington",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "77829": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 02, 1951",
    "EXP": "3",
    "SCHOOL": "Buffalo State",
    "name": "Sam Pellom",
    "rosters": [
      "0931529d-0881-4f63-b629-4c68ef624474",
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "48d933d7-4450-441c-8ef1-2d743521a134"
    ]
  },
  "77834": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 02, 1924",
    "EXP": "1",
    "SCHOOL": "Tulane",
    "name": "Warren Perkins",
    "rosters": [
      "6d008515-8165-4990-a179-942eeec92822",
      "e8e283cb-e932-4006-a2e4-decb30f70f3b"
    ]
  },
  "77835": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 13, 1948",
    "EXP": "7",
    "SCHOOL": "Missouri State",
    "name": "Curtis Perry",
    "rosters": [
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de"
    ]
  },
  "77839": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "FEB 22, 1962",
    "EXP": "4",
    "SCHOOL": "Minnesota",
    "name": "Jim Petersen",
    "rosters": [
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "65fe3c0f-07c5-469c-b786-9542d2d55742"
    ]
  },
  "77840": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 26, 1945",
    "EXP": "1",
    "SCHOOL": "Oregon State",
    "name": "Loy Petersen",
    "rosters": [
      "5ea792bd-890a-47b6-babe-2afabfd78f09",
      "9f64bae2-055b-4727-8d9e-dee954e0bd10"
    ]
  },
  "77841": {
    "NUM": "13-50",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 27, 1924",
    "EXP": "R",
    "SCHOOL": "Cornell",
    "name": "Ed Peterson",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71"
    ]
  },
  "77842": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 23, 1938",
    "EXP": "R",
    "SCHOOL": "Wheaton (IL)",
    "name": "Mel Peterson",
    "rosters": [
      "fa4aff89-3225-4265-8365-6d1dcb219e6b"
    ]
  },
  "77843": {
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 25, 1932",
    "EXP": "2",
    "SCHOOL": "Oregon",
    "name": "Bob Peterson",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8"
    ],
    "NUM": "9"
  },
  "77844": {
    "NUM": "45",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 17, 1948",
    "EXP": "5",
    "SCHOOL": "Princeton",
    "name": "Geoff Petrie",
    "rosters": [
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4",
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "cbf01feb-6986-43c2-8350-23e707cf8272"
    ]
  },
  "77845": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 22, 1964",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Drazen Petrovic",
    "rosters": [
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "24e281c7-c9c7-4698-ae12-a3104e79e69f",
      "45b3a59c-7578-494d-9525-3352a060f871",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6"
    ]
  },
  "77846": {
    "NUM": "3",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "JAN 25, 1969",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Richard Petruska",
    "rosters": [
      "a98c4621-6e20-4474-b8b9-b92e12d377b0"
    ]
  },
  "77847": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 12, 1932",
    "EXP": "10",
    "SCHOOL": "Louisiana State",
    "name": "Bob Pettit",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800",
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b",
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e"
    ]
  },
  "77848": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 16, 1956",
    "EXP": "R",
    "SCHOOL": "Bradley",
    "name": "Roger Phegley",
    "rosters": [
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0"
    ]
  },
  "77849": {
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 19, 1929",
    "EXP": "R",
    "SCHOOL": "La Salle",
    "name": "Jim Phelan",
    "rosters": [
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f"
    ]
  },
  "77852": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 03, 1961",
    "EXP": "1",
    "SCHOOL": "Alcorn State",
    "name": "Mike Phelps",
    "rosters": [
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "0d39d553-7b93-4206-b525-d87f764e4d5d",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6"
    ]
  },
  "77853": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 07, 1922",
    "EXP": "8",
    "SCHOOL": "Illinois",
    "name": "Andy Phillip",
    "rosters": [
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "78cf5ce6-637f-459d-812a-47c6b4615dc4",
      "1c190e61-1a8b-4cd1-aba2-fae468830890",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e",
      "57494c03-afc0-41de-b544-659990d5e952",
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91",
      "0a699404-02aa-4953-8be2-08a5fc726ea3"
    ]
  },
  "77854": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 29, 1961",
    "EXP": "R",
    "SCHOOL": "Alabama",
    "name": "Eddie Phillips",
    "rosters": [
      "180d7461-2299-4b8c-9aef-267bf4d09d28"
    ]
  },
  "77855": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "189",
    "BIRTH_DATE": "DEC 07, 1939",
    "EXP": "4",
    "SCHOOL": "Houston",
    "name": "Gary Phillips",
    "rosters": [
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "754f9d27-7573-442d-8f17-5aeb6cb61e54"
    ]
  },
  "77858": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 14, 1956",
    "EXP": "1",
    "SCHOOL": "Auburn",
    "name": "Stan Pietkiewicz",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "ef880e29-8409-4464-a009-ac827384a450"
    ]
  },
  "77859": {
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 11, 1925",
    "EXP": "R",
    "SCHOOL": "Wyoming",
    "name": "John Pilch",
    "rosters": [
      "56568a86-b30e-48a7-9f7d-80f79aa00339"
    ]
  },
  "77861": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 19, 1961",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "John Pinone",
    "rosters": [
      "234ba200-c395-49b7-8b55-38dbd3fb9d66"
    ]
  },
  "77862": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 27, 1934",
    "EXP": "5",
    "SCHOOL": "Xavier",
    "name": "Dave Piontek",
    "rosters": [
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b",
      "21936ffd-2bd4-4505-a647-b4cd447d157b",
      "dc41b99f-710f-4f81-b125-45b52cf09053",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "e5799d65-732c-4105-b48e-fe6c0e46eee6"
    ]
  },
  "77863": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 17, 1960",
    "EXP": "R",
    "SCHOOL": "La Salle",
    "name": "Tom Piotrowski",
    "rosters": [
      "ce9f3810-9f45-4e5a-8479-92c52c78194c"
    ]
  },
  "77864": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 23, 1958",
    "EXP": "3",
    "SCHOOL": "Maryland",
    "name": "Charlie Pittman",
    "rosters": [
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f"
    ]
  },
  "77865": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 21, 1962",
    "EXP": "R",
    "SCHOOL": "Boston University",
    "name": "Gary Plummer",
    "rosters": [
      "5f767836-141f-43d2-8d66-70390ca5907c",
      "d4f7a688-d761-4a62-88cf-2893af24c272"
    ]
  },
  "77866": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 02, 1963",
    "EXP": "R",
    "SCHOOL": "Pepperdine",
    "name": "Dwayne Polee",
    "rosters": [
      "a8878857-2e27-4452-a526-4d80a3f6b73a"
    ]
  },
  "77867": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 09, 1922",
    "EXP": "6",
    "SCHOOL": "Stanford",
    "name": "Jim Pollard",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503",
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9",
      "0c2ad895-0fc8-49c0-9976-48e929a0c370",
      "56568a86-b30e-48a7-9f7d-80f79aa00339",
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2",
      "1b383d65-6ca5-46ca-b345-acf8e129e382",
      "22dd26a7-1c99-45b7-a759-dce48ccb6829"
    ]
  },
  "77868": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 26, 1929",
    "EXP": "R",
    "SCHOOL": "Whitworth",
    "name": "Ralph Polson",
    "rosters": [
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "77869": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "233",
    "BIRTH_DATE": "SEP 15, 1954",
    "EXP": "2",
    "SCHOOL": "Long Beach State",
    "name": "Cliff Pondexter",
    "rosters": [
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7"
    ]
  },
  "77870": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 15, 1962",
    "EXP": "1",
    "SCHOOL": "Norfolk State",
    "name": "David Pope",
    "rosters": [
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "0d39d553-7b93-4206-b525-d87f764e4d5d"
    ]
  },
  "77872": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 17, 1964",
    "EXP": "2",
    "SCHOOL": "North Carolina",
    "name": "Dave Popson",
    "rosters": [
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "652536cb-d53b-4de7-a147-555aacb21048",
      "da7fed67-9107-4124-becb-84e8169908af"
    ]
  },
  "77873": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 07, 1955",
    "EXP": "1",
    "SCHOOL": "Central Michigan",
    "name": "Ben Poquette",
    "rosters": [
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba",
      "79272c8a-a0ac-4650-b0a6-e971f46652f5",
      "16161b6e-7707-4383-b96c-3f7cf17fe149",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "77875": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 31, 1948",
    "EXP": "5",
    "SCHOOL": "Villanova",
    "name": "Howard Porter",
    "rosters": [
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f"
    ]
  },
  "77876": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 17, 1950",
    "EXP": "6",
    "SCHOOL": "Saint Francis (PA)",
    "name": "Kevin Porter",
    "rosters": [
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "024d58a0-dd09-4396-a891-82ba087f7279",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409",
      "a52d7bc3-ed81-4836-b70b-85c958af2548",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "77878": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 22, 1947",
    "EXP": "3",
    "SCHOOL": "Creighton",
    "name": "Bob Portman",
    "rosters": [
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "852d8746-a465-41f3-b644-085c99bbad27",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4"
    ]
  },
  "77879": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 24, 1958",
    "EXP": "9",
    "SCHOOL": "Tulsa",
    "name": "Paul Pressey",
    "rosters": [
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "f2ab4bf8-6633-44d4-a4c5-c7fdf974a663",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "c5fcd661-3f22-42bc-99bf-414a346732e2",
      "61f90119-05dd-4779-bfd7-fcf297701a65"
    ]
  },
  "77880": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAY 30, 1964",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "Dominic Pressley",
    "rosters": [
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c"
    ]
  },
  "77881": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 14, 1963",
    "EXP": "3",
    "SCHOOL": "Villanova",
    "name": "Harold Pressley",
    "rosters": [
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47",
      "65fe3c0f-07c5-469c-b786-9542d2d55742",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff"
    ]
  },
  "77882": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 05, 1957",
    "EXP": "R",
    "SCHOOL": "Pennsylvania",
    "name": "Tony Price",
    "rosters": [
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528"
    ]
  },
  "77883": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 27, 1949",
    "EXP": "5",
    "SCHOOL": "Louisville",
    "name": "Jim Price",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "c39c5026-a493-40db-84cd-272c3b126805",
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3"
    ]
  },
  "77884": {
    "NUM": "36",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 11, 1948",
    "EXP": "2",
    "SCHOOL": "Illinois",
    "name": "Mike Price",
    "rosters": [
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada"
    ]
  },
  "77890": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 23, 1966",
    "EXP": "R",
    "SCHOOL": "McNeese State",
    "name": "Anthony Pullard",
    "rosters": [
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39"
    ]
  },
  "77892": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 05, 1946",
    "EXP": "3",
    "SCHOOL": "Xavier",
    "name": "Bob Quick",
    "rosters": [
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "d80f75e6-baf7-4843-8122-aa743aea3477",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226"
    ]
  },
  "77893": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 30, 1966",
    "EXP": "1",
    "SCHOOL": "Washington State",
    "name": "Brian Quinnett",
    "rosters": [
      "0ac01206-d8e9-4609-aff9-f970a8acfada",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a"
    ]
  },
  "77894": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 11, 1946",
    "EXP": "R",
    "SCHOOL": "Xavier",
    "name": "Luke Rackley",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1",
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa"
    ]
  },
  "77896": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 05, 1959",
    "EXP": "1",
    "SCHOOL": "Oregon State",
    "name": "Mark Radford",
    "rosters": [
      "9cf046e5-f475-41ef-a3d3-f517368a157b",
      "a59119a0-b26d-4a60-b162-d2df226404ce"
    ]
  },
  "77897": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAY 29, 1956",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Wayne Radford",
    "rosters": [
      "b53d3c18-a4f1-40bf-a266-2555fa606018"
    ]
  },
  "77899": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 03, 1938",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Frank Radovich",
    "rosters": [
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5"
    ]
  },
  "77900": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "160",
    "BIRTH_DATE": "MAY 05, 1929",
    "EXP": "R",
    "SCHOOL": "Wyoming",
    "name": "Moe Radovich",
    "rosters": [
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "77901": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 01, 1935",
    "EXP": "R",
    "SCHOOL": "Saint Joseph's",
    "name": "Ray Radziszewski",
    "rosters": [
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447"
    ]
  },
  "77902": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 10, 1928",
    "EXP": "R",
    "SCHOOL": "Northwestern",
    "name": "Ray Ragelis",
    "rosters": [
      "817867c3-ef94-48b9-81aa-768f4f8f758e"
    ]
  },
  "77903": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 29, 1928",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Sherwin Raiken",
    "rosters": [
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac"
    ]
  },
  "77904": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 24, 1956",
    "EXP": "1",
    "SCHOOL": "South Alabama",
    "name": "Ed Rains",
    "rosters": [
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661"
    ]
  },
  "77905": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "213",
    "BIRTH_DATE": "FEB 25, 1958",
    "EXP": "7",
    "SCHOOL": "Santa Clara",
    "name": "Kurt Rambis",
    "rosters": [
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd",
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "761250ad-879b-4092-a761-56abea7f36ad",
      "227615b9-c36f-451e-a0ec-5ef4a2dd81fa",
      "1a846bd5-9033-48bc-b23f-c363d42f51c8",
      "fb8dc3d1-b04b-4ae8-9db9-4a2a1561b4bb",
      "87bc76d9-706c-4de2-aa16-82991b201ea2",
      "ff6c6820-d86d-4919-8ee9-40bb82cc1f4d",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3"
    ]
  },
  "77906": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 13, 1937",
    "EXP": "1",
    "SCHOOL": "New York University",
    "name": "Cal Ramsey",
    "rosters": [
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2"
    ]
  },
  "77907": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 13, 1931",
    "EXP": "8",
    "SCHOOL": "Kentucky",
    "name": "Frank Ramsey",
    "rosters": [
      "0f305d34-33d7-4e16-a669-f7ab442c151d",
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7",
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "3e94e421-e072-4018-95e3-f183762c70eb"
    ]
  },
  "77910": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 01, 1958",
    "EXP": "R",
    "SCHOOL": "San Jose State",
    "name": "Wally Rank",
    "rosters": [
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528"
    ]
  },
  "77911": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAY 03, 1958",
    "EXP": "1",
    "SCHOOL": "Ohio State",
    "name": "Kelvin Ransey",
    "rosters": [
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f"
    ]
  },
  "77912": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 21, 1927",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Sam Ranzino",
    "rosters": [
      "817867c3-ef94-48b9-81aa-768f4f8f758e"
    ]
  },
  "77913": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 13, 1922",
    "EXP": "2",
    "SCHOOL": null,
    "name": "George Ratkovicz",
    "rosters": [
      "49e76fee-37ca-43ec-96a3-5c7f6b69b4dd",
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "d734ea86-9401-4fa7-846a-e69cc31d9800",
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "98673c29-08ee-48ce-9891-81b0ab92d951",
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e"
    ]
  },
  "77914": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 29, 1950",
    "EXP": "4",
    "SCHOOL": "Long Beach State",
    "name": "Ed Ratleff",
    "rosters": [
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff"
    ]
  },
  "77915": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUN 07, 1951",
    "EXP": "1",
    "SCHOOL": "Wisconsin-Eau Claire",
    "name": "Mike Ratliff",
    "rosters": [
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba"
    ]
  },
  "77916": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 20, 1960",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Leo Rautins",
    "rosters": [
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "cf936114-2a00-471e-8491-371c9e98f705"
    ]
  },
  "77917": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 21, 1949",
    "EXP": "9",
    "SCHOOL": "Oklahoma",
    "name": "Clifford Ray",
    "rosters": [
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444",
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "6af54433-9189-4d97-aafc-8c3d7350a043"
    ]
  },
  "77918": {
    "NUM": "96",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 08, 1921",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Don Ray",
    "rosters": [
      "6d008515-8165-4990-a179-942eeec92822"
    ]
  },
  "77919": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 12, 1934",
    "EXP": "1",
    "SCHOOL": "Toledo",
    "name": "Jim Ray",
    "rosters": [
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "a45c9665-5951-47aa-ad90-2e2f872cee40"
    ]
  },
  "77920": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 27, 1957",
    "EXP": "2",
    "SCHOOL": "Jacksonville",
    "name": "Jamesearl Ray",
    "rosters": [
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e"
    ]
  },
  "77921": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 05, 1945",
    "EXP": "R",
    "SCHOOL": "Brigham Young",
    "name": "Craig Raymond",
    "rosters": [
      "e08b4914-59a6-4d7e-836c-db576c3c3723"
    ]
  },
  "77923": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 27, 1950",
    "EXP": "R",
    "SCHOOL": "Bethel (TN)",
    "name": "Joe Reaves",
    "rosters": [
      "bd877b88-34bc-4231-95a1-6063a90eca41"
    ]
  },
  "77925": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 14, 1905",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Frank Reddout",
    "rosters": [
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6"
    ]
  },
  "77926": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "188",
    "BIRTH_DATE": "APR 15, 1955",
    "EXP": "1",
    "SCHOOL": "San Francisco",
    "name": "Marlon Redmond",
    "rosters": [
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "1df46296-cc39-4688-95c6-3656430e4ae2"
    ]
  },
  "77927": {
    "NUM": "20-23",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 04, 1936",
    "EXP": "6",
    "SCHOOL": "Oklahoma City",
    "name": "Hub Reed",
    "rosters": [
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce",
      "020d6aa2-861a-4e30-8595-c38b6904fb12",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "3902314e-3111-45fa-9433-86d9fdae07db"
    ]
  },
  "77928": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 02, 1942",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Ron Reed",
    "rosters": [
      "61110628-8f7d-478f-b171-52455d3bf9d0",
      "65f693c5-b99c-4553-b1db-458345ed8c34"
    ]
  },
  "77929": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 25, 1942",
    "EXP": "9",
    "SCHOOL": "Grambling",
    "name": "Willis Reed",
    "rosters": [
      "7c3c8b10-137d-4f0f-8249-452818bc279f",
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2"
    ]
  },
  "77932": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 30, 1930",
    "EXP": "2",
    "SCHOOL": "Seton Hall",
    "name": "Richie Regan",
    "rosters": [
      "516c9243-1276-46d0-8dbe-f1db0a8384ad",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b",
      "21936ffd-2bd4-4505-a647-b4cd447d157b"
    ]
  },
  "77933": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 07, 1927",
    "EXP": "1",
    "SCHOOL": "Wisconsin",
    "name": "Don Rehfeldt",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "77935": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 03, 1945",
    "EXP": "R",
    "SCHOOL": "Winston-Salem State",
    "name": "Jim Reid",
    "rosters": [
      "56bc558b-98d9-4a75-9033-fbec8e9fe666"
    ]
  },
  "77936": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 30, 1955",
    "EXP": "11",
    "SCHOOL": "St. Mary's (TX)",
    "name": "Robert Reid",
    "rosters": [
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "77937": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 10, 1957",
    "EXP": "R",
    "SCHOOL": "San Francisco",
    "name": "Billy Reid",
    "rosters": [
      "6af54433-9189-4d97-aafc-8c3d7350a043"
    ]
  },
  "77939": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 16, 1964",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Richard Rellford",
    "rosters": [
      "473f0beb-d4eb-46a7-b66f-d6831084a451"
    ]
  },
  "77941": {
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 11, 1929",
    "EXP": "R",
    "SCHOOL": "Drake",
    "name": "John Rennicke",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "77944": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 23, 1951",
    "EXP": "6",
    "SCHOOL": "San Francisco",
    "name": "Kevin Restani",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "743aba57-7e93-4575-8cb7-e3de53d6223c",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "38ac7402-b81e-4254-b636-95330a997974",
      "a6b98be6-4acd-49be-92b6-decd2522e4df"
    ]
  },
  "77945": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 23, 1947",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "George Reynolds",
    "rosters": [
      "d80f75e6-baf7-4843-8122-aa743aea3477"
    ]
  },
  "77946": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 23, 1962",
    "EXP": "3",
    "SCHOOL": "Louisiana State",
    "name": "Jerry Reynolds",
    "rosters": [
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "ff0c3b72-c576-4f9d-9a5a-c0066f0b5458",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf"
    ]
  },
  "77950": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 07, 1956",
    "EXP": "5",
    "SCHOOL": "Seattle",
    "name": "Clint Richardson",
    "rosters": [
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332"
    ]
  },
  "77952": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "189",
    "BIRTH_DATE": "APR 11, 1955",
    "EXP": "3",
    "SCHOOL": "Montana",
    "name": "Micheal Ray Richardson",
    "rosters": [
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "b001782c-95ad-444b-814b-98ecf23fa9ce",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff"
    ]
  },
  "77954": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 12, 1937",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "John Richter",
    "rosters": [
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6"
    ]
  },
  "77955": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 04, 1933",
    "EXP": "2",
    "SCHOOL": "Duquesne",
    "name": "Dick Ricketts",
    "rosters": [
      "516c9243-1276-46d0-8dbe-f1db0a8384ad",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b",
      "21936ffd-2bd4-4505-a647-b4cd447d157b"
    ]
  },
  "77957": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 13, 1948",
    "EXP": "R",
    "SCHOOL": "California",
    "name": "Jackie Ridgle",
    "rosters": [
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca"
    ]
  },
  "77958": {
    "NUM": "7-9",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 12, 1916",
    "EXP": "1",
    "SCHOOL": "Wooster",
    "name": "Mel Riebe",
    "rosters": [
      "e032e6bf-840a-444b-8044-7f6795db68e6"
    ]
  },
  "77959": {
    "NUM": "9-17",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 14, 1923",
    "EXP": "R",
    "SCHOOL": "Tulane",
    "name": "Jim Riffey",
    "rosters": [
      "648376a8-6ee8-4cad-97d2-369540020f50"
    ]
  },
  "77960": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 28, 1950",
    "EXP": "2",
    "SCHOOL": "South Carolina",
    "name": "Tom Riker",
    "rosters": [
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa"
    ]
  },
  "77962": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 20, 1945",
    "EXP": "8",
    "SCHOOL": "Kentucky",
    "name": "Pat Riley",
    "rosters": [
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "c39c5026-a493-40db-84cd-272c3b126805",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb"
    ]
  },
  "77963": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 06, 1948",
    "EXP": "R",
    "SCHOOL": "Mount St. Mary's",
    "name": "Bob Riley",
    "rosters": [
      "fa657246-a721-4d64-b321-9a6be5364290"
    ]
  },
  "77964": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 11, 1950",
    "EXP": "R",
    "SCHOOL": "Southern California",
    "name": "Ron Riley",
    "rosters": [
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60"
    ]
  },
  "77965": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 03, 1949",
    "EXP": "2",
    "SCHOOL": "St. Peter's",
    "name": "Rich Rinaldi",
    "rosters": [
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "024d58a0-dd09-4396-a891-82ba087f7279"
    ]
  },
  "77966": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 09, 1945",
    "EXP": "8",
    "SCHOOL": "Providence",
    "name": "Mike Riordan",
    "rosters": [
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "024d58a0-dd09-4396-a891-82ba087f7279",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "fd666397-8775-4cb6-9c9b-67881c31f005",
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66"
    ]
  },
  "77967": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 09, 1924",
    "EXP": "6",
    "SCHOOL": "Ohio State",
    "name": "Arnie Risen",
    "rosters": [
      "75b95e75-2397-48c9-b255-676792b7226d",
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0",
      "6146894f-3e97-4aab-a027-77228d1955a6",
      "817867c3-ef94-48b9-81aa-768f4f8f758e",
      "a0311ade-2019-4595-ab92-26a1696f3ce7",
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6",
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af"
    ]
  },
  "77968": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 26, 1924",
    "EXP": "2",
    "SCHOOL": "Eastern Kentucky",
    "name": "Goebel Ritter",
    "rosters": [
      "df1fce20-7cea-47cc-8e99-cf6d772b3162",
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04"
    ]
  },
  "77969": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUN 03, 1966",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Ramon Rivas",
    "rosters": [
      "a00084cd-698b-4fed-9bea-3d9275c16bfa"
    ]
  },
  "77970": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "JAN 20, 1965",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "David Rivers",
    "rosters": [
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "4c991cf6-4082-4074-822a-6c24976b3fc2"
    ]
  },
  "77973": {
    "NUM": "30",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "231",
    "BIRTH_DATE": "JUL 07, 1947",
    "EXP": "5",
    "SCHOOL": "Cincinnati",
    "name": "Rick Roberson",
    "rosters": [
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "77975": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 15, 1955",
    "EXP": "3",
    "SCHOOL": "Oral Roberts",
    "name": "Anthony Roberts",
    "rosters": [
      "66743650-e493-4c91-a3ab-5a488a063464",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "77977": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "214",
    "BIRTH_DATE": "MAY 18, 1936",
    "EXP": "2",
    "SCHOOL": "Ohio State",
    "name": "Joe Roberts",
    "rosters": [
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2",
      "b4492284-7d41-45e4-b3d7-fd93667614a1",
      "1ac58220-677b-4b39-88b8-e0f140cc4134"
    ]
  },
  "77978": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 29, 1950",
    "EXP": "R",
    "SCHOOL": "Utah State",
    "name": "Marv Roberts",
    "rosters": [
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77"
    ]
  },
  "77983": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 01, 1956",
    "EXP": "1",
    "SCHOOL": "West Virginia",
    "name": "Tony Robertson",
    "rosters": [
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10"
    ]
  },
  "77984": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 30, 1956",
    "EXP": "7",
    "SCHOOL": "Kentucky",
    "name": "Rick Robey",
    "rosters": [
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "8c38cbed-2fae-4bea-85ca-d5701da62ce7",
      "8b6ca41c-c301-41d7-871a-28f9f9b3f8bd",
      "928904ed-6459-463d-af51-942ab0d40da2",
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f"
    ]
  },
  "77986": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 13, 1960",
    "EXP": "6",
    "SCHOOL": "Southern California",
    "name": "Cliff T. Robinson",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "77988": {
    "NUM": "21-30",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 28, 1941",
    "EXP": "4",
    "SCHOOL": "Wyoming",
    "name": "Flynn Robinson",
    "rosters": [
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00",
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "38c6a8b5-9c04-429f-9873-476923c33722",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39"
    ]
  },
  "77989": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 20, 1955",
    "EXP": "1",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Jackie Robinson",
    "rosters": [
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "77993": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 04, 1951",
    "EXP": "1",
    "SCHOOL": "Tennessee State",
    "name": "Truck Robinson",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "6a10cc0f-c034-4cee-992e-4023a7280654",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "5517a85a-4623-414c-89a6-b90bb5a42eca",
      "a8b9d60d-1449-49af-89fc-945fadd03a04",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "fd666397-8775-4cb6-9c9b-67881c31f005"
    ]
  },
  "77994": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 13, 1960",
    "EXP": "R",
    "SCHOOL": "Alabama-Birmingham",
    "name": "Oliver Robinson",
    "rosters": [
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661"
    ]
  },
  "77996": {
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "217",
    "BIRTH_DATE": "APR 19, 1958",
    "EXP": "R",
    "SCHOOL": "Virginia Tech",
    "name": "Wayne Robinson",
    "rosters": [
      "c163d65f-d6fd-4a44-968a-2897543255d4"
    ]
  },
  "77997": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 20, 1953",
    "EXP": "6",
    "SCHOOL": "DePaul",
    "name": "Bill Robinzine",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "16161b6e-7707-4383-b96c-3f7cf17fe149"
    ]
  },
  "77998": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "DEC 22, 1949",
    "EXP": "7",
    "SCHOOL": "Kansas",
    "name": "Dave Robisch",
    "rosters": [
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7"
    ]
  },
  "77999": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 18, 1923",
    "EXP": "8",
    "SCHOOL": "Oregon State",
    "name": "Red Rocha",
    "rosters": [
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e",
      "b7540846-94ec-42ec-b008-ff241f8152ea",
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "edb95a5d-c995-43f0-9378-d27051d259ab"
    ]
  },
  "78000": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "170",
    "BIRTH_DATE": "SEP 26, 1949",
    "EXP": "R",
    "SCHOOL": "South Carolina",
    "name": "John Roche",
    "rosters": [
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb"
    ]
  },
  "78002": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 12, 1922",
    "EXP": "R",
    "SCHOOL": "California",
    "name": "Jack Rocker",
    "rosters": [
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a"
    ]
  },
  "78003": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 01, 1935",
    "EXP": "9",
    "SCHOOL": "Temple",
    "name": "Guy Rodgers",
    "rosters": [
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae",
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5",
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00",
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "b630b545-ede0-408a-a797-133d5fdeaa10"
    ]
  },
  "78007": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 30, 1963",
    "EXP": "R",
    "SCHOOL": "California-Irvine",
    "name": "Johnny Rogers",
    "rosters": [
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22"
    ]
  },
  "78008": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 27, 1953",
    "EXP": "R",
    "SCHOOL": "Texas-Pan American",
    "name": "Marshall Rogers",
    "rosters": [
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444"
    ]
  },
  "78009": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "AUG 19, 1973",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Roy Rogers",
    "rosters": [
      "75827e5b-1c4c-4a63-8f07-fc61069a6e50",
      "b884431e-1319-4251-a977-cdd919c3468e"
    ]
  },
  "78010": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 25, 1930",
    "EXP": "1",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Al Roges",
    "rosters": [
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91"
    ]
  },
  "78011": {
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 18, 1939",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Ken Rohloff",
    "rosters": [
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479"
    ]
  },
  "78012": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "168",
    "BIRTH_DATE": "SEP 14, 1923",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Kenny Rollins",
    "rosters": [
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b"
    ]
  },
  "78013": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 19, 1934",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Phil Rollins",
    "rosters": [
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "2fb8b948-e857-417f-8af2-422f0825a7c1",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2"
    ]
  },
  "78014": {
    "NUM": "30",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 16, 1955",
    "EXP": "13",
    "SCHOOL": "Clemson",
    "name": "Tree Rollins",
    "rosters": [
      "63f3df89-660b-46e0-ab32-a6754eb78888",
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474",
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "4f39cfda-e726-4c4d-9553-4328412edaf3",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "73a256d9-f328-424c-a259-734aa5e88c4c"
    ]
  },
  "78015": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 13, 1958",
    "EXP": "4",
    "SCHOOL": "Washington",
    "name": "Lorenzo Romar",
    "rosters": [
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17"
    ]
  },
  "78016": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 27, 1964",
    "EXP": "R",
    "SCHOOL": "George Mason",
    "name": "Robert Rose",
    "rosters": [
      "03a36ce1-3d63-4f28-b362-56b1356f738d"
    ]
  },
  "78017": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "165",
    "BIRTH_DATE": "APR 07, 1918",
    "EXP": "R",
    "SCHOOL": "Saint Joseph's",
    "name": "Alexander Rosenberg",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c"
    ]
  },
  "78018": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 22, 1933",
    "EXP": "1",
    "SCHOOL": "North Carolina",
    "name": "Lennie Rosenbluth",
    "rosters": [
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447",
      "dfaf5774-af9e-4334-82a0-3587b2230530"
    ]
  },
  "78020": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 20, 1933",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Dick Rosenthal",
    "rosters": [
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91",
      "edb95a5d-c995-43f0-9378-d27051d259ab"
    ]
  },
  "78021": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "AUG 24, 1967",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Doug Roth",
    "rosters": [
      "44e38364-f6e8-4d07-bb01-e7f82356367c"
    ]
  },
  "78022": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "212",
    "BIRTH_DATE": "JUN 03, 1963",
    "EXP": "R",
    "SCHOOL": "Wisconsin",
    "name": "Scott Roth",
    "rosters": [
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f"
    ]
  },
  "78023": {
    "NUM": "3",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 31, 1921",
    "EXP": "1",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Irv Rothenberg",
    "rosters": [
      "df1fce20-7cea-47cc-8e99-cf6d772b3162",
      "21aca949-94aa-48fb-ace9-f2a8b20b1e77"
    ]
  },
  "78025": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAY 26, 1953",
    "EXP": "8",
    "SCHOOL": "Central Michigan",
    "name": "Dan Roundfield",
    "rosters": [
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474",
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "355ade3f-1412-4279-a7f6-512d2936bf86",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17"
    ]
  },
  "78027": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 23, 1962",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Ron Rowan",
    "rosters": [
      "3367b969-0b6f-4aa4-927e-679d12463f2b"
    ]
  },
  "78028": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 02, 1949",
    "EXP": "4",
    "SCHOOL": "California-Los Angeles",
    "name": "Curtis Rowe",
    "rosters": [
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185",
      "94f36f95-2ce4-4f55-8a83-19b910b11438",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b"
    ]
  },
  "78029": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "250",
    "BIRTH_DATE": "JAN 04, 1961",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Jim Rowinski",
    "rosters": [
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda"
    ]
  },
  "78030": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 21, 1959",
    "EXP": "R",
    "SCHOOL": "Potsdam",
    "name": "Derrick Rowland",
    "rosters": [
      "fac543c5-7a72-481e-87a4-e3d382c18eec"
    ]
  },
  "78031": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 23, 1965",
    "EXP": "2",
    "SCHOOL": "North Carolina-Wilmington",
    "name": "Brian Rowsom",
    "rosters": [
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "78035": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 08, 1962",
    "EXP": "2",
    "SCHOOL": "Wake Forest",
    "name": "Delaney Rudd",
    "rosters": [
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "955486b2-508c-4776-884b-e521d5730bf8",
      "05c9fbb0-bdb9-4253-bbcb-347adf349bff",
      "e34e570c-e94f-47f1-927d-d070fd2321f8"
    ]
  },
  "78036": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 07, 1955",
    "EXP": "R",
    "SCHOOL": "McNeese State",
    "name": "John Rudd",
    "rosters": [
      "b001782c-95ad-444b-814b-98ecf23fa9ce"
    ]
  },
  "78037": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 06, 1940",
    "EXP": "1",
    "SCHOOL": "Southern California",
    "name": "John Rudometkin",
    "rosters": [
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "ba894f72-662a-4d86-8791-ddc3f2492c47",
      "67233742-0a5f-4ab9-a3f8-682956af416d"
    ]
  },
  "78039": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 15, 1948",
    "EXP": "2",
    "SCHOOL": "Brigham Young",
    "name": "Paul Ruffner",
    "rosters": [
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2",
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "e172999e-d18d-439a-83cc-746ead7f5c56"
    ]
  },
  "78040": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 03, 1938",
    "EXP": "2",
    "SCHOOL": "Northwestern",
    "name": "Joe Ruklick",
    "rosters": [
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "c0a59dc7-31bb-4431-8aa6-9cb7c93956ae",
      "f2075f4b-e4d7-42a3-b67c-74c8d3b8e8d5"
    ]
  },
  "78041": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "DEC 16, 1958",
    "EXP": "7",
    "SCHOOL": "Iona",
    "name": "Jeff Ruland",
    "rosters": [
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "355ade3f-1412-4279-a7f6-512d2936bf86",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "78042": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 29, 1944",
    "EXP": "3",
    "SCHOOL": "Colorado State",
    "name": "Bob Rule",
    "rosters": [
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "3829b824-1221-432d-8dc1-388a4d2f1cce",
      "6e488406-7055-45fd-b876-5b97997c0cf1"
    ]
  },
  "78043": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "165",
    "BIRTH_DATE": "JUN 23, 1923",
    "EXP": "3",
    "SCHOOL": "Temple",
    "name": "Jerry Rullo",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c",
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb",
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388"
    ]
  },
  "78045": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "218",
    "BIRTH_DATE": "JUN 07, 1944",
    "EXP": "4",
    "SCHOOL": "Michigan",
    "name": "Cazzie Russell",
    "rosters": [
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77",
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "81553d34-2d0b-40e2-a229-d33af0d3a5f8",
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "c89d57fe-9137-4336-8384-9435a1aab760"
    ]
  },
  "78046": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "APR 17, 1949",
    "EXP": "R",
    "SCHOOL": "Detroit Mercy",
    "name": "Frank Russell",
    "rosters": [
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a"
    ]
  },
  "78047": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 12, 1952",
    "EXP": "7",
    "SCHOOL": "Michigan",
    "name": "Campy Russell",
    "rosters": [
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86",
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff"
    ]
  },
  "78048": {
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 26, 1960",
    "EXP": "5",
    "SCHOOL": "Western Michigan",
    "name": "Walker Russell",
    "rosters": [
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8"
    ]
  },
  "78049": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 12, 1934",
    "EXP": "12",
    "SCHOOL": "San Francisco",
    "name": "Bill Russell",
    "rosters": [
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7",
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "b576b451-62e4-4a16-98e2-535cecc8e551"
    ]
  },
  "78050": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-5",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 11, 1917",
    "EXP": "2",
    "SCHOOL": "Seton Hall",
    "name": "Ed Sadowski",
    "rosters": [
      "e032e6bf-840a-444b-8044-7f6795db68e6",
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb"
    ]
  },
  "78055": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-4",
    "WEIGHT": "228",
    "BIRTH_DATE": "JUL 07, 1960",
    "EXP": "8",
    "SCHOOL": "Virginia",
    "name": "Ralph Sampson",
    "rosters": [
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff",
      "57c1be08-60cf-40ad-a097-a95ef9958532",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1"
    ]
  },
  "78057": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 23, 1957",
    "EXP": "1",
    "SCHOOL": "Southern University",
    "name": "Frankie Sanders",
    "rosters": [
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "19686855-a120-434c-8ad4-5f15e61b9a38"
    ]
  },
  "78059": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 07, 1960",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Mike Sanders",
    "rosters": [
      "c1ba7f4d-a99e-4e0b-b299-bbfffe508d64",
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "56805152-7f69-4038-9e73-74d65c84c8ba",
      "a5c8704a-f574-41ab-a9f9-60f16c4148ac",
      "ce5d240f-959a-498b-8cec-8d0416972633",
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661"
    ]
  },
  "78060": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 08, 1938",
    "EXP": "12",
    "SCHOOL": "New York University",
    "name": "Thomas Sanders",
    "rosters": [
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "b576b451-62e4-4a16-98e2-535cecc8e551",
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9",
      "685d7197-3cc2-4e98-8f77-699d694916ab"
    ]
  },
  "78061": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 17, 1935",
    "EXP": "R",
    "SCHOOL": "Iona",
    "name": "Bob Santini",
    "rosters": [
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8"
    ]
  },
  "78062": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 17, 1960",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Wayne Sappleton",
    "rosters": [
      "8566310c-0a28-4492-9afd-453f232588e5"
    ]
  },
  "78063": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 16, 1924",
    "EXP": "1",
    "SCHOOL": "Seton Hall",
    "name": "Frank Saul",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800",
      "56568a86-b30e-48a7-9f7d-80f79aa00339",
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2",
      "1b383d65-6ca5-46ca-b345-acf8e129e382",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0",
      "6146894f-3e97-4aab-a027-77228d1955a6"
    ]
  },
  "78064": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 11, 1934",
    "EXP": "5",
    "SCHOOL": "Texas Southern",
    "name": "Woody Sauldsberry",
    "rosters": [
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447",
      "dfaf5774-af9e-4334-82a0-3587b2230530",
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "e5799d65-732c-4105-b48e-fe6c0e46eee6",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "78065": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 13, 1951",
    "EXP": "3",
    "SCHOOL": "Syracuse",
    "name": "Fred Saunders",
    "rosters": [
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "5517a85a-4623-414c-89a6-b90bb5a42eca"
    ]
  },
  "78066": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 09, 1928",
    "EXP": "1",
    "SCHOOL": "Le Moyne",
    "name": "Don Savage",
    "rosters": [
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e",
      "63ab2d0f-d691-48e0-9892-45f784ea03d5"
    ]
  },
  "78068": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "208",
    "BIRTH_DATE": "DEC 28, 1958",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Dewayne Scales",
    "rosters": [
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407"
    ]
  },
  "78069": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "JAN 22, 1950",
    "EXP": "R",
    "SCHOOL": "Wisconsin-Eau Claire",
    "name": "Frank Schade",
    "rosters": [
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60"
    ]
  },
  "78071": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "DEC 20, 1919",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "Herm Schaefer",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503",
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9"
    ]
  },
  "78072": {
    "NUM": "27",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 16, 1905",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Bob Schafer",
    "rosters": [
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b"
    ]
  },
  "78075": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 30, 1925",
    "EXP": "3",
    "SCHOOL": "West Virginia",
    "name": "Fred Schaus",
    "rosters": [
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d",
      "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e",
      "648376a8-6ee8-4cad-97d2-369540020f50",
      "02a3a04b-002a-4afa-8188-8f11ccfafda1",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e"
    ]
  },
  "78076": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 19, 1928",
    "EXP": "14",
    "SCHOOL": "New York University",
    "name": "Dolph Schayes",
    "rosters": [
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "98673c29-08ee-48ce-9891-81b0ab92d951",
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e",
      "b7540846-94ec-42ec-b008-ff241f8152ea",
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8",
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "a45c9665-5951-47aa-ad90-2e2f872cee40",
      "22982acd-6ca6-4a1b-adc8-55a5f701d8b2",
      "b4492284-7d41-45e4-b3d7-fd93667614a1",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a"
    ]
  },
  "78078": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 30, 1919",
    "EXP": "R",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Ossie Schectman",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24"
    ]
  },
  "78080": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 27, 1954",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Tom Scheffler",
    "rosters": [
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3"
    ]
  },
  "78081": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 14, 1944",
    "EXP": "1",
    "SCHOOL": "Purdue",
    "name": "Dave Schellhase",
    "rosters": [
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "080d0104-5755-4078-9dd5-e5b36018755c"
    ]
  },
  "78082": {
    "NUM": "19-22",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "212",
    "BIRTH_DATE": "DEC 21, 1929",
    "EXP": "1",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Herb Scherer",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d"
    ]
  },
  "78084": {
    "NUM": "54",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 12, 1945",
    "EXP": "9",
    "SCHOOL": "Colorado State",
    "name": "Dale Schlueter",
    "rosters": [
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40",
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4",
      "a643026c-452d-404f-9bec-ce36434b7ef9"
    ]
  },
  "78086": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 27, 1928",
    "EXP": "5",
    "SCHOOL": "Ohio State",
    "name": "Dick Schnittker",
    "rosters": [
      "1b383d65-6ca5-46ca-b345-acf8e129e382",
      "22dd26a7-1c99-45b7-a759-dce48ccb6829",
      "156bc9fb-9be0-4d47-ae6c-e255ee721988",
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8",
      "8f51d7d4-966b-43d1-84b2-9267b61db974"
    ]
  },
  "78087": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 16, 1960",
    "EXP": "3",
    "SCHOOL": "Tennessee-Chattanooga",
    "name": "Russ Schoene",
    "rosters": [
      "c24811b3-b1da-43ab-9ab2-9a68328f351c",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "90c8da1b-ce02-4f81-83f9-fadd291577cf"
    ]
  },
  "78088": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 12, 1948",
    "EXP": "R",
    "SCHOOL": "Illinois",
    "name": "Dave Scholz",
    "rosters": [
      "a8ccae69-3758-45d9-b363-2847b583dcf8"
    ]
  },
  "78091": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 03, 1922",
    "EXP": "2",
    "SCHOOL": "Hamline",
    "name": "Howie Schultz",
    "rosters": [
      "56568a86-b30e-48a7-9f7d-80f79aa00339",
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2"
    ]
  },
  "78092": {
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "192",
    "BIRTH_DATE": "JAN 03, 1917",
    "EXP": "3",
    "SCHOOL": "Wisconsin",
    "name": "Dick Schulz",
    "rosters": [
      "cf598b10-dc59-4143-a286-b7dfcbbb6989"
    ]
  },
  "78093": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 19, 1960",
    "EXP": "1",
    "SCHOOL": "Richmond",
    "name": "John Schweitz",
    "rosters": [
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "999df20d-d256-45ec-8e64-111bdc7471d7"
    ]
  },
  "78094": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 01, 1922",
    "EXP": "7",
    "SCHOOL": "San Francisco",
    "name": "Freddie Scolari",
    "rosters": [
      "0f305d34-33d7-4e16-a669-f7ab442c151d",
      "98673c29-08ee-48ce-9891-81b0ab92d951",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e",
      "57494c03-afc0-41de-b544-659990d5e952"
    ]
  },
  "78095": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 14, 1955",
    "EXP": "7",
    "SCHOOL": "Oral Roberts",
    "name": "Alvin Scott",
    "rosters": [
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "e8500690-7102-4acf-ae8b-f1907cf60bcf",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "4afff1de-1a54-4626-90c2-0cc0bf0dbcc3",
      "aca2f6cb-0959-42f9-a0a5-49c843300715",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f"
    ]
  },
  "78097": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "175",
    "BIRTH_DATE": "DEC 15, 1948",
    "EXP": "3",
    "SCHOOL": "North Carolina",
    "name": "Charlie Scott",
    "rosters": [
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792"
    ]
  },
  "78100": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 12, 1938",
    "EXP": "4",
    "SCHOOL": "Portland",
    "name": "Ray Scott",
    "rosters": [
      "1afcc38d-6d62-4ced-a2db-41985c45adb4",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "0403c301-445c-4c5d-8144-e5805bdc801c",
      "e6ca1168-2bff-4198-9727-665654e83794",
      "feda73c5-0fda-42f5-a08e-54ce9c2bbfe0",
      "e389ff50-8e08-469e-aba4-9d1231b91c1a",
      "3902314e-3111-45fa-9433-86d9fdae07db",
      "61110628-8f7d-478f-b171-52455d3bf9d0"
    ]
  },
  "78102": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "188",
    "BIRTH_DATE": "DEC 04, 1962",
    "EXP": "1",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Carey Scurry",
    "rosters": [
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a",
      "324375ef-30c9-4fcb-86c6-f30147491d44"
    ]
  },
  "78103": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 18, 1953",
    "EXP": "2",
    "SCHOOL": "Xavier (LA)",
    "name": "Bruce Seals",
    "rosters": [
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c",
      "8718c236-d862-42de-a84d-ef29c83369b9",
      "6306dadf-1ee7-4678-b035-ef14578746a4"
    ]
  },
  "78105": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 17, 1952",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Ed Searcy",
    "rosters": [
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac"
    ]
  },
  "78106": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "198",
    "BIRTH_DATE": "AUG 17, 1933",
    "EXP": "5",
    "SCHOOL": "Santa Clara",
    "name": "Ken Sears",
    "rosters": [
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "2fb8b948-e857-417f-8af2-422f0825a7c1"
    ]
  },
  "78110": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "7-0",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 17, 1962",
    "EXP": "4",
    "SCHOOL": "Ohio State",
    "name": "Brad Sellers",
    "rosters": [
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "8e1b3223-5273-4a8c-b563-50f9edfd074e",
      "3b167c9d-66a7-4736-b5f2-604aa433bb39",
      "db85dc84-6de1-4038-8c49-8f636dd20715"
    ]
  },
  "78111": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 20, 1953",
    "EXP": "R",
    "SCHOOL": "Rutgers",
    "name": "Phil Sellers",
    "rosters": [
      "553ffb4a-c13e-4629-9598-f1f64182ce7f"
    ]
  },
  "78113": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 09, 1932",
    "EXP": "3",
    "SCHOOL": "Furman",
    "name": "Frank Selvy",
    "rosters": [
      "d734ea86-9401-4fa7-846a-e69cc31d9800",
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b",
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012"
    ]
  },
  "78114": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 01, 1922",
    "EXP": "3",
    "SCHOOL": "Southern California",
    "name": "Jim Seminoff",
    "rosters": [
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6",
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1"
    ]
  },
  "78115": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "179",
    "BIRTH_DATE": "APR 04, 1922",
    "EXP": "7",
    "SCHOOL": "Saint Joseph's",
    "name": "George Senesky",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c",
      "e3b1f6b0-ceb4-4333-bf02-1a30e877d77a",
      "4d4a8481-0be5-4e44-9a17-8dfe8aeb2ebb",
      "f3b8e2c4-4939-49a2-aff3-37eb5a9cd388",
      "78cf5ce6-637f-459d-812a-47c6b4615dc4",
      "1c190e61-1a8b-4cd1-aba2-fae468830890",
      "9398ab8b-2109-490e-8b37-d2bbaadb361f",
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f"
    ]
  },
  "78116": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 11, 1962",
    "EXP": "R",
    "SCHOOL": "Lamar",
    "name": "Tom Sewell",
    "rosters": [
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228"
    ]
  },
  "78117": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 30, 1928",
    "EXP": "11",
    "SCHOOL": "Toledo",
    "name": "Paul Seymour",
    "rosters": [
      "edd1e6a4-e5e5-42f0-a74a-cea065f94d71",
      "98673c29-08ee-48ce-9891-81b0ab92d951",
      "740a5424-9663-4fb6-b4e0-0f0ffd429c5e",
      "b7540846-94ec-42ec-b008-ff241f8152ea",
      "1e38bf86-dd1f-48c5-a30a-fb663e2283e8",
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "63ab2d0f-d691-48e0-9892-45f784ea03d5",
      "957840b6-7508-4ff9-8683-2a1f5f6f074a",
      "1dbc8441-2b65-4d63-a97c-fba1b28af868",
      "a45c9665-5951-47aa-ad90-2e2f872cee40"
    ]
  },
  "78121": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 23, 1939",
    "EXP": "2",
    "SCHOOL": "North Carolina",
    "name": "Lee Shaffer",
    "rosters": [
      "b4492284-7d41-45e4-b3d7-fd93667614a1",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a"
    ]
  },
  "78124": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 10, 1923",
    "EXP": "1",
    "SCHOOL": "Kansas State",
    "name": "Howie Shannon",
    "rosters": [
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1"
    ]
  },
  "78125": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 14, 1927",
    "EXP": "1",
    "SCHOOL": "Bowling Green",
    "name": "Charlie Share",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "d734ea86-9401-4fa7-846a-e69cc31d9800",
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b",
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "6604b030-ca23-43fb-b63e-9cbe726436f1",
      "02a3a04b-002a-4afa-8188-8f11ccfafda1",
      "e527a536-aa8c-43ac-8b79-dd0230b9780e"
    ]
  },
  "78126": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 25, 1926",
    "EXP": "10",
    "SCHOOL": "Southern California",
    "name": "Bill Sharman",
    "rosters": [
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc",
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b",
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "0f305d34-33d7-4e16-a669-f7ab442c151d",
      "75b95e75-2397-48c9-b255-676792b7226d",
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7",
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263"
    ]
  },
  "78128": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 04, 1933",
    "EXP": "1",
    "SCHOOL": "North Carolina State",
    "name": "Ron Shavlik",
    "rosters": [
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b"
    ]
  },
  "78131": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "165",
    "BIRTH_DATE": "NOV 05, 1923",
    "EXP": "R",
    "SCHOOL": "Utah",
    "name": "Fred Sheffield",
    "rosters": [
      "fee19330-42a6-4e11-9702-950d58c8a50c"
    ]
  },
  "78132": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 01, 1957",
    "EXP": "1",
    "SCHOOL": "Georgetown",
    "name": "Craig Shelton",
    "rosters": [
      "3c8804a4-d28c-4245-8592-95f990e5e797",
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5"
    ]
  },
  "78133": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 19, 1955",
    "EXP": "6",
    "SCHOOL": "Oregon State",
    "name": "Lonnie Shelton",
    "rosters": [
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "278e33a4-dd30-4a35-b7be-2e7b71623707",
      "6cb87ca5-b208-46a1-b393-022423c35c0d",
      "9cf046e5-f475-41ef-a3d3-f517368a157b",
      "a59119a0-b26d-4a60-b162-d2df226404ce"
    ]
  },
  "78135": {
    "NUM": "27",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 21, 1954",
    "EXP": "1",
    "SCHOOL": "Maryland",
    "name": "Steve Sheppard",
    "rosters": [
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "78136": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "SEP 13, 1959",
    "EXP": "R",
    "SCHOOL": "Virginia Commonwealth",
    "name": "Edmund Sherod",
    "rosters": [
      "6a10cc0f-c034-4cee-992e-4023a7280654"
    ]
  },
  "78138": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 07, 1953",
    "EXP": "R",
    "SCHOOL": "Jackson State",
    "name": "Gene Short",
    "rosters": [
      "52649402-55a4-4d97-a5fd-f7cf9779a672"
    ]
  },
  "78139": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 02, 1957",
    "EXP": "11",
    "SCHOOL": "Jackson State",
    "name": "Purvis Short",
    "rosters": [
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "79d8e657-ce27-4052-9492-c9833808173f",
      "faa38736-32f4-41a2-8cca-8649fdee48af"
    ]
  },
  "78140": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 24, 1963",
    "EXP": "R",
    "SCHOOL": "South Alabama",
    "name": "Dexter Shouse",
    "rosters": [
      "5dccfcf4-5eac-4345-af96-969f8232576c"
    ]
  },
  "78141": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 07, 1923",
    "EXP": "R",
    "SCHOOL": "Ohio",
    "name": "Dick Shrider",
    "rosters": [
      "df1fce20-7cea-47cc-8e99-cf6d772b3162"
    ]
  },
  "78142": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "DEC 18, 1931",
    "EXP": "7",
    "SCHOOL": "Maryland",
    "name": "Gene Shue",
    "rosters": [
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8",
      "ba894f72-662a-4d86-8791-ddc3f2492c47",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "edb95a5d-c995-43f0-9378-d27051d259ab",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3",
      "03f980b2-d8d0-47cc-a777-71a16e029764",
      "29b33649-2e3d-444b-81b4-caec18163bdd",
      "a4934f64-d807-440b-9639-6e4052c2501e",
      "e6ca1168-2bff-4198-9727-665654e83794"
    ]
  },
  "78143": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 06, 1952",
    "EXP": "2",
    "SCHOOL": "Notre Dame",
    "name": "John Shumate",
    "rosters": [
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "38ac7402-b81e-4254-b636-95330a997974",
      "6cb87ca5-b208-46a1-b393-022423c35c0d",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3"
    ]
  },
  "78144": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 11, 1949",
    "EXP": "R",
    "SCHOOL": "Kentucky State",
    "name": "Sam Sibert",
    "rosters": [
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60"
    ]
  },
  "78145": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 13, 1950",
    "EXP": "R",
    "SCHOOL": "Northwestern",
    "name": "Mark Sibley",
    "rosters": [
      "dd803214-fd70-44fe-988b-85ae27a94245"
    ]
  },
  "78146": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "168",
    "BIRTH_DATE": "NOV 29, 1956",
    "EXP": "8",
    "SCHOOL": "Purdue",
    "name": "Jerry Sichting",
    "rosters": [
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "321bccfb-8638-4330-a1da-00ee8d4007e8",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917",
      "c24811b3-b1da-43ab-9ab2-9a68328f351c",
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "04ef3e81-d8fb-48a8-ba78-694468f81686",
      "148d19d7-f6a8-401d-b449-7e8b111f7959"
    ]
  },
  "78147": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 22, 1939",
    "EXP": "7",
    "SCHOOL": "Ohio State",
    "name": "Larry Siegfried",
    "rosters": [
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "3e94e421-e072-4018-95e3-f183762c70eb",
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "de3c97df-aa08-40c1-bb58-7d65bf023583",
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "b576b451-62e4-4a16-98e2-535cecc8e551",
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "84e31c1f-82ce-462e-bbce-b0b659005594"
    ]
  },
  "78149": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 14, 1955",
    "EXP": "8",
    "SCHOOL": "Illinois Wesleyan",
    "name": "Jack Sikma",
    "rosters": [
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "777d6706-d922-46f9-8db4-e079c5f46c74",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "ec353978-5363-474f-aac3-88a8126172d3",
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "6306dadf-1ee7-4678-b035-ef14578746a4",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "278e33a4-dd30-4a35-b7be-2e7b71623707",
      "6cb87ca5-b208-46a1-b393-022423c35c0d",
      "9cf046e5-f475-41ef-a3d3-f517368a157b",
      "a59119a0-b26d-4a60-b162-d2df226404ce",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "0d39d553-7b93-4206-b525-d87f764e4d5d"
    ]
  },
  "78150": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 11, 1949",
    "EXP": "4",
    "SCHOOL": "Stephen F. Austin",
    "name": "James Silas",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "02822d1e-e608-4487-ba05-46ffbf5312fe",
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34",
      "ed829e6a-a009-4d2d-b830-02d39fbaf148",
      "38ac7402-b81e-4254-b636-95330a997974",
      "a6b98be6-4acd-49be-92b6-decd2522e4df"
    ]
  },
  "78151": {
    "NUM": "36",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 12, 1943",
    "EXP": "15",
    "SCHOOL": "Creighton",
    "name": "Paul Silas",
    "rosters": [
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "fd982b48-258b-4fc9-b6d2-1be39fa32925",
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "385be451-82b9-4142-849d-362d686d325b",
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "7d5d2b23-3811-4485-8ebe-5300180b8967",
      "c5964149-f86c-413e-9d7c-c8d07f4113ef",
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "6306dadf-1ee7-4678-b035-ef14578746a4",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "278e33a4-dd30-4a35-b7be-2e7b71623707"
    ]
  },
  "78152": {
    "NUM": "4-27",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 04, 1944",
    "EXP": "R",
    "SCHOOL": "Army",
    "name": "Mike Silliman",
    "rosters": [
      "94f7f595-0ac0-4c93-8c34-bc941216950a"
    ]
  },
  "78153": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "222",
    "BIRTH_DATE": "MAR 15, 1925",
    "EXP": "9",
    "SCHOOL": null,
    "name": "Connie Simmons",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654",
      "e032e6bf-840a-444b-8044-7f6795db68e6",
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac",
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d",
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "516c9243-1276-46d0-8dbe-f1db0a8384ad"
    ]
  },
  "78154": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "184",
    "BIRTH_DATE": "JUL 07, 1924",
    "EXP": "R",
    "SCHOOL": "New York University",
    "name": "Johnny Simmons",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "78157": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 10, 1949",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Ralph Simpson",
    "rosters": [
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "00e74371-e5e5-40e2-8c0f-ea6ea078dd79",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3"
    ]
  },
  "78159": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 29, 1943",
    "EXP": "R",
    "SCHOOL": "Kent State",
    "name": "Doug Sims",
    "rosters": [
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604"
    ]
  },
  "78160": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 09, 1938",
    "EXP": "R",
    "SCHOOL": "Pepperdine",
    "name": "Bob Sims",
    "rosters": [
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0"
    ]
  },
  "78161": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 18, 1955",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Scott Sims",
    "rosters": [
      "e823e658-8b58-4b0e-ba9a-63cc0292ae34"
    ]
  },
  "78162": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 29, 1961",
    "EXP": "R",
    "SCHOOL": "Alabama-Birmingham",
    "name": "Mckinley Singleton",
    "rosters": [
      "b2182580-6b74-4d01-905a-961e5a283423"
    ]
  },
  "78163": {
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "165",
    "BIRTH_DATE": "JAN 25, 1929",
    "EXP": "1",
    "SCHOOL": "Niagara",
    "name": "Zeke Sinicola",
    "rosters": [
      "02a3a04b-002a-4afa-8188-8f11ccfafda1",
      "57494c03-afc0-41de-b544-659990d5e952"
    ],
    "NUM": "5"
  },
  "78164": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 03, 1962",
    "EXP": "R",
    "SCHOOL": "Oregon State",
    "name": "Charlie Sitton",
    "rosters": [
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd"
    ]
  },
  "78167": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 16, 1952",
    "EXP": "1",
    "SCHOOL": "Massachusetts",
    "name": "Al Skinner",
    "rosters": [
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3"
    ]
  },
  "78168": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 10, 1952",
    "EXP": "1",
    "SCHOOL": "Maryland-Eastern Shore",
    "name": "Talvin Skinner",
    "rosters": [
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c"
    ]
  },
  "78169": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 02, 1926",
    "EXP": "5",
    "SCHOOL": "Minnesota",
    "name": "Whitey Skoog",
    "rosters": [
      "56568a86-b30e-48a7-9f7d-80f79aa00339",
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2",
      "1b383d65-6ca5-46ca-b345-acf8e129e382",
      "22dd26a7-1c99-45b7-a759-dce48ccb6829",
      "156bc9fb-9be0-4d47-ae6c-e255ee721988",
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8"
    ]
  },
  "78170": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 01, 1941",
    "EXP": "R",
    "SCHOOL": "Kenyon",
    "name": "Jeff Slade",
    "rosters": [
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "78172": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 09, 1960",
    "EXP": "R",
    "SCHOOL": "Portland",
    "name": "Jose Slaughter",
    "rosters": [
      "c24811b3-b1da-43ab-9ab2-9a68328f351c"
    ]
  },
  "78173": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 28, 1942",
    "EXP": "R",
    "SCHOOL": "Evansville",
    "name": "Jerry Sloan",
    "rosters": [
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "5ea792bd-890a-47b6-babe-2afabfd78f09",
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2",
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "66953847-11ae-490d-ba1c-76473d320167",
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc"
    ]
  },
  "78174": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 18, 1962",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Tom Sluby",
    "rosters": [
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd"
    ]
  },
  "78175": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 21, 1964",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Keith Smart",
    "rosters": [
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a"
    ]
  },
  "78177": {
    "NUM": "37",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 22, 1922",
    "EXP": "R",
    "SCHOOL": "Illinois",
    "name": "Jack Smiley",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "78178": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 05, 1936",
    "EXP": "7",
    "SCHOOL": "Kentucky",
    "name": "Adrian Smith",
    "rosters": [
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "852d8746-a465-41f3-b644-085c99bbad27",
      "020d6aa2-861a-4e30-8595-c38b6904fb12",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef",
      "38c6a8b5-9c04-429f-9873-476923c33722",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604"
    ]
  },
  "78179": {
    "POSITION": "F",
    "HEIGHT": "6-1",
    "WEIGHT": "160",
    "BIRTH_DATE": "NOV 29, 1967",
    "EXP": "2",
    "SCHOOL": "Georgetown",
    "name": "Charles Smith",
    "rosters": [
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "e170cb31-d4ef-499a-8662-716298adebe7"
    ]
  },
  "78181": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 19, 1964",
    "EXP": "1",
    "SCHOOL": "Cleveland State",
    "name": "Clinton Smith",
    "rosters": [
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6"
    ]
  },
  "78183": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 01, 1961",
    "EXP": "5",
    "SCHOOL": "Louisville",
    "name": "Derek Smith",
    "rosters": [
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "74e6c624-b880-43b1-8ff2-fb83fd0cedda",
      "5dccfcf4-5eac-4345-af96-969f8232576c",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "78184": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "160",
    "BIRTH_DATE": "OCT 10, 1951",
    "EXP": "R",
    "SCHOOL": "Dayton",
    "name": "Don Smith",
    "rosters": [
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8"
    ]
  },
  "78185": {
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 27, 1920",
    "EXP": "R",
    "SCHOOL": "Minnesota",
    "name": "Donald Smith",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503"
    ]
  },
  "78187": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 05, 1929",
    "EXP": "R",
    "SCHOOL": "Harvard",
    "name": "Ed Smith",
    "rosters": [
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d"
    ]
  },
  "78188": {
    "NUM": "3",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAY 09, 1949",
    "EXP": "5",
    "SCHOOL": "Kentucky State",
    "name": "Elmore Smith",
    "rosters": [
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "c39c5026-a493-40db-84cd-272c3b126805",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "743aba57-7e93-4575-8cb7-e3de53d6223c"
    ]
  },
  "78189": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 18, 1945",
    "EXP": "1",
    "SCHOOL": "Eastern Kentucky",
    "name": "Garfield Smith",
    "rosters": [
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9"
    ]
  },
  "78190": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 28, 1947",
    "EXP": "7",
    "SCHOOL": "Western Kentucky",
    "name": "Greg Smith",
    "rosters": [
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00",
      "c7ab28ba-96bf-465d-b793-b95781330560",
      "30c679a6-4b92-42bc-b03d-303894f5aed9",
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "cbf01feb-6986-43c2-8350-23e707cf8272"
    ]
  },
  "78191": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 12, 1958",
    "EXP": "1",
    "SCHOOL": "Ohio State",
    "name": "Jim Smith",
    "rosters": [
      "de2e838d-3259-430d-8634-0759e68893b8",
      "f7a58b16-8257-42be-b944-16319d0abd63"
    ]
  },
  "78192": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 09, 1964",
    "EXP": "R",
    "SCHOOL": "Loyola-Marymount",
    "name": "Keith Smith",
    "rosters": [
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd"
    ]
  },
  "78194": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 03, 1969",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Labradford Smith",
    "rosters": [
      "2678d1f0-b641-4c9f-9473-9217e379be6c",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "8e3fc24c-bb3d-42b0-9312-939b187e5f05"
    ]
  },
  "78195": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 18, 1958",
    "EXP": "12",
    "SCHOOL": "Alcorn State",
    "name": "Larry Smith",
    "rosters": [
      "6af54433-9189-4d97-aafc-8c3d7350a043",
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "aea08569-655f-4acc-8b71-27160b0a4dad",
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "a30f0af5-1ba9-4b40-a70a-b1cab3aa50b9",
      "ef62c5cb-9bb2-4804-9b4f-0ed6fa1d1d90"
    ]
  },
  "78197": {
    "POSITION": "G",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 19, 1965",
    "EXP": "2",
    "SCHOOL": "Brigham Young",
    "name": "Michael Smith",
    "rosters": [
      "6bc9eb85-3647-4a8c-86c8-896fb5397a53",
      "e0e2c95e-209e-4d00-9e53-3035df29fe95",
      "ca789ab7-ce93-4361-be31-0d5042cf56ae"
    ]
  },
  "78198": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 30, 1964",
    "EXP": "5",
    "SCHOOL": "Jacksonville",
    "name": "Otis Smith",
    "rosters": [
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62"
    ]
  },
  "78199": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 22, 1952",
    "EXP": "8",
    "SCHOOL": "San Francisco",
    "name": "Phil Smith",
    "rosters": [
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444",
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "a59119a0-b26d-4a60-b162-d2df226404ce"
    ]
  },
  "78200": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 12, 1948",
    "EXP": "10",
    "SCHOOL": "Buffalo State",
    "name": "Randy Smith",
    "rosters": [
      "27d777a7-4281-47bc-8d7f-03cca62e745b",
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff"
    ]
  },
  "78201": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 21, 1970",
    "EXP": "1",
    "SCHOOL": "Texas Christian",
    "name": "Reggie Smith",
    "rosters": [
      "ce6303d7-7e69-45e6-9f99-e7a14a040764",
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2"
    ]
  },
  "78202": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 26, 1946",
    "EXP": "10",
    "SCHOOL": "Tulsa",
    "name": "Bingo Smith",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1",
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86",
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "ef880e29-8409-4464-a009-ac827384a450"
    ]
  },
  "78203": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 20, 1937",
    "EXP": "1",
    "SCHOOL": "West Virginia",
    "name": "Bobby Smith",
    "rosters": [
      "efb6857f-25a7-443d-a111-1cd36484ccc8",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29"
    ]
  },
  "78204": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "165",
    "BIRTH_DATE": "MAR 10, 1955",
    "EXP": "5",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Robert Smith",
    "rosters": [
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "66743650-e493-4c91-a3ab-5a488a063464",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661"
    ]
  },
  "78205": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 08, 1955",
    "EXP": "R",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Sammy Smith",
    "rosters": [
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "39873456-69db-4f6d-b278-943dbfa788f7"
    ]
  },
  "78207": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 14, 1949",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Bill Smith",
    "rosters": [
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4",
      "448aca36-b464-4106-91e1-7fc632e2c7d5"
    ]
  },
  "78208": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "OCT 26, 1953",
    "EXP": "2",
    "SCHOOL": "Missouri",
    "name": "Willie Smith",
    "rosters": [
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd"
    ]
  },
  "78209": {
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 26, 1939",
    "EXP": "R",
    "SCHOOL": "St. Peter's",
    "name": "Bill Smith",
    "rosters": [
      "816164e0-e2ee-413e-8662-b51fbf40b841"
    ]
  },
  "78213": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "207",
    "BIRTH_DATE": "FEB 01, 1944",
    "EXP": "12",
    "SCHOOL": "Davidson",
    "name": "Dick Snyder",
    "rosters": [
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86",
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "20955b01-8d24-45f5-ba05-3a4253c801f7",
      "3829b824-1221-432d-8dc1-388a4d2f1cce",
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "5e43acaa-2104-49ad-b07f-b37c04e28912"
    ]
  },
  "78215": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "198",
    "BIRTH_DATE": "JAN 15, 1953",
    "EXP": "8",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Ricky Sobers",
    "rosters": [
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "b53d3c18-a4f1-40bf-a266-2555fa606018",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "0d39d553-7b93-4206-b525-d87f764e4d5d",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407"
    ]
  },
  "78216": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 21, 1934",
    "EXP": "3",
    "SCHOOL": "DePaul",
    "name": "Ron Sobie",
    "rosters": [
      "92e7e3b2-600e-499a-b1d1-968fbfa14e64",
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b"
    ]
  },
  "78217": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 16, 1953",
    "EXP": "2",
    "SCHOOL": "Utah",
    "name": "Mike Sojourner",
    "rosters": [
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "0408e522-71e2-49ae-bd83-8ebbebbf212f"
    ]
  },
  "78219": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "5-8",
    "WEIGHT": "170",
    "BIRTH_DATE": "MAR 17, 1942",
    "EXP": "R",
    "SCHOOL": "Duquesne",
    "name": "Willie Somerset",
    "rosters": [
      "f9bda4e1-041b-43c8-b32a-a97bbb7ed7fc"
    ]
  },
  "78220": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 08, 1948",
    "EXP": "2",
    "SCHOOL": "Ohio State",
    "name": "Dave Sorenson",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1",
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada"
    ]
  },
  "78222": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 28, 1957",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Jim Spanarkel",
    "rosters": [
      "6e8363c5-182a-46ea-b472-92fa07dd14b9",
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4"
    ]
  },
  "78223": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "218",
    "BIRTH_DATE": "NOV 02, 1932",
    "EXP": "1",
    "SCHOOL": "Detroit Mercy",
    "name": "Guy Sparrow",
    "rosters": [
      "16181ef3-9a5e-4560-bbac-f30072f1a572",
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012"
    ]
  },
  "78225": {
    "NUM": "8-15",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 26, 1925",
    "EXP": "6",
    "SCHOOL": "Western Kentucky",
    "name": "Odie Spears",
    "rosters": [
      "83150f4c-0043-4b65-834a-7af2f1b9b071",
      "817867c3-ef94-48b9-81aa-768f4f8f758e",
      "a0311ade-2019-4595-ab92-26a1696f3ce7",
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6",
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af",
      "0a699404-02aa-4953-8be2-08a5fc726ea3"
    ]
  },
  "78226": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 17, 1920",
    "EXP": "3",
    "SCHOOL": "Villanova",
    "name": "Art Spector",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654",
      "e032e6bf-840a-444b-8044-7f6795db68e6",
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6",
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1"
    ]
  },
  "78227": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 20, 1964",
    "EXP": "1",
    "SCHOOL": "Northern Arizona",
    "name": "Andre Spencer",
    "rosters": [
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060",
      "2678d1f0-b641-4c9f-9473-9217e379be6c"
    ]
  },
  "78231": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 18, 1945",
    "EXP": "R",
    "SCHOOL": "Tulane",
    "name": "Craig Spitzer",
    "rosters": [
      "080d0104-5755-4078-9dd5-e5b36018755c"
    ]
  },
  "78232": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 11, 1932",
    "EXP": "2",
    "SCHOOL": "Western Kentucky",
    "name": "Art Spoelstra",
    "rosters": [
      "8f51d7d4-966b-43d1-84b2-9267b61db974",
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af",
      "516c9243-1276-46d0-8dbe-f1db0a8384ad",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b"
    ]
  },
  "78233": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 08, 1959",
    "EXP": "4",
    "SCHOOL": "Howard",
    "name": "Larry Spriggs",
    "rosters": [
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a",
      "8ee13c36-3a11-4393-97aa-f2ba8ab08cdd"
    ]
  },
  "78237": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 04, 1951",
    "EXP": "5",
    "SCHOOL": "Providence",
    "name": "Kevin Stacom",
    "rosters": [
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "e7850597-6081-4332-9f69-ba361aaaa0bc"
    ]
  },
  "78238": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 20, 1941",
    "EXP": "6",
    "SCHOOL": "Wichita State",
    "name": "Dave Stallworth",
    "rosters": [
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "3fb360bc-873c-4a39-b17b-8acdb404a070",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "b200486c-7ae8-4f2d-9cda-011f4c747d12",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "024d58a0-dd09-4396-a891-82ba087f7279"
    ]
  },
  "78239": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 18, 1950",
    "EXP": "4",
    "SCHOOL": "Kansas",
    "name": "Isaac Stallworth",
    "rosters": [
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827"
    ]
  },
  "78240": {
    "NUM": "19-21",
    "POSITION": "F",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 15, 1921",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Ed Stanczak",
    "rosters": [
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7"
    ]
  },
  "78241": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 27, 1961",
    "EXP": "2",
    "SCHOOL": "Temple",
    "name": "Terence Stansbury",
    "rosters": [
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6"
    ]
  },
  "78243": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 14, 1954",
    "EXP": "R",
    "SCHOOL": "Pittsburgh",
    "name": "Keith Starr",
    "rosters": [
      "c3238288-bce4-48df-bf77-11f4a2fd274e"
    ]
  },
  "78244": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 11, 1936",
    "EXP": "3",
    "SCHOOL": "Thomas More",
    "name": "Larry Staverman",
    "rosters": [
      "dc41b99f-710f-4f81-b125-45b52cf09053",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "78245": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 05, 1949",
    "EXP": "8",
    "SCHOOL": "Kentucky",
    "name": "Larry Steele",
    "rosters": [
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4",
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "cbf01feb-6986-43c2-8350-23e707cf8272",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "4ac401d3-899b-427d-89d6-584fe69ebf75"
    ]
  },
  "78247": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 21, 1966",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Everette Stephens",
    "rosters": [
      "3692013e-96e8-4e2b-8f7c-24755fc74d17",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5"
    ]
  },
  "78249": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 18, 1933",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Jack Stephens",
    "rosters": [
      "2fb0fbfc-bdbf-4af5-aab5-6c7da978842b"
    ]
  },
  "78250": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 07, 1959",
    "EXP": "2",
    "SCHOOL": "Georgia Tech",
    "name": "Brook Steppe",
    "rosters": [
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17"
    ]
  },
  "78251": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 17, 1963",
    "EXP": "R",
    "SCHOOL": "Iowa State",
    "name": "Barry Stevens",
    "rosters": [
      "4d8dc0a0-9b77-4771-ad3e-4861ded80060"
    ]
  },
  "78252": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 19, 1936",
    "EXP": "R",
    "SCHOOL": "Cincinnati",
    "name": "Wayne Stevens",
    "rosters": [
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2"
    ]
  },
  "78253": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 11, 1947",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Dennis Stewart",
    "rosters": [
      "ab91fee8-d6df-4549-ad42-04456017afc3"
    ]
  },
  "78256": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 20, 1935",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Norm Stewart",
    "rosters": [
      "83150f4c-0043-4b65-834a-7af2f1b9b071"
    ]
  },
  "78257": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "NOV 17, 1960",
    "EXP": "4",
    "SCHOOL": "Missouri",
    "name": "Steve Stipanovich",
    "rosters": [
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "85ec4e60-957a-4941-aa38-d850c52dbd29",
      "04c77a5b-af49-4a23-a09d-009b7ad9eabd",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3"
    ]
  },
  "78259": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 22, 1937",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "Sam Stith",
    "rosters": [
      "816164e0-e2ee-413e-8662-b51fbf40b841"
    ]
  },
  "78260": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 21, 1939",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "Tom Stith",
    "rosters": [
      "ba894f72-662a-4d86-8791-ddc3f2492c47"
    ]
  },
  "78261": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 29, 1962",
    "EXP": "R",
    "SCHOOL": "Colorado",
    "name": "Alex Stivrins",
    "rosters": [
      "0ff89067-4030-4b97-85ef-a563b3488116",
      "0d39d553-7b93-4206-b525-d87f764e4d5d"
    ]
  },
  "78264": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 05, 1963",
    "EXP": "1",
    "SCHOOL": "Iowa",
    "name": "Greg Stokes",
    "rosters": [
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff"
    ]
  },
  "78267": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 16, 1948",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Paul Stovall",
    "rosters": [
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c"
    ]
  },
  "78268": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 21, 1940",
    "EXP": "2",
    "SCHOOL": "Bradley",
    "name": "Joe Strawder",
    "rosters": [
      "61110628-8f7d-478f-b171-52455d3bf9d0",
      "65f693c5-b99c-4553-b1db-458345ed8c34",
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5"
    ]
  },
  "78269": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 22, 1948",
    "EXP": "R",
    "SCHOOL": "Pacific",
    "name": "Bill Stricker",
    "rosters": [
      "cc46ef6e-8b22-4041-80e0-59f4e62dfd40"
    ]
  },
  "78270": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 04, 1940",
    "EXP": "R",
    "SCHOOL": "Jacksonville",
    "name": "Roger Strickland",
    "rosters": [
      "fa4aff89-3225-4265-8365-6d1dcb219e6b"
    ]
  },
  "78271": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUL 24, 1958",
    "EXP": "R",
    "SCHOOL": "Montana",
    "name": "John Stroeder",
    "rosters": [
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "777d6706-d922-46f9-8db4-e079c5f46c74"
    ]
  },
  "78273": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 10, 1968",
    "EXP": "R",
    "SCHOOL": "Christopher Newport",
    "name": "Lamont Strothers",
    "rosters": [
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "f2508938-bae2-4775-8446-97b4a2c9038e"
    ]
  },
  "78274": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 29, 1957",
    "EXP": "R",
    "SCHOOL": "Mississippi",
    "name": "John Stroud",
    "rosters": [
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8"
    ]
  },
  "78275": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 13, 1923",
    "EXP": "1",
    "SCHOOL": "DePaul",
    "name": "Gene Stump",
    "rosters": [
      "e032e6bf-840a-444b-8044-7f6795db68e6",
      "fe9b3a4c-28b4-472c-bf2f-4421d945cba6"
    ]
  },
  "78276": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 14, 1920",
    "EXP": "1",
    "SCHOOL": "Rhode Island",
    "name": "Stan Stutz",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24",
      "bb996e83-b963-4fd6-b44b-484116ff5a4e"
    ]
  },
  "78277": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 18, 1945",
    "EXP": "R",
    "SCHOOL": "Midwestern State",
    "name": "Gary Suiter",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1"
    ]
  },
  "78278": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 20, 1929",
    "EXP": "1",
    "SCHOOL": "Illinois",
    "name": "Don Sunderlage",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "22dd26a7-1c99-45b7-a759-dce48ccb6829"
    ]
  },
  "78279": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUL 02, 1961",
    "EXP": "1",
    "SCHOOL": "Missouri",
    "name": "Jon Sundvold",
    "rosters": [
      "652536cb-d53b-4de7-a147-555aacb21048",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "e7b9ec7d-a840-4995-babb-299a22930d17",
      "50a8ba42-0d1f-40ac-b38f-8a4f3f159030",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd",
      "473f0beb-d4eb-46a7-b66f-d6831084a451",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4"
    ]
  },
  "78280": {
    "NUM": "14-17",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 16, 1929",
    "EXP": "R",
    "SCHOOL": "Long Island-Brooklyn",
    "name": "Dick Surhoff",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac"
    ]
  },
  "78281": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 09, 1961",
    "EXP": "1",
    "SCHOOL": "Pepperdine",
    "name": "Dane Suttle",
    "rosters": [
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e"
    ]
  },
  "78283": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 16, 1933",
    "EXP": "R",
    "SCHOOL": "Texas Southern",
    "name": "Bennie Swain",
    "rosters": [
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7"
    ]
  },
  "78284": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 04, 1930",
    "EXP": "R",
    "SCHOOL": "Detroit Mercy",
    "name": "Norm Swanson",
    "rosters": [
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6"
    ]
  },
  "78285": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 23, 1934",
    "EXP": "R",
    "SCHOOL": "Morehead State",
    "name": "Dan Swartz",
    "rosters": [
      "540c48fa-176d-4700-a617-fc71b5ac523b"
    ]
  },
  "78286": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 21, 1970",
    "EXP": "R",
    "SCHOOL": "Auburn",
    "name": "Aaron Swinson",
    "rosters": [
      "a7a2aca5-5373-4d2f-b138-bf2492160e90"
    ]
  },
  "78291": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "160",
    "BIRTH_DATE": "OCT 08, 1925",
    "EXP": "1",
    "SCHOOL": "New York University",
    "name": "Sid Tannenbaum",
    "rosters": [
      "bb996e83-b963-4fd6-b44b-484116ff5a4e",
      "df1fce20-7cea-47cc-8e99-cf6d772b3162"
    ]
  },
  "78293": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "7-0",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 28, 1964",
    "EXP": "5",
    "SCHOOL": "Michigan",
    "name": "Roy Tarpley",
    "rosters": [
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "d3cf8ad4-8983-4469-9c02-454ae703ad53",
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "373322f2-e185-4dfb-a02c-b13ec97d67ca"
    ]
  },
  "78294": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 26, 1953",
    "EXP": "2",
    "SCHOOL": "Marquette",
    "name": "Earl Tatum",
    "rosters": [
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "78295": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 30, 1965",
    "EXP": "R",
    "SCHOOL": "Oregon",
    "name": "Anthony Taylor",
    "rosters": [
      "652536cb-d53b-4de7-a147-555aacb21048"
    ]
  },
  "78296": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 09, 1951",
    "EXP": "R",
    "SCHOOL": "Princeton",
    "name": "Brian Taylor",
    "rosters": [
      "66743650-e493-4c91-a3ab-5a488a063464",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "ef880e29-8409-4464-a009-ac827384a450",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e"
    ]
  },
  "78297": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 03, 1967",
    "EXP": "R",
    "SCHOOL": "Eastern Illinois",
    "name": "Jay Taylor",
    "rosters": [
      "faa38736-32f4-41a2-8cca-8649fdee48af"
    ]
  },
  "78298": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 05, 1948",
    "EXP": "1",
    "SCHOOL": "Texas-Pan American",
    "name": "Fred Taylor",
    "rosters": [
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4"
    ]
  },
  "78299": {
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 01, 1960",
    "EXP": "1",
    "SCHOOL": "Texas Tech",
    "name": "Jeff Taylor",
    "rosters": [
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "999df20d-d256-45ec-8e64-111bdc7471d7"
    ]
  },
  "78301": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 02, 1966",
    "EXP": "R",
    "SCHOOL": "California",
    "name": "Leonard Taylor",
    "rosters": [
      "607283b5-4d8a-4cbf-8512-75892fe92cdf"
    ]
  },
  "78302": {
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 13, 1946",
    "EXP": "R",
    "SCHOOL": "La Salle",
    "name": "Roland Taylor",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967"
    ]
  },
  "78303": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 11, 1960",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Vince Taylor",
    "rosters": [
      "6a10cc0f-c034-4cee-992e-4023a7280654"
    ]
  },
  "78304": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 10, 1960",
    "EXP": "9",
    "SCHOOL": "Baylor",
    "name": "Terry Teagle",
    "rosters": [
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "14984cdc-30be-46d0-8591-dfe9b9b7c482",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a"
    ]
  },
  "78305": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 19, 1954",
    "EXP": "1",
    "SCHOOL": "Southern Methodist",
    "name": "Ira Terrell",
    "rosters": [
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "a8b9d60d-1449-49af-89fc-945fadd03a04"
    ]
  },
  "78306": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 27, 1950",
    "EXP": "2",
    "SCHOOL": "Long Beach State",
    "name": "Chuck Terry",
    "rosters": [
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "97c2ee90-873f-4c01-92e4-890bb08dda89"
    ]
  },
  "78307": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 22, 1956",
    "EXP": "2",
    "SCHOOL": "Winston-Salem State",
    "name": "Carlos Terry",
    "rosters": [
      "a52d7bc3-ed81-4836-b70b-85c958af2548",
      "f0072d27-be45-4cee-b65f-0d3b87790dcb",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95"
    ]
  },
  "78308": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 12, 1950",
    "EXP": "1",
    "SCHOOL": "Stanford",
    "name": "Claude Terry",
    "rosters": [
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "63f3df89-660b-46e0-ab32-a6754eb78888"
    ]
  },
  "78309": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "NOV 02, 1939",
    "EXP": "2",
    "SCHOOL": "Cincinnati",
    "name": "Tom Thacker",
    "rosters": [
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef"
    ]
  },
  "78310": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 13, 1957",
    "EXP": "9",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Reggie Theus",
    "rosters": [
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "4c6f87e3-5009-4111-80db-5390790d46bb",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47"
    ]
  },
  "78311": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 03, 1961",
    "EXP": "1",
    "SCHOOL": "St. Mary's (CA)",
    "name": "Peter Thibeaux",
    "rosters": [
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "e8f07ede-458f-46ff-8d3c-a054940f687f"
    ]
  },
  "78312": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 28, 1935",
    "EXP": "1",
    "SCHOOL": "Hofstra",
    "name": "Bill Thieben",
    "rosters": [
      "edb95a5d-c995-43f0-9378-d27051d259ab",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3"
    ]
  },
  "78313": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "160",
    "BIRTH_DATE": "AUG 13, 1947",
    "EXP": "R",
    "SCHOOL": "Weber State",
    "name": "Justus Thigpen",
    "rosters": [
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "5d519571-b461-4e3b-963a-2173662c4b55"
    ]
  },
  "78314": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 12, 1960",
    "EXP": "1",
    "SCHOOL": "Bradley",
    "name": "David Thirdkill",
    "rosters": [
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "d4bedead-ad99-4717-ae90-da27e0c99bec",
      "5c09ec26-5505-4508-98c5-db244798d9d1",
      "1a9e125c-e465-42e3-a800-c69234389252"
    ]
  },
  "78316": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 03, 1969",
    "EXP": "R",
    "SCHOOL": "Eastern Michigan",
    "name": "Charles Thomas",
    "rosters": [
      "db85dc84-6de1-4038-8c49-8f636dd20715"
    ]
  },
  "78317": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 02, 1966",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Irving Thomas",
    "rosters": [
      "46c73e8e-0262-4d24-bba5-f501568ed553"
    ]
  },
  "78318": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "APR 30, 1961",
    "EXP": "12",
    "SCHOOL": "Indiana",
    "name": "Isiah Thomas",
    "rosters": [
      "b49a0527-aa53-424d-aeb3-bc7f3d439938",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c",
      "db85dc84-6de1-4038-8c49-8f636dd20715",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "78320": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 19, 1960",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "Jim Thomas",
    "rosters": [
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "b7e6cae1-4596-4cf6-a0ad-785d01ebed61",
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "85ec4e60-957a-4941-aa38-d850c52dbd29"
    ]
  },
  "78321": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 27, 1948",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "Joe Thomas",
    "rosters": [
      "f5458f44-ac3e-4c53-a59d-73946dd09d03"
    ]
  },
  "78322": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 20, 1953",
    "EXP": "R",
    "SCHOOL": "Detroit Mercy",
    "name": "Terry Thomas",
    "rosters": [
      "e8407b6f-093b-43a2-ac16-f610ffc2510b"
    ]
  },
  "78323": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 30, 1962",
    "EXP": "R",
    "SCHOOL": "Fresno State",
    "name": "Bernard Thompson",
    "rosters": [
      "79d8e657-ce27-4052-9492-c9833808173f",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "90b30987-294d-488e-ade2-2e9dd74bb802",
      "ca66d0d0-8947-439e-8245-2fecd01da018",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3"
    ]
  },
  "78325": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 05, 1960",
    "EXP": "R",
    "SCHOOL": "Connecticut",
    "name": "Corny Thompson",
    "rosters": [
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9"
    ]
  },
  "78326": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 13, 1954",
    "EXP": "7",
    "SCHOOL": "North Carolina State",
    "name": "David Thompson",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967",
      "66743650-e493-4c91-a3ab-5a488a063464",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "a59119a0-b26d-4a60-b162-d2df226404ce",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce"
    ]
  },
  "78327": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 29, 1947",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "George Thompson",
    "rosters": [
      "bb2e2269-8e70-4d93-a583-5f824737ab1e"
    ]
  },
  "78328": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 02, 1941",
    "EXP": "1",
    "SCHOOL": "Providence",
    "name": "John Thompson",
    "rosters": [
      "40748532-955b-4d14-ab45-0409adb5a075",
      "5f4f7d08-7158-465f-83ab-59e46d24adfd"
    ]
  },
  "78329": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "FEB 07, 1971",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Kevin Thompson",
    "rosters": [
      "5861ab34-d588-4bda-9eb3-5d8dba9d98a2"
    ]
  },
  "78331": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "226",
    "BIRTH_DATE": "JAN 30, 1955",
    "EXP": "6",
    "SCHOOL": "Minnesota",
    "name": "Mychal Thompson",
    "rosters": [
      "b1898857-82db-453b-849b-591e70ae6807",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "46c73e8e-0262-4d24-bba5-f501568ed553",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2"
    ]
  },
  "78332": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 25, 1961",
    "EXP": "2",
    "SCHOOL": "Tulane",
    "name": "Paul Thompson",
    "rosters": [
      "1eb2a245-2321-463c-b8b3-437375f0d653",
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001"
    ]
  },
  "78333": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 02, 1968",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Stephen Thompson",
    "rosters": [
      "c1e63ab4-3f5c-47dd-ab0d-4f8bcc6d3504"
    ]
  },
  "78335": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 23, 1941",
    "EXP": "2",
    "SCHOOL": "West Virginia",
    "name": "Rod Thorn",
    "rosters": [
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "3829b824-1221-432d-8dc1-388a4d2f1cce",
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "fa4aff89-3225-4265-8365-6d1dcb219e6b",
      "3902314e-3111-45fa-9433-86d9fdae07db",
      "61110628-8f7d-478f-b171-52455d3bf9d0"
    ]
  },
  "78342": {
    "NUM": "41",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 30, 1940",
    "EXP": "R",
    "SCHOOL": "Thomas More",
    "name": "Dan Tieman",
    "rosters": [
      "855d0133-3349-470d-9362-7617b3f72e7f"
    ]
  },
  "78343": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 23, 1960",
    "EXP": "1",
    "SCHOOL": "Cleveland State",
    "name": "Darren Tillis",
    "rosters": [
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "aea08569-655f-4acc-8b71-27160b0a4dad"
    ]
  },
  "78344": {
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 30, 1924",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Jack Tingle",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503"
    ]
  },
  "78346": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 11, 1923",
    "EXP": "1",
    "SCHOOL": "Wyoming",
    "name": "Marko Todorovich",
    "rosters": [
      "e8e283cb-e932-4006-a2e4-decb30f70f3b"
    ]
  },
  "78347": {
    "NUM": "39",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 16, 1965",
    "EXP": "6",
    "SCHOOL": "Arizona",
    "name": "Tom Tolbert",
    "rosters": [
      "607283b5-4d8a-4cbf-8512-75892fe92cdf",
      "f45e82f9-7cbe-41dc-85ce-85176585f132",
      "3b5de1dd-634c-4faf-b5fe-5a55420cd942",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "18d1c64d-d0ad-45a1-8e81-9e9323769291"
    ]
  },
  "78348": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 10, 1958",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "Ray Tolbert",
    "rosters": [
      "00888d29-1563-459d-b34d-1bd9d30be418",
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252"
    ]
  },
  "78349": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 25, 1951",
    "EXP": "2",
    "SCHOOL": "Arkansas",
    "name": "Dean Tolson",
    "rosters": [
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "8718c236-d862-42de-a84d-ef29c83369b9",
      "6306dadf-1ee7-4678-b035-ef14578746a4"
    ]
  },
  "78350": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "218",
    "BIRTH_DATE": "NOV 24, 1948",
    "EXP": "10",
    "SCHOOL": "Michigan",
    "name": "Rudy Tomjanovich",
    "rosters": [
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a",
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8"
    ]
  },
  "78351": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "178",
    "BIRTH_DATE": "NOV 23, 1957",
    "EXP": "7",
    "SCHOOL": "Louisiana-Lafayette",
    "name": "Andrew Toney",
    "rosters": [
      "7495da12-4bc8-4edf-a24e-b24655504ff8",
      "7e9a1af7-4f46-430d-8892-71c61f76cc90",
      "bf317af6-865c-4121-a962-e203a54c91a3",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9"
    ]
  },
  "78352": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "178",
    "BIRTH_DATE": "APR 13, 1962",
    "EXP": "3",
    "SCHOOL": "Dayton",
    "name": "Sedric Toney",
    "rosters": [
      "2d01fe82-37d3-4641-8980-b545cafd6e1d",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "85c439de-6b3c-4f80-bf40-cc61ecb5ddc5",
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff"
    ]
  },
  "78355": {
    "POSITION": "C",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 09, 1922",
    "EXP": "1",
    "SCHOOL": "Pacific",
    "name": "Jack Toomay",
    "rosters": [
      "2bfdc9d7-2f64-4e8f-a827-0ec752496589"
    ]
  },
  "78356": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 14, 1956",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "Bernard Toone",
    "rosters": [
      "66ccd0f7-ed8e-49bb-9118-1ef961b339e4"
    ]
  },
  "78358": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 12, 1937",
    "EXP": "5",
    "SCHOOL": "Tennessee",
    "name": "Gene Tormohlen",
    "rosters": [
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "fd982b48-258b-4fc9-b6d2-1be39fa32925",
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "992d1ecc-4c9e-41e2-9a95-6165deedc409"
    ]
  },
  "78359": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 02, 1926",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "Bill Tosheff",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25"
    ]
  },
  "78360": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 28, 1920",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Bob Tough",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "78361": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "5-7",
    "WEIGHT": "150",
    "BIRTH_DATE": "SEP 27, 1953",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Monte Towe",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967"
    ]
  },
  "78364": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 30, 1959",
    "EXP": "2",
    "SCHOOL": "James Madison",
    "name": "Linton Townes",
    "rosters": [
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "5c09ec26-5505-4508-98c5-db244798d9d1"
    ]
  },
  "78365": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "DEC 20, 1955",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "Raymond Townsend",
    "rosters": [
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "ac1fa6b8-5cf5-4d30-badf-99be378ae917"
    ]
  },
  "78366": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 11, 1948",
    "EXP": "5",
    "SCHOOL": "Long Beach State",
    "name": "George Trapp",
    "rosters": [
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185",
      "94f36f95-2ce4-4f55-8a83-19b910b11438",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f"
    ]
  },
  "78367": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 02, 1945",
    "EXP": "4",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "John Trapp",
    "rosters": [
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "84e31c1f-82ce-462e-bbce-b0b659005594",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada"
    ]
  },
  "78368": {
    "NUM": "28",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "NOV 03, 1922",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Dick Triptow",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "78369": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 16, 1959",
    "EXP": "9",
    "SCHOOL": "Notre Dame",
    "name": "Kelly Tripucka",
    "rosters": [
      "324375ef-30c9-4fcb-86c6-f30147491d44",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "fff96d27-fc15-439f-b70c-c7f7b6d875a3",
      "440e1824-f688-481f-9c58-4951796e7ad1",
      "3007ea37-c35a-47e0-925b-da61b1dbbb1e"
    ]
  },
  "78370": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "206",
    "BIRTH_DATE": "APR 25, 1950",
    "EXP": "2",
    "SCHOOL": "California-Santa Barbara",
    "name": "John Tschogl",
    "rosters": [
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8"
    ]
  },
  "78371": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 31, 1930",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Lou Tsioropoulos",
    "rosters": [
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7"
    ]
  },
  "78372": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 24, 1943",
    "EXP": "3",
    "SCHOOL": "Oklahoma Baptist",
    "name": "Al Tucker",
    "rosters": [
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "ab91fee8-d6df-4549-ad42-04456017afc3"
    ]
  },
  "78374": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 11, 1932",
    "EXP": "2",
    "SCHOOL": "Duquesne",
    "name": "Jim Tucker",
    "rosters": [
      "bb00331b-e519-4f8f-b671-c89f34e10d57",
      "44a75fca-6d85-41a5-81a9-66a6b6ab9f10",
      "63ab2d0f-d691-48e0-9892-45f784ea03d5"
    ]
  },
  "78375": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "193",
    "BIRTH_DATE": "DEC 20, 1959",
    "EXP": "9",
    "SCHOOL": "Minnesota",
    "name": "Trent Tucker",
    "rosters": [
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "6a10cc0f-c034-4cee-992e-4023a7280654",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "61f90119-05dd-4779-bfd7-fcf297701a65"
    ]
  },
  "78377": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "160",
    "BIRTH_DATE": "DEC 13, 1964",
    "EXP": "3",
    "SCHOOL": "Memphis",
    "name": "Andre Turner",
    "rosters": [
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "fc505d11-c3d1-4a2b-8b0e-7ebff72b8db8",
      "7d91f1ee-4372-4388-bc8e-63bd70ae722a",
      "a78ca2e7-4e0e-4c60-905a-10253e6311e9",
      "f41c1355-5b81-4e4e-8e07-c6a6f34f91b1",
      "440e1824-f688-481f-9c58-4951796e7ad1"
    ]
  },
  "78378": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 10, 1959",
    "EXP": "7",
    "SCHOOL": "Mississippi",
    "name": "Elston Turner",
    "rosters": [
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "e5a6a74e-32e5-4965-829e-f866fe21f504"
    ]
  },
  "78379": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 18, 1966",
    "EXP": "1",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Henry Turner",
    "rosters": [
      "6bb8d6a2-1271-4871-ad5c-4b3444e44dff",
      "c2c2d971-f7bc-4a78-90d0-18edc4d177c1"
    ]
  },
  "78380": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUN 29, 1930",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Jack Turner",
    "rosters": [
      "bd85e665-05e8-4770-a5d0-dba1fabfc9aa"
    ]
  },
  "78381": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 09, 1962",
    "EXP": "9",
    "SCHOOL": "Vanderbilt",
    "name": "Jeff Turner",
    "rosters": [
      "8566310c-0a28-4492-9afd-453f232588e5",
      "f2ded0f3-c782-462b-8040-e231fa98bbf5",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62",
      "e140a3b1-5989-4956-a54f-45f979d33ba1",
      "8a1dd266-db70-441b-b5dd-519d06aedf50",
      "a953bcdf-106a-4d19-bcd4-22e0a8abbe14",
      "1a590baf-faf7-46e2-a591-e9ff55372b10"
    ]
  },
  "78382": {
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 05, 1939",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Jack Turner",
    "rosters": [
      "e5799d65-732c-4105-b48e-fe6c0e46eee6"
    ]
  },
  "78385": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 18, 1944",
    "EXP": "2",
    "SCHOOL": "Akron",
    "name": "Bill Turner",
    "rosters": [
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "852d8746-a465-41f3-b644-085c99bbad27",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa"
    ]
  },
  "78386": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "DEC 28, 1960",
    "EXP": "4",
    "SCHOOL": "Kentucky",
    "name": "Mel Turpin",
    "rosters": [
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "279912f2-e80a-4555-b8e8-e15af6f8f2fd",
      "c37d295a-100f-471b-8c29-666593f37dc3",
      "7c59c082-d7e1-4682-a48b-c1f1d2e4d44f",
      "44e38364-f6e8-4d07-bb01-e7f82356367c"
    ]
  },
  "78387": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 20, 1950",
    "EXP": "3",
    "SCHOOL": "Old Dominion",
    "name": "Dave Twardzik",
    "rosters": [
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "394b03f4-4f4f-4d1c-8ffe-6cac39d311cd",
      "4ac401d3-899b-427d-89d6-584fe69ebf75"
    ]
  },
  "78388": {
    "NUM": "27",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 11, 1934",
    "EXP": "10",
    "SCHOOL": "Cincinnati",
    "name": "Jack Twyman",
    "rosters": [
      "516c9243-1276-46d0-8dbe-f1db0a8384ad",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b",
      "21936ffd-2bd4-4505-a647-b4cd447d157b",
      "dc41b99f-710f-4f81-b125-45b52cf09053",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce",
      "020d6aa2-861a-4e30-8595-c38b6904fb12",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef"
    ]
  },
  "78390": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 30, 1956",
    "EXP": "6",
    "SCHOOL": "Detroit Mercy",
    "name": "Terry Tyler",
    "rosters": [
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22",
      "eb7ab102-a23d-4ed6-a8fe-7c01bc159f47",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b",
      "c163d65f-d6fd-4a44-968a-2897543255d4",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17"
    ]
  },
  "78391": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 16, 1935",
    "EXP": "4",
    "SCHOOL": "Louisville",
    "name": "Charlie Tyra",
    "rosters": [
      "6004a19c-3b95-40b2-b7d8-96f6b0ce494b",
      "1f6e95b4-5eb6-4d69-9ca1-e0f5ff9c8012",
      "8d0f9e12-8e11-4890-bb0c-b480f9fbf76b",
      "2fb8b948-e857-417f-8af2-422f0825a7c1",
      "e5799d65-732c-4105-b48e-fe6c0e46eee6"
    ]
  },
  "78392": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAR 14, 1946",
    "EXP": "12",
    "SCHOOL": "Louisville",
    "name": "Wes Unseld",
    "rosters": [
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "0403c301-445c-4c5d-8144-e5805bdc801c",
      "ab91fee8-d6df-4549-ad42-04456017afc3",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "024d58a0-dd09-4396-a891-82ba087f7279",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "fd666397-8775-4cb6-9c9b-67881c31f005",
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66",
      "dd527857-adb8-40c3-9804-8aa4212b8486",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409",
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "78394": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 24, 1963",
    "EXP": "1",
    "SCHOOL": "Utah",
    "name": "Kelvin Upshaw",
    "rosters": [
      "a00084cd-698b-4fed-9bea-3d9275c16bfa",
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "607283b5-4d8a-4cbf-8512-75892fe92cdf"
    ]
  },
  "78395": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "183",
    "BIRTH_DATE": "FEB 03, 1959",
    "EXP": "4",
    "SCHOOL": "Kansas",
    "name": "Darnell Valentine",
    "rosters": [
      "31b331bf-d3c1-4d54-9975-3c5e8d268691",
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2"
    ]
  },
  "78396": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 27, 1957",
    "EXP": "R",
    "SCHOOL": "Old Dominion",
    "name": "Ron Valentine",
    "rosters": [
      "d941e1a4-3192-4944-be68-b1cbdacc880a"
    ]
  },
  "78397": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 03, 1948",
    "EXP": "1",
    "SCHOOL": "California-Los Angeles",
    "name": "John Vallely",
    "rosters": [
      "fa657246-a721-4d64-b321-9a6be5364290",
      "92ed15bb-e4b9-4237-b321-f82f5e329c1a"
    ]
  },
  "78398": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "FEB 22, 1943",
    "EXP": "11",
    "SCHOOL": "Indiana",
    "name": "Dick Van Arsdale",
    "rosters": [
      "833e0d65-644b-4fde-b265-1327632fc59c",
      "054fdbaa-a097-480c-b2de-7711c6b6734b",
      "4d62aaac-8788-4aab-bdec-4738995edafd",
      "20955b01-8d24-45f5-ba05-3a4253c801f7",
      "c5964149-f86c-413e-9d7c-c8d07f4113ef",
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22"
    ]
  },
  "78399": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "202",
    "BIRTH_DATE": "FEB 22, 1943",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "Tom Van Arsdale",
    "rosters": [
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "4eeaa401-c180-46ca-9ec8-b1442a9cfada",
      "992f03c2-845c-48f9-8e9d-5f3af3edf011",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa",
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4",
      "61110628-8f7d-478f-b171-52455d3bf9d0",
      "65f693c5-b99c-4553-b1db-458345ed8c34"
    ]
  },
  "78400": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 16, 1951",
    "EXP": "6",
    "SCHOOL": "Vanderbilt",
    "name": "Jan Van Breda Kolff",
    "rosters": [
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28"
    ]
  },
  "78401": {
    "NUM": "17",
    "POSITION": "G-F",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 28, 1922",
    "EXP": "3",
    "SCHOOL": "New York University",
    "name": "Butch Van Breda Kolff",
    "rosters": [
      "5863b9f0-adf0-4042-9912-f445b7c8ee24",
      "bb996e83-b963-4fd6-b44b-484116ff5a4e",
      "df1fce20-7cea-47cc-8e99-cf6d772b3162",
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7"
    ]
  },
  "78402": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 25, 1923",
    "EXP": "4",
    "SCHOOL": "Illinois",
    "name": "Gene Vance",
    "rosters": [
      "6d008515-8165-4990-a179-942eeec92822",
      "e8e283cb-e932-4006-a2e4-decb30f70f3b",
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "78403": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 03, 1971",
    "EXP": "R",
    "SCHOOL": "Green Bay",
    "name": "Log Vander Velden",
    "rosters": [
      "44875f13-0e20-4679-bbfd-0c88cc9ba953"
    ]
  },
  "78404": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 01, 1958",
    "EXP": "7",
    "SCHOOL": "California-Los Angeles",
    "name": "Kiki Vandeweghe",
    "rosters": [
      "d941e1a4-3192-4944-be68-b1cbdacc880a",
      "051f86ce-ff57-4132-8a18-d40f37418bfc",
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "606a6691-6b18-478d-b60f-85baee1be70c",
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392",
      "3e1b381e-5446-4d31-84cd-e21a71c4c0d3",
      "0d4731b5-ed19-40a8-96dd-6e48f9ffd3d2",
      "3367b969-0b6f-4aa4-927e-679d12463f2b",
      "04ef3e81-d8fb-48a8-ba78-694468f81686"
    ]
  },
  "78405": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 12, 1928",
    "EXP": "5",
    "SCHOOL": "Colgate",
    "name": "Ernie Vandeweghe",
    "rosters": [
      "6e83fe2a-f649-4f13-b0c9-92e7fe7a57d7",
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac",
      "c0b3924a-2f2b-49f1-85d0-5f1b2cd7369d",
      "07b33c54-ebb1-4a81-b9e1-f1864b60cec8"
    ]
  },
  "78406": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "173",
    "BIRTH_DATE": "APR 01, 1947",
    "EXP": "2",
    "SCHOOL": "Saint Francis (PA)",
    "name": "Norm Van Lier",
    "rosters": [
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "66953847-11ae-490d-ba1c-76473d320167",
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa",
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4"
    ]
  },
  "78407": {
    "NUM": "30",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "255",
    "BIRTH_DATE": "APR 13, 1963",
    "EXP": "1",
    "SCHOOL": "Santa Clara",
    "name": "Nick Vanos",
    "rosters": [
      "2f38049d-f39e-4d79-9815-11d4fdf4013f",
      "90b30987-294d-488e-ade2-2e9dd74bb802"
    ]
  },
  "78409": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 19, 1940",
    "EXP": "4",
    "SCHOOL": "Southern Illinois",
    "name": "Charles Vaughn",
    "rosters": [
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "61110628-8f7d-478f-b171-52455d3bf9d0",
      "65f693c5-b99c-4553-b1db-458345ed8c34"
    ]
  },
  "78411": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAY 15, 1918",
    "EXP": "R",
    "SCHOOL": "Western Kentucky",
    "name": "Virgil Vaughn",
    "rosters": [
      "6968c993-52b5-4f36-8d4d-8062bfe11654"
    ]
  },
  "78413": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 07, 1945",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Bob Verga",
    "rosters": [
      "dd803214-fd70-44fe-988b-85ae27a94245"
    ]
  },
  "78414": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 15, 1959",
    "EXP": "3",
    "SCHOOL": "Fresno State",
    "name": "Peter Verhoeven",
    "rosters": [
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "29876b5c-fbfc-46fc-9f3b-279e3667230a",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f",
      "80997709-1fc9-4ada-b330-ff77310ab65f",
      "ce9f3810-9f45-4e5a-8479-92c52c78194c",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e"
    ]
  },
  "78415": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 22, 1967",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Gundars Vetra",
    "rosters": [
      "3b167c9d-66a7-4736-b5f2-604aa433bb39"
    ]
  },
  "78416": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 15, 1966",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Joao Vianna",
    "rosters": [
      "0ac01206-d8e9-4609-aff9-f970a8acfada"
    ]
  },
  "78417": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 18, 1963",
    "EXP": "6",
    "SCHOOL": "Michigan State",
    "name": "Sam Vincent",
    "rosters": [
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "703270c9-07d0-4181-8c5d-d52bb7fd0e9c",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964",
      "8dabde25-68d6-46b8-af0a-488c49653a62"
    ]
  },
  "78418": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 10, 1959",
    "EXP": "5",
    "SCHOOL": "Michigan State",
    "name": "Jay Vincent",
    "rosters": [
      "416a441e-349c-43bf-8a25-d9b8fa253a5c",
      "4d52ede9-cb7f-424a-a5dd-419dcbbe4dc9",
      "efc3062e-498e-41c3-bf80-5f2b1ff7e0e0",
      "24df2a02-fbcc-4ed2-89ad-e607d6f12fbd",
      "562818d9-6fdb-4cb2-b851-4748b1c638f2",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "4567ce1a-2538-43ab-b37e-44c2913ab119"
    ]
  },
  "78419": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 24, 1965",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Gary Voce",
    "rosters": [
      "4f39cfda-e726-4c4d-9553-4328412edaf3"
    ]
  },
  "78421": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "218",
    "BIRTH_DATE": "MAR 29, 1964",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Alexander Volkov",
    "rosters": [
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9",
      "6fd292ed-c920-4655-a87f-7bf233c75094"
    ]
  },
  "78423": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 29, 1958",
    "EXP": "4",
    "SCHOOL": "Utah",
    "name": "Danny Vranes",
    "rosters": [
      "3d24a988-08f7-4aad-ae1e-c528947c7844",
      "b42378b8-dafb-4789-b4ee-3aba286db3a9",
      "9cf046e5-f475-41ef-a3d3-f517368a157b",
      "a59119a0-b26d-4a60-b162-d2df226404ce",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "0d39d553-7b93-4206-b525-d87f764e4d5d"
    ]
  },
  "78425": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 25, 1955",
    "EXP": "R",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Brett Vroman",
    "rosters": [
      "79272c8a-a0ac-4650-b0a6-e971f46652f5"
    ]
  },
  "78426": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "160",
    "BIRTH_DATE": "OCT 15, 1965",
    "EXP": "R",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Mark Wade",
    "rosters": [
      "a4b0ca7d-300a-4686-bcc4-415123e8580a",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d"
    ]
  },
  "78427": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 20, 1920",
    "EXP": "R",
    "SCHOOL": "Saint Mary's (MN)",
    "name": "Clint Wager",
    "rosters": [
      "0e9f285d-9c90-4311-ab96-3d46e6fd7d9e"
    ]
  },
  "78429": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 20, 1963",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Milt Wagner",
    "rosters": [
      "7d322c7b-346e-4ea5-8ea5-7853b1283a3e",
      "e7b9ec7d-a840-4995-babb-299a22930d17"
    ]
  },
  "78430": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 08, 1961",
    "EXP": "1",
    "SCHOOL": "Ohio State",
    "name": "Granville Waiters",
    "rosters": [
      "eb5f39fc-ff99-46c0-9481-df583ece8a3f",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "84846342-36c1-4dc5-96ab-243c663b2065",
      "85ec4e60-957a-4941-aa38-d850c52dbd29"
    ]
  },
  "78431": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 11, 1955",
    "EXP": "R",
    "SCHOOL": "Loyola-Chicago",
    "name": "Andre Wakefield",
    "rosters": [
      "3d64e1d0-7221-4ce7-831f-5d02089042ba",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689"
    ]
  },
  "78432": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 29, 1948",
    "EXP": "5",
    "SCHOOL": "Florida",
    "name": "Neal Walk",
    "rosters": [
      "52649402-55a4-4d97-a5fd-f7cf9779a672",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "c5964149-f86c-413e-9d7c-c8d07f4113ef",
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "78433": {
    "NUM": "41",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 25, 1955",
    "EXP": "R",
    "SCHOOL": "Niagara",
    "name": "Andy Walker",
    "rosters": [
      "4100a46a-2845-4fc5-b5a7-28c783b0e827"
    ]
  },
  "78434": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 15, 1921",
    "EXP": "1",
    "SCHOOL": "Brigham Young",
    "name": "Brady Walker",
    "rosters": [
      "0fed28f3-2e7c-4840-a01b-655dd8b56ec1"
    ]
  },
  "78435": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "212",
    "BIRTH_DATE": "FEB 22, 1940",
    "EXP": "6",
    "SCHOOL": "Bradley",
    "name": "Chet Walker",
    "rosters": [
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2",
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "66953847-11ae-490d-ba1c-76473d320167",
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a",
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "56bc558b-98d9-4a75-9033-fbec8e9fe666",
      "e08b4914-59a6-4d7e-836c-db576c3c3723"
    ]
  },
  "78436": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "172",
    "BIRTH_DATE": "MAY 21, 1951",
    "EXP": "9",
    "SCHOOL": "West Georgia",
    "name": "Foots Walker",
    "rosters": [
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86",
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "4a220bca-138c-4d87-babd-217c513c6b5c",
      "edd9bfc6-82e2-4349-b160-322801b25a0a",
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9"
    ]
  },
  "78437": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 09, 1961",
    "EXP": "8",
    "SCHOOL": "Arkansas",
    "name": "Darrell Walker",
    "rosters": [
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58",
      "44e38364-f6e8-4d07-bb01-e7f82356367c",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6",
      "db85dc84-6de1-4038-8c49-8f636dd20715"
    ]
  },
  "78438": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-3",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 17, 1938",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Horace Walker",
    "rosters": [
      "e5799d65-732c-4105-b48e-fe6c0e46eee6"
    ]
  },
  "78439": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 08, 1944",
    "EXP": "4",
    "SCHOOL": "Providence",
    "name": "Jimmy Walker",
    "rosters": [
      "69ac3cf7-6042-40b1-b780-0bea2ee84e0a",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "66c852c8-74e2-482e-8745-846263fa3f58",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "e7ef6b6a-a34b-48bc-95f7-9636abc598f5",
      "49db955c-2a3f-4693-b68f-834ab5003c4d",
      "d80f75e6-baf7-4843-8122-aa743aea3477",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226"
    ]
  },
  "78440": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 18, 1964",
    "EXP": "6",
    "SCHOOL": "Kentucky",
    "name": "Kenny Walker",
    "rosters": [
      "b2182580-6b74-4d01-905a-961e5a283423",
      "f4e181bd-77c9-4efb-9f15-b44531dc1d44",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "4dba6c84-3a6a-46de-b210-f408a2b2efb7",
      "fa3c62ff-1a19-4597-a827-0005f973f6aa"
    ]
  },
  "78441": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 20, 1956",
    "EXP": "R",
    "SCHOOL": "Millersville",
    "name": "Phil Walker",
    "rosters": [
      "dd527857-adb8-40c3-9804-8aa4212b8486"
    ]
  },
  "78442": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 18, 1954",
    "EXP": "5",
    "SCHOOL": "Virginia",
    "name": "Wally Walker",
    "rosters": [
      "5260f6ab-6888-499d-bfc9-f1e6db1f1e5a",
      "03cc21be-0761-49ef-83d1-c28b52282177",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "278e33a4-dd30-4a35-b7be-2e7b71623707",
      "6cb87ca5-b208-46a1-b393-022423c35c0d",
      "9cf046e5-f475-41ef-a3d3-f517368a157b"
    ]
  },
  "78444": {
    "NUM": "16-34",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 05, 1945",
    "EXP": "R",
    "SCHOOL": "Tennessee State",
    "name": "Dwight Waller",
    "rosters": [
      "385be451-82b9-4142-849d-362d686d325b"
    ]
  },
  "78445": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 20, 1964",
    "EXP": "R",
    "SCHOOL": "Virginia Union",
    "name": "Jamie Waller",
    "rosters": [
      "5c6068d0-8b6b-4473-8dce-c22be7e22766"
    ]
  },
  "78446": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 29, 1931",
    "EXP": "R",
    "SCHOOL": "Stanford",
    "name": "Jim Walsh",
    "rosters": [
      "eca4ab58-7709-4f1a-a4b0-4ac2cf730447"
    ]
  },
  "78447": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "160",
    "BIRTH_DATE": "MAR 23, 1927",
    "EXP": "5",
    "SCHOOL": "Tennessee",
    "name": "Paul Walther",
    "rosters": [
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f",
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9",
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91"
    ]
  },
  "78448": {
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "163",
    "BIRTH_DATE": "APR 11, 1905",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Rabbit Walthour",
    "rosters": [
      "2ad5d0c9-b624-431d-b5e2-fe34429aff25"
    ]
  },
  "78449": {
    "NUM": "11  12",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "160",
    "BIRTH_DATE": "NOV 23, 1953",
    "EXP": "4",
    "SCHOOL": "Marquette",
    "name": "Lloyd Walton",
    "rosters": [
      "743aba57-7e93-4575-8cb7-e3de53d6223c",
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d",
      "19686855-a120-434c-8ad4-5f15e61b9a38"
    ]
  },
  "78450": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 05, 1952",
    "EXP": "3",
    "SCHOOL": "California-Los Angeles",
    "name": "Bill Walton",
    "rosters": [
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "ef880e29-8409-4464-a009-ac827384a450",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "cbf01feb-6986-43c2-8350-23e707cf8272",
      "83cc8718-e77c-472a-b77e-ed262ef4cfba",
      "a643026c-452d-404f-9bec-ce36434b7ef9"
    ]
  },
  "78453": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUN 04, 1921",
    "EXP": "8",
    "SCHOOL": "Seton Hall",
    "name": "Bobby Wanzer",
    "rosters": [
      "9a8eca6a-8c6e-42b3-bea5-c106318f71f9",
      "06b16798-5051-4dbc-8f77-316dfd3f76a0",
      "6146894f-3e97-4aab-a027-77228d1955a6",
      "817867c3-ef94-48b9-81aa-768f4f8f758e",
      "a0311ade-2019-4595-ab92-26a1696f3ce7",
      "18b844a4-6bd1-4a67-acac-df8b594d9cc6",
      "16101391-6b81-4a3f-a8d4-55f40ee7e8af",
      "516c9243-1276-46d0-8dbe-f1db0a8384ad",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b"
    ]
  },
  "78454": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "165",
    "BIRTH_DATE": "SEP 07, 1952",
    "EXP": "R",
    "SCHOOL": "Georgia Southern",
    "name": "Perry Warbington",
    "rosters": [
      "83544fe4-e3ff-4b9e-abe7-35aafd8e84c8"
    ]
  },
  "78455": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 06, 1941",
    "EXP": "2",
    "SCHOOL": "Boston College",
    "name": "Gerry Ward",
    "rosters": [
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "40748532-955b-4d14-ab45-0409adb5a075",
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca"
    ]
  },
  "78456": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 30, 1952",
    "EXP": "R",
    "SCHOOL": "Jackson State",
    "name": "Henry Ward",
    "rosters": [
      "02822d1e-e608-4487-ba05-46ffbf5312fe"
    ]
  },
  "78457": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 02, 1944",
    "EXP": "R",
    "SCHOOL": "Oklahoma City",
    "name": "Jim Ware",
    "rosters": [
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "38c6a8b5-9c04-429f-9873-476923c33722"
    ]
  },
  "78458": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 04, 1936",
    "EXP": "4",
    "SCHOOL": "Tennessee State",
    "name": "Ben Warley",
    "rosters": [
      "1ac58220-677b-4b39-88b8-e0f140cc4134",
      "084db916-a6ce-4651-bbc2-a41c3e21369a",
      "f63ac8fb-d11e-4170-9686-e89dcbf20b22",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca",
      "1afcc38d-6d62-4ced-a2db-41985c45adb4"
    ]
  },
  "78459": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 20, 1941",
    "EXP": "R",
    "SCHOOL": "Pepperdine",
    "name": "Bob Warlick",
    "rosters": [
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "20955b01-8d24-45f5-ba05-3a4253c801f7",
      "61110628-8f7d-478f-b171-52455d3bf9d0"
    ]
  },
  "78460": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 12, 1948",
    "EXP": "4",
    "SCHOOL": "Jackson State",
    "name": "Cornell Warner",
    "rosters": [
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "e5b7f06b-9021-490e-b017-00f08519f3d1",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e"
    ]
  },
  "78461": {
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 07, 1947",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "Johnny Warren",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1",
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "3fb360bc-873c-4a39-b17b-8acdb404a070"
    ]
  },
  "78462": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 22, 1959",
    "EXP": "1",
    "SCHOOL": "Saint Joseph's",
    "name": "Bryan Warrick",
    "rosters": [
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "fac543c5-7a72-481e-87a4-e3d382c18eec",
      "2d7fe7d6-364f-45d0-bc74-80d3d484ad95",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407"
    ]
  },
  "78463": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 13, 1965",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Chris Washburn",
    "rosters": [
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "105e54a0-ada0-4f0c-a038-906cd453091e"
    ]
  },
  "78464": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 31, 1964",
    "EXP": "R",
    "SCHOOL": "Middle Tennessee State",
    "name": "Duane Washington",
    "rosters": [
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766"
    ]
  },
  "78465": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 06, 1964",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Dwayne Washington",
    "rosters": [
      "652536cb-d53b-4de7-a147-555aacb21048",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766"
    ]
  },
  "78466": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 01, 1943",
    "EXP": "6",
    "SCHOOL": "Villanova",
    "name": "Jim Washington",
    "rosters": [
      "fd982b48-258b-4fc9-b6d2-1be39fa32925",
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "cd81f169-3cb2-46b5-bdc3-08821680d249",
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "5ea792bd-890a-47b6-babe-2afabfd78f09",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "8eeab17f-aa62-4d99-9e73-e5182968de63",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1"
    ]
  },
  "78467": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 17, 1951",
    "EXP": "8",
    "SCHOOL": "American",
    "name": "Kermit Washington",
    "rosters": [
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "c39c5026-a493-40db-84cd-272c3b126805",
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "4f6e72ef-a5a4-4e43-93fa-c41c52026b77",
      "4ac401d3-899b-427d-89d6-584fe69ebf75",
      "85fd1fbb-8fd0-451c-ad0f-c31e10a5c18e",
      "df26c905-cf11-4e99-bd8a-dd6ad23b6b0f"
    ]
  },
  "78468": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 15, 1955",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "Richard Washington",
    "rosters": [
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d",
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19"
    ]
  },
  "78469": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 11, 1947",
    "EXP": "1",
    "SCHOOL": "Eastern Kentucky",
    "name": "Bobby Washington",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1",
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca"
    ]
  },
  "78470": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 23, 1952",
    "EXP": "R",
    "SCHOOL": "San Diego",
    "name": "Stan Washington",
    "rosters": [
      "852a9389-6b8e-49c6-a029-8397ecfa81d7"
    ]
  },
  "78471": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "227",
    "BIRTH_DATE": "AUG 03, 1955",
    "EXP": "1",
    "SCHOOL": "Old Dominion",
    "name": "Wilson Washington",
    "rosters": [
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "cac91d50-2f65-4d2c-b139-8486bcc4842f"
    ]
  },
  "78473": {
    "NUM": "00-14",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 22, 1951",
    "EXP": "4",
    "SCHOOL": "Xavier (LA)",
    "name": "Slick Watts",
    "rosters": [
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3",
      "cc792a0f-fa98-4caf-ad55-38fe38f01714",
      "e5c309ea-98c9-4e9e-808e-79e5e4d1dd5c",
      "8718c236-d862-42de-a84d-ef29c83369b9",
      "5517a85a-4623-414c-89a6-b90bb5a42eca"
    ]
  },
  "78474": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 21, 1943",
    "EXP": "1",
    "SCHOOL": "Wake Forest",
    "name": "Ron Watts",
    "rosters": [
      "5f4f7d08-7158-465f-83ab-59e46d24adfd",
      "de3c97df-aa08-40c1-bb58-7d65bf023583"
    ]
  },
  "78475": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 20, 1950",
    "EXP": "2",
    "SCHOOL": "Illinois",
    "name": "Nick Weatherspoon",
    "rosters": [
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "ef880e29-8409-4464-a009-ac827384a450",
      "8718c236-d862-42de-a84d-ef29c83369b9",
      "024d58a0-dd09-4396-a891-82ba087f7279",
      "852a9389-6b8e-49c6-a029-8397ecfa81d7",
      "fd666397-8775-4cb6-9c9b-67881c31f005"
    ]
  },
  "78477": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUL 06, 1948",
    "EXP": "1",
    "SCHOOL": "Kansas State",
    "name": "Jeff Webb",
    "rosters": [
      "30c679a6-4b92-42bc-b03d-303894f5aed9",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e"
    ]
  },
  "78478": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAY 09, 1970",
    "EXP": "R",
    "SCHOOL": "Alabama",
    "name": "Marcus Webb",
    "rosters": [
      "5f014df6-a40d-42f4-9def-c68b338e0ee2"
    ]
  },
  "78481": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 13, 1952",
    "EXP": "1",
    "SCHOOL": "Morgan State",
    "name": "Marvin Webster",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967",
      "0cb17c3a-eb4e-4627-8baf-29d4e33466cd",
      "b001782c-95ad-444b-814b-98ecf23fa9ce",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff",
      "6a10cc0f-c034-4cee-992e-4023a7280654",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "6306dadf-1ee7-4678-b035-ef14578746a4"
    ]
  },
  "78482": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 29, 1952",
    "EXP": "6",
    "SCHOOL": "Colorado",
    "name": "Scott Wedman",
    "rosters": [
      "2e18f5f5-8e9e-42be-aa6d-76f2e0b4e33a",
      "34f78999-59e2-4fc8-9a4e-a0bbce491c6e",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "4280400f-b4ac-4e64-b5fb-a764ff206ced",
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "66c852c8-74e2-482e-8745-846263fa3f58",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "cdddb982-7e73-438a-a4ff-84b2d2b2276e",
      "67dffcf3-787e-41ff-96c5-6b26de5d8cef",
      "76734480-3ae8-4ed7-b5b4-513aa11bce19",
      "1df46296-cc39-4688-95c6-3656430e4ae2",
      "19686855-a120-434c-8ad4-5f15e61b9a38"
    ]
  },
  "78484": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 28, 1960",
    "EXP": "R",
    "SCHOOL": "William & Mary",
    "name": "Brant Weidner",
    "rosters": [
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2"
    ]
  },
  "78485": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 07, 1942",
    "EXP": "11",
    "SCHOOL": "Penn State",
    "name": "Bob Weiss",
    "rosters": [
      "5ea792bd-890a-47b6-babe-2afabfd78f09",
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "0e3936ae-8cc6-4372-ac15-b7ad7c0e93c2",
      "940cea5e-14c6-4fd7-a44a-e5415c80a822",
      "23be4e16-3397-4e06-9037-ee9c4d7cdc1a",
      "aeff072b-6e53-45e9-a24f-d0c71bcf2dd5",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "1704d9ad-eb88-4144-8369-9d87109b6bf6",
      "343311f1-9773-47b8-ae18-71fe7ebdd3ca",
      "62fe75ba-1452-4175-8c96-ffc331d417fb",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa",
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66"
    ]
  },
  "78486": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "APR 30, 1946",
    "EXP": "R",
    "SCHOOL": "Northeastern",
    "name": "Rick Weitzman",
    "rosters": [
      "c2d3d982-20dd-4625-ae6e-e60fe81ca32a"
    ]
  },
  "78488": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 09, 1950",
    "EXP": "R",
    "SCHOOL": "Detroit Mercy",
    "name": "Owen Wells",
    "rosters": [
      "3f4d6adc-f325-4204-a5ca-f7eccb585194"
    ]
  },
  "78489": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 03, 1940",
    "EXP": "R",
    "SCHOOL": "Northwestern",
    "name": "Ralph Wells",
    "rosters": [
      "f32d2ba2-4f9f-404a-9cdd-43307e95a7df"
    ]
  },
  "78492": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 04, 1970",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Matt Wenstrom",
    "rosters": [
      "aedf3815-98a8-4388-ba46-c3ae826b2947"
    ]
  },
  "78493": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 12, 1969",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Robert Werdann",
    "rosters": [
      "d8471143-7caa-497d-8696-5d0bb039d3c2"
    ]
  },
  "78495": {
    "NUM": "31-44",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 25, 1945",
    "EXP": "7",
    "SCHOOL": "Kansas",
    "name": "Walt Wesley",
    "rosters": [
      "03595ca5-71b0-4951-8a4c-75061591b9a1",
      "cbab4ae6-1b09-4518-830c-64f0bf1b90ca",
      "9f64bae2-055b-4727-8d9e-dee954e0bd10",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e",
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "38c6a8b5-9c04-429f-9873-476923c33722",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604",
      "024d58a0-dd09-4396-a891-82ba087f7279"
    ]
  },
  "78497": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 28, 1938",
    "EXP": "13",
    "SCHOOL": "West Virginia",
    "name": "Jerry West",
    "rosters": [
      "8c6d5cc4-e6c7-4f5b-ba90-ec01442498bf",
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29",
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d",
      "e0535701-30f0-4faf-8df8-bf9256a7d8c9",
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "0607a2e4-4900-4392-af54-76ee097fe467",
      "35c1b1f9-52bb-4cce-a910-da3b5e26cb00",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "a289a8c1-f96f-4f91-b664-d6c98ed35f2f",
      "83d54ae1-cfb6-4ad8-a59b-17cbb59236e3",
      "c39c5026-a493-40db-84cd-272c3b126805"
    ]
  },
  "78499": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "178",
    "BIRTH_DATE": "JUN 06, 1944",
    "EXP": "R",
    "SCHOOL": "Cincinnati",
    "name": "Roland West",
    "rosters": [
      "67287c3d-6ead-43c1-8eff-47519e8bbeea"
    ]
  },
  "78500": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 30, 1950",
    "EXP": "8",
    "SCHOOL": "Southern California",
    "name": "Paul Westphal",
    "rosters": [
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff",
      "6a10cc0f-c034-4cee-992e-4023a7280654",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74",
      "4bf319d2-c1c2-499e-aef3-f5e87baadb22",
      "bcc8b7c3-72af-437c-9340-7a5f3168f0de",
      "e8500690-7102-4acf-ae8b-f1907cf60bcf",
      "bbb2dde4-01ca-4734-babd-de880d259b13",
      "35e49d21-3b65-44c4-8cd2-e401df91e976",
      "6cb87ca5-b208-46a1-b393-022423c35c0d"
    ]
  },
  "78501": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 22, 1944",
    "EXP": "6",
    "SCHOOL": "Virginia Tech",
    "name": "John Wetzel",
    "rosters": [
      "690bc2a9-162d-412a-8944-2faf83fc5604",
      "662a66d9-119b-4346-9391-e2e73fb7a872",
      "52ac4966-7c29-485c-b025-f837eb878c5b",
      "b58e37fe-d855-4948-b0f9-9422b49423ba",
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "7c99413d-eb44-4cb6-96e8-20bc92679f74"
    ]
  },
  "78504": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 27, 1959",
    "EXP": "1",
    "SCHOOL": "William Paterson",
    "name": "Clinton Wheeler",
    "rosters": [
      "aa3e1a1a-7dc9-4bed-b24d-5eb92be49ee3",
      "148d19d7-f6a8-401d-b449-7e8b111f7959"
    ]
  },
  "78506": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 29, 1930",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Lucian Whitaker",
    "rosters": [
      "0f305d34-33d7-4e16-a669-f7ab442c151d"
    ]
  },
  "78507": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 30, 1965",
    "EXP": "1",
    "SCHOOL": "Pepperdine",
    "name": "Eric White",
    "rosters": [
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "03a36ce1-3d63-4f28-b362-56b1356f738d"
    ]
  },
  "78508": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 15, 1948",
    "EXP": "R",
    "SCHOOL": "Georgia",
    "name": "Herb White",
    "rosters": [
      "fa657246-a721-4d64-b321-9a6be5364290"
    ]
  },
  "78509": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 26, 1940",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Hubie White",
    "rosters": [
      "60508db9-69f9-4133-ac4d-b9848b2add2d",
      "084db916-a6ce-4651-bbc2-a41c3e21369a"
    ]
  },
  "78510": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 16, 1946",
    "EXP": "11",
    "SCHOOL": "Kansas",
    "name": "Jojo White",
    "rosters": [
      "c068cf79-fb5f-4f83-8302-92b10a5dcfff",
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9",
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "6d722c8b-189c-46c8-b21a-1089287af8e0",
      "2acbc0d5-e653-4e06-9ad8-41b7a0c0b5ac",
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc",
      "19686855-a120-434c-8ad4-5f15e61b9a38"
    ]
  },
  "78512": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 16, 1959",
    "EXP": "R",
    "SCHOOL": "South Alabama",
    "name": "Rory White",
    "rosters": [
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "d4bedead-ad99-4717-ae90-da27e0c99bec"
    ]
  },
  "78513": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 23, 1953",
    "EXP": "4",
    "SCHOOL": "Arizona State",
    "name": "Rudy White",
    "rosters": [
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "1f08be28-cf75-4838-8319-73b4c61b0c71",
      "bcf8abc7-2e95-4058-8cd4-c9c3751281ff",
      "08b96232-bec8-4c70-a55c-7f13e94a9137",
      "6cb87ca5-b208-46a1-b393-022423c35c0d"
    ]
  },
  "78514": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "FEB 15, 1965",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Tony White",
    "rosters": [
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d"
    ]
  },
  "78515": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 20, 1962",
    "EXP": "1",
    "SCHOOL": "Tennessee-Chattanooga",
    "name": "Willie White",
    "rosters": [
      "92aca97c-c546-4b12-80d7-3f47b2556f23",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a"
    ]
  },
  "78516": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 30, 1956",
    "EXP": "1",
    "SCHOOL": "Marquette",
    "name": "Jerome Whitehead",
    "rosters": [
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "105e54a0-ada0-4f0c-a038-906cd453091e",
      "1b8a276f-9054-49c5-aa3a-1d0f0a10cf9d",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "364bdc09-f2ed-4033-8fe4-88cf3367864b",
      "a244a9bb-0ce8-42c7-aa8e-35291e38f08a",
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "78519": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 22, 1957",
    "EXP": "1",
    "SCHOOL": "North Carolina State",
    "name": "Charles Whitney",
    "rosters": [
      "19686855-a120-434c-8ad4-5f15e61b9a38",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365"
    ]
  },
  "78520": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 19, 1949",
    "EXP": "4",
    "SCHOOL": "California-Los Angeles",
    "name": "Sidney Wicks",
    "rosters": [
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "9661eddb-8e02-42a3-859a-311e00d67a47",
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "ef880e29-8409-4464-a009-ac827384a450",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4",
      "448aca36-b464-4106-91e1-7fc632e2c7d5",
      "dd803214-fd70-44fe-988b-85ae27a94245",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "cbf01feb-6986-43c2-8350-23e707cf8272"
    ]
  },
  "78521": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "5-9",
    "WEIGHT": "155",
    "BIRTH_DATE": "DEC 12, 1926",
    "EXP": "R",
    "SCHOOL": "Iowa",
    "name": "Murray Wier",
    "rosters": [
      "6d008515-8165-4990-a179-942eeec92822"
    ]
  },
  "78522": {
    "NUM": "71",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 22, 1938",
    "EXP": "R",
    "SCHOOL": "Cincinnati",
    "name": "Bob Wiesenhahn",
    "rosters": [
      "020d6aa2-861a-4e30-8595-c38b6904fb12"
    ]
  },
  "78523": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 28, 1959",
    "EXP": "5",
    "SCHOOL": "Florida State",
    "name": "Mitchell Wiggins",
    "rosters": [
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "4b873a4c-c9af-4bf4-b246-ebccbf734299",
      "742ebe4a-3448-4659-b32c-a6aa0ea7cb64",
      "6cadde58-cc80-4e96-af67-6d093657445c",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "6d174ccb-f4e3-4736-8cea-81a9bcec83a2"
    ]
  },
  "78524": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 08, 1944",
    "EXP": "1",
    "SCHOOL": "Central State (OH)",
    "name": "Ken Wilburn",
    "rosters": [
      "080d0104-5755-4078-9dd5-e5b36018755c",
      "5ea792bd-890a-47b6-babe-2afabfd78f09"
    ]
  },
  "78526": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 12, 1937",
    "EXP": "3",
    "SCHOOL": "Wichita State",
    "name": "Gene Wiley",
    "rosters": [
      "fd9a0d1d-ad63-422e-989d-f0c88ccc93e1",
      "a9afe90e-7f89-4f3f-87e7-6a97c519159f",
      "12d8f06a-c574-4a71-bd2f-9b827d839693",
      "ea6af352-4d04-4ed1-9583-6bfb3fcfeb9d"
    ]
  },
  "78527": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 16, 1957",
    "EXP": "R",
    "SCHOOL": "Long Beach State",
    "name": "Michael Wiley",
    "rosters": [
      "de2e838d-3259-430d-8634-0759e68893b8",
      "a6b98be6-4acd-49be-92b6-decd2522e4df"
    ]
  },
  "78528": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 24, 1966",
    "EXP": "2",
    "SCHOOL": "Long Beach State",
    "name": "Morlon Wiley",
    "rosters": [
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "a859ae74-acea-4f80-abaa-92f971001064",
      "6c5e3d0b-b3c7-42a9-b426-810a1b9f1b1f",
      "af772cda-00d9-43ee-a6c3-5a0b2fcb7b0c",
      "ca88f8b4-9328-4c6c-a36c-8652a814c7a0",
      "5e5ac014-7b8a-4554-a779-d450c1b517cd",
      "139cf619-7d06-46d0-8eb5-7beaf7afe964"
    ]
  },
  "78529": {
    "NUM": "41",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 18, 1932",
    "EXP": "3",
    "SCHOOL": "Memphis",
    "name": "Win Wilfong",
    "rosters": [
      "ea187132-fedd-4cc9-a408-f8efdbf7552c",
      "48b4a377-9bad-455c-bad0-01dfe0485f4a",
      "46567045-4810-4a4b-8e8f-96ca1bdccdb2",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce"
    ]
  },
  "78530": {
    "NUM": "19",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 28, 1937",
    "EXP": "11",
    "SCHOOL": "Providence",
    "name": "Lenny Wilkens",
    "rosters": [
      "b3e2ebec-a85e-4e83-a60c-e58564046a18",
      "8e1a24c1-5fa0-4ad1-b64a-88bb396bc3f0",
      "f0939af6-305c-41a3-a3ae-9ff408049d2c",
      "851cfbc4-923d-43ea-b7ce-6801f5a3d479",
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "fd982b48-258b-4fc9-b6d2-1be39fa32925",
      "90d69000-90d6-401d-bb07-c69727c20c2d",
      "021571b6-cd31-4915-befc-76b28e61b3ae",
      "9d927b38-7d0c-449c-b6e3-af82ff03ffec",
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "881449d6-c8b7-4563-bdc6-eb8aac8b1d5f",
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "3829b824-1221-432d-8dc1-388a4d2f1cce",
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4"
    ]
  },
  "78531": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 15, 1954",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Bob Wilkerson",
    "rosters": [
      "68c8e4a0-94d6-4144-a571-88637a22964a",
      "4021a72d-9cd5-4e14-9e4d-9c04c5c70c4d",
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "66743650-e493-4c91-a3ab-5a488a063464",
      "2db8a2e7-5351-4109-ac43-ae331e73b4b4",
      "a6bd7a55-926b-4045-a20f-fdda4c48cc0f",
      "8718c236-d862-42de-a84d-ef29c83369b9"
    ]
  },
  "78532": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 02, 1953",
    "EXP": "10",
    "SCHOOL": "California-Los Angeles",
    "name": "Jamaal Wilkes",
    "rosters": [
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444",
      "88744ceb-db9d-450d-9e19-5e7aaa7cae90",
      "fff2243e-9747-44b5-8882-14d1d9d99603",
      "e92adc84-abb2-4977-92b5-c33434e3233a",
      "c3077e01-3d45-4725-9d9e-90a13761dbe8",
      "9894db71-7342-451a-9249-92e7308dbbe9",
      "7868abb4-8d7e-4e07-bc83-5338460cb2b8",
      "5060be0c-335c-47ca-aaaa-f6b63ce254ba",
      "48eedca2-29c5-4d77-a299-70678b08bfa7",
      "d4d129f1-7a59-40e4-b659-766f7affa51a"
    ]
  },
  "78533": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 12, 1958",
    "EXP": "2",
    "SCHOOL": "California-Los Angeles",
    "name": "James Wilkes",
    "rosters": [
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "f7a58b16-8257-42be-b944-16319d0abd63"
    ]
  },
  "78534": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 07, 1962",
    "EXP": "5",
    "SCHOOL": "Gardner-Webb",
    "name": "Eddielee Wilkins",
    "rosters": [
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "33746e83-36c9-4d5e-b9ae-9b30ee39f5c8",
      "e3c84d66-79c8-4bee-9d21-c17271bd2581",
      "efbce694-cabd-4657-b606-4479029ed83a",
      "f646b106-33f9-49c7-9873-99a35bf12813"
    ]
  },
  "78537": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 09, 1955",
    "EXP": "5",
    "SCHOOL": "Illinois State",
    "name": "Jeff Wilkins",
    "rosters": [
      "79272c8a-a0ac-4650-b0a6-e971f46652f5",
      "16161b6e-7707-4383-b96c-3f7cf17fe149",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "959f68fd-f6cb-4d62-b5e9-c5fc70e7e291",
      "f924bbf7-3dec-4b9e-86de-5f7627a8e00a",
      "cd6ab7ed-1eb6-4c45-b831-5bbe1953577a"
    ]
  },
  "78538": {
    "NUM": "38",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 18, 1960",
    "EXP": "R",
    "SCHOOL": "Idaho State",
    "name": "Dale Wilkinson",
    "rosters": [
      "52a6b4e1-d155-47b0-9819-f0cc15bc07d5"
    ]
  },
  "78539": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 29, 1939",
    "EXP": "2",
    "SCHOOL": "Cal State-Poly Pomona",
    "name": "Arthur Williams",
    "rosters": [
      "0cd82dfb-5312-4478-8847-027549aeea25",
      "c16db7e2-2323-44aa-8409-48fb4fa4ced9",
      "685d7197-3cc2-4e98-8f77-699d694916ab",
      "0e176bd4-5cab-4c83-8cd9-f6dc0c14f404",
      "e511f2f0-158b-44a1-90e9-a101165211e2",
      "fd689085-06a6-45c4-ac3c-bdfe79bd037d",
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea"
    ]
  },
  "78540": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "DEC 30, 1945",
    "EXP": "1",
    "SCHOOL": "La Salle",
    "name": "Bernie Williams",
    "rosters": [
      "9bd3bcb9-0ee5-437b-bd21-a473bad44eea",
      "84e31c1f-82ce-462e-bbce-b0b659005594"
    ]
  },
  "78541": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "DEC 31, 1953",
    "EXP": "R",
    "SCHOOL": "Kansas State",
    "name": "Chuckie Williams",
    "rosters": [
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558"
    ]
  },
  "78543": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "APR 15, 1945",
    "EXP": "R",
    "SCHOOL": "Bowling Green",
    "name": "Cliff Williams",
    "rosters": [
      "49db955c-2a3f-4693-b68f-834ab5003c4d"
    ]
  },
  "78545": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 02, 1956",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Don Williams",
    "rosters": [
      "3d64e1d0-7221-4ce7-831f-5d02089042ba"
    ]
  },
  "78546": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 24, 1951",
    "EXP": "1",
    "SCHOOL": "Winston-Salem State",
    "name": "Earl Williams",
    "rosters": [
      "355d317f-a6b3-4f3f-96a6-93a3ea1206f8",
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "51cca0bc-794a-45dd-b4c3-6caab1dc4792",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b"
    ]
  },
  "78547": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 06, 1946",
    "EXP": "1",
    "SCHOOL": "Colorado",
    "name": "Chuck Williams",
    "rosters": [
      "62960c2f-47a2-40f2-8e56-42aae6c8f50f",
      "0c82b7b8-0084-4eef-82c7-89296e03c73e"
    ]
  },
  "78548": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 15, 1956",
    "EXP": "5",
    "SCHOOL": "Portland State",
    "name": "Freeman Williams",
    "rosters": [
      "b667b56e-60ab-48af-874e-fcef6ceccd70",
      "ef880e29-8409-4464-a009-ac827384a450",
      "5cf2bd86-cf09-4347-b1d0-6a95eb905528",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "78549": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 10, 1953",
    "EXP": "9",
    "SCHOOL": "Southern California",
    "name": "Gus Williams",
    "rosters": [
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444",
      "6306dadf-1ee7-4678-b035-ef14578746a4",
      "5e43acaa-2104-49ad-b07f-b37c04e28912",
      "278e33a4-dd30-4a35-b7be-2e7b71623707",
      "9cf046e5-f475-41ef-a3d3-f517368a157b",
      "a59119a0-b26d-4a60-b162-d2df226404ce",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228",
      "355ade3f-1412-4279-a7f6-512d2936bf86"
    ]
  },
  "78550": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 01, 1960",
    "EXP": "R",
    "SCHOOL": "Washington State",
    "name": "Guy Williams",
    "rosters": [
      "e8f07ede-458f-46ff-8d3c-a054940f687f",
      "61e479cf-eeb6-4dca-b9ff-06f2dcb2d228"
    ]
  },
  "78554": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 26, 1966",
    "EXP": "4",
    "SCHOOL": "Louisiana State",
    "name": "John Williams",
    "rosters": [
      "41a9044f-c2f4-4848-8f9e-8db6346645c0",
      "ccf16096-3596-4978-bbc4-14e41d80d693",
      "71e8aaec-a43e-41d2-ac5e-2d6cb7f973ca",
      "4567ce1a-2538-43ab-b37e-44c2913ab119",
      "f7c8f98e-7d46-478b-b8da-4b66953a08a3",
      "c45ea96e-2a6c-4f31-ae56-29735314ea58",
      "44e38364-f6e8-4d07-bb01-e7f82356367c",
      "72f5f604-1980-4a2a-96e2-0bf7912e17c6"
    ]
  },
  "78555": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 09, 1969",
    "EXP": "3",
    "SCHOOL": "Elizabeth City State",
    "name": "Kenny Williams",
    "rosters": [
      "6a844b97-e06e-4e01-8c3c-e52f881fa4c3",
      "8cdb62b7-9540-440c-b349-e1dc0adefb98",
      "8214cfa1-0339-49d6-9f2a-32f550179000",
      "044dce90-7443-4814-8454-a974b791ec76"
    ]
  },
  "78556": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 11, 1961",
    "EXP": "3",
    "SCHOOL": "St. John's (NY)",
    "name": "Kevin Williams",
    "rosters": [
      "54e1af29-ac7b-45dd-88b9-17ae2aefe389",
      "03a36ce1-3d63-4f28-b362-56b1356f738d",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6",
      "d58ee353-d825-4a50-96dc-975b331f83f2"
    ]
  },
  "78558": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "255",
    "BIRTH_DATE": "AUG 14, 1963",
    "EXP": "R",
    "SCHOOL": "Bradley",
    "name": "Mike Williams",
    "rosters": [
      "cb5e9f38-1a01-49f9-b378-bb5e337469e9"
    ]
  },
  "78560": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "182",
    "BIRTH_DATE": "NOV 22, 1945",
    "EXP": "2",
    "SCHOOL": "Lincoln (MO)",
    "name": "Milt Williams",
    "rosters": [
      "ade59950-b4fe-47d2-96c5-c7cb7b85caa6",
      "c89d57fe-9137-4336-8384-9435a1aab760",
      "015afdf3-3da7-4590-9ac2-2a1976094cf3"
    ]
  },
  "78561": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 02, 1950",
    "EXP": "5",
    "SCHOOL": "Utah State",
    "name": "Nate Williams",
    "rosters": [
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "9c55ad6e-f97d-4c85-ad5c-7c0ff4d9eb10",
      "146cb658-0a9a-4f26-8556-39497dc3cfa4",
      "5ac9b01b-c3e8-469b-8f52-0a7fd1aece60",
      "5bebb2fa-d1af-4cbd-986a-7d33be43c5ba",
      "cf18926c-add0-4350-9f9c-f87df758e703",
      "352c468a-24d4-4f1c-8cd5-7348731395a5",
      "4100a46a-2845-4fc5-b5a7-28c783b0e827"
    ]
  },
  "78563": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 12, 1957",
    "EXP": "R",
    "SCHOOL": "Long Beach State",
    "name": "Rickey Williams",
    "rosters": [
      "97b94a55-c8d4-46d9-a717-bc83dc562b5e"
    ]
  },
  "78564": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 12, 1931",
    "EXP": "1",
    "SCHOOL": "Florida A&M",
    "name": "Bob Williams",
    "rosters": [
      "156bc9fb-9be0-4d47-ae6c-e255ee721988",
      "e396acb8-9dc8-42c0-b311-cdf0a86cfce8"
    ]
  },
  "78565": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 05, 1961",
    "EXP": "1",
    "SCHOOL": "Houston",
    "name": "Rob Williams",
    "rosters": [
      "13d70b6b-6bf3-44f1-810b-e54da749cc7e",
      "606a6691-6b18-478d-b60f-85baee1be70c"
    ]
  },
  "78566": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 10, 1965",
    "EXP": "1",
    "SCHOOL": "Arizona",
    "name": "Pete Williams",
    "rosters": [
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f"
    ]
  },
  "78567": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "188",
    "BIRTH_DATE": "SEP 24, 1944",
    "EXP": "6",
    "SCHOOL": "West Virginia",
    "name": "Ron Williams",
    "rosters": [
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "852d8746-a465-41f3-b644-085c99bbad27",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "37f03d05-dd2d-4fd4-975e-0da0d88ef5bb",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "bb2e2269-8e70-4d93-a583-5f824737ab1e"
    ]
  },
  "78568": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JAN 22, 1945",
    "EXP": "1",
    "SCHOOL": "Iowa",
    "name": "Sam Williams",
    "rosters": [
      "9d1d5a18-cd11-48f1-aa5a-cff4be466b00",
      "c7ab28ba-96bf-465d-b793-b95781330560"
    ]
  },
  "78569": {
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 07, 1959",
    "EXP": "3",
    "SCHOOL": "Arizona State",
    "name": "Samuel Williams",
    "rosters": [
      "b865787d-a59a-4abd-b1ce-ce5c96a411d9",
      "43c1dfb0-f6d6-4cf5-a812-d1b007934cd0",
      "cf936114-2a00-471e-8491-371c9e98f705",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332"
    ]
  },
  "78570": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 26, 1958",
    "EXP": "3",
    "SCHOOL": "Rhode Island",
    "name": "Sly Williams",
    "rosters": [
      "234ba200-c395-49b7-8b55-38dbd3fb9d66",
      "a4a21a99-b31a-4a65-abb5-925ebd311511",
      "cbd0f088-e3b1-43f4-af7c-eedca988f4ff",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "ba72d0e2-ca41-42ee-aab5-931f1dda7bff",
      "6a10cc0f-c034-4cee-992e-4023a7280654"
    ]
  },
  "78571": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "188",
    "BIRTH_DATE": "OCT 14, 1954",
    "EXP": "5",
    "SCHOOL": "Minnesota",
    "name": "Ray Williams",
    "rosters": [
      "7b0fd670-8d2b-4de7-8e1c-efa748b19fe6",
      "a10ccb63-e385-40ea-8b37-0fa80e7f9675",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "b001782c-95ad-444b-814b-98ecf23fa9ce",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "48f67883-4176-4790-ac27-c6cec5e58bd8"
    ]
  },
  "78573": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 26, 1923",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Ward Williams",
    "rosters": [
      "a94975cb-3f3a-4d21-b65d-a170a7dca922"
    ]
  },
  "78574": {
    "NUM": "11  15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 28, 1946",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Willie Williams",
    "rosters": [
      "ebe915d7-7456-4c43-b39f-1cc85c7dde39"
    ]
  },
  "78575": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 10, 1951",
    "EXP": "4",
    "SCHOOL": "New Mexico State",
    "name": "John Williamson",
    "rosters": [
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "cac91d50-2f65-4d2c-b139-8486bcc4842f",
      "2e047c1e-b4df-4b65-b41d-6ca65f168b3a",
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "a52d7bc3-ed81-4836-b70b-85c958af2548"
    ]
  },
  "78577": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAY 20, 1957",
    "EXP": "7",
    "SCHOOL": "Dwight Morrow",
    "name": "Bill Willoughby",
    "rosters": [
      "9bcb404e-1244-4a1a-b70f-c130c5a2178c",
      "0408e522-71e2-49ae-bd83-8ebbebbf212f",
      "561af7fe-6914-4e3e-8250-12448f9d452c",
      "4c2f5ffb-01e6-455a-b1ae-9b4b0aeb64c8",
      "c949c9e9-fb17-443f-b682-96f8c34ca982",
      "0c82b7b8-0084-4eef-82c7-89296e03c73e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "20889c5b-74ce-45e7-8127-3b987894fca9"
    ]
  },
  "78578": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 26, 1961",
    "EXP": "1",
    "SCHOOL": "Virginia",
    "name": "Othell Wilson",
    "rosters": [
      "d4f7a688-d761-4a62-88cf-2893af24c272",
      "1874f12b-8c7f-4f0b-8fd0-e6dcc0762e22"
    ]
  },
  "78579": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 09, 1942",
    "EXP": "3",
    "SCHOOL": "Cincinnati",
    "name": "George Wilson",
    "rosters": [
      "94f7f595-0ac0-4c93-8c34-bc941216950a",
      "a8ccae69-3758-45d9-b363-2847b583dcf8",
      "20955b01-8d24-45f5-ba05-3a4253c801f7",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef",
      "38c6a8b5-9c04-429f-9873-476923c33722",
      "c9d83e93-3057-4fd1-83c3-31ceb80562fa"
    ]
  },
  "78580": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 31, 1948",
    "EXP": "R",
    "SCHOOL": "Baltimore",
    "name": "Isaiah Wilson",
    "rosters": [
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226"
    ]
  },
  "78581": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 15, 1959",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "Mike Wilson",
    "rosters": [
      "96e3a73f-0bd6-4012-bcdd-037ccd843b34",
      "8566310c-0a28-4492-9afd-453f232588e5",
      "3dbbb282-12b5-47b4-bf1f-c0597f04b407"
    ]
  },
  "78582": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 25, 1964",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Nikita Wilson",
    "rosters": [
      "04ef3e81-d8fb-48a8-ba78-694468f81686"
    ]
  },
  "78583": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 07, 1956",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Rick Wilson",
    "rosters": [
      "9e2678b7-ef4f-487f-8c0b-3559c45262e1",
      "0931529d-0881-4f63-b629-4c68ef624474"
    ]
  },
  "78584": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 16, 1964",
    "EXP": "R",
    "SCHOOL": "George Mason",
    "name": "Ricky Wilson",
    "rosters": [
      "473f0beb-d4eb-46a7-b66f-d6831084a451"
    ]
  },
  "78585": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 15, 1951",
    "EXP": "3",
    "SCHOOL": "Wichita State",
    "name": "Bobby Wilson",
    "rosters": [
      "3d276145-9d90-441c-bb53-8fe44705d3e2",
      "66953847-11ae-490d-ba1c-76473d320167",
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0"
    ]
  },
  "78586": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 08, 1926",
    "EXP": "R",
    "SCHOOL": "West Virginia State",
    "name": "Bob Wilson",
    "rosters": [
      "d6ffff35-bcc0-428a-b5ad-36aa591e1699"
    ]
  },
  "78587": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 07, 1955",
    "EXP": "R",
    "SCHOOL": "Western Carolina",
    "name": "Thomas Wilson",
    "rosters": [
      "1f05489e-b5ab-49f4-acea-e8f96d192bfc"
    ]
  },
  "78589": {
    "NUM": "7-20",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 03, 1966",
    "EXP": "1",
    "SCHOOL": "Averett",
    "name": "Kennard Winchester",
    "rosters": [
      "08d8a2af-aeb5-46c8-b3e3-f630b3519727",
      "026c485f-5e1e-4793-b4e8-1970ad2c7536",
      "67b5af29-cb30-4af3-aa8c-89db35f1c392"
    ]
  },
  "78590": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "160",
    "BIRTH_DATE": "JAN 27, 1933",
    "EXP": "R",
    "SCHOOL": "Wyoming",
    "name": "Tony Windis",
    "rosters": [
      "29b33649-2e3d-444b-81b4-caec18163bdd"
    ]
  },
  "78591": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 03, 1940",
    "EXP": "R",
    "SCHOOL": "Stanford",
    "name": "John Windsor",
    "rosters": [
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad"
    ]
  },
  "78592": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "174",
    "BIRTH_DATE": "FEB 04, 1947",
    "EXP": "3",
    "SCHOOL": "North Texas",
    "name": "Lee Winfield",
    "rosters": [
      "a7302500-52fe-4776-937c-0c2c59b29346",
      "e172999e-d18d-439a-83cc-746ead7f5c56",
      "dd460500-38ea-4afb-99af-548234a3036d",
      "3829b824-1221-432d-8dc1-388a4d2f1cce",
      "6e488406-7055-45fd-b876-5b97997c0cf1",
      "c8c14e03-40ea-4bea-b6fc-d40c4c7299c4",
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0"
    ]
  },
  "78596": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 09, 1947",
    "EXP": "3",
    "SCHOOL": null,
    "name": "Harthorne Wingo",
    "rosters": [
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "52649402-55a4-4d97-a5fd-f7cf9779a672"
    ]
  },
  "78597": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "164",
    "BIRTH_DATE": "FEB 18, 1948",
    "EXP": "R",
    "SCHOOL": "Louisiana-Lafayette",
    "name": "Marv Winkler",
    "rosters": [
      "30c679a6-4b92-42bc-b03d-303894f5aed9"
    ]
  },
  "78600": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 01, 1952",
    "EXP": "8",
    "SCHOOL": "South Carolina",
    "name": "Brian Winters",
    "rosters": [
      "bfd3d82f-7763-4318-9f54-51769b18a6ce",
      "113518f0-81cb-4e6d-9a60-ae57bf8f20e4",
      "743aba57-7e93-4575-8cb7-e3de53d6223c",
      "71e13c20-8234-43de-9d74-74839ced5c07",
      "39873456-69db-4f6d-b278-943dbfa788f7",
      "b890c31f-8e3d-4a47-8dda-02e70c490b9d",
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "48d933d7-4450-441c-8ef1-2d743521a134"
    ]
  },
  "78601": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 12, 1962",
    "EXP": "R",
    "SCHOOL": "Bradley",
    "name": "Voise Winters",
    "rosters": [
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001"
    ]
  },
  "78602": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 03, 1947",
    "EXP": "1",
    "SCHOOL": "Drake",
    "name": "Willie Wise",
    "rosters": [
      "7d5d2b23-3811-4485-8ebe-5300180b8967",
      "6306dadf-1ee7-4678-b035-ef14578746a4"
    ]
  },
  "78603": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 19, 1950",
    "EXP": "2",
    "SCHOOL": "Ohio State",
    "name": "Luke Witte",
    "rosters": [
      "735f4429-ca95-43f1-9eb0-dcb84c1de682",
      "6c265257-0918-47cb-acd7-85fd0f02f9a1",
      "ffc810fd-c63c-4a82-a09d-2e2b086d6f86"
    ]
  },
  "78605": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 03, 1959",
    "EXP": "R",
    "SCHOOL": "Holy Cross",
    "name": "Garry Witts",
    "rosters": [
      "f0072d27-be45-4cee-b65f-0d3b87790dcb"
    ]
  },
  "78606": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 02, 1949",
    "EXP": "R",
    "SCHOOL": "Pennsylvania",
    "name": "Dave Wohl",
    "rosters": [
      "298aa605-b35d-4f1f-978f-4f4883ddee16",
      "3f4d6adc-f325-4204-a5ca-f7eccb585194",
      "d8f4df03-bf7a-4d59-a977-c0eae0d41cfe",
      "683a627e-6110-4e8e-890b-ab8162df16d5",
      "97c2ee90-873f-4c01-92e4-890bb08dda89",
      "f6db8edf-a145-4fdb-b531-74c601ef91a1",
      "a66f3932-1385-40eb-a71f-3de93d22fbc1"
    ]
  },
  "78609": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 20, 1959",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Howard Wood",
    "rosters": [
      "16161b6e-7707-4383-b96c-3f7cf17fe149"
    ]
  },
  "78610": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "193",
    "BIRTH_DATE": "JUN 02, 1958",
    "EXP": "4",
    "SCHOOL": "North Carolina",
    "name": "Al Wood",
    "rosters": [
      "4c8ca104-147e-44d8-9de3-0c6d6a888309",
      "de2e838d-3259-430d-8634-0759e68893b8",
      "026f02c8-bc1d-40d5-bcfa-cb56f47ea29e",
      "3c25ef9a-4c70-4f76-8ce4-a86f272927ce",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "0d39d553-7b93-4206-b525-d87f764e4d5d"
    ]
  },
  "78611": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 25, 1962",
    "EXP": "5",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Leon Wood",
    "rosters": [
      "7ffa0fc5-7225-4221-8806-a19568db71d9",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "faa38736-32f4-41a2-8cca-8649fdee48af",
      "a92bb844-1670-48c1-a7ad-5c4848bf2332",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "57c1be08-60cf-40ad-a097-a95ef9958532"
    ]
  },
  "78614": {
    "NUM": "42",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 24, 1958",
    "EXP": "5",
    "SCHOOL": "Indiana",
    "name": "Mike Woodson",
    "rosters": [
      "85ea4002-c7dd-4e3d-95ff-1bc4b8d88d7f",
      "79d8e657-ce27-4052-9492-c9833808173f",
      "955db219-1fe5-405f-a607-095000d7ba49",
      "a8878857-2e27-4452-a526-4d80a3f6b73a",
      "24f004b8-d504-43af-a4c2-a15d3b8e382d",
      "116bb713-d75c-4ec4-80e2-0f9ef482f36b",
      "d4565b40-6c6d-4d7b-906e-eb1a4a817365",
      "48f67883-4176-4790-ac27-c6cec5e58bd8",
      "e81896fc-7ddb-4509-a099-1e8914f1dcd7",
      "c49c485d-48ce-4cdb-ac2e-27d3b6da259e",
      "b9189460-149e-4b0b-88c7-ad91b38bd16f"
    ]
  },
  "78615": {
    "NUM": "0-6",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 16, 1959",
    "EXP": "10",
    "SCHOOL": "Notre Dame",
    "name": "Orlando Woolridge",
    "rosters": [
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "e71842ec-52d3-4f13-93be-8bad598624c2",
      "a6274275-4c5c-4d2a-b5fe-6f3ab2afe3cc",
      "977971a3-429e-4fe8-bc6b-f3f80bdfaf2f",
      "061c0a9f-f0a9-430b-a0cc-94b6411c069f",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5",
      "4c991cf6-4082-4074-822a-6c24976b3fc2",
      "07a1b22f-c056-4d55-a1b1-d9dc332965a8",
      "bdfd6caf-a9ad-489d-9676-4c8528e2ae39",
      "92c435c1-938a-4392-8fd2-ef2c944fa4d3",
      "5c6068d0-8b6b-4473-8dce-c22be7e22766",
      "f8980f9a-2074-4965-8469-9086e1c70f28",
      "db85dc84-6de1-4038-8c49-8f636dd20715"
    ]
  },
  "78616": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 10, 1930",
    "EXP": "R",
    "SCHOOL": "West Virginia",
    "name": "Mark Workman",
    "rosters": [
      "9398ab8b-2109-490e-8b37-d2bbaadb361f"
    ]
  },
  "78617": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "NOV 14, 1944",
    "EXP": "2",
    "SCHOOL": "Seattle",
    "name": "Tom Workman",
    "rosters": [
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "d80f75e6-baf7-4843-8122-aa743aea3477"
    ]
  },
  "78618": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 17, 1958",
    "EXP": "1",
    "SCHOOL": "Marquette",
    "name": "Sam Worthen",
    "rosters": [
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "16161b6e-7707-4383-b96c-3f7cf17fe149"
    ]
  },
  "78620": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 26, 1962",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Brad Wright",
    "rosters": [
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "b2182580-6b74-4d01-905a-961e5a283423"
    ]
  },
  "78621": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 20, 1967",
    "EXP": "1",
    "SCHOOL": "Stanford",
    "name": "Howard Wright",
    "rosters": [
      "24a0d010-462a-4f0b-89b1-6cd920460093",
      "e140a3b1-5989-4956-a54f-45f979d33ba1"
    ]
  },
  "78622": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 05, 1950",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Joby Wright",
    "rosters": [
      "061ae24a-cfe3-4632-95c1-7b43f58c91c0"
    ]
  },
  "78623": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "160",
    "BIRTH_DATE": "NOV 23, 1954",
    "EXP": "5",
    "SCHOOL": "Grambling",
    "name": "Larry Wright",
    "rosters": [
      "11ed4bdb-7b98-4d0a-9b6e-8f4850f67a66",
      "dd527857-adb8-40c3-9804-8aa4212b8486",
      "f9fc5d59-1842-4623-a6ff-d1bfb7dbeff0",
      "324480c3-ba0a-4b35-8ae3-aeccec46e409",
      "c163d65f-d6fd-4a44-968a-2897543255d4",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938"
    ]
  },
  "78627": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 11, 1964",
    "EXP": "R",
    "SCHOOL": "Fairfield",
    "name": "A.J. Wynder",
    "rosters": [
      "e0e2c95e-209e-4d00-9e53-3035df29fe95"
    ]
  },
  "78628": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 03, 1928",
    "EXP": "5",
    "SCHOOL": "Stanford",
    "name": "George Yardley",
    "rosters": [
      "a45c9665-5951-47aa-ad90-2e2f872cee40",
      "57494c03-afc0-41de-b544-659990d5e952",
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91",
      "0a699404-02aa-4953-8be2-08a5fc726ea3",
      "edb95a5d-c995-43f0-9378-d27051d259ab",
      "08ee244c-3cfb-449d-8fe6-85e1d66dccd3",
      "03f980b2-d8d0-47cc-a777-71a16e029764"
    ]
  },
  "78629": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 30, 1946",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Barry Yates",
    "rosters": [
      "a66f3932-1385-40eb-a71f-3de93d22fbc1"
    ]
  },
  "78630": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 07, 1937",
    "EXP": "R",
    "SCHOOL": "Memphis",
    "name": "Wayne Yates",
    "rosters": [
      "f2f930e4-58d0-4668-8866-f9d5e0a8da29"
    ]
  },
  "78631": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "DEC 05, 1948",
    "EXP": "R",
    "SCHOOL": "Fordham",
    "name": "Charlie Yelverton",
    "rosters": [
      "35623c7a-d844-4fde-87eb-a7ebd20a24f4"
    ]
  },
  "78632": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 03, 1958",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Rich Yonakor",
    "rosters": [
      "ff30d636-cd58-403e-b6d1-3bf850cd43eb"
    ]
  },
  "78633": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 26, 1962",
    "EXP": "8",
    "SCHOOL": "Wake Forest",
    "name": "Danny Young",
    "rosters": [
      "23fb7ecd-d311-48ba-99e6-2244dfd4b2e5",
      "20b624e6-187e-4570-9266-08913829ee83",
      "148d19d7-f6a8-401d-b449-7e8b111f7959",
      "a666e4ba-2891-4445-8ab6-4489a56cbbc6",
      "1a533bc9-dee9-4f2e-9602-18b8bbcd60ed",
      "4e8fe930-0176-4e3b-9fda-73e7317840a4",
      "0d39d553-7b93-4206-b525-d87f764e4d5d",
      "f318ac55-9b8f-47e9-8d51-5bd60cb174f6",
      "d58ee353-d825-4a50-96dc-975b331f83f2",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f"
    ]
  },
  "78634": {
    "NUM": "43",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 02, 1961",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "Michael Young",
    "rosters": [
      "26c5d00c-d04d-45ae-bc04-83d0ee557440",
      "44e3b638-28e0-4ec7-b3cc-9f7a3ac24001",
      "bb297948-2e0c-4f3b-8cf3-628ff1bb680f"
    ]
  },
  "78635": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 04, 1963",
    "EXP": "R",
    "SCHOOL": "Virginia Tech",
    "name": "Perry Young",
    "rosters": [
      "3367b969-0b6f-4aa4-927e-679d12463f2b"
    ]
  },
  "78638": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "170",
    "BIRTH_DATE": "DEC 07, 1925",
    "EXP": "9",
    "SCHOOL": "St. John's (NY)",
    "name": "Max Zaslofsky",
    "rosters": [
      "f4a89590-ecfb-4322-be0d-7356b8bb3b04",
      "9c8cdc7d-791d-4211-a59b-51e7400aff3d",
      "bae1e9c7-6ded-428f-83c2-215eb4f7e1ac",
      "57494c03-afc0-41de-b544-659990d5e952",
      "3d186bdd-f80f-49e5-b6bb-1d08328eea91",
      "0a699404-02aa-4953-8be2-08a5fc726ea3"
    ]
  },
  "78639": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 13, 1930",
    "EXP": "2",
    "SCHOOL": "St. John's (NY)",
    "name": "Robert Zawoluk",
    "rosters": [
      "2a1981fa-3157-4dfe-84dc-dc914f881f5f",
      "9f9e4bd6-69c3-47f8-8a89-4d74931e4e86"
    ]
  },
  "78640": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 08, 1939",
    "EXP": "R",
    "SCHOOL": "Miami (FL)",
    "name": "Dave Zeller",
    "rosters": [
      "020d6aa2-861a-4e30-8595-c38b6904fb12"
    ]
  },
  "78641": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 20, 1947",
    "EXP": "1",
    "SCHOOL": "Drake",
    "name": "Gary Zeller",
    "rosters": [
      "ab91fee8-d6df-4549-ad42-04456017afc3",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3"
    ]
  },
  "78643": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 01, 1957",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Tony Zeno",
    "rosters": [
      "d3da55d4-ca59-42db-b517-15302adf51f1"
    ]
  },
  "78644": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 13, 1964",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Phil Zevenbergen",
    "rosters": [
      "473f0beb-d4eb-46a7-b66f-d6831084a451"
    ]
  },
  "78647": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "240",
    "BIRTH_DATE": "DEC 30, 1953",
    "EXP": "R",
    "SCHOOL": "Kent State",
    "name": "Jim Zoet",
    "rosters": [
      "f7a58b16-8257-42be-b944-16319d0abd63"
    ]
  },
  "78648": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUN 07, 1948",
    "EXP": "R",
    "SCHOOL": "Duquesne",
    "name": "Bill Zopf",
    "rosters": [
      "30c679a6-4b92-42bc-b03d-303894f5aed9"
    ]
  },
  "78651": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 06, 1939",
    "EXP": "2",
    "SCHOOL": "Seattle",
    "name": "John Tresvant",
    "rosters": [
      "785230d5-4a0c-4e7d-9fe0-bc1a6d7dad8e",
      "122a5267-d509-4769-a4ca-4f7932f65492",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "5df3e966-18d8-42d1-a237-835fc00837b6",
      "3829b824-1221-432d-8dc1-388a4d2f1cce",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3",
      "24d3a3e9-4c00-40db-8b8b-1169b6c6a889",
      "61110628-8f7d-478f-b171-52455d3bf9d0",
      "65f693c5-b99c-4553-b1db-458345ed8c34"
    ]
  },
  "78654": {
    "NUM": "51",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 11, 1965",
    "EXP": "R",
    "SCHOOL": "Austin Peay",
    "name": "Barry Sumpter",
    "rosters": [
      "03a36ce1-3d63-4f28-b362-56b1356f738d"
    ]
  },
  "78656": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "172",
    "BIRTH_DATE": "NOV 13, 1962",
    "EXP": "5",
    "SCHOOL": "Oregon",
    "name": "Blair Rasmussen",
    "rosters": [
      "6fd292ed-c920-4655-a87f-7bf233c75094",
      "9fd8f340-dead-4135-9b33-86ae2c1fd70d",
      "c5a1816a-9035-49ea-8a46-28d38f029f5a",
      "3b2774a2-1ea5-4cf1-a5ac-e638f710782f",
      "294fc5dc-cadb-457c-849a-7a994aeedbda",
      "e5a6a74e-32e5-4965-829e-f866fe21f504",
      "242ef09c-2ea9-49d1-8d5b-8638d520230e",
      "bf9d8407-fef6-40f4-8fe8-a6774f15e9f5"
    ]
  },
  "100263": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 01, 1900",
    "EXP": "13",
    "SCHOOL": " ",
    "name": "Bill Laimbeer",
    "rosters": [
      "e7fbc45b-f144-4840-ad1c-b5f0ec90b697",
      "b49a0527-aa53-424d-aeb3-bc7f3d439938",
      "f7a58b16-8257-42be-b944-16319d0abd63",
      "1a9e125c-e465-42e3-a800-c69234389252",
      "be51cb27-403f-47e3-bf9c-8331e9c4ce17",
      "b074de7d-b368-48a0-aa16-3542d57bfc7b",
      "999df20d-d256-45ec-8e64-111bdc7471d7",
      "60bcac51-ec80-4f70-b3eb-9d73254ac5c8",
      "b8efa707-8ce1-4e0b-922d-9d113ca8a294",
      "ffc81b35-207d-48f8-8091-52733fd65961",
      "73a256d9-f328-424c-a259-734aa5e88c4c",
      "db85dc84-6de1-4038-8c49-8f636dd20715",
      "36c469a4-c0e6-4546-91c7-83d0d3151d8f",
      "6b4f342a-5c5f-4bf8-a4ec-aafd975b32c3"
    ]
  },
  "101106": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "260",
    "BIRTH_DATE": "NOV 28, 1984",
    "EXP": "5",
    "SCHOOL": "Utah",
    "name": "Andrew Bogut",
    "rosters": [
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "fe70708d-44fa-4308-85a4-df0100fc59b8",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "3b676f62-e076-4dce-94be-5d06e04fc018"
    ]
  },
  "101107": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "237",
    "BIRTH_DATE": "JUN 19, 1986",
    "EXP": "13",
    "SCHOOL": "North Carolina",
    "name": "Marvin Williams",
    "rosters": [
      "1133cf87-e88e-4041-9357-78207256459c",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "83706098-479a-4732-9529-09c78cfdb502",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864",
      "31164298-2c0d-4a44-b557-e740801b4b1d",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79"
    ]
  },
  "101108": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 06, 1985",
    "EXP": "14",
    "SCHOOL": "Wake Forest",
    "name": "Chris Paul",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "101109": {
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "198",
    "BIRTH_DATE": "JUN 26, 1984",
    "EXP": "4",
    "SCHOOL": "North Carolina",
    "name": "Raymond Felton",
    "rosters": [
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "77e6e4de-42ee-4c42-be39-b046215914f2",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "2a8cce15-fec9-4173-8665-82815b3bfb24",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e",
      "a16e37d5-d174-4f55-bfdc-ba838caae024",
      "fc384a91-81b8-47cb-b999-2a497289f651",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575"
    ],
    "NUM": "20"
  },
  "101110": {
    "NUM": "9",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 04, 1986",
    "EXP": "9",
    "SCHOOL": "Seattle Preparatory School",
    "name": "Martell Webster",
    "rosters": [
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "8d3a3080-9e74-4512-8869-83aad854d0a0"
    ]
  },
  "101111": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "232",
    "BIRTH_DATE": "AUG 24, 1984",
    "EXP": "8",
    "SCHOOL": "Connecticut",
    "name": "Charlie Villanueva",
    "rosters": [
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "101112": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 17, 1983",
    "EXP": "3",
    "SCHOOL": "Arizona",
    "name": "Channing Frye",
    "rosters": [
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215"
    ]
  },
  "101113": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 11, 1983",
    "EXP": "3",
    "SCHOOL": "Arizona State",
    "name": "Ike Diogu",
    "rosters": [
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317"
    ]
  },
  "101114": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "207",
    "BIRTH_DATE": "JUN 26, 1984",
    "EXP": "4",
    "SCHOOL": "Illinois",
    "name": "Deron Williams",
    "rosters": [
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4"
    ]
  },
  "101115": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "285",
    "BIRTH_DATE": "OCT 27, 1987",
    "EXP": "7",
    "SCHOOL": "Saint Joseph",
    "name": "Andrew Bynum",
    "rosters": [
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "2216683d-34cb-4284-b507-8c9bf140d784"
    ]
  },
  "101117": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "203",
    "BIRTH_DATE": "MAY 07, 1987",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Yaroslav Korolev",
    "rosters": [
      "4cb979b9-ca00-4b88-8661-de8bde01b339"
    ]
  },
  "101118": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "266",
    "BIRTH_DATE": "APR 04, 1984",
    "EXP": "2",
    "SCHOOL": "North Carolina",
    "name": "Sean May",
    "rosters": [
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "2a8cce15-fec9-4173-8665-82815b3bfb24",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e",
      "a16e37d5-d174-4f55-bfdc-ba838caae024",
      "fc384a91-81b8-47cb-b999-2a497289f651"
    ]
  },
  "101119": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 25, 1984",
    "EXP": "3",
    "SCHOOL": "North Carolina",
    "name": "Rashad McCants",
    "rosters": [
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "3e4b502a-18e0-4f72-b057-966f0972f973",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317"
    ]
  },
  "101120": {
    "NUM": "21",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 06, 1984",
    "EXP": "4",
    "SCHOOL": "Texas A&M",
    "name": "Antoine Wright",
    "rosters": [
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "2ea2e46b-335d-4954-9b4e-4d0611c84ea9",
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4"
    ]
  },
  "101121": {
    "NUM": "14",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 11, 1982",
    "EXP": "3",
    "SCHOOL": "Oklahoma State",
    "name": "Joey Graham",
    "rosters": [
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "91c165fd-b08a-4c73-84bf-a029d5600436"
    ]
  },
  "101122": {
    "NUM": "22",
    "POSITION": "F-G",
    "HEIGHT": "6-9",
    "WEIGHT": "222",
    "BIRTH_DATE": "APR 20, 1983",
    "EXP": "9",
    "SCHOOL": "New Mexico",
    "name": "Danny Granger",
    "rosters": [
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "3d331f49-e3c4-41db-8e85-694ca2e24972",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882"
    ]
  },
  "101123": {
    "NUM": "14",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 26, 1986",
    "EXP": "7",
    "SCHOOL": "No College",
    "name": "Gerald Green",
    "rosters": [
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882"
    ]
  },
  "101124": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "219",
    "BIRTH_DATE": "JUL 08, 1982",
    "EXP": "3",
    "SCHOOL": "Syracuse",
    "name": "Hakim Warrick",
    "rosters": [
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394",
      "d98d42ba-33de-433f-9c54-23973925049a",
      "824e5cff-47e8-4169-8451-74a294a985e8"
    ]
  },
  "101125": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 18, 1983",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Julius Hodge",
    "rosters": [
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f"
    ]
  },
  "101126": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "5-9",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 31, 1984",
    "EXP": "5",
    "SCHOOL": "Washington",
    "name": "Nate Robinson",
    "rosters": [
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf"
    ]
  },
  "101127": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "197",
    "BIRTH_DATE": "OCT 28, 1983",
    "EXP": "4",
    "SCHOOL": "Georgia Tech",
    "name": "Jarrett Jack",
    "rosters": [
      "51581511-27eb-417b-be7a-c3792b97c046",
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "b184df4d-4f3f-47a7-8be3-e3151cdbee20",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4"
    ]
  },
  "101128": {
    "NUM": "32",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 31, 1981",
    "EXP": "6",
    "SCHOOL": "Louisville",
    "name": "Francisco Garcia",
    "rosters": [
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "f682d9da-cb2e-4f60-b710-6accad2d386b",
      "8ef85512-070b-4f28-9454-9d4cc78d5562"
    ]
  },
  "101129": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 26, 1982",
    "EXP": "4",
    "SCHOOL": "Illinois",
    "name": "Luther Head",
    "rosters": [
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21"
    ]
  },
  "101130": {
    "NUM": "27",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "247",
    "BIRTH_DATE": "JAN 27, 1986",
    "EXP": "2",
    "SCHOOL": null,
    "name": "Johan Petro",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd"
    ]
  },
  "101131": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "260",
    "BIRTH_DATE": "FEB 18, 1983",
    "EXP": "9",
    "SCHOOL": "Cincinnati",
    "name": "Jason Maxiell",
    "rosters": [
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "162e7cb4-a05c-426d-bb17-42b80e47aef5",
      "532f6daa-fa7a-422a-9326-f0c577396c95",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864"
    ]
  },
  "101132": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "234",
    "BIRTH_DATE": "JAN 03, 1985",
    "EXP": "6",
    "SCHOOL": "Missouri",
    "name": "Linas Kleiza",
    "rosters": [
      "d93c4dad-c4e0-4c3f-b86c-19b57b9ad77f",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "896773e3-d58b-484d-b4ce-cba82ed75933"
    ]
  },
  "101133": {
    "NUM": "28",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "262",
    "BIRTH_DATE": "NOV 05, 1986",
    "EXP": "11",
    "SCHOOL": " ",
    "name": "Ian Mahinmi",
    "rosters": [
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad",
      "6631c074-5c99-48db-b77d-c92e855be5bd",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "101134": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAR 09, 1983",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Wayne Simien",
    "rosters": [
      "374e93e9-dae6-4b55-a6ea-9f090ad3709c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363"
    ]
  },
  "101135": {
    "NUM": "10",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "APR 29, 1983",
    "EXP": "11",
    "SCHOOL": "Florida",
    "name": "David Lee",
    "rosters": [
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "728d43da-a0f1-4599-96c2-a09036340618",
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "e552d1cb-2458-423d-a8d0-88f93f4640b2",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "d52a9555-ff77-4418-a883-c18272a57975",
      "2727fadf-a3e8-4564-9084-0145ec943ae4"
    ]
  },
  "101136": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 11, 1982",
    "EXP": "3",
    "SCHOOL": "Arizona",
    "name": "Salim Stoudamire",
    "rosters": [
      "1133cf87-e88e-4041-9357-78207256459c",
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0"
    ]
  },
  "101137": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 26, 1983",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Daniel Ewing",
    "rosters": [
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "4cb979b9-ca00-4b88-8661-de8bde01b339"
    ]
  },
  "101138": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "250",
    "BIRTH_DATE": "APR 30, 1985",
    "EXP": "5",
    "SCHOOL": "Louisiana State",
    "name": "Brandon Bass",
    "rosters": [
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "01490269-b22f-47cf-87fb-1d45cb96a3f3",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "a13aadde-4723-4b90-b05c-185b76537abe"
    ]
  },
  "101139": {
    "NUM": "6",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 18, 1987",
    "EXP": "13",
    "SCHOOL": "No College",
    "name": "CJ Miles",
    "rosters": [
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "51581511-27eb-417b-be7a-c3792b97c046",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "101141": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 15, 1987",
    "EXP": "9",
    "SCHOOL": " ",
    "name": "Ersan Ilyasova",
    "rosters": [
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "0642a459-b265-4cac-adbd-de39b087debf",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0"
    ]
  },
  "101142": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 13, 1983",
    "EXP": "5",
    "SCHOOL": "Gonzaga",
    "name": "Ronny Turiaf",
    "rosters": [
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "f2364869-d15e-4237-be86-d502b6074d04",
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0"
    ]
  },
  "101143": {
    "NUM": "27",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 01, 1982",
    "EXP": "4",
    "SCHOOL": "Marquette",
    "name": "Travis Diener",
    "rosters": [
      "d29615e8-492c-4cfb-b435-dbf0fac373f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "4027029b-e749-405b-abd7-419c00e4f631"
    ]
  },
  "101144": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 21, 1985",
    "EXP": "2",
    "SCHOOL": "Florida State",
    "name": "Von Wafer",
    "rosters": [
      "88463408-f197-42d6-b955-433fadaa716d",
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "1158f2a2-b551-455d-8934-8d7630f69ba5"
    ]
  },
  "101145": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 26, 1985",
    "EXP": "11",
    "SCHOOL": "No College",
    "name": "Monta Ellis",
    "rosters": [
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "728d43da-a0f1-4599-96c2-a09036340618",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea"
    ]
  },
  "101146": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "183",
    "BIRTH_DATE": "DEC 05, 1984",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Roko Ukic",
    "rosters": [
      "91c165fd-b08a-4c73-84bf-a029d5600436"
    ]
  },
  "101147": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "261",
    "BIRTH_DATE": "MAR 10, 1985",
    "EXP": "R",
    "SCHOOL": "Pittsburgh",
    "name": "Chris Taft",
    "rosters": [
      "4d430473-658d-4cd6-9454-184fc4fa8b60"
    ]
  },
  "101148": {
    "NUM": "9",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUN 02, 1984",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Mile Ilic",
    "rosters": [
      "dcd3f345-f8eb-4d80-b466-74386669a31a"
    ]
  },
  "101149": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 12, 1986",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Martynas Andriuskevicius",
    "rosters": [
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "b8ede009-44e0-4e12-977b-3391a1900e94"
    ]
  },
  "101150": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 27, 1986",
    "EXP": "9",
    "SCHOOL": "No College",
    "name": "Lou Williams",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a"
    ]
  },
  "101152": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "201",
    "BIRTH_DATE": "JUL 01, 1984",
    "EXP": "1",
    "SCHOOL": "Indiana",
    "name": "Bracey Wright",
    "rosters": [
      "1ed38523-9af0-4cfb-ac38-c13d8d80315a",
      "3e4b502a-18e0-4f72-b057-966f0972f973"
    ]
  },
  "101153": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 22, 1983",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Mickael Gelabale",
    "rosters": [
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd"
    ]
  },
  "101154": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "AUG 22, 1986",
    "EXP": "6",
    "SCHOOL": "South Kent School",
    "name": "Andray Blatche",
    "rosters": [
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c"
    ]
  },
  "101155": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "245",
    "BIRTH_DATE": "SEP 01, 1982",
    "EXP": "4",
    "SCHOOL": "Providence",
    "name": "Ryan Gomes",
    "rosters": [
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "86b93b83-92a1-4756-8676-68efd7025399",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1"
    ]
  },
  "101158": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "208",
    "BIRTH_DATE": "FEB 04, 1982",
    "EXP": "1",
    "SCHOOL": "Louisana-Lafayette",
    "name": "Orien Greene",
    "rosters": [
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e"
    ]
  },
  "101160": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 20, 1982",
    "EXP": "1",
    "SCHOOL": "Mississippi",
    "name": "Lawrence Roberts",
    "rosters": [
      "b00a755a-14e3-4682-bed6-49df9136ad09",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394"
    ]
  },
  "101161": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 01, 1987",
    "EXP": "3",
    "SCHOOL": "No College",
    "name": "Amir Johnson",
    "rosters": [
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "896773e3-d58b-484d-b4ce-cba82ed75933",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "532f6daa-fa7a-422a-9326-f0c577396c95",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1"
    ]
  },
  "101162": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 17, 1984",
    "EXP": "10",
    "SCHOOL": null,
    "name": "Marcin Gortat",
    "rosters": [
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "8d3a3080-9e74-4512-8869-83aad854d0a0",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad"
    ]
  },
  "101165": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 21, 1983",
    "EXP": "1",
    "SCHOOL": "Pepperdine",
    "name": "Alex Acker",
    "rosters": [
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5"
    ]
  },
  "101166": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "238",
    "BIRTH_DATE": "MAY 14, 1983",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Uros Slokar",
    "rosters": [
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c"
    ]
  },
  "101177": {
    "NUM": "21",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAR 21, 1975",
    "EXP": "4",
    "SCHOOL": "Las Varillas, Argentina",
    "name": "Fabricio Oberto",
    "rosters": [
      "187b2b41-379c-4869-a6d8-8469aec4efbc",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2",
      "13c01798-8ae0-4b74-aea4-30da75742856",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b"
    ]
  },
  "101178": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "214",
    "BIRTH_DATE": "JAN 19, 1980",
    "EXP": "R",
    "SCHOOL": "Lithuania",
    "name": "Arvydas Macijauskas",
    "rosters": [
      "01490269-b22f-47cf-87fb-1d45cb96a3f3"
    ]
  },
  "101179": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "187",
    "BIRTH_DATE": "JUN 21, 1983",
    "EXP": "5",
    "SCHOOL": "Utah Valley",
    "name": "Ronnie Price",
    "rosters": [
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "2b8c2fa5-408e-4e34-ac03-ec6c754e6f59",
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5"
    ]
  },
  "101180": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "197",
    "BIRTH_DATE": "MAR 05, 1976",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Sarunas Jasikevicius",
    "rosters": [
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "3d331f49-e3c4-41db-8e85-694ca2e24972"
    ]
  },
  "101181": {
    "NUM": "81",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 28, 1981",
    "EXP": "13",
    "SCHOOL": " ",
    "name": "Jose Calderon",
    "rosters": [
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "53990650-b132-46c8-9435-b3308f7a0b10",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "91c165fd-b08a-4c73-84bf-a029d5600436",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "101182": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "193",
    "BIRTH_DATE": "JUL 26, 1982",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Donell Taylor",
    "rosters": [
      "17282f4e-cff4-4e44-b0f2-ed5e6fdb68d2",
      "9ba5d620-d5f8-43d2-b943-459e066d28fe"
    ]
  },
  "101183": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 24, 1983",
    "EXP": "3",
    "SCHOOL": "Duke",
    "name": "Shavlik Randolph",
    "rosters": [
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "1fa437ae-dc38-4d7c-a9de-8e845431d373",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "e04c4504-ea6d-4181-8788-5cef35516215"
    ]
  },
  "101185": {
    "NUM": "2",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 20, 1982",
    "EXP": "1",
    "SCHOOL": "Oakland",
    "name": "Rawle Marshall",
    "rosters": [
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e"
    ]
  },
  "101187": {
    "NUM": "15",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 16, 1982",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Alan Anderson",
    "rosters": [
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "896773e3-d58b-484d-b4ce-cba82ed75933",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "2a8cce15-fec9-4173-8665-82815b3bfb24",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e"
    ]
  },
  "101188": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 15, 1983",
    "EXP": "R",
    "SCHOOL": "North Carolina-Charlotte",
    "name": "Eddie Basden",
    "rosters": [
      "75edf884-23c5-48d9-b441-efa9c4b2aa76"
    ]
  },
  "101189": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 20, 1981",
    "EXP": "4",
    "SCHOOL": "Murray State",
    "name": "James Singleton",
    "rosters": [
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "f91cd7d2-c5ba-4b3b-a805-9b19289efbc8",
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c"
    ]
  },
  "101194": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "FEB 14, 1983",
    "EXP": "R",
    "SCHOOL": "Florida",
    "name": "Anthony Roberson",
    "rosters": [
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "b00a755a-14e3-4682-bed6-49df9136ad09"
    ]
  },
  "101195": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "265",
    "BIRTH_DATE": "DEC 31, 1981",
    "EXP": "1",
    "SCHOOL": "Georgia Tech",
    "name": "Luke Schenscher",
    "rosters": [
      "75edf884-23c5-48d9-b441-efa9c4b2aa76",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e"
    ]
  },
  "101198": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "JAN 04, 1983",
    "EXP": "6",
    "SCHOOL": "Georgia Tech",
    "name": "Will Bynum",
    "rosters": [
      "4d430473-658d-4cd6-9454-184fc4fa8b60",
      "8d3a3080-9e74-4512-8869-83aad854d0a0",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "101204": {
    "NUM": "21",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "251",
    "BIRTH_DATE": "JUN 09, 1983",
    "EXP": "4",
    "SCHOOL": null,
    "name": "Dwayne Jones",
    "rosters": [
      "d2b8a516-34ac-4a97-bec5-a34b8326eee9",
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc"
    ]
  },
  "101207": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 09, 1979",
    "EXP": "R",
    "SCHOOL": "Minnesota",
    "name": "Kevin Burleson",
    "rosters": [
      "2a8cce15-fec9-4173-8665-82815b3bfb24"
    ]
  },
  "101211": {
    "NUM": "23",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 11, 1982",
    "EXP": "4",
    "SCHOOL": "Oklahoma State",
    "name": "Stephen Graham",
    "rosters": [
      "5319635c-297b-4e15-8b2b-a76473122ca9",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575"
    ]
  },
  "101212": {
    "NUM": "11",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "268",
    "BIRTH_DATE": "SEP 02, 1983",
    "EXP": "1",
    "SCHOOL": "Uruguay",
    "name": "Esteban Batista",
    "rosters": [
      "1133cf87-e88e-4041-9357-78207256459c",
      "3458a560-4411-4f2b-808a-125fa86c6925"
    ]
  },
  "101214": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "218",
    "BIRTH_DATE": "FEB 19, 1983",
    "EXP": "1",
    "SCHOOL": "North Carolina",
    "name": "Jawad Williams",
    "rosters": [
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "d880c1ca-1546-4361-b8de-d39e6da49f39"
    ]
  },
  "101215": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 08, 1982",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Will Conroy",
    "rosters": [
      "4cb979b9-ca00-4b88-8661-de8bde01b339"
    ]
  },
  "101219": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 15, 1979",
    "EXP": "R",
    "SCHOOL": "North Carolina State",
    "name": "Anthony Grundy",
    "rosters": [
      "1133cf87-e88e-4041-9357-78207256459c"
    ]
  },
  "101230": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 04, 1981",
    "EXP": "R",
    "SCHOOL": "Fresno State",
    "name": "Noel Felix",
    "rosters": [
      "47dca733-a8bb-4e17-9d48-e6cc54aed42b"
    ]
  },
  "101235": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 16, 1983",
    "EXP": "3",
    "SCHOOL": "Kentucky",
    "name": "Kelenna Azubuike",
    "rosters": [
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a51334eb-1216-409a-a077-121ff993d69c"
    ]
  },
  "101236": {
    "NUM": "44",
    "POSITION": "C-F",
    "HEIGHT": "6-6",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 11, 1983",
    "EXP": "9",
    "SCHOOL": "Kentucky",
    "name": "Chuck Hayes",
    "rosters": [
      "4ebd2afc-6b4c-4b9a-ba79-d85c32a108ef",
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "8632a477-9924-4d11-859a-78889ddfee93",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a"
    ]
  },
  "101249": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "166",
    "BIRTH_DATE": "NOV 21, 1982",
    "EXP": "6",
    "SCHOOL": "Oklahoma State",
    "name": "John Lucas III",
    "rosters": [
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "896773e3-d58b-484d-b4ce-cba82ed75933",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7"
    ]
  },
  "101258": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 08, 1977",
    "EXP": "1",
    "SCHOOL": "Oklahoma State",
    "name": "Corey Williams",
    "rosters": [
      "dfd0639e-65ea-4d2f-8f1b-4f557aa82ce7",
      "54f0f70e-ad29-449d-8754-ee6a602fe375"
    ]
  },
  "101261": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 31, 1980",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "Andre Owens",
    "rosters": [
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "77de9913-6212-4795-a7e5-3cd689ad5bcb"
    ]
  },
  "200081": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 13, 1980",
    "EXP": "4",
    "SCHOOL": "Meridian Community College",
    "name": "Jamario Moon",
    "rosters": [
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6"
    ]
  },
  "200745": {
    "NUM": "7",
    "POSITION": "F-C",
    "HEIGHT": "7-0",
    "WEIGHT": "256",
    "BIRTH_DATE": "OCT 26, 1985",
    "EXP": "6",
    "SCHOOL": null,
    "name": "Andrea Bargnani",
    "rosters": [
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c",
      "0e001846-f440-4bca-ae94-6cf01edf7123",
      "91c165fd-b08a-4c73-84bf-a029d5600436",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "896773e3-d58b-484d-b4ce-cba82ed75933"
    ]
  },
  "200746": {
    "NUM": "12",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUL 19, 1985",
    "EXP": "13",
    "SCHOOL": "Texas",
    "name": "LaMarcus Aldridge",
    "rosters": [
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "8e1bb40f-2c63-4904-9e81-67592b326bda",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "200747": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 19, 1984",
    "EXP": "1",
    "SCHOOL": "Gonzaga",
    "name": "Adam Morrison",
    "rosters": [
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e",
      "a16e37d5-d174-4f55-bfdc-ba838caae024"
    ]
  },
  "200748": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 17, 1986",
    "EXP": "6",
    "SCHOOL": "Louisiana State",
    "name": "Tyrus Thomas",
    "rosters": [
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095"
    ]
  },
  "200749": {
    "NUM": "22",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "OCT 21, 1983",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Shelden Williams",
    "rosters": [
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "a50184cf-bda0-43f5-970c-379a18a8c902"
    ]
  },
  "200750": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "211",
    "BIRTH_DATE": "JUL 23, 1984",
    "EXP": "4",
    "SCHOOL": "Washington",
    "name": "Brandon Roy",
    "rosters": [
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "8e1bb40f-2c63-4904-9e81-67592b326bda"
    ]
  },
  "200751": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "213",
    "BIRTH_DATE": "SEP 24, 1983",
    "EXP": "3",
    "SCHOOL": "Villanova",
    "name": "Randy Foye",
    "rosters": [
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "3e4b502a-18e0-4f72-b057-966f0972f973",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "83706098-479a-4732-9529-09c78cfdb502",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b"
    ]
  },
  "200752": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 17, 1986",
    "EXP": "5",
    "SCHOOL": "Connecticut",
    "name": "Rudy Gay",
    "rosters": [
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e",
      "896773e3-d58b-484d-b4ce-cba82ed75933",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394",
      "d98d42ba-33de-433f-9c54-23973925049a",
      "824e5cff-47e8-4169-8451-74a294a985e8",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c",
      "bce414a0-1435-4744-9faf-b7027085c341",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289"
    ]
  },
  "200753": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 20, 1986",
    "EXP": "3",
    "SCHOOL": "Bradley",
    "name": "Patrick O'Bryant",
    "rosters": [
      "3d8f99eb-b0ef-4035-9fac-39a301a315c8",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "91c165fd-b08a-4c73-84bf-a029d5600436",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4"
    ]
  },
  "200754": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 12, 1986",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Mouhamed Sene",
    "rosters": [
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "3ceb35ca-c6f1-4729-a9ce-14945a8621ab",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd"
    ]
  },
  "200755": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 24, 1984",
    "EXP": "12",
    "SCHOOL": "Duke",
    "name": "JJ Redick",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "5a728193-2607-49fa-b4d1-2298f8044312"
    ]
  },
  "200756": {
    "NUM": "57",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 23, 1984",
    "EXP": "5",
    "SCHOOL": "Connecticut",
    "name": "Hilton Armstrong",
    "rosters": [
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a"
    ]
  },
  "200757": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 02, 1984",
    "EXP": "12",
    "SCHOOL": " ",
    "name": "Thabo Sefolosha",
    "rosters": [
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "b8ede009-44e0-4e12-977b-3391a1900e94",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "200758": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "227",
    "BIRTH_DATE": "MAR 20, 1985",
    "EXP": "3",
    "SCHOOL": "Arkansas",
    "name": "Ronnie Brewer",
    "rosters": [
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c"
    ]
  },
  "200759": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 03, 1986",
    "EXP": "2",
    "SCHOOL": "North Carolina State",
    "name": "Cedric Simmons",
    "rosters": [
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317"
    ]
  },
  "200760": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 05, 1984",
    "EXP": "3",
    "SCHOOL": "Memphis",
    "name": "Rodney Carney",
    "rosters": [
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "5d899f8e-5e64-42dc-8573-762857c42fff"
    ]
  },
  "200761": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 16, 1986",
    "EXP": "7",
    "SCHOOL": "Memphis",
    "name": "Shawne Williams",
    "rosters": [
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "ba39d2a7-c7f8-4444-aa6b-7fda97d5ce0e",
      "23eb2ba2-6730-48ab-83ac-946c3e799268",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7"
    ]
  },
  "200762": {
    "NUM": "14",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "234",
    "BIRTH_DATE": "DEC 08, 1985",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Oleksiy Pecherov",
    "rosters": [
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de"
    ]
  },
  "200763": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAY 16, 1984",
    "EXP": "2",
    "SCHOOL": "Rutgers",
    "name": "Quincy Douby",
    "rosters": [
      "fff327c0-dd6f-411a-96ea-179675fe9739",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "91c165fd-b08a-4c73-84bf-a029d5600436"
    ]
  },
  "200764": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "208",
    "BIRTH_DATE": "JUL 14, 1984",
    "EXP": "4",
    "SCHOOL": "South Carolina",
    "name": "Renaldo Balkman",
    "rosters": [
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0"
    ]
  },
  "200765": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "186",
    "BIRTH_DATE": "FEB 22, 1986",
    "EXP": "9",
    "SCHOOL": "Kentucky",
    "name": "Rajon Rondo",
    "rosters": [
      "86b93b83-92a1-4756-8676-68efd7025399",
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "88463408-f197-42d6-b955-433fadaa716d",
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "db38d209-7326-41b8-948f-588f9a050e00",
      "34a215f4-c672-4508-a765-ed7809c0e939"
    ]
  },
  "200766": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 03, 1985",
    "EXP": "3",
    "SCHOOL": "Connecticut",
    "name": "Marcus Williams",
    "rosters": [
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c"
    ]
  },
  "200767": {
    "NUM": "2",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 21, 1984",
    "EXP": "3",
    "SCHOOL": "Connecticut",
    "name": "Josh Boone",
    "rosters": [
      "dcd3f345-f8eb-4d80-b466-74386669a31a",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4"
    ]
  },
  "200768": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "MAR 25, 1986",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Kyle Lowry",
    "rosters": [
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "8632a477-9924-4d11-859a-78889ddfee93",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "896773e3-d58b-484d-b4ce-cba82ed75933",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394",
      "d98d42ba-33de-433f-9c54-23973925049a"
    ]
  },
  "200769": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 29, 1985",
    "EXP": "6",
    "SCHOOL": "Michigan State",
    "name": "Shannon Brown",
    "rosters": [
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553"
    ]
  },
  "200770": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 30, 1986",
    "EXP": "8",
    "SCHOOL": "California-Los Angeles",
    "name": "Jordan Farmar",
    "rosters": [
      "74d2c7ce-3eea-4099-b898-8842a34143f8",
      "4206ee94-a61a-464f-99f8-016d0e855498",
      "0f3fc843-6d34-46a8-8aac-d07a35a96378",
      "7c717aa8-5048-422a-9ec1-af77b479d5c0",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42"
    ]
  },
  "200771": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "176",
    "BIRTH_DATE": "JUN 12, 1986",
    "EXP": "2",
    "SCHOOL": null,
    "name": "Sergio Rodriguez",
    "rosters": [
      "d52a9555-ff77-4418-a883-c18272a57975",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "be3b4d44-fa5a-4530-9f15-472d9df9726e",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215"
    ]
  },
  "200772": {
    "NUM": "13",
    "POSITION": "F-G",
    "HEIGHT": "6-5",
    "WEIGHT": "202",
    "BIRTH_DATE": "FEB 09, 1984",
    "EXP": "2",
    "SCHOOL": "Michigan State",
    "name": "Maurice Ager",
    "rosters": [
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "6729ad33-cbf8-4795-b814-64b937327c6c"
    ]
  },
  "200776": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 04, 1984",
    "EXP": "1",
    "SCHOOL": "Temple",
    "name": "Mardy Collins",
    "rosters": [
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8"
    ]
  },
  "200777": {
    "NUM": "19",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 07, 1987",
    "EXP": "2",
    "SCHOOL": null,
    "name": "Joel Freeland",
    "rosters": [
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b"
    ]
  },
  "200778": {
    "NUM": "33",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 21, 1982",
    "EXP": "R",
    "SCHOOL": "Cincinnati",
    "name": "James White",
    "rosters": [
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "626775a6-4271-4d9f-ba9d-a8b119b162c2"
    ]
  },
  "200779": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 13, 1983",
    "EXP": "7",
    "SCHOOL": "Marquette",
    "name": "Steve Novak",
    "rosters": [
      "4852e234-5b8e-4b03-b656-686bfe7506c0",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831"
    ]
  },
  "200780": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 16, 1984",
    "EXP": "4",
    "SCHOOL": "South Florida",
    "name": "Solomon Jones",
    "rosters": [
      "3458a560-4411-4f2b-808a-125fa86c6925",
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78"
    ]
  },
  "200781": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "270",
    "BIRTH_DATE": "JUL 21, 1984",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Paul Davis",
    "rosters": [
      "4cb979b9-ca00-4b88-8661-de8bde01b339",
      "a522fda6-7649-4704-9b9f-262e60317e8a"
    ]
  },
  "200782": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 05, 1985",
    "EXP": "5",
    "SCHOOL": "Texas",
    "name": "P.J. Tucker",
    "rosters": [
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e"
    ]
  },
  "200783": {
    "NUM": "83",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "265",
    "BIRTH_DATE": "NOV 10, 1983",
    "EXP": "5",
    "SCHOOL": "Boston College",
    "name": "Craig Smith",
    "rosters": [
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "3e4b502a-18e0-4f72-b057-966f0972f973",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676"
    ]
  },
  "200784": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JAN 09, 1984",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Bobby Jones",
    "rosters": [
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8"
    ]
  },
  "200785": {
    "NUM": "19",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 19, 1985",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Kosta Perovic",
    "rosters": [
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860"
    ]
  },
  "200786": {
    "NUM": "34",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 27, 1984",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "David Noel",
    "rosters": [
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee"
    ]
  },
  "200788": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "FEB 27, 1984",
    "EXP": "1",
    "SCHOOL": "Illinois",
    "name": "James Augustine",
    "rosters": [
      "6c5fba75-facf-42a7-a037-48dd10294ade",
      "028a8849-766d-4111-bb14-ff1df46068c5"
    ]
  },
  "200789": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 27, 1986",
    "EXP": "6",
    "SCHOOL": "Texas",
    "name": "Daniel Gibson",
    "rosters": [
      "54af9cd6-79f3-4008-b5ee-94591bb37088",
      "5406747a-e323-4cbf-be5b-cf9ff36577a9",
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c"
    ]
  },
  "200790": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 31, 1984",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Marcus Vinicius",
    "rosters": [
      "69b0bce7-6eb3-4a3a-8573-640e2d373bc5"
    ]
  },
  "200792": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 08, 1983",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Alexander Johnson",
    "rosters": [
      "8632a477-9924-4d11-859a-78889ddfee93",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394"
    ]
  },
  "200793": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "181",
    "BIRTH_DATE": "AUG 17, 1984",
    "EXP": "R",
    "SCHOOL": "Illinois",
    "name": "Dee Brown",
    "rosters": [
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e"
    ]
  },
  "200794": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "253",
    "BIRTH_DATE": "FEB 10, 1985",
    "EXP": "6",
    "SCHOOL": "Louisiana Tech",
    "name": "Paul Millsap",
    "rosters": [
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "dbd3455e-60ba-4d50-9b96-353f912b371a",
      "88f678db-e0f1-4a17-8f1d-38d09b14cd5e",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502"
    ]
  },
  "200796": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 22, 1984",
    "EXP": "4",
    "SCHOOL": "California",
    "name": "Leon Powe",
    "rosters": [
      "86b93b83-92a1-4756-8676-68efd7025399",
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "bce414a0-1435-4744-9faf-b7027085c341"
    ]
  },
  "200797": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 10, 1984",
    "EXP": "1",
    "SCHOOL": "California-Los Angeles",
    "name": "Ryan Hollins",
    "rosters": [
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e",
      "a16e37d5-d174-4f55-bfdc-ba838caae024"
    ]
  },
  "200798": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "245",
    "BIRTH_DATE": "OCT 22, 1984",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Cheikh Samb",
    "rosters": [
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21"
    ]
  },
  "200801": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 20, 1984",
    "EXP": "R",
    "SCHOOL": "Arizona",
    "name": "Hassan Adams",
    "rosters": [
      "dcd3f345-f8eb-4d80-b466-74386669a31a"
    ]
  },
  "200806": {
    "NUM": "52",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 26, 1985",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Damir Markota",
    "rosters": [
      "b0ee51a2-4e6b-4a6a-a766-18894f7550ee"
    ]
  },
  "200807": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 08, 1983",
    "EXP": "R",
    "SCHOOL": "Iowa State",
    "name": "Will Blalock",
    "rosters": [
      "532f6daa-fa7a-422a-9326-f0c577396c95"
    ]
  },
  "200809": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 27, 1983",
    "EXP": "4",
    "SCHOOL": "Notre Dame",
    "name": "Chris Quinn",
    "rosters": [
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "d4fda0d9-e426-471e-b6ab-a16b93412363",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52"
    ]
  },
  "200810": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 17, 1984",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Allan Ray",
    "rosters": [
      "86b93b83-92a1-4756-8676-68efd7025399"
    ]
  },
  "200811": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "238",
    "BIRTH_DATE": "DEC 07, 1982",
    "EXP": "3",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Lou Amundson",
    "rosters": [
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "728d43da-a0f1-4599-96c2-a09036340618",
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "2b6f0635-6784-4895-aaf2-6ce866b103d8",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc"
    ]
  },
  "200814": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "189",
    "BIRTH_DATE": "MAR 21, 1984",
    "EXP": "R",
    "SCHOOL": "South Carolina",
    "name": "Tarence Kinsey",
    "rosters": [
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "a5a047a0-58fc-499b-ae59-8ebed7fc0394"
    ]
  },
  "200816": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "DEC 19, 1977",
    "EXP": "R",
    "SCHOOL": "Spain",
    "name": "Jorge Garbajosa",
    "rosters": [
      "dbbeab72-0d20-45a0-b2e4-c51e0162026c"
    ]
  },
  "200817": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "175",
    "BIRTH_DATE": "DEC 02, 1983",
    "EXP": "R",
    "SCHOOL": "Portland",
    "name": "Pooh Jeter",
    "rosters": [
      "f682d9da-cb2e-4f60-b710-6accad2d386b"
    ]
  },
  "200818": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 12, 1984",
    "EXP": "R",
    "SCHOOL": "Wyoming",
    "name": "Justin Williams",
    "rosters": [
      "fff327c0-dd6f-411a-96ea-179675fe9739"
    ]
  },
  "200821": {
    "NUM": "9",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 29, 1982",
    "EXP": "3",
    "SCHOOL": "Pepperdine",
    "name": "Yakhouba Diawara",
    "rosters": [
      "8da357d5-a704-4099-97e4-f09887e5cf25",
      "49864ef2-1c25-4439-86f9-86f5b097ecf2",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3"
    ]
  },
  "200822": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 07, 1983",
    "EXP": "1",
    "SCHOOL": "George Washington",
    "name": "Pops Mensah-Bonsu",
    "rosters": [
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "91c165fd-b08a-4c73-84bf-a029d5600436"
    ]
  },
  "200826": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "178",
    "BIRTH_DATE": "JUN 26, 1984",
    "EXP": "7",
    "SCHOOL": "Northeastern",
    "name": "J.J. Barea",
    "rosters": [
      "3ca88bbe-9aad-46ea-b20a-f97e9534f2f2",
      "b854aa2c-06f1-4ed4-aafa-f10552d16a5d",
      "9fded93a-716c-4692-88f2-bcae0e1464b2",
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644"
    ]
  },
  "200835": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 26, 1979",
    "EXP": "R",
    "SCHOOL": "Argentina",
    "name": "Walter Herrmann",
    "rosters": [
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1",
      "4bae2ffc-919f-42b5-98f5-aaa59862364e"
    ]
  },
  "200837": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUN 05, 1984",
    "EXP": "R",
    "SCHOOL": "George Washington",
    "name": "Mike Hall",
    "rosters": [
      "9ba5d620-d5f8-43d2-b943-459e066d28fe"
    ]
  },
  "200839": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 15, 1983",
    "EXP": "1",
    "SCHOOL": "Rice",
    "name": "Mike Harris",
    "rosters": [
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "8632a477-9924-4d11-859a-78889ddfee93"
    ]
  },
  "200971": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 15, 1983",
    "EXP": "R",
    "SCHOOL": "Arkansas",
    "name": "Dontell Jefferson",
    "rosters": [
      "fc384a91-81b8-47cb-b999-2a497289f651"
    ]
  },
  "200978": {
    "NUM": "32",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 01, 1984",
    "EXP": "2",
    "SCHOOL": "Delta State",
    "name": "Jeremy Richardson",
    "rosters": [
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "52121faf-ebcf-4269-8b4a-f0484668809c"
    ]
  },
  "200984": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "FEB 02, 1981",
    "EXP": "R",
    "SCHOOL": "Weber State",
    "name": "Lance Allred",
    "rosters": [
      "5406747a-e323-4cbf-be5b-cf9ff36577a9"
    ]
  },
  "201041": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "170",
    "BIRTH_DATE": "OCT 06, 1982",
    "EXP": "R",
    "SCHOOL": "Jacksonville State",
    "name": "Walker Russell",
    "rosters": [
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1"
    ]
  },
  "201043": {
    "NUM": "5",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "JAN 02, 1986",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Randolph Morris",
    "rosters": [
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "bdfbd89d-2a20-40d6-ab32-cae45a68d7c4",
      "2bf746ba-45da-4f5f-9b41-89612cebece8"
    ]
  },
  "201141": {
    "NUM": "52",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "285",
    "BIRTH_DATE": "JAN 22, 1988",
    "EXP": "2",
    "SCHOOL": "Ohio State",
    "name": "Greg Oden",
    "rosters": [
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "8e1bb40f-2c63-4904-9e81-67592b326bda"
    ]
  },
  "201142": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 29, 1988",
    "EXP": "8",
    "SCHOOL": "Texas",
    "name": "Kevin Durant",
    "rosters": [
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "206a57a6-ccc5-413b-a413-5deab8b03a78",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59"
    ]
  },
  "201143": {
    "NUM": "42",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 03, 1986",
    "EXP": "12",
    "SCHOOL": "Florida",
    "name": "Al Horford",
    "rosters": [
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "201144": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 11, 1987",
    "EXP": "11",
    "SCHOOL": "Ohio State",
    "name": "Mike Conley",
    "rosters": [
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4",
      "d98d42ba-33de-433f-9c54-23973925049a",
      "824e5cff-47e8-4169-8451-74a294a985e8",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c",
      "bce414a0-1435-4744-9faf-b7027085c341",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289",
      "323505fb-0be2-4c98-96be-9a14a40d1c78",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "201145": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "AUG 28, 1986",
    "EXP": "10",
    "SCHOOL": "Georgetown",
    "name": "Jeff Green",
    "rosters": [
      "88463408-f197-42d6-b955-433fadaa716d",
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "d606503c-c3bd-4127-a2f2-5770156c6dcd",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "201146": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "OCT 27, 1987",
    "EXP": "3",
    "SCHOOL": null,
    "name": "Yi Jianlian",
    "rosters": [
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741"
    ]
  },
  "201147": {
    "NUM": "3",
    "POSITION": "G-F",
    "HEIGHT": "6-9",
    "WEIGHT": "186",
    "BIRTH_DATE": "MAR 05, 1986",
    "EXP": "10",
    "SCHOOL": "Florida",
    "name": "Corey Brewer",
    "rosters": [
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "77e6e4de-42ee-4c42-be39-b046215914f2"
    ]
  },
  "201148": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 05, 1987",
    "EXP": "8",
    "SCHOOL": "North Carolina",
    "name": "Brandan Wright",
    "rosters": [
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d"
    ]
  },
  "201149": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 25, 1985",
    "EXP": "11",
    "SCHOOL": "Florida",
    "name": "Joakim Noah",
    "rosters": [
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "201150": {
    "NUM": "00",
    "POSITION": "F-C",
    "HEIGHT": "7-1",
    "WEIGHT": "245",
    "BIRTH_DATE": "APR 28, 1988",
    "EXP": "8",
    "SCHOOL": "Washington",
    "name": "Spencer Hawes",
    "rosters": [
      "51581511-27eb-417b-be7a-c3792b97c046",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "a50184cf-bda0-43f5-970c-379a18a8c902",
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "31164298-2c0d-4a44-b557-e740801b4b1d"
    ]
  },
  "201151": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "202",
    "BIRTH_DATE": "JAN 25, 1985",
    "EXP": "3",
    "SCHOOL": "Texas A&M",
    "name": "Acie Law",
    "rosters": [
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "728d43da-a0f1-4599-96c2-a09036340618"
    ]
  },
  "201152": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUN 21, 1988",
    "EXP": "6",
    "SCHOOL": "Georgia Tech",
    "name": "Thaddeus Young",
    "rosters": [
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "341a849d-e970-4a90-8bee-8a209ea47cb1"
    ]
  },
  "201153": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 20, 1987",
    "EXP": "3",
    "SCHOOL": "Kansas",
    "name": "Julian Wright",
    "rosters": [
      "14a0291f-910b-489c-ad62-b6ef70677114",
      "6c0c60a8-7363-41d6-bea6-a19e1b5adcea",
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670"
    ]
  },
  "201154": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 07, 1983",
    "EXP": "2",
    "SCHOOL": "Florida State",
    "name": "Al Thornton",
    "rosters": [
      "728d43da-a0f1-4599-96c2-a09036340618",
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b"
    ]
  },
  "201155": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 21, 1986",
    "EXP": "6",
    "SCHOOL": "Eastern Washington",
    "name": "Rodney Stuckey",
    "rosters": [
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "201156": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 01, 1985",
    "EXP": "3",
    "SCHOOL": "USC",
    "name": "Nick Young",
    "rosters": [
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741"
    ]
  },
  "201157": {
    "NUM": "51",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 13, 1986",
    "EXP": "1",
    "SCHOOL": "Boston College",
    "name": "Sean Williams",
    "rosters": [
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "658a886a-3fad-4c2d-ae09-85c553c4e7e4",
      "6729ad33-cbf8-4795-b814-64b937327c6c"
    ]
  },
  "201158": {
    "NUM": "21",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 25, 1986",
    "EXP": "9",
    "SCHOOL": " ",
    "name": "Marco Belinelli",
    "rosters": [
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e"
    ]
  },
  "201159": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 31, 1987",
    "EXP": "2",
    "SCHOOL": "Georgia Tech",
    "name": "Javaris Crittenton",
    "rosters": [
      "d98d42ba-33de-433f-9c54-23973925049a",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b"
    ]
  },
  "201160": {
    "NUM": "14",
    "POSITION": "F-C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 02, 1986",
    "EXP": "9",
    "SCHOOL": "Colorado State",
    "name": "Jason Smith",
    "rosters": [
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad"
    ]
  },
  "201161": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 28, 1987",
    "EXP": "4",
    "SCHOOL": "Ohio State",
    "name": "Daequan Cook",
    "rosters": [
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d"
    ]
  },
  "201162": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 10, 1985",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "Jared Dudley",
    "rosters": [
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "db38d209-7326-41b8-948f-588f9a050e00",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "a16e37d5-d174-4f55-bfdc-ba838caae024"
    ]
  },
  "201163": {
    "NUM": "21",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 10, 1987",
    "EXP": "2",
    "SCHOOL": "DePaul",
    "name": "Wilson Chandler",
    "rosters": [
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "206a57a6-ccc5-413b-a413-5deab8b03a78",
      "2bf746ba-45da-4f5f-9b41-89612cebece8",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "d52a9555-ff77-4418-a883-c18272a57975"
    ]
  },
  "201164": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 04, 1985",
    "EXP": "2",
    "SCHOOL": null,
    "name": "Rudy Fernandez",
    "rosters": [
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "8e1bb40f-2c63-4904-9e81-67592b326bda"
    ]
  },
  "201165": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 02, 1985",
    "EXP": "2",
    "SCHOOL": "Rice",
    "name": "Morris Almond",
    "rosters": [
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c"
    ]
  },
  "201166": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "161",
    "BIRTH_DATE": "JAN 14, 1985",
    "EXP": "3",
    "SCHOOL": "Oregon",
    "name": "Aaron Brooks",
    "rosters": [
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "8632a477-9924-4d11-859a-78889ddfee93",
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916"
    ]
  },
  "201167": {
    "NUM": "28",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 15, 1985",
    "EXP": "1",
    "SCHOOL": "UCLA",
    "name": "Arron Afflalo",
    "rosters": [
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "a497fd4a-1e3a-470a-a658-dbd552e1ac21",
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1"
    ]
  },
  "201168": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 01, 1985",
    "EXP": "4",
    "SCHOOL": " ",
    "name": "Tiago Splitter",
    "rosters": [
      "e024d958-351f-40c8-be44-4c56111346b2",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a"
    ]
  },
  "201169": {
    "NUM": "29",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 11, 1984",
    "EXP": "1",
    "SCHOOL": "Wisconsin",
    "name": "Alando Tucker",
    "rosters": [
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b"
    ]
  },
  "201171": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "248",
    "BIRTH_DATE": "SEP 19, 1983",
    "EXP": "7",
    "SCHOOL": "Purdue",
    "name": "Carl Landry",
    "rosters": [
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "1eea79e8-edd3-4788-a937-608905bafdf4",
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c"
    ]
  },
  "201172": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "170",
    "BIRTH_DATE": "APR 19, 1986",
    "EXP": "1",
    "SCHOOL": "Southern California",
    "name": "Gabe Pruitt",
    "rosters": [
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482"
    ]
  },
  "201173": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "207",
    "BIRTH_DATE": "NOV 18, 1986",
    "EXP": "1",
    "SCHOOL": "Arizona",
    "name": "Marcus Williams",
    "rosters": [
      "a522fda6-7649-4704-9b9f-262e60317e8a",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87"
    ]
  },
  "201174": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 17, 1985",
    "EXP": "R",
    "SCHOOL": "Nevada-Reno",
    "name": "Nick Fazekas",
    "rosters": [
      "a522fda6-7649-4704-9b9f-262e60317e8a"
    ]
  },
  "201175": {
    "NUM": "11",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "289",
    "BIRTH_DATE": "JAN 01, 1986",
    "EXP": "5",
    "SCHOOL": "Louisiana State",
    "name": "Glen Davis",
    "rosters": [
      "84fd7836-34e8-47f3-9e7e-e0f1be13db4d",
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "18a81748-917e-4293-8ca8-c13073995d9b",
      "88463408-f197-42d6-b955-433fadaa716d",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "286981d2-4163-449b-ae90-84e38b5204fc"
    ]
  },
  "201176": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 15, 1984",
    "EXP": "R",
    "SCHOOL": "Alabama",
    "name": "Jermareo Davidson",
    "rosters": [
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a16e37d5-d174-4f55-bfdc-ba838caae024"
    ]
  },
  "201177": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "FEB 28, 1987",
    "EXP": "6",
    "SCHOOL": "Duke",
    "name": "Josh McRoberts",
    "rosters": [
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "1158f2a2-b551-455d-8934-8d7630f69ba5",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095",
      "45ed0581-1916-49f0-9a2f-fb15342345c5"
    ]
  },
  "201178": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "280",
    "BIRTH_DATE": "DEC 24, 1986",
    "EXP": "3",
    "SCHOOL": null,
    "name": "Kyrylo Fesenko",
    "rosters": [
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "95eab72c-d15a-4057-bc71-c9cea00af893",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5"
    ]
  },
  "201180": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 06, 1985",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Sun Sun",
    "rosters": [
      "0f3fc843-6d34-46a8-8aac-d07a35a96378"
    ]
  },
  "201181": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "270",
    "BIRTH_DATE": "DEC 25, 1984",
    "EXP": "R",
    "SCHOOL": "Florida",
    "name": "Chris Richard",
    "rosters": [
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "97bb2be7-c9f5-4788-be33-8c731aa5e9d6"
    ]
  },
  "201182": {
    "NUM": "34",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 25, 1984",
    "EXP": "R",
    "SCHOOL": "Vanderbilt",
    "name": "Derrick Byars",
    "rosters": [
      "eaa71d89-5839-4a85-945e-b783a1864495"
    ]
  },
  "201186": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 17, 1982",
    "EXP": "R",
    "SCHOOL": "Massachusetts",
    "name": "Stephane Lasme",
    "rosters": [
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba"
    ]
  },
  "201187": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 20, 1985",
    "EXP": "3",
    "SCHOOL": "Fresno State",
    "name": "Dominic McGuire",
    "rosters": [
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "0b186d86-fd9e-42c1-b360-819024c5089c",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9"
    ]
  },
  "201188": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "255",
    "BIRTH_DATE": "JAN 29, 1985",
    "EXP": "9",
    "SCHOOL": " ",
    "name": "Marc Gasol",
    "rosters": [
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67",
      "824e5cff-47e8-4169-8451-74a294a985e8",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c",
      "bce414a0-1435-4744-9faf-b7027085c341",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289",
      "323505fb-0be2-4c98-96be-9a14a40d1c78",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "201189": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "270",
    "BIRTH_DATE": "DEC 07, 1984",
    "EXP": "5",
    "SCHOOL": "Pittsburgh",
    "name": "Aaron Gray",
    "rosters": [
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0",
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "896773e3-d58b-484d-b4ce-cba82ed75933"
    ]
  },
  "201191": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 07, 1986",
    "EXP": "R",
    "SCHOOL": "Oklahoma State",
    "name": "JamesOn Curry",
    "rosters": [
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0"
    ]
  },
  "201192": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "177",
    "BIRTH_DATE": "NOV 28, 1986",
    "EXP": "R",
    "SCHOOL": "Florida",
    "name": "Taurean Green",
    "rosters": [
      "49864ef2-1c25-4439-86f9-86f5b097ecf2"
    ]
  },
  "201193": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "216",
    "BIRTH_DATE": "SEP 04, 1984",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Demetris Nichols",
    "rosters": [
      "5d87b501-07e9-4b0d-bf0f-041d38dddfb0"
    ]
  },
  "201195": {
    "NUM": "35",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 01, 1984",
    "EXP": "R",
    "SCHOOL": "Providence",
    "name": "Herbert Hill",
    "rosters": [
      "18a2842d-ab87-49a7-87e8-257d7f5bfe8c"
    ]
  },
  "201196": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 11, 1986",
    "EXP": "9",
    "SCHOOL": "Nevada-Reno",
    "name": "Ramon Sessions",
    "rosters": [
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "63a0e7fd-f677-44c6-8a1a-6ffcba56cd2c",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "0642a459-b265-4cac-adbd-de39b087debf",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "8d3a3080-9e74-4512-8869-83aad854d0a0",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e"
    ]
  },
  "201199": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "201",
    "BIRTH_DATE": "JUN 15, 1985",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "DJ Strawberry",
    "rosters": [
      "ec837e73-81d2-4056-a56a-4e4dcbf8f706"
    ]
  },
  "201202": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "AUG 09, 1982",
    "EXP": "8",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Joel Anthony",
    "rosters": [
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7",
      "fbff0712-5fed-4e22-8afa-30387f9f0753"
    ]
  },
  "201203": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 18, 1984",
    "EXP": "R",
    "SCHOOL": "Arizona",
    "name": "Mustafa Shakur",
    "rosters": [
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741"
    ]
  },
  "201207": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 08, 1983",
    "EXP": "R",
    "SCHOOL": "Boise State",
    "name": "Coby Karl",
    "rosters": [
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "4206ee94-a61a-464f-99f8-016d0e855498"
    ]
  },
  "201208": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "258",
    "BIRTH_DATE": "NOV 08, 1984",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Darryl Watkins",
    "rosters": [
      "506d3176-b688-4317-9dc7-cfecc25eb790"
    ]
  },
  "201228": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "APR 17, 1984",
    "EXP": "7",
    "SCHOOL": "Tennessee",
    "name": "C.J. Watson",
    "rosters": [
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "77a2ce8b-f5dd-44da-a383-e3ad6e1f7860",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3"
    ]
  },
  "201229": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUN 01, 1985",
    "EXP": "5",
    "SCHOOL": "Creighton",
    "name": "Anthony Tolliver",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "45324799-5d41-45eb-b5ff-b3095f173c43",
      "45ed0581-1916-49f0-9a2f-fb15342345c5"
    ]
  },
  "201234": {
    "NUM": "10",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "218",
    "BIRTH_DATE": "AUG 30, 1984",
    "EXP": "1",
    "SCHOOL": "Jackson State",
    "name": "Trey Johnson",
    "rosters": [
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b"
    ]
  },
  "201238": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 19, 1984",
    "EXP": "3",
    "SCHOOL": "Georgia Tech",
    "name": "Mario West",
    "rosters": [
      "77b6338f-7de2-4f7d-ba3b-5c3bad321805",
      "90104477-18df-4be0-bd36-d89f5a93be6e",
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb"
    ]
  },
  "201242": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 08, 1985",
    "EXP": "1",
    "SCHOOL": "Missouri",
    "name": "Thomas Gardner",
    "rosters": [
      "90104477-18df-4be0-bd36-d89f5a93be6e"
    ]
  },
  "201274": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "255",
    "BIRTH_DATE": "APR 10, 1984",
    "EXP": "1",
    "SCHOOL": "Cal State-San Bernardino",
    "name": "Ivan Johnson",
    "rosters": [
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "09be1129-7bd3-4a7d-83ae-2eff8261643e"
    ]
  },
  "201281": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 19, 1985",
    "EXP": "R",
    "SCHOOL": "American University",
    "name": "Andre Ingram",
    "rosters": [
      "caf58514-10b8-4a99-8841-2c49c45bc519"
    ]
  },
  "201336": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 27, 1984",
    "EXP": "2",
    "SCHOOL": "Missouri State",
    "name": "Blake Ahearn",
    "rosters": [
      "afdba645-cf3b-45d9-8aa5-8c355af0c5ba",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de"
    ]
  },
  "201446": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "165",
    "BIRTH_DATE": "JAN 21, 1986",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Mike Taylor",
    "rosters": [
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5"
    ]
  },
  "201563": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 09, 1989",
    "EXP": "4",
    "SCHOOL": "Kansas State",
    "name": "Michael Beasley",
    "rosters": [
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553"
    ]
  },
  "201564": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 05, 1987",
    "EXP": "3",
    "SCHOOL": "Southern California",
    "name": "O.J. Mayo",
    "rosters": [
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "0642a459-b265-4cac-adbd-de39b087debf",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "824e5cff-47e8-4169-8451-74a294a985e8",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c",
      "bce414a0-1435-4744-9faf-b7027085c341",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289"
    ]
  },
  "201565": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 04, 1988",
    "EXP": "10",
    "SCHOOL": "Memphis",
    "name": "Derrick Rose",
    "rosters": [
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a",
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "201566": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 12, 1988",
    "EXP": "10",
    "SCHOOL": "UCLA",
    "name": "Russell Westbrook",
    "rosters": [
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c",
      "77e6e4de-42ee-4c42-be39-b046215914f2",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199"
    ]
  },
  "201567": {
    "NUM": "42",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "243",
    "BIRTH_DATE": "SEP 07, 1988",
    "EXP": "5",
    "SCHOOL": "UCLA",
    "name": "Kevin Love",
    "rosters": [
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce",
      "6b0a0176-c625-4185-97c7-e44301ac931f",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644"
    ]
  },
  "201568": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "233",
    "BIRTH_DATE": "AUG 08, 1988",
    "EXP": "10",
    "SCHOOL": " ",
    "name": "Danilo Gallinari",
    "rosters": [
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9",
      "d52a9555-ff77-4418-a883-c18272a57975",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "201569": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 25, 1988",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "Eric Gordon",
    "rosters": [
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "2cefe883-cd66-4007-b4b2-371761a1f717"
    ]
  },
  "201570": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 26, 1986",
    "EXP": "R",
    "SCHOOL": "West Virginia",
    "name": "Joe Alexander",
    "rosters": [
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0"
    ]
  },
  "201571": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 10, 1987",
    "EXP": "3",
    "SCHOOL": "Texas",
    "name": "D.J. Augustin",
    "rosters": [
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "fc384a91-81b8-47cb-b999-2a497289f651",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6"
    ]
  },
  "201572": {
    "NUM": "11",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "268",
    "BIRTH_DATE": "APR 01, 1988",
    "EXP": "8",
    "SCHOOL": "Stanford",
    "name": "Brook Lopez",
    "rosters": [
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18"
    ]
  },
  "201573": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 20, 1988",
    "EXP": "4",
    "SCHOOL": "Arizona",
    "name": "Jerryd Bayless",
    "rosters": [
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "323505fb-0be2-4c98-96be-9a14a40d1c78"
    ]
  },
  "201574": {
    "NUM": "1",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUL 21, 1986",
    "EXP": "7",
    "SCHOOL": "Rider University",
    "name": "Jason Thompson",
    "rosters": [
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "f682d9da-cb2e-4f60-b710-6accad2d386b",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "5ac68c0d-43c1-490d-87f9-5b431453d831"
    ]
  },
  "201575": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "233",
    "BIRTH_DATE": "JUL 07, 1985",
    "EXP": "5",
    "SCHOOL": "Kansas",
    "name": "Brandon Rush",
    "rosters": [
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d"
    ]
  },
  "201576": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 15, 1989",
    "EXP": "3",
    "SCHOOL": "Louisiana State",
    "name": "Anthony Randolph",
    "rosters": [
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4"
    ]
  },
  "201577": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "APR 01, 1988",
    "EXP": "6",
    "SCHOOL": "Stanford",
    "name": "Robin Lopez",
    "rosters": [
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc",
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b"
    ]
  },
  "201578": {
    "NUM": "5",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "AUG 04, 1987",
    "EXP": "3",
    "SCHOOL": "Florida",
    "name": "Marreese Speights",
    "rosters": [
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "c73e446c-a68a-4f40-895b-c4f1af1a5fb1",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289"
    ]
  },
  "201579": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "290",
    "BIRTH_DATE": "DEC 11, 1986",
    "EXP": "6",
    "SCHOOL": "Georgetown",
    "name": "Roy Hibbert",
    "rosters": [
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "dbea6066-cd75-4d65-9f35-343a7e0b5413",
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3"
    ]
  },
  "201580": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "252",
    "BIRTH_DATE": "JAN 19, 1988",
    "EXP": "2",
    "SCHOOL": "Nevada-Reno",
    "name": "JaVale McGee",
    "rosters": [
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "db38d209-7326-41b8-948f-588f9a050e00",
      "8b74a71f-9020-45e3-bbba-a5a962da11de",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741"
    ]
  },
  "201581": {
    "NUM": "21",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "242",
    "BIRTH_DATE": "SEP 04, 1988",
    "EXP": "7",
    "SCHOOL": "North Carolina State",
    "name": "JJ Hickson",
    "rosters": [
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "d5add497-b2c3-4523-b2ef-584c47479c7d"
    ]
  },
  "201582": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 06, 1988",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Alexis Ajinca",
    "rosters": [
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "fc384a91-81b8-47cb-b999-2a497289f651",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575"
    ]
  },
  "201583": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 06, 1988",
    "EXP": "3",
    "SCHOOL": "California",
    "name": "Ryan Anderson",
    "rosters": [
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "b259b8bd-910f-4cc5-87bd-b61bc14b7ac1",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7"
    ]
  },
  "201584": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 03, 1985",
    "EXP": "7",
    "SCHOOL": "Western Kentucky",
    "name": "Courtney Lee",
    "rosters": [
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "52121faf-ebcf-4269-8b4a-f0484668809c",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "31164298-2c0d-4a44-b557-e740801b4b1d"
    ]
  },
  "201585": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "265",
    "BIRTH_DATE": "FEB 24, 1989",
    "EXP": "6",
    "SCHOOL": "Ohio State",
    "name": "Kosta Koufos",
    "rosters": [
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "3cc7e0fb-8fe5-4365-9cda-b5f338c55cce",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00"
    ]
  },
  "201586": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 18, 1989",
    "EXP": "10",
    "SCHOOL": " ",
    "name": "Serge Ibaka",
    "rosters": [
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "201587": {
    "NUM": "5",
    "POSITION": "G-F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 14, 1988",
    "EXP": "11",
    "SCHOOL": " ",
    "name": "Nicolas Batum",
    "rosters": [
      "e04c4504-ea6d-4181-8788-5cef35516215",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "8e1bb40f-2c63-4904-9e81-67592b326bda",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "31164298-2c0d-4a44-b557-e740801b4b1d",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "201588": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "188",
    "BIRTH_DATE": "MAY 04, 1986",
    "EXP": "8",
    "SCHOOL": null,
    "name": "George Hill",
    "rosters": [
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "2d3887fe-0c7c-4e89-af3a-55503c9d6b87",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "272b357c-17be-4ce8-acf5-24791690c53b"
    ]
  },
  "201589": {
    "NUM": "00",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 25, 1988",
    "EXP": "4",
    "SCHOOL": "Kansas",
    "name": "Darrell Arthur",
    "rosters": [
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "824e5cff-47e8-4169-8451-74a294a985e8",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c",
      "bce414a0-1435-4744-9faf-b7027085c341",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289",
      "323505fb-0be2-4c98-96be-9a14a40d1c78"
    ]
  },
  "201590": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "226",
    "BIRTH_DATE": "FEB 21, 1988",
    "EXP": "4",
    "SCHOOL": "Syracuse",
    "name": "Donte Greene",
    "rosters": [
      "5e5e9d7a-7e23-4c30-84c1-20b4541bc317",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "f682d9da-cb2e-4f60-b710-6accad2d386b",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "323505fb-0be2-4c98-96be-9a14a40d1c78"
    ]
  },
  "201591": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 31, 1986",
    "EXP": "5",
    "SCHOOL": "Indiana",
    "name": "DJ White",
    "rosters": [
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6",
      "45ed0581-1916-49f0-9a2f-fb15342345c5"
    ]
  },
  "201592": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 13, 1985",
    "EXP": "1",
    "SCHOOL": "New Mexico",
    "name": "J.R. Giddens",
    "rosters": [
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "d52a9555-ff77-4418-a883-c18272a57975"
    ]
  },
  "201593": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "307",
    "BIRTH_DATE": "JAN 03, 1986",
    "EXP": "6",
    "SCHOOL": " ",
    "name": "Nikola Pekovic",
    "rosters": [
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e"
    ]
  },
  "201594": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 18, 1986",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Walter Sharpe",
    "rosters": [
      "2cea620b-9720-4d1e-a9ce-b356a1fb3ea1"
    ]
  },
  "201595": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "268",
    "BIRTH_DATE": "DEC 16, 1983",
    "EXP": "2",
    "SCHOOL": "Memphis",
    "name": "Joey Dorsey",
    "rosters": [
      "c5f83588-1194-41d6-980b-c021b4b2ba98",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670"
    ]
  },
  "201596": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 19, 1986",
    "EXP": "8",
    "SCHOOL": "Kansas",
    "name": "Mario Chalmers",
    "rosters": [
      "24a63bd2-7dc0-4207-af30-c5baf3d57ef0",
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3",
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "201599": {
    "NUM": "6",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "265",
    "BIRTH_DATE": "JUL 21, 1988",
    "EXP": "10",
    "SCHOOL": "Texas A&M",
    "name": "DeAndre Jordan",
    "rosters": [
      "1d15b0c9-d7b1-462a-bfef-69941adc38c5",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "206a57a6-ccc5-413b-a413-5deab8b03a78",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880"
    ]
  },
  "201600": {
    "NUM": "3",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUL 04, 1986",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Omer Asik",
    "rosters": [
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f"
    ]
  },
  "201601": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 09, 1986",
    "EXP": "6",
    "SCHOOL": "California-Los Angeles",
    "name": "Luc Mbah a Moute",
    "rosters": [
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "a93bbfbb-84d5-429d-a5f0-310c2c4034a0",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "23894f15-3411-4056-9e26-0a374667fc93"
    ]
  },
  "201602": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "201",
    "BIRTH_DATE": "FEB 18, 1986",
    "EXP": "1",
    "SCHOOL": "Washington State",
    "name": "Kyle Weaver",
    "rosters": [
      "23acb720-d0cf-4182-9809-8a82ffe3779c",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac"
    ]
  },
  "201603": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "203",
    "BIRTH_DATE": "JUL 08, 1986",
    "EXP": "2",
    "SCHOOL": "Arkansas",
    "name": "Sonny Weems",
    "rosters": [
      "04da51ef-15ac-4e60-a2b6-a4c8c08f61ae",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670"
    ]
  },
  "201604": {
    "NUM": "55",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 08, 1987",
    "EXP": "4",
    "SCHOOL": "Memphis",
    "name": "Chris Douglas-Roberts",
    "rosters": [
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "6729ad33-cbf8-4795-b814-64b937327c6c",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "45ed0581-1916-49f0-9a2f-fb15342345c5"
    ]
  },
  "201605": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "280",
    "BIRTH_DATE": "OCT 10, 1986",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Nathan Jawai",
    "rosters": [
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "91c165fd-b08a-4c73-84bf-a029d5600436"
    ]
  },
  "201606": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 06, 1985",
    "EXP": "R",
    "SCHOOL": "Virginia",
    "name": "Sean Singletary",
    "rosters": [
      "fc384a91-81b8-47cb-b999-2a497289f651"
    ]
  },
  "201607": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAY 20, 1984",
    "EXP": "R",
    "SCHOOL": "Georgetown",
    "name": "Patrick Ewing",
    "rosters": [
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6"
    ]
  },
  "201609": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 06, 1986",
    "EXP": "5",
    "SCHOOL": " ",
    "name": "Goran Dragic",
    "rosters": [
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "d1566529-2393-422f-9177-00c89290a8dc",
      "1517c08b-f620-4a30-ac0f-2da4d9ecf77b",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "cd1c6261-0878-4024-8b67-e754584c830b"
    ]
  },
  "201611": {
    "NUM": "5",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 09, 1987",
    "EXP": "2",
    "SCHOOL": "Kansas State",
    "name": "Henry Walker",
    "rosters": [
      "459b35a0-59ee-410e-a56c-bfe0340b5482",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "d52a9555-ff77-4418-a883-c18272a57975",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0"
    ]
  },
  "201612": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 23, 1987",
    "EXP": "1",
    "SCHOOL": "Oregon",
    "name": "Malik Hairston",
    "rosters": [
      "4822c589-71e6-4c95-ad9f-fe4f724202aa"
    ]
  },
  "201616": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "253",
    "BIRTH_DATE": "NOV 07, 1985",
    "EXP": "2",
    "SCHOOL": "Kansas",
    "name": "Darnell Jackson",
    "rosters": [
      "0d546caf-814a-4eb2-8303-fd97b90bc898",
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "f682d9da-cb2e-4f60-b710-6accad2d386b"
    ]
  },
  "201619": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "MAY 08, 1985",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Sasha Kaun",
    "rosters": [
      "6ac28050-d5f5-4251-8faa-6c3ccee02978"
    ]
  },
  "201621": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 17, 1986",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Joe Crawford",
    "rosters": [
      "1c88500c-202c-426a-b9f7-cdaa825a0df9"
    ]
  },
  "201623": {
    "NUM": "9",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 28, 1986",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Semih Erden",
    "rosters": [
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "394863c9-b8a6-474b-b7ac-a73cf972c685"
    ]
  },
  "201627": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 27, 1985",
    "EXP": "7",
    "SCHOOL": "Georgia Tech",
    "name": "Anthony Morrow",
    "rosters": [
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04",
      "a51334eb-1216-409a-a077-121ff993d69c",
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59"
    ]
  },
  "201628": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 24, 1984",
    "EXP": "R",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Bobby Brown",
    "rosters": [
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "6b0a0176-c625-4185-97c7-e44301ac931f"
    ]
  },
  "201629": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 28, 1986",
    "EXP": "R",
    "SCHOOL": "Ohio State",
    "name": "Othello Hunter",
    "rosters": [
      "90104477-18df-4be0-bd36-d89f5a93be6e"
    ]
  },
  "201632": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "265",
    "BIRTH_DATE": "MAY 19, 1985",
    "EXP": "3",
    "SCHOOL": "Ahvaz, Iran",
    "name": "Hamed Haddadi",
    "rosters": [
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "824e5cff-47e8-4169-8451-74a294a985e8",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c",
      "bce414a0-1435-4744-9faf-b7027085c341",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289"
    ]
  },
  "201633": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "232",
    "BIRTH_DATE": "MAR 05, 1985",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Rob Kurz",
    "rosters": [
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "6d70f6ee-362f-4ae7-b814-24b533c75c04"
    ]
  },
  "201634": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 02, 1985",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "DeMarcus Nelson",
    "rosters": [
      "faedfee7-5dfc-4e62-a80c-0a69bbd4a90a"
    ]
  },
  "201785": {
    "NUM": "12",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 05, 1985",
    "EXP": "1",
    "SCHOOL": "Robert Morris (IL)",
    "name": "Othyus Jeffers",
    "rosters": [
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741"
    ]
  },
  "201802": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 06, 1984",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "Oliver Lafayette",
    "rosters": [
      "18a81748-917e-4293-8ca8-c13073995d9b"
    ]
  },
  "201805": {
    "NUM": "31",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 09, 1984",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Chris Hunter",
    "rosters": [
      "a51334eb-1216-409a-a077-121ff993d69c",
      "1c88500c-202c-426a-b9f7-cdaa825a0df9"
    ]
  },
  "201814": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 25, 1985",
    "EXP": "1",
    "SCHOOL": "Massachusetts",
    "name": "Gary Forbes",
    "rosters": [
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "819d71de-4194-483f-b197-3c61d2f97675"
    ]
  },
  "201858": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 20, 1984",
    "EXP": "R",
    "SCHOOL": "Kansas State",
    "name": "Cartier Martin",
    "rosters": [
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7",
      "fc384a91-81b8-47cb-b999-2a497289f651"
    ]
  },
  "201880": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "SEP 26, 1985",
    "EXP": "3",
    "SCHOOL": "Wisconsin",
    "name": "Greg Stiemsma",
    "rosters": [
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a"
    ]
  },
  "201933": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAR 16, 1989",
    "EXP": "9",
    "SCHOOL": "Oklahoma",
    "name": "Blake Griffin",
    "rosters": [
      "6e9d5ea8-a598-40b4-956a-1a9851fdbcf0",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "45324799-5d41-45eb-b5ff-b3095f173c43",
      "17ec0980-7e1f-455e-a186-b7156367018b",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "201934": {
    "NUM": "34",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "267",
    "BIRTH_DATE": "FEB 16, 1987",
    "EXP": "R",
    "SCHOOL": "Connecticut",
    "name": "Hasheem Thabeet",
    "rosters": [
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c"
    ]
  },
  "201935": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 26, 1989",
    "EXP": "2",
    "SCHOOL": "Arizona State",
    "name": "James Harden",
    "rosters": [
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d"
    ]
  },
  "201936": {
    "NUM": "12",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 19, 1989",
    "EXP": "8",
    "SCHOOL": "Memphis",
    "name": "Tyreke Evans",
    "rosters": [
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "f682d9da-cb2e-4f60-b710-6accad2d386b",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "201937": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 21, 1990",
    "EXP": "7",
    "SCHOOL": " ",
    "name": "Ricky Rubio",
    "rosters": [
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "fdf322f1-9f93-4535-913c-53228db4b4fb",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "201938": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 06, 1989",
    "EXP": "2",
    "SCHOOL": "Syracuse",
    "name": "Jonny Flynn",
    "rosters": [
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676"
    ]
  },
  "201939": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 14, 1988",
    "EXP": "10",
    "SCHOOL": "Davidson",
    "name": "Stephen Curry",
    "rosters": [
      "a51334eb-1216-409a-a077-121ff993d69c",
      "728d43da-a0f1-4599-96c2-a09036340618",
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "201941": {
    "NUM": "27",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 27, 1987",
    "EXP": "6",
    "SCHOOL": "Arizona",
    "name": "Jordan Hill",
    "rosters": [
      "8632a477-9924-4d11-859a-78889ddfee93",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "4a8979df-b35b-47d6-acbf-06105e98a732"
    ]
  },
  "201942": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 07, 1989",
    "EXP": "8",
    "SCHOOL": "USC",
    "name": "DeMar DeRozan",
    "rosters": [
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e",
      "18266baf-bfab-4bf0-95b6-3b7f9f282af4",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "896773e3-d58b-484d-b4ce-cba82ed75933",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6"
    ]
  },
  "201943": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "169",
    "BIRTH_DATE": "SEP 23, 1989",
    "EXP": "5",
    "SCHOOL": " ",
    "name": "Brandon Jennings",
    "rosters": [
      "99c7795f-1136-4419-84cb-2b176eba2129",
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "e897fcee-4043-4882-b3ac-076b89980fff",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7"
    ]
  },
  "201944": {
    "NUM": "55",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 28, 1987",
    "EXP": "2",
    "SCHOOL": "Louisville",
    "name": "Terrence Williams",
    "rosters": [
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "ea819ad7-3ab1-4ad8-a15e-f443f42933d4",
      "8ef85512-070b-4f28-9454-9d4cc78d5562"
    ]
  },
  "201945": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "DEC 09, 1987",
    "EXP": "5",
    "SCHOOL": "Duke",
    "name": "Gerald Henderson",
    "rosters": [
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095",
      "45ed0581-1916-49f0-9a2f-fb15342345c5",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864"
    ]
  },
  "201946": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 03, 1985",
    "EXP": "6",
    "SCHOOL": "North Carolina",
    "name": "Tyler Hansbrough",
    "rosters": [
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "31164298-2c0d-4a44-b557-e740801b4b1d"
    ]
  },
  "201947": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 17, 1988",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Earl Clark",
    "rosters": [
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc"
    ]
  },
  "201948": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 05, 1988",
    "EXP": "2",
    "SCHOOL": "Gonzaga",
    "name": "Austin Daye",
    "rosters": [
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "323505fb-0be2-4c98-96be-9a14a40d1c78",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1"
    ]
  },
  "201949": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "248",
    "BIRTH_DATE": "FEB 20, 1987",
    "EXP": "4",
    "SCHOOL": "Wake Forest",
    "name": "James Johnson",
    "rosters": [
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3"
    ]
  },
  "201950": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 12, 1990",
    "EXP": "3",
    "SCHOOL": "UCLA",
    "name": "Jrue Holiday",
    "rosters": [
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "1b10411f-c8db-4154-9db0-1900f8577a91",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68",
      "2216683d-34cb-4284-b507-8c9bf140d784"
    ]
  },
  "201951": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 03, 1987",
    "EXP": "8",
    "SCHOOL": "North Carolina",
    "name": "Ty Lawson",
    "rosters": [
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad"
    ]
  },
  "201952": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "186",
    "BIRTH_DATE": "JUN 10, 1988",
    "EXP": "7",
    "SCHOOL": "Wake Forest",
    "name": "Jeff Teague",
    "rosters": [
      "45da336c-49c6-4cdc-bf17-b1407bce4edc",
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3",
      "55e966cc-28f6-412c-ba16-a6f3cb71b074",
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "e8169801-0d82-448f-b1b7-6fb64e81aac6",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea"
    ]
  },
  "201953": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 11, 1987",
    "EXP": "2",
    "SCHOOL": "Va Commonwealth",
    "name": "Eric Maynor",
    "rosters": [
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d"
    ]
  },
  "201954": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 23, 1987",
    "EXP": "7",
    "SCHOOL": "UCLA",
    "name": "Darren Collison",
    "rosters": [
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb"
    ]
  },
  "201956": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 22, 1988",
    "EXP": "6",
    "SCHOOL": null,
    "name": "Omri Casspi",
    "rosters": [
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14",
      "f682d9da-cb2e-4f60-b710-6accad2d386b",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "34a215f4-c672-4508-a765-ed7809c0e939"
    ]
  },
  "201957": {
    "NUM": "22",
    "POSITION": "F-C",
    "HEIGHT": "7-0",
    "WEIGHT": "275",
    "BIRTH_DATE": "FEB 14, 1989",
    "EXP": "3",
    "SCHOOL": "Ohio State",
    "name": "Byron Mullens",
    "rosters": [
      "341a849d-e970-4a90-8bee-8a209ea47cb1",
      "9d178503-abbf-4f3b-865a-c13b7c1da8ac",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095"
    ]
  },
  "201958": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 24, 1988",
    "EXP": "3",
    "SCHOOL": null,
    "name": "Rodrigue Beaubois",
    "rosters": [
      "b700ceb6-53ed-4084-89f3-5a24e516486e",
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a",
      "25084fac-f6f9-4c54-b21c-e94d6295d687"
    ]
  },
  "201959": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "236",
    "BIRTH_DATE": "JUN 24, 1985",
    "EXP": "7",
    "SCHOOL": "USC",
    "name": "Taj Gibson",
    "rosters": [
      "9108c5c3-88a9-4172-8bf2-7dd36a671a0a",
      "ee55f8e5-a811-4e18-b0a5-a1ee7e62b801",
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c"
    ]
  },
  "201960": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "212",
    "BIRTH_DATE": "JUL 27, 1986",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "DeMarre Carroll",
    "rosters": [
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c"
    ]
  },
  "201961": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 29, 1987",
    "EXP": "9",
    "SCHOOL": "North Carolina",
    "name": "Wayne Ellington",
    "rosters": [
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "e3fc8c17-a818-4d6b-b322-de577d3bfee1",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89",
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "201962": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 16, 1986",
    "EXP": "3",
    "SCHOOL": "Florida State",
    "name": "Toney Douglas",
    "rosters": [
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "d52a9555-ff77-4418-a883-c18272a57975",
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d"
    ]
  },
  "201963": {
    "NUM": "88",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 22, 1989",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Christian Eyenga",
    "rosters": [
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9"
    ]
  },
  "201964": {
    "NUM": "18",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "224",
    "BIRTH_DATE": "AUG 30, 1988",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Victor Claver",
    "rosters": [
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed"
    ]
  },
  "201965": {
    "NUM": "11",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "APR 29, 1987",
    "EXP": "4",
    "SCHOOL": "Arizona State",
    "name": "Jeff Ayres",
    "rosters": [
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a"
    ]
  },
  "201966": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 08, 1986",
    "EXP": "R",
    "SCHOOL": "Central Florida",
    "name": "Jermaine Taylor",
    "rosters": [
      "8632a477-9924-4d11-859a-78889ddfee93"
    ]
  },
  "201967": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 22, 1987",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Dante Cunningham",
    "rosters": [
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "4027029b-e749-405b-abd7-419c00e4f631",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9"
    ]
  },
  "201969": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 24, 1988",
    "EXP": "1",
    "SCHOOL": "Georgetown",
    "name": "DaJuan Summers",
    "rosters": [
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44"
    ]
  },
  "201970": {
    "NUM": "4",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 01, 1985",
    "EXP": "1",
    "SCHOOL": "Pittsburgh",
    "name": "Sam Young",
    "rosters": [
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c",
      "bce414a0-1435-4744-9faf-b7027085c341"
    ]
  },
  "201971": {
    "NUM": "45",
    "POSITION": "C-F",
    "HEIGHT": "6-7",
    "WEIGHT": "270",
    "BIRTH_DATE": "APR 22, 1989",
    "EXP": "5",
    "SCHOOL": "Pittsburgh",
    "name": "DeJuan Blair",
    "rosters": [
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "8d3a3080-9e74-4512-8869-83aad854d0a0"
    ]
  },
  "201972": {
    "NUM": "40",
    "POSITION": "C-F",
    "HEIGHT": "6-7",
    "WEIGHT": "255",
    "BIRTH_DATE": "MAR 20, 1987",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Jon Brockman",
    "rosters": [
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "09275de7-3dd0-4c9c-8b46-54f21b4b3a14"
    ]
  },
  "201973": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "231",
    "BIRTH_DATE": "MAR 02, 1987",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Jonas Jerebko",
    "rosters": [
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "b791032a-997d-47da-b33e-2d34d9e2cd22",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "201974": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "233",
    "BIRTH_DATE": "SEP 08, 1987",
    "EXP": "2",
    "SCHOOL": "Xavier",
    "name": "Derrick Brown",
    "rosters": [
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "f93da3e3-c6f0-4b6a-a43c-73573c1aa575",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6"
    ]
  },
  "201975": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 21, 1987",
    "EXP": "6",
    "SCHOOL": "Kentucky",
    "name": "Jodie Meeks",
    "rosters": [
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "5d899f8e-5e64-42dc-8573-762857c42fff",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7",
      "fbff0712-5fed-4e22-8afa-30387f9f0753"
    ]
  },
  "201976": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 12, 1988",
    "EXP": "7",
    "SCHOOL": "Arkansas",
    "name": "Patrick Beverley",
    "rosters": [
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697"
    ]
  },
  "201977": {
    "NUM": "15",
    "POSITION": "F-G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 05, 1987",
    "EXP": "6",
    "SCHOOL": "Louisiana State",
    "name": "Marcus Thornton",
    "rosters": [
      "3496e345-3758-4ff7-ac86-111d4710f34d",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "f682d9da-cb2e-4f60-b710-6accad2d386b",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "d5add497-b2c3-4523-b2ef-584c47479c7d"
    ]
  },
  "201978": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "209",
    "BIRTH_DATE": "MAY 22, 1988",
    "EXP": "6",
    "SCHOOL": "Arizona",
    "name": "Chase Budinger",
    "rosters": [
      "8632a477-9924-4d11-859a-78889ddfee93",
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1"
    ]
  },
  "201979": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "213",
    "BIRTH_DATE": "FEB 07, 1989",
    "EXP": "1",
    "SCHOOL": "Florida",
    "name": "Nick Calathes",
    "rosters": [
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00"
    ]
  },
  "201980": {
    "NUM": "14",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 22, 1987",
    "EXP": "9",
    "SCHOOL": "North Carolina",
    "name": "Danny Green",
    "rosters": [
      "d880c1ca-1546-4361-b8de-d39e6da49f39",
      "db38d209-7326-41b8-948f-588f9a050e00",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "7855a129-e474-423e-a119-3ea4ddf812d3"
    ]
  },
  "201981": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "238",
    "BIRTH_DATE": "APR 18, 1986",
    "EXP": "R",
    "SCHOOL": "Oklahoma",
    "name": "Taylor Griffin",
    "rosters": [
      "3929fd1d-99b1-42e3-b041-3e593ae01cfc"
    ]
  },
  "201985": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 07, 1986",
    "EXP": "3",
    "SCHOOL": "Connecticut",
    "name": "AJ Price",
    "rosters": [
      "2b6a416e-d405-4fbc-b5be-5f2c557d5e21",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8"
    ]
  },
  "201986": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUN 23, 1987",
    "EXP": "1",
    "SCHOOL": "France",
    "name": "Nando De Colo",
    "rosters": [
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831"
    ]
  },
  "201987": {
    "NUM": "30",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 03, 1985",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Robert Vaden",
    "rosters": [
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf"
    ]
  },
  "201988": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "AUG 11, 1988",
    "EXP": "10",
    "SCHOOL": "St. Mary's (CA)",
    "name": "Patty Mills",
    "rosters": [
      "4027029b-e749-405b-abd7-419c00e4f631",
      "8e1bb40f-2c63-4904-9e81-67592b326bda",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "201991": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 07, 1984",
    "EXP": "1",
    "SCHOOL": "Tennessee-Martin",
    "name": "Lester Hudson",
    "rosters": [
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "2fd59817-7492-4a2f-a8f2-25aac87eea3c",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289"
    ]
  },
  "201998": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 05, 1987",
    "EXP": "R",
    "SCHOOL": "Baylor",
    "name": "Curtis Jerrells",
    "rosters": [
      "4822c589-71e6-4c95-ad9f-fe4f724202aa"
    ]
  },
  "202066": {
    "NUM": "41",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 08, 1986",
    "EXP": "1",
    "SCHOOL": "Louisiana State",
    "name": "Garrett Temple",
    "rosters": [
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "206a57a6-ccc5-413b-a413-5deab8b03a78",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "8d3a3080-9e74-4512-8869-83aad854d0a0",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "8b6f4221-fba7-4f57-bf1d-b18a0613b7f9"
    ]
  },
  "202067": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 08, 1987",
    "EXP": "R",
    "SCHOOL": "St. Mary's (CA)",
    "name": "Diamon Simpson",
    "rosters": [
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a"
    ]
  },
  "202070": {
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 14, 1984",
    "EXP": "R",
    "SCHOOL": "Massachusetts",
    "name": "Tony Gaffney",
    "rosters": [
      "18a81748-917e-4293-8ca8-c13073995d9b"
    ]
  },
  "202077": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 01, 1987",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "Jerel McNeal",
    "rosters": [
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "83706098-479a-4732-9529-09c78cfdb502"
    ]
  },
  "202081": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "305",
    "BIRTH_DATE": "OCT 25, 1986",
    "EXP": "R",
    "SCHOOL": "Augusta State",
    "name": "Garret Siler",
    "rosters": [
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916"
    ]
  },
  "202082": {
    "NUM": "32",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 08, 1983",
    "EXP": "R",
    "SCHOOL": "Oral Roberts",
    "name": "Larry Owens",
    "rosters": [
      "4aecdd98-f356-4a06-93a4-7dee3adb6741"
    ]
  },
  "202083": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 14, 1986",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "Wesley Matthews",
    "rosters": [
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "8e1bb40f-2c63-4904-9e81-67592b326bda",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4"
    ]
  },
  "202087": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 29, 1987",
    "EXP": "R",
    "SCHOOL": "Alabama",
    "name": "Alonzo Gee",
    "rosters": [
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "51581511-27eb-417b-be7a-c3792b97c046",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "4822c589-71e6-4c95-ad9f-fe4f724202aa"
    ]
  },
  "202091": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 15, 1986",
    "EXP": "R",
    "SCHOOL": "Temple",
    "name": "Dionte Christmas",
    "rosters": [
      "cd1c6261-0878-4024-8b67-e754584c830b"
    ]
  },
  "202130": {
    "NUM": "55",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 14, 1986",
    "EXP": "3",
    "SCHOOL": "Virginia Military Institute",
    "name": "Reggie Williams",
    "rosters": [
      "a51334eb-1216-409a-a077-121ff993d69c",
      "728d43da-a0f1-4599-96c2-a09036340618",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095"
    ]
  },
  "202132": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 05, 1986",
    "EXP": "R",
    "SCHOOL": "Cleveland State",
    "name": "Cedric Jackson",
    "rosters": [
      "811b428c-0d87-46f4-9c9a-208cb1e9e38b"
    ]
  },
  "202148": {
    "NUM": "32",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 26, 1986",
    "EXP": "R",
    "SCHOOL": "Alabama A&M",
    "name": "Mickell Gladness",
    "rosters": [
      "044b28cb-9356-44d5-8389-fa87b0ad75da"
    ]
  },
  "202178": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "195",
    "BIRTH_DATE": "APR 18, 1986",
    "EXP": "R",
    "SCHOOL": "Georgia",
    "name": "Sundiata Gaines",
    "rosters": [
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "0e711c53-a43f-41a3-aba9-ff2789f269d4"
    ]
  },
  "202220": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "191",
    "BIRTH_DATE": "SEP 10, 1984",
    "EXP": "R",
    "SCHOOL": "Virginia Tech",
    "name": "Zabian Dowdell",
    "rosters": [
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916"
    ]
  },
  "202221": {
    "NUM": "43",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "DEC 22, 1984",
    "EXP": "R",
    "SCHOOL": "Wisconsin",
    "name": "Brian Butch",
    "rosters": [
      "a8d64aaf-36e8-451d-9ece-f80d97529ff5"
    ]
  },
  "202227": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 10, 1987",
    "EXP": "R",
    "SCHOOL": "Oklahoma State",
    "name": "Terrel Harris",
    "rosters": [
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863"
    ]
  },
  "202238": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 14, 1986",
    "EXP": "R",
    "SCHOOL": "Siena",
    "name": "Kenny Hasbrouck",
    "rosters": [
      "aaba7a68-44ab-4582-a519-af97ad7ac8f3"
    ]
  },
  "202322": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 06, 1990",
    "EXP": "9",
    "SCHOOL": "Kentucky",
    "name": "John Wall",
    "rosters": [
      "4aecdd98-f356-4a06-93a4-7dee3adb6741",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "8d3a3080-9e74-4512-8869-83aad854d0a0",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad",
      "6631c074-5c99-48db-b77d-c92e855be5bd",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "202323": {
    "NUM": "1",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 27, 1988",
    "EXP": "8",
    "SCHOOL": "Ohio State",
    "name": "Evan Turner",
    "rosters": [
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add"
    ]
  },
  "202324": {
    "NUM": "15",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "265",
    "BIRTH_DATE": "JUL 15, 1991",
    "EXP": "8",
    "SCHOOL": "Georgia Tech",
    "name": "Derrick Favors",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "202325": {
    "NUM": "4",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 11, 1987",
    "EXP": "8",
    "SCHOOL": "Syracuse",
    "name": "Wesley Johnson",
    "rosters": [
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "202326": {
    "NUM": "15",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "270",
    "BIRTH_DATE": "AUG 13, 1990",
    "EXP": "5",
    "SCHOOL": "Kentucky",
    "name": "DeMarcus Cousins",
    "rosters": [
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "f682d9da-cb2e-4f60-b710-6accad2d386b",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "34a215f4-c672-4508-a765-ed7809c0e939"
    ]
  },
  "202327": {
    "NUM": "33",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 20, 1987",
    "EXP": "6",
    "SCHOOL": "Baylor",
    "name": "Ekpe Udoh",
    "rosters": [
      "728d43da-a0f1-4599-96c2-a09036340618",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "0642a459-b265-4cac-adbd-de39b087debf",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "202328": {
    "NUM": "10",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 04, 1990",
    "EXP": "4",
    "SCHOOL": "Georgetown",
    "name": "Greg Monroe",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "443b1d56-f27e-4c16-a9ca-855a72cacb44",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "e897fcee-4043-4882-b3ac-076b89980fff",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7"
    ]
  },
  "202329": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 21, 1990",
    "EXP": "8",
    "SCHOOL": "Wake Forest",
    "name": "Al-Farouq Aminu",
    "rosters": [
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add"
    ]
  },
  "202330": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "226",
    "BIRTH_DATE": "MAR 23, 1990",
    "EXP": "6",
    "SCHOOL": "Butler",
    "name": "Gordon Hayward",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "f58fe66a-52f3-49b3-8f25-65157ccddeed",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b"
    ]
  },
  "202331": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 02, 1990",
    "EXP": "8",
    "SCHOOL": "Fresno State",
    "name": "Paul George",
    "rosters": [
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "77e6e4de-42ee-4c42-be39-b046215914f2",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199"
    ]
  },
  "202332": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 31, 1988",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Cole Aldrich",
    "rosters": [
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "48ed01ad-a36d-4f59-80d2-ea0a53fd48cf",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d"
    ]
  },
  "202333": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 15, 1991",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Xavier Henry",
    "rosters": [
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "bce414a0-1435-4744-9faf-b7027085c341"
    ]
  },
  "202334": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 05, 1989",
    "EXP": "3",
    "SCHOOL": "North Carolina",
    "name": "Ed Davis",
    "rosters": [
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "819d71de-4194-483f-b197-3c61d2f97675",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4",
      "323505fb-0be2-4c98-96be-9a14a40d1c78",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3"
    ]
  },
  "202335": {
    "NUM": "54",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 14, 1989",
    "EXP": "6",
    "SCHOOL": "Kentucky",
    "name": "Patrick Patterson",
    "rosters": [
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "77e6e4de-42ee-4c42-be39-b046215914f2",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e"
    ]
  },
  "202336": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 21, 1988",
    "EXP": "3",
    "SCHOOL": "Va Commonwealth",
    "name": "Larry Sanders",
    "rosters": [
      "3b676f62-e076-4dce-94be-5d06e04fc018",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "0642a459-b265-4cac-adbd-de39b087debf"
    ]
  },
  "202337": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUN 20, 1989",
    "EXP": "2",
    "SCHOOL": "Nevada-Reno",
    "name": "Luke Babbitt",
    "rosters": [
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "8e1bb40f-2c63-4904-9e81-67592b326bda",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d"
    ]
  },
  "202338": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "278",
    "BIRTH_DATE": "DEC 07, 1989",
    "EXP": "4",
    "SCHOOL": null,
    "name": "Kevin Seraphin",
    "rosters": [
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "8d3a3080-9e74-4512-8869-83aad854d0a0"
    ]
  },
  "202339": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 09, 1989",
    "EXP": "6",
    "SCHOOL": "Kentucky",
    "name": "Eric Bledsoe",
    "rosters": [
      "2cefe883-cd66-4007-b4b2-371761a1f717",
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105",
      "6d8101b2-0835-4661-8dd5-46d4653e0851",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "58ed739f-5da8-4431-9d04-f20d9505d73b"
    ]
  },
  "202340": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 26, 1990",
    "EXP": "8",
    "SCHOOL": "Texas",
    "name": "Avery Bradley",
    "rosters": [
      "88463408-f197-42d6-b955-433fadaa716d",
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "db38d209-7326-41b8-948f-588f9a050e00",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "202341": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 25, 1989",
    "EXP": "1",
    "SCHOOL": "Oklahoma State",
    "name": "James Anderson",
    "rosters": [
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "341a849d-e970-4a90-8bee-8a209ea47cb1",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "eaa71d89-5839-4a85-945e-b783a1864495"
    ]
  },
  "202342": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 09, 1987",
    "EXP": "1",
    "SCHOOL": "Iowa State",
    "name": "Craig Brackins",
    "rosters": [
      "cd76ad8c-2906-4fbf-b478-25b1e443c7c2",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68"
    ]
  },
  "202343": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 20, 1989",
    "EXP": "2",
    "SCHOOL": "Memphis",
    "name": "Elliot Williams",
    "rosters": [
      "341a849d-e970-4a90-8bee-8a209ea47cb1",
      "8e1bb40f-2c63-4904-9e81-67592b326bda",
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d"
    ]
  },
  "202344": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "NOV 25, 1987",
    "EXP": "3",
    "SCHOOL": "Clemson",
    "name": "Trevor Booker",
    "rosters": [
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a"
    ]
  },
  "202345": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 07, 1987",
    "EXP": "3",
    "SCHOOL": "Texas",
    "name": "Damion James",
    "rosters": [
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab"
    ]
  },
  "202346": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 15, 1988",
    "EXP": "1",
    "SCHOOL": "South Florida",
    "name": "Dominique Jones",
    "rosters": [
      "d12bf9aa-8464-4fb7-9918-3c36a769c1b0",
      "3bb057eb-42b5-49f7-ba1d-267e8181cf1a"
    ]
  },
  "202347": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 10, 1988",
    "EXP": "3",
    "SCHOOL": "Washington",
    "name": "Quincy Pondexter",
    "rosters": [
      "02f605c4-2f53-49b5-9add-ed6fc2e857b6",
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "fdbb84b4-a3cc-4660-a3ba-719af8281289",
      "323505fb-0be2-4c98-96be-9a14a40d1c78",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3"
    ]
  },
  "202348": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "OCT 23, 1988",
    "EXP": "1",
    "SCHOOL": "Xavier",
    "name": "Jordan Crawford",
    "rosters": [
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "4aecdd98-f356-4a06-93a4-7dee3adb6741",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c"
    ]
  },
  "202349": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "211",
    "BIRTH_DATE": "JAN 16, 1987",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Greivis Vasquez",
    "rosters": [
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "bce414a0-1435-4744-9faf-b7027085c341"
    ]
  },
  "202350": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "AUG 06, 1990",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Daniel Orton",
    "rosters": [
      "a13aadde-4723-4b90-b05c-185b76537abe",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc"
    ]
  },
  "202351": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 26, 1986",
    "EXP": "1",
    "SCHOOL": "Marquette",
    "name": "Lazar Hayward",
    "rosters": [
      "ea89e2df-b552-4760-8e5d-5646fac7e0e5",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d"
    ]
  },
  "202353": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "256",
    "BIRTH_DATE": "NOV 02, 1989",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Tibor Pleiss",
    "rosters": [
      "8443b84f-b107-4647-9a68-51bc436e7c61"
    ]
  },
  "202354": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "285",
    "BIRTH_DATE": "MAR 02, 1988",
    "EXP": "1",
    "SCHOOL": "Texas",
    "name": "Dexter Pittman",
    "rosters": [
      "ac2ef7bc-6de6-4f35-b480-6a5f5bd3d20b",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863"
    ]
  },
  "202355": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 13, 1989",
    "EXP": "1",
    "SCHOOL": "Marshall",
    "name": "Hassan Whiteside",
    "rosters": [
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33",
      "f682d9da-cb2e-4f60-b710-6accad2d386b",
      "8ef85512-070b-4f28-9454-9d4cc78d5562"
    ]
  },
  "202356": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 23, 1989",
    "EXP": "R",
    "SCHOOL": "Nevada-Reno",
    "name": "Armon Johnson",
    "rosters": [
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "8e1bb40f-2c63-4904-9e81-67592b326bda"
    ]
  },
  "202357": {
    "NUM": "88",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "234",
    "BIRTH_DATE": "MAY 09, 1988",
    "EXP": "4",
    "SCHOOL": " ",
    "name": "Nemanja Bjelica",
    "rosters": [
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "202358": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "213",
    "BIRTH_DATE": "MAR 07, 1990",
    "EXP": "R",
    "SCHOOL": "Mississippi",
    "name": "Terrico White",
    "rosters": [
      "443b1d56-f27e-4c16-a9ca-855a72cacb44"
    ]
  },
  "202360": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 02, 1986",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Andy Rautins",
    "rosters": [
      "a52ff111-cd72-4740-8ea6-3f19233c78c0"
    ]
  },
  "202361": {
    "NUM": "2",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUN 27, 1988",
    "EXP": "4",
    "SCHOOL": "Stanford",
    "name": "Landry Fields",
    "rosters": [
      "a52ff111-cd72-4740-8ea6-3f19233c78c0",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "896773e3-d58b-484d-b4ce-cba82ed75933",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a"
    ]
  },
  "202362": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 05, 1990",
    "EXP": "4",
    "SCHOOL": "Cincinnati",
    "name": "Lance Stephenson",
    "rosters": [
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "6b39df4c-f728-4eb1-a069-b6973299bd78",
      "18ccc342-b735-4763-bc05-89b9f428f609",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864"
    ]
  },
  "202363": {
    "NUM": "40",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 01, 1988",
    "EXP": "1",
    "SCHOOL": "Mississippi State",
    "name": "Jarvis Varnado",
    "rosters": [
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "341a849d-e970-4a90-8bee-8a209ea47cb1"
    ]
  },
  "202364": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 25, 1988",
    "EXP": "R",
    "SCHOOL": "West Virginia",
    "name": "Da'Sean Butler",
    "rosters": [
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52"
    ]
  },
  "202365": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 28, 1989",
    "EXP": "2",
    "SCHOOL": "West Virginia",
    "name": "Devin Ebanks",
    "rosters": [
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b"
    ]
  },
  "202366": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 29, 1986",
    "EXP": "R",
    "SCHOOL": "Tulsa",
    "name": "Jerome Jordan",
    "rosters": [
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70"
    ]
  },
  "202371": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "234",
    "BIRTH_DATE": "NOV 07, 1988",
    "EXP": "R",
    "SCHOOL": "Georgia Tech",
    "name": "Gani Lawal",
    "rosters": [
      "4c5661ac-769c-43ec-b80f-43ea1f9cd916"
    ]
  },
  "202374": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "252",
    "BIRTH_DATE": "MAR 21, 1988",
    "EXP": "1",
    "SCHOOL": "Florida State",
    "name": "Solomon Alabi",
    "rosters": [
      "5f979ffb-67ac-4f2e-8f07-8a63b2f10670",
      "819d71de-4194-483f-b197-3c61d2f97675"
    ]
  },
  "202375": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "FEB 23, 1986",
    "EXP": "R",
    "SCHOOL": "Louisiana Tech",
    "name": "Magnum Rolle",
    "rosters": [
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3"
    ]
  },
  "202376": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 02, 1988",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Luke Harangody",
    "rosters": [
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "394863c9-b8a6-474b-b7ac-a73cf972c685"
    ]
  },
  "202377": {
    "NUM": "19",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 05, 1988",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Pape Sy",
    "rosters": [
      "6cf40ab4-4d59-4726-b858-a2680c16e9c3"
    ]
  },
  "202378": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "203",
    "BIRTH_DATE": "OCT 22, 1989",
    "EXP": "R",
    "SCHOOL": "Oklahoma",
    "name": "Willie Warren",
    "rosters": [
      "2cefe883-cd66-4007-b4b2-371761a1f717"
    ]
  },
  "202379": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "200",
    "BIRTH_DATE": "OCT 24, 1987",
    "EXP": "4",
    "SCHOOL": "Western Kentucky",
    "name": "Jeremy Evans",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "92dd460c-1f73-4e8e-b205-0338061e8ac5",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509"
    ]
  },
  "202380": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 12, 1987",
    "EXP": "R",
    "SCHOOL": "Rutgers",
    "name": "Hamady Ndiaye",
    "rosters": [
      "4aecdd98-f356-4a06-93a4-7dee3adb6741"
    ]
  },
  "202382": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "275",
    "BIRTH_DATE": "MAY 04, 1988",
    "EXP": "R",
    "SCHOOL": "Texas-El Paso",
    "name": "Derrick Caracter",
    "rosters": [
      "d91b6884-86c1-4e6b-8c1b-82a8574ff68b"
    ]
  },
  "202386": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 18, 1988",
    "EXP": "1",
    "SCHOOL": "Tulsa",
    "name": "Ben Uzoh",
    "rosters": [
      "ea311dd0-c904-40b7-9c82-69884c7fe4eb",
      "819d71de-4194-483f-b197-3c61d2f97675"
    ]
  },
  "202388": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 15, 1988",
    "EXP": "3",
    "SCHOOL": "Texas A&M",
    "name": "Donald Sloan",
    "rosters": [
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3"
    ]
  },
  "202389": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "275",
    "BIRTH_DATE": "JUL 16, 1986",
    "EXP": "8",
    "SCHOOL": " ",
    "name": "Timofey Mozgov",
    "rosters": [
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "aa6d4063-7f8f-4c38-8c42-4eca7aa9421b",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9"
    ]
  },
  "202390": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 03, 1984",
    "EXP": "3",
    "SCHOOL": "Towson",
    "name": "Gary Neal",
    "rosters": [
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "7730ce78-78ed-4bca-a1a8-2c0df5d31c52",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "45ed0581-1916-49f0-9a2f-fb15342345c5"
    ]
  },
  "202391": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 23, 1988",
    "EXP": "5",
    "SCHOOL": "Harvard",
    "name": "Jeremy Lin",
    "rosters": [
      "728d43da-a0f1-4599-96c2-a09036340618",
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "31164298-2c0d-4a44-b557-e740801b4b1d"
    ]
  },
  "202392": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 22, 1988",
    "EXP": "R",
    "SCHOOL": "Vermont",
    "name": "Marqus Blakely",
    "rosters": [
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b"
    ]
  },
  "202396": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "260",
    "BIRTH_DATE": "JAN 09, 1989",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Samardo Samuels",
    "rosters": [
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "394863c9-b8a6-474b-b7ac-a73cf972c685"
    ]
  },
  "202397": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 05, 1988",
    "EXP": "8",
    "SCHOOL": "Wake Forest",
    "name": "Ish Smith",
    "rosters": [
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "bce414a0-1435-4744-9faf-b7027085c341",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e",
      "45324799-5d41-45eb-b5ff-b3095f173c43",
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "202399": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "243",
    "BIRTH_DATE": "FEB 10, 1986",
    "EXP": "2",
    "SCHOOL": "Connecticut",
    "name": "Jeff Adrien",
    "rosters": [
      "728d43da-a0f1-4599-96c2-a09036340618",
      "0642a459-b265-4cac-adbd-de39b087debf",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095"
    ]
  },
  "202406": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAY 01, 1987",
    "EXP": "R",
    "SCHOOL": "Connecticut",
    "name": "Jerome Dyson",
    "rosters": [
      "506d3176-b688-4317-9dc7-cfecc25eb790"
    ]
  },
  "202407": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 12, 1987",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Elijah Millsap",
    "rosters": [
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509"
    ]
  },
  "202408": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "DEC 18, 1986",
    "EXP": "R",
    "SCHOOL": "Houston",
    "name": "Marcus Cousin",
    "rosters": [
      "1bbf1c9a-faa0-4ce7-af8a-4f2d446b574b"
    ]
  },
  "202412": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 21, 1989",
    "EXP": "3",
    "SCHOOL": "Michigan",
    "name": "Manny Harris",
    "rosters": [
      "305ed289-0686-4e89-93f0-6bc32c4b5857",
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668"
    ]
  },
  "202419": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 17, 1985",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Chris Johnson",
    "rosters": [
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "8e1bb40f-2c63-4904-9e81-67592b326bda"
    ]
  },
  "202498": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 24, 1988",
    "EXP": "7",
    "SCHOOL": "Duke",
    "name": "Lance Thomas",
    "rosters": [
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880"
    ]
  },
  "202620": {
    "NUM": "50",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "275",
    "BIRTH_DATE": "JUL 13, 1987",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Arinze Onuaku",
    "rosters": [
      "0bdf6350-c69b-4b66-b2f9-a35d44294191"
    ]
  },
  "202622": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 23, 1988",
    "EXP": "R",
    "SCHOOL": "Arkansas",
    "name": "Courtney Fortson",
    "rosters": [
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a"
    ]
  },
  "202681": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 23, 1992",
    "EXP": "8",
    "SCHOOL": "Duke",
    "name": "Kyrie Irving",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "51581511-27eb-417b-be7a-c3792b97c046",
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "202682": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 25, 1991",
    "EXP": "3",
    "SCHOOL": "Arizona",
    "name": "Derrick Williams",
    "rosters": [
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c"
    ]
  },
  "202683": {
    "NUM": "0",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "247",
    "BIRTH_DATE": "MAY 20, 1992",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Enes Kanter",
    "rosters": [
      "f58fe66a-52f3-49b3-8f25-65157ccddeed",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d"
    ]
  },
  "202684": {
    "NUM": "13",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "254",
    "BIRTH_DATE": "MAR 13, 1991",
    "EXP": "8",
    "SCHOOL": "Texas",
    "name": "Tristan Thompson",
    "rosters": [
      "394863c9-b8a6-474b-b7ac-a73cf972c685",
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "51581511-27eb-417b-be7a-c3792b97c046",
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  },
  "202685": {
    "NUM": "17",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "265",
    "BIRTH_DATE": "MAY 06, 1992",
    "EXP": "7",
    "SCHOOL": " ",
    "name": "Jonas Valanciunas",
    "rosters": [
      "896773e3-d58b-484d-b4ce-cba82ed75933",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "60ef2ec8-e872-4112-b28c-8e665c403fba",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "202686": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 24, 1990",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Jan Vesely",
    "rosters": [
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8"
    ]
  },
  "202687": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-8",
    "WEIGHT": "255",
    "BIRTH_DATE": "AUG 28, 1992",
    "EXP": "8",
    "SCHOOL": " ",
    "name": "Bismack Biyombo",
    "rosters": [
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095",
      "45ed0581-1916-49f0-9a2f-fb15342345c5",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864",
      "7e4b59f0-0509-4845-8730-9663232e7b79",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "202688": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "182",
    "BIRTH_DATE": "DEC 02, 1991",
    "EXP": "7",
    "SCHOOL": "Kentucky",
    "name": "Brandon Knight",
    "rosters": [
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "0642a459-b265-4cac-adbd-de39b087debf",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae",
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "202689": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "184",
    "BIRTH_DATE": "MAY 08, 1990",
    "EXP": "7",
    "SCHOOL": "Connecticut",
    "name": "Kemba Walker",
    "rosters": [
      "f58fe66a-52f3-49b3-8f25-65157ccddeed",
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095",
      "45ed0581-1916-49f0-9a2f-fb15342345c5",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864",
      "31164298-2c0d-4a44-b557-e740801b4b1d",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79"
    ]
  },
  "202690": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 25, 1989",
    "EXP": "1",
    "SCHOOL": "Brigham Young",
    "name": "Jimmer Fredette",
    "rosters": [
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d"
    ]
  },
  "202691": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 08, 1990",
    "EXP": "8",
    "SCHOOL": "Washington State",
    "name": "Klay Thompson",
    "rosters": [
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "202692": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "214",
    "BIRTH_DATE": "JUL 20, 1991",
    "EXP": "6",
    "SCHOOL": "Colorado",
    "name": "Alec Burks",
    "rosters": [
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "d46a5105-de7f-4d91-abee-dd4eb7ff82de",
      "83706098-479a-4732-9529-09c78cfdb502",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10"
    ]
  },
  "202693": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "SEP 02, 1989",
    "EXP": "6",
    "SCHOOL": "Kansas",
    "name": "Markieff Morris",
    "rosters": [
      "db38d209-7326-41b8-948f-588f9a050e00",
      "fa166c7c-372a-489f-b069-635a9196ea73",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad"
    ]
  },
  "202694": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 02, 1989",
    "EXP": "5",
    "SCHOOL": "Kansas",
    "name": "Marcus Morris Sr.",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e"
    ]
  },
  "202695": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUN 29, 1991",
    "EXP": "7",
    "SCHOOL": "San Diego State",
    "name": "Kawhi Leonard",
    "rosters": [
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "7855a129-e474-423e-a119-3ea4ddf812d3"
    ]
  },
  "202696": {
    "NUM": "8",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 24, 1990",
    "EXP": "R",
    "SCHOOL": "USC",
    "name": "Nikola Vucevic",
    "rosters": [
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68"
    ]
  },
  "202697": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 26, 1990",
    "EXP": "6",
    "SCHOOL": "Georgia Tech",
    "name": "Iman Shumpert",
    "rosters": [
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0"
    ]
  },
  "202698": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "228",
    "BIRTH_DATE": "NOV 21, 1989",
    "EXP": "2",
    "SCHOOL": "Florida State",
    "name": "Chris Singleton",
    "rosters": [
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c",
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a"
    ]
  },
  "202699": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 15, 1992",
    "EXP": "5",
    "SCHOOL": "Tennessee",
    "name": "Tobias Harris",
    "rosters": [
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e"
    ]
  },
  "202700": {
    "NUM": "28",
    "POSITION": "F-C",
    "HEIGHT": "7-0",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 20, 1990",
    "EXP": "5",
    "SCHOOL": "Lithuania",
    "name": "Donatas Motiejunas",
    "rosters": [
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b"
    ]
  },
  "202701": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUL 25, 1988",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Nolan Smith",
    "rosters": [
      "eb1fe675-4b7f-425d-9ad0-cd2f9abbc676",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d"
    ]
  },
  "202702": {
    "NUM": "35",
    "POSITION": "F-C",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 19, 1989",
    "EXP": "7",
    "SCHOOL": "Morehead State",
    "name": "Kenneth Faried",
    "rosters": [
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "7555fde4-17ee-408f-a2cb-c48414c8073d"
    ]
  },
  "202703": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "FEB 11, 1991",
    "EXP": "4",
    "SCHOOL": " ",
    "name": "Nikola Mirotic",
    "rosters": [
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "4eeead22-09ec-4f45-95dc-12de108dc38f"
    ]
  },
  "202704": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "208",
    "BIRTH_DATE": "APR 16, 1990",
    "EXP": "7",
    "SCHOOL": "Boston College",
    "name": "Reggie Jackson",
    "rosters": [
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "7f23a5fb-d7d1-4096-955b-6836e40d9f9d",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e",
      "45324799-5d41-45eb-b5ff-b3095f173c43",
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "202705": {
    "NUM": "8",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 26, 1989",
    "EXP": "3",
    "SCHOOL": "Providence",
    "name": "MarShon Brooks",
    "rosters": [
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "202706": {
    "NUM": "1",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 06, 1990",
    "EXP": "3",
    "SCHOOL": "Texas",
    "name": "Jordan Hamilton",
    "rosters": [
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3"
    ]
  },
  "202707": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "221",
    "BIRTH_DATE": "FEB 08, 1989",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "JaJuan Johnson",
    "rosters": [
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331"
    ]
  },
  "202708": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 13, 1988",
    "EXP": "5",
    "SCHOOL": "Cleveland State",
    "name": "Norris Cole",
    "rosters": [
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "3c3c9d71-2ac4-4e72-ad9e-a196fd9c7863",
      "cee68385-dff8-4a7d-ac14-a73bb6a69edc",
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c"
    ]
  },
  "202709": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 20, 1991",
    "EXP": "5",
    "SCHOOL": "Texas",
    "name": "Cory Joseph",
    "rosters": [
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac",
      "eaa71d89-5839-4a85-945e-b783a1864495",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e"
    ]
  },
  "202710": {
    "NUM": "23",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "232",
    "BIRTH_DATE": "SEP 14, 1989",
    "EXP": "7",
    "SCHOOL": "Marquette",
    "name": "Jimmy Butler",
    "rosters": [
      "cffc2631-ee7d-4642-b929-718bf23c51fc",
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "d1566529-2393-422f-9177-00c89290a8dc",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "5a728193-2607-49fa-b4d1-2298f8044312"
    ]
  },
  "202711": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 18, 1989",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Bojan Bogdanovic",
    "rosters": [
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6"
    ]
  },
  "202712": {
    "NUM": "32",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 30, 1989",
    "EXP": "R",
    "SCHOOL": "Richmond",
    "name": "Justin Harper",
    "rosters": [
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7"
    ]
  },
  "202713": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 04, 1988",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Kyle Singler",
    "rosters": [
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c",
      "77e6e4de-42ee-4c42-be39-b046215914f2",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "202714": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "203",
    "BIRTH_DATE": "APR 22, 1990",
    "EXP": "7",
    "SCHOOL": "Butler",
    "name": "Shelvin Mack",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b",
      "0cca6630-b178-49fe-a6b3-37fee4aa7b7c",
      "7e4b59f0-0509-4845-8730-9663232e7b79"
    ]
  },
  "202715": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "188",
    "BIRTH_DATE": "JUL 15, 1990",
    "EXP": "R",
    "SCHOOL": "California-Los Angeles",
    "name": "Tyler Honeycutt",
    "rosters": [
      "8ef85512-070b-4f28-9454-9d4cc78d5562"
    ]
  },
  "202716": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "OCT 11, 1990",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Jordan Williams",
    "rosters": [
      "c5b4e2c0-91e5-453d-ad22-ac66b6068540"
    ]
  },
  "202717": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 29, 1990",
    "EXP": "R",
    "SCHOOL": "Georgia",
    "name": "Trey Thompkins",
    "rosters": [
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105"
    ]
  },
  "202718": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 25, 1988",
    "EXP": "7",
    "SCHOOL": "Florida",
    "name": "Chandler Parsons",
    "rosters": [
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "202719": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 21, 1991",
    "EXP": "2",
    "SCHOOL": null,
    "name": "Jeremy Tyler",
    "rosters": [
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b"
    ]
  },
  "202720": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "228",
    "BIRTH_DATE": "MAY 14, 1989",
    "EXP": "7",
    "SCHOOL": "Wisconsin",
    "name": "Jon Leuer",
    "rosters": [
      "2e023de2-4ded-40cd-a695-c36f062148dd",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "323505fb-0be2-4c98-96be-9a14a40d1c78",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e",
      "45324799-5d41-45eb-b5ff-b3095f173c43",
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "202721": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 03, 1991",
    "EXP": "3",
    "SCHOOL": "Michigan",
    "name": "Darius Morris",
    "rosters": [
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7"
    ]
  },
  "202722": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 12, 1992",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Davis Bertans",
    "rosters": [
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "202723": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 22, 1990",
    "EXP": "1",
    "SCHOOL": "California-Los Angeles",
    "name": "Malcolm Lee",
    "rosters": [
      "bf279b0d-e75c-486c-90a8-ef9e42a95cc4",
      "c7ea7be2-56d1-4574-b226-2038cb0e053c"
    ]
  },
  "202724": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 28, 1989",
    "EXP": "1",
    "SCHOOL": "Hofstra",
    "name": "Charles Jenkins",
    "rosters": [
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "2216683d-34cb-4284-b507-8c9bf140d784"
    ]
  },
  "202725": {
    "NUM": "55",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "275",
    "BIRTH_DATE": "FEB 12, 1989",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Josh Harrellson",
    "rosters": [
      "b4719220-3f70-4b0f-8fb8-bf85cac62a70",
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "202726": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 07, 1988",
    "EXP": "1",
    "SCHOOL": "College of Charleston",
    "name": "Andrew Goudelock",
    "rosters": [
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "ac885666-cac9-4f64-8d38-9179a8c79cf9",
      "70af2982-c5b1-40e5-a2fb-508f22146f3b"
    ]
  },
  "202727": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 29, 1990",
    "EXP": "R",
    "SCHOOL": "Georgia",
    "name": "Travis Leslie",
    "rosters": [
      "3e5888f2-9bf8-4adb-bc20-e74ba3870105"
    ]
  },
  "202729": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "183",
    "BIRTH_DATE": "MAR 27, 1991",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Josh Selby",
    "rosters": [
      "fdbb84b4-a3cc-4660-a3ba-719af8281289"
    ]
  },
  "202730": {
    "NUM": "50",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "255",
    "BIRTH_DATE": "FEB 04, 1989",
    "EXP": "1",
    "SCHOOL": "Temple",
    "name": "Lavoy Allen",
    "rosters": [
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68",
      "2216683d-34cb-4284-b507-8c9bf140d784"
    ]
  },
  "202731": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "227",
    "BIRTH_DATE": "SEP 25, 1986",
    "EXP": "R",
    "SCHOOL": "Florida",
    "name": "Vernon Macklin",
    "rosters": [
      "11078f24-e0e4-4d1b-89cb-44eb30bed6c1"
    ]
  },
  "202732": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "209",
    "BIRTH_DATE": "MAR 31, 1988",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "DeAndre Liggins",
    "rosters": [
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "e938ceb5-43c8-48c0-9214-12c48bc2b2d7",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc"
    ]
  },
  "202734": {
    "NUM": "55",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "191",
    "BIRTH_DATE": "FEB 25, 1989",
    "EXP": "2",
    "SCHOOL": "Purdue",
    "name": "E'Twaun Moore",
    "rosters": [
      "6d77e166-8f28-4a0c-9d22-6c9ecdd9c331",
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "1b10411f-c8db-4154-9db0-1900f8577a91",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "5fff07e8-267c-43b9-9b16-95da13845b0e"
    ]
  },
  "202738": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "5-9",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 07, 1989",
    "EXP": "2",
    "SCHOOL": "Washington",
    "name": "Isaiah Thomas",
    "rosters": [
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "8ef85512-070b-4f28-9454-9d4cc78d5562",
      "afc314f8-d2d1-427f-b7b2-2233ba35248d",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f"
    ]
  },
  "202779": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 06, 1989",
    "EXP": "2",
    "SCHOOL": "Marquette",
    "name": "Dwight Buycks",
    "rosters": [
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "45324799-5d41-45eb-b5ff-b3095f173c43"
    ]
  },
  "202809": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 14, 1989",
    "EXP": "R",
    "SCHOOL": "Colorado",
    "name": "Cory Higgins",
    "rosters": [
      "ec301ccf-b3d6-4a12-ae55-a477e60e04c6"
    ]
  },
  "202810": {
    "NUM": "12",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "214",
    "BIRTH_DATE": "NOV 05, 1989",
    "EXP": "R",
    "SCHOOL": "St. John's (NY)",
    "name": "D.J. Kennedy",
    "rosters": [
      "394863c9-b8a6-474b-b7ac-a73cf972c685"
    ]
  },
  "202874": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "226",
    "BIRTH_DATE": "SEP 30, 1988",
    "EXP": "1",
    "SCHOOL": "Dayton",
    "name": "Chris Wright",
    "rosters": [
      "044b28cb-9356-44d5-8389-fa87b0ad75da",
      "0642a459-b265-4cac-adbd-de39b087debf"
    ]
  },
  "202918": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 22, 1988",
    "EXP": "R",
    "SCHOOL": "Northern Illinois",
    "name": "Xavier Silas",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "c57d0d27-d323-4563-82ba-db2c87f6ec68"
    ]
  },
  "202933": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 07, 1988",
    "EXP": "3",
    "SCHOOL": "Texas-El Paso",
    "name": "Julyan Stone",
    "rosters": [
      "d4c8dbd4-ea8a-469e-ac4b-3f4e2478bb5d",
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9"
    ]
  },
  "202951": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "219",
    "BIRTH_DATE": "MAR 17, 1986",
    "EXP": "R",
    "SCHOOL": "Gonzaga",
    "name": "Jeremy Pargo",
    "rosters": [
      "fdbb84b4-a3cc-4660-a3ba-719af8281289"
    ]
  },
  "202952": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 08, 1988",
    "EXP": "2",
    "SCHOOL": "San Diego State",
    "name": "Malcolm Thomas",
    "rosters": [
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d"
    ]
  },
  "202954": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 25, 1989",
    "EXP": "1",
    "SCHOOL": "Pittsburgh",
    "name": "Brad Wanamaker",
    "rosters": [
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "202962": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "JAN 08, 1991",
    "EXP": "4",
    "SCHOOL": "Fresno State",
    "name": "Greg Smith",
    "rosters": [
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "5bfebbd7-bf97-456f-ab7b-3e1a2ce5f41a",
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "d6b9a95f-5c95-4743-9999-f4582523ddc0"
    ]
  },
  "202970": {
    "NUM": "19",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "250",
    "BIRTH_DATE": "APR 01, 1985",
    "EXP": "1",
    "SCHOOL": "Tepic, Mexico",
    "name": "Gustavo Ayon",
    "rosters": [
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "506d3176-b688-4317-9dc7-cfecc25eb790",
      "a139cb1e-1d24-4d95-a51e-af40949889f2"
    ]
  },
  "203006": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 10, 1986",
    "EXP": "R",
    "SCHOOL": "Cal State-Fullerton",
    "name": "Josh Akognon",
    "rosters": [
      "25084fac-f6f9-4c54-b21c-e94d6295d687"
    ]
  },
  "203076": {
    "NUM": "3",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "253",
    "BIRTH_DATE": "MAR 11, 1993",
    "EXP": "7",
    "SCHOOL": "Kentucky",
    "name": "Anthony Davis",
    "rosters": [
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "db38d209-7326-41b8-948f-588f9a050e00"
    ]
  },
  "203077": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "232",
    "BIRTH_DATE": "SEP 26, 1993",
    "EXP": "6",
    "SCHOOL": "Kentucky",
    "name": "Michael Kidd-Gilchrist",
    "rosters": [
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095",
      "45ed0581-1916-49f0-9a2f-fb15342345c5",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864",
      "31164298-2c0d-4a44-b557-e740801b4b1d",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79"
    ]
  },
  "203078": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "207",
    "BIRTH_DATE": "JUN 28, 1993",
    "EXP": "7",
    "SCHOOL": "Florida",
    "name": "Bradley Beal",
    "rosters": [
      "a7d27287-c67a-47d6-a1cd-10bfa0d64eb8",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "8d3a3080-9e74-4512-8869-83aad854d0a0",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad",
      "6631c074-5c99-48db-b77d-c92e855be5bd",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "203079": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 10, 1991",
    "EXP": "3",
    "SCHOOL": "Syracuse",
    "name": "Dion Waiters",
    "rosters": [
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "51581511-27eb-417b-be7a-c3792b97c046",
      "db38d209-7326-41b8-948f-588f9a050e00",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59"
    ]
  },
  "203080": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "237",
    "BIRTH_DATE": "MAR 17, 1991",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Thomas Robinson",
    "rosters": [
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed"
    ]
  },
  "203081": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 15, 1990",
    "EXP": "7",
    "SCHOOL": "Weber State",
    "name": "Damian Lillard",
    "rosters": [
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "203082": {
    "NUM": "31",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 05, 1991",
    "EXP": "3",
    "SCHOOL": "Washington",
    "name": "Terrence Ross",
    "rosters": [
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63",
      "896773e3-d58b-484d-b4ce-cba82ed75933",
      "d7c8c23d-7f6f-4ae3-b73b-cd15f4e81831",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "5ac68c0d-43c1-490d-87f9-5b431453d831"
    ]
  },
  "203083": {
    "NUM": "0",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "279",
    "BIRTH_DATE": "AUG 10, 1993",
    "EXP": "6",
    "SCHOOL": "Connecticut",
    "name": "Andre Drummond",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac",
      "e897fcee-4043-4882-b3ac-076b89980fff",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e",
      "45324799-5d41-45eb-b5ff-b3095f173c43",
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "203084": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 30, 1992",
    "EXP": "7",
    "SCHOOL": "North Carolina",
    "name": "Harrison Barnes",
    "rosters": [
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "203085": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 01, 1992",
    "EXP": "5",
    "SCHOOL": "Duke",
    "name": "Austin Rivers",
    "rosters": [
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae"
    ]
  },
  "203086": {
    "NUM": "11",
    "POSITION": "F-C",
    "HEIGHT": "7-1",
    "WEIGHT": "255",
    "BIRTH_DATE": "FEB 27, 1992",
    "EXP": "6",
    "SCHOOL": "Illinois",
    "name": "Meyers Leonard",
    "rosters": [
      "d1566529-2393-422f-9177-00c89290a8dc",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add"
    ]
  },
  "203087": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 30, 1992",
    "EXP": "6",
    "SCHOOL": "Connecticut",
    "name": "Jeremy Lamb",
    "rosters": [
      "58b309fd-0cb6-4071-8912-360baeb068ca",
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "31164298-2c0d-4a44-b557-e740801b4b1d",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79"
    ]
  },
  "203088": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 19, 1991",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Kendall Marshall",
    "rosters": [
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553"
    ]
  },
  "203089": {
    "NUM": "31",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "219",
    "BIRTH_DATE": "DEC 28, 1990",
    "EXP": "7",
    "SCHOOL": "North Carolina",
    "name": "John Henson",
    "rosters": [
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "a139cb1e-1d24-4d95-a51e-af40949889f2",
      "0642a459-b265-4cac-adbd-de39b087debf",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "203090": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 11, 1993",
    "EXP": "6",
    "SCHOOL": "St. John's, N.Y.",
    "name": "Maurice Harkless",
    "rosters": [
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add"
    ]
  },
  "203091": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "260",
    "BIRTH_DATE": "APR 10, 1991",
    "EXP": "R",
    "SCHOOL": "Iowa State",
    "name": "Royce White",
    "rosters": [
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963"
    ]
  },
  "203092": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "253",
    "BIRTH_DATE": "JAN 17, 1990",
    "EXP": "6",
    "SCHOOL": "North Carolina",
    "name": "Tyler Zeller",
    "rosters": [
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c",
      "51581511-27eb-417b-be7a-c3792b97c046",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "203093": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "252",
    "BIRTH_DATE": "JAN 09, 1992",
    "EXP": "3",
    "SCHOOL": "Kentucky",
    "name": "Terrence Jones",
    "rosters": [
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2"
    ]
  },
  "203094": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "DEC 08, 1989",
    "EXP": "3",
    "SCHOOL": "St. Bonaventure",
    "name": "Andrew Nicholson",
    "rosters": [
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "ef7374a1-417b-4cf4-b556-208b918b32d6"
    ]
  },
  "203095": {
    "NUM": "10",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 29, 1992",
    "EXP": "7",
    "SCHOOL": " ",
    "name": "Evan Fournier",
    "rosters": [
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63"
    ]
  },
  "203096": {
    "NUM": "7",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "260",
    "BIRTH_DATE": "MAR 04, 1992",
    "EXP": "3",
    "SCHOOL": "Ohio State",
    "name": "Jared Sullinger",
    "rosters": [
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1",
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27"
    ]
  },
  "203097": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUN 20, 1990",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Fab Melo",
    "rosters": [
      "e39b91e4-d40c-4b88-8610-a0d54d6e9dc1"
    ]
  },
  "203098": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 06, 1991",
    "EXP": "3",
    "SCHOOL": "Vanderbilt",
    "name": "John Jenkins",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1"
    ]
  },
  "203099": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 22, 1991",
    "EXP": "1",
    "SCHOOL": "Oregon State",
    "name": "Jared Cunningham",
    "rosters": [
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f"
    ]
  },
  "203100": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "208",
    "BIRTH_DATE": "APR 13, 1993",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Tony Wroten",
    "rosters": [
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "341a849d-e970-4a90-8bee-8a209ea47cb1",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "323505fb-0be2-4c98-96be-9a14a40d1c78"
    ]
  },
  "203101": {
    "NUM": "18",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "249",
    "BIRTH_DATE": "SEP 01, 1988",
    "EXP": "4",
    "SCHOOL": "Duke",
    "name": "Miles Plumlee",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e"
    ]
  },
  "203102": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 18, 1990",
    "EXP": "1",
    "SCHOOL": "Mississippi State",
    "name": "Arnett Moultrie",
    "rosters": [
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "341a849d-e970-4a90-8bee-8a209ea47cb1"
    ]
  },
  "203103": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 24, 1991",
    "EXP": "2",
    "SCHOOL": "Baylor",
    "name": "Perry Jones III",
    "rosters": [
      "58c24127-2a5a-4b63-a84e-ce7fb8091ddc",
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "40187213-bcbb-43d1-a283-a7682ab728e0"
    ]
  },
  "203104": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "189",
    "BIRTH_DATE": "FEB 28, 1993",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Marquis Teague",
    "rosters": [
      "24f3581c-303b-4110-b220-004a40cdaf1c",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "203105": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "265",
    "BIRTH_DATE": "OCT 21, 1989",
    "EXP": "3",
    "SCHOOL": "Vanderbilt",
    "name": "Festus Ezeli",
    "rosters": [
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "bf8f9985-d8ac-46b8-a125-085201212231"
    ]
  },
  "203106": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAY 23, 1989",
    "EXP": "2",
    "SCHOOL": "Vanderbilt",
    "name": "Jeffery Taylor",
    "rosters": [
      "0d64aa31-dbd0-4e9e-a9af-9a567e992095",
      "45ed0581-1916-49f0-9a2f-fb15342345c5",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864"
    ]
  },
  "203107": {
    "NUM": "31",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 30, 1991",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Tomas Satoransky",
    "rosters": [
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad",
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "203108": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "FEB 07, 1985",
    "EXP": "2",
    "SCHOOL": "Florida State",
    "name": "Bernard James",
    "rosters": [
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8"
    ]
  },
  "203109": {
    "NUM": "99",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 06, 1990",
    "EXP": "6",
    "SCHOOL": "Marquette",
    "name": "Jae Crowder",
    "rosters": [
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "25084fac-f6f9-4c54-b21c-e94d6295d687",
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "d1566529-2393-422f-9177-00c89290a8dc",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "203110": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAR 04, 1990",
    "EXP": "7",
    "SCHOOL": "Michigan State",
    "name": "Draymond Green",
    "rosters": [
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "203111": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 11, 1989",
    "EXP": "R",
    "SCHOOL": "Cal-Santa Barbara",
    "name": "Orlando Johnson",
    "rosters": [
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456"
    ]
  },
  "203112": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 06, 1990",
    "EXP": "R",
    "SCHOOL": "Baylor",
    "name": "Quincy Acy",
    "rosters": [
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "896773e3-d58b-484d-b4ce-cba82ed75933"
    ]
  },
  "203113": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 18, 1992",
    "EXP": "2",
    "SCHOOL": "Baylor",
    "name": "Quincy Miller",
    "rosters": [
      "4bddbc82-c340-415e-a90b-60892b06c970",
      "927197ae-18fb-4e93-9274-396b0b0eceeb",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7"
    ]
  },
  "203114": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 12, 1991",
    "EXP": "R",
    "SCHOOL": "Texas A&M",
    "name": "Khris Middleton",
    "rosters": [
      "0642a459-b265-4cac-adbd-de39b087debf",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac"
    ]
  },
  "203115": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 06, 1991",
    "EXP": "1",
    "SCHOOL": "Memphis",
    "name": "Will Barton",
    "rosters": [
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "dbd3455e-60ba-4d50-9b96-353f912b371a",
      "6f3a808f-3d1d-4041-ba62-bd244ca7c76d",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed"
    ]
  },
  "203116": {
    "NUM": "41",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 12, 1990",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Tyshawn Taylor",
    "rosters": [
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710"
    ]
  },
  "203117": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 06, 1991",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Doron Lamb",
    "rosters": [
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "5fff07e8-267c-43b9-9b16-95da13845b0e"
    ]
  },
  "203118": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "237",
    "BIRTH_DATE": "JUL 16, 1988",
    "EXP": "5",
    "SCHOOL": "Virginia",
    "name": "Mike Scott",
    "rosters": [
      "09be1129-7bd3-4a7d-83ae-2eff8261643e",
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad"
    ]
  },
  "203119": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 24, 1988",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Kim English",
    "rosters": [
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac"
    ]
  },
  "203120": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "APR 01, 1990",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Justin Hamilton",
    "rosters": [
      "af9f6951-8f66-4c7a-bff9-df3b4ff3e368",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18"
    ]
  },
  "203121": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 21, 1990",
    "EXP": "5",
    "SCHOOL": "Kentucky",
    "name": "Darius Miller",
    "rosters": [
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "1b10411f-c8db-4154-9db0-1900f8577a91"
    ]
  },
  "203122": {
    "NUM": "55",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 06, 1990",
    "EXP": "R",
    "SCHOOL": "Tennessee Tech",
    "name": "Kevin Murphy",
    "rosters": [
      "83706098-479a-4732-9529-09c78cfdb502"
    ]
  },
  "203123": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 31, 1990",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Kostas Papanikolaou",
    "rosters": [
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee"
    ]
  },
  "203124": {
    "NUM": "9",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAR 26, 1990",
    "EXP": "7",
    "SCHOOL": "Norfolk State",
    "name": "Kyle O'Quinn",
    "rosters": [
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "286981d2-4163-449b-ae90-84e38b5204fc",
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "203126": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 17, 1988",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Kris Joseph",
    "rosters": [
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710"
    ]
  },
  "203128": {
    "NUM": "19",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 09, 1991",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Furkan Aldemir",
    "rosters": [
      "23894f15-3411-4056-9e26-0a374667fc93"
    ]
  },
  "203129": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 05, 1991",
    "EXP": "R",
    "SCHOOL": "Georgia",
    "name": "Tornike Shengelia",
    "rosters": [
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710"
    ]
  },
  "203133": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "219",
    "BIRTH_DATE": "MAR 08, 1989",
    "EXP": "1",
    "SCHOOL": "Purdue",
    "name": "Robbie Hummel",
    "rosters": [
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191"
    ]
  },
  "203135": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "270",
    "BIRTH_DATE": "JUN 06, 1989",
    "EXP": "3",
    "SCHOOL": "Gonzaga",
    "name": "Robert Sacre",
    "rosters": [
      "70af2982-c5b1-40e5-a2fb-508f22146f3b",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed"
    ]
  },
  "203136": {
    "NUM": "1",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "260",
    "BIRTH_DATE": "MAY 16, 1990",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Ognjen Kuzmic",
    "rosters": [
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a",
      "3be5e5e4-3153-4f95-920a-943fe574edb9"
    ]
  },
  "203138": {
    "NUM": "31",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "206",
    "BIRTH_DATE": "APR 03, 1991",
    "EXP": "2",
    "SCHOOL": "Georgetown",
    "name": "Hollis Thompson",
    "rosters": [
      "341a849d-e970-4a90-8bee-8a209ea47cb1",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "b4ff765f-4502-4299-a2e5-383cd5889dea"
    ]
  },
  "203139": {
    "NUM": "55",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "247",
    "BIRTH_DATE": "AUG 25, 1987",
    "EXP": "R",
    "SCHOOL": "Ukraine",
    "name": "Viacheslav Kravtsov",
    "rosters": [
      "28fbed8c-c7c6-480e-ac1d-0cb93972dcac"
    ]
  },
  "203141": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "242",
    "BIRTH_DATE": "SEP 17, 1985",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Mirza Teletovic",
    "rosters": [
      "f08ff061-361a-4172-9765-ffea895a1265",
      "3d30044e-ba2e-4407-8b2e-aa9d09c23710",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1"
    ]
  },
  "203142": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 17, 1984",
    "EXP": "2",
    "SCHOOL": "Colorado",
    "name": "Chris Copeland",
    "rosters": [
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3"
    ]
  },
  "203143": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 17, 1977",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Pablo Prigioni",
    "rosters": [
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "b0f967e1-ee8b-4d1e-850f-b36d7650298f",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b"
    ]
  },
  "203144": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "187",
    "BIRTH_DATE": "DEC 16, 1988",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Alexey Shved",
    "rosters": [
      "c7ea7be2-56d1-4574-b226-2038cb0e053c",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "490624e3-33f6-49ad-b637-d7afef77804f"
    ]
  },
  "203145": {
    "NUM": "26",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 01, 1989",
    "EXP": "7",
    "SCHOOL": "Old Dominion",
    "name": "Kent Bazemore",
    "rosters": [
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "674c9b5e-4ae4-466f-ae8c-c27783858243",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "203146": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 02, 1991",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Maalik Wayns",
    "rosters": [
      "6d8101b2-0835-4661-8dd5-46d4653e0851"
    ]
  },
  "203148": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "173",
    "BIRTH_DATE": "DEC 03, 1985",
    "EXP": "4",
    "SCHOOL": "University of Dayton",
    "name": "Brian Roberts",
    "rosters": [
      "f491a823-32f7-4eac-9f38-2e571e28a597",
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e"
    ]
  },
  "203156": {
    "NUM": "35",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "248",
    "BIRTH_DATE": "MAR 27, 1990",
    "EXP": "2",
    "SCHOOL": "Georgetown",
    "name": "Henry Sims",
    "rosters": [
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "341a849d-e970-4a90-8bee-8a209ea47cb1",
      "23894f15-3411-4056-9e26-0a374667fc93"
    ]
  },
  "203158": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "260",
    "BIRTH_DATE": "AUG 25, 1989",
    "EXP": "R",
    "SCHOOL": "West Virginia",
    "name": "Kevin Jones",
    "rosters": [
      "08c952ee-8f32-4c75-99e0-0faba51dcd4c"
    ]
  },
  "203159": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 08, 1990",
    "EXP": "R",
    "SCHOOL": "Iona",
    "name": "Scott Machado",
    "rosters": [
      "674c9b5e-4ae4-466f-ae8c-c27783858243"
    ]
  },
  "203162": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "203",
    "BIRTH_DATE": "DEC 23, 1987",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Ben Hansbrough",
    "rosters": [
      "1af6fcdb-4544-4bb5-b5db-da7deaa96456"
    ]
  },
  "203186": {
    "NUM": "3",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 16, 1990",
    "EXP": "R",
    "SCHOOL": "St. Louis",
    "name": "Willie Reed",
    "rosters": [
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "323505fb-0be2-4c98-96be-9a14a40d1c78"
    ]
  },
  "203187": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "206",
    "BIRTH_DATE": "APR 29, 1990",
    "EXP": "3",
    "SCHOOL": "Dayton",
    "name": "Chris Johnson",
    "rosters": [
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61"
    ]
  },
  "203197": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 03, 1988",
    "EXP": "1",
    "SCHOOL": "Iowa State",
    "name": "Diante Garrett",
    "rosters": [
      "0d7e2bf9-ab2d-4aa8-9980-49bf9909c553",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d"
    ]
  },
  "203199": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "221",
    "BIRTH_DATE": "JUN 20, 1990",
    "EXP": "R",
    "SCHOOL": "Miami (FL)",
    "name": "DeQuan Jones",
    "rosters": [
      "286981d2-4163-449b-ae90-84e38b5204fc"
    ]
  },
  "203200": {
    "NUM": "7",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "181",
    "BIRTH_DATE": "APR 05, 1989",
    "EXP": "5",
    "SCHOOL": "Washington",
    "name": "Justin Holiday",
    "rosters": [
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "58b309fd-0cb6-4071-8912-360baeb068ca",
      "2216683d-34cb-4284-b507-8c9bf140d784",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "203210": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "227",
    "BIRTH_DATE": "JUN 21, 1990",
    "EXP": "3",
    "SCHOOL": "Alabama",
    "name": "JaMychal Green",
    "rosters": [
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "203268": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "189",
    "BIRTH_DATE": "DEC 27, 1988",
    "EXP": "2",
    "SCHOOL": "California",
    "name": "Jorge Gutierrez",
    "rosters": [
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "31164298-2c0d-4a44-b557-e740801b4b1d"
    ]
  },
  "203315": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "NOV 08, 1989",
    "EXP": "R",
    "SCHOOL": "Wichita State",
    "name": "Toure' Murry",
    "rosters": [
      "dfed58fd-d7c6-45f4-ba66-809742a9320b"
    ]
  },
  "203317": {
    "NUM": "14",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "AUG 30, 1988",
    "EXP": "R",
    "SCHOOL": "Germany",
    "name": "Tim Ohlbrecht",
    "rosters": [
      "3d4a6009-0dcb-4aaf-a836-e4c80e32d963"
    ]
  },
  "203318": {
    "NUM": "14",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "206",
    "BIRTH_DATE": "JAN 01, 1991",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Glen Rice",
    "rosters": [
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a"
    ]
  },
  "203382": {
    "NUM": "12",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "260",
    "BIRTH_DATE": "DEC 09, 1986",
    "EXP": "4",
    "SCHOOL": "Washington State",
    "name": "Aron Baynes",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "fdf322f1-9f93-4535-913c-53228db4b4fb",
      "4231849b-3ead-4d05-b93f-0c6a52ae3008",
      "5f2d0085-7d27-4458-be57-9fb5ff3d09ab",
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e"
    ]
  },
  "203457": {
    "NUM": "9",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "APR 10, 1994",
    "EXP": "5",
    "SCHOOL": "Kentucky",
    "name": "Nerlens Noel",
    "rosters": [
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "341a849d-e970-4a90-8bee-8a209ea47cb1",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "203458": {
    "NUM": "25",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 16, 1993",
    "EXP": "6",
    "SCHOOL": "Maryland",
    "name": "Alex Len",
    "rosters": [
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "203459": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 09, 1992",
    "EXP": "3",
    "SCHOOL": "California",
    "name": "Allen Crabbe",
    "rosters": [
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "bf8f9985-d8ac-46b8-a125-085201212231"
    ]
  },
  "203460": {
    "NUM": "21",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 04, 1991",
    "EXP": "5",
    "SCHOOL": "Colorado",
    "name": "Andre Roberson",
    "rosters": [
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c",
      "77e6e4de-42ee-4c42-be39-b046215914f2",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "203461": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAR 14, 1993",
    "EXP": "1",
    "SCHOOL": "UNLV",
    "name": "Anthony Bennett",
    "rosters": [
      "51581511-27eb-417b-be7a-c3792b97c046",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191"
    ]
  },
  "203462": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "AUG 17, 1994",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Archie Goodwin",
    "rosters": [
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "cd1c6261-0878-4024-8b67-e754584c830b",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1"
    ]
  },
  "203463": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 11, 1993",
    "EXP": "4",
    "SCHOOL": "Kansas",
    "name": "Ben McLemore",
    "rosters": [
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "203464": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 30, 1991",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Brandon Paul",
    "rosters": [
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8"
    ]
  },
  "203467": {
    "NUM": "30",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "201",
    "BIRTH_DATE": "AUG 17, 1990",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Carrick Felix",
    "rosters": [
      "51581511-27eb-417b-be7a-c3792b97c046"
    ]
  },
  "203468": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 19, 1991",
    "EXP": "6",
    "SCHOOL": "Lehigh",
    "name": "CJ McCollum",
    "rosters": [
      "62ee5039-cd9c-4bdd-a3ee-b241a2901eed",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "203469": {
    "NUM": "40",
    "POSITION": "F-C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 05, 1992",
    "EXP": "6",
    "SCHOOL": "Indiana",
    "name": "Cody Zeller",
    "rosters": [
      "45ed0581-1916-49f0-9a2f-fb15342345c5",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864",
      "31164298-2c0d-4a44-b557-e740801b4b1d",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "203471": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "172",
    "BIRTH_DATE": "SEP 15, 1993",
    "EXP": "6",
    "SCHOOL": " ",
    "name": "Dennis Schroder",
    "rosters": [
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "203473": {
    "NUM": "3",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "AUG 12, 1989",
    "EXP": "3",
    "SCHOOL": "USC",
    "name": "Dewayne Dedmon",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "e8169801-0d82-448f-b1b7-6fb64e81aac6",
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "2727fadf-a3e8-4564-9084-0145ec943ae4"
    ]
  },
  "203475": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAY 09, 1991",
    "EXP": "R",
    "SCHOOL": "Virginia Tech",
    "name": "Erick Green",
    "rosters": [
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f"
    ]
  },
  "203476": {
    "NUM": "14",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "252",
    "BIRTH_DATE": "JAN 18, 1990",
    "EXP": "6",
    "SCHOOL": "Louisville",
    "name": "Gorgui Dieng",
    "rosters": [
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "203477": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "201",
    "BIRTH_DATE": "MAY 21, 1991",
    "EXP": "2",
    "SCHOOL": "Murray State",
    "name": "Isaiah Canaan",
    "rosters": [
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "b4ff765f-4502-4299-a2e5-383cd5889dea"
    ]
  },
  "203479": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "191",
    "BIRTH_DATE": "JUL 21, 1991",
    "EXP": "R",
    "SCHOOL": "San Diego State",
    "name": "Jamaal Franklin",
    "rosters": [
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "2b385c6a-6d38-4a83-8530-3b3d54ade9b3"
    ]
  },
  "203480": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 28, 1990",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "James Southerland",
    "rosters": [
      "05a55327-a089-468c-962f-fef3dd7afc4b"
    ]
  },
  "203481": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "231",
    "BIRTH_DATE": "MAR 07, 1990",
    "EXP": "3",
    "SCHOOL": "Kansas",
    "name": "Jeff Withey",
    "rosters": [
      "05a55327-a089-468c-962f-fef3dd7afc4b",
      "e6b0c2d8-aba3-4e93-935a-8d4a75eabfd8",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b"
    ]
  },
  "203482": {
    "NUM": "9",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 19, 1991",
    "EXP": "6",
    "SCHOOL": "Gonzaga",
    "name": "Kelly Olynyk",
    "rosters": [
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "d1566529-2393-422f-9177-00c89290a8dc"
    ]
  },
  "203484": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 18, 1993",
    "EXP": "3",
    "SCHOOL": "Georgia",
    "name": "Kentavious Caldwell-Pope",
    "rosters": [
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "db38d209-7326-41b8-948f-588f9a050e00",
      "e897fcee-4043-4882-b3ac-076b89980fff",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e"
    ]
  },
  "203485": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "189",
    "BIRTH_DATE": "AUG 26, 1990",
    "EXP": "2",
    "SCHOOL": "North Carolina State",
    "name": "Lorenzo Brown",
    "rosters": [
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "fbff0712-5fed-4e22-8afa-30387f9f0753"
    ]
  },
  "203486": {
    "NUM": "24",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 05, 1990",
    "EXP": "2",
    "SCHOOL": "Duke",
    "name": "Mason Plumlee",
    "rosters": [
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "dbd3455e-60ba-4d50-9b96-353f912b371a",
      "7842004f-64a2-41a8-8552-327d53b7a972",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "cbb4c930-842b-4d16-a892-29ffd4897a48"
    ]
  },
  "203487": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 10, 1991",
    "EXP": "4",
    "SCHOOL": "Syracuse",
    "name": "Michael Carter-Williams",
    "rosters": [
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63",
      "341a849d-e970-4a90-8bee-8a209ea47cb1",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9"
    ]
  },
  "203488": {
    "NUM": "33",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 01, 1991",
    "EXP": "6",
    "SCHOOL": "Bucknell",
    "name": "Mike Muscala",
    "rosters": [
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a",
      "e024d958-351f-40c8-be44-4c56111346b2",
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "203489": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 15, 1991",
    "EXP": "R",
    "SCHOOL": "South Dakota State",
    "name": "Nate Wolters",
    "rosters": [
      "0642a459-b265-4cac-adbd-de39b087debf"
    ]
  },
  "203490": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "198",
    "BIRTH_DATE": "JUN 03, 1993",
    "EXP": "4",
    "SCHOOL": "Georgetown",
    "name": "Otto Porter Jr.",
    "rosters": [
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc",
      "2a6a6206-a7c4-40af-9928-fc2c809ce17a",
      "8d3a3080-9e74-4512-8869-83aad854d0a0",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad"
    ]
  },
  "203491": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 24, 1990",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Peyton Siva",
    "rosters": [
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "203492": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUN 12, 1991",
    "EXP": "1",
    "SCHOOL": "Detroit",
    "name": "Ray McCallum",
    "rosters": [
      "e5874b6a-365d-44c5-bc64-9ffa2196f29f",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c"
    ]
  },
  "203493": {
    "NUM": "25",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 16, 1991",
    "EXP": "4",
    "SCHOOL": "North Carolina",
    "name": "Reggie Bullock",
    "rosters": [
      "f3020eca-0611-420b-8333-8de8e62b54b2",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e",
      "45324799-5d41-45eb-b5ff-b3095f173c43"
    ]
  },
  "203495": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 10, 1992",
    "EXP": "1",
    "SCHOOL": "Providence",
    "name": "Ricky Ledo",
    "rosters": [
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39",
      "490624e3-33f6-49ad-b637-d7afef77804f"
    ]
  },
  "203496": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 14, 1990",
    "EXP": "4",
    "SCHOOL": "Tennessee State",
    "name": "Robert Covington",
    "rosters": [
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0"
    ]
  },
  "203497": {
    "NUM": "27",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "258",
    "BIRTH_DATE": "JUN 26, 1992",
    "EXP": "6",
    "SCHOOL": "France",
    "name": "Rudy Gobert",
    "rosters": [
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "203498": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "223",
    "BIRTH_DATE": "NOV 13, 1992",
    "EXP": "3",
    "SCHOOL": "California-Los Angeles",
    "name": "Shabazz Muhammad",
    "rosters": [
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "75b48a6d-8ce0-43e8-9531-1a1b43248644",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e"
    ]
  },
  "203499": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "175",
    "BIRTH_DATE": "OCT 02, 1992",
    "EXP": "1",
    "SCHOOL": "Miami",
    "name": "Shane Larkin",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "490624e3-33f6-49ad-b637-d7afef77804f"
    ]
  },
  "203500": {
    "NUM": "12",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "265",
    "BIRTH_DATE": "JUL 20, 1993",
    "EXP": "6",
    "SCHOOL": "Pittsburgh",
    "name": "Steven Adams",
    "rosters": [
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c",
      "77e6e4de-42ee-4c42-be39-b046215914f2",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "203501": {
    "NUM": "3",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 16, 1992",
    "EXP": "4",
    "SCHOOL": "Michigan",
    "name": "Tim Hardaway Jr.",
    "rosters": [
      "e024d958-351f-40c8-be44-4c56111346b2",
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "dfed58fd-d7c6-45f4-ba66-809742a9320b",
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "62811488-fdf8-4824-9860-e73dfc40e088"
    ]
  },
  "203502": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 07, 1992",
    "EXP": "R",
    "SCHOOL": "North Texas",
    "name": "Tony Mitchell",
    "rosters": [
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "203503": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "213",
    "BIRTH_DATE": "NOV 10, 1991",
    "EXP": "6",
    "SCHOOL": " ",
    "name": "Tony Snell",
    "rosters": [
      "ea54bc05-d8ca-4dbb-91a5-d5fbf66d8de1",
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "203504": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "191",
    "BIRTH_DATE": "NOV 12, 1992",
    "EXP": "3",
    "SCHOOL": "Michigan",
    "name": "Trey Burke",
    "rosters": [
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6"
    ]
  },
  "203505": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 17, 1992",
    "EXP": "1",
    "SCHOOL": "Marquette",
    "name": "Vander Blue",
    "rosters": [
      "b462e94b-f32c-4a5a-99c9-5e5188884419"
    ]
  },
  "203506": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 04, 1992",
    "EXP": "3",
    "SCHOOL": "Indiana",
    "name": "Victor Oladipo",
    "rosters": [
      "5fff07e8-267c-43b9-9b16-95da13845b0e",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "58b309fd-0cb6-4071-8912-360baeb068ca",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c"
    ]
  },
  "203507": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "242",
    "BIRTH_DATE": "DEC 06, 1994",
    "EXP": "6",
    "SCHOOL": " ",
    "name": "Giannis Antetokounmpo",
    "rosters": [
      "0642a459-b265-4cac-adbd-de39b087debf",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97"
    ]
  },
  "203508": {
    "NUM": "10",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "208",
    "BIRTH_DATE": "OCT 26, 1993",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Sergey Karasev",
    "rosters": [
      "51581511-27eb-417b-be7a-c3792b97c046",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b"
    ]
  },
  "203511": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "232",
    "BIRTH_DATE": "JUL 08, 1993",
    "EXP": "R",
    "SCHOOL": "Arizona",
    "name": "Grant Jerrett",
    "rosters": [
      "ea74fd30-6765-4dc3-948b-9faed7ec7b7e",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509"
    ]
  },
  "203512": {
    "NUM": "92",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUL 26, 1992",
    "EXP": "3",
    "SCHOOL": "Brazil",
    "name": "Lucas Nogueira",
    "rosters": [
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6"
    ]
  },
  "203513": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 26, 1990",
    "EXP": "R",
    "SCHOOL": "Florida",
    "name": "Erik Murphy",
    "rosters": [
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d"
    ]
  },
  "203515": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "175",
    "BIRTH_DATE": "FEB 17, 1991",
    "EXP": "1",
    "SCHOOL": "Missouri",
    "name": "Phil Pressey",
    "rosters": [
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "783a47da-3162-4403-8a4a-5cd40c5b642b"
    ]
  },
  "203516": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 01, 1990",
    "EXP": "3",
    "SCHOOL": "Cal State-Long Beach",
    "name": "James Ennis III",
    "rosters": [
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "45324799-5d41-45eb-b5ff-b3095f173c43"
    ]
  },
  "203517": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "192",
    "BIRTH_DATE": "JUN 16, 1991",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Nemanja Nedovic",
    "rosters": [
      "1b0e3844-8de5-43dd-98a8-06dfafc12e5a"
    ]
  },
  "203518": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 01, 1993",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Alex Abrines",
    "rosters": [
      "0e161bfd-52f6-41c1-ba70-177db3f7526c",
      "77e6e4de-42ee-4c42-be39-b046215914f2"
    ]
  },
  "203519": {
    "NUM": "7",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 25, 1993",
    "EXP": "R",
    "SCHOOL": "Memphis",
    "name": "Adonis Thomas",
    "rosters": [
      "341a849d-e970-4a90-8bee-8a209ea47cb1"
    ]
  },
  "203521": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "198",
    "BIRTH_DATE": "SEP 08, 1990",
    "EXP": "4",
    "SCHOOL": "St. Mary's (CA)",
    "name": "Matthew Dellavedova",
    "rosters": [
      "51581511-27eb-417b-be7a-c3792b97c046",
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "0e43ebeb-653c-4269-a881-1aef85d79db7"
    ]
  },
  "203524": {
    "NUM": "44",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 18, 1991",
    "EXP": "2",
    "SCHOOL": "Arizona",
    "name": "Solomon Hill",
    "rosters": [
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "d1566529-2393-422f-9177-00c89290a8dc",
      "7bfdc927-91d7-4515-ba15-1c25c356b27c",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "4a8979df-b35b-47d6-acbf-06105e98a732"
    ]
  },
  "203526": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "179",
    "BIRTH_DATE": "MAY 19, 1992",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Raul Neto",
    "rosters": [
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "203527": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 09, 1991",
    "EXP": "2",
    "SCHOOL": "Duke",
    "name": "Ryan Kelly",
    "rosters": [
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "2b916dcd-2382-4695-8b82-9c3f683f3f13",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed"
    ]
  },
  "203530": {
    "NUM": "77",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "SEP 30, 1991",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Joffrey Lauvergne",
    "rosters": [
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8"
    ]
  },
  "203539": {
    "NUM": "33",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "191",
    "BIRTH_DATE": "MAR 04, 1988",
    "EXP": "R",
    "SCHOOL": "Wichita State",
    "name": "Gal Mekel",
    "rosters": [
      "fa0bce98-f205-4a4b-ad13-66dfb26beb39"
    ]
  },
  "203540": {
    "NUM": "13",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 27, 1987",
    "EXP": "R",
    "SCHOOL": "Italy",
    "name": "Gigi Datome",
    "rosters": [
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "e897fcee-4043-4882-b3ac-076b89980fff"
    ]
  },
  "203543": {
    "NUM": "38",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "MAY 05, 1988",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Vitor Faverani",
    "rosters": [
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1"
    ]
  },
  "203544": {
    "NUM": "6",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "260",
    "BIRTH_DATE": "JUL 29, 1982",
    "EXP": "1",
    "SCHOOL": "Macedonia",
    "name": "Pero Antic",
    "rosters": [
      "c4c74cf2-8a5a-4f6a-99e9-13a8bc8d8626",
      "85fbd70c-d195-4ad9-8b22-5623295c653a"
    ]
  },
  "203545": {
    "NUM": "9",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "JAN 05, 1988",
    "EXP": "R",
    "SCHOOL": "Serbia",
    "name": "Miroslav Raduljica",
    "rosters": [
      "0642a459-b265-4cac-adbd-de39b087debf"
    ]
  },
  "203546": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "173",
    "BIRTH_DATE": "MAR 07, 1991",
    "EXP": "R",
    "SCHOOL": "Belmont",
    "name": "Ian Clark",
    "rosters": [
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "aef5f61f-5f6a-4168-a4a5-f8268c6f2d5d"
    ]
  },
  "203552": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 23, 1990",
    "EXP": "2",
    "SCHOOL": "Duke",
    "name": "Seth Curry",
    "rosters": [
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "34a215f4-c672-4508-a765-ed7809c0e939"
    ]
  },
  "203561": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 25, 1991",
    "EXP": "R",
    "SCHOOL": "Brigham Young",
    "name": "Brandon Davies",
    "rosters": [
      "341a849d-e970-4a90-8bee-8a209ea47cb1"
    ]
  },
  "203564": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAY 24, 1989",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Kalin Lucas",
    "rosters": [
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "203569": {
    "NUM": "52",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 14, 1990",
    "EXP": "1",
    "SCHOOL": "Iowa State",
    "name": "Chris Babb",
    "rosters": [
      "69981c85-16cf-45f4-a1bd-a6b4ce882fa1",
      "783a47da-3162-4403-8a4a-5cd40c5b642b"
    ]
  },
  "203580": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 05, 1990",
    "EXP": "1",
    "SCHOOL": "UCLA",
    "name": "Larry Drew II",
    "rosters": [
      "3fa2e383-80a4-4177-aad1-60ea0379e220"
    ]
  },
  "203584": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 15, 1991",
    "EXP": "2",
    "SCHOOL": "Va Commonwealth",
    "name": "Troy Daniels",
    "rosters": [
      "dbd3455e-60ba-4d50-9b96-353f912b371a",
      "d5cb7b5e-338a-4f62-9ad0-415963cc682f",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae",
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864",
      "31164298-2c0d-4a44-b557-e740801b4b1d"
    ]
  },
  "203585": {
    "NUM": "17",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 29, 1991",
    "EXP": "1",
    "SCHOOL": "Kansas State",
    "name": "Rodney McGruder",
    "rosters": [
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1"
    ]
  },
  "203613": {
    "NUM": "17",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "SEP 14, 1989",
    "EXP": "1",
    "SCHOOL": "Houston",
    "name": "Jonathon Simmons",
    "rosters": [
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "2727fadf-a3e8-4564-9084-0145ec943ae4"
    ]
  },
  "203648": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "219",
    "BIRTH_DATE": "JUL 18, 1992",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Thanasis Antetokounmpo",
    "rosters": [
      "b414ac61-29b6-44e4-a605-c7f0581fbf97"
    ]
  },
  "203658": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "231",
    "BIRTH_DATE": "FEB 03, 1993",
    "EXP": "R",
    "SCHOOL": "Iona",
    "name": "Norvel Pelle",
    "rosters": [
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "203687": {
    "NUM": "38",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "AUG 14, 1991",
    "EXP": "R",
    "SCHOOL": "Northwestern",
    "name": "Reggie Hearn",
    "rosters": [
      "45324799-5d41-45eb-b5ff-b3095f173c43"
    ]
  },
  "203705": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "160",
    "BIRTH_DATE": "NOV 28, 1989",
    "EXP": "R",
    "SCHOOL": "Alabama Huntsville",
    "name": "Josh Magette",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf"
    ]
  },
  "203710": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "226",
    "BIRTH_DATE": "FEB 06, 1990",
    "EXP": "1",
    "SCHOOL": "North Carolina State",
    "name": "C.J. Williams",
    "rosters": [
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f"
    ]
  },
  "203798": {
    "NUM": "19",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 24, 1992",
    "EXP": "R",
    "SCHOOL": null,
    "name": "PJ Hairston",
    "rosters": [
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864"
    ]
  },
  "203810": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 17, 1990",
    "EXP": "5",
    "SCHOOL": "Long Beach State",
    "name": "Casper Ware",
    "rosters": [
      "341a849d-e970-4a90-8bee-8a209ea47cb1"
    ]
  },
  "203816": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "204",
    "BIRTH_DATE": "AUG 08, 1989",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Scotty Hopson",
    "rosters": [
      "51581511-27eb-417b-be7a-c3792b97c046"
    ]
  },
  "203893": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "165",
    "BIRTH_DATE": "APR 19, 1991",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Russ Smith",
    "rosters": [
      "04bba9e9-1218-49fc-af8a-f49a0a705f00"
    ]
  },
  "203894": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUL 14, 1991",
    "EXP": "5",
    "SCHOOL": "Connecticut",
    "name": "Shabazz Napier",
    "rosters": [
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "203895": {
    "NUM": "52",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "179",
    "BIRTH_DATE": "MAR 28, 1991",
    "EXP": "3",
    "SCHOOL": "Tennessee",
    "name": "Jordan McRae",
    "rosters": [
      "6ac28050-d5f5-4251-8faa-6c3ccee02978",
      "6631c074-5c99-48db-b77d-c92e855be5bd",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "203897": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "MAR 10, 1995",
    "EXP": "2",
    "SCHOOL": "UCLA",
    "name": "Zach LaVine",
    "rosters": [
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e"
    ]
  },
  "203898": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "194",
    "BIRTH_DATE": "AUG 24, 1994",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Tyler Ennis",
    "rosters": [
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c"
    ]
  },
  "203900": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 29, 1992",
    "EXP": "1",
    "SCHOOL": "Oklahoma State",
    "name": "Markel Brown",
    "rosters": [
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b"
    ]
  },
  "203901": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 22, 1994",
    "EXP": "3",
    "SCHOOL": "Louisana-Lafayette",
    "name": "Elfrid Payton",
    "rosters": [
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89",
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae"
    ]
  },
  "203902": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 01, 1992",
    "EXP": "R",
    "SCHOOL": "Xavier",
    "name": "Semaj Christon",
    "rosters": [
      "0e161bfd-52f6-41c1-ba70-177db3f7526c"
    ]
  },
  "203903": {
    "NUM": "00",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "194",
    "BIRTH_DATE": "JUN 07, 1992",
    "EXP": "5",
    "SCHOOL": "Missouri",
    "name": "Jordan Clarkson",
    "rosters": [
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "203906": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 21, 1992",
    "EXP": "1",
    "SCHOOL": "Iowa",
    "name": "Devyn Marble",
    "rosters": [
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "ef7374a1-417b-4cf4-b556-208b918b32d6"
    ]
  },
  "203909": {
    "NUM": "14",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 09, 1993",
    "EXP": "2",
    "SCHOOL": "Clemson",
    "name": "KJ McDaniels",
    "rosters": [
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18"
    ]
  },
  "203910": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "202",
    "BIRTH_DATE": "DEC 22, 1992",
    "EXP": "R",
    "SCHOOL": "Arizona",
    "name": "Nick Johnson",
    "rosters": [
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee"
    ]
  },
  "203912": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 30, 1990",
    "EXP": "3",
    "SCHOOL": "Washington",
    "name": "C.J. Wilcox",
    "rosters": [
      "0a28b192-f961-4a95-bbaa-b9a12af736d3",
      "28013d64-7f98-45fb-bdf8-b76b92f39380",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d"
    ]
  },
  "203913": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "214",
    "BIRTH_DATE": "DEC 18, 1992",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Jabari Brown",
    "rosters": [
      "b462e94b-f32c-4a5a-99c9-5e5188884419"
    ]
  },
  "203914": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "SEP 14, 1994",
    "EXP": "5",
    "SCHOOL": "Michigan State",
    "name": "Gary Harris",
    "rosters": [
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "dbd3455e-60ba-4d50-9b96-353f912b371a"
    ]
  },
  "203915": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "APR 06, 1993",
    "EXP": "1",
    "SCHOOL": "Colorado",
    "name": "Spencer Dinwiddie",
    "rosters": [
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "206a57a6-ccc5-413b-a413-5deab8b03a78",
      "d7e0c0c0-0c6e-40c3-ae4f-e7eb087b3ff7",
      "fbff0712-5fed-4e22-8afa-30387f9f0753"
    ]
  },
  "203917": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 07, 1993",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Nik Stauskas",
    "rosters": [
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "3aadb71f-93d3-4f2e-826e-a896f5df554c"
    ]
  },
  "203918": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "206",
    "BIRTH_DATE": "OCT 20, 1992",
    "EXP": "2",
    "SCHOOL": "Duke",
    "name": "Rodney Hood",
    "rosters": [
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b"
    ]
  },
  "203919": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "209",
    "BIRTH_DATE": "JUL 08, 1994",
    "EXP": "1",
    "SCHOOL": "UCLA",
    "name": "Jordan Adams",
    "rosters": [
      "04bba9e9-1218-49fc-af8a-f49a0a705f00",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42"
    ]
  },
  "203920": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "6-9",
    "WEIGHT": "233",
    "BIRTH_DATE": "SEP 28, 1992",
    "EXP": "2",
    "SCHOOL": "UNLV",
    "name": "Khem Birch",
    "rosters": [
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63"
    ]
  },
  "203921": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 17, 1991",
    "EXP": "1",
    "SCHOOL": "Wichita State",
    "name": "Cleanthony Early",
    "rosters": [
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "f8dc0860-8831-4890-9e99-c896637ea3e3"
    ]
  },
  "203922": {
    "NUM": "22",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "222",
    "BIRTH_DATE": "JAN 08, 1994",
    "EXP": "4",
    "SCHOOL": "Michigan",
    "name": "Glenn Robinson III",
    "rosters": [
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7",
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "203923": {
    "NUM": "13",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 16, 1995",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "James Young",
    "rosters": [
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524"
    ]
  },
  "203924": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 12, 1994",
    "EXP": "4",
    "SCHOOL": "Syracuse",
    "name": "Jerami Grant",
    "rosters": [
      "dbd3455e-60ba-4d50-9b96-353f912b371a",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c",
      "77e6e4de-42ee-4c42-be39-b046215914f2",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199"
    ]
  },
  "203925": {
    "NUM": "12",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 06, 1991",
    "EXP": "5",
    "SCHOOL": "Virginia",
    "name": "Joe Harris",
    "rosters": [
      "62fd3fcb-2ff5-4a03-8199-d6d22e7f5592",
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "203926": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "219",
    "BIRTH_DATE": "JAN 03, 1992",
    "EXP": "2",
    "SCHOOL": "Creighton",
    "name": "Doug McDermott",
    "rosters": [
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "58b309fd-0cb6-4071-8912-360baeb068ca",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c"
    ]
  },
  "203928": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "218",
    "BIRTH_DATE": "DEC 26, 1990",
    "EXP": "R",
    "SCHOOL": "Baylor",
    "name": "Cory Jefferson",
    "rosters": [
      "428cfee4-0a6d-4eab-a1d0-c3b9af80e6c7"
    ]
  },
  "203930": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "219",
    "BIRTH_DATE": "JAN 06, 1990",
    "EXP": "2",
    "SCHOOL": "Cincinnati",
    "name": "Sean Kilpatrick",
    "rosters": [
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18"
    ]
  },
  "203932": {
    "NUM": "00",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 16, 1995",
    "EXP": "5",
    "SCHOOL": "Arizona",
    "name": "Aaron Gordon",
    "rosters": [
      "a77872d3-628b-475d-95a3-5a07cf809667",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63"
    ]
  },
  "203933": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 05, 1993",
    "EXP": "4",
    "SCHOOL": "North Carolina State",
    "name": "T.J. Warren",
    "rosters": [
      "58b309fd-0cb6-4071-8912-360baeb068ca",
      "27be790c-5dc5-4dc6-aeb1-46c90a8d1882",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae",
      "f6d5681a-88c5-4a65-856a-0362e1012713"
    ]
  },
  "203934": {
    "NUM": "13",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "AUG 12, 1991",
    "EXP": "R",
    "SCHOOL": "Pittsburgh",
    "name": "Lamar Patterson",
    "rosters": [
      "e024d958-351f-40c8-be44-4c56111346b2"
    ]
  },
  "203935": {
    "NUM": "36",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 06, 1994",
    "EXP": "5",
    "SCHOOL": "Oklahoma State",
    "name": "Marcus Smart",
    "rosters": [
      "783a47da-3162-4403-8a4a-5cd40c5b642b",
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "203937": {
    "NUM": "1",
    "POSITION": "F-G",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 20, 1993",
    "EXP": "5",
    "SCHOOL": "UCLA",
    "name": "Kyle Anderson",
    "rosters": [
      "76edbc4f-6dd2-4da2-8392-ed0d57db862a",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "60ef2ec8-e872-4112-b28c-8e665c403fba",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "203939": {
    "NUM": "7",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 20, 1991",
    "EXP": "5",
    "SCHOOL": "Stanford",
    "name": "Dwight Powell",
    "rosters": [
      "d78fca6a-85ea-4d8f-ad24-43938ce73ff8",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32"
    ]
  },
  "203940": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "237",
    "BIRTH_DATE": "FEB 19, 1991",
    "EXP": "2",
    "SCHOOL": "Michigan State",
    "name": "Adreian Payne",
    "rosters": [
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e"
    ]
  },
  "203943": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 24, 1995",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Noah Vonleh",
    "rosters": [
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "dbd3455e-60ba-4d50-9b96-353f912b371a",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "34b5ca1c-2ef8-4e94-b64b-10030f65c864"
    ]
  },
  "203944": {
    "NUM": "30",
    "POSITION": "F-C",
    "HEIGHT": "6-8",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 29, 1994",
    "EXP": "5",
    "SCHOOL": "Kentucky",
    "name": "Julius Randle",
    "rosters": [
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "203946": {
    "NUM": "41",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "DEC 07, 1990",
    "EXP": "1",
    "SCHOOL": "New Mexico",
    "name": "Cameron Bairstow",
    "rosters": [
      "9a0ff6b8-2d93-4e02-8176-e3dbd9343406",
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "fbff0712-5fed-4e22-8afa-30387f9f0753"
    ]
  },
  "203948": {
    "NUM": "8",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "257",
    "BIRTH_DATE": "JUN 01, 1993",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Johnny O'Bryant III",
    "rosters": [
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e"
    ]
  },
  "203949": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 04, 1993",
    "EXP": "2",
    "SCHOOL": "North Carolina",
    "name": "James Michael McAdoo",
    "rosters": [
      "3be5e5e4-3153-4f95-920a-943fe574edb9",
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "815c2c83-c122-4c09-ab9b-0807af4014bd"
    ]
  },
  "203950": {
    "NUM": "1",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "263",
    "BIRTH_DATE": "JAN 07, 1994",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Jarnell Stokes",
    "rosters": [
      "04bba9e9-1218-49fc-af8a-f49a0a705f00"
    ]
  },
  "203952": {
    "NUM": "22",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "194",
    "BIRTH_DATE": "FEB 23, 1995",
    "EXP": "4",
    "SCHOOL": "Kansas",
    "name": "Andrew Wiggins",
    "rosters": [
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e",
      "0bdf6350-c69b-4b66-b2f9-a35d44294191",
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f"
    ]
  },
  "203953": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAR 15, 1995",
    "EXP": "4",
    "SCHOOL": "Duke",
    "name": "Jabari Parker",
    "rosters": [
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac",
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "203954": {
    "NUM": "21",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "280",
    "BIRTH_DATE": "MAR 16, 1994",
    "EXP": "3",
    "SCHOOL": "Kansas",
    "name": "Joel Embiid",
    "rosters": [
      "23894f15-3411-4056-9e26-0a374667fc93",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "203955": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "165",
    "BIRTH_DATE": "AUG 11, 1992",
    "EXP": "1",
    "SCHOOL": "Providence",
    "name": "Bryce Cotton",
    "rosters": [
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42"
    ]
  },
  "203956": {
    "NUM": "33",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUN 06, 1992",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "Mitch McGary",
    "rosters": [
      "40187213-bcbb-43d1-a283-a7682ab728e0",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59"
    ]
  },
  "203957": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 13, 1995",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Dante Exum",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "203960": {
    "NUM": "29",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "207",
    "BIRTH_DATE": "MAR 20, 1993",
    "EXP": "2",
    "SCHOOL": "St. John's, N.Y.",
    "name": "JaKarr Sampson",
    "rosters": [
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "58b309fd-0cb6-4071-8912-360baeb068ca",
      "23894f15-3411-4056-9e26-0a374667fc93",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0"
    ]
  },
  "203961": {
    "NUM": "24",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "238",
    "BIRTH_DATE": "DEC 24, 1991",
    "EXP": "2",
    "SCHOOL": "Oregon State",
    "name": "Eric Moreland",
    "rosters": [
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "45324799-5d41-45eb-b5ff-b3095f173c43"
    ]
  },
  "203962": {
    "NUM": "34",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 19, 1991",
    "EXP": "2",
    "SCHOOL": "Stanford",
    "name": "Josh Huestis",
    "rosters": [
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c",
      "77e6e4de-42ee-4c42-be39-b046215914f2"
    ]
  },
  "203963": {
    "NUM": "42",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAR 27, 1991",
    "EXP": "1",
    "SCHOOL": "Western Michigan",
    "name": "Shayne Whittington",
    "rosters": [
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3",
      "4a8979df-b35b-47d6-acbf-06105e98a732"
    ]
  },
  "203967": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 08, 1994",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Dario Saric",
    "rosters": [
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "203991": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 18, 1994",
    "EXP": "4",
    "SCHOOL": "Switzerland",
    "name": "Clint Capela",
    "rosters": [
      "e8169801-0d82-448f-b1b7-6fb64e81aac6",
      "0ea1110c-eb77-4c6e-bb8d-946ed87fb0ee",
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "7555fde4-17ee-408f-a2cb-c48414c8073d"
    ]
  },
  "203992": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "AUG 18, 1992",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Bogdan Bogdanovic",
    "rosters": [
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "203994": {
    "NUM": "27",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "290",
    "BIRTH_DATE": "AUG 23, 1994",
    "EXP": "5",
    "SCHOOL": " ",
    "name": "Jusuf Nurkic",
    "rosters": [
      "ff2978b8-16ba-4686-ba9b-e3ae8c294d7f",
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "203996": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "246",
    "BIRTH_DATE": "MAY 20, 1995",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Damien Inglis",
    "rosters": [
      "d35292b8-6161-46c5-938c-e771e7bac15a",
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c"
    ]
  },
  "203998": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "218",
    "BIRTH_DATE": "SEP 21, 1995",
    "EXP": "4",
    "SCHOOL": "Brazil",
    "name": "Bruno Caboclo",
    "rosters": [
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "b6b65341-6d36-4ee7-82e6-8fd5bdec8d0a",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "203999": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "284",
    "BIRTH_DATE": "FEB 19, 1995",
    "EXP": "4",
    "SCHOOL": " ",
    "name": "Nikola Jokic",
    "rosters": [
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "dbd3455e-60ba-4d50-9b96-353f912b371a"
    ]
  },
  "204001": {
    "NUM": "6",
    "POSITION": "F-C",
    "HEIGHT": "7-3",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 02, 1995",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Kristaps Porzingis",
    "rosters": [
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "62811488-fdf8-4824-9860-e73dfc40e088"
    ]
  },
  "204002": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "265",
    "BIRTH_DATE": "MAR 22, 1992",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Edy Tavares",
    "rosters": [
      "e024d958-351f-40c8-be44-4c56111346b2",
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071"
    ]
  },
  "204014": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 17, 1986",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Damjan Rudez",
    "rosters": [
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "e51ac3aa-e071-4c99-a480-5254e51cb1d3"
    ]
  },
  "204020": {
    "NUM": "16",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 07, 1992",
    "EXP": "4",
    "SCHOOL": "Fresno State",
    "name": "Tyler Johnson",
    "rosters": [
      "c5b89585-a505-4afc-a546-0291ba751b57",
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "f6d5681a-88c5-4a65-856a-0362e1012713"
    ]
  },
  "204022": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "246",
    "BIRTH_DATE": "APR 12, 1991",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Jack Cooley",
    "rosters": [
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "15bd6cda-c4f0-4c22-9921-d720a0b70509"
    ]
  },
  "204025": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "170",
    "BIRTH_DATE": "NOV 01, 1990",
    "EXP": "3",
    "SCHOOL": "Penn State",
    "name": "Tim Frazier",
    "rosters": [
      "a05d79f4-ff84-446b-ba4e-bdcf17064a00",
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "496ae576-436a-46a6-94da-38f07f54a41b",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad"
    ]
  },
  "204028": {
    "NUM": "28",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 22, 1991",
    "EXP": "2",
    "SCHOOL": "Kansas",
    "name": "Tarik Black",
    "rosters": [
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d",
      "b462e94b-f32c-4a5a-99c9-5e5188884419",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a"
    ]
  },
  "204037": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 21, 1990",
    "EXP": "R",
    "SCHOOL": "UCLA",
    "name": "Travis Wear",
    "rosters": [
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "490624e3-33f6-49ad-b637-d7afef77804f"
    ]
  },
  "204038": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 09, 1991",
    "EXP": "5",
    "SCHOOL": " ",
    "name": "Langston Galloway",
    "rosters": [
      "490624e3-33f6-49ad-b637-d7afef77804f",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "45324799-5d41-45eb-b5ff-b3095f173c43",
      "17ec0980-7e1f-455e-a186-b7156367018b",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "204054": {
    "NUM": "12",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 22, 1989",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Zoran Dragic",
    "rosters": [
      "c5b89585-a505-4afc-a546-0291ba751b57"
    ]
  },
  "204060": {
    "NUM": "2",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 02, 1987",
    "EXP": "5",
    "SCHOOL": " ",
    "name": "Joe Ingles",
    "rosters": [
      "15bd6cda-c4f0-4c22-9921-d720a0b70509",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "204065": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "165",
    "BIRTH_DATE": "JUN 24, 1991",
    "EXP": "1",
    "SCHOOL": "Gonzaga",
    "name": "David Stockton",
    "rosters": [
      "3aadb71f-93d3-4f2e-826e-a896f5df554c",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10"
    ]
  },
  "204066": {
    "NUM": "30",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 06, 1988",
    "EXP": "R",
    "SCHOOL": "Boston U.",
    "name": "John Holland",
    "rosters": [
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27"
    ]
  },
  "204067": {
    "NUM": "8",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "OCT 30, 1991",
    "EXP": "R",
    "SCHOOL": "Virginia Tech",
    "name": "Jarell Eddie",
    "rosters": [
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "d5add497-b2c3-4523-b2ef-584c47479c7d"
    ]
  },
  "204098": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUN 01, 1992",
    "EXP": "R",
    "SCHOOL": "Rhode Island",
    "name": "Xavier Munford",
    "rosters": [
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42"
    ]
  },
  "204456": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 25, 1992",
    "EXP": "3",
    "SCHOOL": "Arizona",
    "name": "T.J. McConnell",
    "rosters": [
      "58b309fd-0cb6-4071-8912-360baeb068ca",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "5a728193-2607-49fa-b4d1-2298f8044312"
    ]
  },
  "600001": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "JUL 25, 1941",
    "EXP": "10",
    "SCHOOL": "Bowling Green",
    "name": "Nate Thurmond",
    "rosters": [
      "1a7d40c1-d265-4c85-ad2f-d5386aca9558",
      "66953847-11ae-490d-ba1c-76473d320167",
      "18b07887-8de0-4728-924b-f6f6bca06f1d",
      "d0e9da40-3a3c-49b0-848e-a6f97e7ec6ad",
      "6530f527-58ae-467d-8504-53e63dd6c84e",
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "46106409-18d2-4f10-9158-50537c42c5f5",
      "c6b73aca-ce2e-47a8-8a18-ffe6c2c01a1e",
      "19c148e8-3989-4f5a-8bdd-b388c4a1defe",
      "852d8746-a465-41f3-b644-085c99bbad27",
      "0f779b88-44c7-47eb-84fd-fd9043bbd2cd",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864"
    ]
  },
  "600003": {
    "NUM": "19",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "175",
    "BIRTH_DATE": "AUG 09, 1928",
    "EXP": "13",
    "SCHOOL": "Holy Cross",
    "name": "Bob Cousy",
    "rosters": [
      "cce1fe22-30c3-41a1-96e9-d9cbf78b94d7",
      "3d3cb2ff-25c9-46a7-a0e3-555540b9cddc",
      "388102eb-8ad3-4dde-8091-c1dd42d4df9b",
      "9f27c185-ffd4-46f2-8bdc-af4c63a87f71",
      "0f305d34-33d7-4e16-a669-f7ab442c151d",
      "75b95e75-2397-48c9-b255-676792b7226d",
      "bae9643c-0a73-40b8-aa1d-bb17197c309f",
      "fe5e959f-5c88-4953-a2b0-23b90347849d",
      "d4c935ec-c1d1-41ef-b98b-9feafd69a0f7",
      "dbde9336-2a91-43f4-b1e4-d556d19c14a6",
      "aa143b42-fd39-4478-a383-635e77cdb263",
      "6f8988e1-d339-4b6e-a083-75700b9406ef",
      "540c48fa-176d-4700-a617-fc71b5ac523b",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa"
    ]
  },
  "600005": {
    "NUM": "16",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "SEP 10, 1948",
    "EXP": "9",
    "SCHOOL": "St. Bonaventure",
    "name": "Bob Lanier",
    "rosters": [
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "e7850597-6081-4332-9f69-ba361aaaa0bc",
      "48d933d7-4450-441c-8ef1-2d743521a134",
      "ffa6228e-39da-4145-8319-41a17387cede",
      "3e022c30-7d7f-4ede-91a9-ccf9727e34c9",
      "652a2a9f-cb88-4141-a32c-1b9b3d44a226",
      "5d519571-b461-4e3b-963a-2173662c4b55",
      "1f0e40f0-6d7d-4ef3-a95e-f86255508185",
      "94f36f95-2ce4-4f55-8a83-19b910b11438",
      "e8407b6f-093b-43a2-ac16-f610ffc2510b",
      "553ffb4a-c13e-4629-9598-f1f64182ce7f",
      "81d0ec32-6a4c-4f7e-8fb1-c1a5d17608b3",
      "d3bb5e75-5b3f-4ca3-a5fe-cd351086f689",
      "46b6d84f-cfa5-4bec-b169-b870c218e89b"
    ]
  },
  "600006": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 21, 1944",
    "EXP": "4",
    "SCHOOL": "Winston-Salem State",
    "name": "Earl Monroe",
    "rosters": [
      "096c68b6-fffe-4947-b2cc-873274da6b3b",
      "98a19076-a6e8-4bc0-b991-e249597a93b2",
      "95ba34ae-5f31-43cb-847c-c1a2a9782aaa",
      "52649402-55a4-4d97-a5fd-f7cf9779a672",
      "cf09c412-e6a0-40e9-bffe-8c9810d57c4c",
      "92a4fe2d-0b2a-433e-9c8a-aa51656713eb",
      "b001782c-95ad-444b-814b-98ecf23fa9ce",
      "8bc6e000-7cda-4b92-8998-a07f5c80c68d",
      "67287c3d-6ead-43c1-8eff-47519e8bbeea",
      "ddf45ab5-dc0d-42fc-8207-30146fcaf938",
      "0403c301-445c-4c5d-8144-e5805bdc801c",
      "ab91fee8-d6df-4549-ad42-04456017afc3",
      "d2dca6b7-5bc2-46e3-8d86-2a2d4e308ab3"
    ]
  },
  "600009": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "175",
    "BIRTH_DATE": "JUN 12, 1958",
    "EXP": "10",
    "SCHOOL": "Villanova",
    "name": "Rory Sparrow",
    "rosters": [
      "217eb2d7-0d13-4b6e-bb3c-787afa52edd5",
      "bd1683be-ac22-4089-812f-a0cad11f532c",
      "c3b8e442-4b20-42f1-99ae-03e014396f4a",
      "652536cb-d53b-4de7-a147-555aacb21048",
      "57881dc6-8022-4f8e-b510-97723b8c870e",
      "30f87621-72fd-4d91-987d-50cce0bf1714",
      "6a10cc0f-c034-4cee-992e-4023a7280654",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "edf9e3a0-e760-40c6-b84c-fbc4efa6a152",
      "6a0aae0f-b683-4a88-a7a2-8e88ea1754aa",
      "b2182580-6b74-4d01-905a-961e5a283423",
      "57c1be08-60cf-40ad-a097-a95ef9958532"
    ]
  },
  "600010": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 28, 1952",
    "EXP": "3",
    "SCHOOL": "Maryland",
    "name": "Len Elmore",
    "rosters": [
      "3fa351f6-06f4-4879-80b9-9ec7b8bc8778",
      "c70377d9-fc43-426e-bc2c-9ccc5031ef2e",
      "180d7461-2299-4b8c-9aef-267bf4d09d28",
      "c6d6c649-e897-4dc0-8a1d-78fd1a096757",
      "d2f4a19d-2dc4-4849-a134-4dd576dade5d",
      "758e5736-0f3d-4311-9782-923d1ff9cbc0",
      "b53d3c18-a4f1-40bf-a266-2555fa606018",
      "1df46296-cc39-4688-95c6-3656430e4ae2"
    ]
  },
  "600011": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 22, 1947",
    "EXP": "5",
    "SCHOOL": "Morehead State",
    "name": "Lamar Green",
    "rosters": [
      "c5964149-f86c-413e-9d7c-c8d07f4113ef",
      "f5458f44-ac3e-4c53-a59d-73946dd09d03",
      "5ea3e1f0-4c1b-4bdc-8031-06913aa4740e",
      "0e6fb679-f25a-4aa3-a8cb-a9c0118b1f1c",
      "bd877b88-34bc-4231-95a1-6063a90eca41",
      "cf18926c-add0-4350-9f9c-f87df758e703"
    ]
  },
  "600012": {
    "NUM": "99",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 18, 1924",
    "EXP": "6",
    "SCHOOL": "DePaul",
    "name": "George Mikan",
    "rosters": [
      "7a6c7aa0-cbb0-4bc2-ac43-2ca30e6d4503",
      "4ebc71fe-d377-4e13-8b8b-b6511e23e4d9",
      "0c2ad895-0fc8-49c0-9976-48e929a0c370",
      "56568a86-b30e-48a7-9f7d-80f79aa00339",
      "896b2d35-cd68-45f6-8ed3-70cf67383fa2",
      "1b383d65-6ca5-46ca-b345-acf8e129e382",
      "156bc9fb-9be0-4d47-ae6c-e255ee721988"
    ]
  },
  "600013": {
    "NUM": "2-4",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 28, 1944",
    "EXP": "9",
    "SCHOOL": "Miami (FL)",
    "name": "Rick Barry",
    "rosters": [
      "754f9d27-7573-442d-8f17-5aeb6cb61e54",
      "ef08786a-55dc-4d6e-9bbf-ebfd76018f9b",
      "ecb02e00-4b70-45b1-b216-cabefbc881e4",
      "6f1b3ca5-9585-4d10-afb2-d34d80dca864",
      "c1880d6b-19f4-45d3-9d7e-564fe009bff1",
      "edf26e64-d881-43d8-a1c3-c5951c5cf796",
      "a41a6363-1cad-4f4f-9d67-2ab15fc75444",
      "79486fdf-1032-41a7-b0fd-7bc9c309f79e",
      "766b4adc-1011-4526-90b5-c29ba8adc757",
      "08b96232-bec8-4c70-a55c-7f13e94a9137"
    ]
  },
  "600014": {
    "NUM": "53",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 21, 1948",
    "EXP": "10",
    "SCHOOL": "Jacksonville",
    "name": "Artis Gilmore",
    "rosters": [
      "70d3bb18-8c77-4118-8c4e-a198d7f4ed83",
      "c3238288-bce4-48df-bf77-11f4a2fd274e",
      "45875a9e-79f0-4bd3-a0e1-dfdd4031e8b7",
      "87958611-d920-4ef5-b85e-78dafe9cbf1d",
      "7065fada-dca9-420f-9f3e-14c3d4112b09",
      "e0fd7afa-adb6-438b-b16f-331254cbf1f6",
      "3dcf18d7-adb9-4f4d-a81d-24e5d45110fe",
      "cc0330a4-e337-4c24-a4bb-37c8de5dc661",
      "f6fa2351-5b01-46de-99f2-98bc7a6dcbf2",
      "5c09ec26-5505-4508-98c5-db244798d9d1",
      "d49c80b8-af47-4f5b-9e7f-f3fa32e3c8e8",
      "1c2a533a-883a-4690-a94e-edebbb2ec9bd"
    ]
  },
  "600015": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 24, 1938",
    "EXP": "9",
    "SCHOOL": "Cincinnati",
    "name": "Oscar Robertson",
    "rosters": [
      "30c679a6-4b92-42bc-b03d-303894f5aed9",
      "100e1bc9-a0c1-46cd-9361-42b784f75cf6",
      "7dafa3ad-5454-4b95-a524-c76cf80fe558",
      "75e6490e-9bf2-4901-8762-6daf8eec1606",
      "d7b5f409-d638-4a98-a3ad-ecf937bae6ce",
      "020d6aa2-861a-4e30-8595-c38b6904fb12",
      "855d0133-3349-470d-9362-7617b3f72e7f",
      "2645a4da-5c26-4f80-8c7a-2490722103ad",
      "4eff069e-7043-4696-b69b-76eb51998133",
      "4321cda2-7706-48e9-be7e-e1ae7595f2ef",
      "38c6a8b5-9c04-429f-9873-476923c33722",
      "b630b545-ede0-408a-a797-133d5fdeaa10",
      "e8c1fb6f-a628-4bbf-bda3-873d7c698604",
      "69c1ae9b-9539-4b62-ac92-7c0085dce1aa"
    ]
  },
  "600016": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "232",
    "BIRTH_DATE": "JUN 17, 1933",
    "EXP": "2",
    "SCHOOL": "Saint Francis (PA)",
    "name": "Maurice Stokes",
    "rosters": [
      "516c9243-1276-46d0-8dbe-f1db0a8384ad",
      "53b991a3-a4e4-4ebd-82fc-e1dd183ea44b",
      "21936ffd-2bd4-4505-a647-b4cd447d157b"
    ]
  },
  "1626122": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 01, 1900",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Makhtar Ndiaye",
    "rosters": [
      "fc8d0225-3f5d-4f86-881e-de5501b3eba7"
    ]
  },
  "1626143": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "275",
    "BIRTH_DATE": "DEC 15, 1995",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Jahlil Okafor",
    "rosters": [
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "1b10411f-c8db-4154-9db0-1900f8577a91",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2"
    ]
  },
  "1626144": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 05, 1996",
    "EXP": "4",
    "SCHOOL": " ",
    "name": "Emmanuel Mudiay",
    "rosters": [
      "8b352059-4d32-4f24-b88d-cfa95665790a",
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1626145": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "196",
    "BIRTH_DATE": "MAY 10, 1996",
    "EXP": "4",
    "SCHOOL": "Duke",
    "name": "Tyus Jones",
    "rosters": [
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1626146": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 16, 1995",
    "EXP": "R",
    "SCHOOL": "Kansas",
    "name": "Cliff Alexander",
    "rosters": [
      "cbb4c930-842b-4d16-a892-29ffd4897a48"
    ]
  },
  "1626147": {
    "NUM": "1",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "230",
    "BIRTH_DATE": "NOV 19, 1993",
    "EXP": "2",
    "SCHOOL": "Virginia",
    "name": "Justin Anderson",
    "rosters": [
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0"
    ]
  },
  "1626148": {
    "NUM": "3",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "224",
    "BIRTH_DATE": "OCT 10, 1992",
    "EXP": "2",
    "SCHOOL": "Stanford",
    "name": "Anthony Brown",
    "rosters": [
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198"
    ]
  },
  "1626149": {
    "NUM": "5",
    "POSITION": "F-C",
    "HEIGHT": "6-7",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 26, 1994",
    "EXP": "4",
    "SCHOOL": "Louisville",
    "name": "Montrezl Harrell",
    "rosters": [
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697"
    ]
  },
  "1626150": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "213",
    "BIRTH_DATE": "OCT 28, 1994",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Andrew Harrison",
    "rosters": [
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "1626151": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 28, 1994",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Aaron Harrison",
    "rosters": [
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "31164298-2c0d-4a44-b557-e740801b4b1d"
    ]
  },
  "1626153": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "183",
    "BIRTH_DATE": "APR 26, 1992",
    "EXP": "3",
    "SCHOOL": "Utah",
    "name": "Delon Wright",
    "rosters": [
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "1626154": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "OCT 24, 1993",
    "EXP": "2",
    "SCHOOL": "Georgia State",
    "name": "RJ Hunter",
    "rosters": [
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d"
    ]
  },
  "1626155": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 06, 1994",
    "EXP": "3",
    "SCHOOL": "Wisconsin",
    "name": "Sam Dekker",
    "rosters": [
      "887509b8-d63d-40e8-8a65-4a266146bdb2",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "1626156": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "198",
    "BIRTH_DATE": "FEB 23, 1996",
    "EXP": "3",
    "SCHOOL": "Ohio State",
    "name": "D'Angelo Russell",
    "rosters": [
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e"
    ]
  },
  "1626157": {
    "NUM": "32",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "248",
    "BIRTH_DATE": "NOV 15, 1995",
    "EXP": "4",
    "SCHOOL": "Kentucky",
    "name": "Karl-Anthony Towns",
    "rosters": [
      "d6b9a95f-5c95-4743-9999-f4582523ddc0",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e",
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1626158": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 15, 1993",
    "EXP": "4",
    "SCHOOL": "Bowling Green",
    "name": "Richaun Holmes",
    "rosters": [
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "1626159": {
    "NUM": "7",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "222",
    "BIRTH_DATE": "MAR 26, 1996",
    "EXP": "4",
    "SCHOOL": "Duke",
    "name": "Justise Winslow",
    "rosters": [
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1626161": {
    "NUM": "00",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 18, 1993",
    "EXP": "3",
    "SCHOOL": "Kentucky",
    "name": "Willie Cauley-Stein",
    "rosters": [
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "34a215f4-c672-4508-a765-ed7809c0e939",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "a33db084-b2b6-45ed-8752-743576aa1a5d"
    ]
  },
  "1626162": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "DEC 09, 1995",
    "EXP": "2",
    "SCHOOL": "Kansas",
    "name": "Kelly Oubre Jr.",
    "rosters": [
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "fdf322f1-9f93-4535-913c-53228db4b4fb",
      "d5add497-b2c3-4523-b2ef-584c47479c7d",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad"
    ]
  },
  "1626163": {
    "NUM": "44",
    "POSITION": "F-C",
    "HEIGHT": "7-0",
    "WEIGHT": "242",
    "BIRTH_DATE": "APR 04, 1993",
    "EXP": "3",
    "SCHOOL": "Wisconsin",
    "name": "Frank Kaminsky",
    "rosters": [
      "fdf322f1-9f93-4535-913c-53228db4b4fb",
      "31164298-2c0d-4a44-b557-e740801b4b1d",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79"
    ]
  },
  "1626164": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "206",
    "BIRTH_DATE": "OCT 30, 1996",
    "EXP": "4",
    "SCHOOL": "Kentucky",
    "name": "Devin Booker",
    "rosters": [
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae",
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1626166": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "AUG 08, 1994",
    "EXP": "R",
    "SCHOOL": "Murray State",
    "name": "Cameron Payne",
    "rosters": [
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "d8d5355f-4f4e-4c00-9a9c-7d700c555d59"
    ]
  },
  "1626167": {
    "NUM": "33",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAR 24, 1996",
    "EXP": "4",
    "SCHOOL": "Texas",
    "name": "Myles Turner",
    "rosters": [
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "58b309fd-0cb6-4071-8912-360baeb068ca"
    ]
  },
  "1626168": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "234",
    "BIRTH_DATE": "NOV 05, 1995",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Trey Lyles",
    "rosters": [
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e",
      "8443b84f-b107-4647-9a68-51bc436e7c61",
      "272b357c-17be-4ce8-acf5-24791690c53b"
    ]
  },
  "1626169": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "245",
    "BIRTH_DATE": "MAY 29, 1996",
    "EXP": "2",
    "SCHOOL": "Arizona",
    "name": "Stanley Johnson",
    "rosters": [
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e",
      "45324799-5d41-45eb-b5ff-b3095f173c43"
    ]
  },
  "1626170": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 09, 1992",
    "EXP": "3",
    "SCHOOL": "Notre Dame",
    "name": "Jerian Grant",
    "rosters": [
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "f8dc0860-8831-4890-9e99-c896637ea3e3",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9"
    ]
  },
  "1626171": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "FEB 10, 1995",
    "EXP": "3",
    "SCHOOL": "Arkansas",
    "name": "Bobby Portis",
    "rosters": [
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89",
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "1626172": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "222",
    "BIRTH_DATE": "FEB 06, 1996",
    "EXP": "4",
    "SCHOOL": "UCLA",
    "name": "Kevon Looney",
    "rosters": [
      "db39856a-8bb1-4ea4-9ef1-1e99ff028d42",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "1626173": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "202",
    "BIRTH_DATE": "AUG 16, 1996",
    "EXP": "1",
    "SCHOOL": "Nevada-Las Vegas",
    "name": "Rashad Vaughn",
    "rosters": [
      "3f5caa17-b7cc-45f6-a19b-f85149a5e28c",
      "f08ff061-361a-4172-9765-ffea895a1265"
    ]
  },
  "1626174": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 27, 1995",
    "EXP": "1",
    "SCHOOL": "UNLV",
    "name": "Christian Wood",
    "rosters": [
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "b4ff765f-4502-4299-a2e5-383cd5889dea",
      "de2d945e-04d3-4b3c-aba1-385a321827ad",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e"
    ]
  },
  "1626175": {
    "NUM": "25",
    "POSITION": "C-F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUL 09, 1994",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Jordan Mickey",
    "rosters": [
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "64494d90-4847-4b7e-817d-270d082d53b1"
    ]
  },
  "1626176": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "DEC 01, 1991",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Rakeem Christmas",
    "rosters": [
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea"
    ]
  },
  "1626177": {
    "NUM": "44",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "SEP 22, 1995",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Dakari Johnson",
    "rosters": [
      "77e6e4de-42ee-4c42-be39-b046215914f2"
    ]
  },
  "1626178": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "217",
    "BIRTH_DATE": "JAN 03, 1995",
    "EXP": "4",
    "SCHOOL": "Arizona",
    "name": "Rondae Hollis-Jefferson",
    "rosters": [
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1626179": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 17, 1994",
    "EXP": "4",
    "SCHOOL": "Louisville",
    "name": "Terry Rozier",
    "rosters": [
      "6df6d771-ac46-4dcf-8c3a-4428995d5b27",
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1626181": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAY 25, 1993",
    "EXP": "4",
    "SCHOOL": "UCLA",
    "name": "Norman Powell",
    "rosters": [
      "5ac68c0d-43c1-490d-87f9-5b431453d831",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1626183": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 01, 1993",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Branden Dawson",
    "rosters": [
      "28013d64-7f98-45fb-bdf8-b76b92f39380"
    ]
  },
  "1626184": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "FEB 05, 1993",
    "EXP": "1",
    "SCHOOL": "Stanford",
    "name": "Chasson Randle",
    "rosters": [
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "1626185": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "239",
    "BIRTH_DATE": "MAY 24, 1994",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Jarell Martin",
    "rosters": [
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "5d5ed08f-254a-4915-8dfd-3bd2d549bb42",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "1626187": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 08, 1994",
    "EXP": "R",
    "SCHOOL": "Florida",
    "name": "Michael Frazier",
    "rosters": [
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9"
    ]
  },
  "1626188": {
    "NUM": "28",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "MAR 23, 1993",
    "EXP": "3",
    "SCHOOL": "Duke",
    "name": "Quinn Cook",
    "rosters": [
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "db38d209-7326-41b8-948f-588f9a050e00"
    ]
  },
  "1626191": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 05, 1995",
    "EXP": "2",
    "SCHOOL": "Syracuse",
    "name": "Chris McCullough",
    "rosters": [
      "648726e0-d700-4ab3-8c10-d5cfa4b7a83b",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6",
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad"
    ]
  },
  "1626192": {
    "NUM": "5",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 06, 1993",
    "EXP": "2",
    "SCHOOL": "Notre Dame",
    "name": "Pat Connaughton",
    "rosters": [
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "cbb4c930-842b-4d16-a892-29ffd4897a48",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d"
    ]
  },
  "1626195": {
    "NUM": "9",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAY 27, 1994",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Willy Hernangomez",
    "rosters": [
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1626196": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 15, 1993",
    "EXP": "4",
    "SCHOOL": "Tennessee",
    "name": "Josh Richardson",
    "rosters": [
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5",
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "1626199": {
    "NUM": "6",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 13, 1993",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Darrun Hilliard",
    "rosters": [
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "fbff0712-5fed-4e22-8afa-30387f9f0753",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e"
    ]
  },
  "1626202": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 27, 1992",
    "EXP": "2",
    "SCHOOL": "Oregon",
    "name": "Joe Young",
    "rosters": [
      "4a8979df-b35b-47d6-acbf-06105e98a732",
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e"
    ]
  },
  "1626203": {
    "NUM": "21",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "219",
    "BIRTH_DATE": "OCT 28, 1993",
    "EXP": "1",
    "SCHOOL": "Va Commonwealth",
    "name": "Treveon Graham",
    "rosters": [
      "e8169801-0d82-448f-b1b7-6fb64e81aac6",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e",
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9"
    ]
  },
  "1626204": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "JAN 01, 1993",
    "EXP": "1",
    "SCHOOL": "Wyoming",
    "name": "Larry Nance Jr.",
    "rosters": [
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce",
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a"
    ]
  },
  "1626209": {
    "NUM": "44",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 25, 1995",
    "EXP": "4",
    "SCHOOL": " ",
    "name": "Mario Hezonja",
    "rosters": [
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "ef7374a1-417b-4cf4-b556-208b918b32d6",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da",
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "1626210": {
    "NUM": "15",
    "POSITION": "F-C",
    "HEIGHT": "6-8",
    "WEIGHT": "265",
    "BIRTH_DATE": "JAN 28, 1993",
    "EXP": "2",
    "SCHOOL": "Cal-Santa Barbara",
    "name": "Alan Williams",
    "rosters": [
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "8a9f50f1-728b-4c59-af7b-f5adfdb1fce1",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae"
    ]
  },
  "1626218": {
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "192",
    "BIRTH_DATE": "MAY 06, 1992",
    "EXP": "R",
    "SCHOOL": "St. John's, N.Y.",
    "name": "Sir'Dominic Pointer",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  },
  "1626220": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-4",
    "WEIGHT": "226",
    "BIRTH_DATE": "JUN 05, 1993",
    "EXP": "2",
    "SCHOOL": "Baylor",
    "name": "Royce O'Neale",
    "rosters": [
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1626224": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 08, 1995",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Cedi Osman",
    "rosters": [
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  },
  "1626242": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 06, 1993",
    "EXP": "R",
    "SCHOOL": "Westchester CC NY",
    "name": "Luis Montero",
    "rosters": [
      "cbb4c930-842b-4d16-a892-29ffd4897a48"
    ]
  },
  "1626245": {
    "NUM": "6",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "270",
    "BIRTH_DATE": "JUL 07, 1992",
    "EXP": "4",
    "SCHOOL": " ",
    "name": "Cristiano Felicio",
    "rosters": [
      "94ccb42e-7317-4c67-a681-708f0dd0f117",
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1626246": {
    "NUM": "51",
    "POSITION": "C",
    "HEIGHT": "7-3",
    "WEIGHT": "290",
    "BIRTH_DATE": "AUG 15, 1988",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Boban Marjanovic",
    "rosters": [
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "513319a3-e55b-4c85-93d5-b45d51fa457e",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e"
    ]
  },
  "1626251": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 04, 1991",
    "EXP": "R",
    "SCHOOL": "Wisconsin",
    "name": "Duje Dukan",
    "rosters": [
      "34a215f4-c672-4508-a765-ed7809c0e939"
    ]
  },
  "1626253": {
    "NUM": "6",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 23, 1992",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Axel Toupane",
    "rosters": [
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "8b352059-4d32-4f24-b88d-cfa95665790a"
    ]
  },
  "1626254": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 18, 1992",
    "EXP": "R",
    "SCHOOL": "Ohio U.",
    "name": "Maurice Ndour",
    "rosters": [
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5"
    ]
  },
  "1626257": {
    "NUM": "50",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "235",
    "BIRTH_DATE": "JUN 15, 1986",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Salah Mejri",
    "rosters": [
      "2e619fa8-fe19-4df4-a834-a6ba21ebf87a",
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "52b4e695-cf53-49a6-8a86-ff4295a423ce"
    ]
  },
  "1626259": {
    "NUM": "13",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 06, 1993",
    "EXP": "2",
    "SCHOOL": "Holy Cross",
    "name": "Malcolm Miller",
    "rosters": [
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1626273": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 25, 1983",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Marcelo Huertas",
    "rosters": [
      "395561f6-a7d9-4329-adf6-0ffd5ff8faed"
    ]
  },
  "1626780": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 08, 1987",
    "EXP": "2",
    "SCHOOL": "New Mexico State",
    "name": "Jonathan Gibson",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a"
    ]
  },
  "1627098": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 11, 1989",
    "EXP": "1",
    "SCHOOL": "Virginia Tech",
    "name": "Malcolm Delaney",
    "rosters": [
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf"
    ]
  },
  "1627215": {
    "NUM": "25",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 26, 1992",
    "EXP": "1",
    "SCHOOL": "Bradley",
    "name": "Walt Lemon Jr.",
    "rosters": [
      "7542de7d-8692-4f6a-a302-a389bb3d20ab"
    ]
  },
  "1627362": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "165",
    "BIRTH_DATE": "DEC 29, 1992",
    "EXP": "1",
    "SCHOOL": "Virginia Commonwealth",
    "name": "Briante Weber",
    "rosters": [
      "f7c9456c-b872-44c5-8aa7-e0abd8e2eea7",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21",
      "4c417b8c-07cf-4c43-a27c-2d30e7fc133e"
    ]
  },
  "1627395": {
    "NUM": "4",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "209",
    "BIRTH_DATE": "DEC 18, 1991",
    "EXP": "R",
    "SCHOOL": "Texas-El Paso",
    "name": "Julian Washburn",
    "rosters": [
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "1627732": {
    "NUM": "25",
    "POSITION": "G-F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 20, 1996",
    "EXP": "2",
    "SCHOOL": "Louisiana State",
    "name": "Ben Simmons",
    "rosters": [
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "1627733": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "7-1",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 17, 1997",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Dragan Bender",
    "rosters": [
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae",
      "f6d5681a-88c5-4a65-856a-0362e1012713"
    ]
  },
  "1627734": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 03, 1996",
    "EXP": "R",
    "SCHOOL": "Gonzaga",
    "name": "Domantas Sabonis",
    "rosters": [
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "58b309fd-0cb6-4071-8912-360baeb068ca",
      "0e161bfd-52f6-41c1-ba70-177db3f7526c"
    ]
  },
  "1627735": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "202",
    "BIRTH_DATE": "MAR 29, 1996",
    "EXP": "R",
    "SCHOOL": "Vanderbilt",
    "name": "Wade Baldwin IV",
    "rosters": [
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d"
    ]
  },
  "1627736": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "187",
    "BIRTH_DATE": "NOV 26, 1996",
    "EXP": "3",
    "SCHOOL": "Florida State",
    "name": "Malik Beasley",
    "rosters": [
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1627737": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 02, 1997",
    "EXP": "1",
    "SCHOOL": "Washington",
    "name": "Marquese Chriss",
    "rosters": [
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e",
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae"
    ]
  },
  "1627738": {
    "NUM": "21",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "237",
    "BIRTH_DATE": "DEC 02, 1996",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Deyonta Davis",
    "rosters": [
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "1627739": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 18, 1994",
    "EXP": "R",
    "SCHOOL": "Providence",
    "name": "Kris Dunn",
    "rosters": [
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc",
      "f5d1f46a-b453-44f3-8ec7-88d388748e5e"
    ]
  },
  "1627740": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "JAN 13, 1997",
    "EXP": "1",
    "SCHOOL": "Marquette",
    "name": "Henry Ellenson",
    "rosters": [
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e",
      "45324799-5d41-45eb-b5ff-b3095f173c43"
    ]
  },
  "1627741": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 17, 1992",
    "EXP": "3",
    "SCHOOL": "Oklahoma",
    "name": "Buddy Hield",
    "rosters": [
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "1627742": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 02, 1997",
    "EXP": "2",
    "SCHOOL": "Duke",
    "name": "Brandon Ingram",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e"
    ]
  },
  "1627743": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "201",
    "BIRTH_DATE": "SEP 07, 1994",
    "EXP": "1",
    "SCHOOL": "Notre Dame",
    "name": "Demetrius Jackson",
    "rosters": [
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0"
    ]
  },
  "1627744": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUN 27, 1994",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Brice Johnson",
    "rosters": [
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748"
    ]
  },
  "1627745": {
    "NUM": "15",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUN 30, 1995",
    "EXP": "2",
    "SCHOOL": "Vanderbilt",
    "name": "Damian Jones",
    "rosters": [
      "e8169801-0d82-448f-b1b7-6fb64e81aac6",
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01"
    ]
  },
  "1627746": {
    "NUM": "7",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 18, 1996",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Skal Labissiere",
    "rosters": [
      "e8169801-0d82-448f-b1b7-6fb64e81aac6",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0"
    ]
  },
  "1627747": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "AUG 25, 1994",
    "EXP": "3",
    "SCHOOL": "Michigan",
    "name": "Caris LeVert",
    "rosters": [
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "1627748": {
    "NUM": "7",
    "POSITION": "F-C",
    "HEIGHT": "7-0",
    "WEIGHT": "221",
    "BIRTH_DATE": "FEB 25, 1997",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Thon Maker",
    "rosters": [
      "f08ff061-361a-4172-9765-ffea895a1265",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "17ec0980-7e1f-455e-a186-b7156367018b",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "1627749": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 19, 1996",
    "EXP": "2",
    "SCHOOL": "Washington",
    "name": "Dejounte Murray",
    "rosters": [
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "1627750": {
    "NUM": "27",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "215",
    "BIRTH_DATE": "FEB 23, 1997",
    "EXP": "3",
    "SCHOOL": "Kentucky",
    "name": "Jamal Murray",
    "rosters": [
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "dbd3455e-60ba-4d50-9b96-353f912b371a"
    ]
  },
  "1627751": {
    "NUM": "42",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 15, 1995",
    "EXP": "1",
    "SCHOOL": "Utah",
    "name": "Jakob Poeltl",
    "rosters": [
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e",
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6"
    ]
  },
  "1627752": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "MAR 22, 1994",
    "EXP": "3",
    "SCHOOL": "Baylor",
    "name": "Taurean Prince",
    "rosters": [
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "1627753": {
    "NUM": "9",
    "POSITION": "F-C",
    "HEIGHT": "7-1",
    "WEIGHT": "210",
    "BIRTH_DATE": "JAN 16, 1996",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Zhou Qi",
    "rosters": [
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d"
    ]
  },
  "1627754": {
    "NUM": "0",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "255",
    "BIRTH_DATE": "FEB 10, 1997",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Diamond Stone",
    "rosters": [
      "b0a1c1bf-f6bc-40ac-bcb0-1c2d026eb748"
    ]
  },
  "1627755": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "160",
    "BIRTH_DATE": "JAN 05, 1996",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Tyler Ulis",
    "rosters": [
      "58ed739f-5da8-4431-9d04-f20d9505d73b",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae"
    ]
  },
  "1627756": {
    "NUM": "45",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 16, 1993",
    "EXP": "2",
    "SCHOOL": "Michigan State",
    "name": "Denzel Valentine",
    "rosters": [
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1627757": {
    "NUM": "33",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "SEP 09, 1996",
    "EXP": "R",
    "SCHOOL": "UNLV",
    "name": "Stephen Zimmerman",
    "rosters": [
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da"
    ]
  },
  "1627758": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 30, 1993",
    "EXP": "1",
    "SCHOOL": "Wichita State",
    "name": "Ron Baker",
    "rosters": [
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5",
      "62811488-fdf8-4824-9860-e73dfc40e088"
    ]
  },
  "1627759": {
    "NUM": "7",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "223",
    "BIRTH_DATE": "OCT 24, 1996",
    "EXP": "3",
    "SCHOOL": "California",
    "name": "Jaylen Brown",
    "rosters": [
      "aa07aa32-4898-40ca-bca4-a83009a87524",
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1627761": {
    "NUM": "95",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 04, 1994",
    "EXP": "3",
    "SCHOOL": null,
    "name": "DeAndre' Bembry",
    "rosters": [
      "26c5cccc-f9b4-44f4-b37d-591287106fe7",
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "e8169801-0d82-448f-b1b7-6fb64e81aac6"
    ]
  },
  "1627762": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "JAN 28, 1994",
    "EXP": "R",
    "SCHOOL": "Weber State",
    "name": "Joel Bolomboy",
    "rosters": [
      "272b357c-17be-4ce8-acf5-24791690c53b"
    ]
  },
  "1627763": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "229",
    "BIRTH_DATE": "DEC 11, 1992",
    "EXP": "3",
    "SCHOOL": "Virginia",
    "name": "Malcolm Brogdon",
    "rosters": [
      "f08ff061-361a-4172-9765-ffea895a1265",
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "58b309fd-0cb6-4071-8912-360baeb068ca"
    ]
  },
  "1627767": {
    "NUM": "14",
    "POSITION": "F-C",
    "HEIGHT": "6-8",
    "WEIGHT": "219",
    "BIRTH_DATE": "SEP 13, 1996",
    "EXP": "3",
    "SCHOOL": "Kansas",
    "name": "Cheick Diallo",
    "rosters": [
      "4f609e20-7c46-4dcd-8a73-d44e2c1b11bb",
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1627770": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "5-9",
    "WEIGHT": "176",
    "BIRTH_DATE": "MAR 29, 1995",
    "EXP": "1",
    "SCHOOL": "Oakland",
    "name": "Kay Felder",
    "rosters": [
      "bd07daee-a787-4c9c-bd26-aa7f2f3eb071",
      "45324799-5d41-45eb-b5ff-b3095f173c43"
    ]
  },
  "1627771": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 05, 1992",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Michael Gbinije",
    "rosters": [
      "55ce14d3-4bba-46a5-8e11-ea7e903edf7e"
    ]
  },
  "1627772": {
    "NUM": "25",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 08, 1995",
    "EXP": "R",
    "SCHOOL": "Connecticut",
    "name": "Daniel Hamilton",
    "rosters": [
      "77e6e4de-42ee-4c42-be39-b046215914f2"
    ]
  },
  "1627773": {
    "NUM": "20",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "260",
    "BIRTH_DATE": "AUG 27, 1992",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "AJ Hammons",
    "rosters": [
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668"
    ]
  },
  "1627774": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 07, 1994",
    "EXP": "2",
    "SCHOOL": "Maryland",
    "name": "Jake Layman",
    "rosters": [
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca",
      "bf8f9985-d8ac-46b8-a125-085201212231",
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add"
    ]
  },
  "1627775": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "181",
    "BIRTH_DATE": "OCT 25, 1995",
    "EXP": "3",
    "SCHOOL": "UNLV",
    "name": "Patrick McCaw",
    "rosters": [
      "815c2c83-c122-4c09-ab9b-0807af4014bd",
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1627777": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUN 17, 1993",
    "EXP": "3",
    "SCHOOL": "Iowa State",
    "name": "Georges Niang",
    "rosters": [
      "2107ed47-f77b-4c98-acfc-e2fbe052aaea",
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1627778": {
    "NUM": "21",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "NOV 01, 1996",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Chinanu Onuaku",
    "rosters": [
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d"
    ]
  },
  "1627779": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "164",
    "BIRTH_DATE": "SEP 11, 1993",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Marcus Paige",
    "rosters": [
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9"
    ]
  },
  "1627780": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 01, 1992",
    "EXP": "3",
    "SCHOOL": "Oregon State",
    "name": "Gary Payton II",
    "rosters": [
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "f08ff061-361a-4172-9765-ffea895a1265",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1627781": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 05, 1996",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Malachi Richardson",
    "rosters": [
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6"
    ]
  },
  "1627782": {
    "NUM": "7",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "230",
    "BIRTH_DATE": "SEP 30, 1994",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Wayne Selden",
    "rosters": [
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "16f96fd9-ce6d-48d9-8e7f-abb894b6af6d",
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "1627783": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "APR 02, 1994",
    "EXP": "3",
    "SCHOOL": "New Mexico State",
    "name": "Pascal Siakam",
    "rosters": [
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1627784": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "221",
    "BIRTH_DATE": "MAY 19, 1993",
    "EXP": "R",
    "SCHOOL": "Iowa",
    "name": "Jarrod Uthoff",
    "rosters": [
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668"
    ]
  },
  "1627785": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "213",
    "BIRTH_DATE": "MAR 08, 1995",
    "EXP": "2",
    "SCHOOL": "Seton Hall",
    "name": "Isaiah Whitehead",
    "rosters": [
      "90b7b2c5-144a-47b4-be9d-7c030d7bde18",
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "17ec0980-7e1f-455e-a186-b7156367018b"
    ]
  },
  "1627786": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "218",
    "BIRTH_DATE": "DEC 30, 1994",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "Troy Williams",
    "rosters": [
      "4eca3491-dad7-43db-bf8d-73ff082df4f0",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "a33db084-b2b6-45ed-8752-743576aa1a5d"
    ]
  },
  "1627787": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 20, 1992",
    "EXP": "R",
    "SCHOOL": "Gonzaga",
    "name": "Kyle Wiltjer",
    "rosters": [
      "4eca3491-dad7-43db-bf8d-73ff082df4f0"
    ]
  },
  "1627788": {
    "NUM": "30",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "202",
    "BIRTH_DATE": "JUL 24, 1997",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Furkan Korkmaz",
    "rosters": [
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0",
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "1627789": {
    "NUM": "7",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 09, 1995",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Timothe Luwawu-Cabarrot",
    "rosters": [
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "206a57a6-ccc5-413b-a413-5deab8b03a78",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0"
    ]
  },
  "1627790": {
    "NUM": "41",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "266",
    "BIRTH_DATE": "JAN 04, 1997",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Ante Zizic",
    "rosters": [
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  },
  "1627812": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "178",
    "BIRTH_DATE": "MAY 09, 1993",
    "EXP": "3",
    "SCHOOL": "Indiana",
    "name": "Yogi Ferrell",
    "rosters": [
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "1627814": {
    "NUM": "1",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 21, 1992",
    "EXP": "2",
    "SCHOOL": "Louisville",
    "name": "Damion Lee",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "1627815": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 21, 1992",
    "EXP": "R",
    "SCHOOL": "Miami",
    "name": "Sheldon Mac",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce",
      "be0a6795-9a40-4756-9ab4-11a5554c68a6"
    ]
  },
  "1627816": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "238",
    "BIRTH_DATE": "SEP 06, 1993",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Alex Poythress",
    "rosters": [
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "ebfee957-1808-4754-a8dc-3fafb2017bf2"
    ]
  },
  "1627817": {
    "NUM": "55",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 27, 1994",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Tim Quarterman",
    "rosters": [
      "bf8f9985-d8ac-46b8-a125-085201212231"
    ]
  },
  "1627819": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "170",
    "BIRTH_DATE": "JUL 11, 1994",
    "EXP": "R",
    "SCHOOL": "Texas",
    "name": "Isaiah Taylor",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "4eca3491-dad7-43db-bf8d-73ff082df4f0"
    ]
  },
  "1627820": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "198",
    "BIRTH_DATE": "JUN 10, 1994",
    "EXP": "1",
    "SCHOOL": "California",
    "name": "Tyrone Wallace",
    "rosters": [
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "60943860-af22-4302-bd4c-e9d7fa67fb17"
    ]
  },
  "1627821": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "202",
    "BIRTH_DATE": "AUG 19, 1993",
    "EXP": "R",
    "SCHOOL": "Boise State",
    "name": "James Webb III",
    "rosters": [
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1"
    ]
  },
  "1627823": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "214",
    "BIRTH_DATE": "SEP 28, 1995",
    "EXP": "3",
    "SCHOOL": " ",
    "name": "Juancho Hernangomez",
    "rosters": [
      "2cb80819-95d7-430d-89e8-20ee10cc90af",
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1627824": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "260",
    "BIRTH_DATE": "DEC 17, 1995",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Guerschon Yabusele",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a"
    ]
  },
  "1627826": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAR 18, 1997",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Ivica Zubac",
    "rosters": [
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a",
      "caf58514-10b8-4a99-8841-2c49c45bc519"
    ]
  },
  "1627827": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 04, 1993",
    "EXP": "3",
    "SCHOOL": "Florida",
    "name": "Dorian Finney-Smith",
    "rosters": [
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668",
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32"
    ]
  },
  "1627832": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "197",
    "BIRTH_DATE": "FEB 25, 1994",
    "EXP": "3",
    "SCHOOL": "Wichita State",
    "name": "Fred VanVleet",
    "rosters": [
      "0d566ecc-3ff9-41a2-bf28-d6bbad7ea63e",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1627834": {
    "NUM": "13",
    "POSITION": "C",
    "HEIGHT": "7-1",
    "WEIGHT": "240",
    "BIRTH_DATE": "JUL 03, 1997",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Georgios Papagiannis",
    "rosters": [
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "e224a1c9-d8ae-4918-aaf5-70bed34a01bb"
    ]
  },
  "1627835": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "226",
    "BIRTH_DATE": "FEB 18, 1994",
    "EXP": "1",
    "SCHOOL": null,
    "name": "Paul Zipser",
    "rosters": [
      "5ce05276-e599-4e4e-8ec6-40ef7f5129f2",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5"
    ]
  },
  "1627846": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "SEP 25, 1993",
    "EXP": "2",
    "SCHOOL": "Iowa State",
    "name": "Abdel Nader",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "1627848": {
    "NUM": "36",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "255",
    "BIRTH_DATE": "JAN 29, 1993",
    "EXP": "R",
    "SCHOOL": "Louisana-Lafayette",
    "name": "Shawn Long",
    "rosters": [
      "ebfee957-1808-4754-a8dc-3fafb2017bf2"
    ]
  },
  "1627849": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "DEC 15, 1993",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Daniel Ochefu",
    "rosters": [
      "be0a6795-9a40-4756-9ab4-11a5554c68a6"
    ]
  },
  "1627850": {
    "NUM": "40",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUL 14, 1992",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Marshall Plumlee",
    "rosters": [
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5"
    ]
  },
  "1627851": {
    "NUM": "91",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 19, 1989",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Mindaugas Kuzminskas",
    "rosters": [
      "8952c2a7-aa60-4f01-a85a-75c1361c70f5"
    ]
  },
  "1627852": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 02, 1993",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Nicolas Brussino",
    "rosters": [
      "f8f9b0dd-9e3d-4c00-90b0-e4ed7d15a668"
    ]
  },
  "1627853": {
    "NUM": "51",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "MAR 26, 1994",
    "EXP": "2",
    "SCHOOL": "Villanova",
    "name": "Ryan Arcidiacono",
    "rosters": [
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1627854": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 23, 1993",
    "EXP": "3",
    "SCHOOL": "Michigan State",
    "name": "Bryn Forbes",
    "rosters": [
      "2727fadf-a3e8-4564-9084-0145ec943ae4",
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "1627855": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "204",
    "BIRTH_DATE": "AUG 13, 1992",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Okaro White",
    "rosters": [
      "d3fa34ae-65ce-4373-b8b9-b9679b504e5a",
      "bff7c457-7acf-4c98-986e-1ce75d4973d5"
    ]
  },
  "1627856": {
    "NUM": "10",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 05, 1993",
    "EXP": "R",
    "SCHOOL": "Michigan State",
    "name": "Matt Costello",
    "rosters": [
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8"
    ]
  },
  "1627858": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "OCT 03, 1991",
    "EXP": "R",
    "SCHOOL": "Brigham Young",
    "name": "Kyle Collinsworth",
    "rosters": [
      "ee481f46-7270-4ab6-8086-5a707f5f85d4"
    ]
  },
  "1627863": {
    "NUM": "23",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "JUN 07, 1993",
    "EXP": "1",
    "SCHOOL": "Texas A&M",
    "name": "Danuel House Jr.",
    "rosters": [
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae"
    ]
  },
  "1627868": {
    "NUM": "29",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "203",
    "BIRTH_DATE": "MAY 17, 1993",
    "EXP": "R",
    "SCHOOL": "George Washington",
    "name": "Patricio Garino",
    "rosters": [
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da"
    ]
  },
  "1627875": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAR 28, 1994",
    "EXP": "R",
    "SCHOOL": "Georgia Tech",
    "name": "Marcus Georges-Hunt",
    "rosters": [
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "dfe8eef2-e042-4ae8-ba9a-ab496354b3da"
    ]
  },
  "1627883": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 27, 1993",
    "EXP": "R",
    "SCHOOL": "Texas A&M",
    "name": "Jalen Jones",
    "rosters": [
      "ee481f46-7270-4ab6-8086-5a707f5f85d4"
    ]
  },
  "1627884": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 15, 1997",
    "EXP": "R",
    "SCHOOL": "UNLV",
    "name": "Derrick Jones Jr.",
    "rosters": [
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "d1566529-2393-422f-9177-00c89290a8dc",
      "58ed739f-5da8-4431-9d04-f20d9505d73b"
    ]
  },
  "1627885": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 06, 1993",
    "EXP": "R",
    "SCHOOL": "Tulsa",
    "name": "Shaquille Harrison",
    "rosters": [
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae"
    ]
  },
  "1627936": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "186",
    "BIRTH_DATE": "FEB 28, 1994",
    "EXP": "2",
    "SCHOOL": "Texas A&M",
    "name": "Alex Caruso",
    "rosters": [
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "db38d209-7326-41b8-948f-588f9a050e00"
    ]
  },
  "1627982": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 09, 1993",
    "EXP": "1",
    "SCHOOL": "Louisiana State",
    "name": "Josh Gray",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91"
    ]
  },
  "1627988": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 23, 1992",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Myke Henry",
    "rosters": [
      "c5fcad90-ec25-4e4c-9858-41d43a980a21"
    ]
  },
  "1628021": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "209",
    "BIRTH_DATE": "JAN 14, 1993",
    "EXP": "R",
    "SCHOOL": "Cal Poly-Obispo",
    "name": "David Nwaba",
    "rosters": [
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "1a89b7b0-ae68-4d85-88ec-283f8aa6ea1a"
    ]
  },
  "1628035": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 17, 1992",
    "EXP": "R",
    "SCHOOL": "Wisc.-Green Bay",
    "name": "Alfonzo McKinnie",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "42265145-0668-4113-bd04-6a1fa3d9f3d6"
    ]
  },
  "1628070": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUL 27, 1993",
    "EXP": "R",
    "SCHOOL": "Indianapolis",
    "name": "Jordan Loyd",
    "rosters": [
      "7855a129-e474-423e-a119-3ea4ddf812d3"
    ]
  },
  "1628249": {
    "NUM": "55",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 27, 1992",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Mitchell Creek",
    "rosters": [
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f"
    ]
  },
  "1628365": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 29, 1998",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Markelle Fultz",
    "rosters": [
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63",
      "c5dd1f08-5d86-4593-9196-a30b8080e6e0"
    ]
  },
  "1628366": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 27, 1997",
    "EXP": "1",
    "SCHOOL": "UCLA",
    "name": "Lonzo Ball",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91",
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e"
    ]
  },
  "1628367": {
    "NUM": "20",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "207",
    "BIRTH_DATE": "FEB 10, 1997",
    "EXP": "2",
    "SCHOOL": "Kansas",
    "name": "Josh Jackson",
    "rosters": [
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae",
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1628368": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "DEC 20, 1997",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "De'Aaron Fox",
    "rosters": [
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "1628369": {
    "NUM": "0",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 03, 1998",
    "EXP": "2",
    "SCHOOL": "Duke",
    "name": "Jayson Tatum",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1628370": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 04, 1998",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Malik Monk",
    "rosters": [
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1628371": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 03, 1997",
    "EXP": "2",
    "SCHOOL": "Florida State",
    "name": "Jonathan Isaac",
    "rosters": [
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63"
    ]
  },
  "1628372": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "205",
    "BIRTH_DATE": "NOV 25, 1997",
    "EXP": "2",
    "SCHOOL": "North Carolina State",
    "name": "Dennis Smith Jr.",
    "rosters": [
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1628373": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUL 28, 1998",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Frank Ntilikina",
    "rosters": [
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1628374": {
    "NUM": "24",
    "POSITION": "F-C",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "MAY 22, 1997",
    "EXP": "2",
    "SCHOOL": "Arizona",
    "name": "Lauri Markkanen",
    "rosters": [
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1628378": {
    "NUM": "45",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 07, 1996",
    "EXP": "2",
    "SCHOOL": "Louisville",
    "name": "Donovan Mitchell",
    "rosters": [
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1628379": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "206",
    "BIRTH_DATE": "JUN 24, 1996",
    "EXP": "2",
    "SCHOOL": "Duke",
    "name": "Luke Kennard",
    "rosters": [
      "45324799-5d41-45eb-b5ff-b3095f173c43",
      "17ec0980-7e1f-455e-a186-b7156367018b",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "1628380": {
    "NUM": "33",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "NOV 19, 1997",
    "EXP": "2",
    "SCHOOL": "Gonzaga",
    "name": "Zach Collins",
    "rosters": [
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "1628381": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "SEP 23, 1997",
    "EXP": "2",
    "SCHOOL": "Wake Forest",
    "name": "John Collins",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "e8169801-0d82-448f-b1b7-6fb64e81aac6"
    ]
  },
  "1628382": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 28, 1995",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Justin Jackson",
    "rosters": [
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0"
    ]
  },
  "1628383": {
    "NUM": "24",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "236",
    "BIRTH_DATE": "JUN 14, 1997",
    "EXP": "R",
    "SCHOOL": "Creighton",
    "name": "Justin Patton",
    "rosters": [
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198"
    ]
  },
  "1628384": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "232",
    "BIRTH_DATE": "JUL 17, 1997",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "OG Anunoby",
    "rosters": [
      "42265145-0668-4113-bd04-6a1fa3d9f3d6",
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1628385": {
    "NUM": "20",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 22, 1998",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Harry Giles III",
    "rosters": [
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "1628386": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "243",
    "BIRTH_DATE": "APR 21, 1998",
    "EXP": "2",
    "SCHOOL": "Texas",
    "name": "Jarrett Allen",
    "rosters": [
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1",
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "1628387": {
    "NUM": "13",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "262",
    "BIRTH_DATE": "OCT 16, 1998",
    "EXP": "R",
    "SCHOOL": "UCLA",
    "name": "Ike Anigbogu",
    "rosters": [
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e"
    ]
  },
  "1628388": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "222",
    "BIRTH_DATE": "APR 30, 1997",
    "EXP": "2",
    "SCHOOL": "UCLA",
    "name": "TJ Leaf",
    "rosters": [
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "58b309fd-0cb6-4071-8912-360baeb068ca"
    ]
  },
  "1628389": {
    "NUM": "13",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "255",
    "BIRTH_DATE": "JUL 18, 1997",
    "EXP": "2",
    "SCHOOL": "Kentucky",
    "name": "Bam Adebayo",
    "rosters": [
      "64494d90-4847-4b7e-817d-270d082d53b1",
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "d1566529-2393-422f-9177-00c89290a8dc"
    ]
  },
  "1628390": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 17, 1998",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Terrance Ferguson",
    "rosters": [
      "77e6e4de-42ee-4c42-be39-b046215914f2",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "1628391": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "231",
    "BIRTH_DATE": "FEB 19, 1996",
    "EXP": "2",
    "SCHOOL": "Michigan",
    "name": "D.J. Wilson",
    "rosters": [
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97"
    ]
  },
  "1628392": {
    "NUM": "55",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "250",
    "BIRTH_DATE": "MAY 05, 1998",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Isaiah Hartenstein",
    "rosters": [
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9"
    ]
  },
  "1628393": {
    "NUM": "8",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 26, 1996",
    "EXP": "1",
    "SCHOOL": "Oklahoma State",
    "name": "Jawun Evans",
    "rosters": [
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199"
    ]
  },
  "1628394": {
    "NUM": "18",
    "POSITION": "C-F",
    "HEIGHT": "7-2",
    "WEIGHT": "229",
    "BIRTH_DATE": "DEC 20, 1995",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Anzejs Pasecniks",
    "rosters": [
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1628395": {
    "NUM": "2",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "224",
    "BIRTH_DATE": "JAN 07, 1995",
    "EXP": "1",
    "SCHOOL": "Oregon",
    "name": "Jordan Bell",
    "rosters": [
      "39e73052-29ff-45ac-b1b9-e4a6a187b0ca",
      "e8c56c8c-df2c-4465-923b-c47a35c6de01"
    ]
  },
  "1628396": {
    "NUM": "13",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "248",
    "BIRTH_DATE": "JAN 08, 1998",
    "EXP": "2",
    "SCHOOL": "North Carolina",
    "name": "Tony Bradley",
    "rosters": [
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3",
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1628397": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 04, 1997",
    "EXP": "1",
    "SCHOOL": "University of California, Berkeley",
    "name": "Ivan Rabb",
    "rosters": [
      "c5fcad90-ec25-4e4c-9858-41d43a980a21",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "1628398": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "221",
    "BIRTH_DATE": "JUL 24, 1995",
    "EXP": "2",
    "SCHOOL": "Utah",
    "name": "Kyle Kuzma",
    "rosters": [
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "db38d209-7326-41b8-948f-588f9a050e00"
    ]
  },
  "1628399": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 09, 1996",
    "EXP": "1",
    "SCHOOL": "Syracuse",
    "name": "Tyler Lydon",
    "rosters": [
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9"
    ]
  },
  "1628400": {
    "NUM": "37",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "240",
    "BIRTH_DATE": "DEC 05, 1994",
    "EXP": "2",
    "SCHOOL": "Southern Methodist",
    "name": "Semi Ojeleye",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1628401": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 02, 1994",
    "EXP": "2",
    "SCHOOL": "Colorado",
    "name": "Derrick White",
    "rosters": [
      "acfee44c-b308-4a58-a6b3-e1fcb450b8f8",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "1628402": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAY 04, 1998",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Frank Jackson",
    "rosters": [
      "3fa2e383-80a4-4177-aad1-60ea0379e220",
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "1b10411f-c8db-4154-9db0-1900f8577a91"
    ]
  },
  "1628403": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "APR 18, 1997",
    "EXP": "1",
    "SCHOOL": "Purdue",
    "name": "Caleb Swanigan",
    "rosters": [
      "1a813a61-ec5f-471d-a82b-7f1f04400c3d",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33",
      "a33db084-b2b6-45ed-8752-743576aa1a5d"
    ]
  },
  "1628404": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 06, 1995",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Josh Hart",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91",
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e"
    ]
  },
  "1628405": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "MAY 04, 1995",
    "EXP": "2",
    "SCHOOL": "Baylor",
    "name": "Johnathan Motley",
    "rosters": [
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697"
    ]
  },
  "1628407": {
    "NUM": "7",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "221",
    "BIRTH_DATE": "AUG 30, 1995",
    "EXP": "2",
    "SCHOOL": "Florida State",
    "name": "Dwayne Bacon",
    "rosters": [
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9",
      "7e4b59f0-0509-4845-8730-9663232e7b79",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1628408": {
    "NUM": "35",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "205",
    "BIRTH_DATE": "OCT 25, 1996",
    "EXP": "R",
    "SCHOOL": "South Carolina",
    "name": "PJ Dozier",
    "rosters": [
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "dbd3455e-60ba-4d50-9b96-353f912b371a",
      "77e6e4de-42ee-4c42-be39-b046215914f2"
    ]
  },
  "1628409": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 13, 1995",
    "EXP": "R",
    "SCHOOL": "Valparaiso",
    "name": "Alec Peters",
    "rosters": [
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae"
    ]
  },
  "1628410": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "196",
    "BIRTH_DATE": "DEC 31, 1995",
    "EXP": "2",
    "SCHOOL": "Xavier",
    "name": "Edmond Sumner",
    "rosters": [
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "58b309fd-0cb6-4071-8912-360baeb068ca"
    ]
  },
  "1628411": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "DEC 20, 1994",
    "EXP": "2",
    "SCHOOL": "Kansas State",
    "name": "Wes Iwundu",
    "rosters": [
      "65d4beea-3e80-42e8-87ae-d493354f9056",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63"
    ]
  },
  "1628412": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "190",
    "BIRTH_DATE": "APR 03, 1994",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Frank Mason",
    "rosters": [
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "ddaa00aa-d14d-44d5-809c-c6831499cac0",
      "a33db084-b2b6-45ed-8752-743576aa1a5d"
    ]
  },
  "1628413": {
    "NUM": "43",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 02, 1996",
    "EXP": "R",
    "SCHOOL": "UCLA",
    "name": "Jonah Bolden",
    "rosters": [
      "5a728193-2607-49fa-b4d1-2298f8044312"
    ]
  },
  "1628414": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "NOV 15, 1994",
    "EXP": "1",
    "SCHOOL": "South Carolina",
    "name": "Sindarius Thornwell",
    "rosters": [
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae",
      "60943860-af22-4302-bd4c-e9d7fa67fb17"
    ]
  },
  "1628415": {
    "NUM": "24",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 22, 1996",
    "EXP": "2",
    "SCHOOL": "Oregon",
    "name": "Dillon Brooks",
    "rosters": [
      "c5fcad90-ec25-4e4c-9858-41d43a980a21",
      "60ef2ec8-e872-4112-b28c-8e665c403fba",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1628416": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "183",
    "BIRTH_DATE": "FEB 18, 1996",
    "EXP": "1",
    "SCHOOL": "Oregon",
    "name": "Tyler Dorsey",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "1628417": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "SEP 16, 1993",
    "EXP": "R",
    "SCHOOL": "Clemson",
    "name": "Jaron Blossomgame",
    "rosters": [
      "53265a6a-bb78-4439-a46d-37e7fb24c78a"
    ]
  },
  "1628418": {
    "NUM": "13",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "248",
    "BIRTH_DATE": "JUL 31, 1997",
    "EXP": "2",
    "SCHOOL": "Indiana",
    "name": "Thomas Bryant",
    "rosters": [
      "caf58514-10b8-4a99-8841-2c49c45bc519",
      "6631c074-5c99-48db-b77d-c92e855be5bd",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1628420": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "183",
    "BIRTH_DATE": "JUN 27, 1995",
    "EXP": "2",
    "SCHOOL": "Iowa State",
    "name": "Monte Morris",
    "rosters": [
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "dbd3455e-60ba-4d50-9b96-353f912b371a"
    ]
  },
  "1628421": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 07, 1995",
    "EXP": "1",
    "SCHOOL": "Florida",
    "name": "Devin Robinson",
    "rosters": [
      "32483b7a-3a83-49cb-9899-b81ebb1c12ad",
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "1628422": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 06, 1994",
    "EXP": "2",
    "SCHOOL": "Houston",
    "name": "Damyean Dotson",
    "rosters": [
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1628424": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 04, 1997",
    "EXP": "2",
    "SCHOOL": "Arizona",
    "name": "Kobi Simmons",
    "rosters": [
      "c5fcad90-ec25-4e4c-9858-41d43a980a21",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1628425": {
    "NUM": "23",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "219",
    "BIRTH_DATE": "FEB 10, 1995",
    "EXP": "2",
    "SCHOOL": "Southern Methodist",
    "name": "Sterling Brown",
    "rosters": [
      "0e43ebeb-653c-4269-a881-1aef85d79db7",
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97"
    ]
  },
  "1628427": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "236",
    "BIRTH_DATE": "APR 10, 1997",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Vlatko Cancar",
    "rosters": [
      "dbd3455e-60ba-4d50-9b96-353f912b371a"
    ]
  },
  "1628429": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "196",
    "BIRTH_DATE": "JUL 01, 1994",
    "EXP": "R",
    "SCHOOL": "University of Dayton",
    "name": "Charles Cooke",
    "rosters": [
      "3fa2e383-80a4-4177-aad1-60ea0379e220"
    ]
  },
  "1628430": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "SEP 16, 1994",
    "EXP": "R",
    "SCHOOL": "Gonzaga",
    "name": "Nigel Williams-Goss",
    "rosters": [
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1628432": {
    "NUM": "32",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 11, 1995",
    "EXP": "R",
    "SCHOOL": "Miami",
    "name": "Davon Reed",
    "rosters": [
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "3c677db4-7d86-4321-8c93-65ab6d27f0ae"
    ]
  },
  "1628436": {
    "NUM": "2",
    "POSITION": "F-C",
    "HEIGHT": "7-1",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUL 15, 1995",
    "EXP": "1",
    "SCHOOL": "Vanderbilt",
    "name": "Luke Kornet",
    "rosters": [
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc",
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880"
    ]
  },
  "1628439": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 24, 1994",
    "EXP": "1",
    "SCHOOL": "North Carolina",
    "name": "Isaiah Hicks",
    "rosters": [
      "62811488-fdf8-4824-9860-e73dfc40e088",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880"
    ]
  },
  "1628443": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 15, 1993",
    "EXP": "2",
    "SCHOOL": "Arizona",
    "name": "Kadeem Allen",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1628444": {
    "NUM": "26",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "198",
    "BIRTH_DATE": "JUL 03, 1994",
    "EXP": "R",
    "SCHOOL": "California",
    "name": "Jabari Bird",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf"
    ]
  },
  "1628449": {
    "NUM": "25",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 11, 1993",
    "EXP": "2",
    "SCHOOL": "Oregon",
    "name": "Chris Boucher",
    "rosters": [
      "7855a129-e474-423e-a119-3ea4ddf812d3",
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1628462": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "196",
    "BIRTH_DATE": "MAR 19, 1987",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Milos Teodosic",
    "rosters": [
      "07e63f3d-7db6-403e-a6a4-95e2e3cf7fae"
    ]
  },
  "1628463": {
    "NUM": "34",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "238",
    "BIRTH_DATE": "FEB 09, 1994",
    "EXP": "1",
    "SCHOOL": "George Washington",
    "name": "Tyler Cavanaugh",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "1628464": {
    "NUM": "27",
    "POSITION": "F-C",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "APR 04, 1992",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Daniel Theis",
    "rosters": [
      "f703048f-cc3f-439a-bbe4-479bfdca8abf",
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1628467": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 29, 1992",
    "EXP": "2",
    "SCHOOL": " ",
    "name": "Maxi Kleber",
    "rosters": [
      "ee481f46-7270-4ab6-8086-5a707f5f85d4",
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32"
    ]
  },
  "1628469": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "192",
    "BIRTH_DATE": "OCT 04, 1996",
    "EXP": "1",
    "SCHOOL": "Louisiana State",
    "name": "Antonio Blakeney",
    "rosters": [
      "5da31c3b-d3b0-4b7f-b460-47003aa7a8c5",
      "7542de7d-8692-4f6a-a302-a389bb3d20ab"
    ]
  },
  "1628470": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "221",
    "BIRTH_DATE": "DEC 19, 1990",
    "EXP": "2",
    "SCHOOL": "South Carolina Upstate",
    "name": "Torrey Craig",
    "rosters": [
      "4d75880a-40ba-475a-9d0d-58175849b671",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "dbd3455e-60ba-4d50-9b96-353f912b371a"
    ]
  },
  "1628476": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 03, 1995",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Derrick Walton Jr.",
    "rosters": [
      "64494d90-4847-4b7e-817d-270d082d53b1"
    ]
  },
  "1628493": {
    "NUM": "9",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "230",
    "BIRTH_DATE": "OCT 08, 1992",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Mangok Mathiang",
    "rosters": [
      "ee0bc4bd-8484-4fc7-a5f2-0d78a2399bf9"
    ]
  },
  "1628495": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 31, 1993",
    "EXP": "R",
    "SCHOOL": "Loyola (IL)",
    "name": "Milton Doyle",
    "rosters": [
      "dc1a2bd6-7fca-40e5-b4da-0933a7b827e1"
    ]
  },
  "1628499": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 02, 1994",
    "EXP": "1",
    "SCHOOL": "S.E. Missouri",
    "name": "Antonius Cleveland",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf",
      "55f52854-053e-40c1-a39d-85ba78a61d32"
    ]
  },
  "1628500": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 13, 1995",
    "EXP": "1",
    "SCHOOL": "Southern Methodist",
    "name": "Ben Moore",
    "rosters": [
      "471119b9-d0fc-4ba2-a21c-d6635ae5d18e",
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b"
    ]
  },
  "1628502": {
    "NUM": "40",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "254",
    "BIRTH_DATE": "DEC 16, 1994",
    "EXP": "R",
    "SCHOOL": "Wisconsin",
    "name": "Nigel Hayes",
    "rosters": [
      "ddaa00aa-d14d-44d5-809c-c6831499cac0"
    ]
  },
  "1628503": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-7",
    "WEIGHT": "213",
    "BIRTH_DATE": "JAN 12, 1993",
    "EXP": "R",
    "SCHOOL": "Pittsburgh",
    "name": "Jamel Artis",
    "rosters": [
      "65d4beea-3e80-42e8-87ae-d493354f9056"
    ]
  },
  "1628505": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "NOV 29, 1995",
    "EXP": "R",
    "SCHOOL": "Cincinnati",
    "name": "Troy Caupain",
    "rosters": [
      "77986a72-d455-47c5-bf8a-92d32f6e27b9"
    ]
  },
  "1628510": {
    "NUM": "4",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 16, 1993",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Andrew White III",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf"
    ]
  },
  "1628513": {
    "NUM": "30",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "218",
    "BIRTH_DATE": "AUG 03, 1993",
    "EXP": "1",
    "SCHOOL": "Iowa State",
    "name": "Naz Mitrou-Long",
    "rosters": [
      "58b309fd-0cb6-4071-8912-360baeb068ca",
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3"
    ]
  },
  "1628518": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "222",
    "BIRTH_DATE": "MAY 07, 1993",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Amile Jefferson",
    "rosters": [
      "f9d8a546-a0a6-4d34-8621-eff1ee6b8198",
      "77986a72-d455-47c5-bf8a-92d32f6e27b9"
    ]
  },
  "1628537": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 19, 1995",
    "EXP": "R",
    "SCHOOL": "Molloy",
    "name": "Jaylen Morris",
    "rosters": [
      "14c9a360-f6fd-47ee-bd45-11d21cb11ddf"
    ]
  },
  "1628539": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "184",
    "BIRTH_DATE": "JUN 12, 1994",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Mychal Mulder",
    "rosters": [
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "1628571": {
    "NUM": "21",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 20, 1993",
    "EXP": "R",
    "SCHOOL": "Louisiana Tech",
    "name": "Erik McCree",
    "rosters": [
      "1bef196c-7fb3-4331-867f-b7ebbf83fa10"
    ]
  },
  "1628656": {
    "NUM": "3",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 16, 1994",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Billy Garrett",
    "rosters": [
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880"
    ]
  },
  "1628681": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "FEB 14, 1994",
    "EXP": "R",
    "SCHOOL": "Connecticut",
    "name": "Rodney Purvis",
    "rosters": [
      "65d4beea-3e80-42e8-87ae-d493354f9056"
    ]
  },
  "1628778": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 30, 1994",
    "EXP": "R",
    "SCHOOL": "Fresno State",
    "name": "Paul Watson",
    "rosters": [
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1628935": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "183",
    "BIRTH_DATE": "MAY 06, 1986",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Aaron Jackson",
    "rosters": [
      "ed5ee77e-62cb-4831-a0cb-5934e4ffad1d"
    ]
  },
  "1628959": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "225",
    "BIRTH_DATE": "OCT 29, 1997",
    "EXP": "R",
    "SCHOOL": "Arizona",
    "name": "Rawle Alkins",
    "rosters": [
      "7542de7d-8692-4f6a-a302-a389bb3d20ab"
    ]
  },
  "1628960": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "198",
    "BIRTH_DATE": "OCT 08, 1995",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Grayson Allen",
    "rosters": [
      "3003ae4a-7020-4b96-9041-d3bcf427a8f3",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1628961": {
    "NUM": "37",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "200",
    "BIRTH_DATE": "NOV 20, 1997",
    "EXP": "1",
    "SCHOOL": "University of Dayton",
    "name": "Kostas Antetokounmpo",
    "rosters": [
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "db38d209-7326-41b8-948f-588f9a050e00"
    ]
  },
  "1628963": {
    "NUM": "35",
    "POSITION": "F",
    "HEIGHT": "6-11",
    "WEIGHT": "235",
    "BIRTH_DATE": "MAR 14, 1999",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Marvin Bagley III",
    "rosters": [
      "a33db084-b2b6-45ed-8752-743576aa1a5d",
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "1628964": {
    "NUM": "5",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "231",
    "BIRTH_DATE": "MAY 12, 1998",
    "EXP": "1",
    "SCHOOL": "University of Texas at Austin",
    "name": "Mo Bamba",
    "rosters": [
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63"
    ]
  },
  "1628966": {
    "NUM": "31",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "229",
    "BIRTH_DATE": "JAN 23, 1996",
    "EXP": "R",
    "SCHOOL": "Ohio State",
    "name": "Keita Bates-Diop",
    "rosters": [
      "dbd3455e-60ba-4d50-9b96-353f912b371a",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f"
    ]
  },
  "1628968": {
    "NUM": "10",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 02, 1998",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Brian Bowen II",
    "rosters": [
      "58b309fd-0cb6-4071-8912-360baeb068ca"
    ]
  },
  "1628969": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "209",
    "BIRTH_DATE": "AUG 30, 1996",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Mikal Bridges",
    "rosters": [
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1628970": {
    "NUM": "0",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 21, 1998",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Miles Bridges",
    "rosters": [
      "7e4b59f0-0509-4845-8730-9663232e7b79",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1628971": {
    "NUM": "6",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "202",
    "BIRTH_DATE": "AUG 15, 1996",
    "EXP": "1",
    "SCHOOL": "Miami",
    "name": "Bruce Brown",
    "rosters": [
      "17ec0980-7e1f-455e-a186-b7156367018b",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "1628972": {
    "NUM": "6",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "215",
    "BIRTH_DATE": "JUL 28, 1999",
    "EXP": "1",
    "SCHOOL": "Oregon",
    "name": "Troy Brown Jr.",
    "rosters": [
      "6631c074-5c99-48db-b77d-c92e855be5bd",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1628973": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 31, 1996",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Jalen Brunson",
    "rosters": [
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32"
    ]
  },
  "1628975": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "196",
    "BIRTH_DATE": "SEP 14, 1995",
    "EXP": "R",
    "SCHOOL": "West Virginia",
    "name": "Jevon Carter",
    "rosters": [
      "fdf322f1-9f93-4535-913c-53228db4b4fb",
      "60ef2ec8-e872-4112-b28c-8e665c403fba"
    ]
  },
  "1628976": {
    "NUM": "34",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "270",
    "BIRTH_DATE": "APR 16, 1999",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Wendell Carter Jr.",
    "rosters": [
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1628977": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "202",
    "BIRTH_DATE": "JUL 31, 1998",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Hamidou Diallo",
    "rosters": [
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "1628978": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "203",
    "BIRTH_DATE": "JAN 31, 1997",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Donte DiVincenzo",
    "rosters": [
      "4eeead22-09ec-4f45-95dc-12de108dc38f",
      "b414ac61-29b6-44e4-a605-c7f0581fbf97"
    ]
  },
  "1628979": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "189",
    "BIRTH_DATE": "AUG 03, 1998",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Trevon Duval",
    "rosters": [
      "7555fde4-17ee-408f-a2cb-c48414c8073d"
    ]
  },
  "1628980": {
    "NUM": "12",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 18, 1997",
    "EXP": "1",
    "SCHOOL": "Cincinnati",
    "name": "Jacob Evans",
    "rosters": [
      "e8c56c8c-df2c-4465-923b-c47a35c6de01",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1628981": {
    "NUM": "24",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 15, 1998",
    "EXP": "R",
    "SCHOOL": "Maryland",
    "name": "Bruno Fernando",
    "rosters": [
      "e8169801-0d82-448f-b1b7-6fb64e81aac6"
    ]
  },
  "1628982": {
    "NUM": "35",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 30, 1996",
    "EXP": "1",
    "SCHOOL": "Tulane",
    "name": "Melvin Frazier Jr.",
    "rosters": [
      "77986a72-d455-47c5-bf8a-92d32f6e27b9",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63"
    ]
  },
  "1628983": {
    "NUM": "2",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 12, 1998",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Shai Gilgeous-Alexander",
    "rosters": [
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "1628984": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 22, 1995",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Devonte' Graham",
    "rosters": [
      "7e4b59f0-0509-4845-8730-9663232e7b79",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1628987": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "230",
    "BIRTH_DATE": "JUL 09, 1996",
    "EXP": "R",
    "SCHOOL": "Texas-Arlington",
    "name": "Kevin Hervey",
    "rosters": [
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "1628988": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "185",
    "BIRTH_DATE": "SEP 30, 1996",
    "EXP": "1",
    "SCHOOL": "UCLA",
    "name": "Aaron Holiday",
    "rosters": [
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "58b309fd-0cb6-4071-8912-360baeb068ca"
    ]
  },
  "1628989": {
    "NUM": "3",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 27, 1998",
    "EXP": "1",
    "SCHOOL": "Maryland",
    "name": "Kevin Huerter",
    "rosters": [
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "e8169801-0d82-448f-b1b7-6fb64e81aac6"
    ]
  },
  "1628990": {
    "NUM": "15",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "APR 26, 1996",
    "EXP": "1",
    "SCHOOL": "Boise State",
    "name": "Chandler Hutchison",
    "rosters": [
      "7542de7d-8692-4f6a-a302-a389bb3d20ab",
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1628991": {
    "NUM": "13",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "242",
    "BIRTH_DATE": "SEP 15, 1999",
    "EXP": "1",
    "SCHOOL": "Michigan State",
    "name": "Jaren Jackson Jr.",
    "rosters": [
      "60ef2ec8-e872-4112-b28c-8e665c403fba",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1628993": {
    "NUM": "24",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "212",
    "BIRTH_DATE": "APR 22, 1996",
    "EXP": "1",
    "SCHOOL": "Missouri State",
    "name": "Alize Johnson",
    "rosters": [
      "5bc8153c-5498-47f3-b9ab-73dd9a009945",
      "58b309fd-0cb6-4071-8912-360baeb068ca"
    ]
  },
  "1628994": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "220",
    "BIRTH_DATE": "JAN 15, 1994",
    "EXP": "R",
    "SCHOOL": "University of Colorado Boulder",
    "name": "George King",
    "rosters": [
      "f6d5681a-88c5-4a65-856a-0362e1012713"
    ]
  },
  "1628995": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 11, 1999",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Kevin Knox II",
    "rosters": [
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1628997": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 28, 1995",
    "EXP": "R",
    "SCHOOL": "Nevada-Reno",
    "name": "Caleb Martin",
    "rosters": [
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1628998": {
    "NUM": "11",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 28, 1995",
    "EXP": "R",
    "SCHOOL": "Nevada-Reno",
    "name": "Cody Martin",
    "rosters": [
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1628999": {
    "NUM": "00",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "AUG 14, 1996",
    "EXP": "R",
    "SCHOOL": "Georgia",
    "name": "Yante Maten",
    "rosters": [
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73"
    ]
  },
  "1629001": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAY 28, 1998",
    "EXP": "1",
    "SCHOOL": "USC",
    "name": "De'Anthony Melton",
    "rosters": [
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1629002": {
    "NUM": "7",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 22, 1997",
    "EXP": "1",
    "SCHOOL": "USC",
    "name": "Chimezie Metu",
    "rosters": [
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "1629003": {
    "NUM": "18",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 26, 1996",
    "EXP": "1",
    "SCHOOL": "Southern Methodist",
    "name": "Shake Milton",
    "rosters": [
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "1629004": {
    "NUM": "19",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 10, 1997",
    "EXP": "1",
    "SCHOOL": "Kansas",
    "name": "Svi Mykhailiuk",
    "rosters": [
      "17ec0980-7e1f-455e-a186-b7156367018b",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "1629006": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "213",
    "BIRTH_DATE": "SEP 01, 1998",
    "EXP": "1",
    "SCHOOL": "Georgia Institute of Technology",
    "name": "Josh Okogie",
    "rosters": [
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1629007": {
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "240",
    "BIRTH_DATE": "NOV 15, 1999",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Jontay Porter",
    "rosters": [
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1629008": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "218",
    "BIRTH_DATE": "JUN 29, 1998",
    "EXP": "R",
    "SCHOOL": "Missouri",
    "name": "Michael Porter Jr.",
    "rosters": [
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "dbd3455e-60ba-4d50-9b96-353f912b371a"
    ]
  },
  "1629010": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "FEB 22, 1997",
    "EXP": "1",
    "SCHOOL": "Boston College",
    "name": "Jerome Robinson",
    "rosters": [
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1629011": {
    "NUM": "23",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "240",
    "BIRTH_DATE": "APR 01, 1998",
    "EXP": "1",
    "SCHOOL": "Western Kentucky",
    "name": "Mitchell Robinson",
    "rosters": [
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1629012": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 04, 1999",
    "EXP": "1",
    "SCHOOL": "Alabama",
    "name": "Collin Sexton",
    "rosters": [
      "53265a6a-bb78-4439-a46d-37e7fb24c78a",
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  },
  "1629013": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAR 13, 1997",
    "EXP": "1",
    "SCHOOL": "Wichita State",
    "name": "Landry Shamet",
    "rosters": [
      "60943860-af22-4302-bd4c-e9d7fa67fb17",
      "fb00bf45-e0ac-49b0-a60c-03eccab39697"
    ]
  },
  "1629014": {
    "NUM": "1",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "181",
    "BIRTH_DATE": "JUN 08, 1999",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Anfernee Simons",
    "rosters": [
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "1629015": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 04, 1999",
    "EXP": "1",
    "SCHOOL": "Texas Tech",
    "name": "Zhaire Smith",
    "rosters": [
      "5a728193-2607-49fa-b4d1-2298f8044312",
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "1629016": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "245",
    "BIRTH_DATE": "JUL 21, 1997",
    "EXP": "1",
    "SCHOOL": "Villanova",
    "name": "Omari Spellman",
    "rosters": [
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1629017": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAY 08, 1996",
    "EXP": "1",
    "SCHOOL": "Creighton",
    "name": "Khyri Thomas",
    "rosters": [
      "17ec0980-7e1f-455e-a186-b7156367018b",
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "1629018": {
    "NUM": "2",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "209",
    "BIRTH_DATE": "JAN 18, 1999",
    "EXP": "1",
    "SCHOOL": "Duke",
    "name": "Gary Trent Jr.",
    "rosters": [
      "869c9f07-5ffd-4f84-8929-3a72ecf08add",
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "1629019": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "JAN 17, 1996",
    "EXP": "1",
    "SCHOOL": "Arizona",
    "name": "Allonzo Trier",
    "rosters": [
      "7bb11b5b-69f1-4190-9b23-8b26f69f1880",
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1629020": {
    "NUM": "3",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "214",
    "BIRTH_DATE": "APR 03, 1999",
    "EXP": "1",
    "SCHOOL": "Kentucky",
    "name": "Jarred Vanderbilt",
    "rosters": [
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9",
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1629021": {
    "NUM": "21",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "245",
    "BIRTH_DATE": "APR 26, 1997",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "Moritz Wagner",
    "rosters": [
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1629022": {
    "NUM": "1",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "204",
    "BIRTH_DATE": "DEC 14, 1998",
    "EXP": "1",
    "SCHOOL": "Miami",
    "name": "Lonnie Walker IV",
    "rosters": [
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "1629023": {
    "NUM": "25",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 23, 1998",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "P.J. Washington",
    "rosters": [
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1629026": {
    "NUM": "34",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "210",
    "BIRTH_DATE": "DEC 02, 1994",
    "EXP": "1",
    "SCHOOL": "Texas Christian",
    "name": "Kenrich Williams",
    "rosters": [
      "039f5cee-9d10-4eaf-b226-d5668fe65648",
      "1b10411f-c8db-4154-9db0-1900f8577a91"
    ]
  },
  "1629027": {
    "NUM": "11",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "180",
    "BIRTH_DATE": "SEP 19, 1998",
    "EXP": "1",
    "SCHOOL": "Oklahoma",
    "name": "Trae Young",
    "rosters": [
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694",
      "e8169801-0d82-448f-b1b7-6fb64e81aac6"
    ]
  },
  "1629028": {
    "NUM": "22",
    "POSITION": "C",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUL 23, 1998",
    "EXP": "1",
    "SCHOOL": "Arizona",
    "name": "Deandre Ayton",
    "rosters": [
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1629029": {
    "NUM": "77",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 28, 1999",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Luka Doncic",
    "rosters": [
      "52b4e695-cf53-49a6-8a86-ff4295a423ce",
      "55f52854-053e-40c1-a39d-85ba78a61d32"
    ]
  },
  "1629033": {
    "NUM": "1",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "212",
    "BIRTH_DATE": "NOV 05, 1995",
    "EXP": "1",
    "SCHOOL": "North Carolina",
    "name": "Theo Pinson",
    "rosters": [
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "1629034": {
    "NUM": "26",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "225",
    "BIRTH_DATE": "MAR 11, 1997",
    "EXP": "1",
    "SCHOOL": "Louisville",
    "name": "Ray Spalding",
    "rosters": [
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1629035": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "200",
    "BIRTH_DATE": "MAR 12, 1998",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Carsen Edwards",
    "rosters": [
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1629045": {
    "NUM": "50",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "JAN 12, 1996",
    "EXP": "R",
    "SCHOOL": "Notre Dame",
    "name": "Bonzie Colson",
    "rosters": [
      "4eeead22-09ec-4f45-95dc-12de108dc38f"
    ]
  },
  "1629048": {
    "NUM": "88",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUL 20, 1999",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Goga Bitadze",
    "rosters": [
      "58b309fd-0cb6-4071-8912-360baeb068ca"
    ]
  },
  "1629052": {
    "NUM": "12",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 20, 1998",
    "EXP": "R",
    "SCHOOL": "Syracuse",
    "name": "Oshae Brissett",
    "rosters": [
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1629053": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "APR 05, 1996",
    "EXP": "R",
    "SCHOOL": "Purdue",
    "name": "Vincent Edwards",
    "rosters": [
      "7555fde4-17ee-408f-a2cb-c48414c8073d"
    ]
  },
  "1629055": {
    "NUM": "15",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 19, 1995",
    "EXP": "R",
    "SCHOOL": "Clemson",
    "name": "Donte Grantham",
    "rosters": [
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199"
    ]
  },
  "1629056": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "201",
    "BIRTH_DATE": "MAY 16, 1997",
    "EXP": "R",
    "SCHOOL": "Mississippi",
    "name": "Terence Davis",
    "rosters": [
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1629057": {
    "NUM": "44",
    "POSITION": "C-F",
    "HEIGHT": "6-8",
    "WEIGHT": "237",
    "BIRTH_DATE": "OCT 17, 1997",
    "EXP": "1",
    "SCHOOL": "Texas A&M",
    "name": "Robert Williams III",
    "rosters": [
      "3fbdb326-c438-4197-a3c0-def1b0c8d39a",
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1629058": {
    "NUM": "13",
    "POSITION": "G-F",
    "HEIGHT": "6-9",
    "WEIGHT": "217",
    "BIRTH_DATE": "MAY 08, 1999",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Dzanan Musa",
    "rosters": [
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "1629059": {
    "NUM": "2",
    "POSITION": "F-G",
    "HEIGHT": "6-3",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 23, 1997",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Elie Okobo",
    "rosters": [
      "f6d5681a-88c5-4a65-856a-0362e1012713",
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1629060": {
    "NUM": "8",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 08, 1998",
    "EXP": "R",
    "SCHOOL": "Gonzaga",
    "name": "Rui Hachimura",
    "rosters": [
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1629061": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "FEB 01, 1997",
    "EXP": "R",
    "SCHOOL": "Louisville",
    "name": "Deng Adel",
    "rosters": [
      "53265a6a-bb78-4439-a46d-37e7fb24c78a"
    ]
  },
  "1629065": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "187",
    "BIRTH_DATE": "JUN 16, 1997",
    "EXP": "R",
    "SCHOOL": "Boston College",
    "name": "Ky Bowman",
    "rosters": [
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "1629066": {
    "NUM": "00",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "228",
    "BIRTH_DATE": "FEB 05, 1998",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Rodions Kurucs",
    "rosters": [
      "9e0e72ad-de33-463a-b1b4-c0d3d4d61f0e",
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "1629067": {
    "NUM": "17",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 08, 1999",
    "EXP": "1",
    "SCHOOL": " ",
    "name": "Isaac Bonga",
    "rosters": [
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1629094": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "249",
    "BIRTH_DATE": "FEB 01, 1996",
    "EXP": "R",
    "SCHOOL": "Georgetown",
    "name": "Marcus Derrickson",
    "rosters": [
      "e8c56c8c-df2c-4465-923b-c47a35c6de01"
    ]
  },
  "1629102": {
    "NUM": "44",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "184",
    "BIRTH_DATE": "MAY 01, 1997",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Brandon Sampson",
    "rosters": [
      "7542de7d-8692-4f6a-a302-a389bb3d20ab"
    ]
  },
  "1629103": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 03, 1995",
    "EXP": "R",
    "SCHOOL": "Butler",
    "name": "Kelan Martin",
    "rosters": [
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1629109": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "225",
    "BIRTH_DATE": "NOV 16, 1994",
    "EXP": "1",
    "SCHOOL": "Cincinnati",
    "name": "Gary Clark",
    "rosters": [
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "320943e2-fd6a-4354-a265-fc9b02f5ab63"
    ]
  },
  "1629116": {
    "NUM": "31",
    "POSITION": "C",
    "HEIGHT": "6-10",
    "WEIGHT": "245",
    "BIRTH_DATE": "NOV 20, 1994",
    "EXP": "R",
    "SCHOOL": "Seton Hall",
    "name": "Angel Delgado",
    "rosters": [
      "60943860-af22-4302-bd4c-e9d7fa67fb17"
    ]
  },
  "1629117": {
    "NUM": "32",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "205",
    "BIRTH_DATE": "MAR 26, 1997",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Wenyen Gabriel",
    "rosters": [
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33",
      "a33db084-b2b6-45ed-8752-743576aa1a5d"
    ]
  },
  "1629118": {
    "NUM": "45",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "255",
    "BIRTH_DATE": "FEB 03, 1996",
    "EXP": "R",
    "SCHOOL": "UCLA",
    "name": "Thomas Welsh",
    "rosters": [
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9"
    ]
  },
  "1629121": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "MAY 04, 1996",
    "EXP": "R",
    "SCHOOL": "St. Bonaventure",
    "name": "Jaylen Adams",
    "rosters": [
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694"
    ]
  },
  "1629122": {
    "NUM": "55",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 05, 1995",
    "EXP": "R",
    "SCHOOL": "Xavier",
    "name": "J.P. Macura",
    "rosters": [
      "7e4b59f0-0509-4845-8730-9663232e7b79"
    ]
  },
  "1629123": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "227",
    "BIRTH_DATE": "FEB 10, 1995",
    "EXP": "R",
    "SCHOOL": "Rhode Island",
    "name": "Jared Terrell",
    "rosters": [
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f"
    ]
  },
  "1629126": {
    "NUM": "30",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "240",
    "BIRTH_DATE": "JAN 31, 1994",
    "EXP": "1",
    "SCHOOL": "Iowa State",
    "name": "Deonte Burton",
    "rosters": [
      "ad12d9f8-f5c2-4e9c-a65c-0ac78a9c2199",
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "1629129": {
    "NUM": "5",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "198",
    "BIRTH_DATE": "NOV 04, 1994",
    "EXP": "R",
    "SCHOOL": "Xavier",
    "name": "Trevon Bluiett",
    "rosters": [
      "039f5cee-9d10-4eaf-b226-d5668fe65648"
    ]
  },
  "1629130": {
    "NUM": "55",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 22, 1994",
    "EXP": "1",
    "SCHOOL": "Michigan",
    "name": "Duncan Robinson",
    "rosters": [
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "d1566529-2393-422f-9177-00c89290a8dc"
    ]
  },
  "1629133": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "185",
    "BIRTH_DATE": "NOV 29, 1995",
    "EXP": "R",
    "SCHOOL": "Arkansas",
    "name": "Daryl Macon",
    "rosters": [
      "52b4e695-cf53-49a6-8a86-ff4295a423ce"
    ]
  },
  "1629134": {
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 03, 1995",
    "EXP": "R",
    "SCHOOL": "Oakland",
    "name": "Kendrick Nunn",
    "rosters": [
      "2b1352ba-8b7e-4d0b-9b04-e23e5b3f0d73",
      "d1566529-2393-422f-9177-00c89290a8dc"
    ],
    "NUM": "25"
  },
  "1629139": {
    "NUM": "18",
    "POSITION": "G-F",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 13, 1994",
    "EXP": "1",
    "SCHOOL": "George Washington",
    "name": "Yuta Watanabe",
    "rosters": [
      "60ef2ec8-e872-4112-b28c-8e665c403fba",
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1629140": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "228",
    "BIRTH_DATE": "MAY 22, 1995",
    "EXP": "1",
    "SCHOOL": "Gonzaga",
    "name": "Johnathan Williams",
    "rosters": [
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e",
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1629147": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "NOV 01, 1995",
    "EXP": "1",
    "SCHOOL": "College of Charleston",
    "name": "Joe Chealey",
    "rosters": [
      "7e4b59f0-0509-4845-8730-9663232e7b79",
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1629151": {
    "NUM": "45",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 23, 1990",
    "EXP": "R",
    "SCHOOL": "Valparaiso",
    "name": "Ryan Broekhoff",
    "rosters": [
      "52b4e695-cf53-49a6-8a86-ff4295a423ce"
    ]
  },
  "1629162": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "185",
    "BIRTH_DATE": "APR 09, 1996",
    "EXP": "R",
    "SCHOOL": "USC",
    "name": "Jordan McLaughlin",
    "rosters": [
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1629164": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "180",
    "BIRTH_DATE": "OCT 02, 1995",
    "EXP": "R",
    "SCHOOL": "Florida Gulf Coast",
    "name": "Brandon Goodwin",
    "rosters": [
      "e8169801-0d82-448f-b1b7-6fb64e81aac6",
      "c53fc949-ca86-4abf-b9af-e1fa07b1a4c9"
    ]
  },
  "1629168": {
    "NUM": "9",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "200",
    "BIRTH_DATE": "DEC 21, 1995",
    "EXP": "R",
    "SCHOOL": "La Salle",
    "name": "BJ Johnson",
    "rosters": [
      "320943e2-fd6a-4354-a265-fc9b02f5ab63",
      "a33db084-b2b6-45ed-8752-743576aa1a5d"
    ]
  },
  "1629185": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "5-11",
    "WEIGHT": "175",
    "BIRTH_DATE": "NOV 21, 1995",
    "EXP": "1",
    "SCHOOL": "Florida",
    "name": "Chris Chiozza",
    "rosters": [
      "7555fde4-17ee-408f-a2cb-c48414c8073d",
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "1629203": {
    "NUM": "10",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "174",
    "BIRTH_DATE": "APR 09, 1995",
    "EXP": "R",
    "SCHOOL": "New Mexico State",
    "name": "Jemerrio Jones",
    "rosters": [
      "3078bd19-8c1f-4efb-9dfa-25030cefbd5e"
    ]
  },
  "1629216": {
    "NUM": "2",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "200",
    "BIRTH_DATE": "JUN 14, 1996",
    "EXP": "R",
    "SCHOOL": "Cal-Santa Barbara",
    "name": "Gabe Vincent",
    "rosters": [
      "d1566529-2393-422f-9177-00c89290a8dc"
    ]
  },
  "1629234": {
    "NUM": "14",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "245",
    "BIRTH_DATE": "FEB 01, 1997",
    "EXP": "1",
    "SCHOOL": "Oregon State",
    "name": "Drew Eubanks",
    "rosters": [
      "3d5103a2-12a8-48dd-9a26-ceec7ef3af4b",
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "1629244": {
    "NUM": "13",
    "POSITION": "G",
    "HEIGHT": "6-8",
    "WEIGHT": "225",
    "BIRTH_DATE": "FEB 07, 1995",
    "EXP": "R",
    "SCHOOL": "Tulane",
    "name": "Cameron Reynolds",
    "rosters": [
      "b414ac61-29b6-44e4-a605-c7f0581fbf97",
      "0c0a29ba-0c70-454d-8ff0-cbe844e4429f"
    ]
  },
  "1629308": {
    "NUM": "95",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "209",
    "BIRTH_DATE": "APR 10, 1993",
    "EXP": "R",
    "SCHOOL": "Marquette",
    "name": "Juan Toscano-Anderson",
    "rosters": [
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "1629312": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "220",
    "BIRTH_DATE": "DEC 09, 1996",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Haywood Highsmith",
    "rosters": [
      "5a728193-2607-49fa-b4d1-2298f8044312"
    ]
  },
  "1629341": {
    "NUM": "22",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "195",
    "BIRTH_DATE": "AUG 10, 1993",
    "EXP": "R",
    "SCHOOL": "West Virginia",
    "name": "Tarik Phillip",
    "rosters": [
      "6631c074-5c99-48db-b77d-c92e855be5bd"
    ]
  },
  "1629346": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "215",
    "BIRTH_DATE": "AUG 18, 2000",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Alen Smailagic",
    "rosters": [
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "1629353": {
    "NUM": "8",
    "POSITION": "C",
    "HEIGHT": "7-0",
    "WEIGHT": "260",
    "BIRTH_DATE": "JAN 05, 1998",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Isaac Humphries",
    "rosters": [
      "f3a386ba-a54e-40c2-8b4f-93b47b5f4694"
    ]
  },
  "1629541": {
    "NUM": "9",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "200",
    "BIRTH_DATE": "SEP 09, 1989",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Dairis Bertans",
    "rosters": [
      "039f5cee-9d10-4eaf-b226-d5668fe65648"
    ]
  },
  "1629597": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 17, 1995",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Zylan Cheatham",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91"
    ]
  },
  "1629598": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "5-9",
    "WEIGHT": "180",
    "BIRTH_DATE": "JUL 23, 1997",
    "EXP": "R",
    "SCHOOL": "Campbell University",
    "name": "Chris Clemons",
    "rosters": [
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9"
    ]
  },
  "1629599": {
    "NUM": "7",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "210",
    "BIRTH_DATE": "JUN 17, 1997",
    "EXP": "R",
    "SCHOOL": "Minnesota",
    "name": "Amir Coffey",
    "rosters": [
      "fb00bf45-e0ac-49b0-a60c-03eccab39697"
    ]
  },
  "1629605": {
    "NUM": "99",
    "POSITION": "C",
    "HEIGHT": "7-5",
    "WEIGHT": "311",
    "BIRTH_DATE": "DEC 10, 1995",
    "EXP": "R",
    "SCHOOL": "Central Florida",
    "name": "Tacko Fall",
    "rosters": [
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1629607": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "175",
    "BIRTH_DATE": "SEP 14, 1997",
    "EXP": "R",
    "SCHOOL": "Auburn",
    "name": "Jared Harper",
    "rosters": [
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1629608": {
    "NUM": "20",
    "POSITION": "C-F",
    "HEIGHT": "6-10",
    "WEIGHT": "235",
    "BIRTH_DATE": "DEC 09, 1996",
    "EXP": "R",
    "SCHOOL": "Miami",
    "name": "Dewan Hernandez",
    "rosters": [
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1629610": {
    "NUM": "19",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "230",
    "BIRTH_DATE": "AUG 30, 1997",
    "EXP": "R",
    "SCHOOL": "Tulsa",
    "name": "DaQuan Jeffries",
    "rosters": [
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "1629611": {
    "NUM": "14",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 18, 1996",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Terance Mann",
    "rosters": [
      "fb00bf45-e0ac-49b0-a60c-03eccab39697"
    ]
  },
  "1629621": {
    "NUM": "35",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "198",
    "BIRTH_DATE": "JUL 26, 1995",
    "EXP": "R",
    "SCHOOL": "Iowa State",
    "name": "Marial Shayok",
    "rosters": [
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "1629622": {
    "NUM": "28",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "MAR 28, 1996",
    "EXP": "R",
    "SCHOOL": "DePaul",
    "name": "Max Strus",
    "rosters": [
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1629624": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "235",
    "BIRTH_DATE": "APR 17, 1998",
    "EXP": "R",
    "SCHOOL": "Oregon",
    "name": "Kenny Wooten",
    "rosters": [
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1629625": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-0",
    "WEIGHT": "190",
    "BIRTH_DATE": "OCT 27, 1997",
    "EXP": "R",
    "SCHOOL": "Hofstra",
    "name": "Justin Wright-Foreman",
    "rosters": [
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1629626": {
    "NUM": "10",
    "POSITION": "C-F",
    "HEIGHT": "7-2",
    "WEIGHT": "220",
    "BIRTH_DATE": "NOV 16, 1999",
    "EXP": "R",
    "SCHOOL": "Oregon",
    "name": "Bol Bol",
    "rosters": [
      "dbd3455e-60ba-4d50-9b96-353f912b371a"
    ]
  },
  "1629627": {
    "NUM": "1",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "284",
    "BIRTH_DATE": "JUL 06, 2000",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Zion Williamson",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91"
    ]
  },
  "1629628": {
    "NUM": "9",
    "POSITION": "F-G",
    "HEIGHT": "6-6",
    "WEIGHT": "214",
    "BIRTH_DATE": "JUN 14, 2000",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "RJ Barrett",
    "rosters": [
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1629629": {
    "NUM": "22",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "218",
    "BIRTH_DATE": "SEP 01, 1999",
    "EXP": "R",
    "SCHOOL": "Duke",
    "name": "Cam Reddish",
    "rosters": [
      "e8169801-0d82-448f-b1b7-6fb64e81aac6"
    ]
  },
  "1629630": {
    "NUM": "12",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "174",
    "BIRTH_DATE": "AUG 10, 1999",
    "EXP": "R",
    "SCHOOL": "Murray State",
    "name": "Ja Morant",
    "rosters": [
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1629631": {
    "NUM": "12",
    "POSITION": "F-G",
    "HEIGHT": "6-7",
    "WEIGHT": "225",
    "BIRTH_DATE": "DEC 02, 1997",
    "EXP": "R",
    "SCHOOL": "Virginia",
    "name": "De'Andre Hunter",
    "rosters": [
      "e8169801-0d82-448f-b1b7-6fb64e81aac6"
    ]
  },
  "1629632": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 16, 2000",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Coby White",
    "rosters": [
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1629633": {
    "NUM": "23",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "195",
    "BIRTH_DATE": "FEB 20, 1999",
    "EXP": "R",
    "SCHOOL": "Texas Tech",
    "name": "Jarrett Culver",
    "rosters": [
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1629634": {
    "NUM": "15",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "SEP 19, 1996",
    "EXP": "R",
    "SCHOOL": "Gonzaga",
    "name": "Brandon Clarke",
    "rosters": [
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1629635": {
    "NUM": "45",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "DEC 23, 2000",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Sekou Doumbouya",
    "rosters": [
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "1629636": {
    "NUM": "10",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "192",
    "BIRTH_DATE": "JAN 26, 2000",
    "EXP": "R",
    "SCHOOL": "Vanderbilt",
    "name": "Darius Garland",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  },
  "1629637": {
    "NUM": "10",
    "POSITION": "C-F",
    "HEIGHT": "6-11",
    "WEIGHT": "220",
    "BIRTH_DATE": "MAY 23, 2000",
    "EXP": "R",
    "SCHOOL": "Texas",
    "name": "Jaxson Hayes",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91"
    ]
  },
  "1629638": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 02, 1998",
    "EXP": "R",
    "SCHOOL": "Virginia Tech",
    "name": "Nickeil Alexander-Walker",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91"
    ]
  },
  "1629639": {
    "NUM": "14",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JAN 20, 2000",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Tyler Herro",
    "rosters": [
      "d1566529-2393-422f-9177-00c89290a8dc"
    ]
  },
  "1629640": {
    "NUM": "3",
    "POSITION": "F-G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "OCT 11, 1999",
    "EXP": "R",
    "SCHOOL": "Kentucky",
    "name": "Keldon Johnson",
    "rosters": [
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "1629641": {
    "NUM": "45",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "216",
    "BIRTH_DATE": "OCT 25, 1999",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Romeo Langford",
    "rosters": [
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1629642": {
    "NUM": "9",
    "POSITION": "F-G",
    "HEIGHT": "6-5",
    "WEIGHT": "220",
    "BIRTH_DATE": "FEB 11, 2000",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Nassir Little",
    "rosters": [
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "1629644": {
    "NUM": "4",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 28, 1999",
    "EXP": "R",
    "SCHOOL": "Stanford",
    "name": "KZ Okpala",
    "rosters": [
      "d1566529-2393-422f-9177-00c89290a8dc"
    ]
  },
  "1629645": {
    "NUM": "4",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "203",
    "BIRTH_DATE": "MAY 04, 2000",
    "EXP": "R",
    "SCHOOL": "USC",
    "name": "Kevin Porter Jr.",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  },
  "1629647": {
    "NUM": "7",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "208",
    "BIRTH_DATE": "JUN 12, 2000",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Darius Bazley",
    "rosters": [
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "1629648": {
    "NUM": "18",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "180",
    "BIRTH_DATE": "NOV 05, 1997",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Jordan Bone",
    "rosters": [
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "1629649": {
    "NUM": "17",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "221",
    "BIRTH_DATE": "JAN 08, 1999",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Ignas Brazdeikis",
    "rosters": [
      "d03b2c81-799a-49e6-9f1f-c1b8c4918a89"
    ]
  },
  "1629650": {
    "NUM": "4",
    "POSITION": "C",
    "HEIGHT": "7-2",
    "WEIGHT": "245",
    "BIRTH_DATE": "OCT 13, 1999",
    "EXP": "R",
    "SCHOOL": "UCLA",
    "name": "Moses Brown",
    "rosters": [
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "1629651": {
    "NUM": "33",
    "POSITION": "F-C",
    "HEIGHT": "6-11",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 17, 1999",
    "EXP": "R",
    "SCHOOL": "Georgia",
    "name": "Nicolas Claxton",
    "rosters": [
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "1629652": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "215",
    "BIRTH_DATE": "APR 19, 1999",
    "EXP": "R",
    "SCHOOL": "Arizona State",
    "name": "Luguentz Dort",
    "rosters": [
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "1629655": {
    "NUM": "12",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "234",
    "BIRTH_DATE": "OCT 01, 1998",
    "EXP": "R",
    "SCHOOL": "Arkansas",
    "name": "Daniel Gafford",
    "rosters": [
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1629657": {
    "NUM": "7",
    "POSITION": "G",
    "HEIGHT": "6-1",
    "WEIGHT": "167",
    "BIRTH_DATE": "AUG 11, 1997",
    "EXP": "R",
    "SCHOOL": "Virginia",
    "name": "Kyle Guy",
    "rosters": [
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "1629658": {
    "NUM": "6",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "216",
    "BIRTH_DATE": "MAR 30, 1999",
    "EXP": "R",
    "SCHOOL": "Wake Forest",
    "name": "Jaylen Hoard",
    "rosters": [
      "3a36d3bf-9ebc-4def-a79e-cb4b77b6eb33"
    ]
  },
  "1629659": {
    "NUM": "5",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "234",
    "BIRTH_DATE": "NOV 25, 2000",
    "EXP": "R",
    "SCHOOL": "Iowa State",
    "name": "Talen Horton-Tucker",
    "rosters": [
      "db38d209-7326-41b8-948f-588f9a050e00"
    ]
  },
  "1629660": {
    "NUM": "10",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "195",
    "BIRTH_DATE": "JUL 08, 1997",
    "EXP": "R",
    "SCHOOL": "Virginia",
    "name": "Ty Jerome",
    "rosters": [
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1629661": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 03, 1996",
    "EXP": "R",
    "SCHOOL": "North Carolina",
    "name": "Cameron Johnson",
    "rosters": [
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1629662": {
    "NUM": "25",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "250",
    "BIRTH_DATE": "AUG 14, 1997",
    "EXP": "R",
    "SCHOOL": "Florida State",
    "name": "Mfiondu Kabengele",
    "rosters": [
      "fb00bf45-e0ac-49b0-a60c-03eccab39697"
    ]
  },
  "1629663": {
    "NUM": "14",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "205",
    "BIRTH_DATE": "APR 06, 1999",
    "EXP": "R",
    "SCHOOL": "Oregon",
    "name": "Louis King",
    "rosters": [
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "1629665": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 13, 2000",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Jalen Lecque",
    "rosters": [
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1629667": {
    "NUM": "6",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "205",
    "BIRTH_DATE": "JAN 31, 1998",
    "EXP": "R",
    "SCHOOL": "San Diego State",
    "name": "Jalen McDaniels",
    "rosters": [
      "215d58c2-6db2-44bf-94e9-79535092d38e"
    ]
  },
  "1629669": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "201",
    "BIRTH_DATE": "JUL 09, 1999",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Jaylen Nowell",
    "rosters": [
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1629671": {
    "NUM": "81",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "206",
    "BIRTH_DATE": "AUG 04, 1997",
    "EXP": "R",
    "SCHOOL": "Yale",
    "name": "Miye Oni",
    "rosters": [
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1629672": {
    "NUM": "7",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "255",
    "BIRTH_DATE": "NOV 04, 1996",
    "EXP": "R",
    "SCHOOL": "Villanova",
    "name": "Eric Paschall",
    "rosters": [
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "1629673": {
    "NUM": "3",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "194",
    "BIRTH_DATE": "JUN 19, 1999",
    "EXP": "R",
    "SCHOOL": "Michigan",
    "name": "Jordan Poole",
    "rosters": [
      "84c34c47-edfa-4bd4-919b-b94fd2cd9d2e"
    ]
  },
  "1629675": {
    "NUM": "11",
    "POSITION": "C-F",
    "HEIGHT": "6-9",
    "WEIGHT": "264",
    "BIRTH_DATE": "AUG 26, 1999",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Naz Reid",
    "rosters": [
      "4eebffb2-c1ae-4aff-872a-963dbe9919ca"
    ]
  },
  "1629676": {
    "NUM": "22",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "230",
    "BIRTH_DATE": "FEB 03, 1998",
    "EXP": "R",
    "SCHOOL": "University of Nebraska-Lincoln",
    "name": "Isaiah Roby",
    "rosters": [
      "132cab8d-a67b-4237-8434-a38471a710a7"
    ]
  },
  "1629677": {
    "NUM": "19",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "227",
    "BIRTH_DATE": "JAN 09, 2000",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Luka Samanic",
    "rosters": [
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "1629678": {
    "NUM": "1",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "241",
    "BIRTH_DATE": "MAR 30, 1997",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Admiral Schofield",
    "rosters": [
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1629680": {
    "NUM": "22",
    "POSITION": "G-F",
    "HEIGHT": "6-5",
    "WEIGHT": "201",
    "BIRTH_DATE": "MAR 04, 1997",
    "EXP": "R",
    "SCHOOL": "Washington",
    "name": "Matisse Thybulle",
    "rosters": [
      "04ce94c4-287c-4e35-bc8f-6f68f579fda7"
    ]
  },
  "1629682": {
    "NUM": "51",
    "POSITION": "G",
    "HEIGHT": "5-10",
    "WEIGHT": "175",
    "BIRTH_DATE": "JAN 10, 1998",
    "EXP": "R",
    "SCHOOL": "Louisiana State",
    "name": "Tremont Waters",
    "rosters": [
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1629683": {
    "NUM": "15",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "205",
    "BIRTH_DATE": "SEP 10, 1996",
    "EXP": "R",
    "SCHOOL": "Mississippi State",
    "name": "Quinndary Weatherspoon",
    "rosters": [
      "5bb0f588-4ae1-4b44-bc07-0ca64b1e7e5e"
    ]
  },
  "1629684": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-6",
    "WEIGHT": "236",
    "BIRTH_DATE": "NOV 30, 1998",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Grant Williams",
    "rosters": [
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1629685": {
    "NUM": "9",
    "POSITION": "G-F",
    "HEIGHT": "6-6",
    "WEIGHT": "196",
    "BIRTH_DATE": "SEP 22, 1996",
    "EXP": "R",
    "SCHOOL": "Belmont",
    "name": "Dylan Windler",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  },
  "1629690": {
    "NUM": "20",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "190",
    "BIRTH_DATE": "JUL 18, 1998",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Adam Mokoka",
    "rosters": [
      "110e63fa-bb3f-43aa-bb6d-6ee7adb149dc"
    ]
  },
  "1629713": {
    "NUM": "10",
    "POSITION": "G-F",
    "HEIGHT": "6-7",
    "WEIGHT": "190",
    "BIRTH_DATE": "JAN 24, 1997",
    "EXP": "R",
    "SCHOOL": "Wyoming",
    "name": "Justin James",
    "rosters": [
      "c4e00e27-136a-44e7-875f-03ce99bc45ac"
    ]
  },
  "1629714": {
    "NUM": "5",
    "POSITION": "F",
    "HEIGHT": "6-5",
    "WEIGHT": "250",
    "BIRTH_DATE": "JUN 07, 1996",
    "EXP": "R",
    "SCHOOL": "College of Charleston",
    "name": "Jarrell Brantley",
    "rosters": [
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1629718": {
    "NUM": "4",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "199",
    "BIRTH_DATE": "FEB 02, 1997",
    "EXP": "R",
    "SCHOOL": null,
    "name": "Charles Brown Jr.",
    "rosters": [
      "e8169801-0d82-448f-b1b7-6fb64e81aac6"
    ]
  },
  "1629719": {
    "NUM": "12",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "240",
    "BIRTH_DATE": "OCT 08, 1996",
    "EXP": "R",
    "SCHOOL": "UNC-Wilmington",
    "name": "Devontae Cacok",
    "rosters": [
      "db38d209-7326-41b8-948f-588f9a050e00"
    ]
  },
  "1629723": {
    "NUM": "46",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "210",
    "BIRTH_DATE": "MAR 22, 1996",
    "EXP": "R",
    "SCHOOL": null,
    "name": "John Konchar",
    "rosters": [
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1629724": {
    "NUM": "23",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "201",
    "BIRTH_DATE": "DEC 19, 1995",
    "EXP": "R",
    "SCHOOL": "Northwestern",
    "name": "Vic Law",
    "rosters": [
      "320943e2-fd6a-4354-a265-fc9b02f5ab63"
    ]
  },
  "1629725": {
    "NUM": "0",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "185",
    "BIRTH_DATE": "JUN 19, 1996",
    "EXP": "R",
    "SCHOOL": "Memphis",
    "name": "Jeremiah Martin",
    "rosters": [
      "206a57a6-ccc5-413b-a413-5deab8b03a78"
    ]
  },
  "1629726": {
    "NUM": "24",
    "POSITION": "G",
    "HEIGHT": "6-5",
    "WEIGHT": "215",
    "BIRTH_DATE": "OCT 24, 1996",
    "EXP": "R",
    "SCHOOL": "Lipscomb",
    "name": "Garrison Mathews",
    "rosters": [
      "8b7de8da-f5a4-4ce3-9ff8-5ae5a00a6919"
    ]
  },
  "1629729": {
    "NUM": "23",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "214",
    "BIRTH_DATE": "JUN 04, 1997",
    "EXP": "R",
    "SCHOOL": "Penn State",
    "name": "Josh Reaves",
    "rosters": [
      "55f52854-053e-40c1-a39d-85ba78a61d32"
    ]
  },
  "1629730": {
    "NUM": "6",
    "POSITION": "G",
    "HEIGHT": "6-3",
    "WEIGHT": "209",
    "BIRTH_DATE": "SEP 24, 1997",
    "EXP": "R",
    "SCHOOL": "Arkansas-Little Rock",
    "name": "Rayjon Tucker",
    "rosters": [
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1629731": {
    "NUM": "32",
    "POSITION": "F-C",
    "HEIGHT": "6-9",
    "WEIGHT": "228",
    "BIRTH_DATE": "NOV 20, 1996",
    "EXP": "R",
    "SCHOOL": "Kansas State",
    "name": "Dean Wade",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  },
  "1629734": {
    "NUM": "17",
    "POSITION": "F-C",
    "HEIGHT": "6-10",
    "WEIGHT": "216",
    "BIRTH_DATE": "OCT 21, 1996",
    "EXP": "R",
    "SCHOOL": "Tennessee",
    "name": "Kyle Alexander",
    "rosters": [
      "d1566529-2393-422f-9177-00c89290a8dc"
    ]
  },
  "1629735": {
    "NUM": "30",
    "POSITION": "F",
    "HEIGHT": "6-8",
    "WEIGHT": "234",
    "BIRTH_DATE": "SEP 19, 1996",
    "EXP": "R",
    "SCHOOL": "South Carolina",
    "name": "Chris Silva",
    "rosters": [
      "d1566529-2393-422f-9177-00c89290a8dc"
    ]
  },
  "1629738": {
    "NUM": "77",
    "POSITION": "C-F",
    "HEIGHT": "7-0",
    "WEIGHT": "235",
    "BIRTH_DATE": "OCT 17, 1993",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Vincent Poirier",
    "rosters": [
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1629739": {
    "NUM": "52",
    "POSITION": "F-G",
    "HEIGHT": "6-8",
    "WEIGHT": "207",
    "BIRTH_DATE": "OCT 25, 1993",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "William Howard",
    "rosters": [
      "07d89fb9-f2fc-4a5a-b886-c540634f94a9"
    ]
  },
  "1629740": {
    "NUM": "20",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "236",
    "BIRTH_DATE": "JAN 26, 1991",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Nicolo Melli",
    "rosters": [
      "1b10411f-c8db-4154-9db0-1900f8577a91"
    ]
  },
  "1629741": {
    "NUM": "23",
    "POSITION": "G",
    "HEIGHT": "6-6",
    "WEIGHT": "201",
    "BIRTH_DATE": "MAR 08, 1995",
    "EXP": "R",
    "SCHOOL": " ",
    "name": "Marko Guduric",
    "rosters": [
      "3a80cffe-adec-482f-ac76-04c11a407dd1"
    ]
  },
  "1629743": {
    "NUM": "42",
    "POSITION": "F",
    "HEIGHT": "6-9",
    "WEIGHT": "229",
    "BIRTH_DATE": "AUG 07, 1997",
    "EXP": "R",
    "SCHOOL": "Alabama",
    "name": "Donta Hall",
    "rosters": [
      "de2d945e-04d3-4b3c-aba1-385a321827ad"
    ]
  },
  "1629744": {
    "NUM": "21",
    "POSITION": "G",
    "HEIGHT": "6-4",
    "WEIGHT": "190",
    "BIRTH_DATE": "AUG 04, 1994",
    "EXP": "R",
    "SCHOOL": "Iowa State",
    "name": "Matt Thomas",
    "rosters": [
      "00aa6bed-2e74-4a5e-82ef-2bb680b41d67"
    ]
  },
  "1629745": {
    "NUM": "41",
    "POSITION": "F",
    "HEIGHT": "6-10",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUN 30, 1995",
    "EXP": "R",
    "SCHOOL": "Texas Tech",
    "name": "Tariq Owens",
    "rosters": [
      "fdf322f1-9f93-4535-913c-53228db4b4fb"
    ]
  },
  "1629750": {
    "NUM": "43",
    "POSITION": "G-F",
    "HEIGHT": "6-4",
    "WEIGHT": "205",
    "BIRTH_DATE": "JUL 23, 1993",
    "EXP": "R",
    "SCHOOL": "Radford University",
    "name": "Javonte Green",
    "rosters": [
      "f58fe66a-52f3-49b3-8f25-65157ccddeed"
    ]
  },
  "1629752": {
    "NUM": "16",
    "POSITION": "F",
    "HEIGHT": "6-7",
    "WEIGHT": "232",
    "BIRTH_DATE": "APR 17, 1997",
    "EXP": "R",
    "SCHOOL": "Indiana",
    "name": "Juwan Morgan",
    "rosters": [
      "89f7f8c2-217e-4cc6-b8bc-ff2162aff7d4"
    ]
  },
  "1629760": {
    "NUM": "31",
    "POSITION": "G",
    "HEIGHT": "6-2",
    "WEIGHT": "199",
    "BIRTH_DATE": "FEB 07, 1997",
    "EXP": "R",
    "SCHOOL": "Texas Tech",
    "name": "Matt Mooney",
    "rosters": [
      "10cfa83d-aa34-4f82-a0eb-ece8c29014ce"
    ]
  }
};